import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateString'
})
export class DateStringPipe implements PipeTransform {

  pipe = new DatePipe('en-US');
  transform(value: any): any {
    let data: any = new Date(value);
    if (data != 'Invalid Date') {
      data = this.pipe.transform(data, 'dd MMM yyyy');
    } else {
      data = value;
    }
    return data
  }

}
