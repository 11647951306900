<p-toolbar class="sub-header" [ngClass]="isApptEdit ? 'editor' : ''">
    <div class="p-toolbar-group-left">
        <p-button (onClick)="backProjectClick()" styleClass="p-button p-button-secondary2 text-blue mr-2" label="" icon="pi pi-arrow-left" iconPos="right" pTooltip="Exit Project Editor" tooltipPosition="bottom" *ngIf="isApptEdit"> </p-button>
        <ui-fibr-breadcrumb></ui-fibr-breadcrumb>
        <div class="app-detail">
            <ui-fibr-iconapps [appConfig]="appConfig" *ngIf="isApptEdit" ></ui-fibr-iconapps>
            <ui-fibr-middle-action-button [appConfig]="appConfig" *ngIf="isApptEdit"></ui-fibr-middle-action-button>
        </div>
    </div>

    <div class="p-toolbar-group-right" >
      <ui-fibr-right-action-button 
        [loading]="loading" [appConfig]="appConfig" [isApptEdit]="isApptEdit" [isCreateNew]="isCreateNew" 
        [hideButtonCreate]="hideButtonCreate" [dataSource]="dataSource"
        (properties)="propertiesClick($event)" (publish)="publishClick($event)" (save)="saveClick($event)" (delete)="deleteBeMarket($event)"  
        (eventOnchange)="onChangeEvent($event)" (copylink)="copyLinkClick($event)" (downloadqr)="downloadQrClick($event)" 
        (publishapp)="preparePublishApp($event)" (checkpackage)="checkPackageClick($event)" (backProject)="backProjectClick()">
      </ui-fibr-right-action-button>
    </div>
    
</p-toolbar>

<p-confirmDialog key="subheader" [style]="{width: '500px'}" [baseZIndex]="10000"></p-confirmDialog>