/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { NgModule } from '@angular/core';
import { CommonModule, LowerCasePipe, TitleCasePipe, DatePipe  } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {TranslateLoader, TranslateModule, TranslateService, TranslateStore} from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';

//import primeng
import { PanelModule } from 'primeng/panel';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MessageModule } from 'primeng/message';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogModule } from "primeng/dialog";
import { ButtonModule } from "primeng/button";
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from "primeng/toast";
import { ToastNotificationComponent } from './components/toast-notification/toast-notification.component';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from "primeng/checkbox";
import { MenubarModule } from 'primeng/menubar';
import { ToolbarModule } from 'primeng/toolbar';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from "primeng/card";
import { ChartModule } from "primeng/chart";
import { ProgressBarModule } from "primeng/progressbar";
import {DividerModule} from 'primeng/divider';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {PanelMenuModule} from 'primeng/panelmenu';
import {FileUploadModule} from 'primeng/fileupload';
import { MenuModule } from "primeng/menu";
import {TabMenuModule} from 'primeng/tabmenu';
import {DialogService, DynamicDialogConfig, DynamicDialogModule} from 'primeng/dynamicdialog';
import {StepsModule} from 'primeng/steps';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {SidebarModule} from 'primeng/sidebar';
import {AccordionModule} from 'primeng/accordion';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {SelectButtonModule} from 'primeng/selectbutton';
import {DropdownModule} from 'primeng/dropdown';
import {DataViewModule} from 'primeng/dataview';
import {OrderListModule} from 'primeng/orderlist';
import {TimelineModule} from 'primeng/timeline';
import {RatingModule} from 'primeng/rating';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {BlockUIModule} from 'primeng/blockui';
import {CalendarModule} from "primeng/calendar";
import {InputNumberModule} from "primeng/inputnumber";
import {TreeModule} from 'primeng/tree';
import { ContextMenuModule } from 'primeng/contextmenu';

import {TreeTableModule} from 'primeng/treetable';
import {RadioButtonModule} from "primeng/radiobutton";
import {MultiSelectModule} from "primeng/multiselect";
import {EditorModule} from 'primeng/editor'
import { FieldsetModule, } from 'primeng/fieldset';
import {ColorPickerModule} from 'primeng/colorpicker';
import {TabViewModule} from 'primeng/tabview';
import {ChipsModule} from 'primeng/chips';
import {TooltipModule} from 'primeng/tooltip';
import {InputMaskModule} from 'primeng/inputmask';
import {GalleriaModule} from 'primeng/galleria';
import { InputSwitchModule } from 'primeng/inputswitch';
import { BadgeModule } from 'primeng/badge';


import { FooterComponent } from './components/footer/footer.component';
import { ModalComponent } from './components/modal/modal.component';
import { ComfirmationComponent } from './components/comfirmation/comfirmation.component';
import { LanguageComponent } from './components/topbar/language/language.component';
import { ThemeComponent } from './components/topbar/theme/theme.component';
import { ProfileComponent } from './components/topbar/profile/profile.component';
import { HelpComponent } from './components/topbar/help/help.component';
import { NotificationsComponent } from './components/topbar/notifications/notifications.component';
import { BrandComponent } from './components/topbar/brand/brand.component';
import { HorizontalmenuComponent } from './components/topbar/horizontalmenu/horizontalmenu.component';
import { IconappsComponent } from './components/subtopbar/iconapps/iconapps.component';
import { TitleComponent } from './components/subtopbar/title/title.component';
import { RightActionButtonComponent } from './components/subtopbar/right-action-button/right-action-button.component';
import { MiddleActionButtonComponent } from './components/subtopbar/middle-action-button/middle-action-button.component';
import { SearchComponent } from './components/subtopbar/search/search.component';
import { HeaderComponent } from './components/header/header.component';
import { LeftmenuComponent } from './components/leftmenu/leftmenu.component';
import { SubheaderComponent } from './components/subheader/subheader.component';
import { GridComponent } from './components/features/grid/grid.component';
import { GridApiComponent } from './components/features/grid-api/grid-api.component';
import { LinkcolumnPipe } from './pipes/linkcolumn.pipe';
import { BreadcrumbComponent } from './components/subtopbar/breadcrumb/breadcrumb.component';

import { AngularFireModule, FirebaseApp } from '@angular/fire';
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule, GetDownloadURLPipeModule, GetDownloadURLPipe } from "@angular/fire/storage";
import {
  GoogleApiModule, 
  NgGapiClientConfig, 
  NG_GAPI_CONFIG
} from "ng-gapi";
import { environment } from 'libs/auth/src/environments/environment';
import { CountdatabooleanPipe } from './pipes/countdataboolean.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { CreatenewComponent } from './components/dynamicdialogs/createnew/createnew.component';
import { GlobalComponent } from './components/dynamicdialogs/global/global.component';
import { ToastNotificationService } from './services/toast-notification.service';
import { MobileComponent } from './features/mobile/mobile.component';
import { BottommenuComponent } from './features/mobile/bottommenu/bottommenu.component';
import { HeaderComponent as HeaderMobileComponent } from "./features/mobile/header/header.component";
import { GridComponent as GridMobileComponent } from "./features/mobile/grid/grid.component";
import { ListComponent } from './features/mobile/list/list.component';
import { InjectDirective } from './directives/inject.directive';
import { DataviewComponent } from './components/dataview/dataview.component';
import { Dataview2Component } from './components/dataview2/dataview2.component';
import { NotFoundComponent } from './components/http-status-code-components/not-found/not-found.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { KiloformaterPipe } from './pipes/kiloformater.pipe';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { BlockuiComponent } from './components/blockui/blockui.component';
import { AddColumnsComponent } from './components/dynamicdialogs/add-columns/add-columns.component';
import { NotifHtmlComponent } from './components/dynamicdialogs/notif-html/notif-html.component';
import { AddTableComponent } from './components/dynamicdialogs/add-table/add-table.component';
import { DateHelperService } from './services/date-helper.service';
import { FormComponent } from './features/mobile/form/form.component';
import { PageDetailsComponent } from './features/mobile/page-details/page-details.component';
import { WelcomeComponent } from './components/topbar/welcome/welcome.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { GridFirebaseComponent } from './components/features/grid-firebase/grid-firebase.component';
import { QRCodeModule } from 'angularx-qrcode';
import { CodeDescPipe } from './pipes/code-desc.pipe';
import { StripTagsPipe } from './pipes/strip-tags.pipe';
import { ElipsisMeDirective } from './directives/elipsis-me.directive';
import { CountdownModule } from 'ngx-countdown';
import { CountdownTimerComponent } from './components/countdown-timer/countdown-timer.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { FullCalendarComponent } from './components/full-calendar/full-calendar.component';
import { EditColumnsComponent } from './components/dynamicdialogs/edit-columns/edit-columns.component';
import { FileDragNDropDirective } from './directives/file-DragNDrop.directive';
import { TooltipDirective } from './directives/tooltip.directive';
import { TooltipComponent, TooltipContainerDirective } from './directives/tooltip.component';
import { ForTooltipComponent } from './components/for-tooltip/for-tooltip.component';
import { CreatenewBlankComponent } from './components/dynamicdialogs/createnew-blank/createnew-blank.component';
import { InAppPaymentApprovalComponent } from './components/dynamicdialogs/inapppyament-approval/inapppyament-approval.component';
import { CreatenewOptionComponent } from './components/dynamicdialogs/createnew-option/createnew-option.component';
import { DetailProjectComponent } from './components/detail-project/detail-project.component';
import { CreatenewTemplateComponent } from './components/dynamicdialogs/createnew-template/createnew-template.component';
import { GridAdminComponent } from './components/features/grid-admin/grid-admin.component';
import { UpdateTableComponent } from './components/dynamicdialogs/update-table/update-table.component';
import { ResizeImageService } from './services/resize-image.service';
import { LoginFormComponent } from './features/mobile/login-form/login-form.component';
import { RegisterFormComponent } from './features/mobile/register-form/register-form.component';
import { NotifProComponent } from './components/dynamicdialogs/notif-pro/notif-pro.component';
import { VerifiEmailComponent } from './features/mobile/verifi-email/verifi-email.component';
import { ResetPasswordComponent } from './features/mobile/reset-password/reset-password.component';
import { QuestionComponent } from './components/question/question.component';
import { GdriveConvertPipe } from './pipes/gdrive-convert.pipe'
import { NgxScannerQrcodeModule, LOAD_WASM } from 'ngx-scanner-qrcode';
import { ShepherdComponent } from './components/shepherd/shepherd.component';
import { SummaryComponent } from './features/mobile/summary/summary.component';
import { CartComponent } from './features/mobile/cart/cart.component';
import { TimeStringPipe } from './pipes/time-string.pipe';
import { DateStringPipe } from './pipes/date-string.pipe';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MapComponent } from './features/mobile/map/map.component';
import { NumberStringPipe } from './pipes/number-string.pipe';

LOAD_WASM().subscribe((res: any) => console.log('LOAD_WASM', res));


const gapiClientConfig: NgGapiClientConfig = environment.googleapis;
let firebase: any = {};
if (environment.status == 'dev') {
  firebase = {
    apiKey: "AIzaSyA_DkX0qh3cFAFhmTOlznL7qx4zAusUwi8",
    authDomain: "dev-fibr.firebaseapp.com",
    projectId: "dev-fibr",
    storageBucket: "dev-fibr.appspot.com",
    messagingSenderId: "308692921820",
    appId: "1:308692921820:web:201e323569808aca7ab319",
    measurementId: "G-00R75TSJKP"
  }
} else {
  firebase ={
    apiKey: "AIzaSyD5lVZr-aAoLD1gxRPFPQ0GTYrENsxLIYM",
    authDomain: "prod-fibr.firebaseapp.com",
    projectId: "prod-fibr",
    storageBucket: "prod-fibr.appspot.com",
    messagingSenderId: "1026420756455",
    appId: "1:1026420756455:web:4e23b6e269a2161247f742",
    measurementId: "G-FVSH7DNKT1"
  }
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin
]);

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, HttpClientModule,
    //primeng
    AutoCompleteModule,
    PanelModule, 
    MessageModule, 
    DialogModule,
    ButtonModule,
    ConfirmDialogModule,
    ToastModule,
    TableModule,
    CheckboxModule,
    MenubarModule,
    ToolbarModule,
    InputTextModule,
    CardModule,
    ChartModule,
    ProgressBarModule,
    DividerModule,
    BreadcrumbModule,
    PanelMenuModule,
    FileUploadModule,
    MenuModule,
    TabMenuModule,
    DynamicDialogModule,
    StepsModule,
    OverlayPanelModule,
    SidebarModule,
    AccordionModule,
    VirtualScrollerModule,
    ScrollPanelModule,
    SelectButtonModule,
    DropdownModule,
    DataViewModule,
    OrderListModule,
    TimelineModule,
    RatingModule,
    BlockUIModule,
    CalendarModule,
    InputNumberModule,
    ProgressSpinnerModule,
    TreeModule,
    ContextMenuModule,
    FieldsetModule,
    TreeTableModule,
    RadioButtonModule,
    MultiSelectModule,
    EditorModule,
    ColorPickerModule,
    TabViewModule,
    ChipsModule,
    TooltipModule,
    InputMaskModule,
    TooltipModule,
    GalleriaModule,
    InputSwitchModule,
    BadgeModule,
    // AngularFireModule.initializeApp(environment.firebase),
    AngularFireModule.initializeApp(firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    GetDownloadURLPipeModule,
    QRCodeModule,
    NgxScannerQrcodeModule,
    GoogleApiModule.forRoot({
      provide: NG_GAPI_CONFIG,
      useValue: gapiClientConfig
    }),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'id',
      isolate: false,
      extend: true,
    }),
    CountdownModule,
    FullCalendarModule,
    LeafletModule,
    // FirebaseApp.messaging(),
    //import
  ],
  declarations: [
    FooterComponent,
    ModalComponent,
    ComfirmationComponent,
    LanguageComponent,
    ThemeComponent,
    ProfileComponent,
    HelpComponent,
    NotificationsComponent,
    BrandComponent,
    HorizontalmenuComponent,
    IconappsComponent,
    TitleComponent,
    RightActionButtonComponent,
    MiddleActionButtonComponent,
    MiddleActionButtonComponent,
    SearchComponent,
    HeaderComponent,
    LeftmenuComponent,
    SubheaderComponent,
    ToastNotificationComponent,
    GridComponent,
    GridApiComponent,
    LinkcolumnPipe,
    BreadcrumbComponent,
    CountdatabooleanPipe,
    SafePipe,
    CreatenewComponent,
    GlobalComponent,
    MobileComponent,
    BottommenuComponent,
    HeaderMobileComponent,
    GridMobileComponent,
    ListComponent,
    InjectDirective,
    DataviewComponent,
    Dataview2Component,
    NotFoundComponent,
    KiloformaterPipe,
    PrivacyPolicyComponent,
    BlockuiComponent,
    AddColumnsComponent,
    AddTableComponent,
    FormComponent,
    PageDetailsComponent,
    WelcomeComponent,
    UserProfileComponent,
    GridFirebaseComponent,
    CodeDescPipe,
    StripTagsPipe,
    ElipsisMeDirective,
    CountdownTimerComponent,
    FullCalendarComponent,
    EditColumnsComponent,
    FileDragNDropDirective,
    TooltipDirective,NotifHtmlComponent,
    TooltipComponent, TooltipContainerDirective, ForTooltipComponent, 
    CreatenewBlankComponent, InAppPaymentApprovalComponent, CreatenewOptionComponent, DetailProjectComponent, 
    CreatenewTemplateComponent, GridAdminComponent, UpdateTableComponent, LoginFormComponent, RegisterFormComponent, NotifProComponent, 
    VerifiEmailComponent, ResetPasswordComponent, QuestionComponent, GdriveConvertPipe, 
    ShepherdComponent, SummaryComponent, CartComponent, TimeStringPipe, DateStringPipe, MapComponent, NumberStringPipe,
    //
  ],
  exports:[
    UserProfileComponent,
    FooterComponent,
    ModalComponent,
    ComfirmationComponent,
    LanguageComponent,
    ThemeComponent,
    ProfileComponent,
    PrivacyPolicyComponent,
    HelpComponent,
    NotificationsComponent,
    BrandComponent,
    HorizontalmenuComponent,
    IconappsComponent,
    TitleComponent,
    RightActionButtonComponent,
    MiddleActionButtonComponent,
    MiddleActionButtonComponent,
    SearchComponent,
    HeaderComponent,
    LeftmenuComponent,
    SubheaderComponent,
    ToastNotificationComponent,
    GridComponent,
    GridApiComponent,
    GetDownloadURLPipe,
    CountdatabooleanPipe,
    SafePipe,
    InjectDirective,
    DataviewComponent,
    Dataview2Component,
    NotFoundComponent,
    KiloformaterPipe,
    BlockuiComponent,
    AddTableComponent,
    FieldsetModule,
    TranslateModule,
    ColorPickerModule,
    TabViewModule,
    ChipsModule,
    TooltipModule,
    GalleriaModule,
    InputSwitchModule,
    BadgeModule,
    QuestionComponent,

    //mobile
    MobileComponent,
    HeaderMobileComponent,
    BottommenuComponent,
    GridMobileComponent,

    AutoCompleteModule, 
    PanelModule, 
    MessageModule, 
    DialogModule,
    ButtonModule,
    ConfirmDialogModule,
    ToastModule,
    TableModule,
    CheckboxModule,
    MenubarModule,
    ToolbarModule,
    InputTextModule,
    CardModule,
    ChartModule,
    ProgressBarModule,
    DividerModule,
    BreadcrumbModule,
    PanelMenuModule,
    FileUploadModule,
    MenuModule,
    TabMenuModule,
    DynamicDialogModule,
    StepsModule,
    OverlayPanelModule,
    SidebarModule,
    AccordionModule,
    VirtualScrollerModule,
    ScrollPanelModule,
    SelectButtonModule,
    DropdownModule,
    DataViewModule,
    OrderListModule,
    TimelineModule,
    RatingModule,
    BlockUIModule,
    CalendarModule,
    InputNumberModule,
    TreeModule,
    ContextMenuModule,
    TreeTableModule,
    RadioButtonModule,
    GridFirebaseComponent,
    GridAdminComponent,
    ProgressSpinnerModule,
    MultiSelectModule,
    CountdownTimerComponent,
    CountdownModule,
    EditorModule,
    FullCalendarComponent,
    ShepherdComponent,
    //exports
  ],
  providers: [MessageService, ConfirmationService, DialogService, ToastNotificationService, DynamicDialogConfig, 
    TitleCasePipe, LowerCasePipe, DateHelperService, DatePipe, TranslateStore]
})
export class SharedModule {}
