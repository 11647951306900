import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';

@Component({
  selector: 'ui-fibr-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss']
})
export class CountdownTimerComponent {
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent | undefined;

  @Input() config: CountdownConfig = { leftTime: 60 * 30, format: 'HH:mm:ss.S', demand: true };
  
  begin(){
    this.countdown?.begin();
  }
}
