import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalcrudService, GlobalService, ToastNotificationService } from '@core-fibr/shared';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'ui-fibr-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {
  userProfile: any = {};
  editProfile: any ={}
  value!: number;
  selectTeamOptions: any;
  selectAppOptions: any;

  @Output() saveQuesioner: EventEmitter<any> = new EventEmitter<any>();
    
  // teamOptions: any[] = [
  //     { name: 'App Creator', value: 1, description: "Creators & developers who develop apps for their clients " },
  //     { name: 'Business Owner', value: 2, description: "Business owner who would like to have app to make their business easier" },
  //     { name: 'Student', value: 3, description: "Student who would like to explore or create apps for personal use" },
  //     { name: 'Personal Use', value: 5, description: "Hobbyist & people who wanna create apps for personal use" },
  //     { name: 'Others', value: 4, description: "Others" }
  // ];
  teamOptions: any[] = [];
  appOptions: any[] = [];

  // appOptions: any[] = [
  //     { name: 'Survey & form', value: 1 },
  //     { name: 'Inventory', value: 2 },
  //     { name: 'Team directory & resources', value: 3 },
  //     { name: 'Food business', value: 4 },
  //     { name: 'School management', value: 5 },
  //     { name: 'Property', value: 6 },
  //     { name: 'Art & style', value: 7 },
  //     { name: 'Hospitality', value: 8 },
  //     { name: 'Others', value: 9 }
  // ];

  constructor(private globalService: GlobalService, private toastNotificationService : ToastNotificationService,
    private globalCrudService: GlobalcrudService,private router: Router, private translate: TranslateService,) { }

  ngOnInit(): void {
    this.userProfile = this.globalService.getCurrReffDataParsed('user_profile');
    // if (!this.userProfile?.teamOptions) this.userProfile.teamOptions = []; else this.userProfile?.teamOptions;
    // if (!this.userProfile?.appOptions) this.userProfile.appOptions = []; else this.userProfile?.appOptions;
    if (!this.userProfile?.teamOptions) this.userProfile.teamOptions = []; else this.userProfile.teamOptions = [];
    if (!this.userProfile?.appOptions) this.userProfile.appOptions = []; else this.userProfile.appOptions = [];
    if (!this.userProfile?.noHp) this.userProfile.noHp = ''; else this.userProfile?.noHp;
    if (!this.userProfile?.kodeReferal) this.userProfile.kodeReferal = ''; else this.userProfile?.kodeReferal;
    this.translate.get(['personal_use', 'others','app_creator','business_owner','student','survey_form','inventory','team_directory',
    'food_business','school_management','property','art_style','hospitality','sales_transaction','donation','rental']).subscribe(translations => {
      this.teamOptions = [
        { name: translations['app_creator'], value: 1, description: "Creators & developers who develop apps for their clients " },
        { name: translations['business_owner'], value: 2, description: "Business owner who would like to have app to make their business easier" },
        { name: translations['student'], value: 3, description: "Student who would like to explore or create apps for personal use" },
        { name: translations['personal_use'], value: 5, description: "Hobbyist & people who wanna create apps for personal use" },
        { name: translations['others'], value: 4, description: "Others" }
      ];
      this.appOptions = [
          { name: translations['survey_form'], value: 1 },
          { name: translations['inventory'], value: 2 },
          { name: translations['team_directory'], value: 3 },
          { name: translations['food_business'], value: 4 },
          { name: translations['school_management'], value: 5 },
          { name: translations['property'], value: 6 },
          { name: translations['art_style'], value: 7 },
          { name: translations['hospitality'], value: 8 },
          { name: translations['sales_transaction'], value: 9 },
          { name: translations['donation'], value: 10 }, 
          { name: translations['rental'], value: 11 },
          { name: translations['others'], value: 12 }
      ];
    });
  }

  onBlur() {
    this.validasiNoHp()
  }

  validasiNoHp() {
    const cekHp = this.globalService.replaceOnlyNumber(this.userProfile?.noHp)
    this.userProfile.noHp = cekHp;
  }

  saveQuestion() {
    const errVal = this.validasiQuestion();
    if (errVal != '') {
      this.toastNotificationService.showNotification('error', errVal);
    } else {
      this.editProfile.uid = this.userProfile.uid;
      this.editProfile.noHp = this.userProfile.noHp;
      this.editProfile.kodeReferal = this.userProfile.kodeReferal;
      this.editProfile.teamOptions = this.userProfile.teamOptions;
      this.editProfile.appOptions = this.userProfile.appOptions;
      this.editProfile.modify_date = new Date();
      this.saveQuesioner.emit({userProfile: this.editProfile, existProfile: this.userProfile });

      // this.globalService.changeBlockui(true);
      // const pathProfile = `/users/${this.userProfile.uid}/profile`;
      // console.log(this.userProfile)
      // this.userProfile.questionUser = true;
      // this.globalCrudService.modifyRefMergeCollection(this.userProfile, pathProfile, 'data');
      // this.globalService.setRefDataToLocalstorage("user_profile", this.userProfile);
      // setTimeout(() => {
      //   this.router.navigate(["/portal/project"], {});
      //   this.globalService.changeBlockui(false);
      // }, 2000);
    }
  }

  validasiQuestion() {
    let err = '';
    const cekHp = this.globalService.replaceOnlyNumber(this.userProfile?.noHp)
    const lengthHp = this.userProfile.noHp.length;
    // console.log(this.userProfile?.noHp, this.globalService.replaceOnlyNumber(this.userProfile?.noHp), lengthHp)
    if (this.userProfile?.noHp == '') err = 'Phone Number must be filled!'
    if (this.userProfile?.noHp != '' && (this.userProfile?.noHp != cekHp) ) err = 'Phone Number can only be numeric characters!'
    if (this.userProfile?.noHp != '' && (lengthHp < 9 || lengthHp > 15) ) err = 'Phone Number cannot be less than 9 and cannot be more than 15 characters!'
    if (this.userProfile?.teamOptions.length == 0) err = 'Team Work Options must be selected!'
    if (this.userProfile?.appOptions.length == 0) err = 'App Make Options must be selected!'

    return err;
  }

}
