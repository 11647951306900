import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'ui-fibr-update-table',
  templateUrl: './update-table.component.html',
  styleUrls: ['./update-table.component.scss']
})
export class UpdateTableComponent implements OnInit {

  fields: any = {
    oldName: '',
    newName: ''
  };

  constructor( public ref: DynamicDialogRef, public dynamicDialogConfig: DynamicDialogConfig) { }

  ngOnInit(): void {
    console.log(this.dynamicDialogConfig)
    this.fields.oldName = this.dynamicDialogConfig.data;
    this.fields.newName = this.dynamicDialogConfig.data;
  }

  cancelDialog(){
    this.ref.close(false);
  }

  doItDialog(){
    this.ref.close(this.fields);
  }

}
