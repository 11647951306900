import { Component, Input, OnInit } from '@angular/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from 'libs/auth/src/environments/environment';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'ui-fibr-horizontalmenu',
  templateUrl: './horizontalmenu.component.html',
  styleUrls: ['./horizontalmenu.component.scss']
})
export class HorizontalmenuComponent implements OnInit {
  @Input() items:MenuItem[] = [];
  @Input() srcImg!: string;
  @Input() userProfilePath= '/portal/config/user-profile';
  @Input() aksesFrom!: string;
  
  isNotif = environment.topbar.isNotif;
  isLanguage = environment.topbar.isLanguage;
  isHelp = environment.topbar.isHelp;
  isGreeting = environment.topbar.isGreeting;
  isProfile = environment.topbar.isProfile;
  
  constructor() {
    //
   }

  ngOnInit(): void {
    console.log();
  }

}
