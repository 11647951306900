
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Global {
}


export interface GridModelStyle {
    orientation?:string;
    column?:string;
    border?:string;
    shape?:string;
    style?:string;
    corners?:string;
    padding?:string;
}

export interface GridModelText {
    position?:string;
    fontsize?:string;
    aligment?:string;
    lettercase?:string;
}

export interface SectionDetails {
    type?:string;
    field?:string;
    header?:string;
    custom?:string;
    mandatoryField?:boolean;
    buttonProperty?: buttonProperty;
    parentMedia?:string; 
    childMedia?:string;
    configLocation?: configDetailLocation;
}

export interface configDetailLocation {
    latitude?: string;
    longitude?: string;
}

export interface sectionFilter {
    column?:string;
    condition?:string;
    value?:string;
    filterType?:string;
    filterDateType?:any;
}

export interface sectionSort {
    column?:string;
    sortType?:string;
}

export interface buttonProperty {
    type?:string;
    label?:string;
    url?:string;
    linkToPage?:string;
    relationWithField?:boolean;
    queryParam?: any;
    columnData?:string;
    foreignKey?:string;
    relationField?: any;
    // customForeignKey?:boolean;
    customValueField?:boolean;
    inAppPaymentAmountColumn?:string;
    inAppPaymentDescription?:string;
}

export interface pageProperty {
    pageId?: string;
    pageTitle?: string;
    pageIcon?: string;
    pagePadding?: string;
    pageImagePosition?: string;
    isMenu?:boolean;
    isShowAllData?:boolean;
    accessedRoles?: any;
    isLoginAsGuest?:boolean;
}

export interface layoutDesign {
    pageId?: string;
    layoutTemplate?: string;
    layoutStyle?: string | GridModelStyle;
    layoutText?: string | GridModelText;
    layoutSlider?: string;
    layoutPadding?: string;
    layoutImagePosition?: string;
    layoutPosition?: string;
    summaryType?: string;
}

export interface dataContent {
    pageId?: string;
    contentPadding?: string;
    contentImagePosition?: string;
    contentDataSource?: string;
    contentTemplate?: any;
    contentField?:any;
    header?:string;
    mainTitle?:string;
    description?:string;
    label?:string;
    images?:string;
    isImageUrl?: boolean;
    additionalImage?:string;
    additionalText?:string;
    additionalLabel?:string;
    details?:string;
    form?:any,
    linkToDetails?:string;
    sectionDetails?: SectionDetails[];
    filter?: sectionFilter[];
    isProfileBanner?: boolean;
    sort?: sectionSort[];
    isNumberOfLines?: boolean;
    pageInfo?: sectionPageInfo;
    isExternalDataSource?: boolean;
    accessible?: accessibleData;
    linkToDetailsConfig?: linkToDetailsConfig;
    configSummaryTable?: configSummaryTable;
}

export interface configSummaryTable {
    content?: contentSummary;
    value?: valueSummary[];
    filterFunction?: filterFunctionSummary[];
    sectionChart: sectionChartSummary[];
}

export interface filterFunctionSummary {
    column?: string;
    columnType?: string;
    label?: string;
    header?: string;
    // value?: any;
    // formula?: any;
}

export interface sectionChartSummary {
    chartType?: string;
    chartTitle?: string;
    chartConfig?: chartConfigSummary;
}

export interface chartConfigSummary {
    sourceColumn?: string;
    referenceColumn?: string;
    referenceType?: string;
    colX?: string;
    colXType?: string;
    colY?: string;
}

export interface valueSummary {
    label?: string;
    column?: string;
    formula?: any;
    columnType?: string;
}

export interface contentSummary {
    group?: childContent;
    sub_group?: childContent;
    child_group?: childContent;
}

export interface childContent {
    column?: string;
    sortType?: string;
}


export interface linkToDetailsConfig {
    pageId?: string;
    relationWithField?: boolean;
    relationField?: string;
    foreignKey?: string;
    url?: string;
    queryParam?: string;
}

export interface accessibleData {
    type?: string;
    specificUserColumn?: string;
}

export interface configHiddenField {
    type?: string;
    uniqueType?: string;
    startFrom?:any;
    column?: string;
    staticValue?: string;
    prefix?:any;
    digit?:any;
    relation?: relationHiddenField;
    calculation?: calculationHiddenField;
    location?: locationHiddenField;
}

export interface locationHiddenField {
    latitude?: string; longitude?: string;
}
export interface relationHiddenField {
    type?: string; source?: string; columns?: fieldsLopp; propertyColumn?: any;
}

export interface calculationHiddenField {
    operation?: string; source?: string; foreignKey?: string, sourceColumn?: string; propertyColumn?: any; primaryKey?: string;
}

export interface formContent {
    field?:string;
    fieldType?:string;
    type?:string;
    label?:string;
    validations?:string;
    values?:any;
    data_type?:string;
    source?:string;
    checkbox_max?:any;
    extension?:any;
    content?:string;
    sourceColumn?:string;
    isExternalSource?: any;
    configHiddenField?: configHiddenField;
    qrConfig?: qrConfig;
    configCascading?: configCascading;
    configCalculation?: any;
}

export interface configCascading {
    parent?:parentCascading; child?:childCascading[];
}

export interface parentCascading {
    contentDataSource?:any; sourceColumn?:any;
}

export interface childCascading {
    label?: string; sourceColumn?: string; targetColumn?: string;
}

export interface qrConfig {
    status?: boolean; source?: any; sourceColumn?: any; type?: string; fields?: fieldsLopp[]; propertyColumn?: any;
}

export interface fieldsLopp {
    targetColumn?: string; sourceColumn?: string;
}

export interface formNotif {
  status?:boolean;
  add?: formNotifAction;
  edit?: formNotifAction;
  delete?: formNotifAction;
}

export interface formNotifAction {
  status?:any;
  roles?: any;
  toSelf?: any;
  title?: any;
  message?: any;
}

// export interface buttonDetailProperty {
//     title?:string;
// }

// export interface buttonFormProperty {
//     cancel?:buttonDetailProperty;
//     save?: buttonDetailProperty;
// }

export interface formConfiguration {
    isEnable?:boolean;
    add?:boolean;
    edit?:boolean;
    delete?:boolean;
    formContent?: formContent[];
    notif?: formNotif;
    button?: any;
}

export interface configMobile {
    pageProperty?: pageProperty;
    layoutDesign?: layoutDesign;
    dataContent?: dataContent;
    formConfiguration?: formConfiguration;
    cartConfig?: cartConfig;
}

export interface appConfig {
    app_id?: string;
    app_name?: string;
    app_icon?: string;
    file_id?: string;
    file_name?:string;
    build_number?: number;
    is_publish?: boolean;
    is_suspend?: boolean;
    version?: string;
    config?:string;
    sheetsName?:any[];
    created_date?: Date;
    updated_date?: Date;
    app_desc?: string;
    package_name?: string;
    app_type?: string;
    publish_option?: string;
    app_logo?: string;
    primary_color?: string;
    secondary_color?: string;
    font_color?: string;
    splash_screen?: string;
    login_registration?: string;
    broadcast_title?: string | undefined;
    broadcast_content?: string | undefined;
    is_paypwa?: boolean;
    is_payapps?: boolean;
    category?: string;
    created_by?: string;
    is_paid?: boolean;
    max_rows?: number;
    max_tables?: number;
    max_pages?: number;
    max_upload?: number;
    is_login?: boolean;
    quota?: number;
    email?: string;
    is_process_status?: any;
    published_date?: any;
    uid?: string;
    isDelete?: boolean;
    number_registered?: number;
    number_logged?: number;
    fullName?: string;
    phoneNumber?: string;
    upgrade_pro_date?: any;
    membership_expired_date?: any;
    traffic?: number;
    traffic_app?: number;
    traffic_pwa?: number;
    is_publish_pwa?: boolean;
    max_storage?: number;
    publish_pwa_date?: any;
    is_role?: boolean;
    status_project?: any;
    is_registration?: boolean;
    gsheet_connection?: any;
    component?: any;
    paymentConfig?: any;
    is_login_guest?: boolean;
    roles_config?:any;
    users_config?:any
}
export interface historyPublish {
    date_publish?: string;
    version?: string;
    app_type?: string;
    publish_at?: string;
}

export interface DataViewModel {
    url?: string;
    sortField?: string;
    sortKey?: string;
    sortOrder?: number;
    routerlink?:string;
    data?: any;
    params?: any;
    dataViewField?:any;
}

export interface DataViewModel2 {
    url?: string;
    sortField?: string;
    sortKey?: string;
    sortOrder?: number;
    routerlink?:string;
    data?: any;
    params?: any;
    dataViewField?:any;
}

export interface sectionPageInfo {
  status?: boolean;
  label?: string;
  group?: pageInfoGroup;
  sum?: pageInfoSum;
}

export interface pageInfoGroup {
  column?: string;
}

export interface pageInfoSum {
  column?: string;
}

export interface DataLayer{
    event?:string,
    category?:string,
    action?:string,
    label?: string,
    value?:any
}

export interface cartConfig{
    startOfPage?:startOfPage,
    cartContent?:cartContent,
    pricingCalculation?:pricingCalculation[],
    inAppPayment?:boolean,
    inAppPaymentConfig?:cartInAppPaymentConfig
}

export interface startOfPage{
    isLogin?:boolean,
    userInputLabel?:string
}

export interface cartContent{
    targetDataSource?:string,
    columns?:cartColumn
}

export interface cartColumn{
    itemName?:cartColumnItem,
    itemPrice?:cartColumnItem,
    itemDescription?:cartColumnItem,
    itemPicture?:cartColumnItem
}

export interface cartColumnItem{
    source?:string,
    columnType?:string,
    target?:string
}

export interface cartInAppPaymentConfig{
    amountColumn?:string,
    description?:string,
    usingCashPayment?:any
}

export interface pricingCalculation{
    label?:string,
    percentage?:number,
    calculate?:string,
    column?: string
}


