/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-empty */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PrimeNGConfig, FilterMatchMode, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { GridModel, GridColumnModel } from '../../../clases/global';
// import { jsPDF } from "jspdf";
// import { autoTable } from "jspdf-autotable";
import { HttpClient } from '@angular/common/http';
import { environment } from 'libs/auth/src/environments/environment';
import { GlobalService } from '../../../services/global.service';
import { GlobalcrudService } from '../../../services/crud/globalcrud.service';
import { AuthenticationService } from '@core-fibr/auth';
import { GoogleAuthService } from 'ng-gapi';
import { DynamicdialogService } from '../../../services/dynamicdialog.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AddColumnsComponent } from '../../dynamicdialogs/add-columns/add-columns.component';
import { GlobalCrudPromisesService } from '../../../services/crud/global-crud-promises.service';
import { validateEventsArray } from '@angular/fire/firestore';

@Component({
  selector: 'ui-fibr-grid-firebase',
  templateUrl: './grid-firebase.component.html',
  styleUrls: ['./grid-firebase.component.scss']
})
export class GridFirebaseComponent implements OnInit, AfterViewInit {
  @ViewChild('table') table: Table | undefined;
  @Input() tableId!:string;
  @Input() refreshGrid: boolean | undefined;
  @Input() isMockData = false;
  @Input() isArrayData = false;
  @Input() isOpenInModal = false;
  @Input() columns: any[]= [];
  @Input() gridModel: GridModel = new GridModel();
  @Input() Filters = [];
  @Input() isfirebase = false;
  @Input() isGoogleFiles = false;
  @Input() params:any;
  @Input() dataArray:any[] = [];

  @Output() fetchData = new EventEmitter<any>();
  @Output() dataSelect = new EventEmitter<any>();
  @Output() refreshTableGrid = new EventEmitter<any>();
  @Output() removeTableGrid = new EventEmitter<any>();
  @Output() addTableGrid = new EventEmitter<any>();
  @Output() showUpload = new EventEmitter<boolean>();
  @Output() isDownload = new EventEmitter<boolean>();
  @Output() removeMultipleRow = new EventEmitter<any>();
  @Output() isSubmit = new EventEmitter<boolean>();

  public isLoading = false;
  public paginator = true;
  public showCurrentPageReport = true;
  public isVisible = true;
  showFilterRow = false;
  public multipleSelection: any = [];
  isMultipleSelection = false;
  @Output() multipleSelectionReturn = new EventEmitter<any>();

  // change data to use getter and setter
  public data: any;
  private datasource: any = [];
  private _gm: GridModel | undefined;
  matchModeOptions: SelectItem[] = [];
  lastLazyLoadEvent: any | undefined;
  additionalColumns: any[] = [];
  parameterColumns: any[] =[];
  currReffDataParsed: any[] | undefined;
  selectData: any = {}
  globalFilter = '';

  public currentPageReportTemplate = 'Showing {first} to {last} of {totalRecords} entries';
  public rowsPerPage = 10;
  public rowsPerPageOptions = [10, 25, 50];
  public totalRecords = 0;
  emptyTemplateColSpan = 0;
  currentPage = 0;
  first=0;

  body:any;
  static initColumns(columnList: (GridColumnModel)[] | undefined): any[] | undefined {
    // TODO: apply translations to headers using i18n?
    return columnList;
  }

  constructor(private primengConfig: PrimeNGConfig, private http: HttpClient, 
    private globalService: GlobalService, private globalCrudService : GlobalcrudService,
    private authService: AuthenticationService, private googleAuthService : GoogleAuthService,
    private dynamicDialogService : DynamicdialogService, public dynamicDialogConfig: DynamicDialogConfig,
    private cdr: ChangeDetectorRef, private globalCrudPromisesService : GlobalCrudPromisesService,) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.primengConfig.ripple = true;    

    this._gm = new GridModel();
    if (this.gridModel?.RowsPerPageOptions != null) {
      if (this.gridModel.RowsPerPageOptions.length > 0) {
        this.rowsPerPageOptions = this.gridModel.RowsPerPageOptions;
        this.rowsPerPage = this.rowsPerPageOptions[0];
      }
    }

    this.matchModeOptions = [
      { label: 'Equals', value: FilterMatchMode.EQUALS },
      { label: 'Not Equals', value: FilterMatchMode.NOT_EQUALS },
    ];

    this.getAdditionalColumns();
    this.getParameterColumns();
  }

  ngAfterViewInit(){
    this.cdr.detectChanges();
  }

  getAdditionalColumns(): void {
    this.additionalColumns = [];
    for (let ii = 0; ii < this.columns.length; ii++) {
      const col = this.columns[ii];
      if (col.additional) {
        this.additionalColumns.push(col);
      }
    }
  }

  getParameterColumns(): void {
    this.parameterColumns = [];
    for (let ii = 0; ii < this.columns.length; ii++) {
      const col = this.columns[ii];
      if (col.parameter) {
        this.parameterColumns.push(col);
      }
    }
  }

  refresh() {
    this.lazyLoadData(this.lastLazyLoadEvent);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.refreshGrid?.currentValue) {
      this.refresh();
    }
  }

  lazyLoadData(event: any): void {
    this.lastLazyLoadEvent = event;
    this.isLoading = true;
    const body: any = {};

    if(this.isfirebase && this.gridModel.APIEndpoint != '' && !this.isArrayData){
      this.createLazyLoadBodyRequest(body, event);
      if(this.gridModel.APIEndpoint?.includes('sifa_staging') || this.gridModel.APIEndpoint?.includes('models')){
        if(body.filters.length > 0){
          this.globalCrudPromisesService
          .a_getSearchCollectionsByColumn(this.gridModel.APIEndpoint!, body)
          ?.then((results: any)=>{
            this.configurationGrid(results.docs, results.size);
          });
        }else if(this.globalFilter != ''){
          this.getfirebaseSearch(this.globalFilter, body);
        }else{
          this.globalCrudPromisesService
          .a_getCollections(this.gridModel.APIEndpoint!, body)
          ?.then((results: any)=>{
            this.configurationGrid(results, 0, true);
          });
        }
      }else{
        if(body.filters.length > 0){
          this.globalCrudPromisesService
          .getSearchCollectionsByColumn(this.gridModel.APIEndpoint!, body)
          ?.then((results: any)=>{
            this.configurationGrid(results.docs, results.size);
          });
        }else if(this.globalFilter != ''){
          this.getfirebaseSearch(this.globalFilter, body);
        }else{
          this.globalCrudPromisesService
          .getCollections(this.gridModel.APIEndpoint!, body)
          ?.then((results: any)=>{
            this.configurationGrid(results);
          });
        }
      }
      // if(body.Filters.length == 1) {
      //   const el = body.Filters[0];
      //   if(el.MatchMode == 'equals')this.getfirebaseSearchEqual(el.Value, el.PropertyName);
      //   else if(el.MatchMode == 'notEquals') this.getfirebaseSearchNotEqual(el.Value, el.PropertyName)
      // }if(body.SortField.length != '') {
      //   this.getfirebaseOrderBy(body.SortField, body.SortOrder == 1 ?'asc': 'desc' );
      // }
      // else this.getfirebase();
    }else if(this.isGoogleFiles&& this.gridModel.APIEndpoint != '' && !this.isArrayData){
      // console.log('kesini-b', this.gridModel)
      if(this.authService.getTokenGoogleSheet() != ''){
        this.getGoogleSheets();
      }else{
        this.googleAuthService.getAuth()
        .subscribe((auth:any) => {
           auth.signIn().then((res:any) => {
            this.authService.signInSuccessGoogleSheetsHandler(res);
            this.getGoogleSheets();          
          });
        });
      }
    }else{
      // console.log('kesini-c', this.gridModel)
      this.gridModel.Data = this.dataArray??[];
      this.totalRecords = this.dataArray?.length?? 0;
      this.isLoading = false;
    }
  }

  getfirebase(){
    let count = 0;
    this.globalCrudService.get_id_dynamicColection(this.gridModel.APIEndpoint!)
      .subscribe((results: any)=>{
        if (results != null) {
          this.datasource = results;
          if([environment.apiEndpoint].includes(this.gridModel.APIEndpoint!)){
            results.forEach((doc: any) => {
              let obj: any = {};
              obj['id'] = doc.id;
              this.gridModel.Data.push(obj);
          });
          this.paginator = true;
          }else{
            results.forEach((doc: any) => {
                let obj = doc.data();
                if(count == 0 && this.columns.length == 0){
                  this.columns = this.makingTableColumns(obj);
                }
                obj['id'] = doc.id;
                this.gridModel.Data.push(obj);
                if((count === (this.totalRecords - 1)) && this.gridModel.isAddBlankRow){
                  this.addBlankRow(obj);
                }
                count++;
            });
          this.paginator = false;
          }
          this.fetchData.emit(this.gridModel.Data);
        }
        this.totalRecords = this.gridModel.Data.length;
        const linkColumns = this.columns.filter(el => el.type?.toLowerCase() === 'link');
        this.isLoading = false;
      });
  }

  getGoogleSheets(){
    const _this = this;
    _this.columns = [
      { "type": "icon", "header" : "Type" },
      { "field" : "name", "header" : "Filename", "sortable" : true, "filterable": true },
      { "type":"button2", "header" : "Actions", "field": [  {"Title": "Select"} ] }
    ];
    
    gapi.load('client', () => {
      const options: gapi.client.RequestOptions = {
          path: `/drive/v3/files?q=mimeType='application/vnd.google-apps.spreadsheet'`,
          method: 'GET',
          params: {},
          body: {}
      };

      const request: gapi.client.HttpRequest<any> = gapi.client.request(options);
      request.execute((result: any) => {
          if (result != null) {
            _this.fetchData.emit(result);
            _this.totalRecords = result.files.length;
            _this.gridModel.Data = result.files;
          }
          const linkColumns = _this.columns.filter(el => el.type?.toLowerCase() === 'link');
          _this.isLoading = false;
          _this.cdr.detectChanges();
      });
    });
  }

  fetchMockData(): Observable<any> {
    const url: any = this.gridModel.APIEndpoint? this.gridModel.APIEndpoint : "./assets/json/projectlist.json";
    return this.http.get(url);
  }

  makingTableColumns(topOneResult: any){
    let columns: any[] = [];
    Object.keys(topOneResult).map((key : string) => {
      if(key != 'id') columns.push({ "field" : key, "header" : key, "sortable" : true, "filterable": true , type: this.getType(key) })
    });
    return columns;
  }

  getType(key: string){
    let result;
    if((key.toLowerCase()).includes('date')) result = 'date';
    else if( key.toLowerCase() == 'foto' || (key.toLowerCase()).includes('avatar') || (key.toLowerCase()).includes('image')) result = 'image'
    return result
  }

  getSortOrder(event:any){
    let val;
    if(event?.sortField) val = event?.sortOrder == 1? 'asc' : 'desc'
    else val = this.gridModel?.SortDir ?? 'desc';
    return val;
  }

  createLazyLoadBodyRequest(body: any, event: any): void {
    if( Object.keys(this.gridModel?.GridId).length > 0) body['whereField'] = [];
    Object.keys(this.gridModel?.GridId).forEach(item => {
      body[item] = this.gridModel.GridId[item];
      if(Object.keys(this.gridModel?.GridId).length > 1) body['whereField'].push(item);
      else body['whereField'] = item;
    })
    //body.AggregateIdentifier = this.gridModel.GridId; //this.gridModel.AggregateIdentifier;
    body.first = event?.first??0;
    body.rows = event?.rows??10;
    body.sortField = event?.sortField ?? (this.gridModel.SortBy?? 'id');
    body.sortOrder =this.getSortOrder(event);
    body.filters = [];

    // handle empty filter
    if (event == null || Object.keys(event?.filters).length <= 0) {

    } else {
      for (let ii = 0; ii < Object.keys(event?.filters).length; ii++) {
        const item = Object.keys(event?.filters)[ii];
        if(event?.filters[item].value != null){
          const newFilter = {
            propertyName: item,
            value: event?.filters[item].value,
            matchMode: event?.filters[item].matchMode
          }
          if(event?.filters[item].value != '') body.filters.push(newFilter);
        }
      }
    }
    // set input filters as body filters
    if (this.Filters.length > 0) {
      body.filters = this.Filters;
    }
    this.body = body;
  }

  onButtonActionClick(button: any, data: any): void {
    this.fetchData.emit({ event: button, id: data[button.ForeignKey], type: 'buttonAction', data: data });
  }

  onButtonActionClick2(button: any, data: any): void {
    this.dataSelect.emit({ event: button, id: data[button.ForeignKey], type: 'buttonAction', data: data });
  }

  onButtonLinkActionClick(button: any, data: any): void {
    const navigationLink = this.createNavigationLink(button, data);

    this.fetchData.emit({ event: button, data: data, navigationLink: navigationLink, type: 'buttonLinkAction' })
  }

  createNavigationLink(button: any, data: any): string {
    let navLink = '';
    if (button.linkObject != null) {
      if (button.linkObject.NavigationParam != null) {
        navLink += '/';
        for (let ii = 0; ii < button.linkObject.NavigationParam.length; ii++) {
          const item = button.linkObject.NavigationParam[ii];
          navLink += data[item];
          if (ii !== button.linkObject.NavigationParam.length - 1) {
            navLink += '/'
          }
        }
      }
    }
    return navLink;
  }

  checkArray(data: any): boolean {
    return Array.isArray(data);
  }

  checkIsString(data: any): boolean {
    return (typeof data == 'string')
  }

  loadData(): void {
    this.fetchData.emit(this._gm)
    this.isLoading = true;
  }

  exportPdf(): void {
    // const doc = new jsPDF.default(0, 0);
    // doc.autoTable(this.table.columns.map(col => ({ title: col.header, dataKey: col.field })), this.data);
    // doc.save('products.pdf');
  }

  exportExcel(): void {
      import('xlsx').then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.data);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, 'products');
      });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
      import('file-saver').then(FileSaver => {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      });
  }

  addNewColumns(){
    this.dynamicDialogConfig = {
      header: 'Add New Colum',
      width: '500px',
      contentStyle: {"max-height": "500px", "overflow": "unset"},
      styleClass : 'dialog-no-footer create-new', 
      dismissableMask: true,
      baseZIndex: 10000,
      showHeader : true,
      closable : true,
      data: {
        isGoogleFiles: false
      }
    }
    this.dynamicDialogService.show(AddColumnsComponent, this.dynamicDialogConfig)
    .onClose.subscribe((res: any) => {
      if(res){
        this.removeTableGrid.emit();
        let data:any={};
        data[res['fieldName']] = res['value'];
        this.isLoading = true;
        this.datasource.forEach((doc: any) => {
          doc.ref.update(data);
        }); 
    
        setTimeout(() => {
          this.addTableGrid.emit({ data : this.gridModel.GridName, params: this.params});
          this.isLoading = false;
        }, 400);
      }
    });
  }

  updateGrid(data: any){
    let check = false;
    Object.keys(data).map((key: string) => {
      if(key != 'id') check ||= (data[key] == null || data[key] == ''? false : true)
    });
    if(data.id != null){
      if(check) {
        this.globalCrudService.modifyDynamicColection(data, this.gridModel.APIEndpoint!, data.id)
        .then((res:any) => {
          this.removeTableGrid.emit();
          setTimeout(() => {
            this.addTableGrid.emit({ data : this.gridModel.GridName, params: this.params});
          }, 300);
        });
      }else {
        this.globalCrudService.deleteDynamicCollection(this.gridModel.APIEndpoint!, data.id)
        .then((res:any) => {
          this.removeTableGrid.emit();
          setTimeout(() => {
            this.addTableGrid.emit({ data : this.gridModel.GridName, params: this.params});
          }, 300);
        });
      }
    }else{
      if(check) this.addDataRowGrid(data);
    }
    
  }

  addDataRowGrid(data: any){
    delete data.id;
    data['modify_date'] = new Date();
    this.globalCrudService.addDynamicCollection(data, this.gridModel.APIEndpoint!)
    .then((res:any) => {
      this.removeTableGrid.emit();
        setTimeout(() => {
          this.addTableGrid.emit({ data : this.gridModel.GridName, params: this.params});
        }, 300);
    });
  }

  addBlankRow(row: any){
    let nRow: any = {};
    Object.keys(row).map((key : string) => {
      nRow[key]= null;
    });
    this.gridModel.Data.push(nRow);
  }

  createNewRow(){
    this.fetchData.emit({ event: {Name: 'createnew', Title: "Tambah Data"}, data: {}, navigationLink: "", type: 'buttonAction' })
  }

  filterGlobal(value:any, type:any, body?:any){
    this.globalFilter = value;
    if(!body) body = { 
      filters: [],
      first : 0,
      sortOrder: this.gridModel.SortDir,
      sortField: this.gridModel.SortBy,
      rows: this.rowsPerPage
    }
    this.getfirebaseSearch(value, body);
  }

  getfirebaseSearch(value: any, body: any){
    if(this.gridModel.APIEndpoint?.includes('sifa_staging') || this.gridModel.APIEndpoint?.includes('models')){
      this.globalCrudPromisesService.a_getSearchCollections(this.gridModel.APIEndpoint!, body, value)
      .then((results: any)=>{
        this.configurationGrid(results.docs, results.size);
      })
    }else{
      this.globalCrudPromisesService.getSearchCollections(this.gridModel.APIEndpoint!, body, value)
      .then((results: any)=>{
        this.configurationGrid(results.docs, results.size);
      })
    }
  }

  getfirebaseSearchEqual(value: any, field: string){
    this.globalCrudService.get_search_equal_dynamicColection(this.gridModel.APIEndpoint!, value, `${field}_insensitive`)
    .subscribe((results: any)=>{
      this.configurationGrid(results);
    })
  }

  getfirebaseSearchNotEqual(value: any, field: string){
    this.gridModel.Data = [];
    this.globalCrudService.get_search_notequal_dynamicColection(this.gridModel.APIEndpoint!, value, `${field}_insensitive`)
    .subscribe((results: any)=>{
      this.configurationGrid(results);
    })
  }

  async configurationGrid(results: any, totalRecordsFromResults?:number, isAdmin?:boolean){
    let count = 0;
    this.gridModel.Data = [];
    this.totalRecords = 0;

    if(!isAdmin){
      if(Object.keys(this.gridModel?.GridId).length > 0){
        await this.globalCrudPromisesService.getCountCollectionWhereField(this.gridModel.APIEndpoint!, this.body)
        .then(x => this.totalRecords = totalRecordsFromResults? totalRecordsFromResults: x);

      }else{
        await this.globalCrudPromisesService.getCountCollection(this.gridModel.APIEndpoint!)
        .then(x => this.totalRecords = totalRecordsFromResults? totalRecordsFromResults: x);
      }
    } 

    if(isAdmin) await this.globalCrudPromisesService.a_getCountCollection(this.gridModel.APIEndpoint!)
    .then(x => this.totalRecords = x);
    
    if (results != null) {
        results.forEach((doc: any) => {
            let obj = doc.data();
            if(count == 0 && this.columns.length == 0){
              this.columns = this.makingTableColumns(obj);
            }
            obj['uid'] = doc.id;
            // this.totalRecords += 1;
            this.gridModel.Data.push(obj);
            if((count === (this.totalRecords - 1)) && this.gridModel.isAddBlankRow){
              this.addBlankRow(obj);
            }
            count++;
        });
      this.fetchData.emit(this.gridModel.Data);
    }
    const linkColumns = this.columns.filter(el => el.type?.toLowerCase() === 'link');
    this.isLoading = false;
  }

  uploadButton(){
    this.showUpload.emit(true);
  }

  downloadButton(){
    this.isDownload.emit(true);
  }

  removeButton(){
    this.removeMultipleRow.emit({table: '', data:[]});
  }

  public async getDataDate(date: any){
    const dateInMillis = await date.seconds * 1000;
    const val = await new Date(dateInMillis);
    return val;
  }

  getLabelClass(data:string, additional?:string){
    if(typeof data !== 'string') return ''
    const val = data.toLocaleLowerCase();
    let result = '';
    switch (val) {
      case 'belum verifikasi':
      case 'lunas':
        result = 'label-black'
        break;
      case 'menunggu konfirmasi':
        // result = 'label-ungu'
        result = 'p-button-help label'
        break;
      case 'on process':
        result = 'p-button-success label'
        break;
      case 'on delivery':
        result = 'p-button-success label'
        break;
      case 'delivered':
          result = 'p-button-success label'
          break;
      case 'process to lab':
        result = 'p-button-success label'
        break;
      case 'lab process':
        result = 'p-button-success label'
        break;
      case 'result ready':
        result = 'p-button-primary label'
        break;
      case 'selesai':
      case 'tidak aktif':
      case 'belum aktif':
      case 'menunggu bayar':
      case 'resep kosong':
      case 'blank':
      case 'draft':
        result = 'label-grey'
        break;
      case 'terkonfirmasi':
        result = 'label-telur-asin'
        break;
      case 'terjadwalkan':
      case 'sudah verifikasi':
      case 'terverifikasi':
      case 'resep terisi':
      case 'resep ditebus':
      case 'resep perlu ditebus':
      case 'terkirim ke client':
        result = 'label-biru-muda'
        break;
      case 'hadir':
      case 'aktif':
      case 'resep terkirim':
      case 'menunggu verifikasi':
      case 'paid':
        result = 'label-telur-asin-muda'
        break;
      case 'mulai konsultasi':
      case 'obat diantar':
        result = 'label-green'
      break;
      case 'tunggu antrian':
      case 'faskes':
      case 'menunggu jadwal':
      case 'resep diracik':
        if(!this.gridModel.APIEndpoint?.includes('roles')) result = 'label-ungu'
        break;
      case 'dibatalkan':
      case 'ditolak':
      case 'batal':
        result = 'label-red'
        break;
      case 'jadwal ulang':
      case 'unpaid':
        result = 'label-yellow'
        break;
      case 'jadwal':
        if(additional?.toLocaleLowerCase() === 'telekonsul') result = 'label-telur-asin-muda';
        if(additional?.toLocaleLowerCase() === 'janji temu' || additional?.toLocaleLowerCase() === 'janjitemu') result = 'label-biru-muda';
        break;
      default:
        result = 'p-button-danger label'
        break;
    }
    return result;
  }

  onRowSelects(event:any) {
    this.multipleSelection.push(event?.data);
    this.multipleSelectionReturn.emit(this.multipleSelection);
  }

  onRowUnSelects(event:any) {
    this.multipleSelection = this.multipleSelection.filter((x:any) => x.RecordId != event?.data.RecordId);
    this.multipleSelectionReturn.emit(this.multipleSelection);
    this.isMultipleSelection = false;
  }

  onRowMultipleSelect(event:any) {
    this.multipleSelection = this.isMultipleSelection ? this.gridModel.Data : [];
    this.multipleSelectionReturn.emit(this.multipleSelection);
  }

  submitEvent(){
    this.isSubmit.emit(true);
  }

}
