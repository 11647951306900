<div *ngIf="!showFullScreen" class="layout-main full m-0 hidden lg:flex"  [ngStyle]="{'background-image': 'url(' + appConfig.splash_screen + ')'}">
    <div class="layout-content flex">
        <div class="grid absolute" *ngIf="!isPaid">
            <div class="col text-center">
                <p class="text-xs">Powered by</p>
                <a href="https://appfibr.io/"><img src="/assets/logo_appfibr.svg" alt="AppFibr Logo" height="30"/></a>
                <!-- <p-button styleClass="p-button p-button-primary p-button-sm text-xs py-1 bg-dark-blue mt-2" label="Try Now"> </p-button> -->
            </div>
        </div>
        <div class="grid align-items-center justify-content-center w-100">
            <div class="col-12 lg:col-5 ml-auto align-self-center">
                <div class="list-item p-0 mb-3">
                    <div class="list-detail d-flex flex-row align-items-center">
                        <img alt="Project Image" class="img-template" [src]="appConfig.app_icon" onerror="this.src = './assets/iconic_logo.png'">
                        <div class="d-block">
                            <!-- <p>05/01/2023</p> -->
                            <h3 class="list-title m-0">{{appConfig.app_name}}</h3>
                        </div>
                    </div>
                </div>
                <p class="list-desc mb-5">{{appConfig.app_desc}}</p>
                <div class="flex">
                    <div class="m-auto w-100">
                        <p class="text-sm text-center">Share App with link</p>
                        <p-button (click)="copyLinkClick(linkApp)" styleClass="p-button p-button-primary w-100" label="Copy Link" icon="pi pi-link" iconPos="right" > </p-button>
                    </div>
                    <p-divider class="mx-5" layout="vertical">
                        <b>OR</b>
                    </p-divider>
                    <div class="m-auto w-100">
                        <p class="text-sm text-center">Scan QR Code</p>
                        <div class="card box inline-flex p-2">
                          <qrcode [qrdata]="linkApp" [errorCorrectionLevel]="'M'" [elementType]="'img'" [allowEmptyString]="true"></qrcode>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 lg:col-5 mr-auto">
                <div class="grid">
                  <div class="col-12">
                    <div class="footer-sticky" style="text-align: center;">
                      <button pButton pRipple label="Full Screen Mode" (click)="actShowFullScreen({'fullScreen': true})" class="p-button-info p-button-text"><img width="24" class="p-button-icon mr-2" src="/assets/images/icon/icon_maximize.svg"></button>
                    </div>
                  </div>
                    <div class="col-12 preview-app">
                        <div class="iphone">
                            <div class="content">
                                <ui-fibr-mobile *ngIf="itemPages.length > 0" 
                                  [config]="configMobile" [data]="data" [dataSummary]="dataSummary" [itemPages]="itemPages" [params]="paramater"
                                  [actFrom]="actFrom" [isPaid]="isPaid" [isLogin]="isLogin" [dataColumn]="columnData" [appConfig]="appConfig"
                                  (menuClick)="modifyPageMenu($event)" (menuClickWithParams)="modifyPageMenu($event)" 
                                  (backParentClick)="modifyPageMenu($event)"></ui-fibr-mobile>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="sticky-phone lg:hidden"> -->
<div class="sticky-phone" [ngClass]="!showFullScreen ? 'lg:hidden' : ''">
    <div (click)="actShowFullScreen({'fullScreen': false})" class="btn-fullscreen hidden lg:flex">
        <img class="mr-2" src="/assets/images/icon/icon_minimize.svg"> <span class="underline">Exit Full Screen Mode</span>
    </div>
    <div class="content">
        <ui-fibr-mobile *ngIf="itemPages.length > 0" [config]="configMobile" [data]="data" [dataSummary]="dataSummary" [itemPages]="itemPages" [params]="paramater"
            [actFrom]="actFrom" [isPaid]="isPaid" [isLogin]="isLogin" [dataColumn]="columnData" [appConfig]="appConfig" 
            (menuClick)="modifyPageMenu($event)" (menuClickWithParams)="modifyPageMenu($event)"
            (backParentClick)="modifyPageMenu($event)"></ui-fibr-mobile>
    </div>
</div>

<ui-fibr-blockui [blocked]="blocked"></ui-fibr-blockui>