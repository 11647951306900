import { Pipe, PipeTransform } from '@angular/core';
import { ReferenceDataService } from '../services/reference-data.service';

@Pipe({
  name: 'codeDesc'
})
export class CodeDescPipe implements PipeTransform {

  constructor(private sharedReferenceDataService: ReferenceDataService) { }

  transform(value: any, type: string): string {
    let codeDescription: string = this.sharedReferenceDataService.getDescriptionForReferenceType(type, value);
    if (typeof value == 'object') {
      codeDescription = '';
      if (value) {
        value.forEach((x:any) => {
          codeDescription = codeDescription == '' ? this.sharedReferenceDataService.getDescriptionForReferenceType(type, x)
            : codeDescription + ', ' + this.sharedReferenceDataService.getDescriptionForReferenceType(type, x);
        })
      }
    }
    return codeDescription;
  }

}
