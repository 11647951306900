<div class="login-body layout-light">
    <div class="login-content">
        <div class="night-switch">
            <div class="language-menu">
                <p-dropdown (onChange)="onLanguageChange()" [options]="country" [(ngModel)]="selectedLanguage" placeholder="{{'selectLanguage' | translate}}" optionLabel="name" [showClear]="true"></p-dropdown>
            </div>
        </div>
        <div class="login-panel">
            <div class="login-panel-content text-left">
                <div class="col login-header">
                  <div class="logo">
                    <img [src]="logo" alt="{{labelAlt}}" />
                  </div>
                  <h4 class="mb-2">{{'Fibr-portalLogin' | translate}}</h4>
                  <span>{{'WelcomeMessage' | translate}}</span>
                </div>
                <div class="col">
                  <div class="forms mt-0">
                      <div class="login-input-wrapper mb-3">
                        <label for="username">Username</label>
                        <input (keydown.enter)="onClickLoginUseFireEmailandPassword()" id="username" pinputtext="" [(ngModel)]="userName" placeholder="{{'placeholderUsername' | translate}}" class="p-inputtext p-component line-input py-3" />
                        <i class="pi pi-user"></i>
                      </div>
                      <div class="login-input-wrapper mb-3">
                        <label for="password">Password</label>
                        <input (keydown.enter)="onClickLoginUseFireEmailandPassword()" [type]="fieldTextType ? 'text' : 'password'" [(ngModel)]="password" id="password" placeholder="{{'placeholderPassword' | translate}}" ppassword="" class="p-inputtext p-component line-input py-3" />
                        <i [class]="fieldTextType? 'pi pi-eye': 'pi pi-eye-slash'" (click)="toggleFieldTextType()"></i>
                      </div>
                      <div class="grid center">
                        <div class="col-12 px-0 text-right">
                            <a href="javascript:;" class="secondary-button text-blue">{{'forgotPassword' | translate}}</a>
                        </div>
                      </div>
                      
                      <div class="buttons">
                          <button pbutton="" [disabled]="isProgressSpinner" type="button" label="Login" tabindex="0" class="col-12 py-3 p-button text-lg" (click)="onClickLoginUseFireEmailandPassword()"
                          [ngStyle]="{ 'background-color': primaryButtonColor}"
                          >
                            <span class="p-button-label">{{'Login' | translate}}</span>
                            <p-progressSpinner *ngIf="isProgressSpinner" strokeWidth="3" styleClass="progress-spinner-btn" [style]="{width: '20px', height: '20px'}" ></p-progressSpinner>
                          </button>
                      </div>
                  </div>
                </div>
            </div>
        </div>
        <div class="login-panel right"
          [ngStyle]="{
            'background-image': 'url(' + image_login + ')',
            'background-size': 'contain',
            'background-position': 'center',
            'background-repeat': 'no-repeat'}"
            >
          <div class="login-panel-content">
            <h4 class="mb-2">{{env.login_right.h4_admin | translate}}</h4>
            <p>{{env.login_right.p_admin | translate}}</p>
  
            <div class="mt-auto">
              <span>Copyright © 2023 <b [ngStyle]="{ 'color': primaryButtonColor, 'font-size': '1.5rem'}"></b>
                <img src="/assets/logo_fibr_footer.svg" height="14">
              </span>
            </div>
          </div>
        </div>
    </div>
</div>
