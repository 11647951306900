import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

export class ToastSeverityList {
  public static readonly Success = 'success';
  public static readonly Info = 'info';
  public static readonly Warn = 'warn';
  public static readonly Error = 'error';
}

export class ToastDefaultTitleList {
  public static readonly Success = 'Success';
  public static readonly Info = 'Info';
  public static readonly Warn = 'Warn';
  public static readonly Error = 'Error';
}

export class ToastResultMessageList {
  public static readonly NoResult = 'No result';
  public static readonly Cancel = 'Action cancelled';
}

@Injectable({
  providedIn: 'root'
})
export class ToastNotificationService {

  constructor(private messageService: MessageService) { }

  showNotification(severity: string = ToastSeverityList.Success, message: string, title?: string, life: number = 3000, sticky: boolean = false, closable: boolean = true): void {
    if (title == null) {
      const type = this.getKeyByValue(ToastSeverityList, severity);
      title = this.titleCaseWord(type);
    }
    this.messageService.add({
      severity: severity, summary: title, detail: message, life: life, sticky: sticky, closable: closable
    });
  }

  clear(): void {
    this.messageService.clear();
  }

  getKeyByValue(object: any, value: any): string  {
    return (Object.keys(object).find(key => object[key] === value)!).toString();
  }

   titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }
}
