/* eslint-disable no-debugger */
/* eslint-disable @angular-eslint/directive-selector */
import { Directive, HostListener, HostBinding, Output, EventEmitter, Input } from '@angular/core';

@Directive({
  selector: '[fileDragDrop]'
})

export class FileDragNDropDirective {
  //@Input() private allowed_extensions : Array<string> = ['png', 'jpg', 'bmp'];
  @Output() private filesChangeEmiter : EventEmitter<File[]> = new EventEmitter();
  //@Output() private filesInvalidEmiter : EventEmitter<File[]> = new EventEmitter();
  // @HostBinding('style.background') private background = '#eee';
  // @HostBinding('style.border') private borderStyle = '2px dashed';
  // @HostBinding('style.border-color') private borderColor = '#696D7D';
  // @HostBinding('style.border-radius') private borderRadius = '5px';

  constructor() { 
    
  }

  @HostListener('dragover', ['$event']) public onDragOver(evt: { preventDefault: () => void; stopPropagation: () => void; }){
    evt.preventDefault();
    evt.stopPropagation();
    // this.background = 'lightgray';
    // this.borderColor = 'cadetblue';
    // this.borderStyle = '3px solid';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: { preventDefault: () => void; stopPropagation: () => void; }){
    evt.preventDefault();
    evt.stopPropagation();
    // this.background = '#eee';
    // this.borderColor = '#696D7D';
    // this.borderStyle = '2px dashed';
  }

  @HostListener('drop', ['$event']) public onDrop(evt: { preventDefault: () => void; stopPropagation: () => void; dataTransfer: { files: any; }; }){
    evt.preventDefault();
    evt.stopPropagation();
    // this.background = '#eee';
    // this.borderColor = '#696D7D';
    // this.borderStyle = '2px dashed';
    // debugger;
    const files = evt.dataTransfer.files;
    const valid_files : Array<File> = files;
    this.filesChangeEmiter.emit(valid_files);
  }
}