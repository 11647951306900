/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, Input, OnInit, AfterContentInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core-fibr/auth';
import { MenuItem } from 'primeng/api';
import { GlobalService, SessionService } from "@core-fibr/shared";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ui-fibr-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, AfterContentInit {
  items: MenuItem[] = [];
  @Input() userProfilePath= '/portal/config/user-profile';
  @Input() userChangePasswordPath= '/portal/config/change-password';
  @Input() aksesFrom!: string;
  user: any = {};
  partner_name = "";

  constructor(
    private authService: AuthenticationService,
    public router: Router,
    private globalService: GlobalService,
    private translate: TranslateService,
    private sessionService: SessionService,
  ) { }

  ngOnInit(): void {
    // console.log(this.aksesFrom)
    this.translate.use(this.sessionService.getItem('fibr-language'));

    this.user = this.globalService.userprofile;
    this.partner_name = this.user.partner_name;
  }

  ngAfterContentInit(): void {
    this.translate.get([
      'profile.text',
      'profile.change_password',
      'profile.logout'
    ]).subscribe((trx) => {
      this.items = [
        {
          label: trx['profile.text'], 
          icon: 'pi pi-fw pi-user',
          command: () => {
            this.profile();
          }
        },
        {
          label: trx['profile.change_password'], 
          icon: 'pi pi-fw pi-lock',
          command: () => {
            this.changePassword();
          }
        },
        {
          label: trx['profile.logout'], 
          icon: 'pi pi-fw pi-sign-out',
          command: () => {
            this.logout();
          }
        }
      ];
    })
  }

  profile(){
    if (this.aksesFrom == 'admin-portal') this.userProfilePath = '/portal-admin/config/user-profile'; else this.userProfilePath;
    this.router.navigateByUrl(this.userProfilePath);
  }

  changePassword(){
    if (this.aksesFrom == 'admin-portal') this.userChangePasswordPath = '/portal-admin/config/change-password'; else this.userChangePasswordPath;
    this.router.navigateByUrl(this.userChangePasswordPath);
  }

  logout(){
    setTimeout(() => {
      localStorage.removeItem('user_profile');
    }, 1000);
    this.authService.clearAuthToken();
    this.authService.SignOutFire();
  }

}
