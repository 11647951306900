/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import { appConfig, configMobile, formContent, DataLayer } from '../interfaces/global';
import iconlist from '../../../assets/src/lib/json/material-icon-svg-list.json';

import tables from '../../../assets/src/lib/json/tables.json';
import chat_tables from '../../../assets/src/lib/json/sifa_chat_tables.json';
import vcall_tables from '../../../assets/src/lib/json/sifa_vcall_tables.json';
import queries from '../../../assets/src/lib/json/query.json';
import { Confirmation } from '../interfaces/confirmation';
import { TreeNode } from 'primeng/api';

// new sifa
import sifa_tables from '../../../assets/src/lib/json/sifa-v1-rev.json';
import reference_data from '../../../assets/src/lib/json/reference-data.json';
import _, { isNumber } from "lodash";
import { Options } from 'preact';
import { count } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import * as CryptoJS from 'crypto-js';
import { WindowReferenceService } from './window-reference.service';
import { DomSanitizer  } from "@angular/platform-browser";
import { ParserService } from './parser.service';
// import { environment } from '../../../../../libs/auth/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public isSubHeader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isApptEditStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isAppCreateNew: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isStatusLogin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isBlocked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public breadcrumb: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public appConfig: BehaviorSubject<appConfig> = new BehaviorSubject<appConfig>({});
  public confirmationConfig: BehaviorSubject<Confirmation> = new BehaviorSubject<Confirmation>({});
  public actions: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public chooseTable: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public statusProject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public isStatusPublish: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isStatusFullScreen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currReffDataParsed:any;
  private _referenceDataParameterList: any = {};
  private _iconList: any = {};
  public userProfile: any;
  public userProject: any;
  pipe = new DatePipe('en-US');
  private window;
  public columnGrid: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public columnGridAfterEdit: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public isSummaryFilter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isSummaryDownload: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public headerCart: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public disbursementHistory: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public pageMenuSelected: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public caseFeature: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public mapLocation: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public refreshScrollLazyLoad: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public notifDataHtml: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(private http: HttpClient, public _windowRef: WindowReferenceService,private sanitizer: DomSanitizer,
    private parser: ParserService,) { 
    this.isSubHeader.next(false);
    this.isBlocked.next(false);
    this.breadcrumb.next([]);
    this.window = _windowRef.nativeWindow();
  }

  private pingHome(obj:DataLayer) {
    if(obj)  this.window.dataLayer.push(obj);
  }

  logPageView(data:string) {
    const hit = {event: data};
    this.pingHome(hit);
  }

  get referenceDataParameterList() {
    return this._referenceDataParameterList;
  }

  set referenceDataParameterList(value) {
    this._referenceDataParameterList = value;
  }

  get iconList(){
    // const url: any = "./assets/json/iconlist.json"; // icon primeng
    // const url: any = "./assets/json/material-icon-list.json"; // icon primeng
    const url: any = "./assets/json/material-icon-svg-list.json"; // icon primeng
    return iconlist
  }

  get userprofile() {
    return this.userProfile;
  }

  get userproject() {
    return this.userProject;
  }

  get tables() {
    return tables;
  }

  get chatTables() {
    return chat_tables;
  }

  get vcallTables() {
    return vcall_tables;
  }
  
  get queries() {
    return queries;
  }

  get new_sifa_tables() {
    return sifa_tables;
  }

  get reference_datas() {
    return reference_data;
  }
  
  set userprofile(value) {
    this.userProfile = value;
  }

  set userproject(value) {
    this.userProfile = value;
  }

  get getAppConfig() {
    return this.appConfig;
  }  

  setProjectEditStatus(val: boolean){
    this.isApptEditStatus.next(val);
  }

  setShowFullscreen(val: boolean){
    this.isStatusFullScreen.next(val);
  }

  setProjectCreateNew(val: boolean){
    this.isAppCreateNew.next(val);
  }

  setStatusLogin(val: boolean){
    this.isStatusLogin.next(val);
  }

  changeSubHeaderShow(value:any) {
    this.isSubHeader.next(value);
  }

  changeBlockui(value:boolean) {
    this.isBlocked.next(value);
  }

  setStatusProject(val: string){
    this.statusProject.next(val);
  }

  changeTableItem(value:any) {
    this.chooseTable.next(value);
  }

  setStatusPublish(val: boolean) {
    this.isStatusPublish.next(val)
  }

  setColumnGrid(val: any){
    this.columnGrid.next(val);
  }

  setColumnGridAfterEdit(val: any){
    this.columnGridAfterEdit.next(val);
  }

  setSummaryFilter(val: any){
    this.isSummaryFilter.next(val);
  }

  setScrollLazyLoad(val: any){
    this.refreshScrollLazyLoad.next(val);
  }

  setHeaderCart(val: any){
    this.headerCart.next(val);
  }

  setSummaryDownload(val: any){
    this.isSummaryDownload.next(val);
  }

  setPageMenuSelected(val:any) {
    this.pageMenuSelected.next(val);
  }

  setCaseFeature(val:any) {
    this.caseFeature.next(val);
  }

  setMapLocation(val:any) {
    this.mapLocation.next(val);
  }

  setNotifDataHtml(val: any){
    this.notifDataHtml.next(val);
  }

  getCurrReffDataParsed(LOCAL_STORAGE_KEY: string = ''): any {
    let data = localStorage.getItem(LOCAL_STORAGE_KEY) as string;
    this.currReffDataParsed = JSON.parse(data);
    return this.currReffDataParsed;
  }

  getSessionCurrReffDataParsed(LOCAL_STORAGE_KEY: string = ''): any {
    let data = sessionStorage.getItem(LOCAL_STORAGE_KEY) as string;
    this.currReffDataParsed = JSON.parse(data);
    return this.currReffDataParsed;
  }

  setRefDataToLocalstorage(LOCAL_STORAGE_KEY: string, value: any):void{
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(value));
  }

  setSessionRefDataToLocalstorage(LOCAL_STORAGE_KEY: string, value: any):void{
    sessionStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(value));
  }

  setBreadcrumb(value: any){
    this.breadcrumb.next(value);
  }

  setAppConfig(value: any){
    this.appConfig.next(value);
  }

  setConfirmationConfig(value: Confirmation){
    this.confirmationConfig.next(value);
  }

  setParamDisbursementHistory(val: any){
    this.disbursementHistory.next(val);
  }

  getObjData(el: any){
    let obj:any = {};
    const array2: any = el.fields;
    for (let index = 0; index < array2.length; index++) {
      const ele = array2[index];
      switch (ele.data_type) {
        case "integer":
        case "numeric":
        case "smallint":
        case "bigint":
        case "double precision":
          obj[ele.column_name] = 0;
          break;
        case "boolean":
          obj[ele.column_name] = true;
          break;
        case "ARRAY":
          obj[ele.column_name] = [];
          break;
        case "date":
        case "timestamp with time zone":
        case "timestamp without time zone":
          obj[ele.column_name] = new Date();
          break;
        case "character":
        case "inet":
        case "character varying":
        case "text":
        case "json":
          obj[ele.column_name] = 'empty';
          break;
      }
    }
    return obj;
  }

  setDocumentObj(el: any){
    let obj:any = {};
    const array2: any = el.fields.sort();
    for (let index = 0; index < array2.length; index++) {
      const ele = array2[index];
      obj[ele.column_name] = {
        col_order : ele.col_order > -1? ele.col_order : index,
        field: ele.column_name,
        filterable: ele?.show_in_grid? true : false,
        form_order: index,
        header: ele.header? ele.header: ele.column_name,
        // header_id: ele.column_name,
        show_in_form: true,
        show_in_grid:  ele?.show_in_grid && ele?.show_in_grid !== null? ele.show_in_grid : false,
        sortable: ele?.show_in_grid? true : false,
        type: ele.web_input_type,
        kindof: ele.kind_of,
        options: "",
        validation: ['unrequired']
      }
    }
    return obj;
  }

  getInputType(data_type: string){
    let type = "";
    switch (data_type) {
      case "integer":
      case "numeric":
      case "smallint":
      case "bigint":
      case "double precision":
        type = 'number';
        break;
      case "boolean":
        type = 'radio';
        break;
      case "boolean2":
        type = 'checkbox';
        break;
      case "ARRAY":
        type = 'array';
        break;
      case "date":
      case "timestamp with time zone":
      case "timestamp without time zone":
        type = 'date';
        break;
      case "character":
      case "inet":
      case "character varying":
      case "text":
      case "json":
        type = 'input';
        break;
      case "select":
        type = 'select';
        break;
      case "link":
        type = 'link';
        break;
    }
    return type;
  }

  getInputType2(web_input_type: string){
    let type = "";
    switch (web_input_type) {
      case "number":
        type = 'number';
        break;
      case "boolean":
        type = 'radio';
        break;
      case "boolean2":
        type = 'checkbox';
        break;
      case "date":
      case "timestamp":
        type = 'date';
        break;
      case "geopoint":
      case "string":
      case "reference":
        type = 'input';
        break;
      case "select":
        type = 'select';
        break;
    }
    return type;
  }

  setObjTreeNode(el: any){
    let obj: TreeNode[] = [];
    Object.keys(el).map((key, index)=> {
      if(!(['modify_date', 'created_date'].includes(key))){
        obj[index] = {
          "key": key,
          "label": key ,
          "data": el[key],
          "type":"nonedit",
          "children": []
        }
        Object.keys(el[key]).map((keys)=> {
          obj[index]['children']?.push({
              "key":keys,
              "label": keys + ' : ' + el[key][keys],
              "data": el[key][keys],
              "type": 'child'
            })
        })
      }
      
    });
    return obj;
  }

  methodGet(api:string){
    return this.http.get(api);
  }

  methodGetOptions(api:string, headers: any){
    return this.http.get(api, { headers });
  }

  methodPostHeader(api:string, headers:any){
    return this.http.post<any>(api, {headers});
  }

  methodPost(api:string, body:any){
    return this.http.post<any>(api, body);
  }

  /**return response lengkap  */
  methodPostObserve(api:string, body:any, headers: any){
    return this.http.post<any>(api, body, { headers, observe : 'response' });
  }

  methodPostObserveNoHeader(api:string, body:any){
    return this.http.post<any>(api, body, { observe : 'response' });
  }

  methodPostPromise(api:string, body:any){
    return this.http.post<any>(api, body);
  }
  
  methodPostOptions(api:string, body:any, headers: any){
    return this.http.post<any>(api, body, { headers });
  }

  methodPut(api:string, body:any){
    return this.http.put<any>(api, body);
  }

  methodPutOptions(api:string, body:any, headers: any){
    return this.http.put<any>(api, body, { headers });
  }

  download(url:string) {
    return this.http.get(url, { responseType: 'blob'});
  }

  downloadCsv(url:string, headers: any) {
    return this.http.get(url, { responseType: 'text', headers});
  }

  convertExcelToJSON(){
    // import('xlsx').then((xlsx) => {
    //   const worksheet = xlsx.utils.json_to_sheet(this.reformatData(exportedDataWithHeader));
    //   Object.keys(obj).map((k,i) => {
    //     if(i==0) { worksheet[String.fromCharCode((65 + i))+ '2'] = { t: 's', v: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ[Z]'), 
    //     w: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ[Z]')};}
    //     else{
    //       worksheet[String.fromCharCode((65 + i))+ '2'] = { t: 's', v: '', w: ''};
    //     }
    //   })
    //   const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    //   const excelBuffer: any = xlsx.write(workbook, {
    //     bookType: 'csv',
    //     type: 'array',
    //   });
    //   this.saveAsCSV(excelBuffer, 'data');
    // });
  }

  public flattenConfig = (config: any[]): any[] => {
    const flattenedConfig = _.filter(_.flatMap(config, 'items'), (el:any) => {
      return el != null;
    });

    const flattenedConfig1 = _.filter(_.flatMap(flattenedConfig, 'items'), (el:any) => {
      return el != null;
    });

    const buttonConfig = _.filter(config, (el:any) => {
      return el.type === 'button';
    });
    return [...flattenedConfig,...flattenedConfig1,...buttonConfig];
  };

  setActions(obj:any){
    this.actions.next(obj);
  }

  validasiProject(data: any, filteredBanks?:any) {
    let errVal = '';
      if (data.app_name == '') errVal = errVal + 'app name is not empty!\n';
      const cekAppDesc = this.replaceString(data.app_desc);
      if (cekAppDesc != data.app_desc) {
        errVal = errVal + 'App Description cannot contain characters ( " ; { } [ ] ! @ # $ % ^ & * ( ) = + ** not use enter!)\n';
      }

      const validEmail = this.emailValidator(data.paymentConfig.email_account);
      if (data.paymentConfig.status == false) errVal = errVal + 'PLease Turn On Mode Payment!\n';
      if (data.paymentConfig.email_account == '') errVal = errVal + 'Account Email Is Required!\n';
      if (data.paymentConfig.account_name == '') errVal = errVal + 'Account Name Is Required!\n';
      if (!validEmail) errVal = errVal + 'Email Account Is Invalid!\n';
      if (data.paymentConfig.account_number == '') errVal = errVal + 'Account Number Is Required!\n';
      if (data.paymentConfig.bank_code == '' && (!filteredBanks || filteredBanks.length === 0)) errVal = errVal + 'Please choose a bank name!\n';
    return errVal;
  }

  validasiApp(configMobile: configMobile, errValid: any, fieldTarget?: any) {
    // console.log(configMobile,fieldTarget)
    // let app = JSON.stringify(configMobile) 
    // let app = JSON.parse(configMobile)
    let errValidasi = ""
    let mandatory: any = {};
    let arrErr: any =[];
    /** validasi pageProperty*/
    if (configMobile.pageProperty?.pageTitle == "") {
      errValidasi = errValidasi + "Page Property - Page Title must be filled!";
      mandatory.pageTitle = true;
      arrErr.push("Page Property - Page Title must be filled!")
    }
    if (configMobile.pageProperty?.isMenu &&  configMobile.pageProperty?.pageIcon == '') {
      errValidasi = errValidasi + "Page Property - Page Icon must be selected! ";
      arrErr.push("Page Property - Page Icon must be selected!")
    }

    /** validasi dataContent*/
    if (configMobile.dataContent?.contentDataSource == "dummy_fibr" || configMobile.dataContent?.contentDataSource == "") {
      errValidasi = errValidasi + "Data Content - Data Source must be selected!";
      mandatory.contentDataSource = true;
      arrErr.push("Data Content - Data Source must be selected!")
    }
    let arrFilter:any = configMobile.dataContent?.filter;
    for (let iftr = 0; iftr < arrFilter.length; iftr++) {
      if (arrFilter[iftr]?.filterDateType?.status && (arrFilter[iftr]?.filterType == 'date' || arrFilter[iftr]?.filterType == 'time')) {
        if (arrFilter[iftr].column != '' && arrFilter[iftr].filterDateType.type == '') {
          errValidasi = errValidasi + "Data Content - Filter - Value Filter - can not be empty! ";
          arrErr.push('Data Content - Filter - Value Filter - can not be empty!')
        }
        if (arrFilter[iftr].column == '' && arrFilter[iftr].filterDateType.type != '') {
          errValidasi = errValidasi + "Data Content - Filter - Value Filter - must be empty! ";
          arrErr.push('Data Content - Filter - Value Filter - must be empty!')
        }
        if (arrFilter[iftr].column != '' && (arrFilter[iftr].filterDateType.start == '' || arrFilter[iftr].filterDateType.start == null)) {
          errValidasi = errValidasi + "Data Content - Filter - Start Date - can not be empty! ";
          arrErr.push('Data Content - Filter - Start Date - can not be empty!')
        }
        if (arrFilter[iftr].column != '' && arrFilter[iftr].filterDateType.type == 'range' && 
          (arrFilter[iftr].filterDateType.end == '' || arrFilter[iftr].filterDateType.end == null)) {
          errValidasi = errValidasi + "Data Content - Filter - End Date - can not be empty! ";
          arrErr.push('Data Content - Filter - End Date - can not be empty!')
        }
      } else {
        if (arrFilter[iftr].column != '' && arrFilter[iftr].value == '') {
          errValidasi = errValidasi + "Data Content - Filter - Value Filter - can not be empty! ";
          arrErr.push('Data Content - Filter - Value Filter - can not be empty!')
        }
        if (arrFilter[iftr].column == '' && arrFilter[iftr].value != '') {
          errValidasi = errValidasi + "Data Content - Filter - Value Filter - must be empty! ";
          arrErr.push('Data Content - Filter - Value Filter - must be empty!')
        }
      }
    }
    let arrSort:any = configMobile.dataContent?.sort;
    for (let sr = 0; sr < arrSort.length; sr++) {
      if (arrFilter[sr].column != '' && arrFilter[sr].sortType == '') {
        errValidasi = errValidasi + "Data Content - Sort - Sort Type - must be selected! ";
        arrErr.push('Data Content - Sort - Sort Type - must be selected!')
      }
    }
    if (configMobile.dataContent?.pageInfo?.status == true) 
      if (configMobile.dataContent?.pageInfo?.group?.column == '' && configMobile.dataContent?.pageInfo?.sum?.column == '') {
        errValidasi = errValidasi + "Data Content - Page Info Column Grouping Or Sum must be selected! ";
        arrErr.push('Data Content - Page Info Column Grouping Or Sum must be selected!')
      }
    if (configMobile.dataContent?.accessible?.type == 'specific_user' && configMobile.dataContent?.accessible?.specificUserColumn == '') {
      errValidasi = errValidasi + "Data Content - Accessible By - Column must be selected!";
      arrErr.push("Data Content - Accessible By - Column must be selected!")
    }
    /**linkToDetails */
    if (configMobile.dataContent?.linkToDetails != 'default') {
      if (configMobile.dataContent?.linkToDetails == 'detail_page') {
        if (configMobile.dataContent?.linkToDetailsConfig?.pageId == '') {
          errValidasi = errValidasi + "Data Content - link To Detail - Page Id must be selected!";
          arrErr.push("Data Content - link To Detail - Page Id must be selected!")
        }
      }
      if (configMobile.dataContent?.linkToDetails == 'page_type') {
        if (configMobile.dataContent?.linkToDetailsConfig?.pageId == '') {
          errValidasi = errValidasi + "Data Content - link To Detail - Page Id must be selected!";
          arrErr.push("Data Content - link To Detail - Page Id must be selected!")
        }
        if (configMobile.dataContent?.linkToDetailsConfig?.relationWithField == true && 
          (configMobile.dataContent?.linkToDetailsConfig?.relationField == '' || configMobile.dataContent?.linkToDetailsConfig?.foreignKey == '')) {
          errValidasi = errValidasi + "Data Content - link To Detail - Field Or Foreign Key must be selected!";
          arrErr.push("Data Content - link To Detail -  Field Or Foreign Key must be selected!")
        }
      } 
      if (configMobile.dataContent?.linkToDetails == 'link_type') {
        if (configMobile.dataContent?.linkToDetailsConfig?.url == '') {
          errValidasi = errValidasi + "Data Content - link To Detail - Link can not be empty!";
          arrErr.push("Data Content - link To Detail - Link can not be empty!")
        }
        if (configMobile.dataContent?.linkToDetailsConfig?.relationWithField == true && configMobile.dataContent?.linkToDetailsConfig?.relationField == '') {
          errValidasi = errValidasi + "Data Content - link To Detail - Field must be selected!";
          arrErr.push("Data Content - link To Detail -  Field must be selected!")
        }
      } 
    }

    if (configMobile.layoutDesign?.layoutTemplate == 'summary' && configMobile.layoutDesign?.summaryType == 'data') {
      if (configMobile.dataContent?.configSummaryTable?.content?.group?.column == '') {
        errValidasi = errValidasi + "Data Content - Source Content - Group Summary must be selected!";
        arrErr.push("Data Content - Source Content - Group Summary must be selected!");
      }
      let arrSumValue:any = configMobile.dataContent?.configSummaryTable?.value;
      for (let i=0; i<arrSumValue.length; i++) {
        let desc = '';
        if (arrSumValue[i].column != '' && arrSumValue[i].formula == '') {
          if (i==0) desc= 'Main Formula';
          if (i==1) desc= 'Value 1 Formula';
          if (i==2) desc= 'Value 2 Formula';
          errValidasi = errValidasi + "Data Content - Source Content - Formula "+desc+" must be selected!";
          arrErr.push('Data Content - Source Content - Formula '+desc+' must be selected!')
        }
      }
    } 
    if (configMobile.layoutDesign?.layoutTemplate == 'summary' && configMobile.layoutDesign?.summaryType == 'chart') {
      let arrChart:any = configMobile.dataContent?.configSummaryTable?.sectionChart;
      for (let i=0; i<arrChart.length; i++) {
        if (arrChart[i].chartType == '') {
          errValidasi = errValidasi + "Data Content - Config Summary Cart - Type "+ i +" must be selected!";
          arrErr.push("Data Content - Config Summary Cart - Type "+ i +" must be selected!")
        }
        if (arrChart[i].chartType == 'pie') {
          if (arrChart[i]?.chartConfig?.sourceColumn == '') {
            errValidasi = errValidasi + "Data Content - Config Summary Cart - Source Column "+ i +" must be selected!";
            arrErr.push("Data Content - Config Summary Cart - Source Column "+ i +" must be selected!")
          }
          if (arrChart[i]?.chartConfig?.referenceColumn == '') {
            errValidasi = errValidasi + "Data Content - Config Summary Cart - Reference Column "+ i +" must be selected!";
            arrErr.push("Data Content - Config Summary Cart - Reference Column "+ i +" must be selected!")
          }
        }
        if (arrChart[i].chartType != 'pie') {
          if (arrChart[i]?.chartConfig?.colX == '') {
            errValidasi = errValidasi + "Data Content - Config Summary Cart - Col X "+ i +" must be selected!";
            arrErr.push("Data Content - Config Summary Cart - Col X "+ i +" must be selected!")
          }
          if (arrChart[i]?.chartConfig?.colY == '') {
            errValidasi = errValidasi + "Data Content - Config Summary Cart - Col Y "+ i +" must be selected!";
            arrErr.push("Data Content - Config Summary Cart - Col Y "+ i +" must be selected!")
          }
        }
      }
    } 

    /** validasi layoutDesign*/
    // configMobile.layoutDesign
    if (configMobile.layoutDesign?.layoutTemplate == 'details') {
      let arrSection: any = configMobile.dataContent?.sectionDetails; 
      let arrSectionNo = 1
      mandatory.sectionDetails = [];
      for (let iSec = 0; iSec < arrSection.length; iSec++) {
        if ((arrSection[iSec].type == 'title' && arrSection[iSec].field == '') ||
        (arrSection[iSec].type == 'image' && arrSection[iSec].field == '') || (arrSection[iSec].type == 'content' && arrSection[iSec].field == '')
        || (arrSection[iSec].type == 'media' && arrSection[iSec].field == '')) {
          errValidasi = errValidasi + "*Data Content - Section List - Section "+arrSectionNo+" must be selected! ";
          mandatory.sectionDetails.push(true);
          arrErr.push("*Data Content - Section List - Section "+arrSectionNo+" must be selected!")
        } else {
          mandatory.sectionDetails.push(false);
        }
        if (arrSection[iSec].type == 'button') {
          if (arrSection[iSec].buttonProperty.type == '') {
            errValidasi = errValidasi + "*Data Content - Section List - Section "+arrSectionNo+" - buttonProperty "+arrSectionNo+" Type can not be empty!";
            arrErr.push("*Data Content - Section List - Section "+arrSectionNo+" - buttonProperty "+arrSectionNo+" Type can not be empty!");
          }
          if (arrSection[iSec].buttonProperty.label == '') {
            errValidasi = errValidasi + "*Data Content - Section List - Section "+arrSectionNo+" - buttonProperty "+arrSectionNo+" Label can not be empty!";
            arrErr.push("*Data Content - Section List - Section "+arrSectionNo+" - buttonProperty "+arrSectionNo+" Label can not be empty!")
          }
            /** custom */
          if (arrSection[iSec].buttonProperty.type == 'custom' && arrSection[iSec].buttonProperty.url == '') {
            errValidasi = errValidasi + "*Data Content - Section List - Section "+arrSectionNo+" - buttonProperty "+arrSectionNo+" URL can not be empty!";
            arrErr.push("*Data Content - Section List - Section "+arrSectionNo+" - buttonProperty "+arrSectionNo+" URL can not be empty!")
          } 
          if (arrSection[iSec].buttonProperty.type == 'custom' && arrSection[iSec].buttonProperty.url != '' 
          && arrSection[iSec].buttonProperty.relationWithField == true && arrSection[iSec].buttonProperty.queryParam == '') {
            errValidasi = errValidasi + "*Data Content - Section List - Section "+arrSectionNo+" - buttonProperty "+arrSectionNo+" Query Param can not be empty!";
            arrErr.push("*Data Content - Section List - Section "+arrSectionNo+" - buttonProperty "+arrSectionNo+" Query Param can not be empty!")
          } 
          if (arrSection[iSec].buttonProperty.type == 'custom' && arrSection[iSec].buttonProperty.url != '' 
          && arrSection[iSec].buttonProperty.relationWithField == true && arrSection[iSec].buttonProperty.relationField == '') {
            errValidasi = errValidasi + "*Data Content - Section List - Section "+arrSectionNo+" - buttonProperty "+arrSectionNo+" Relation Field must be selected!";
            arrErr.push("*Data Content - Section List - Section "+arrSectionNo+" - buttonProperty "+arrSectionNo+" Relation Field must be selected!")
          }  
          /** page */
          if (arrSection[iSec].buttonProperty.type == 'page' && arrSection[iSec].buttonProperty.linkToPage == '') {
            errValidasi = errValidasi + "*Data Content - Section List - Section "+arrSectionNo+" - buttonProperty "+arrSectionNo+" Page must be selected!";
            arrErr.push("*Data Content - Section List - Section "+arrSectionNo+" - buttonProperty "+arrSectionNo+" Page must be selected!")
          }
          if (arrSection[iSec].buttonProperty.type == 'page' && arrSection[iSec].buttonProperty.linkToPage != ''
          && arrSection[iSec].buttonProperty.relationWithField == true && arrSection[iSec].buttonProperty.foreignKey == '') {
            errValidasi = errValidasi + "*Data Content - Section List - Section "+arrSectionNo+" - buttonProperty "+arrSectionNo+" Key must be selected!";
          } 
          if (arrSection[iSec].buttonProperty.type == 'page' && arrSection[iSec].buttonProperty.linkToPage != ''
          && arrSection[iSec].buttonProperty.relationWithField == true && arrSection[iSec].buttonProperty.relationField == '') {
            errValidasi = errValidasi + "*Data Content - Section List - Section "+arrSectionNo+" - buttonProperty "+arrSectionNo+" Relation Field must be selected!";
            arrErr.push("*Data Content - Section List - Section "+arrSectionNo+" - buttonProperty "+arrSectionNo+" Relation Field must be selected!")
          } 
          /** column */
          if (arrSection[iSec].buttonProperty.type == 'column' && arrSection[iSec].buttonProperty.columnData == '') {
            errValidasi = errValidasi + "*Data Content - Section List - Section "+arrSectionNo+" - buttonProperty "+arrSectionNo+" Column must be selected!";
            arrErr.push("*Data Content - Section List - Section "+arrSectionNo+" - buttonProperty "+arrSectionNo+" Column must be selected!")
          }
        }
        if (arrSection[iSec].type == 'media' && arrSection[iSec].field != '') {
          if (arrSection[iSec].parentMedia == '') {
            errValidasi = errValidasi + "*Data Content - Section List - Section Media Parent Media "+arrSectionNo+" must be selected! ";
            arrErr.push("*Data Content - Section List - Section Media Parent Media "+arrSectionNo+" must be selected! ")
          }
          if (arrSection[iSec].parentMedia != '' && arrSection[iSec].childMedia == '') {
            errValidasi = errValidasi + "*Data Content - Section List - Section Media Child Media "+arrSectionNo+" must be selected! ";
            arrErr.push("*Data Content - Section List - Section Media Child Media "+arrSectionNo+" must be selected! ")
          }
        } 
        if (arrSection[iSec].type == 'location') {
          if (arrSection[iSec].configLocation.latitude == '') {
            errValidasi = errValidasi + "*Data Content - Section List - Section Location Latitude "+arrSectionNo+" must be selected! ";
            arrErr.push("*Data Content - Section List - Section Location Latitude "+arrSectionNo+" must be selected! ")
          }
          if (arrSection[iSec].configLocation.longitude == '') {
            errValidasi = errValidasi + "*Data Content - Section List - Section Location Longitude "+arrSectionNo+" must be selected! ";
            arrErr.push("*Data Content - Section List - Section Location Longitude "+arrSectionNo+" must be selected! ")
          }
        }
        arrSectionNo++;
      }
    }

    /** validasi formConfiguration*/
    // formContent
    if (configMobile.formConfiguration?.add == true || configMobile.formConfiguration?.edit == true) {
      if (configMobile.formConfiguration?.formContent) {
        let arrForm = configMobile.formConfiguration?.formContent;
        let arrFormNo = 1;
        mandatory.formContentField = [];
        mandatory.formContentType = [];
        mandatory.formContentLabel = [];
        mandatory.formContentTypeUpload = [];
        let childQrCode: { row: any, field: any; }[] = [];
        for (let iForm = 0; iForm < arrForm.length; iForm++) {
          if ((arrForm[iForm].field == "" || arrForm[iForm].field == "-blank-" || arrForm[iForm].field == "None") 
          && (arrForm[iForm]?.configHiddenField?.type != 'location')) {
            errValidasi = errValidasi + "*Form - Field List - Field"+arrFormNo+" - Field "+arrFormNo+" must be selected!";
            mandatory.formContentField.push(true);
            arrErr.push("*Form - Field List - Field"+arrFormNo+" - Field "+arrFormNo+" must be selected!")
          } else {
            mandatory.formContentField.push(false);
          }

          if (arrForm[iForm].type == "" || arrForm[iForm].type == "-blank-" || arrForm[iForm].type == "None") {
            errValidasi = errValidasi + "*Form - Field List - Field"+arrFormNo+" - Type "+arrFormNo+" must be selected! ";
            mandatory.formContentType.push(true);
            arrErr.push("*Form - Field List - Field"+arrFormNo+" - Type "+arrFormNo+" must be selected! ")
          } else {
            mandatory.formContentType.push(false);
          }

          if ((arrForm[iForm].label == "" || arrForm[iForm].label == "None") && arrForm[iForm].type != "hidden") {
            errValidasi = errValidasi + "*Form - Field List - Field"+arrFormNo+" - Label "+arrFormNo+" must be filled! ";
            mandatory.formContentLabel.push(true);
            arrErr.push("*Form - Field List - Field"+arrFormNo+" - Label "+arrFormNo+" must be filled! ")
          } else {
            mandatory.formContentLabel.push(false);
          }

          if (arrForm[iForm].type == "upload" && (!arrForm[iForm]?.extension || arrForm[iForm]?.extension?.length == 0)) {
            errValidasi = errValidasi + "*Form - Field List - Field"+arrFormNo+" - Extension File "+arrFormNo+" must be selected!";
            mandatory.formContentTypeUpload.push(true);
            arrErr.push("*Form - Field List - Field"+arrFormNo+" - Extension File "+arrFormNo+" must be selected!")
          } else {
            mandatory.formContentTypeUpload.push(false);
          }
          if (arrForm[iForm].data_type == "static") {
            if (arrForm[iForm].type == "select" && (!arrForm[iForm]?.values || arrForm[iForm]?.values.length == 0 || arrForm[iForm]?.values == "")) {
              errValidasi = errValidasi + "*Form - Field List - Field"+arrFormNo+" - Values List can not be empty! ";
              mandatory.formContentTypeUpload.push(true);
              arrErr.push("*Form - Field List - Field"+arrFormNo+" - Values List can not be empty! ")
            } else {
              mandatory.formContentTypeUpload.push(false);
            }
            if (arrForm[iForm].type == "radio" && (!arrForm[iForm]?.values || arrForm[iForm]?.values.length == 0 || arrForm[iForm]?.values == "")) {
              errValidasi = errValidasi + "*Form - Field List - Field"+arrFormNo+" - Values List can not be empty! ";
              mandatory.formContentTypeUpload.push(true);
              arrErr.push("*Form - Field List - Field"+arrFormNo+" - Values List can not be empty! ")
            } else {
              mandatory.formContentTypeUpload.push(false);
            }
            if (arrForm[iForm].type == "checkbox" && (!arrForm[iForm]?.values || arrForm[iForm]?.values.length == 0 || arrForm[iForm]?.values == "")) {
              errValidasi = errValidasi + "*Form - Field List - Field"+arrFormNo+" - Values List can not be empty! ";
              mandatory.formContentTypeUpload.push(true);
              arrErr.push("*Form - Field List - Field"+arrFormNo+" - Values List can not be empty! ")
            } else {
              mandatory.formContentTypeUpload.push(false);
            }
            if (arrForm[iForm].type == "checkbox" && (arrForm[iForm]?.validations == 'required' && 
            (arrForm[iForm]?.checkbox_max == null || arrForm[iForm]?.checkbox_max == 0))) {
              errValidasi = errValidasi + "*Form - Field List - Field"+arrFormNo+" - Checkbox Validation Minimum can not be empty or 0 ! ";
              mandatory.formContentTypeUpload.push(true);
              arrErr.push("*Form - Field List - Field"+arrFormNo+" - Checkbox Validation Minimum can not be empty or 0 ! ")
            } else {
              mandatory.formContentTypeUpload.push(false);
            }
            if (arrForm[iForm].type == "checkbox" && (arrForm[iForm]?.validations == 'required' && 
            (arrForm[iForm]?.values.length < arrForm[iForm]?.checkbox_max))) {
              errValidasi = errValidasi + "*Form - Field List - Field"+arrFormNo+" - Checkbox Validation Minimum cannot be greater than Value List! ";
              mandatory.formContentTypeUpload.push(true);
              arrErr.push("*Form - Field List - Field"+arrFormNo+" - Checkbox Validation Minimum cannot be greater than Value List!")
            } else {
              mandatory.formContentTypeUpload.push(false);
            }
          }

          if (arrForm[iForm].data_type == "dynamic") {
            if (arrForm[iForm].type == "select" && (!arrForm[iForm]?.source || arrForm[iForm]?.source == "" || !arrForm[iForm]?.sourceColumn || arrForm[iForm]?.sourceColumn == "")) {
              errValidasi = errValidasi + "*Form - Source or Source Column List - Source or Source Column "+arrFormNo+" - must be selected! ";
              arrErr.push("*Form - Source or Source Column List - Source or Source Column "+arrFormNo+" - must be selected! ")
            }
            if (arrForm[iForm].type == "radio" && (!arrForm[iForm]?.source || arrForm[iForm]?.source == "" || !arrForm[iForm]?.sourceColumn || arrForm[iForm]?.sourceColumn == "")) {
              errValidasi = errValidasi + "*Form - Source or Source Column List - Source or Source Column "+arrFormNo+" - must be selected! ";
              arrErr.push("*Form - Source or Source Column List - Source or Source Column "+arrFormNo+" - must be selected! ")
            }
            if (arrForm[iForm].type == "checkbox" && (!arrForm[iForm]?.source || arrForm[iForm]?.source == "" || !arrForm[iForm]?.sourceColumn || arrForm[iForm]?.sourceColumn == "")) {
              errValidasi = errValidasi + "*Form - Source or Source Column List - Source or Source Column "+arrFormNo+" - must be selected! ";
              arrErr.push("*Form - Source or Source Column List - Source or Source Column "+arrFormNo+" - must be selected! ")
            }
          }
          
          if (arrForm[iForm].type == "hidden") {
            if (arrForm[iForm]?.configHiddenField?.type == '') {errValidasi = errValidasi + "*Form - AutoFill Field - Type "+arrFormNo+" - must be selected! "; }
            if (arrForm[iForm]?.configHiddenField?.type == 'uniqueid' && arrForm[iForm]?.configHiddenField?.uniqueType == '') {
              errValidasi = errValidasi + "*Form - AutoFill Field - Unique Type "+arrFormNo+" - must be selected! "; 
              arrErr.push("*Form - AutoFill Field - Unique Type "+arrFormNo+" - must be selected! ")
            }
            if (arrForm[iForm]?.configHiddenField?.type == 'uniqueid' && ((arrForm[iForm]?.configHiddenField?.uniqueType == 'prefix_random' 
            || arrForm[iForm]?.configHiddenField?.uniqueType == 'prefix_increment')
            && (arrForm[iForm]?.configHiddenField?.prefix == '' || arrForm[iForm]?.configHiddenField?.prefix == null))) {
              errValidasi = errValidasi + "*Form - AutoFill Field - Prefix "+arrFormNo+" - can not be empty! "; 
              arrErr.push("*Form - AutoFill Field - Prefix "+arrFormNo+" - can not be empty! ")
            }
            if (arrForm[iForm]?.configHiddenField?.type == 'uniqueid' && arrForm[iForm]?.configHiddenField?.uniqueType != ''
            && (arrForm[iForm]?.configHiddenField?.digit == '' || arrForm[iForm]?.configHiddenField?.digit == null)) {
              errValidasi = errValidasi + "*Form - AutoFill Field - Digit "+arrFormNo+" - can not be empty! ";
              arrErr.push("*Form - AutoFill Field - Digit "+arrFormNo+" - can not be empty! ") 
            }
            if (arrForm[iForm]?.configHiddenField?.type == 'userapp' && arrForm[iForm]?.configHiddenField?.column == '') {
              errValidasi = errValidasi + "*Form - AutoFill Field - Column "+arrFormNo+" - must be selected! ";
              arrErr.push("*Form - AutoFill Field - Column "+arrFormNo+" - must be selected! ") 
            }
            if (arrForm[iForm]?.configHiddenField?.type == 'static' && arrForm[iForm]?.configHiddenField?.staticValue == '') {
              errValidasi = errValidasi + "*Form - AutoFill Field - Static Value "+arrFormNo+" - can not be empty! "; 
            }
            if (arrForm[iForm]?.configHiddenField?.type == 'relational_column') {
              if (arrForm[iForm]?.configHiddenField?.relation?.type == '') {
                errValidasi = errValidasi + "*Form - AutoFill Field "+arrFormNo+" - Type Relation Column - Type - must be selected!";
                arrErr.push("*Form - AutoFill Field "+arrFormNo+" - Type Relation Column - Type - must be selected!")
              }
              if (arrForm[iForm]?.configHiddenField?.relation?.source == '') {
                errValidasi = errValidasi + "*Form - AutoFill Field "+arrFormNo+" - Type Relation Column - Source - must be selected!";
                arrErr.push("*Form - AutoFill Field "+arrFormNo+" - Type Relation Column - Source - must be selected!")
              }

                let fields : any = arrForm[iForm]?.configHiddenField?.relation?.columns;
                let fieldsSource: any = arrForm[iForm]?.configHiddenField?.relation?.propertyColumn;
                let noQr = 1;
                for (let ar=0; ar < fields.length; ar++) {
                  if (fields[ar].targetColumn == '') {
                    errValidasi = errValidasi + "*Form - AutoFill Field "+arrFormNo+" Type Relation Column -  Field - Target Column "+noQr+" - must be selected!";
                    arrErr.push("*Form - AutoFill Field "+arrFormNo+" Type Relation Column -  Field - Target Column "+noQr+" - must be selected!")
                  }
                  if (fields[ar].targetColumn != '') {
                    let counter = 0;
                    for (let cekField = 0; cekField < fields.length; cekField++) {
                      if (fields[ar].targetColumn == fields[cekField].targetColumn)
                        counter++;
                    }
                    if (counter > 1) {
                      errValidasi = errValidasi + "*Form - AutoFill Field "+arrFormNo+" Type Relation Column - Field - Target Column "+noQr+" selected more than one! in Field!";
                      arrErr.push("*Form - AutoFill Field "+arrFormNo+" Type Relation Column - Field - Target Column "+noQr+" selected more than one! in Field!")
                    }
                  }
                  if (fields[ar].sourceColumn == '') {
                    errValidasi = errValidasi + "*Form - AutoFill Field "+arrFormNo+" Type Relation Column -  Field - Source Column"+noQr+" - must be selected!";
                    arrErr.push("*Form - AutoFill Field "+arrFormNo+" Type Relation Column -  Field - Source Column"+noQr+" - must be selected!")
                  }
                  /*cek type*/
                  let target = fieldTarget.filter((x: any) => x.value == fields[ar].targetColumn);
                  let source = fieldsSource.filter((x: any) => x.value == fields[ar].sourceColumn);
                  let cekType = this.cekTypeData(target[0], source[0]);
                  if (cekType) {
                    errValidasi = errValidasi + "*Form - AutoFill Field "+arrFormNo+" Type Relation Column -  Field - Target Column"+noQr+" Wrong Type with Source column!";
                    arrErr.push("*Form - AutoFill Field "+arrFormNo+" Type Relation Column -  Field - Target Column"+noQr+" Wrong Type with Source column!")
                  }
                  noQr++;
                }
            }
            
            if (arrForm[iForm]?.configHiddenField?.type == 'autofill_calculation') {
              if (arrForm[iForm]?.fieldType != 'autofill_calculation') {
                errValidasi = errValidasi + "*Form - "+arrFormNo+" Column - must type Autofill Calculation!";
                arrErr.push("*Form - "+arrFormNo+" Column - must type Autofill Calculation!")
              }
              if (arrForm[iForm]?.configHiddenField?.calculation?.primaryKey == '') {
                errValidasi = errValidasi + "*Form - AutoFill Key Column "+arrFormNo+" - must be selected!";
                arrErr.push("*Form - AutoFill Key Column "+arrFormNo+" - must be selected!")
              }
              if (arrForm[iForm]?.configHiddenField?.calculation?.source == '') {
                errValidasi = errValidasi + "*Form - AutoFill Calculation "+arrFormNo+" -  Source Table must be selected!";
                arrErr.push("*Form - AutoFill Calculation "+arrFormNo+" -  Source Table must be selected!") 
              }
              if (arrForm[iForm]?.configHiddenField?.calculation?.foreignKey == '') {
                errValidasi = errValidasi + "*Form - AutoFill Calculation "+arrFormNo+" -  foreignKey Column must be selected!";
                arrErr.push("*Form - AutoFill Calculation "+arrFormNo+" -  foreignKey Column must be selected!") 
              }
              if (arrForm[iForm]?.configHiddenField?.calculation?.sourceColumn == '') {
                errValidasi = errValidasi + "*Form - AutoFill Calculation "+arrFormNo+" -  Source Column must be selected!";
                arrErr.push("*Form - AutoFill Calculation "+arrFormNo+" -  Source Column must be selected!") 
              }
              if (arrForm[iForm]?.configHiddenField?.calculation?.operation == '') {
                errValidasi = errValidasi + "*Form - AutoFill Calculation "+arrFormNo+" -  Operation must be selected!";
                arrErr.push("*Form - AutoFill Calculation "+arrFormNo+" -  Operation must be selected!") 
              }
            }

            if (arrForm[iForm]?.configHiddenField?.type == 'location') {
              if (arrForm[iForm]?.configHiddenField?.location?.latitude == '') {
                errValidasi = errValidasi + "*Form - Location Latitude "+arrFormNo+" -  Column must be selected!";
                arrErr.push("*Form - Location Latitude "+arrFormNo+" -  Column must be selected!") 
              }
              if (arrForm[iForm]?.configHiddenField?.location?.longitude == '') {
                errValidasi = errValidasi + "*Form - Location Longitude "+arrFormNo+" -  Column must be selected!";
                arrErr.push("*Form - Location Longitude "+arrFormNo+" -  Column must be selected!") 
              }
            }
          }

          if (arrForm[iForm].type == "qrcode") {
            if (arrForm[iForm]?.qrConfig?.type == 'asform') {
              if (arrForm[iForm]?.qrConfig?.source == '') {
                errValidasi = errValidasi + "*Form - Qr Code Field "+arrFormNo+" - Type As Form - Source - must be selected!";
                arrErr.push("*Form - Qr Code Field "+arrFormNo+" - Type As Form - Source - must be selected!") 
              }
              if (arrForm[iForm]?.qrConfig?.source != '' && arrForm[iForm]?.qrConfig?.sourceColumn == '') {
                errValidasi = errValidasi + "*Form - Qr Code Field "+arrFormNo+" - Type As Form - Column Key - must be selected!";
                arrErr.push("*Form - Qr Code Field "+arrFormNo+" - Type As Form - Column Key - must be selected!")
              }

              let fields : any = arrForm[iForm]?.qrConfig?.fields;
              let fieldsSource: any = arrForm[iForm]?.qrConfig?.propertyColumn;
              let noQr = 1;
              for (let xqr=0; xqr < fields.length; xqr++) {
                if (fields[xqr].targetColumn == '') {
                  errValidasi = errValidasi + "*Form - Qr Code Field "+arrFormNo+" - Type As Form - Target Column "+noQr+" - must be selected!";
                  arrErr.push("*Form - Qr Code Field "+arrFormNo+" - Type As Form - Target Column "+noQr+" - must be selected!")
                }
                if (fields[xqr].targetColumn != '') {
                  let counter = 0;
                  for (let cekField = 0; cekField < fields.length; cekField++) {
                    if (fields[xqr].targetColumn == fields[cekField].targetColumn)
                      counter++;
                  }
                  if (counter > 1) {
                    errValidasi = errValidasi + "*Form - QR Code Field "+arrFormNo+" - Type As Form - Field - Target Column "+noQr+" selected more than one! in Field!";
                    arrErr.push("*Form - QR Code Field "+arrFormNo+" - Type As Form - Field - Target Column "+noQr+" selected more than one! in Field!")
                  }
                  childQrCode.push({row:iForm, field:fields[xqr].targetColumn});
                }
                if (fields[xqr].sourceColumn == '') {
                  errValidasi = errValidasi + "*Form - Qr Code Field "+arrFormNo+" - Type As Form - Source Column"+noQr+" - must be selected!";
                }
                /*cek type*/
                let target = fieldTarget.filter((x: any) => x.value == fields[xqr]?.targetColumn);
                let source = fieldsSource.filter((x: any) => x.value == fields[xqr]?.sourceColumn);
                let cekType = this.cekTypeData(target[0], source[0]);
                if (cekType) {
                  errValidasi = errValidasi + "*Form - QR Code Field "+arrFormNo+" Type As Form - Target Column"+noQr+" Wrong Type with Source column!";
                  arrErr.push("*Form - QR Code Field "+arrFormNo+" Type As Form - Target Column"+noQr+" Wrong Type with Source column!");
                }
                noQr++;
              }
            }
          }

          if (arrForm[iForm].type == "cascading") {
            // if (arrForm[iForm].validations == "required") {
              if (arrForm[iForm].configCascading?.parent?.contentDataSource == '') {
                errValidasi = errValidasi + "*Form - Cascading Source "+arrFormNo+" must be selected!";
                arrErr.push("*Form - Cascading Source "+arrFormNo+" must be selected!");
              }
              if (arrForm[iForm].configCascading?.parent?.sourceColumn == '') {
                errValidasi = errValidasi + "*Form - Cascading Source Column "+arrFormNo+" must be selected!";
                arrErr.push("*Form - Cascading Source Column "+arrFormNo+" must be selected!");
              }
              let childCas:any = arrForm[iForm].configCascading?.child;
              for (let x=0; x<childCas.length; x++) {
                if (childCas[x].label == '') {
                  errValidasi = errValidasi + "*Form - Cascading Child Label "+x+" in "+arrFormNo+" can not be empty!";
                  arrErr.push("*Form - Cascading Child Label "+x+" in "+arrFormNo+" can not be empty!");
                }
                if (childCas[x].sourceColumn == '') {
                  errValidasi = errValidasi + "*Form - Cascading Child Source Column "+x+" in "+arrFormNo+" must be selected!";
                  arrErr.push("*Form - Cascading Child Source Column "+x+" in "+arrFormNo+" must be selected!");
                }
                if (childCas[x].targetColumn == '') {
                  errValidasi = errValidasi + "*Form - Cascading Child Target Column "+x+" in "+arrFormNo+" must be selected!";
                  arrErr.push("*Form - Cascading Child Target Column "+x+" in "+arrFormNo+" must be selected!");
                }
              }
            // }
          }

          if (arrForm[iForm].type == "calculation_form") {
            if (arrForm[iForm]?.configCalculation?.dataReference?.contentDataSource == '') {
              errValidasi = errValidasi + "*Form - Calculation Source "+arrFormNo+" must be selected!";
              arrErr.push("*Form - Calculation Source "+arrFormNo+" must be selected!");
            }
            if (arrForm[iForm]?.configCalculation?.dataReference?.contentDataSource != '' && 
            arrForm[iForm]?.configCalculation?.dataReference?.sourceColumn == '') {
              errValidasi = errValidasi + "*Form - Calculation Source Column "+arrFormNo+" must be selected!";
              arrErr.push("*Form - Calculation Source Column "+arrFormNo+" must be selected!");
            }
            const arrVar = arrForm[iForm]?.configCalculation?.variableList;
            for (let y=0; y< arrVar.length; y++) {
              if (arrVar[y].label == '') {
                errValidasi = errValidasi + "*Form - Calculation Variable List "+arrFormNo+" List Label "+y+" can not be empty!";
                arrErr.push("*Form - Calculation Variable List "+arrFormNo+" List Label "+y+" can not be empty!");
              }
              if (arrVar[y].targetColumn == '' && arrVar[y].isDataReference == false) {
                errValidasi = errValidasi + "*Form - Calculation Variable List "+arrFormNo+" List Target Column "+y+" must be selected!";
                arrErr.push("*Form - Calculation Variable List "+arrFormNo+" List Target Column "+y+" must be selected!");
              }
              if (arrVar[y].targetColumn != '' && arrVar[y].isDataReference == false && arrVar[y].targetColumn == arrForm[iForm].field) {
                errValidasi = errValidasi + "*Form - Calculation Variable List "+arrFormNo+" List Target Column "+y+" can't be the same with Field Parent!";
                arrErr.push("*Form - Calculation Variable List "+arrFormNo+" List Target Column "+y+" can't be the same with Field Parent!");
              }
              if (arrVar[y].sourceColumn == '' && arrVar[y].isDataReference == true) {
                errValidasi = errValidasi + "*Form - Calculation Variable List "+arrFormNo+" List Source Column "+y+" must be selected!";
                arrErr.push("*Form - Calculation Variable List "+arrFormNo+" List Source Column "+y+" must be selected!");
              }
            }

            if (arrForm[iForm]?.configCalculation?.configFormula?.formula == '') {
              errValidasi = errValidasi + "*Form - Calculation Config Formula - formula "+arrFormNo+"  can not be empty!";
              arrErr.push("*Form - Calculation Config Formula - formula "+arrFormNo+"  can not be empty!");
            }
            let errFormula = this.checkFormula(arrForm[iForm]?.configCalculation?.configFormula?.formula, arrVar);
            if (arrForm[iForm]?.configCalculation?.configFormula?.formula != '' && errFormula.length > 0) {
              errValidasi = errValidasi + "*Form - Calculation Config Formula - wrote the formula "+arrFormNo+" wrong!";
              arrErr.push("*Form - Calculation Config Formula - formula "+arrFormNo+"  can not be empty!");
            }
            if (arrForm[iForm]?.configCalculation?.configFormula?.label == '') {
              errValidasi = errValidasi + "*Form - Calculation Config Formula - label "+arrFormNo+"  can not be empty!";
              arrErr.push("*Form - Calculation Config Formula - label "+arrFormNo+"  can not be empty!");
            }

            /*cek type*/
            let fieldsSource: any = arrForm[iForm]?.configCalculation?.propertyColumn;
            if (fieldsSource != undefined) {
              let target = fieldTarget.filter((x: any) => x.value == arrForm[iForm]?.field);
              let source = fieldsSource.filter((x: any) => x.value == arrForm[iForm]?.configCalculation?.dataReference?.sourceColumn);
              let cekType = this.cekTypeData(target[0], source[0]);
              if (cekType) {
                errValidasi = errValidasi + "*Form list "+arrFormNo+" - Calculation Field Wrong Type with Source column!";
                arrErr.push("*Form list "+arrFormNo+" - Calculation Field Wrong Type with Source column!");
              }
            }
          }
          
          if (arrForm[iForm].field != "") {
            let counter = 0;
            for (let cekField = 0; cekField < arrForm.length; cekField++) {
              if (arrForm[iForm].field == arrForm[cekField].field && arrForm[cekField].type != 'hidden')
                counter++;
            }
            /** cek validasi field double */
            if (counter > 1) {
              errValidasi = errValidasi + "*Form - Field List - Field "+iForm+" - "+arrForm[iForm].field+" selected more than one! in Field "+arrFormNo+"!";
              arrErr.push("*Form - Field List - Field "+iForm+" - "+arrForm[iForm].field+" selected more than one! in Field "+arrFormNo+"!");
            }
            /** cek match type data */
            if (arrForm[iForm].type != 'hidden') {
              for (let ii = 0; ii < fieldTarget.length; ii++) {
                if (arrForm[iForm].field == fieldTarget[ii].value && (arrForm[iForm].type == 'calendar' && fieldTarget[ii].type != 'date')) {
                  errValidasi = errValidasi + "*Form - Field List - Field "+arrForm[iForm].field+" wrong type data!";
                  mandatory.formContentField.push(true);
                  arrErr.push("*Form - Field List - Field "+arrForm[iForm].field+" wrong type data!")
                }
                if (arrForm[iForm].field == fieldTarget[ii].value && (arrForm[iForm].type == 'time' && fieldTarget[ii].type != 'time')) {
                  errValidasi = errValidasi + "*Form - Field List - Field "+arrForm[iForm].field+" wrong type data!";
                  mandatory.formContentField.push(true);
                  arrErr.push("*Form - Field List - Field "+arrForm[iForm].field+" wrong type data!")
                } 
                if (arrForm[iForm].field == fieldTarget[ii].value && (arrForm[iForm].type == 'number' && fieldTarget[ii].type != 'number')) {
                  errValidasi = errValidasi + "*Form - Field List - Field "+arrForm[iForm].field+" wrong type data!";
                  mandatory.formContentField.push(true);
                  arrErr.push("*Form - Field List - Field "+arrForm[iForm].field+" wrong type data!")
                }
                if (arrForm[iForm].field == fieldTarget[ii].value && (arrForm[iForm].type == 'text_editor' && fieldTarget[ii].type != 'text_editor')) {
                  errValidasi = errValidasi + "*Form - Field List - Field "+arrForm[iForm].field+" wrong type data!";
                  mandatory.formContentField.push(true);
                  arrErr.push("*Form - Field List - Field "+arrForm[iForm].field+" wrong type data!")
                }
              }
            }
          }
          
          const colSelected: any = fieldTarget.find((x:any) => x.value == arrForm[iForm].field);
          arrForm[iForm].fieldType = colSelected?.type;

          arrFormNo++;
        }
        
        if (childQrCode.length > 0) {
          let tempQr = 0;
          for (let qr=0; qr<childQrCode.length; qr++) {
            let ckQr = childQrCode.filter((x:any) => x.field == childQrCode[qr].field);
            if (ckQr.length > 1) tempQr = tempQr +1;
          }
          if (tempQr > 0) {
            errValidasi = errValidasi + "*Form - QR Code Type As Form - Target Column cannot be selected more than 1!";
            arrErr.push("*Form - QR Code Type As Form - Target Column cannot be selected more than 1!");
          }
        } 
      }
    }

    /**validasi cartConfig */
    if (configMobile.layoutDesign?.layoutTemplate == 'cart') {
      if (configMobile?.cartConfig?.startOfPage?.userInputLabel == '') {
        errValidasi = errValidasi + "Cart Config - Cart Front Page - Label can not be empty!";
        arrErr.push("Cart Config - Cart Front Page - Label can not be empty!")
      }
      if (configMobile?.cartConfig?.cartContent?.columns?.itemName?.source == '') {
        errValidasi = errValidasi + "Cart Config - Cart Content - Item Name Source must be selected!";
        arrErr.push("Cart Config - Cart Content - Item Name Source must be selected!")
      }
      if (configMobile?.cartConfig?.cartContent?.columns?.itemPrice?.source == '') {
        errValidasi = errValidasi + "Cart Config - Cart Content - Item Name Source must be selected!";
        arrErr.push("Cart Config - Cart Content - Item Name Source must be selected!")
      }
    }
    // return errValidasi;
    errValid = {errValidasi: errValidasi, mandatory: mandatory, arrErr: arrErr};
    return errValid;
  }

  cekTblUse(con:any, tblName: any) {
    let cek = false;
    let itemPages = [];
    itemPages = con.itemPages ?? {};
    for (let ip = 0; ip < itemPages.length; ip++) {
      if (itemPages[ip]?.state?.dataContent.contentDataSource == tblName) {
        cek = true;
      }
      if (itemPages[ip].id != '_' && itemPages[ip].id != '') {
        let formCek = itemPages[ip]?.state?.formConfiguration?.formContent;
        for (let x=0; x < formCek.length; x++) {
          if (formCek[x]?.configCalculation?.dataReference?.contentDataSource == tblName) cek = true;
          if (formCek[x]?.configCascading?.parent?.contentDataSource == tblName) cek = true;
          if (formCek[x]?.configHiddenField?.relation?.source == tblName) cek = true;
          if (formCek[x]?.qrConfig?.source == tblName) cek = true;
          if (formCek[x]?.source == tblName) cek = true;
        }
      }
    }
    return cek;
  }

  cekTypeData(target:any, source:any) {
    // console.log(target,source)
    let xx = false;
    if (target.type == 'string') target.type = 'text';
    if (source.type == 'string') source.type = 'text';
    if (target.type == 'time') target.type = 'date';
    if (source.type == 'time') source.type = 'date';

    if (target.type == 'text' && source.type != 'text') xx = true;
    if (target.type == 'date' && source.type != 'date') xx = true;
    // if (target.type == 'time' && source.type != 'time') xx = true;
    if (target.type == 'number' && source.type != 'number') xx = true;
    if (target.type == 'image' && source.type != 'image') xx = true;
    if (target.type == 'link' && source.type != 'link') xx = true;
    if (target.type == 'boolean' && source.type != 'boolean') xx = true;
    if (target.type == 'text_editor' && source.type != 'text_editor') xx = true;
    if (target.type == 'qrcode' && source.type != 'qrcode') xx = true;
    return xx;
  }

  validasiTypeApp(data: any, fields: any) {
    // console.log(data,fields)
    let errValid: any = {};
    let errValidasi = ""
    let mandatory: any = {};
    mandatory.formOptionType = [];
    let status = false;
    for (let i = 0; i < data.length; i++) {
      status = false;
      if (data[i].type != 'hidden') {
        for (let ii = 0; ii < fields.length; ii++) {
          if (data[i].field == fields[ii].value && (data[i].type == 'calendar' && fields[ii].type != 'date')) {
            errValidasi = errValidasi + "*Form - Field List - Field "+data[i].field+" wrong type data!";
            status = true;
          }
          if (data[i].field == fields[ii].value && (data[i].type == 'time' && fields[ii].type != 'time')) {
            errValidasi = errValidasi + "*Form - Field List - Field "+data[i].field+" wrong type data!";
            status = true;
          } 
          if (data[i].field == fields[ii].value && (data[i].type == 'number' && fields[ii].type != 'number')) {
            errValidasi = errValidasi + "*Form - Field List - Field "+data[i].field+" wrong type data!";
            status = true;
          }
          if (data[i].field == fields[ii].value && (data[i].type == 'text_editor' && fields[ii].type != 'text_editor')) {
            errValidasi = errValidasi + "*Form - Field List - Field "+data[i].field+" wrong type data!";
            status = true;
          }
          // if (data[i].field == fields[ii].value && (fields[ii].type=='text' || fields[ii].type=='image' || fields[ii].type=='link' || fields[ii].type=='boolean') 
          // && (data[i].type=='calendar' || data[i].type=='time' || data[i].type=='number')) {
          //   errValidasi = errValidasi + "*Form - Field List - Field "+data[i].field+" wrong type data!";
          //   status = true;
          // }
          mandatory.formOptionType.push(status);
        }
      }
    }
    errValid= {errValidasi: errValidasi, mandatory: mandatory};
    return errValid;
  }

  cekUseColumn(info: any, itemPages: any, tableName: string, statusColumn: boolean){
    let column = info.fieldName;
    if (column == 'created_date' || column == 'modify_date' || column == 'created_by' || column == 'modified_by') statusColumn = true;
    for (let i = 0; i < itemPages.length; i++) {
      if (itemPages[i]?.state?.dataContent?.contentDataSource == tableName) {
        /**dataContent - list */
        if (itemPages[i]?.state?.dataContent?.mainTitle == column) statusColumn = true;
        if (itemPages[i]?.state?.dataContent?.images == column) statusColumn = true;
        if (itemPages[i]?.state?.dataContent?.description == column) statusColumn = true;
        /**dataContent - grid */
        if (itemPages[i]?.state?.dataContent?.header == column) statusColumn = true;
        if (itemPages[i]?.state?.dataContent?.label == column) statusColumn = true;
        if (itemPages[i]?.state?.dataContent?.additionalImage == column) statusColumn = true;
        if (itemPages[i]?.state?.dataContent?.additionalText == column) statusColumn = true;
        if (itemPages[i]?.state?.dataContent?.additionalLabel == column) statusColumn = true;
        if (itemPages[i]?.state?.dataContent?.details == column) statusColumn = true;

        /**dataContent - sectionDetails */
        for (let ib = 0; ib < itemPages[i]?.state?.dataContent?.sectionDetails.length; ib++) {
          if (itemPages[i]?.state?.dataContent?.sectionDetails[ib]?.field == column) statusColumn = true;
        }
        /**formConfiguration - formContent */
        for (let ia = 0; ia < itemPages[i]?.state?.formConfiguration?.formContent.length; ia++) {
          if (itemPages[i]?.state?.formConfiguration?.formContent[ia]?.field == column) statusColumn = true;
        }
      }
    }
    return statusColumn;
  }

  changeTypeOption(res: any) {
    let cekType = false;
    if (res.fieldTypeOld == 'number' && res.fieldType == 'date') cekType = true;
    if (res.fieldTypeOld == 'number' && res.fieldType == 'time') cekType = true;
    if (res.fieldTypeOld == 'autofill_calculation') {
      const allowAct = ['autofill_calculation', 'number','text'];
      if (allowAct.indexOf(res.fieldType) == -1) {
        cekType = true 
      }
    }
    return cekType;
  }

  checkGoogleSheets (paid: boolean, max_free: number, max_paid: number, exists: number, fromConfig: number) {
    let countSheet = 0
    if (paid == true && exists >= max_paid) {
      if (exists >= fromConfig)
        countSheet = fromConfig;
      else 
        countSheet = exists;
    }
    if (paid == true && exists < max_paid) countSheet = exists;
    if (paid == false && exists >= max_free) countSheet = max_free;
    if (paid == false && exists < max_free) countSheet = exists;
    return countSheet;
  }

  checkGoogleSheetRows (paid: boolean, max_free: number, max_paid: number, exists: number, fromConfig: number) {
    let countRow = 0
    if (paid == true && exists >= max_paid) {
      if (exists >= fromConfig)
        countRow = fromConfig;
      else 
        countRow = exists;
    }
    if (paid == true && exists < max_paid) countRow = exists;
    if (paid == false && exists >= max_free) countRow = max_free;
    if (paid == false && exists < max_free) countRow = exists;
    return countRow;
  }

  addConfigRunning(appConfig: any, environment: any) {
    // console.log(appConfig)
    /**tambahan field baru is_paid 20230202 - sprint issue #1153*/
    if (!appConfig?.is_paid) appConfig.is_paid = false;
    /**tambahan field baru max_rows,max_pages,max_tables 20230208*/
    if (!appConfig?.max_rows) appConfig.max_rows = environment.addPackage.max_rows_free;
    if (!appConfig?.max_pages) appConfig.max_pages = environment.addPackage.max_page_free;
    if (!appConfig?.max_tables) appConfig.max_tables = environment.addPackage.max_table_free;
    /**tambahan field baru is_login 20230217 - sprint issue #1226 dan #1227 */
    if (!appConfig?.is_login) appConfig.is_login = false;
    /**tambahan 20230222 */
    if (!appConfig?.quota) appConfig.quota = 1;
    /**tambahan 20230622 */
    if (!appConfig?.is_publish_pwa) appConfig.is_publish_pwa = false;
    if (!appConfig?.max_storage) appConfig.max_storage = 0;
    if (!appConfig?.publish_pwa_date) appConfig.publish_pwa_date = null;
    /** tambahan 20230704 */
    if (!appConfig?.is_role) appConfig.is_role = false;
    if (!appConfig?.status_project) appConfig.status_project = 'basic';
    /** tambahan 20230718 */
    if (appConfig?.is_registration == undefined) appConfig.is_registration = true;
    /**tambahan 20230914 */
    if (appConfig?.gsheet_connection == undefined) appConfig.gsheet_connection = null;
    /**tambahan 2023098 */
    if (appConfig?.component == undefined) appConfig.component = null;
    // if (appConfig?.payment_config == undefined) appConfig.payment_config = null;
    /**tambahan 20231207 */
    if (appConfig?.paymentConfig == undefined) {
      appConfig.paymentConfig = {status: false, functional: false, on_review: false};
    }    
    return appConfig;
  }

  makeData(result: any) {
    let data: any[] = [];
    result.forEach((doc: any) => {
      const obj = doc.data();
      obj['id'] = doc.id;
      data.push(obj);
    });
    return data;
  }

  checkFileUpload(appConfig: any, environment: any) {
    let maxUpload = 0; 
    if (appConfig.is_paid == true) 
      maxUpload = environment.addPackage.max_upload;
    else 
      maxUpload = environment.addPackage.min_upload;
    return maxUpload;
  }

  convertData(result: any[], fields: any[]) {
    if (result) {
      for (let index = 0; index < result.length; index++) {
        let c = 0, p;
        for (p in result[index]) {
          if (result[index].hasOwnProperty(p)) {
              c += 1;
              if (result[index][p] == null) {
                result[index][p] = '';
              } 
              if (typeof(result[index][p]) == 'object' && result[index][p].toString().substring(0,9) == 'Timestamp') {
                for (let i = 0; i < fields.length; i++) {
                  if (p == fields[i].value && fields[i].type == 'date') {
                    result[index][p] = this.pipe.transform(result[index][p].toDate(), 'dd MMM yyyy');
                  }
                  if (p == fields[i].value && fields[i].type == 'time') {
                    result[index][p] = this.pipe.transform(result[index][p].toDate(), 'dd MMM yyyy HH:mm');
                  }
                }
              } else {
                if (typeof(result[index][p]) == 'string' && result[index][p].substring(0,1) == "[")
                  result[index][p] = this.replaceString(result[index][p]);
                else
                  result[index][p];
              }
          }
        }
      }
    }
    return result;
  }

  convertDataApp(result: any[]) {
    for (let index = 0; index < result.length; index++) {
      let c = 0, p;
      for (p in result[index]) {
        if (result[index].hasOwnProperty(p)) {
            c += 1;
            if (result[index][p] == null) {
              result[index][p] = '';
            } 
            if (typeof(result[index][p]) == 'object' && result[index][p].toString().substring(0,9) == 'Timestamp') {
              const frmTgl = result[index][p].toDate();
              const bucket = this.pipe.transform(frmTgl, 'dd MMM yyyy HH:mm');
              if (bucket) {
                if (bucket.toString().substring(12,17) == '00:00')
                  result[index][p] = bucket.toString().substring(0,11);
                else
                  result[index][p] = bucket.toString().substring(0,17);
              } else {
                result[index][p];
              }
            } else {
              if (typeof(result[index][p]) == 'string' && result[index][p].substring(0,1) == "[")
                result[index][p] = this.replaceString(result[index][p]);
              else
                result[index][p];
              // if (result[index][p].charAt(0) == "[")
              //   result[index][p] = this.replaceString(result[index][p]);
              // else 
              //   result[index][p];
            }
        }
      }
    }
    return result;
  }

  convertDataAppOriginal(result: any[]) {
    for (let index = 0; index < result.length; index++) {
      let c = 0, p;
      for (p in result[index]) {
        if (result[index].hasOwnProperty(p)) {
            c += 1;
            if (result[index][p] == null) {
              result[index][p] = '';
            } 
            if (typeof(result[index][p]) == 'object' && result[index][p].toString().substring(0,9) == 'Timestamp') {
              const frmTgl = result[index][p].toDate();
              const bucket = this.pipe.transform(frmTgl, 'dd MMM yyyy HH:mm');
              if (bucket) {
                if (bucket.toString().substring(12,17) == '00:00')
                  result[index][p] = bucket.toString().substring(0,11);
                else
                  result[index][p] = bucket.toString().substring(0,17);
              } else {
                result[index][p];
              }
            } else {
              if (typeof(result[index][p]) == 'string' && result[index][p].substring(0,1) == "[")
                result[index][p] = this.replaceString(result[index][p]);
              else
                result[index][p];
            }
        }
      }
    }
    return result;
  }

  convertDataOne(result: any) {
    let c = 0, p;
    for (p in result) {
      // eslint-disable-next-line no-prototype-builtins
      if (result.hasOwnProperty(p)) {
        c += 1;
        if (result[p] === null) {
          result[p] = '';
        } 
        if (typeof(result[p]) === 'object' && result[p].toString().substring(0,9) === 'Timestamp') {
          const frmTgl = result[p].toDate();
          const bucket = this.pipe.transform(frmTgl, 'dd MMM yyyy HH:mm');
              if (bucket) {
                if (bucket.toString().substring(12,17) == '00:00')
                  result[p] = bucket.toString().substring(0,11);
                else
                  result[p] = bucket.toString().substring(0,17);
              } else {
                result[p];
              }
        } else {
          if (typeof(result[p]) == 'string' && result[p].substring(0,1) == "[")
            result[p] = this.replaceString(result[p]);
          else
            result[p];
        }
      }
    }
    return result;
  }

  convertDataOneOriginal(result: any) {
    let c = 0, p;
    for (p in result) {
      // eslint-disable-next-line no-prototype-builtins
      if (result.hasOwnProperty(p)) {
        c += 1;
        if (result[p] === null) {
          result[p] = '';
        } 
        if (typeof(result[p]) === 'object' && result[p].toString().substring(0,9) === 'Timestamp') {
          const frmTgl = result[p].toDate();
          const bucket = this.pipe.transform(frmTgl, 'dd MMM yyyy HH:mm');
              if (bucket) {
                if (bucket.toString().substring(12,17) == '00:00')
                  result[p] = bucket.toString().substring(0,11);
                else
                  result[p] = bucket.toString().substring(0,17);
              } else {
                result[p];
              }
        } else {
          result[p];
        }
      }
    }
    return result;
  }

  cekDataSum(result:any) {
    if (result === null) {
      result = '';
    } 
    if (typeof(result) === 'object' && result.toString().substring(0,9) === 'Timestamp') {
      const frmTgl = result.toDate();
      const bucket = this.pipe.transform(frmTgl, 'dd MMM yyyy');
      result = bucket;
      // const bucket = this.pipe.transform(frmTgl, 'dd MMM yyyy HH:mm');
      // if (bucket) {
      //   if (bucket.toString().substring(12,17) == '00:00')
      //     result = bucket.toString().substring(0,11);
      //   else
      //     result = bucket.toString().substring(0,17);
      // } else {
      //   result;
      // }
    } else {
      result;
    }
    return result;
  }

  convertDataForSum(result: any[], fields:any) {
    for (let index = 0; index < result.length; index++) {
      let c = 0, p;
      for (p in result[index]) {
        if (result[index].hasOwnProperty(p)) {
            c += 1;
            if (result[index][p] == null) {
              result[index][p] = '';
            } 
            if (typeof(result[index][p]) == 'object' && result[index][p].toString().substring(0,9) == 'Timestamp') {
              for (let i = 0; i < fields.length; i++) {
                if (p == fields[i].value && fields[i].type == 'date') {
                  result[index][p] = this.pipe.transform(result[index][p].toDate(), 'dd MMM yyyy');
                }
                if (p == fields[i].value && fields[i].type == 'time') {
                  result[index][p] = this.pipe.transform(result[index][p].toDate(), 'dd MMM yyyy HH:mm');
                }
              }
            } else {
              if (typeof(result[index][p]) == 'string' && result[index][p].substring(0,1) == "[")
                result[index][p] = this.replaceString(result[index][p]);
              else
                result[index][p];
            }
        }
      }
    }
    return result;
  }

  cekDate(dateNow: any, action: any) {
    let result: any = '';
    if (action == 'day') {
      result = this.pipe.transform(dateNow, 'dd MMM yyyy');
    } else if (action == 'mounth') {
      result = this.pipe.transform(dateNow, 'MMM yyyy');
    } else if (action == 'year') {
      result = this.pipe.transform(dateNow, 'yyyy');
    }
    return result;
  }

  cekDataDate(dataDate: any, action: any) {
    let result: any = '';
    const dateNow = dataDate.toDate();
    if (action == 'day') {
      result = this.pipe.transform(dateNow, 'dd MMM yyyy');
    } else if (action == 'mounth') {
      result = this.pipe.transform(dateNow, 'MMM yyyy');
    } else if (action == 'year') {
      result = this.pipe.transform(dateNow, 'yyyy');
    }
    return result;
  }

  convertDataJsonArr(result: any[]) {
    // console.log(result)
    for (let i = 0; i < result.length; i++) {
      if (result[i].type == 'string' && result[i].value != "" && this.isJson(result[i].value))
        result[i].value = JSON.parse(result[i].value)
      if (result[i].data_type == 'media' && result[i].mediaProperty.childMedia == 'youtube' && result[i].value != "") {
        let rs = this.getUrlEmbed(result[i].value);
        let url = 'https://www.youtube.com/embed/'+rs;
        result[i].value = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }
        
    }
    return result;
  }

  emailValidator(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  getUrlEmbed(url: any) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
}

  isJson(str: any) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  isDate(str: any) {
    try {
      Date.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  convertToNumber(data: any) {
    let angka = 0;
    if (Number.isNaN(Number(data))) {
      angka = 0;
    } else {
      angka = Number(data)
    }
    return angka;
  }

  windowsOpen (url:any, title: string,w: any, h: any) {
    let left = (screen.width/2) - (w/2)
    let top = (screen.height/2) - (h/2)
    return window.open(url, title, 'toolbar=no,scrollbars=no,resizable=no,top='+top+',left='+left+',width='+w+',height='+h)
    // window.focus();
  }

  replaceString (data: string) {
    // referensi : https://makersaid.com/remove-special-characters-from-string-javascript/
    data = data.replace(/[^a-zA-Z0-9,\-._ ]/g, '');
    return data;
  }

  replaceForUrl (data: string) {
    // referensi : https://makersaid.com/remove-special-characters-from-string-javascript/
    data = data.replace(/[^a-zA-Z0-9,\-._/: ]/g, '');
    return data;
  }

  replaceOnlyStringAndNumber (data: any) {
    data = data.replace(/[^a-zA-Z0-9 ]/g, '');
    return data;
  }

  replaceOnlyNumber (data: any) {
    data = data.replace(/[^0-9]/g, '');
    return data;
  }

  replacePackageName (data: string) {
    data = data.replace(/[^a-zA-Z.]/g, '');
    return data;
  }

  replaceTextEditor (data: string) {
    data = data.replace(/<[^>]*>|[^a-zA-Z0-9,;\-.!?<> ]/g, '');
    return data;
  }

  getType(key: string){
    let result;
    if(['created_date','modify_date', 'dob', 'tanggal', 'updated_date'].includes(key.toLowerCase())) result = 'date';
    else if(key.toLowerCase() == 'foto' || (key.toLowerCase()).includes('icon') || (key.toLowerCase()).includes('avatar') || (key.toLowerCase()).includes('image')) result = 'image'
    return result??'string'
  }

  cekAppId(appId: string) {
    if (appId == 'userapp_fibr') appId = appId + '_struct';
    if (appId == 'roleapp_fibr') appId = appId + '_struct'; 
    if (appId == 'detail_payment_transaction_fibr') appId = appId + '_struct'; 
    return appId;
  }

  cekConfigFacilities(act: any, status: any, data: any, existdata: any, appConfig: any) {
    let notif = '';
    let addItemTable: any;
    if (appConfig.is_login) addItemTable = 2; else addItemTable = 1;
    if (act == 'addPage' && status == 'free') {
      if (existdata.length > data.max_pages + 1)
        notif = 'You can only create a maximum of '+data.max_pages+' page for Free projects!'
      else 
       notif = '';
    }
    if (act == 'addPage' && status == 'paid') {
      if (existdata.length > data.max_pages + 1)
        notif = 'You can only create a maximum of '+data.max_pages+' page for Pro projects!'
      else 
        notif = '';
    }
    if (act == 'addTable' && status == 'free') {
      if (existdata.length > data.max_tables + addItemTable)
        notif = 'You can only create a maximum of '+data.max_tables+' table for Free projects!'
      else 
        notif = '';
    }
    if (act == 'addTable' && status == 'paid') {
      if (existdata.length > data.max_tables + addItemTable)
        notif = 'You can only create a maximum of '+data.max_tables+' table for Pro projects!'
      else 
        notif = '';
    }
    // if (act == 'addRows' && status == 'free') {
    //   notif = 'You can only create a maximum of '+data.max_tables+' row for Free projects!'
    // }
    // if (act == 'addRows' && status == 'paid') {
    //   notif = 'You can only create a maximum of '+data.max_tables+' row for Pro projects!'
    // }
    return notif;
  }

  cekConfigFacilitiesBaseonProject(act: any, existdata: any, appConfig: any) {
    let notif = '';
    let addItemTable: any;
    if (appConfig.is_login) addItemTable = 2; else addItemTable = 1;
    if (act == 'addPage') {
      if (existdata.length > appConfig.max_pages + 1)
        notif = 'You can only create a maximum of '+appConfig.max_pages+' page for Free projects!'
      else 
       notif = '';
    }
    if (act == 'addPage') {
      if (existdata.length > appConfig.max_pages + 1)
        notif = 'You can only create a maximum of '+appConfig.max_pages+' page for Pro projects!'
      else 
        notif = '';
    }
    if (act == 'addTable') {
      if (existdata.length > appConfig.max_tables + addItemTable)
        notif = 'You can only create a maximum of '+appConfig.max_tables+' table for Free projects!'
      else 
        notif = '';
    }
    if (act == 'addTable') {
      if (existdata.length > appConfig.max_tables + addItemTable)
        notif = 'You can only create a maximum of '+appConfig.max_tables+' table for Pro projects!'
      else 
        notif = '';
    }
    return notif;
  }

  cekForNewComponent(items: any) {
    /**add component type button in state/dataContent/sectionDetails */
    // console.log(items)
    let buttonProperty= {type: "",label: "",url: "",linkToPage: "",relationWithField: false, queryParam: "",columnData: "",foreignKey: "",
    relationField: "",customValueField: false,inAppPaymentAmountColumn: '',inAppPaymentDescription:""};
    for (let i=0; i<items.state.dataContent.sectionDetails.length; i++) {
      if (!items.state.dataContent.sectionDetails[i]?.buttonProperty) 
        items.state.dataContent.sectionDetails[i].buttonProperty = buttonProperty;
      if (!items.state.dataContent.sectionDetails[i]?.buttonProperty?.inAppPaymentAmountColumn) 
        items.state.dataContent.sectionDetails[i].buttonProperty.inAppPaymentAmountColumn = '';
      if (!items.state.dataContent.sectionDetails[i]?.buttonProperty?.inAppPaymentDescription) 
        items.state.dataContent.sectionDetails[i].buttonProperty.inAppPaymentDescription = '';
      if (!items.state.dataContent.sectionDetails[i]?.configLocation) 
        items.state.dataContent.sectionDetails[i].configLocation = {latitude: '', longitude: ''};
    }

    let filterDateType = {status:true,type:'',start:null,end:null}
    if (!items.state.dataContent.filter)
      items.state.dataContent.filter = [{column: "", condition: "==", value: "", filterType: "", filterDateType: filterDateType}];

    if (!items.state.dataContent.filter[0]?.filterDateType)
      items.state.dataContent.filter[0].filterDateType = filterDateType;

    if (!items.state.dataContent.filter[0]?.filterDateType?.status || items.state.dataContent.filter[0]?.filterDateType?.status == undefined)
      items.state.dataContent.filter[0].filterDateType.status = true;

    if (!items.state.dataContent.sort)
      items.state.dataContent.sort = [{column: "", sortType: ""}];

    let formNotifAct = {status: true, roles: [], toSelf: false, title: '', message: ''};
    if (!items.state.formConfiguration.notif)
      items.state.formConfiguration.notif = {status: false, add: formNotifAct, edit: formNotifAct, delete: formNotifAct}
    if (!items.state.formConfiguration.button)
      items.state.formConfiguration.button = {cancel: {title:'Cancel'}, save: {title:'Save'}}
    
      let fieldRealtion = [{targetColumn: '', sourceColumn: ''}]
      let relation = {type: '', source: '', columns: fieldRealtion, propertyColumn: []};
      let hiddenField = {type: '', uniqueType: '', startFrom: '', column: '', staticValue: '', prefix: '', digit: '', relation: relation}
      let fieldQrCode = [{targetColumn: '', sourceColumn: ''}];
      let configCascading = {parent: {contentDataSource:'',sourceColumn:''}, child: [{label:'',sourceColumn:'',targetColumn:''}]};
      let configCalculation = {
        showFieldReference: false, dataReference: {contentDataSource: '',sourceColumn: ''},
        variableList: [{variable:'a',isDataReference: '',sourceColumn: '',targetColumn: '',label: ''}],
        configFormula: {formula: '',label: '',targetColumn: ''}
      }
      for (let i=0; i<items.state.formConfiguration.formContent.length; i++) {
        if (!items.state.formConfiguration.formContent[i]?.configHiddenField) 
          items.state.formConfiguration.formContent[i].configHiddenField = hiddenField;
        if (!items.state.formConfiguration.formContent[i]?.qrConfig)
          items.state.formConfiguration.formContent[i].qrConfig = {status: false, source: '', sourceColumn: '', type: 'readonly', fields: fieldQrCode, propertyColumn: []}
        if (!items.state.formConfiguration.formContent[i]?.configCascading)
          items.state.formConfiguration.formContent[i].configCascading = configCascading;
        if (!items.state.formConfiguration.formContent[i]?.configCalculation)
          items.state.formConfiguration.formContent[i].configCalculation = configCalculation;
        if (!items.state.formConfiguration.formContent[i]?.configHiddenField?.calculation) 
          items.state.formConfiguration.formContent[i].configHiddenField.calculation = {source: '', sourceColumn: '', foreignKey: '', operation: '',primaryKey: ''};
        if (!items.state.formConfiguration.formContent[i]?.configHiddenField?.location) 
          items.state.formConfiguration.formContent[i].configHiddenField.location = {latitude: '', longitude: '', IsMapPreview: false};
      }

    if (!items.state.dataContent.isNumberOfLines) items.state.dataContent.isNumberOfLines = false;

    if (!items.state.dataContent?.pageInfo) 
      items.state.dataContent.pageInfo = {status: false,label: 'Page Info', group: {column: ''}, sum: {column: ''}};
    if (items.state.dataContent?.isExternalDataSource == undefined) items.state.dataContent.isExternalDataSource = false
    if (!items.state.dataContent?.accessible) 
      items.state.dataContent.accessible = {type: '', specificUserColumn: '', accessedRoles: []};
    if (items.state.dataContent?.linkToDetailsConfig == undefined) 
      items.state.dataContent.linkToDetailsConfig = {pageId:'',relationWithField:false,relationField:'',foreignKey:'',url:'',queryParam:''}
    
    if (items.state.dataContent?.configSummaryTable == undefined) {
      let loopSectionFilters= [{ column: "", columnType: "", label: "", header: ""}];
      let loopSummaryValues = [{label:"Main Value", column: "", formula: "", columnType: ""},{label:"Value 1", column: "", formula: "", columnType: ""},{label:"Value 2", column: "", formula: "", columnType: ""}];
      let summaryContent = {group: {column: '', sortType: 'asc'}, sub_group: {column: '', sortType: 'asc'}, child_group: {column: '', sortType: 'asc'}};
      items.state.dataContent.configSummaryTable = {content: summaryContent, value: loopSummaryValues, filterFunction: loopSectionFilters};
    }
    if (items.state.dataContent?.configSummaryTable.content.child_group == undefined)
      items.state.dataContent.configSummaryTable.content.child_group = {column: '', sortType: 'asc'};

    if (!items.state?.cartConfig) {
      let startOfPage = {isLogin: false, userInputLabel: ''};
      let cartCol = {
        itemName: {source: '',columnType: '', target: 'item_name'}, 
        itemPrice: {source: '',columnType: '', target: 'item_price'},
        itemPicture: {source: '',columnType: '', target: 'item_picture'},
        itemDescription: {source: '',columnType: '', target: 'item_description'}
      }
      let cartContent = {targetDataSource: 'cart_transactions', columns: cartCol};
      let cartCalculation = [
        {label: '', percentage: 0,calculate: 'increase', column: 'value_1'}, 
        {label: '', percentage: 0,calculate: 'decrease', column: 'value_2'}
      ];
      let inAppPaymentConfig = {amountColumn: 'amount', description: ''}
      items.state.cartConfig = {startOfPage:startOfPage, cartContent:cartContent, pricingCalculation:cartCalculation,
      inAppPaymentConfig:inAppPaymentConfig, inAppPayment:false}
    }
    
    if (!items.state.cartConfig?.inAppPaymentConfig ) {
      let inAppPaymentConfig = {amountColumn: 'amount', description: '', usingCashPayment: false}
      items.state.cartConfig.inAppPaymentConfig = inAppPaymentConfig;
    }
    if (!items.state.cartConfig?.inAppPaymentConfig?.usingCashPayment ) {
      items.state.cartConfig.inAppPaymentConfig.usingCashPayment = false;
    }
    if (!items.state.cartConfig?.inAppPayment ) {
      items.state.cartConfig.inAppPayment = false;
    }
    if (!items.state.pageProperty?.isLoginAsGuest || items.state.pageProperty?.isLoginAsGuest == undefined) {
      items.state.pageProperty.isLoginAsGuest = false;
    }
    if (!items.state.layoutDesign?.summaryType) {
      items.state.layoutDesign.summaryType = 'data';
    }
    if (!items.state.dataContent?.configSummaryTable?.sectionChart) {
      items.state.dataContent.configSummaryTable.sectionChart = [{chartType: "pie", chartTitle: "", chartConfig: {sourceColumn: '',referenceColumn: '',referenceType: '',colX: '',colXType: '',colY: ''}}];
    }
    return items;
  }

  externalDataSource(itemDataFiles:any, dtSource?: any) {
    let dataSource =[];
    const payment = itemDataFiles.find((x:any) => x.id == 'detail_payment_transaction_fibr' );
    if (payment != undefined) dataSource.push({value:payment.id, name:payment.label})
    const user = itemDataFiles.find((x:any) => x.id == 'userapp_fibr' );
    if (user != undefined) dataSource.push({value:user.id, name:user.label})
    const role = itemDataFiles.find((x:any) => x.id == 'roleapp_fibr' );
    if (role != undefined) dataSource.push({value:role.id, name:role.label})

    let dataSourceDynamic = [];
    for (let i = 0; i < dataSource.length; i++) {
      if (dataSource[i].value != dtSource) 
        dataSourceDynamic.push({value: dataSource[i].value, name: dataSource[i].name})
    }
    return dataSourceDynamic;
  }

  getPathColumnExternalSource(tbl:string, uid:string) {
    let urlColumn = '';
    if (tbl == 'userapp_fibr') {
      urlColumn = `/users/${uid}/userapp_fibr`;
    } 
    if (tbl == 'roleapp_fibr') {
      urlColumn = `/users/${uid}/roleapp_fibr`;
    }
    if (tbl == 'detail_payment_transaction_fibr') {
      urlColumn = `/users/${uid}/detail_payment_transaction_fibr`;
    }
    return urlColumn;
  }

  getPathDataExternalSource(tbl:any, params:any) {
    let urlData = '';
    if (tbl == 'userapp_fibr') {
      urlData = `/users/${params.id}/userapp_fibr/${params.appid}/data`;
    } 
    if (tbl == 'roleapp_fibr') {
      urlData = `/users/${params.id}/roleapp_fibr/${params.appid}/data`;
    }
    if (tbl == 'detail_payment_transaction_fibr') {
      urlData = `/users/${params.id}/detail_payment_transaction_fibr/${params.appid}/data`;
    }
    return urlData;
  }

  getColumnExternalDataSource(event: any, user: any) {
    let urlColumn = '';
    urlColumn = `/users/${user}/${event.value}`;
    return urlColumn;
  }

  cekTypeNumber(col:any, field:any) {
    const data = col.filter((x:any) => x.field == field );
    return data
  }
  getTotalSum(col: any, data: any) {
    // console.log(data)
    let infoSum = [];
    let sumData = 0;
    let header = '';
    if (col) {
      if (col.type == "number" || col.type == "calculation") {
        for (let i=0; i<data.length; i++) {
          if (isNumber(data[i][col.field])) sumData = sumData + data[i][col.field]; else sumData = sumData + 0;
        }
        header = col.header;
      } else {
        sumData = 0;
        header = 'Data';
      }
    } else {
      sumData = 0;
      header = 'Data';
    }
    infoSum = [{header: header, sumData: sumData}];
    return infoSum;
  }

  getGroupData(colGroup: any, colSum: any, data: any) {
    let infoGroup: any[] = [];
    if (colGroup) {
      let readGroup: any[] = [];
      let arrGroup = [];
      if (colSum == undefined) {
        for (let i=0; i<data.length; i++) {
          arrGroup = readGroup.filter((x:any) => x == data[i][colGroup.field]); 
          if (arrGroup.length == 0) {
            readGroup.push(data[i][colGroup.field])
            let arrDataGroup: any[] = [];
            let sumGroup = 0;
            arrDataGroup = data.filter((dx:any) => dx[colGroup?.field] == data[i][colGroup?.field]);
            for (let z=0; z < arrDataGroup.length; z++) {
              sumGroup = sumGroup + 1;
            }
            infoGroup.push({header: data[i][colGroup.field], groupData: sumGroup})
          }
        }
      } else {
        if (colSum?.field != '' && (colSum.type == "number" || colSum.type == "calculation")) {
          for (let i=0; i<data.length; i++) {
            // cek read group
            arrGroup = readGroup.filter((x:any) => x == data[i][colGroup.field]); 
            if (arrGroup.length == 0) {
              readGroup.push(data[i][colGroup.field])
              let arrDataGroup: any[] = [];
              let sumGroup = 0;
              arrDataGroup = data.filter((dx:any) => dx[colGroup?.field] == data[i][colGroup?.field]);
              // console.log(arrDataGroup)
              for (let z=0; z < arrDataGroup.length; z++) {
                sumGroup = sumGroup + arrDataGroup[z][colSum.field]
              }
              infoGroup.push({header: data[i][colGroup.field], groupData: sumGroup})
            }
          }
        } else {
          for (let i=0; i<data.length; i++) {
            arrGroup = readGroup.filter((x:any) => x == data[i][colGroup.field]); 
            if (arrGroup.length == 0) {
              readGroup.push(data[i][colGroup.field])
              let arrDataGroup: any[] = [];
              let sumGroup = 0;
              arrDataGroup = data.filter((dx:any) => dx[colGroup?.field] == data[i][colGroup?.field]);
              for (let z=0; z < arrDataGroup.length; z++) {
                sumGroup = sumGroup + 1;
              }
              infoGroup.push({header: data[i][colGroup.field], groupData: sumGroup})
            }
          }
        }
      }
    } else {
      // infoGroup = [{header: 'Data', groupData: 0}];
      infoGroup = [];
    }
    return infoGroup;
  }

  checkFormula(formula:any, listVar: any) {
    const varlist:any = [];
    for (let x=0; x<listVar.length; x++) {
      varlist.push({alias: listVar[x].variable})
    }
    const err: any = this.matchFormula(formula,varlist);
    return err;
  }

  matchFormula(formula: any, varList: any) {
    const err: any = this.parser.parserCalculation(formula,varList)
    return err
  }

  decryptEnv(env: any) {
    let envNew: any = {firebase: {}, firebase_additional: {}};
    // Decrypt Info
    let deFirebase = CryptoJS.AES.decrypt(decodeURIComponent(env.firebase),'secret key 123');
    envNew.firebase = JSON.parse(deFirebase.toString(CryptoJS.enc.Utf8));
    // console.log('firebase',envNew.firebase);
    let deFirebaseAdd = CryptoJS.AES.decrypt(decodeURIComponent( env.firebase_additional),'secret key 123');
    envNew.firebase_additional = JSON.parse(deFirebaseAdd.toString(CryptoJS.enc.Utf8));
    // console.log('firebase_add',envNew.firebase_additional);
    return envNew;
  }

  testEncrypDecryp() {
    /** fungsi ini untuk encrypt firebse config yg hasil encryptnya akan ditaruh di env firebase
     * ada setting firebase di auth.module.ts dan shared.module.ts, jika nanti ada perubahan koneksi ke firebase 
     * ceking configurasi firebase bisa ditaruh di component index, untuk mengecek hasil encryt
     * this.globalService.testEncrypDecryp();*/
    /** dev */
    let firebaseDev ={
      apiKey: "AIzaSyA_DkX0qh3cFAFhmTOlznL7qx4zAusUwi8",
      authDomain: "dev-fibr.firebaseapp.com",
      projectId: "dev-fibr",
      storageBucket: "dev-fibr.appspot.com",
      messagingSenderId: "308692921820",
      appId: "1:308692921820:web:201e323569808aca7ab319",
      measurementId: "G-00R75TSJKP"
    }
    let firebase_addDev = {
      apiKey: "AIzaSyDqINWe1QL_I2ZXn4LTBHxjfSHeQxVG2GE",
      authDomain: "dev-sifa.firebaseapp.com",
      projectId: "dev-sifa",
      storageBucket: "dev-sifa.appspot.com",
      messagingSenderId: "1036327806188",
      appId: "1:1036327806188:web:603d39b082fb5f8674f1c9",
      measurementId: "G-JW3Y2QR89W"
    }
    /**prod */
    let firebaseProd ={
      apiKey: "AIzaSyD5lVZr-aAoLD1gxRPFPQ0GTYrENsxLIYM",
      authDomain: "prod-fibr.firebaseapp.com",
      projectId: "prod-fibr",
      storageBucket: "prod-fibr.appspot.com",
      messagingSenderId: "1026420756455",
      appId: "1:1026420756455:web:4e23b6e269a2161247f742",
      measurementId: "G-FVSH7DNKT1"
    }
    let firebase_addProd = {
      apiKey: "AIzaSyDqINWe1QL_I2ZXn4LTBHxjfSHeQxVG2GE",
      authDomain: "dev-sifa.firebaseapp.com",
      projectId: "dev-sifa",
      storageBucket: "dev-sifa.appspot.com",
      messagingSenderId: "1036327806188",
      appId: "1:1036327806188:web:603d39b082fb5f8674f1c9",
      measurementId: "G-JW3Y2QR89W"
    }

    let encryFirebase = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(firebaseProd),'secret key 123').toString());
    console.log('firebase',encryFirebase);
    let encryfirebaseAdd = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(firebase_addProd),'secret key 123').toString());
    console.log('firebaseAdd',encryfirebaseAdd);

    // Decrypt Info
    let deFirebase = CryptoJS.AES.decrypt(decodeURIComponent(encryFirebase),'secret key 123');
    let decryFirebase = JSON.parse(deFirebase.toString(CryptoJS.enc.Utf8));
    console.log('firebase',decryFirebase);
    let deFirebaseAdd = CryptoJS.AES.decrypt(decodeURIComponent(encryfirebaseAdd),'secret key 123');
    let decryfirebaseAdd = JSON.parse(deFirebaseAdd.toString(CryptoJS.enc.Utf8));
    console.log('firebase_add',decryfirebaseAdd);
  }

  formatCurrency(amount: number | string): string {
    if (amount == null) return '';
    const numericAmount = +amount;
    return numericAmount.toLocaleString('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0, maximumFractionDigits: 0 });
  }

  formatCurrencyWithoutRp(amount: number | string): string {
    if (amount == null) return '';
    const numericAmount = +amount;
    const formattedAmount = numericAmount.toLocaleString('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0, maximumFractionDigits: 0 });
    // Remove the currency symbol 'Rp'
    return formattedAmount.replace('Rp', '').trim();
  }
}
