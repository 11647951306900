import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
} from "@angular/core";
import { GlobalService } from "@core-fibr/shared";
import { MenuItem } from "primeng/api";
import { configMobile, formConfiguration } from "../../../interfaces/global";

@Component({
  selector: "ui-fibr-mobile-bottommenu",
  templateUrl: "./bottommenu.component.html",
  styleUrls: ["./bottommenu.component.scss"],
})
export class BottommenuComponent {
  @Input() config: configMobile = {};
  @Input() itemPages: MenuItem[] = [];
  @Input() formConfiguration!: formConfiguration;
  @Input() actFrom: any;
  @Input() isTemplate: any;
  @Input() appConfig: any;

  @Output() menuClick = new EventEmitter<any>();
  @Output() showPwa = new EventEmitter<any>()

  idMenu: any = '';
  userProject: any;
  itemRole: MenuItem[] = [];
  itemApp: MenuItem[] = [];

  constructor(private globalService: GlobalService, ) {
    // 
  }

  async ngOnInit(): Promise<void> {
    /** create defaul PWA data yang pertama */
    this.userProject = this.globalService.getCurrReffDataParsed("user_project");
    if (this.actFrom == 'app' && this.isTemplate == undefined) {
      // this.itemApp = this.injectItem(this.itemPages,'nofilter');
      this.globalService.appConfig.subscribe((res: any) => {
        setTimeout(() => {
          if (res?.config) {
            const con = JSON.parse(res?.config);
            this.itemRole = con.itemPages ?? {};
          }
        },500);
      })
    } else if (this.actFrom == 'app' && this.isTemplate == true) {
      this.itemRole = this.injectItem(this.itemPages,'nofilter');
    } else {
      // if (!this.appConfig?.is_role || (this.appConfig?.is_role && this.userProject.role == 'fibr_creator')) {
      //   this.itemRole = this.injectItem(this.itemPages,'nofilter');
      // } else {
      //   this.itemRole = this.injectItem(this.itemPages,'filter');
      // }

      if (this.appConfig?.is_role == false || this.appConfig?.is_role == undefined) {
        if (this.appConfig?.is_login_guest && this.userProject.role == 'fibr_guest') {
          this.itemRole = this.injectItem(this.itemPages,'filter');
        } else {
          this.itemRole = this.injectItem(this.itemPages,'nofilter');
        }
      } else {
        if (this.userProject.role == 'fibr_creator') {
          this.itemRole = this.injectItem(this.itemPages,'nofilter');
        } else {
          this.itemRole = this.injectItem(this.itemPages,'filter');
        }
      }
    }
    setTimeout(() => {this.loadingPage();},500);     
  }

  loadingPage() {
    if (this.actFrom == 'pwa' || this.isTemplate == true)  {
      this.globalService.changeBlockui(true);
      setTimeout(() => {
        const listMenu = this.itemRole.filter((x: any) => x?.state?.pageProperty?.isMenu == true);
        if (listMenu.length > 0) {
          if (!this.isTemplate && listMenu[0]?.id != "") {
            this.onClick(listMenu[0]);
          } else {
            this.onClick(listMenu[0]);
          }
        }
        this.globalService.changeBlockui(false);
      }, 500);
    } else {
      console.log()
    }
  }

  injectItem(itemPage: any, status: string) {
    const itemRole = []
    if (status == 'nofilter') {
      for (let i=0; i<itemPage.length; i++) {
        itemRole.push(itemPage[i])
      }
    } else if (status == 'filter') {
      if (this.userProject.role != 'fibr_guest') {
        for (let i=0; i<itemPage.length; i++) {
          if (itemPage[i].id == '_' || itemPage[i].id == '') {
            itemRole.push(itemPage[i])
          } else {
            const pgRole = itemPage[i]?.state?.pageProperty?.accessedRoles;
            if (pgRole != undefined) {
                for (let x=0; x<pgRole.length; x++) {
                  if (pgRole[x] == this.userProject.role)
                    itemRole.push(itemPage[i])
                }
            }
          } 
        }
      } else {
        for (let i=0; i<itemPage.length; i++) {
          if (itemPage[i].id == '_' || itemPage[i].id == '') {
            itemRole.push(itemPage[i])
          } else {
            if (itemPage[i]?.state?.pageProperty?.isLoginAsGuest) {
              itemRole.push(itemPage[i])
            }
          } 
        } 
      }
    }
    return itemRole;
  }

  onClick(e: any) {
    this.menuClick.emit(e);
    this.idMenu = e.id;
    if (e.state.layoutDesign.layoutTemplate == 'summary') {
      this.globalService.setSummaryFilter(false);
      this.globalService.setSummaryDownload(false);
    }
  }
}
