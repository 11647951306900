import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PrimeNGConfig, FilterMatchMode, SelectItem, ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { GridModel, GridColumnModel } from '../../../clases/global';
// import { jsPDF } from "jspdf";
// import { autoTable } from "jspdf-autotable";
import { HttpClient } from '@angular/common/http';
import { environment } from 'libs/auth/src/environments/environment';
import { GlobalService } from '../../../services/global.service';
import { GlobalcrudService } from '../../../services/crud/globalcrud.service';
import { AuthenticationService } from '@core-fibr/auth';
import { GoogleAuthService } from 'ng-gapi';
import { DynamicdialogService } from '../../../services/dynamicdialog.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AddColumnsComponent } from '../../dynamicdialogs/add-columns/add-columns.component';
import { GlobalCrudAdminService } from '../../../services/crud/global-crud-admin.service';
import { validateEventsArray } from '@angular/fire/firestore';
import { DatePipe } from '@angular/common';
import { WorkBook } from 'xlsx';

@Component({
  selector: 'ui-fibr-grid-admin',
  templateUrl: './grid-admin.component.html',
  styleUrls: ['./grid-admin.component.scss']
})
export class GridAdminComponent implements OnInit, AfterViewInit {
  user: any = {};
  @ViewChild('table') table: Table | undefined;
  @Input() tableId!:string;
  @Input() refreshGrid: boolean | undefined;
  @Input() filterInAppPayment: boolean | undefined;
  @Input() customInAppPayment: string | undefined;
  @Input() isMockData = false;
  @Input() isArrayData = false;
  @Input() isOpenInModal = false;
  @Input() columns: any[]= [];
  @Input() columnsDate: any[]= [];
  @Input() gridModel: GridModel = new GridModel();
  @Input() Filters = [];
  @Input() isfirebase = false;
  @Input() isGoogleFiles = false;
  @Input() params:any;
  @Input() dataArray:any[] = [];
  @Input() isFibr = false;
  @Input() refreshGridAct!: boolean;

  @Output() fetchData = new EventEmitter<any>();
  @Output() dataSelect = new EventEmitter<any>();
  @Output() refreshTableGrid = new EventEmitter<any>();
  @Output() removeTableGrid = new EventEmitter<any>();
  @Output() addTableGrid = new EventEmitter<any>();
  @Output() showUpload = new EventEmitter<boolean>();
  @Output() isDownload = new EventEmitter<boolean>();
  @Output() removeMultipleRow = new EventEmitter<any>();
  @Output() isSubmit = new EventEmitter<boolean>();

  public isLoading = false;
  public paginator = true;
  public showCurrentPageReport = true;
  public isVisible = true;
  showFilterRow = false;
  public multipleSelection: any = [];
  isMultipleSelection = false;
  @Output() multipleSelectionReturn = new EventEmitter<any>();

  // change data to use getter and setter
  public data: any;
  private datasource: any = [];
  private _gm: GridModel | undefined;
  matchModeOptions: SelectItem[] = [];
  lastLazyLoadEvent: any | undefined;
  additionalColumns: any[] = [];
  parameterColumns: any[] =[];
  currReffDataParsed: any[] | undefined;
  selectData: any = {};
  globalFilter = '';
  textSearch = "";
  liststatus: any[] = [];
  selectStatus: any[] = [];
  listProject: any[] = [];
  filteredProject: any[] = [];
  listCreator: any[] = [];
  filteredCreator: any[] = [];
  selectColumn: any = {};
  date1: any;
  date2: any;

  public currentPageReportTemplate = 'Showing {first} to {last} of {totalRecords} entries';
  public rowsPerPage = 10;
  public rowsPerPageOptions = [10, 25, 50];
  public totalRecords = 0;
  emptyTemplateColSpan = 0;
  currentPage = 0;
  first=0;
  pipe = new DatePipe('en-US');
  totalRows = 0;

  body:any;
  static initColumns(columnList: (GridColumnModel)[] | undefined): any[] | undefined {
    // TODO: apply translations to headers using i18n?
    return columnList;
  }

  private capitalizeFirstLetter(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  constructor(private primengConfig: PrimeNGConfig, private http: HttpClient, public cs : ConfirmationService,
    private globalService: GlobalService, private globalCrudService : GlobalcrudService,
    private authService: AuthenticationService, private googleAuthService : GoogleAuthService,
    private dynamicDialogService : DynamicdialogService, public dynamicDialogConfig: DynamicDialogConfig,
    private cdr: ChangeDetectorRef, private globalCrudPromisesService : GlobalCrudAdminService,) { 
      this.user = this.globalService.userprofile; 
    }

  ngOnInit(): void {
    this.isLoading = true;
    this.primengConfig.ripple = true;  

    this._gm = new GridModel();
    if (this.gridModel?.RowsPerPageOptions != null) {
      if (this.gridModel.RowsPerPageOptions.length > 0) {
        this.rowsPerPageOptions = this.gridModel.RowsPerPageOptions;
        this.rowsPerPage = this.rowsPerPageOptions[0];
      }
    }

    this.matchModeOptions = [
      { label: 'Equals', value: FilterMatchMode.EQUALS },
      { label: 'Not Equals', value: FilterMatchMode.NOT_EQUALS },
    ];

    if(this.filterInAppPayment){
      if(this.customInAppPayment === 'inapppayment_transaction'){
        this.getListProject();
        this.getListCreator();
        this.liststatus = [
            {name: 'Waiting', code: 'EXPIRED'},
            {name: 'Success', code: 'PAID'},
            {name: 'Failed', code: 'UNPAID'}
        ];
      }
    }

    this.getAdditionalColumns();
    this.getParameterColumns();
    this.getColumnsDate();
  }

  getListProject() {
    this.globalCrudService.getcollectiongroup('root_payment_transaction_fibr').then((res: any) => {
      // this.listProject = res;
      const projectNamesSet = new Set();
      res.forEach((item: any) => {
        if (item.project_name) {
          projectNamesSet.add(item.project_name);
        }
      });
      this.listProject = Array.from(projectNamesSet).map(projectName => ({ project_name: projectName }));
    }).catch((error: any) => {
      console.error('Error getting projects:', error);
    });
  }

  getListCreator() {
    this.globalCrudService.getcollectiongroup('root_payment_transaction_fibr').then((res: any) => {
      // this.listCreator = res;
      const projectNamesSet = new Set();
      res.forEach((item: any) => {
        if (item.project_owner) {
          projectNamesSet.add(item.project_owner);
        }
      });
      this.listCreator = Array.from(projectNamesSet).map(projectName => ({ project_owner: projectName }));
    }).catch((error: any) => {
      console.error('Error getting profile:', error);
    });
  }

  filterStatusPayment(event: any, type: string){
    this.lastLazyLoadEvent.show = 'filterInAppPayment';
    this.lastLazyLoadEvent.sortField = 'created_date';
    this.lastLazyLoadEvent.sortOrder = 'asc';
    this.lastLazyLoadEvent.filters = [];
    if(type == 'project_name' || type == 'project_owner'){
      this.lastLazyLoadEvent.filters.push({propertyName: type, value: event.value, matchMode: 'in'});
    }else if(type == 'payment_date'){
      this.lastLazyLoadEvent.filters.push({propertyName: type, value: event, matchMode: '<='});
    }else {
      this.lastLazyLoadEvent.filters.push({propertyName: type, value: event.value, matchMode: '=='});
    }
    this.lazyLoadData(this.lastLazyLoadEvent);
  }

  ngAfterViewInit(){
    this.cdr.detectChanges();
  }

  getAdditionalColumns(): void {
    this.additionalColumns = [];
    for (let ii = 0; ii < this.columns.length; ii++) {
      const col = this.columns[ii];
      if (col.additional) {
        this.additionalColumns.push(col);
      }
    }
  }

  getParameterColumns(): void {
    this.parameterColumns = [];
    for (let ii = 0; ii < this.columns.length; ii++) {
      const col = this.columns[ii];
      if (col.parameter) {
        this.parameterColumns.push(col);
      }
    }
  }

  getColumnsDate() {
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].type == 'date' )
        this.columnsDate.push(this.columns[i]);
    }
  }

  filterGlobal(value:any, type:any){
    // console.log(this.lastLazyLoadEvent)
    this.lastLazyLoadEvent.filters = {'a_b': value};

    this.lastLazyLoadEvent.show = 'filterColumns';
    this.lastLazyLoadEvent.filters = [];
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].type == 'text' || this.columns[i].type == 'string')
        this.lastLazyLoadEvent.filters.push({propertyName: this.columns[i].field, value: value, matchMode: 'equals'});
    }
    this.lazyLoadData(this.lastLazyLoadEvent);
  }

  searchByPeriod() {
    // console.log(this.selectColumn,'-',this.date1,'-',this.date2, this.lastLazyLoadEvent)
    if (this.date1 == undefined) this.date1 = new Date();
    if (this.date2 == undefined) this.date2 = new Date();
    this.lastLazyLoadEvent.show = 'filterPeriod';
    this.lastLazyLoadEvent.sortField = 'created_date';
    this.lastLazyLoadEvent.sortOrder = 'asc';
    this.lastLazyLoadEvent.filters = [];
      this.lastLazyLoadEvent.filters.push({propertyName: this.selectColumn, value: this.date1, matchMode: 'equals'});
      this.lastLazyLoadEvent.filters.push({propertyName: this.selectColumn, value: this.date2, matchMode: 'equals'});
    this.lazyLoadData(this.lastLazyLoadEvent);
  }

  refresh() {
    // console.log(this.lastLazyLoadEvent)
    this.lastLazyLoadEvent.filters = [];
    this.lastLazyLoadEvent.first = 0;
    this.lastLazyLoadEvent.rows = 10;
    this.lastLazyLoadEvent.sortField = 'id';
    this.lastLazyLoadEvent.sortOrder = 'asc';
    this.gridModel.isPaginator = true;
    this.textSearch = '';
    this.lazyLoadData(this.lastLazyLoadEvent);
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('run-first-incomponent', changes)
    if (changes?.refreshGrid?.currentValue || changes?.refreshGridAct?.currentValue) {
      // console.log('run-first kesini')
      this.refresh();
    }
  }

  lazyLoadData(event: any): void {
    this.lastLazyLoadEvent = event;
    this.isLoading = true;
    const body: any = {};

    if(this.isfirebase && this.gridModel.APIEndpoint != '' && !this.isArrayData){
      // console.log('body',body, event)
      this.createLazyLoadBodyRequest(body, event);
        if(body.filters.length > 0){
          // console.log('search', body, this.gridModel.APIEndpoint!)
          this.gridModel.isPaginator = false;
          if (body?.show == 'filterColumns') {
            this.globalCrudPromisesService.getAllColectionGroupSeacrh(this.gridModel.APIEndpoint!, body)?.then((results:any) => {
              this.configurationGridSearch(results);
            });
          } else if (body?.show == 'filterPeriod') {
            this.globalCrudPromisesService.getPeriodCollectionsGroupPromise(this.gridModel.APIEndpoint!, body)?.then((results:any) => {
              this.configurationGridSearch(results);
            });
          } else if (body?.show == 'filterInAppPayment') {
            this.globalCrudPromisesService.getPeriodCollectionsGroupPromisePayment(this.gridModel.APIEndpoint!, body)?.then((results:any) => {
              this.configurationGridSearch(results);
            });
          } 
        }else if(this.globalFilter != ''){
          // this.getfirebaseSearch(this.globalFilter, body);
        }else{
          if (this.isFibr) {
            // console.log(this.gridModel, body)
            // body.filters.push({propertyName: 'user_id', value: this.user.uid, matchMode: 'equals'});
            // body.filters.push({propertyName: 'status', value: 'unpaid', matchMode: 'equals'});
            if (this.gridModel.GridName == 'Billing History') {
              this.globalCrudPromisesService.getCollectionsGroupBillingHis(this.gridModel.APIEndpoint!, body)?.then((results: any)=>{
                this.configurationGrid(results);
              });
            } else {
              // console.log(this.gridModel.APIEndpoint!, body)
                this.globalCrudPromisesService.getCollections(this.gridModel.APIEndpoint!, body)?.then((results: any)=>{
                // this.globalCrudPromisesService.getCollectionsWhere(this.gridModel.APIEndpoint!, body)?.then((results: any)=>{ 
                // this.globalCrudService.colectionPromise(this.gridModel.APIEndpoint!)?.then((results: any)=>{
                this.configurationGrid(results);
              });
            }
          } else {
            // console.log('kesini-c', this.gridModel)
            this.gridModel.isPaginator = true;
            this.globalCrudPromisesService.getCollectionsGroup(this.gridModel.APIEndpoint!, body)?.then((results: any)=>{
              this.configurationGrid(results);
            });
          }
        }
    } else if (this.isGoogleFiles&& this.gridModel.APIEndpoint != '' && !this.isArrayData){
      // console.log('kesini-b', this.gridModel)
      if(this.authService.getTokenGoogleSheet() != ''){
        this.getGoogleSheets();
      }else{
        this.googleAuthService.getAuth()
        .subscribe((auth:any) => {
           auth.signIn().then((res:any) => {
            this.authService.signInSuccessGoogleSheetsHandler(res);
            this.getGoogleSheets();          
          });
        });
      }
    } else {
      this.gridModel.Data = this.dataArray??[];
      this.totalRecords = this.dataArray?.length?? 0;
      this.isLoading = false;
    }
  }

  getfirebase(){
    let count = 0;
    this.globalCrudService.get_id_dynamicColection(this.gridModel.APIEndpoint!)
      .subscribe((results: any)=>{
        if (results != null) {
          this.datasource = results;
          if([environment.apiEndpoint].includes(this.gridModel.APIEndpoint!)){
            results.forEach((doc: any) => {
              let obj: any = {};
              obj['id'] = doc.id;
              this.gridModel.Data.push(obj);
          });
          this.paginator = true;
          }else{
            results.forEach((doc: any) => {
                let obj = doc.data();
                if(count == 0 && this.columns.length == 0){
                  this.columns = this.makingTableColumns(obj);
                }
                obj['id'] = doc.id;
                this.gridModel.Data.push(obj);
                if((count === (this.totalRecords - 1)) && this.gridModel.isAddBlankRow){
                  this.addBlankRow(obj);
                }
                count++;
            });
          this.paginator = false;
          }
          this.fetchData.emit(this.gridModel.Data);
        }
        this.totalRecords = this.gridModel.Data.length;
        const linkColumns = this.columns.filter(el => el.type?.toLowerCase() === 'link');
        this.isLoading = false;
      });
  }

  getGoogleSheets(){
    const _this = this;
    _this.columns = [
      { "type": "icon", "header" : "Type" },
      { "field" : "name", "header" : "Filename", "sortable" : true, "filterable": true },
      { "type":"button2", "header" : "Actions", "field": [  {"Title": "Select"} ] }
    ];
    
    gapi.load('client', () => {
      const options: gapi.client.RequestOptions = {
          path: `/drive/v3/files?q=mimeType='application/vnd.google-apps.spreadsheet'`,
          method: 'GET',
          params: {},
          body: {}
      };

      const request: gapi.client.HttpRequest<any> = gapi.client.request(options);
      request.execute((result: any) => {
          if (result != null) {
            _this.fetchData.emit(result);
            _this.totalRecords = result.files.length;
            _this.gridModel.Data = result.files;
          }
          const linkColumns = _this.columns.filter(el => el.type?.toLowerCase() === 'link');
          _this.isLoading = false;
          _this.cdr.detectChanges();
      });
    });
  }

  fetchMockData(): Observable<any> {
    const url: any = this.gridModel.APIEndpoint? this.gridModel.APIEndpoint : "./assets/json/projectlist.json";
    return this.http.get(url);
  }

  makingTableColumns(topOneResult: any){
    let columns: any[] = [];
    Object.keys(topOneResult).map((key : string) => {
      if(key != 'id') columns.push({ "field" : key, "header" : key, "sortable" : true, "filterable": true , type: this.getType(key) })
    });
    return columns;
  }

  getType(key: string){
    let result;
    if((key.toLowerCase()).includes('date')) result = 'date';
    else if( key.toLowerCase() == 'foto' || (key.toLowerCase()).includes('avatar') || (key.toLowerCase()).includes('image')) result = 'image'
    return result
  }

  getSortOrder(event:any){
    let val;
    if(event?.sortField) val = event?.sortOrder == 1? 'asc' : 'desc'
    else val = this.gridModel?.SortDir ?? 'desc';
    return val;
  }

  createLazyLoadBodyRequest(body: any, event: any): void {
    // console.log(body)
    if( Object.keys(this.gridModel?.GridId).length > 0) body['whereField'] = [];
    Object.keys(this.gridModel?.GridId).forEach(item => {
      body[item] = this.gridModel.GridId[item];
      if(Object.keys(this.gridModel?.GridId).length > 1) body['whereField'].push(item);
      else body['whereField'] = item;
    })
    //body.AggregateIdentifier = this.gridModel.GridId; //this.gridModel.AggregateIdentifier;
    body.first = event?.first??0;
    body.rows = event?.rows??10;
    body.sortField = event?.sortField ?? (this.gridModel.SortBy?? 'id');
    body.sortOrder =this.getSortOrder(event);
    body.filters = [];
    body.show = event?.show;

    // handle empty filter
    if (event == null || Object.keys(event?.filters).length <= 0) {
      // 
    } else {
      for (let ii = 0; ii < Object.keys(event?.filters).length; ii++) {
        const item = Object.keys(event?.filters)[ii];
        if(event?.filters[item].value != null){
          const newFilter = {
            // propertyName: item,
            propertyName: event?.filters[item].propertyName,
            value: event?.filters[item].value,
            matchMode: event?.filters[item].matchMode
          }
          if(event?.filters[item].value != '') body.filters.push(newFilter);
        }
      }
    }
    if (this.Filters.length > 0) {
      body.filters = this.Filters;
    }
    this.body = body;
  }

  onButtonActionClick(button: any, data: any): void {
    this.fetchData.emit({ event: button, id: data[button.ForeignKey], type: 'buttonAction', data: data });
  }

  onButtonActionClick2(button: any, data: any): void {
    this.dataSelect.emit({ event: button, id: data[button.ForeignKey], type: 'buttonAction', data: data });
  }

  onButtonActionClick3(button: any, data: any): void {
    this.fetchData.emit({ event: button, id: data[button.ForeignKey], type: 'buttonAction', data: data });
  }

  onButtonActionClick4(button: any, data: any): void {
    this.fetchData.emit({ event: button, id: data[button.ForeignKey], type: 'buttonAction', data: data });
  }

  onButtonLinkActionClick(button: any, data: any): void {
    const navigationLink = this.createNavigationLink(button, data);
    this.fetchData.emit({ event: button, data: data, navigationLink: navigationLink, type: 'buttonLinkAction' })
  }

  createNavigationLink(button: any, data: any): string {
    let navLink = '';
    if (button.linkObject != null) {
      if (button.linkObject.NavigationParam != null) {
        navLink += '/';
        for (let ii = 0; ii < button.linkObject.NavigationParam.length; ii++) {
          const item = button.linkObject.NavigationParam[ii];
          navLink += data[item];
          if (ii !== button.linkObject.NavigationParam.length - 1) {
            navLink += '/'
          }
        }
      }
    }
    return navLink;
  }

  checkArray(data: any): boolean {
    return Array.isArray(data);
  }

  checkIsString(data: any): boolean {
    return (typeof data == 'string')
  }

  loadData(): void {
    this.fetchData.emit(this._gm)
    this.isLoading = true;
  }

  exportPdf(): void {
    // const doc = new jsPDF.default(0, 0);
    // doc.autoTable(this.table.columns.map(col => ({ title: col.header, dataKey: col.field })), this.data);
    // doc.save('products.pdf');
  }

  notifExport(act: any, period?: any){
    // console.log(act,this.gridModel)
    // if (act == 'csv') {
      this.gridModel.isPaginator = false;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.globalCrudPromisesService.getAllCollectionsGroupPromise(this.gridModel.APIEndpoint!)?.then((results:any) => {
        this.configurationGridSearch(results);
      });
    // }
    this.cs.confirm({
        key:"subheader",
        header: `Export ${act}`,
        message: `Do you want to Export ${act}?`,
        dismissableMask: true,
        acceptLabel : `Export ${act}`,
        rejectLabel : 'Cancel',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptButtonStyleClass: "p-button-success",
        rejectButtonStyleClass: 'p-button-secondary',
        accept: () => {
          if (act == 'csv') this.exportCSV();
          if (act == 'excell') this.exportExcel(act);
        },
        reject: () => {
          this.refresh();   
        }
    });
  }

  exportCSV() {
    const csvData: any | undefined = this.table?.value;
    for (let index = 0; index < csvData.length; index++) {
        let c = 0, p: string;
        for (p in csvData[index]) {
          // console.log(p)
          if (csvData[index].hasOwnProperty(p)) {
              c += 1;
              if (csvData[index][p] == null) {
                csvData[index][p] = '';
              } 
              if (typeof(csvData[index][p]) == 'object' && csvData[index][p].toString().substring(0,9) == 'Timestamp') {
                const frmTgl = csvData[index][p].toDate();
                csvData[index][p] = this.pipe.transform(frmTgl, 'dd MMM yyyy');
              } 
              if (typeof(csvData[index][p]) == 'string') {
               csvData[index][p];
              }
          }
        }
    }
    this.table?.exportCSV(csvData);
    this.refresh();
  }

  exportExcel(act: any): void {
    const dataws: any = this.table?.value;
    const cols = this.columns;
    // console.log(cols)
    const bucket: any = [];
    
    for (let i = 0; i < dataws.length; i++) {
      const obj: any = [];
      for (let i2 = 0; i2 < cols.length; i2++) {
        if (cols[i2].header != "Action")
          obj[cols[i2].field] = dataws[i][cols[i2].field]
      }
      bucket.push(obj);
    }
    // console.log(bucket)
      import('xlsx').then(xlsx => {
        // const obj: any = {};
        // this.gridModel.Data.forEach(item => obj[item] = item);
        // #2 Converting the object to JSON...
        // const json: any = JSON.stringify(obj);

        const workbook = xlsx.utils.book_new();

        const worksheet = xlsx.utils.json_to_sheet(bucket);
        console.log('worksheet',worksheet);
        // const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] }
        // const workbook  = { Sheets: { data: worksheet }, SheetNames: ['data'] }
        xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        console.log(workbook)
        xlsx.writeFile(excelBuffer, 'act.xlsx');
        // this.saveAsExcelFile(excelBuffer, act);
        this.refresh();
      });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
      import('file-saver').then(FileSaver => {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        // const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
        // FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);

        const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      });
  }

  addNewColumns(){
    this.dynamicDialogConfig = {
      header: 'Add New Colum',
      width: '500px',
      contentStyle: {"max-height": "500px", "overflow": "unset"},
      styleClass : 'dialog-no-footer create-new', 
      dismissableMask: true,
      baseZIndex: 10000,
      showHeader : true,
      closable : true,
      data: {
        isGoogleFiles: false
      }
    }
    this.dynamicDialogService.show(AddColumnsComponent, this.dynamicDialogConfig)
    .onClose.subscribe((res: any) => {
      if(res){
        this.removeTableGrid.emit();
        let data:any={};
        data[res['fieldName']] = res['value'];
        this.isLoading = true;
        this.datasource.forEach((doc: any) => {
          doc.ref.update(data);
        }); 
    
        setTimeout(() => {
          this.addTableGrid.emit({ data : this.gridModel.GridName, params: this.params});
          this.isLoading = false;
        }, 400);
      }
    });
  }

  updateGrid(data: any){
    let check = false;
    Object.keys(data).map((key: string) => {
      if(key != 'id') check ||= (data[key] == null || data[key] == ''? false : true)
    });
    if(data.id != null){
      if(check) {
        this.globalCrudService.modifyDynamicColection(data, this.gridModel.APIEndpoint!, data.id)
        .then((res:any) => {
          this.removeTableGrid.emit();
          setTimeout(() => {
            this.addTableGrid.emit({ data : this.gridModel.GridName, params: this.params});
          }, 300);
        });
      }else {
        this.globalCrudService.deleteDynamicCollection(this.gridModel.APIEndpoint!, data.id)
        .then((res:any) => {
          this.removeTableGrid.emit();
          setTimeout(() => {
            this.addTableGrid.emit({ data : this.gridModel.GridName, params: this.params});
          }, 300);
        });
      }
    }else{
      if(check) this.addDataRowGrid(data);
    }
    
  }

  addDataRowGrid(data: any){
    delete data.id;
    data['modify_date'] = new Date();
    this.globalCrudService.addDynamicCollection(data, this.gridModel.APIEndpoint!)
    .then((res:any) => {
      this.removeTableGrid.emit();
        setTimeout(() => {
          this.addTableGrid.emit({ data : this.gridModel.GridName, params: this.params});
        }, 300);
    });
  }

  addBlankRow(row: any){
    let nRow: any = {};
    Object.keys(row).map((key : string) => {
      nRow[key]= null;
    });
    this.gridModel.Data.push(nRow);
  }

  createNewRow(){
    this.fetchData.emit({ event: {Name: 'createnew', Title: "Tambah Data"}, data: {}, navigationLink: "", type: 'buttonAction' })
  }

  // filterGlobal(value:any, type:any, body?:any){
    // this.globalFilter = value;
    // if(!body) body = { 
    //   filters: [],
    //   first : 0,
    //   sortOrder: this.gridModel.SortDir,
    //   sortField: this.gridModel.SortBy,
    //   rows: this.rowsPerPage
    // }
    // this.getfirebaseSearch(value, body);
  // }

  // getfirebaseSearch(value: any, body: any){
  //   if(this.gridModel.APIEndpoint?.includes('sifa_staging') || this.gridModel.APIEndpoint?.includes('models')){
  //     this.globalCrudPromisesService.a_getSearchCollections(this.gridModel.APIEndpoint!, body, value)
  //     .then((results: any)=>{
  //       this.configurationGrid(results.docs, results.size);
  //     })
  //   }else{
  //     this.globalCrudPromisesService.getSearchCollections(this.gridModel.APIEndpoint!, body, value)
  //     .then((results: any)=>{
  //       this.configurationGrid(results.docs, results.size);
  //     })
  //   }
  // }

  // getfirebaseSearchEqual(value: any, field: string){
  //   this.globalCrudService.get_search_equal_dynamicColection(this.gridModel.APIEndpoint!, value, `${field}_insensitive`)
  //   .subscribe((results: any)=>{
  //     this.configurationGrid(results);
  //   })
  // }

  // getfirebaseSearchNotEqual(value: any, field: string){
  //   this.gridModel.Data = [];
  //   this.globalCrudService.get_search_notequal_dynamicColection(this.gridModel.APIEndpoint!, value, `${field}_insensitive`)
  //   .subscribe((results: any)=>{
  //     this.configurationGrid(results);
  //   })
  // }

  async configurationGrid(results: any, totalRecordsFromResults?:number, isAdmin?:boolean){
    let count = 0;
    this.gridModel.Data = [];
    this.totalRecords = 0;

    if(!isAdmin){
      if(Object.keys(this.gridModel?.GridId).length > 0){
        // console.log('aa',this.body)
        if (this.isFibr) {
          if (this.gridModel.GridName == 'Billing History') {
            this.body.whereField = [];
            this.body.whereField.push({field: 'user_id', value: this.user.uid, identifier: 'historyBiling'}, {field: 'status', value: 'unpaid'});
            await this.globalCrudPromisesService.getCountCollectionGroupWhereField(this.gridModel.APIEndpoint!, this.body)
            .then(x => this.totalRecords = totalRecordsFromResults? totalRecordsFromResults: x);
          } else {
            // await this.globalCrudPromisesService.getCountCollectionWhereField(this.gridModel.APIEndpoint!, this.body)
            await this.globalCrudPromisesService.getCountCollection(this.gridModel.APIEndpoint!)
            .then(x => this.totalRecords = totalRecordsFromResults? totalRecordsFromResults: x);
          }
        } else {
          await this.globalCrudPromisesService.getCountCollectionGroupWhereField(this.gridModel.APIEndpoint!, this.body)
          .then(x => this.totalRecords = totalRecordsFromResults? totalRecordsFromResults: x);
        }
      }else{
        // console.log('bb',this.body)
        if (this.isFibr) {
          if (this.gridModel.GridName == 'Billing History') { 
            this.body.whereField = [];
            this.body.whereField.push({field: 'user_id', value: this.user.uid, identifier: 'historyBiling'}, {field: 'status', value: 'unpaid'});
            await this.globalCrudPromisesService.getCountCollectionGroupWhereField(this.gridModel.APIEndpoint!, this.body)
            .then(x => this.totalRecords = totalRecordsFromResults? totalRecordsFromResults: x);
          } else {
            this.body.whereField = [];
            this.body.whereField.push({field: 'status', value: 'unpaid', identifier: 'biling'});
            // await this.globalCrudPromisesService.getCountCollection(this.gridModel.APIEndpoint!)
            // await this.globalCrudPromisesService.getCountCollectionWhereField(this.gridModel.APIEndpoint!, this.body)
            await this.globalCrudPromisesService.getCountCollection(this.gridModel.APIEndpoint!)
            .then(x => this.totalRecords = totalRecordsFromResults? totalRecordsFromResults: x);
          }
        } else {
          await this.globalCrudPromisesService.getCountCollectionGroup(this.gridModel.APIEndpoint!)
          .then(x => this.totalRecords = totalRecordsFromResults? totalRecordsFromResults: x);
        }
      }
    } 

    if(isAdmin) await this.globalCrudPromisesService.a_getCountCollection(this.gridModel.APIEndpoint!)
    .then(x => this.totalRecords = x);
    
    if (results != null) {
        results.forEach((doc: any) => {
            let obj = doc.data();
            if(count == 0 && this.columns.length == 0){
              this.columns = this.makingTableColumns(obj);
            }
            let pathData = ''; let pathData2 = '';
            obj.pathCollectionGroup = doc.ref.path;
              if (this.gridModel.GridName == 'Users') {
                pathData = `/users/${obj.uid}/profile`;
                pathData2 = `/users/${obj.uid}/projects`
                this.globalCrudService.getcollection(pathData).subscribe((res: any)=>{
                  if (res[0] != undefined) {
                    this.globalCrudPromisesService.a_getCountCollection(pathData2).then(x=> obj.total_project = x);
                    this.globalCrudService.getcollection(pathData2).subscribe((resp: any)=>{
                      let cPublish = 0;
                      let cPaid = 0;
                      resp.forEach((doc: any) => {
                        if (doc?.is_publish) {
                          cPublish = cPublish + 1;
                          obj.publised = cPublish;
                        }
                        if (doc?.is_paid) {
                          cPaid = cPaid + 1;
                          obj.pro_project = cPaid;
                        }
                        obj.publised = cPublish;
                        obj.pro_project = cPaid;
                      });
                      // if (res[0].activated == false) obj.activated = 'inactive'; else obj.activated = 'active';
                      if (res[0]?.is_email_verified == false) obj.is_email_verified = 'unverified'; else obj.is_email_verified = 'verified';
                      if (res[0]?.last_login != null) {
                        const dateNow : any = new Date()
                        const lastlogin_date = res[0]?.last_login.toDate()
                        const explogin_date = lastlogin_date.setDate(lastlogin_date.getDate() + 7)
                        if (explogin_date  > dateNow.getTime()) obj.activeLogin = 'active'; else obj.activeLogin = 'inactive';
                      }
                    });
                  } 
                });
                if (!obj?.uid) {obj.displayName = 'User Invalid, No UID!';}
              }
              if (this.gridModel.GridName == 'Projects') {
                console.log(obj)
                // if (!obj?.is_paid == true)
                if (obj?.is_paid == true) obj.is_paid = 'Pro'; else obj.is_paid = 'Basic';
                if (obj.is_suspend == false) obj.is_suspend = 'Active'; else obj.is_suspend = 'Suspened';
                if (obj.is_publish == true) obj.is_publish = 'Published'; else obj.is_publish = 'Draft';
                if (!obj?.app_id) {
                  obj.app_id = 'project_invalid';
                  obj.app_name = 'Project Invalid, No AppID!'; 
                } 
                if (obj?.membership_expired_date !=null) {
                  const dateNow : any = new Date()
                  const pro_date = obj?.membership_expired_date.toDate()
                  const exp_date = pro_date.setDate(pro_date.getDate())
                  obj.expired_pro_date = this.pipe.transform(exp_date, 'dd MMM yyyy HH:mm');
                  const diff = (exp_date - dateNow.getTime()) / (1000 * 3600 * 24)
                  if (diff > 14) obj.end_soon= 'active'; else if (diff < 14 && diff > 0) obj.end_soon= 'expired soon'; else obj.end_soon= 'expired';
                }
                // if (obj?.upgrade_pro_date != null) {
                //   const dateNow : any = new Date()
                //   const pro_date = obj?.upgrade_pro_date.toDate()
                //   const exp_date = pro_date.setDate(pro_date.getDate() + 30)
                //   obj.expired_pro_date = this.pipe.transform(exp_date, 'dd MMM yyyy HH:mm');
                //   const diff = (exp_date - dateNow.getTime()) / (1000 * 3600 * 24)
                //   // console.log(pro_date,exp_date,dateNow,diff) 
                //   if (diff > 14) obj.end_soon= 'active'; else if (diff < 14 && diff > 0) obj.end_soon= 'expired soon'; else obj.end_soon= 'expired';
                // }
              }
              if (this.gridModel.GridName == 'Transactions') {
                if (obj?.callback_midtrans?.payment_type) 
                  obj.payment_method = obj?.callback_midtrans?.payment_type; 
                else 
                  obj.payment_method;
              }
              if (this.gridModel.GridName == 'In App Payment') {
                if(obj?.submission_status == 'procced' && obj?.review_status == true){
                  obj.submission_status = 'Approved';
                } else if (obj?.submission_status == 'procced' && obj?.review_status == false){
                  obj.submission_status = 'Rejected';
                } else{
                  obj.submission_status = 'Waiting for Approval';
                }

                if(obj?.from){
                  const type_page = obj.from.split('_').map((word: string) => this.capitalizeFirstLetter(word)).join(' ');
                  obj.from = type_page;
                }
                if(obj?.amount){
                  obj.amount = this.globalService.formatCurrencyWithoutRp(obj.amount);
                }

                if(obj?.disbursed_amount){
                  obj.disbursed_amount = this.globalService.formatCurrencyWithoutRp(obj.disbursed_amount);
                }

                if(obj?.disbursement_status){
                  obj.disbursement_status = this.capitalizeFirstLetter(obj.disbursement_status);
                }

                if(obj?.status_payment){
                  obj.status_payment = this.capitalizeFirstLetter(obj.status_payment.toLowerCase());
                }
              }
              this.gridModel.Data.push(obj);
              // console.log(doc, obj)
              if((count === (this.totalRecords - 1)) && this.gridModel.isAddBlankRow){
                this.addBlankRow(obj);
              }
              count++;
        });
      this.fetchData.emit(this.gridModel.Data);
    }
    const linkColumns = this.columns.filter(el => el.type?.toLowerCase() === 'link');
    this.isLoading = false;
  }

  async configurationGridSearch(results: any){
    let count = 0;
    this.gridModel.Data = [];
    if (results.docs.length) {
      this.totalRows = results.size;
        results.docs.forEach((doc: any) => {
            const obj = doc.data();
            // if(count == 0 && this.columns.length == 0){
            //   this.columns = this.makingTableColumns(obj);
            // }
            let pathData = ''; let pathData2 = '';
            obj.pathCollectionGroup = doc.ref.path;
            if (this.gridModel.GridName == 'Users') {
              pathData = `/users/${obj.uid}/profile`;
              pathData2 = `/users/${obj.uid}/projects`
              this.globalCrudService.getcollection(pathData).subscribe((res: any)=>{
                if (res[0] != undefined) {
                  this.globalCrudPromisesService.a_getCountCollection(pathData2).then(x=> obj.total_project = x);
                  this.globalCrudService.getcollection(pathData2).subscribe((resp: any)=>{
                    let cPublish = 0;
                    let cPaid = 0;
                    resp.forEach((doc: any) => {
                      if (doc?.is_publish) {
                        cPublish = cPublish + 1;
                        obj.publised = cPublish;
                      }
                      if (doc?.is_paid) {
                        cPaid = cPaid + 1;
                        obj.pro_project = cPaid;
                      }
                      obj.publised = cPublish;
                      obj.pro_project = cPaid;
                    });
                    // if (res[0].activated == false) obj.activated = 'inactive'; else obj.activated = 'active';
                    if (res[0]?.is_email_verified == false) obj.is_email_verified = 'unverified'; else obj.is_email_verified = 'verified';
                    if (res[0]?.last_login != null) {
                      const dateNow : any = new Date()
                      const lastlogin_date = res[0]?.last_login.toDate()
                      const explogin_date = lastlogin_date.setDate(lastlogin_date.getDate() + 7)
                      if (explogin_date  > dateNow.getTime()) obj.activeLogin = 'active'; else obj.activeLogin = 'inactive';
                    }
                  });
                } 
              });
              if (!obj?.uid) {obj.displayName = 'User Invalid, No UID!';}
            }
            if (this.gridModel.GridName == 'Projects') {
              if (obj.is_paid == true) obj.is_paid = 'Pro'; else obj.is_paid = 'Basic';
              if (obj.is_suspend == false) obj.is_suspend = 'Active'; else obj.is_suspend = 'Suspened';
              if (obj.is_publish == true) obj.is_publish = 'Published'; else obj.is_publish = 'Draft';
              if (!obj?.app_id) {
                obj.app_id = 'project_invalid';
                obj.app_name = 'Project Invalid, No AppID!'; 
              }
              if (obj?.upgrade_pro_date != null) {
                const dateNow : any = new Date()
                const pro_date = obj?.upgrade_pro_date.toDate()
                const exp_date = pro_date.setDate(pro_date.getDate() + 30)
                obj.expired_pro_date = this.pipe.transform(exp_date, 'dd MMM yyyy HH:mm');
                const diff = (exp_date - dateNow.getTime()) / (1000 * 3600 * 24)
                if (diff > 14) obj.end_soon= 'active'; else if (diff < 14 && diff > 0) obj.end_soon= 'expired soon'; else obj.end_soon= 'expired';
              }
            }
            if (this.gridModel.GridName == 'Transactions') {
              if (obj?.callback_midtrans?.payment_type) 
                obj.payment_method = obj?.callback_midtrans?.payment_type; 
              else 
                obj.payment_method;
            }
              this.gridModel.Data.push(obj);
              // console.log(doc, obj)
              if((count === (this.totalRecords - 1)) && this.gridModel.isAddBlankRow){
                this.addBlankRow(obj);
              }
              count++;
        });
      this.fetchData.emit(this.gridModel.Data);
    }
    const linkColumns = this.columns.filter(el => el.type?.toLowerCase() === 'link');
    this.isLoading = false;
  }

  uploadButton(){
    this.showUpload.emit(true);
  }

  downloadButton(){
    this.isDownload.emit(true);
  }

  removeButton(){
    this.removeMultipleRow.emit({table: '', data:[]});
  }

  public async getDataDate(date: any){
    const dateInMillis = await date.seconds * 1000;
    const val = await new Date(dateInMillis);
    return val;
  }

  getLabelClass(data:string, additional?:string){
    if(typeof data !== 'string') return ''
    const val = data.toLocaleLowerCase();
    let result = '';
    switch (val) {
      case 'belum verifikasi':
      case 'deleted':
        result = 'label-black'
        break;
      case 'menunggu konfirmasi':
      case 'selesai':
      case 'tidak aktif':
      case 'belum aktif':
      case 'menunggu bayar':
      case 'resep kosong':
      case 'blank':
      case 'draft':
      case 'PAID':
        result = 'label-green'
      break;
      case 'expired soon':
        result = 'label-green'
      break;
      case 'expired':
        result = 'label-grey'
      break;
      case 'terkonfirmasi':
        result = 'label-telur-asin'
        break;
      case 'terjadwalkan':
      case 'sudah verifikasi':
      case 'terverifikasi':
      case 'resep terisi':
      case 'resep ditebus':
      case 'resep perlu ditebus':
      case 'terkirim ke client':
        result = 'label-biru-muda'
        break;
      case 'hadir':
      case 'approved':
      case 'aktif':
      case 'resep terkirim':
      case 'menunggu verifikasi':
      case 'paid':
        result = 'label-telur-asin'
        break;
      case 'mulai konsultasi':
      case 'published':
        result = 'label-telur-asin'
      break;
      case 'success':
        result = 'label-telur-asin'
      break;
      case 'active':
        result = 'label-telur-asin'
      break;
      case 'pro':
        result = 'label-telur-asin'
      break;
      case 'tunggu antrian':
      case 'faskes':
      case 'menunggu jadwal':
      case 'resep diracik':
        if(!this.gridModel.APIEndpoint?.includes('roles')) result = 'label-ungu'
        break;
      case 'dibatalkan':
      case 'rejected':
      case 'ditolak':
      case 'batal':
        result = 'label-red'
        break;
      case 'jadwal ulang':
      case 'unpaid':
        result = 'label-red'
      break;
      case 'waiting':
      case 'waiting for approval':
      case 'pending':
        result = 'label-grey'
        break;
      case 'failed':
        result = 'label-grey'
        break;
      case 'basic':
          result = 'label-grey'
          break;
      case 'suspend':
          result = 'label-grey'
          break;
      case 'invalid':
        result = 'label-grey'
        break;
      case 'inactive':
        result = 'label-grey'
        break;
      case 'jadwal':
        if(additional?.toLocaleLowerCase() === 'telekonsul') result = 'label-telur-asin-muda';
        if(additional?.toLocaleLowerCase() === 'janji temu' || additional?.toLocaleLowerCase() === 'janjitemu') result = 'label-biru-muda';
        break;
      default:
        result = 'p-button-danger label'
        break;
    }
    return result;
  }

  onRowSelects(event:any) {
    this.multipleSelection.push(event?.data);
    this.multipleSelectionReturn.emit(this.multipleSelection);
  }

  onRowUnSelects(event:any) {
    this.multipleSelection = this.multipleSelection.filter((x:any) => x.RecordId != event?.data.RecordId);
    this.multipleSelectionReturn.emit(this.multipleSelection);
    this.isMultipleSelection = false;
  }

  onRowMultipleSelect(event:any) {
    this.multipleSelection = this.isMultipleSelection ? this.gridModel.Data : [];
    this.multipleSelectionReturn.emit(this.multipleSelection);
  }

  submitEvent(){
    this.isSubmit.emit(true);
  }

}


