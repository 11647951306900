/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  AuthenticationFireGuard,
  LoginComponent,
  RegisterComponent,
} from "@core-fibr/auth";
import { NotFoundComponent } from "@core-fibr/shared";
import { InputForgotPasswordComponent } from "@core-fibr/auth";
import { SendEmailForgotPasswordComponent } from "@core-fibr/auth";
import { PrivacyPolicyComponent } from "@core-fibr/shared";
import { ProgresiveWebApplicationComponent } from "./components/progresive-web-application/progresive-web-application.component";
import { SampleComponent } from "./components/sample/sample.component";
import { PwaTemplateComponent } from "./components/pwa-template/pwa-template.component";
import { ResponseMidtransComponent } from "./components/response-midtrans/response-midtrans.component"
import { InvoiceNotifComponent } from "./components/invoice-notif/invoice-notif.component";
import { PwaUnwearableComponent } from "./components/pwa-unwearable/pwa-unwearable.component";
// import { ProjectComponent } from "./components/project/project.component";
// import {SessionService} from "../../../../libs/shared/src/lib/services/session.service";

// this.user = this.globalService.getCurrReffDataParsed("user_profile");

const appRoutes: Routes = [
  { path: "", redirectTo: "portal", pathMatch: "full" },
  { path: "auth/login", component: LoginComponent },
  { path: "auth/register", component: RegisterComponent },
  { path: "auth/send-email", component: SendEmailForgotPasswordComponent },
  { path: "auth/enter-my-password", component: InputForgotPasswordComponent },
  { path: "app-fibr", component: ProgresiveWebApplicationComponent },
  { path: "app-fibr-unwearable", component: PwaUnwearableComponent },
  { path: "template-fibr", component: PwaTemplateComponent },
  { path: "app-fibr-payment-close", component: ResponseMidtransComponent },
  { path: "app-fibr-invoice-notif", component: InvoiceNotifComponent },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
    data: { title: "cloudfibr.io", name: "Cloud Fibr" },
  },
  { path: "sample", component: SampleComponent, data: { title: "Sample" } },
  {
    path: "portal",
    canActivate: [AuthenticationFireGuard],
    loadChildren: () =>
      import("../app/components/app-components.module").then(
        (m) => m.AppComponentsModule
      ),
  },
  { path: "404", component: NotFoundComponent },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- debugging purposes only
    ),
    // other imports here
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
