import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-fibr-for-tooltip',
  templateUrl: './for-tooltip.component.html',
//   template: `
//   <span style="color: cyan">Tooltip from Component</span>
// `,
  styleUrls: ['./for-tooltip.component.scss']
})

export class ForTooltipComponent {

}
// export class ForTooltipComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }
