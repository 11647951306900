/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit, AfterViewInit, ElementRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PrimeNGConfig } from "primeng/api";
import { LoaderService, SessionService, ThemeService } from "@core-fibr/shared";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { environment } from "@core-fibr/auth";
import { PaymentGateway } from "./components/_shared/services/payment_gateway.service";

// eslint-disable-next-line @typescript-eslint/ban-types
declare const gtag: Function; // <------------Important: the declartion for gtag is required!

@Component({
  selector: "ui-fibr-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = "fibr-portal";
  showLoader: boolean | undefined;
  theme: string;

  constructor(
    private primengConfig: PrimeNGConfig,
    private loaderService: LoaderService,
    private themeService: ThemeService,
    private sessionService: SessionService,
    private paymentGateway: PaymentGateway,
    private router: Router,
    private elementForScript: ElementRef,
    translate: TranslateService
  ) {
    const theme = this.sessionService.getItem("selected-theme");
    if (theme != null && theme.length > 0) {
      this.theme = theme;
      this.themeService.selectTheme(theme);
    } else {
      this.theme = "theme-teal";
    }

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang("id");
    const language = this.sessionService.getItem("fibr-language");
    if (language != null && language.length > 0) {
      // the lang to use, if the lang isn't available, it will use the current loader to get them
      translate.use(language);
    } else {
      this.sessionService.setItem("fibr-language", "id");
    }

    /** START : Code to Track Page View using gtag.js */
    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // console.log(event, environment)
       gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects
       })
    })
    /** END : Code to Track Page View  using gtag.js */

  }

  ngOnInit() {    
    this.primengConfig.ripple = true;
    this.loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
    });

    this.themeService.theme.subscribe((val: string) => {
      this.theme = val;
    });
  }

  ngAfterViewInit(): void {
    this.paymentGateway.init(this.elementForScript)
  }

  ngOnDestroy() {
    this.themeService.theme.observers.forEach(function (element) {
      element.complete();
    });
    this.loaderService.status.observers.forEach(function (element) {
      element.complete();
    });
  }
}
