import { Component, OnInit } from '@angular/core';
import { GlobalcrudService, GlobalService, SessionService, ToastNotificationService } from '@core-fibr/shared';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ui-fibr-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notifUser: any[] = [];
  pathNotif = '';
  userProfile: any = {};
  detailProject: any = {};
  showNotif = false;
  showProject = false
  detailNotif: any = {};
  countRead: any;

  constructor(
    private globalService: GlobalService,
    private globalCrudService: GlobalcrudService,
    public translate: TranslateService,
    public sessionService: SessionService,
    private toastNotificationService: ToastNotificationService,
  ) {
    // 
  }

  ngOnInit(): void {
    this.translate.use(this.sessionService.getItem("fibr-language"))
    this.showDataNotif()
  }

  showDataNotif() {
    this.userProfile = this.globalService.getCurrReffDataParsed('user_profile');
    this.pathNotif = `/users/${this.userProfile.uid}/notif_user`;
    this.globalCrudService.getCollectionOrderByDescWithFieldId(this.pathNotif, 'created_date')
      .subscribe((notification) => {
        this.notifUser = notification.filter((x: any) => !x.hide);
        this.countRead = 0;
        notification.forEach((doc: any) => {
          if (!doc.read) this.countRead = this.countRead + 1;
        })
      })
  }

  changeData(field: any) {
    field.read = true;
    this.detailNotif = field;
    this.globalCrudService.modifyRefMergeCollection(field, this.pathNotif, field.id);
    const pathProject = `/users/${this.userProfile.uid}/projects`;
    this.globalCrudService.getonecollectionwithid(pathProject, field.param.projectId).subscribe((res: any) => {
      this.detailProject = res.data();
      if (field.type == 'build_app') {
        this.showProject = true; //saat ini yang mengeluarkan modal detail khusus build_app terlebih dahulu
      }
    });
  }

  hideNotification(field: any) {
    field.read = true;
    field.hide = true;
    this.globalCrudService.modifyRefMergeCollection(field, this.pathNotif, field.id)
  }

  async navigateToProject(field: any) {
    const path = `users/${this.userProfile.uid}/projects/${field.param.projectId}/firebase`;
    const stP = await this.globalCrudService.getcollectionPromise(path);
    if (stP[0]?.process) {
      this.toastNotificationService.showNotification("info","You cannot access this project, please wait until the Build app process is successful");
    } 
    else {
      const pathProject = `/users/${this.userProfile.uid}/projects`;
      this.globalCrudService.getonecollectionwithid(pathProject, field.param.projectId).subscribe((res: any) => {
        const projectdetails = res.data();
        if (projectdetails?.app_id) {
          this.showNotif = false;
          let url = `/portal/project/editor?filename=${projectdetails?.file_name}&fileid=${projectdetails?.file_id}&appid=${projectdetails?.app_id}`;
          if (field.type == 'build_app')
            url += `&notification_action=display_dialog_publish`;

          window.location.href = url;
        }
      });
    }
  }

  onClickButtonNotif() {
    this.showNotif = true;
  }

  downloadApk(url:any) {
    window.open(url);
  }
}
