import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { GlobalService } from "@core-fibr/shared";
import {
  configMobile,
  GridModelStyle,
  GridModelText,
  layoutDesign,
} from "../../../interfaces/global";

@Component({
  selector: "ui-fibr-mobile-grid",
  templateUrl: "./grid.component.html",
  styleUrls: ["./grid.component.scss"],
})
export class GridComponent implements DoCheck {
  @Input() config: configMobile = {};
  @Input() data: any[] = [];
  @Input() column: any;
  @Input() dataColumn: any;

  @Output() buttonFloatOnclick = new EventEmitter<any>();
  @Output() buttonDetailOnclick = new EventEmitter<any>();

  isHorizontal!: boolean;
  layoutStyle!: GridModelStyle;
  layoutText!: GridModelText;
  pipe = new DatePipe('en-US');

  mainTitleType: any;
  descriptionType: any;
  headerType: any;
  labelType:any;
  additionalTextType: any;
  additionalLabelType: any;
  detailType: any;
  userProject: any;
  modalPageInfo: any;
  fieldSum = '';
  totalSum = 0;
  infoGroupData: any[] = [];
  infoHeaderGroup: any;
  constructor(private httpClient: HttpClient, private globalService: GlobalService,) {
    // this.userProject = this.globalService.getCurrReffDataParsed("user_project");
  }

  ngOnInit(): void {
    // console.log(this.data, this.column,this.config)
    for (let i=0; i<this.column.length; i++) {
      if (this.column[i].value == this.config.dataContent?.mainTitle ) this.mainTitleType = this.column[i].type;
      if (this.column[i].value == this.config.dataContent?.description ) this.descriptionType = this.column[i].type;
      if (this.column[i].value == this.config.dataContent?.header ) this.headerType = this.column[i].type;
      if (this.column[i].value == this.config.dataContent?.label ) this.labelType = this.column[i].type;
      if (this.column[i].value == this.config.dataContent?.additionalText ) this.additionalTextType = this.column[i].type;
      if (this.column[i].value == this.config.dataContent?.additionalLabel ) this.additionalLabelType = this.column[i].type;
      if (this.column[i].value == this.config.dataContent?.details ) this.detailType = this.column[i].type;
    }
    this.userProject = this.globalService.getCurrReffDataParsed("user_project");
    this.infoHeaderGroup = this.column.filter((x:any) => x.value == this.config.dataContent?.pageInfo?.sum?.column);
  }

  ngDoCheck() {
    const n: any = this.config.layoutDesign?.layoutStyle;
    const m: any = this.config.layoutDesign?.layoutText;
    this.layoutStyle = n;
    this.layoutText = m;
  }

  getSumGroupData() {
    this.modalPageInfo = true;
    let colTypeSum = [];
    let colTypeGroup = [];
    let infoSumData = [];
    /**sum all data in column */
    colTypeSum = this.globalService.cekTypeNumber(this.dataColumn, this.config.dataContent?.pageInfo?.sum?.column);
    infoSumData = this.globalService.getTotalSum(colTypeSum[0], this.data);
    this.fieldSum = infoSumData[0].header;
    this.totalSum = infoSumData[0].sumData;
    /**group */
    colTypeGroup = this.globalService.cekTypeNumber(this.dataColumn, this.config.dataContent?.pageInfo?.group?.column);
    this.infoGroupData = this.globalService.getGroupData(colTypeGroup[0],colTypeSum[0], this.data);
  }

  onButtonFloatClick() {
    this.buttonFloatOnclick.emit(true);
  }

  onButtonDetailClick(field: any) {
    // console.log(field)
    this.buttonDetailOnclick.emit(field);
  }

  checkType(val: any): boolean {
    return typeof(val) === 'object';
  }
}
