<p-dataView #dv [value]="data" [paginator]="true" [rows]="8" filterBy="name" [sortField]="sortField" [sortOrder]="sortOrder" layout="grid">
    <ng-template pTemplate="header">
        <div class="flex flex-column md:flex-row p-jc-md-between">
            <div class="p-inputgroup md:col-4 col-12 mb-3 p-0">
                <input type="text" pInputText placeholder="Search" (input)="dv.filter($any($event.target).value)"> 
                <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>        
            </div>
            <div class="col-auto ml-auto p-0">
                <span class="mr-3"><i class="pi pi-sort-amount-down mr-2"></i> Sort by</span>
                <p-dropdown [options]="sortOptions" [(ngModel)]="sortKey" placeholder="Sort By Price" (onChange)="onSortChange($event)"></p-dropdown>
            </div>
        </div>
        <p-divider></p-divider>
    </ng-template>
    <ng-template let-template pTemplate="listItem">
        <div class="col-12">
            <div class="template-list-item">
                <div class="template-list-detail">
                    <img src="/assets/logo_fibr_footer.svg" [alt]="template.template_name">
                    <div class="template-name">{{template.template_name}}</div>
                    <div class="template-description">{{template.description}}</div>
                    <p-rating [ngModel]="template.rating" [readonly]="true" [cancel]="false"></p-rating>
                    <i class="pi pi-tag template-category-icon"></i><span class="template-category">{{template.category}}</span>
                </div>
                <div class="template-list-action">
                    <span class="template-price">${{template.price}}</span>
                    <p-button icon="pi pi-shopping-cart" label="Add to Cart" [disabled]="template.inventoryStatus === 'OUTOFSTOCK'"></p-button>
                    <span [class]="'template-badge status-' + template.inventoryStatus.toLowerCase()">{{template.inventoryStatus}}</span>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template let-template pTemplate="gridItem">
        <div class="col-12 md:col-3">
        <div class="template-grid-item card">
            <div class=" center">
                <div>
                    <span class="template-category center" *ngIf="template.updated_date">{{template.updated_date.toDate() | date:'dd MMM yyyy'}}</span>
                </div>
            </div>
            <div class="template-grid-item-content">
                <img src="{{template.template_icon}}" [alt]="template.template_name">
                <p-rating [ngModel]="template.rating" [readonly]="true" [cancel]="false"></p-rating>
                <div class="template-name">{{template.template_name}}</div>
                <div class="template-description">by {{template.created_byname}}</div>
            </div>
            <div class="center">
                <p-button styleClass="p-button-secondary p-button-outlined" [style]="{'background-color': '#efefef'}"  icon="pi pi-shopping-cart" iconPos="right" [label]="'IDR '+ (template.price | kiloFormater:'decimal')"></p-button>
            </div>
        </div>
            <!-- <div class="template-grid-item card">
                <div class="template-grid-item-top">
                    <div>
                        <i class="pi pi-tag template-category-icon"></i>
                        <span class="template-category">{{template.category}}</span>
                    </div>
                    <span [class]="'template-badge status-' + template.inventoryStatus.toLowerCase()">{{template.inventoryStatus}}</span>
                </div>
                <div class="template-grid-item-content">
                    <img src="/assets/logo_fibr_footer.svg" [alt]="template.template_name">
                    <div class="template-name">{{template.template_name}}</div>
                    <div class="template-description">{{template.description}}</div>
                    <p-rating [ngModel]="template.rating" [readonly]="true" [cancel]="false"></p-rating>
                </div>
                <div class="template-grid-item-bottom">
                    <span class="template-price">${{template.price}}</span>
                    <p-button icon="pi pi-shopping-cart" [disabled]="template.inventoryStatus === 'OUTOFSTOCK'"></p-button>
                </div>
            </div> -->
        </div>
    </ng-template>
</p-dataView>