import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Template } from '../../interfaces/template';
import { TemplateService } from '../../services/crud/template.service';

@Component({
  selector: 'ui-fibr-dataview',
  templateUrl: './dataview.component.html',
  styleUrls: ['./dataview.component.scss']
})
export class DataviewComponent implements OnInit {
  @Input() sortOptions: SelectItem[] = [];
  @Input() dataViewModel: any ={};

  data: Template[]= [];
  sortOrder = -1;
  sortField = 'id';
  sortKey = '';
  @Output() status: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dataAndAction: EventEmitter<any> = new EventEmitter<any>();

  constructor(private templateService: TemplateService) { }

  ngOnInit(): void {
    this.templateService.getCollectionsPromise(this.dataViewModel.url, 'updated_date').subscribe((data : any[]) => {
      this.data = data;
      this.status.emit(this.data.length > 0);
    });
  }

  onSortChange(event: any) {
      const value = event.value;
      if (value.indexOf('!') === 0) {
          this.sortOrder = -1;
          this.sortField = value.substring(1, value.length);
      } else {
          this.sortOrder = 1;
          this.sortField = value;
      }
  }

  applyFilterGlobal(){
    this.data.filter
  }

  onclick(type: string, data: any){
    this.dataAndAction.emit({action : type, data: data})
  }

}
