import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  appConfig,
  configMobile,
  dataContent,
  formConfiguration,
  GlobalcrudService,
  GlobalService,
  GridModelStyle,
  GridModelText,
  layoutDesign,
  pageProperty,
  ShowMockupService,
} from "@core-fibr/shared";
import { MenuItem } from "primeng/api";

@Component({
  selector: 'ui-fibr-pwa-template',
  templateUrl: './pwa-template.component.html',
  styleUrls: ['./pwa-template.component.scss']
})
export class PwaTemplateComponent implements OnInit {

  data: any[] = [];
  dataSummary: any[] = [];
  urlPathFirebase = "";
  urlDataPathFirebase = "";

  appConfig: appConfig = {};
  configMobile: configMobile = {};
  pageProperty: pageProperty = {};
  layoutDesign!: layoutDesign;
  dataContent: dataContent = {};
  formConfiguration: formConfiguration = {};
  cartConfig: any;
  layoutStyle!: GridModelStyle;
  layoutText!: GridModelText;
  itemPages: MenuItem[] = [];
  paramater: any = {};
  actFrom = "app";
  isTemplate = true;

  blocked = false;
  pipe = new DatePipe('en-US');
  isPaid: boolean | undefined = false;
  isLogin: boolean | undefined = false;
  columnData: any[] =[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private globalServiceCrud: GlobalcrudService,
    private showPageDetailService: ShowMockupService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.paramater = params;
      this.globalService.setProjectEditStatus(true);
      this.urlPathFirebase = `/templates`;
      this.urlDataPathFirebase = `/templates/${params.appid}/tables`;
      this.globalServiceCrud
        .getcollectionwithid(this.urlPathFirebase, params.appid)
        .subscribe((res: any) => {
          // console.log(res);
          if (res?.app_id) {
            const appConfig: appConfig = {
              app_id: res.app_id,
              app_name: res.app_name,
              app_icon: res.app_icon,
              file_id: res.file_id,
              file_name: res.file_name,
              version: res.version,
              build_number: res.build_number,
              is_suspend: res.is_suspend,
              is_publish: res.is_publish,
              config: res.config,
              app_desc: res.app_desc,
              package_name: res.package_name,
              app_type: res.app_type,
              publish_option: res.publish_option,
              app_logo: res.app_logo,
              primary_color: res.primary_color,
              secondary_color: res.secondary_color,
              font_color: res.font_color,
              splash_screen: res.splash_screen,
              login_registration: res.login_registration,
              updated_date: res.updated_date,
              broadcast_content: res.broadcast_content,
              broadcast_title: res.broadcast_title,
              is_paid: res?.is_paid,
              max_rows: res?.max_rows,
              max_tables: res?.max_tables,
              max_pages: res?.max_pages,
              is_login: res?.is_login,
              is_publish_pwa: res?.is_publish_pwa,
              publish_pwa_date: res?.publish_pwa_date,
              upgrade_pro_date: res?.upgrade_pro_date,
              is_role: res?.is_role,
              status_project: res?.status_project,
              is_registration: res?.is_registration,
              paymentConfig: res?.paymentConfig
            };

            this.globalService.setAppConfig(appConfig);
            if (res.config == "") {
              console.log();
            } else {
              const con: any = JSON.parse(res.config);
              this.itemPages = con.itemPages ?? {};
              this.addCommandForModifyItemPages();
            }
            this.showPageDetailService.showAppConfig(res);
          }
        });
    });
  }

  ngOnInit(): void {

    this.globalService.appConfig.subscribe(
      (val: appConfig) => (this.appConfig = val)
    );
    this.globalService.isBlocked.subscribe(
      (val: boolean) => (this.blocked = val)
    );
    
  }

  initPageProperty() {
    this.pageProperty = {
      pageId: "",
      pageTitle: "",
      pageIcon: "",
      pagePadding: "",
      pageImagePosition: "",
      isMenu: false,
    };
  }

  initLayoutDesign() {
    this.layoutDesign = {
      pageId: "",
      layoutTemplate: "",
      layoutStyle: "card",
      layoutText: "",
      layoutPadding: "",
      layoutImagePosition: "left",
    };

    this.layoutStyle = {
      orientation: "vertical",
      column: "1",
      shape: "sq",
      border: "float",
      corners: "sharp",
      padding: "loose",
    };

    this.layoutText = {
      position: "below",
      fontsize: "small",
      aligment: "left",
      lettercase: "titlecase",
    };
  }

  initDataContent() {
    this.dataContent = {
      pageId: "",
      contentDataSource: "",
      contentPadding: "",
      contentImagePosition: "",
      contentTemplate: {},
      mainTitle: "",
      description: "",
      images: undefined,
      linkToDetails: "default",
      sectionDetails: [],
    };
  }

  initForm() {
    this.formConfiguration = {
      isEnable: false,
      add: false,
      edit: false,
      delete: false,
      formContent: [],
    };
  }

  initConfigMobile() {
    this.configMobile = {
      pageProperty: this.pageProperty,
      layoutDesign: this.layoutDesign,
      dataContent: this.dataContent,
      formConfiguration: this.formConfiguration,
    };
  }

  addCommandForModifyItemPages() {
    this.itemPages.forEach((element) => {
      if (element.id == "_" || element.id == "") {
        console.log();
      } else {
        element["command"] = (event) => {
          this.modifyPageMenu(event);
        };
      }
    });
  }

  modifyPageMenu($event: any) {
    let addFilter = {field: '', value: ''}
    if ($event?.params != undefined) {
      if ($event.from == 'link_detail') {
        const dtValue = $event.data[$event.params.dataContent.linkToDetailsConfig.relationField];
        const dtField = $event.params.dataContent.linkToDetailsConfig.foreignKey;
        addFilter = {field: dtField, value: dtValue}
      }
      if ($event.from == 'button_page') {
        const dtValue = $event.data[$event.params.relationField];
        const dtField = $event.params.foreignKey;
        addFilter = {field: dtField, value: dtValue}
      }
    }

    this.globalService.changeBlockui(true);
    const items = $event.item;

    this.initPageProperty();
    this.initLayoutDesign();
    this.initDataContent();
    this.initForm();
    this.initConfigMobile();

    this.layoutDesign = items.state.layoutDesign;
    if (items.state.dataContent.contentDataSource != "") {
      this.dataSourceChange({value: items.state, pwa: $event.bodyPwa, addFilter: addFilter});
      setTimeout(() => {this.getColumnData(items.state)},500);
    }
    // this.dataSourceChange({
    //   value: items.state.dataContent.contentDataSource,
    // });

    setTimeout(() => {
      this.formConfiguration.add = items.state.formConfiguration.add;
      this.formConfiguration.delete = items.state.formConfiguration.delete;
      this.formConfiguration.edit = items.state.formConfiguration.edit;

      this.pageProperty = items.state.pageProperty;
      this.layoutDesign = items.state.layoutDesign;
      this.dataContent = items.state.dataContent;
      this.formConfiguration = items.state.formConfiguration;
      this.cartConfig = items.state.cartConfig;

      this.configMobile = {
        pageProperty: this.pageProperty,
        layoutDesign: this.layoutDesign,
        dataContent: this.dataContent,
        formConfiguration: this.formConfiguration,
        cartConfig: this.cartConfig,
      };

      if (this.layoutDesign.layoutTemplate == "grid") {
        const layoutStyle: any = this.layoutDesign.layoutStyle;
        const layoutText: any = this.layoutDesign.layoutText;
        this.layoutStyle = layoutStyle;
        this.layoutText = layoutText;
      }
      this.globalService.changeBlockui(false);
    }, 1000);
  }

  dataSourceChange(event: any) {
    const url = this.urlDataPathFirebase + `/${event.value.dataContent.contentDataSource}/data`;

    if (event.value.layoutDesign.layoutTemplate == 'grid' || event.value.layoutDesign.layoutTemplate == 'list'
    || event.value.layoutDesign.layoutTemplate == 'cart') {
      let filterCol = '';
      let sortCol = '';
      if (!event.value.dataContent?.filter) filterCol = ''; else filterCol = event.value.dataContent?.filter[0]?.column;
      if (!event.value.dataContent?.sort) sortCol = ''; else sortCol = event.value.dataContent?.sort[0]?.column;
      
      if (filterCol == '' && sortCol == '') {
        this.data = [];
        let body: any = {};
        body = this.bodyDataNoFilterNoSort(event)
        if (event.addFilter.field == '') {
          this.showData(url,body, event.value);
        } else {
          this.showData(url,body, event.value, event.addFilter);
        }
      } else {
        // console.log('filter', this.paramater, this.appConfig)
        this.data = [];
        let body: any = {};
          body = this.bodyData(event);
          if (filterCol != '' && sortCol != '')
            this.showData(url,body, event.value, event.addFilter);
          if (filterCol != '' && sortCol == '')
            this.showData(url,body, event.value, event.addFilter);
          if (filterCol == '' && sortCol != '') 
            this.showData(url,body, event.value, event.addFilter);
      }
    }
    if (event.value.layoutDesign.layoutTemplate == 'summary') {
      this.data = [];
        let body: any = {};
        body = this.bodyDataSummary(event)
          this.globalServiceCrud.getColectionWithBody(url,body).subscribe((result: any) => {
            if (result != null) {
              this.data = result;
            }
          });
          this.globalServiceCrud.getColectionWithBody(url,body).subscribe((result: any) => {
            if (result != null) {
              this.dataSummary = result;
            }
          });
        
    }
    if (event.value.layoutDesign.layoutTemplate == 'details') {
      // 
    }
  }

  async getColumnData(e: any) {
    this.columnData = [];
    const tableName = e.dataContent.contentDataSource;

    let urlColumn = '';
    if (this.isTemplate) {
      urlColumn = `/templates/${this.paramater.appid}/tables`
    } 

    this.columnData = await this.globalServiceCrud.getCollectionwithidPromise(urlColumn, tableName)
    .then((res) => { 
      return res?.columns 
    });
    
  }

  showData(url: any, body: any, config: any, addFilter?: any){
    if (addFilter == undefined || addFilter?.field == '') {
      if (!this.appConfig.is_login) {
        this.getAllData(url,body);
      } else {
        const userProject = this.globalService.getCurrReffDataParsed("user_project");
        if (config.dataContent?.accessible?.type == 'all_users') {
          this.getAllData(url,body);
        }
        if (config.dataContent?.accessible?.type == 'user_created') {
          if (userProject?.role == 'fibr_creator') {
            this.getAllData(url,body);
          } else {
            this.globalServiceCrud.dynamicColectionSeacrh(url,body,true)?.then((result:any) => {
              if (result.docs != null) {
                this.data = [];
                result.docs.forEach((doc: any) => {
                  const rowdata: any = doc.data();
                  if (rowdata?.created_by == userProject.email) {
                    rowdata.id = doc.id;
                    this.data.push(rowdata);
                  }
                });
                this.globalService.convertDataApp(this.data)
              }
            });
          }
        }
        if (config.dataContent?.accessible?.type == 'specific_user') {
          if (userProject?.role == 'fibr_creator') {
            this.getAllData(url,body);
          } else {
            this.globalServiceCrud.dynamicColectionSeacrh(url,body,true)?.then((result:any) => {
              if (result.docs != null) {
                this.data = [];
                result.docs.forEach((doc: any) => {
                  const rowdata: any = doc.data();
                  // console.log(rowdata, rowdata[config.dataContent?.accessible?.specificUserColumn])
                  if (rowdata[config.dataContent?.accessible?.specificUserColumn] == userProject.email) {
                    rowdata.id = doc.id;
                    this.data.push(rowdata);
                  }
                });
                this.globalService.convertDataApp(this.data)
              }
            });
          }
        }
      }
    } else {
      this.getDataWithAddFilter(url,body,config,addFilter);
    }
  }

  getAllData(url:any, body:any) {
    this.globalServiceCrud.dynamicColectionSeacrh(url,body,true)?.then((result:any) => {
      if (result.docs != null) {
        this.data = [];
        result.docs.forEach((doc: any) => {
          this.pushData(doc);
        });
        this.globalService.convertDataApp(this.data)
      }
    });
  }

  getDataWithAddFilter(url:any, body:any, config:any, addFilter: any) {
    this.globalServiceCrud.dynamicColectionSeacrh(url,body,true)?.then((result:any) => {
      if (result.docs != null) {
        this.data = [];
        if (!this.appConfig.is_login) {
          result.docs.forEach((doc: any) => {
            const rowdata: any = doc.data();
            if (rowdata[addFilter?.field] == addFilter.value) {
              rowdata.id = doc.id;
              this.data.push(rowdata);
            }
          });
        } else {
          const userProject = this.globalService.getCurrReffDataParsed("user_project");
          if (config.dataContent?.accessible?.type == 'all_users') {
            result.docs.forEach((doc: any) => {
              const rowdata: any = doc.data();
              if (rowdata[addFilter?.field] == addFilter.value) {
                rowdata.id = doc.id;
                this.data.push(rowdata);
              }
            });
          }
          if (config.dataContent?.accessible?.type == 'user_created') {
            if (userProject?.role == 'fibr_creator') {
              result.docs.forEach((doc: any) => {
                const rowdata: any = doc.data();
                if (rowdata[addFilter?.field] == addFilter.value) {
                  rowdata.id = doc.id;
                  this.data.push(rowdata);
                }
              });
            } else {
              result.docs.forEach((doc: any) => {
                const rowdata: any = doc.data();
                if (rowdata?.created_by == userProject.email && rowdata[addFilter?.field] == addFilter.value) {
                  rowdata.id = doc.id;
                  this.data.push(rowdata);
                }
              });
            }
          }
          if (config.dataContent?.accessible?.type == 'specific_user') {
            if (userProject?.role == 'fibr_creator') {
              result.docs.forEach((doc: any) => {
                const rowdata: any = doc.data();
                if (rowdata[addFilter?.field] == addFilter.value) {
                  rowdata.id = doc.id;
                  this.data.push(rowdata);
                }
              });
            } else {
              result.docs.forEach((doc: any) => {
                const rowdata: any = doc.data();
                if (rowdata[config.dataContent?.accessible?.specificUserColumn] == userProject.email && rowdata[addFilter?.field] == addFilter.value) {
                  rowdata.id = doc.id;
                  this.data.push(rowdata);
                }
              });
            }
          }
        }
        this.globalService.convertDataApp(this.data)
      }
    });
  }

  showDataSort(url: any, body: any, config: any){
    if (config.pageProperty?.isShowAllData || !this.appConfig.is_login) {
      this.globalServiceCrud.dynamicColectionSort(url,body)?.then((result:any) => {
        if (result.docs != null) {
          this.data = [];
          this.data = this.globalService.makeData(result);
          this.globalService.convertDataApp(this.data)
        }
      });
    } else {
      const userProject = this.globalService.getCurrReffDataParsed("user_project");
      if (userProject.level == 'admin') {
        this.globalServiceCrud.dynamicColectionSort(url,body)?.then((result:any) => {
          if (result.docs != null) {
            this.data = [];
            this.data = this.globalService.makeData(result);
            this.globalService.convertDataApp(this.data)
          }
        });
      } else {
        this.globalServiceCrud.dynamicColectionSortWithUser(url,body,userProject.email)?.then((result:any) => {
          if (result.docs != null) {
            this.data = [];
            this.data = this.globalService.makeData(result);
            this.globalService.convertDataApp(this.data)
          }
        });
      }
    }
  }

  bodyData(event: any) {
    const body: any = {};
    body.first = 0;
    body.rows = 10;
    body.filters = [];
    if (event.pwa != undefined) body.reqFrom = 'pwa'; else body.reqFrom = 'config';
      if (event.pwa != undefined) {
        if (event.value.dataContent?.sort[0].column == event.pwa.sortField) {
          // console.log('kesini e')
          body.sortField = event.value.dataContent?.sort[0].column;
          body.sortOrder = event.value.dataContent?.sort[0].sortType;
        } else {
          // console.log('kesini b')
          body.sortField = event.pwa.sortField;
          body.sortOrder = event.pwa.sortOrder;
        }
      } else {
        if (!event.value.dataContent?.sort || event.value.dataContent?.sort[0].column == '') {
          body.sortField = 'created_date';
          body.sortOrder ='desc';
        } else {
          body.sortField = event.value.dataContent?.sort[0].column;
          body.sortOrder = event.value.dataContent?.sort[0].sortType;
        }
      }

    const column = event.value.dataContent?.filter[0].column;
    const valColumn = event.value.dataContent?.filter[0].value;
    const colType = event.value.dataContent?.filter[0].filterType;
    if (event.pwa != undefined) {
      if (event.pwa.filters.length == 0) {
        body.reqFrom = 'config'
        body.filters.push({propertyName: column, value: valColumn, matchMode: 'equals', columnType: colType, reqFrom: 'config'});
        // console.log('kesini c', body)
      } else {
        const cekValue = event.pwa.filters.filter((x:any) => x.value != "");
        // console.log(cekValue)
        if (cekValue.length == 0) {
          body.reqFrom = 'config'
          body.filters.push({propertyName: column, value: valColumn, matchMode: 'equals', columnType: colType, reqFrom: 'config'});
          // console.log('kesini a', body)
        } else {
          body.reqFrom = 'pwa'
          for (let i = 0; i < event.pwa.filters.length; i++) {
            if (column == event.pwa.filters[i].propertyName) {
              if (colType != 'date' || colType != 'time') {
                body.filters.push({propertyName: column, value: valColumn, matchMode: 'equals', columnType: '', reqFrom: 'pwa'});
              } else {
                body.filters.push({propertyName: event.pwa.filters[i].propertyName, value: event.pwa.filters[i].value, matchMode: 'equals', 
                columnType: '', reqFrom: 'pwa'});
              }
            } else {
              body.filters.push({propertyName: event.pwa.filters[i].propertyName, value: event.pwa.filters[i].value, matchMode: 'equals', 
                columnType: '', reqFrom: 'pwa'});
            }
          }
        }
        // console.log('kesini b', body)
      }
    } else {
      body.reqFrom = 'config'
      body.filters.push({propertyName: column, value: valColumn, matchMode: 'equals', columnType: colType, reqFrom: 'config'});
      // console.log('kesini h', body)
    }
    return body;
  }

  bodyDataNoFilterNoSort(event: any) {
    const body: any = {};
    body.first = 0;
    body.rows = 10;
    body.filters = [];
    if (event.pwa != undefined) {
      if (event.pwa.sortField != '') body.sortField = event.pwa.sortField; else body.sortField = 'created_date';
      if (event.pwa.sortOrder != '') body.sortOrder = event.pwa.sortOrder; else body.sortOrder = 'desc';
      for (let i = 0; i < event.pwa.filters.length; i++) {
        body.filters.push({propertyName: event.pwa.filters[i].propertyName, value: event.pwa.filters[i].value, matchMode: 'equals'});
      }
    } else {
      body.sortField = 'created_date';
      body.sortOrder ='desc';
    }
    return body;
  }

  bodyDataSummary(event: any) {
    const body: any = {};
    body.first = 0;
    body.rows = 10;
    body.filters = [];
    if (!event.value.layoutDesign?.summaryType || event.value.layoutDesign?.summaryType == 'data') {
      if (event.value.dataContent?.configSummaryTable?.content?.group?.column == undefined || 
        event.value.dataContent?.configSummaryTable?.content?.group?.column == '') {
        body.sortField = 'created_date';
        body.sortOrder ='desc';
      } else {
        body.sortField = event.value.dataContent?.configSummaryTable?.content?.group?.column;
        body.sortOrder = event.value.dataContent?.configSummaryTable?.content?.group?.sortType;
      }
    } else {
      body.sortField = 'created_date';
      body.sortOrder ='desc';
    }
    return body;
  }


  pushData(doc: any) {
    const rowdata = doc.data();
    rowdata.id = doc.id;
    this.data.push(rowdata);
  }

  convertData(result: any[]) {
    for (let index = 0; index < result.length; index++) {
      let c = 0, p;
      for (p in result[index]) {
        if (result[index].hasOwnProperty(p)) {
            c += 1;
            if (result[index][p] === null) {
              this.data[index][p] = '';
            } 
            if (typeof(result[index][p]) === 'object' && result[index][p].toString().substring(0,9) === 'Timestamp') {
              const frmTgl = result[index][p].toDate();
              this.data[index][p] = this.pipe.transform(frmTgl, 'dd MMM yyyy');
            } else {
              this.data[index][p];
            }
        }
      }
    }
  }

}
