import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-fibr-blockui',
  templateUrl: './blockui.component.html',
  styleUrls: ['./blockui.component.scss']
})
export class BlockuiComponent implements OnInit {
  @Input() blocked = false;
  osPlatform: any = null;
  constructor() { 
    this.osPlatform = this.getOS();
  }

  ngOnInit(): void {
    console.log();
  }

  getOS() {
    const userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'MacOS';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (/Linux/.test(platform)) {
      os = 'Linux';
    }
    return os;
  }

}
