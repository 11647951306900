/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-empty */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PrimeNGConfig, FilterMatchMode, SelectItem, MenuItem, ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { GridModel, GridColumnModel } from '../../../clases/global';
// import { jsPDF } from "jspdf";
// import { autoTable } from "jspdf-autotable";
import { HttpClient } from '@angular/common/http';
import { environment } from 'libs/auth/src/environments/environment';
import { GlobalService } from '../../../services/global.service';
import { GlobalcrudService } from '../../../services/crud/globalcrud.service';
import { AuthenticationService } from '@core-fibr/auth';
import { GoogleAuthService } from 'ng-gapi';
import { DynamicdialogService } from '../../../services/dynamicdialog.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AddColumnsComponent } from '../../dynamicdialogs/add-columns/add-columns.component';
import { takeUntil } from 'rxjs/operators';
import { EditColumnsComponent } from '../../dynamicdialogs/edit-columns/edit-columns.component';
import { result, values } from 'lodash';
import { FileDocumentModel } from '@core-fibr/dynamic-form';
import { Confirmation, ConnectionService, ToastNotificationService } from '@core-fibr/shared';
import { DatePipe } from '@angular/common';
import { UpdateTableComponent } from '../../dynamicdialogs/update-table/update-table.component';
import { HttpHeaders } from '@angular/common/http';
import { ResizeImageService } from 'libs/shared/src/lib/services/resize-image.service';
import { take } from 'rxjs/operators';
import { GsheetService } from 'apps/fibr-portal/src/app/components/_shared/services/gsheet.service';
import { ParserService } from '../../../services/parser.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ui-fibr-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit, AfterViewInit {
  user: any = {};
  @ViewChild('table') table: Table | undefined;
  @Input() tableId = 'table-el';
  @Input() refreshGrid: boolean | undefined;
  @Input() isMockData = false;
  @Input() isOpenInModal = false;
  @Input() columns: any[] = [];
  @Input() gridModel: GridModel = new GridModel();
  @Input() Filters = [];
  @Input() isfirebase = false;
  @Input() isGoogleFiles = false;
  @Input() params: any;

  @Output() fetchData = new EventEmitter<any>();
  @Output() dataSelect = new EventEmitter<any>();
  @Output() refreshTableGrid = new EventEmitter<any>();
  @Output() removeTableGrid = new EventEmitter<any>();
  @Output() addTableGrid = new EventEmitter<any>();
  @Output() showUpload = new EventEmitter<boolean>();
  @Output() removeMultipleRow = new EventEmitter<any>();
  @Output() isSubmit = new EventEmitter<any>();
  @Output() deleteTable = new EventEmitter<any>();

  public isLoading = false;
  public paginator = true;
  public showCurrentPageReport = true;
  public isVisible = true;
  showFilterRow = false;

  // change data to use getter and setter
  public data: any;
  private datasource: any = [];
  private _gm: GridModel | undefined;
  private _gridModel = new BehaviorSubject<GridModel>(new GridModel());
  matchModeOptions: SelectItem[] = [];
  lastLazyLoadEvent: any | undefined;
  additionalColumns: any[] = [];
  parameterColumns: any[] = [];
  currReffDataParsed: any[] | undefined;
  selectData: any = {};
  iconType = "";
  showImageUploadOpt = false;
  itemTable: MenuItem[] = [];
  confirmationTemplate: Confirmation | undefined;
  showDateTimeType = false;

  public currentPageReportTemplate = 'Showing {first} to {last} of {totalRecords} entries';
  LOCAL_STORAGE_KEY = 'CURRENT_REFERENCE_DATA';

  public rowsPerPage = 10;
  public rowsPerPageOptions = [10, 25, 50];
  public totalRecords = 0;
  emptyTemplateColSpan = 0;
  currentPage = 0;
  first = 0;
  body: any;
  public multipleSelection: any = [];
  isMultipleSelection = false;
  @Output() multipleSelectionReturn = new EventEmitter<any>();

  valueData: any = new Date();
  dataColumn: any = {};
  nameField: any = "";
  typeField: any = "";
  fileImage: any = [];
  isUploadImage = false;
  pipe = new DatePipe('en-US');
  pathProjectFirebase = "";
  textSearch = "";
  isPaid = false;
  max_pages = 0;
  max_rows = 0;
  max_tables = 0;
  max_upload = 0;
  statusProj = "";
  countGetToken = 0;
  optionsRole: any = [];
  headers: any = "";
  columnForSetting: any = [];
  columnShow: any = [];
  showSettingColumn = false;
  showImportTable = false;
  showExportTable = false;
  gbrQrcode ='/assets/icon_qrcode.png';

  static initColumns(columnList: (GridColumnModel)[] | undefined): any[] | undefined {
    // TODO: apply translations to headers using i18n?
    return columnList;
  }

  constructor(private primengConfig: PrimeNGConfig, private http: HttpClient,
    private globalService: GlobalService, private globalCrudService: GlobalcrudService,
    private authService: AuthenticationService, private googleAuthService: GoogleAuthService,
    private dynamicDialogService: DynamicdialogService, public dynamicDialogConfig: DynamicDialogConfig,
    private cdr: ChangeDetectorRef, private toastNotificationService: ToastNotificationService,
    private connectionService: ConnectionService, private confirmService: ConfirmationService,
    private compressImage: ResizeImageService,
    private _gsheetService: GsheetService,
    private parser: ParserService,
    private translate: TranslateService,
  ) {
    this.user = this.globalService.userprofile;
    // this.typeBoolean = [
    //   { value: "false", label: "false" },
    //   { value: "true", label: "true" }
    // ];
  }

  ngOnInit(): void {
    this.globalService.appConfig.subscribe((res) => {
      let ispaid: any = res.is_paid;
      this.isPaid = ispaid;
      if (!res.is_paid) {
        this.limitTable('/config/facilities/data', 'free');
        this.statusProj = 'Free';
      } else {
        this.limitTable('/config/facilities/data', 'paid');
        this.statusProj = 'Pro';
      }
    });

    this.isLoading = true;
    this.primengConfig.ripple = true;
    this._gm = new GridModel();
    if (this.gridModel?.RowsPerPageOptions != null) {
      if (this.gridModel.RowsPerPageOptions.length > 0) {
        this.rowsPerPageOptions = this.gridModel.RowsPerPageOptions;
        this.rowsPerPage = this.rowsPerPageOptions[0];
      }
    }

    this.matchModeOptions = [
      { label: 'Equals', value: FilterMatchMode.EQUALS },
      { label: 'Not Equals', value: FilterMatchMode.NOT_EQUALS },
    ];

    this.getAdditionalColumns();
    this.getParameterColumns();


    this.itemTable = [
      {
        label: this.translate.instant("editor.grid.show.hide.column"),
        icon: 'pi pi-fw pi-list',
        styleClass: "menu-blue",
        command: () => {
          this.settingColumns();
        }
      },
      {
        label: 'Download CSV',
        icon: 'pi pi-fw pi-download',
        styleClass: "menu-blue",
        command: () => {
          // this.exportCSV();
          this.notifExport('csv')
        }
      },
      // {
      //     separator:true
      // },
      // {
      //   label: '<img alt="icon" src="/assets/images/icon/icon_pi-file-import.svg" height="15" class="mr-2"> Import Table', 
      //   escape: false,
      //   styleClass: "menu-static",
      //   command: () => {
      //     this.showImportTable = true;
      //   }
      // },
      {
        label: `<img alt="icon" src="/assets/images/icon/icon_pi-file-export.svg" height="15" class="mr-2"> ${this.translate.instant('editor.grid.export')}`,
        escape: false,
        // icon: 'pi pi-file-export',
        styleClass: "menu-static",
        // disabled: true,
        command: () => {
          this.showExportTable = true;
        }
      },
      // {
      //   label: 'Edit Table', 
      //   icon: 'pi pi-fw pi-pencil',
      //   styleClass: "menu-blue",
      //   command: () => {
      //     this.actionTable('update');
      //   }
      // },
      {
        separator: true
      },
      {
        label: 'Delete Table',
        icon: 'pi pi-fw pi-trash',
        styleClass: "menu-magenta",
        command: () => {
          this.actionTable('delete');
        }
      },
    ];
  }

  limitTable(path: any, collection: any) {
    this.globalCrudService.getcollectionwithid(path, collection).subscribe((resp: any) => {
      this.max_pages = resp.max_pages;
      this.max_tables = resp.max_tables;
      this.max_rows = resp.max_rows;
      this.max_upload = resp.max_upload;
    });
  }

  notifExport(act: any){
    const body = {First: 0,Rows: 10,SortField: 'created_date',SortOrder: 'desc',Filters: [], action: 'downloadCsv'}
    this.gridModel.isPaginator = false;
    this.globalService.changeBlockui(true)
    this.getfirebaseSearchWithBody(body);
      this.confirmService.confirm({
        key:"subheader",
        header: `Download ${act}`,
        message: `Do you want to Download ${act}?`,
        dismissableMask: true,
        acceptLabel : `Download ${act}`,
        rejectLabel : 'Cancel',
        acceptIcon: 'null',
        rejectIcon: 'null',
        acceptButtonStyleClass: "p-button-success",
        rejectButtonStyleClass: 'p-button-secondary',
        accept: () => {
          if (act == 'csv') this.exportCSV();
        },
        reject: () => {
          this.refresh();   
        }
      });
  }

  exportCSV() {
    let csvData: any | undefined = this.table?.value;
    for (let index = 0; index < csvData.length; index++) {
      let c = 0, p: string;
      for (p in csvData[index]) {
        if (csvData[index].hasOwnProperty(p)) {
          c += 1;
          if (csvData[index][p] == null) {
            csvData[index][p] = '';
          }
          if (typeof (csvData[index][p]) == 'object' && csvData[index][p].toString().substring(0, 9) == 'Timestamp') {
            const frmTgl = csvData[index][p].toDate();
            csvData[index][p] = this.pipe.transform(frmTgl, 'dd MMM yyyy');
          }
          if (typeof (csvData[index][p]) == 'string') {
            csvData[index][p];
          }
        }
      }
    }
    this.table?.exportCSV(csvData);
    this.refresh();
  }

  disabledNav(event: any) {
    if (event.key == 'ArrowRight' || event.key == 'ArrowLeft')
      event.stopPropagation();
  }

  actionTable(act: any) {
    const pathProject = `/users/${this.user.uid}/projects`;
    const pathDataProject = `/users/${this.user.uid}/data_projects`;
    const split = this.gridModel.APIEndpoint!.split('/');
    const appId = split[4];

    const split2 = this.gridModel.APIEndpoint!.split('/');
    split2.splice(split2.length - 2, 2);
    const pathTable = split2.join('/');
    if (act == 'delete' && (split[3] == 'userapp_fibr' || split[3] == 'roleapp_fibr' || split[6] == 'cart_transactions' 
    || split[6] == 'button_transactions' || split[3] == 'detail_payment_transaction_fibr')) {
      this.toastNotificationService.showNotification("error", "Table can not be deleted!");
    } else {
      // this.globalCrudService.getDynamicCollectionwithidPromise(pathProject, appId).then((res: any) => {
      this.globalCrudService.getCollectionwithidPromise(pathProject, appId).then((res: any) => {
        // if (res.is_paid == true) {
        //   this.toastNotificationService.showNotification("error", "Delete Table " + this.gridModel.GridName + " error, Project is Pro!");
        // }
        // else 
        if (res.config == "") {
          if (act == 'delete') {
            this.execDeleteTable(pathDataProject, appId);
          }
        } else {
          let tableUsed = false;
          const con = JSON.parse(res?.config);
          tableUsed = this.globalService.cekTblUse(con,this.gridModel.GridName);
          // let itemPages = [];
          // itemPages = con.itemPages ?? {};
          // for (let ip = 0; ip < itemPages.length; ip++) {
          //   if (itemPages[ip]?.state?.dataContent.contentDataSource == this.gridModel.GridName) {
          //     tableUsed = true;
          //   }
          // }
          if (act == 'delete') {
            if (tableUsed) {
              this.toastNotificationService.showNotification("error", "Delete Table " + this.gridModel.GridName + " error, table is already in use!");
            } else {
              this.execDeleteTable(pathDataProject, appId);
            }
          }
        }
      });
    }
  }

  execDeleteTable(pathDataProject: string, appId: string) {
    this.confirmationTemplate = {
      header: "Confirmation!",
      content: "Click Yes, if you want to delete the table?",
      acceptButtonStyleClass: "p-button-danger",
      rejectButtonStyleClass: "p-button-secondary"
    };
    this.confirmService.confirm({
      message: this.confirmationTemplate.content,
      header: this.confirmationTemplate.header,
      acceptButtonStyleClass: this.confirmationTemplate.acceptButtonStyleClass,
      rejectButtonStyleClass: this.confirmationTemplate
        .rejectButtonStyleClass,
      accept: () => {
        this.globalService.changeBlockui(true);
        // this.globalCrudService.getDynamicCollectionwithid(pathDataProject, appId).subscribe((result: any) => {
        this.globalCrudService.getcollectionwithid(pathDataProject, appId).subscribe((result: any) => {
          let itemDataFile: any[] = [];
          itemDataFile = result.key;
          for (let idf = 0; idf < itemDataFile.length; idf++) {
            if (itemDataFile[idf] == this.gridModel.GridName) {
              itemDataFile.splice(idf, 1);
            }
          }
          this.globalCrudService.modifyRefDynamiccollectionwithdocumentname({ key: itemDataFile }, pathDataProject, appId); // ######
          this.globalCrudService.deleteCollection(`${pathDataProject}/${appId}/tables/`, this.gridModel.GridName ?? "");

          this.pathProjectFirebase = `/users/${this.user.uid}/projects/${appId}/firebase`;
          this.globalCrudService.getcollection(this.pathProjectFirebase).subscribe((res: any) => {
            if (res[0]?.state == 'ACTIVE') {
              this.globalCrudService.modifyRefCollection({ key: itemDataFile }, pathDataProject, appId); // ######
              this.globalCrudService.deleteDynamicCollection(`${pathDataProject}/${appId}/tables/`, this.gridModel.GridName ?? "");
            }
          });
        });
        setTimeout(() => {
          // location.reload();
          this.onDeleteTableClick();
          this.globalService.changeBlockui(false);
        }, 3000);
      },
      reject: () => console.log()
    });
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  getAdditionalColumns(): void {
    this.additionalColumns = [];
    for (let ii = 0; ii < this.columns.length; ii++) {
      const col = this.columns[ii];
      if (col.additional) {
        this.additionalColumns.push(col);
      }
    }
  }

  getParameterColumns(): void {
    this.parameterColumns = [];
    for (let ii = 0; ii < this.columns.length; ii++) {
      const col = this.columns[ii];
      if (col.parameter) {
        this.parameterColumns.push(col);
      }
    }
  }

  filterGlobal(value: any, type: any) {
    // console.log(this.lastLazyLoadEvent)
    this.lastLazyLoadEvent.filters = { 'a_b': value };

    this.lastLazyLoadEvent.filters = [];
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].type == 'text' || this.columns[i].type == 'string')
        this.lastLazyLoadEvent.filters.push({ propertyName: this.columns[i].field, value: value, matchMode: 'equals' });
    }
    this.lazyLoadData(this.lastLazyLoadEvent);
  }

  refresh() {
    this.lazyLoadData(this.lastLazyLoadEvent);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.refreshGrid?.currentValue) {
      this.refresh();
    }
  }

  lazyLoadData(event: any): void {
    this.lastLazyLoadEvent = event;
    this.isLoading = true;
    const body: any = {};
    if (this.isfirebase) {
      this.gridModel.Data = [];
      this.createLazyLoadBodyRequest(body, event);
      const split = this.gridModel.APIEndpoint!.split('/');
      // console.log(body,'--', this.gridModel.APIEndpoint!.split('/'), '--',this.gridModel.GridName)
      this.connectionService.swicthFirebaseGrid(split[2], split[4], this.gridModel.GridName);
      if (this.gridModel.GridName == 'cart_transactions' || this.gridModel.GridName == 'button_transactions' || 
      this.gridModel.GridName == 'detail_payment_transaction_fibr') {
        this.gridModel.isEditor = false;
      } else {
        this.gridModel.isEditor = true;
      }
      
      if (body.Filters.length == 0) {
        this.gridModel.isPaginator = true;
        this.getfirebaseWithBody(body);
      } else {
        this.gridModel.isPaginator = false;
        this.getfirebaseSearchWithBody(body);
      }
    }
    if (this.isGoogleFiles) {
      this.gridModel.isPaginator = false;
      if (this.authService.getTokenGoogleSheet() != '') {
        this.getGoogleSheets();
      } else {
        this.googleAuthService.getAuth()
          .subscribe((auth: any) => {
            auth.signIn().then((res: any) => {
              this.authService.signInSuccessGoogleSheetsHandler(res);
              this.getGoogleSheets();
            });
          });
      }
    }
  }

  getfirebase() {
    let count = 0;
    // this.globalCrudService.get_id_dynamicColection(this.gridModel.APIEndpoint!)
    this.globalCrudService.get_id_dynamicColection_with_order(this.gridModel.APIEndpoint!, 'created_date', 'desc')
      .subscribe((results: any) => {
        if (results != null) {
          this.datasource = results;
          if ([environment.apiEndpoint].includes(this.gridModel.APIEndpoint!)) {
            results.forEach((doc: any) => {
              let obj: any = {};
              obj['id'] = doc.id;
              this.gridModel.Data.push(obj);
            });
            this.paginator = true;
          } else {
            if (results.docs.length > 0) {
              results.forEach((doc: any) => {
                let obj = doc.data();
                if (count == 0 && this.columns.length == 0) {
                  const c = this.makingTableColumns(obj);
                  c.then((res) => {
                    this.columns = res;
                    // console.log('this.columns',this.columns);
                  });
                }
                obj['id'] = doc.id;
                this.gridModel.Data.push(obj);
                if ((count === (results.size - 1)) && this.gridModel.isAddBlankRow) {
                  this.addBlankRow(obj);
                }
                count = count + 1;
              });
            } else {
              this.afterDeleteLastRow()
            }
            this.paginator = false;
          }
          this.fetchData.emit(this.gridModel.Data);
        }
        this.totalRecords = this.gridModel.Data.length;
        // this.totalRecords = this.gridModel.Data.length - 1;
        const linkColumns = this.columns.filter(el => el.type?.toLowerCase() === 'link');
        this.isLoading = false;
      });
  }

  async getfirebaseWithBody(body: any) {
    this.gridModel.Data = [];
    await this.globalCrudService.getCountCollection(this.gridModel.APIEndpoint!)
    .then(x => this.totalRecords = x);
    
    this.globalCrudService.getDynamicCollectionsWithBody(this.gridModel.APIEndpoint!, body)
    .then((results: any) => {
      this.configurationGrid(results, this.totalRecords);
    })
  }

  getfirebaseSearchWithBody(body: any) {
    this.gridModel.Data = [];
    body.filters = body.Filters;
    body.sortField = body.SortField;
    body.sortOrder = body.SortOrder;
    
    this.globalCrudService.dynamicColectionSeacrh(this.gridModel.APIEndpoint!, body)?.then((results: any) => {
      this.configurationGridSearch(results);
      if (body?.action == 'downloadCsv') {
        this.globalService.changeBlockui(true)
        setTimeout(() => {
          this.globalService.changeBlockui(false)
        }, 10 * results.size)
      }
    });
  }

  async configurationGrid(results: any, totalRecordsFromResults?: any) {
    let count = 0;
    this.totalRecords = 0;
    this.gridModel.Data = [];
    if (results != null) {
      this.datasource = results;
      if (this.gridModel.APIEndpoint == ('/sifa_staging')) {
        results.forEach((doc: any) => {
          let obj: any = {};
          obj['id'] = doc.id;
          this.totalRecords += 1;
          this.gridModel.Data.push(obj);
        });
        this.paginator = false;
      } else {
        /**untuk kebutuhan data calculation */
        const split = this.gridModel.APIEndpoint!.split('/');
        split.splice(split.length - 2, 2);
        const path = split.join('/');
        // let colStruct = this.globalService.cekAppId(this.gridModel.GridName!)
        // let col = await this.globalCrudService.getDynamicCollectionwithidPromise(path, colStruct).then((res) => { return res?.columns })
        // let colCalculation = col.filter((x: any) => x.type == 'calculation');
        /**end */
        if (results.docs.length > 0) {
          results.docs.forEach((doc: any) => {
            // let obj = doc.data();
            let obj = this.globalService.convertDataOneOriginal(doc.data());
            if (count == 0 && this.columns.length == 0) {
              const c = this.makingTableColumns(obj);
              c.then((res) => {
                this.columns = res;
              });
            }
            obj['id'] = doc.id;
            // this.getIdentifyTypeNum(obj,colCalculation);
            this.gridModel.Data.push(obj);
            if ((count === (results.docs.length - 1)) && this.gridModel.isAddBlankRow) {
              this.addBlankRow(obj);
            }
            count++;
          });
        } else {
          this.afterDeleteLastRow()
        }
      }
      this.fetchData.emit(this.gridModel.Data);
    }
    this.totalRecords = totalRecordsFromResults;
    const linkColumns = this.columns.filter(el => el.type?.toLowerCase() === 'link');
    this.isLoading = false;
  }

  async configurationGridSearch(results: any) {
    let count = 0;
    this.gridModel.Data = [];
    if (results.docs.length > 0) {
      results.docs.forEach((doc: any) => {
        // let obj = doc.data();
        // let obj = this.globalService.convertDataOne(doc.data());
        let obj = this.globalService.convertDataOneOriginal(doc.data());
        if (count == 0 && this.columns.length == 0) {
          const c = this.makingTableColumns(obj);
          c.then((res) => {
            this.columns = res;
          });
        }
        obj['id'] = doc.id;
        this.gridModel.Data.push(obj);
        if ((count === (results.docs.length - 1)) && this.gridModel.isAddBlankRow) {
          this.addBlankRow(obj);
        }
        count++;
      });
    }
    this.fetchData.emit(this.gridModel.Data);
    this.isLoading = false;
  }

  afterDeleteLastRow() {
    const split = this.gridModel.APIEndpoint!.split('/');
    split.splice(split.length - 2, 2);
    const pathTable = split.join('/');
    const basket: any = []

    let colStruct = this.globalService.cekAppId(this.gridModel.GridName!)
    this.globalCrudService.getDynamicCollectionwithidPromise(pathTable, colStruct)
      .then((response) => {
        response.columns.forEach((col: any) => {
          basket.push(col?.field);
        });
        const fieldTable = Object.values(basket).map(key => ({ field: key }));
        const body: any = {}
        fieldTable.forEach((doc: any) => {
          if (doc.field == 'created_date' || doc.field == 'modify_date') {
            doc.value = new Date();
            body[doc?.field] = doc?.value
          } else {
            doc.value = '';
            body[doc?.field] = doc?.value
          }
        });
        this.globalCrudService.addDynamicCollection(body, this.gridModel.APIEndpoint!);
        this.refresh();
      });
  }

  getGoogleSheets() {
    const _this = this;
    _this.columns = [
      { "type": "icon", "header": "Type" },
      { "field": "name", "header": "Filename", "sortable": true, "filterable": true },
      { "type": "button2", "header": "Actions", "field": [{ "Title": "Select" }] }
    ];

    gapi.load('client', () => {
      const options: gapi.client.RequestOptions = {
        path: `/drive/v3/files?q=mimeType='application/vnd.google-apps.spreadsheet'`,
        method: 'GET',
        params: {},
        body: {}
      };

      const request: gapi.client.HttpRequest<any> = gapi.client.request(options);
      request.execute((result: any) => {
        if (result != null) {
          _this.fetchData.emit(result);
          _this.totalRecords = result.files.length;
          _this.gridModel.Data = result.files;
        }
        const linkColumns = _this.columns.filter(el => el.type?.toLowerCase() === 'link');
        _this.isLoading = false;
        _this.cdr.detectChanges();
      });
    });
  }

  fetchMockData(): Observable<any> {
    const url: any = this.gridModel.APIEndpoint ? this.gridModel.APIEndpoint : "./assets/json/projectlist.json";
    return this.http.get(url);
  }

  async makingTableColumns(topOneResult: any) {
    let columns: any[] = [];
    const split = this.gridModel.APIEndpoint!.split('/');
    split.splice(split.length - 2, 2);
    const path = split.join('/');
    // console.log(split,path)

    const split2 = this.gridModel.APIEndpoint!.split('/');
    const appId = split2[4];
    let colStruct = this.globalService.cekAppId(this.gridModel.GridName!)
    const b = await this.globalCrudService.getDynamicCollectionwithidPromise(path, colStruct)
      .then((res) => {
        if (res?.columns && res?.columns?.length > 0) {
          /** 20230726 add property showInGrid and showInForm in column, ada kemungkinan jika sudah terpenuhi semua table bisa di hide case ini */
          if (res?.columns[0]?.showInGrid == undefined || res?.columns[0]?.showInForm == undefined) {
            for (let x = 0; x < res?.columns.length; x++) {
              if (!res?.columns[x]?.showInGrid) res.columns[x].showInGrid = true;
              if (!res?.columns[x]?.showInForm) res.columns[x].showInForm = true;
            }
            this.globalCrudService.createDynamiccollectionwithdocumentid({ columns: res.columns }, path, colStruct).then(() => console.log());
            for (let x = 0; x < res?.columns.length; x++) {
              if (res?.columns[x]?.showInGrid) columns.push(res?.columns[x]);
            }
          } else {
            for (let x = 0; x < res?.columns.length; x++) {
              if (res?.columns[x]?.showInGrid) columns.push(res?.columns[x]);
            }
          }
          /**disini add column invoice untuk tbl detail_payment_transaction_fibr_struct */
          if (colStruct == 'detail_payment_transaction_fibr_struct') {
            const colInv = columns.find((x:any) => x.field == 'payment_invoice');
            if (colInv == undefined) {
              columns.push({field:'payment_invoice', header: 'Invoice', type: 'link', showInGrid: true, showInForm: true,filterable: true,sortable:true,col_order:12})
              this.globalCrudService.createDynamiccollectionwithdocumentid({ columns: columns }, path, colStruct).then(() => console.log());
            }
          }
        } else {
          Object.keys(topOneResult).map((key: string) => {
            if (key != 'id') columns.push({
              "field": key, "header": key, "sortable": true, "filterable": true, type: this.getType(key), 'showInGrid': true, 'showInForm': true
            })
          });
          // if (colStruct == 'userapp_fibr_struct')
          //   this.globalCrudService.createcollectionwithdocumentname({appId: appId}, path, appId);

          this.globalCrudService.createDynamiccollectionwithdocumentid({ columns: columns }, path, colStruct).then(() => console.log())
          this.pathProjectFirebase = `/users/${this.user.uid}/projects/${appId}/firebase`;
          this.globalCrudService.getcollection(this.pathProjectFirebase).subscribe((res: any) => {
            if (res[0]?.state == 'ACTIVE') {
              this.globalCrudService.createcollectionwithdocumentname({ columns: columns }, path, colStruct)
                .then(() => console.log());
            }
          });
        }
        // columns = columns.sort((a,b) => (a.header > b.header) ? 1 : ((b.header > a.header) ? -1 : 0));
        return columns;
      })
    return b;
  }

  getType(key: string) {
    let result;
    if (['created_date', 'modify_date', 'dob', 'tanggal', 'updated_date'].includes(key.toLowerCase())) result = 'date';
    else if (key.toLowerCase() == 'foto' || (key.toLowerCase()).includes('icon') || (key.toLowerCase()).includes('avatar') || (key.toLowerCase()).includes('image')) result = 'image'
    return result ?? 'string'
  }

  setLocalizationDataInPayload(payloadDataRow: any): void {
    //
  }

  setAdditionalColumnsInPayload(payloadDataRow: any): void {
    for (let ii = 0; ii < this.additionalColumns.length; ii++) {
      const col = this.additionalColumns[ii];
      const currentLocal = payloadDataRow['Localization'].find((el: any) => el.AppLanguageId === environment.localization) //TODO change to login value later
      payloadDataRow[col.field] = currentLocal != null ? currentLocal[col.field] : null;
    }
  }

  setParameterDataInPayload(payloadDataRow: any): void {
    let currRefDataParamList = [];
    if (this.gridModel != null) {
      if (this.gridModel.GridId) {
        if (this.gridModel.GridId['ReferenceDataType']) {
          const currRefData = this.gridModel.GridId['ReferenceDataType'];
          const paramList = this.globalService.referenceDataParameterList as Array<any>;
          const paramCurrRefData = paramList.find(el => el.ReferenceDataType === currRefData);
          if (paramCurrRefData != null) {
            if (paramCurrRefData.Details != null) {
              currRefDataParamList = paramCurrRefData.Details;
            }
          }
        }
      }
    }

    for (let ii = 0; ii < this.parameterColumns.length; ii++) {
      const col = this.parameterColumns[ii];
      const currField = currRefDataParamList.find((el: any) => el.ParameterColumn.toLowerCase() === col.field.toLowerCase());

      payloadDataRow[col.field] = payloadDataRow.ParameterData[col.field];
      if (currField != null) {
        if (currField.Referential) {
          const currRefData = this.currReffDataParsed as Array<any>;
          const currColumnRefData = currRefData
            .find(el => el.ReferenceDataType.replaceAll('_', '').toLowerCase() === col.field.toLowerCase());
          if (currColumnRefData != null) {
            const currColumnRefDataDetail = currColumnRefData.Details.find((el: any) => el.Code === payloadDataRow.ParameterData[col.field]);
            if (currColumnRefDataDetail != null) {
              payloadDataRow[col.field] = currColumnRefDataDetail.CodeDescription;
            }
          }
        }
      }
    }
  }

  getSortOrder(event: any) {
    let val;
    if (event?.sortField) val = event?.sortOrder == 1 ? 'desc' : 'asc'
    else val = this.gridModel?.SortDir ?? 'asc';
    return val;
  }

  createLazyLoadBodyRequest(body: any, event: any): void {
    // console.log(event)
    // if( Object.keys(this.gridModel?.GridId).length > 0) body['whereField'] = [];
    // Object.keys(this.gridModel?.GridId).forEach(item => {
    //   body[item] = this.gridModel.GridId[item];
    //   if(Object.keys(this.gridModel?.GridId).length > 1) body['whereField'].push(item);
    //   else body['whereField'] = item;
    // })
    /**research 1 gagal dua param column harus membuat index di firebase-- ga memungkinkan */
    // body['whereField'] = [];
    // const whereField = Object.keys(event.filters).map(key => ({field: key, value: event.filters[key]}));
    // console.log(whereField)
    // if(whereField.length <= 0) body['whereField'] = [];
    // if(whereField.length > 0 && whereField[0].value != '') {
    //   whereField.forEach(item => {
    //     body['whereField'].push(item);
    //   })
    // } else {
    //   body['whereField'] = [];
    // }

    this.gridModel.SortDir = 'desc';
    this.gridModel.SortBy = 'created_date';
    body.First = event?.first ?? 0;
    body.Rows = event?.rows ?? 10;
    body.SortField = event?.sortField ?? (this.gridModel.SortBy ?? 'created_date');
    body.SortOrder = this.getSortOrder(event);
    body.Filters = [];

    // handle empty filter
    if (event == null || Object.keys(event?.filters).length <= 0) {
      // no action
    } else {
      for (let ii = 0; ii < Object.keys(event?.filters).length; ii++) {
        const item = Object.keys(event?.filters)[ii];
        if (event?.filters[item].value != null) {
          const newFilter = {
            propertyName: event?.filters[item].propertyName,
            value: event?.filters[item].value,
            matchMode: event?.filters[item].matchMode
          }
          if (event?.filters[item].value != '') body.Filters.push(newFilter);
        }
      }
    }
    // set input filters as body filters
    if (this.Filters.length > 0) {
      body.Filters = this.Filters;
    }
    this.body = body;
  }

  // createLazyLoadBodyRequest(body: any, event: any): void {
  //   Object.keys(this.gridModel?.GridId).forEach(item => {
  //     body[item] = this.gridModel.GridId[item];
  //   })
  //   //body.AggregateIdentifier = this.gridModel.GridId; //this.gridModel.AggregateIdentifier;
  //   body.First = event?.first;
  //   body.Rows = event?.rows;
  //   body.SortField = event.sortField ?? '';
  //   body.SortOrder = event.sortOrder;
  //   body.Filters = [];
  //   //handle empty filter
  //   if (Object.keys(event.filters).length <= 0) {

  //   } else {
  //     for (let ii = 0; ii < Object.keys(event.filters).length; ii++) {
  //       const item = Object.keys(event.filters)[ii];
  //       if(event.filters[item].value != null){
  //         const newFilter = {
  //           PropertyName: item,
  //           Value: event.filters[item].value,
  //           MatchMode: event.filters[item].matchMode
  //         }
  //         body.Filters.push(newFilter);
  //       }
  //     }
  //   }
  //   // set input filters as body filters
  //   if (this.Filters.length > 0) {
  //     body.Filters = this.Filters;
  //   }
  // }

  onButtonActionClick(button: any, data: any): void {
    this.fetchData.emit({ event: button, id: data[button.ForeignKey], type: 'buttonAction', data: data });
    // this.dataSelect.emit({ event: button, id: data[button.ForeignKey], type: 'buttonAction', data: data });
  }

  onButtonActionClick2(button: any, data: any): void {
    this.dataSelect.emit({ event: button, id: data[button.ForeignKey], type: 'buttonAction', data: data });
  }

  onButtonLinkActionClick(button: any, data: any): void {
    const navigationLink = this.createNavigationLink(button, data);

    this.fetchData.emit({ event: button, data: data, navigationLink: navigationLink, type: 'buttonLinkAction' })
  }

  onDeleteTableClick(): void {
    // this.deleteTable.emit({event: 'deleteTable'});
    this.deleteTable.emit();
  }

  createNavigationLink(button: any, data: any): string {
    let navLink = '';
    if (button.linkObject != null) {
      if (button.linkObject.NavigationParam != null) {
        navLink += '/';
        for (let ii = 0; ii < button.linkObject.NavigationParam.length; ii++) {
          const item = button.linkObject.NavigationParam[ii];
          navLink += data[item];
          if (ii !== button.linkObject.NavigationParam.length - 1) {
            navLink += '/'
          }
        }
      }
    }
    return navLink;
  }

  checkArray(data: any): boolean {
    return Array.isArray(data);
  }

  loadData(): void {
    this.fetchData.emit(this._gm)
    this.isLoading = true;
  }

  exportPdf(): void {
    // const doc = new jsPDF.default(0, 0);
    // doc.autoTable(this.table.columns.map(col => ({ title: col.header, dataKey: col.field })), this.data);
    // doc.save('products.pdf');
  }

  exportExcel(): void {
    console.log(this.gridModel)
    console.log(this.gridModel.Data)
    console.log(JSON.stringify(this.gridModel.Data))
    // console.log(JSON.parse(this.gridModel.Data))
    // import('xlsx').then(xlsx => {
    //   const worksheet = xlsx.utils.json_to_sheet(this.data);
    //   const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    //   const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    //   this.saveAsExcelFile(excelBuffer, 'products');
    // });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  addNewColumns() {
    this.globalService.setColumnGrid(this.columns);
    this.dynamicDialogConfig = {
      header: this.translate.instant('editor.grid.add.column'),
      width: '500px',
      contentStyle: { "max-height": "90%" },
      styleClass: 'dialog-no-footer create-new',
      dismissableMask: true,
      baseZIndex: 10000,
      showHeader: true,
      closable: true,
      data: {
        isGoogleFiles: false
      }
    }
    this.dynamicDialogService.show(AddColumnsComponent, this.dynamicDialogConfig)
    .onClose.subscribe(async (res: any) => {
        // console.log(res)
        if (res) {
          if (this.gridModel.GridName == 'cart_transactions') {
            this.toastNotificationService.showNotification("error", "table cart_transaction cannot add columns!");
          } else {
            const split = this.gridModel.APIEndpoint!.split('/');
            split.splice(split.length - 2, 2);
            const path = split.join('/');
            let colStruct = this.globalService.cekAppId(this.gridModel.GridName!)
            let checkColumn = false;
            res.fieldName = this.globalService.replaceString(res.fieldName);
            let allColumn: any = [];
            allColumn = await this.globalCrudService.getDynamicCollectionwithidPromise(path, colStruct)
              .then((resp) => { return resp?.columns })
            for (let ii = 0; ii < allColumn.length; ii++) {
              if (allColumn[ii].field == res.fieldName) checkColumn = true;
            }
            if (checkColumn) {
              this.toastNotificationService.showNotification("error", "add column " + res.fieldName + " error, column already exists!");
            } else {
              this.globalService.changeBlockui(true);
              this.removeTableGrid.emit();
              let data: any = {};
              let topOneResult: any;
              let columns: any[] = [];
              data[res['fieldName']] = res['value'];
              this.isLoading = true;
              this.datasource.forEach((doc: any) => {
                doc.ref.update(data);
                topOneResult = doc.data();
                topOneResult[res['fieldName']] = res['value'];
              });
              /**jika menggunakan API */
              setTimeout(() => {
                this.updateRowWhenAddField(res);
              },1000);

              /** jika tidak menggunakan API */
              // this.globalCrudService.getDynamicCollectionwithidPromise(path, colStruct)
              // .then((response) => {
              //     if (response?.columns && response?.columns?.length > 0) {
              //       columns = response.columns;
              //       columns.push({
              //         "field": res['fieldName'], "header": res['fieldName'], "col_order": response?.columns?.length,
              //         "sortable": true, "filterable": true, type: res['fieldType'], "showInGrid": true, "showInForm": true,
              //         "calculation": res["calculation"]
              //       })
              //     } else {
              //       Object.keys(topOneResult).map((key: string) => {
              //         if (key != 'id') {
              //           if (key == res['fieldName'])
              //             columns.push({
              //               "field": key, "header": key, "sortable": true, "filterable": true, type: res['fieldType'],
              //               "showInGrid": true, "showInForm": true, "calculation": res["calculation"]
              //             })
              //           else
              //             columns.push({
              //               "field": key, "header": key, "sortable": true, "filterable": true, type: this.getType(key),
              //               "showInGrid": true, "showInForm": true, "calculation": res["calculation"]
              //             });
              //         }
              //       });
              //     }
              //     this.globalCrudService.createDynamiccollectionwithdocumentid({ columns: columns }, path, colStruct)
              //       .then(() => console.log())
              //     /** untuk kebutuhan dev / prod */
              //     const splitA = this.gridModel.APIEndpoint!.split('/');
              //     const appId = splitA[4];
              //     this.pathProjectFirebase = `/users/${this.user.uid}/projects/${appId}/firebase`;
              //     this.globalCrudService.getcollection(this.pathProjectFirebase).subscribe((res: any) => {
              //       if (res[0]?.state == 'ACTIVE') {
              //         this.globalCrudService.modifyCollection({ columns: columns }, path, colStruct)
              //           .then(() => console.log());
              //       }
              //     });
              //     setTimeout(() => {
              //       this.addTableGrid.emit({ data: colStruct, params: this.params });
              //       this.isLoading = false;
              //     }, 400);
              // });
            }
          }
      }
    });
  }

  editColumns(infoColumn: any) {
    this.globalService.setColumnGrid(this.columns);
    let appConfig: any = this.globalService.appConfig.value;
    if (appConfig?.is_publish == false) {
      // console.log(this.gridModel.GridName)
      this.dynamicDialogConfig = {
        header: 'Update Columns',
        width: '500px',
        // contentStyle: { "max-height": "500px", "overflow": "unset" },
        styleClass: 'dialog-no-footer create-new',
        dismissableMask: true,
        baseZIndex: 10000,
        showHeader: true,
        closable: true,
        data: infoColumn
      }
      this.dynamicDialogService.show(EditColumnsComponent, this.dynamicDialogConfig)
      .onClose.subscribe((res: any) => {
        if (res) {
          if (this.gridModel.GridName == 'cart_transactions' || this.gridModel.GridName == 'button_transactions' || 
          this.gridModel.GridName == 'detail_payment_transaction_fibr') {
            this.toastNotificationService.showNotification("error", "table cannot edit or delete columns!");
          } else {
            const allowAct = ['created_date', 'created_by', 'modify_date', 'modified_by'];
            const split = this.gridModel.APIEndpoint!.split('/');
            if (res.action == 'update') {
              if (allowAct.indexOf(res.fieldName) != -1) {
                this.toastNotificationService.showNotification('error', 'columns cannot be edited!');
              } else {
                if (split[3] == 'userapp_fibr') {
                  this.toastNotificationService.showNotification('error', 'columns cannot be edited!');
                } else {
                  let cekUpdateTipe = this.globalService.changeTypeOption(res)
                  if (cekUpdateTipe) {
                    this.toastNotificationService.showNotification('error', 'columns cannot be edited!');
                  } else {
                    if (this.columns.length > 0) {
                      for (let index = 0; index < this.columns.length; index++) {
                        if (this.columns[index].field == res.fieldName) {
                          this.columns[index].type = res.fieldType;
                          this.columns[index].header = res.fieldHeader;
                          this.columns[index].calculation = res.calculation;
                          if (!this.columns[index].col_order) this.columns[index].col_order = index; else this.columns[index].col_order;
                        } else {
                          // eslint-disable-next-line no-self-assign
                          this.columns[index].type = this.columns[index].type;
                          if (!this.columns[index].col_order) this.columns[index].col_order = index; else this.columns[index].col_order;
                        }
                      }
                    }
                    this.updateHeaderGrid('edit');
                    setTimeout(() => {
                      this.updateRowWhenUpdateField(res);
                    },2000);
                  }
                }
              }
            }
            if (res.action == 'delete') {
              const pathProject = `/users/${this.user.uid}/projects`;
              // const pathDataProject = `/users/${this.user.uid}/data_projects`;
              // const split = this.gridModel.APIEndpoint!.split('/');
              const appId = split[4];
              // console.log(split[3])
              if (split[3] == 'userapp_fibr') {
                this.toastNotificationService.showNotification('error', 'columns cannot be deleted!');
              } else {
                if (allowAct.indexOf(res.fieldName) != -1) {
                  this.toastNotificationService.showNotification('error', 'columns cannot be deleted!');
                } else {
                  this.globalCrudService.getDynamicCollectionwithidPromise(pathProject, appId).then((resp: any) => {
                    let statusColumn: any = false;
                    let tableName: any = this.gridModel.GridName;
                    if (resp.config == '') {
                      statusColumn = this.globalService.cekUseColumn(res, [], tableName, statusColumn);
                      if (statusColumn == true) {
                        this.toastNotificationService.showNotification('error', 'columns cannot be deleted!');
                      } else {
                        this.deleteColumn(res);
                      }
                    } else {
                      const con = JSON.parse(resp.config);
                      statusColumn = this.globalService.cekUseColumn(res, con.itemPages, tableName, statusColumn);
                      if (statusColumn == true) {
                        this.toastNotificationService.showNotification('error', 'column is already used in apps!');
                      } else {
                        this.deleteColumn(res);
                      }
                    }
                  });
                }
              }
            }
          }
        }
      });
    } else {
      this.toastNotificationService.showNotification('error', "if the app has been published to mobile, you can't edit the column type!");
    }
  }

  updateRowWhenAddField(col:any) {
    const splitA = this.gridModel.APIEndpoint!.split('/');
    const appId = splitA[4];
    let colStruct = this.globalService.cekAppId(this.gridModel.GridName!);
    const header = this.headers  = new HttpHeaders({ 'Content-Type':  'application/json', Authorization: `Bearer ${this.user?.accessToken}`});
    const body = {
      projectId: appId,
      collectionName: colStruct,
      fieldName: col?.fieldName,
      fieldType: col?.fieldType,
      calculation: col?.calculation
      // initValue: null
    }
    this.isLoading = true;
    this.globalService.methodPostOptions(environment.be_dev + `/api/v1/fibr-app/project/dataeditor/field/add`, body, header)
    .subscribe(( res: any) => {
      if (res.code == 200) {
        this.addTableGrid.emit({ data: colStruct, params: this.params });
        this.isLoading = false;
        this.globalService.changeBlockui(false);
      }
    },
    (err: any) => {
      this.toastNotificationService.showNotification('error', "Can't add Columns!");
      this.addTableGrid.emit({ data: colStruct, params: this.params });
      this.isLoading = false;
      this.globalService.changeBlockui(false);
    })
  }

  updateRowWhenUpdateField(col:any) {
    const splitA = this.gridModel.APIEndpoint!.split('/');
    const appId = splitA[4];
    let colStruct = this.globalService.cekAppId(this.gridModel.GridName!);
    const header = this.headers  = new HttpHeaders({ 'Content-Type':  'application/json', Authorization: `Bearer ${this.user?.accessToken}`});
    const body = {
      projectId: appId,
      collectionName: colStruct,
      fieldName: col?.fieldName,
      fieldType: col?.fieldType
    }
    this.globalService.methodPostOptions(environment.be_dev + `/api/v1/fibr-app/project/dataeditor/field`, body, header)
    .subscribe(( res: any) => {
      // console.log(res) 
    })
  }

  deleteColumn(event: any) {
    // console.log(this.columns)
    this.confirmService.confirm({
      key: "subheader",
      header: 'Delete Column',
      message: `Do you want to delete Column ${event.fieldName}?`,
      dismissableMask: true,
      acceptLabel: 'Delete Column',
      rejectLabel: 'Cancel',
      acceptIcon: 'pi pi-trash',
      rejectIcon: 'null',
      acceptButtonStyleClass: "p-button-danger",
      rejectButtonStyleClass: 'p-button-secondary',
      accept: async () => {
        // for ( let idf = 0; idf < this.columns.length; idf++) {
        //   if (this.columns[idf].field == event.fieldName) {
        //     this.columns.splice(idf, 1); 
        //   }
        // }
        this.updateHeaderGrid('delete', event.fieldName);
      },
      reject: () => {
        console.log();
      }
    });
  }

  updateGrid(data: any, type?: string, field?: any, e?: any) {
    let check = false;
    Object.keys(data).map((key: string) => {
      if (key != 'id') check ||= (data[key] == null || data[key] == '' ? false : true)
    });

    if (data.id != null) {
      let data2: any = {};
      const mappedForm = Object.keys(data).map(key => ({ field: key, value: data[key] }));
      const cekField = mappedForm.filter((x) => x.field == field);
      if (cekField.length == 0) mappedForm.push({ field: field, value: e });

      for (let i = 0; i < mappedForm.length; i++) {
        if (mappedForm[i].field == field || mappedForm[i].field == 'id') {
          data2[mappedForm[i].field] = mappedForm[i].value;
        }
      }

      // if(type == 'date' || type == 'time') data[field!] = e;
      // if(type == 'date' || type == 'time') data[field!] = new Date(e);
      if (check) {
        // this.globalCrudService.modifyRefDynamicColection(data, this.gridModel.APIEndpoint!, data.id)
        this.globalCrudService.modifyRefDynamicColection(data2, this.gridModel.APIEndpoint!, data.id)
        // let sec = e.getTime() / 1000;
        if (type == 'date') data[field!] = this.pipe.transform(e, 'dd MMM YYYY');
        if (type == 'time') data[field!] = this.pipe.transform(e, 'dd MMM YYYY HH:mm');
      } else {
        this.globalCrudService.deleteDynamicCollection(this.gridModel.APIEndpoint!, data.id)
        // .then(() => {
        //   this.removeTableGrid.emit();
        //   setTimeout(() => {
        //     this.addTableGrid.emit({ data : this.gridModel.GridName, params: this.params});
        //   }, 500);
        // });
      }
    } else {
      if (this.totalRecords < this.max_rows) {
        if (check) this.addDataRowGrid(data);
      } else {
        this.toastNotificationService.showNotification("error", "for the " + this.statusProj + " version max " + this.max_rows + " rows per table!");
      }
    }
  }

  async getIdentifyTypeNum(data:any,colCalculation:any) {
    for (let x=0; x<colCalculation.length; x++) {
      this.getCalculation(data,colCalculation[x].field,colCalculation);
    }
  }

  getCalculation(data: any,field?: any, columns?: any) {
    /**this.columns valuenya hanya yg showInGrid = true */
    const colCal = columns.filter((col:any) => col.type == 'calculation');
    if (colCal.length > 0) {
      for (let x=0; x<colCal.length; x++) {
        /**colCal[x] ada column tujuan dari calculation */
        const varList: any = colCal[x].calculation.varList;
        /**untuk memastikan field ini masuk ke calculation apa ga tidak jadi dipakai karena ada kemungkinan tipe calculation dipakai di proses selanjutnya*/
        // const rowCal = varList.filter((row:any) => row.column == field);
        // if (rowCal.length > 0) {
          let inData = []
          for (let y=0; y<varList.length; y++) {
            let dataCal = data[varList[y].column];
            inData.push({column: varList[y].column, value: dataCal, alias:varList[y].alias});
          }
          data[colCal[x].field] = this.parser.getData(inData,colCal[x].calculation.formula);
          this.updateGrid(data, colCal[x].type, colCal[x].field);
        // }
      }
    }
  }

  getDataGrid(data: any, type?: string, field?: string, e?: any) {
    const arrData = Object.entries(data);
    arrData.forEach(([key, value]) => {
      if (key == field) {
        if (type == 'date' || type == 'time') {
          let val1: any
          let t_date: any;
          if (value != '') {
            val1 = value;
            t_date = new Date(val1);
          } else {
            t_date = new Date()
          }
          // console.log(this.pipe.transform(val1, 'dd MMM yyyy'));
          this.valueData = t_date;
        } else {
          this.valueData = value;
        }
      }
    });
    this.dataColumn = data;
    this.nameField = field;
    this.typeField = type;
  }

  getDataRole() {
    // console.log(this.user, this.gridModel, this.dataColumn,this.nameField,this.typeField, this.valueData)
    const split = this.gridModel.APIEndpoint!.split('/');
    // console.log(split)
    let pathRole = `/users/${split[2]}/roleapp_fibr/${split[4]}/data`;
    this.globalCrudService.getcollectionPromise(pathRole).then((resp: any) => {
      this.optionsRole = [];
      for (let a = 0; a < resp.length; a++) {
        this.optionsRole.push({ 'name': resp[a].role, 'value': resp[a].role })
      }
    })
  }

  updateColumnDialog(data: any, type?: string, field?: string, e?: any) {
    const arrData = Object.entries(data);
    arrData.forEach(([key]) => {
      if (key == field) {
        data[key] = this.valueData;
      }
    });
    const split = this.gridModel.APIEndpoint!.split('/');
    if (type == 'image') {
      this.updateGrid(data, type, field);
      this.showImageUploadOpt = false;
    }
    if (type == 'date' || type == 'time') {
      if (this.valueData === null) {
        this.toastNotificationService.showNotification("error", "wrong data format!");
      }
      else {
        this.updateGrid(data, type, field, this.valueData);
        this.showDateTimeType = false;
      }
    }
    if (type == 'text_editor') {
      let sizeInBytes = new Blob([this.valueData]).size;
      if (sizeInBytes < 1048487) {
        this.updateGrid(data, type, field, this.valueData);
        this.globalService.changeBlockui(true)
        setTimeout(() => {
          this.showDateTimeType = false;
          this.globalService.changeBlockui(false)
        }, 2000)
      } else {
        this.toastNotificationService.showNotification("error", "Text Editor file is more than 1Mb!");
        this.valueData = null;
        arrData.forEach(([key]) => {
          if (key == field) {
            data[key] = null;
          }
        });
      }
    }
    if (split[3] == 'userapp_fibr' || split[3] == 'roleapp_fibr') {
      if ((type == 'string' || type == 'text') && field == 'password') {
        const appId = split[4];
        // console.log(data,type,field,this.valueData,appId)
        this.updatePasswordUser(data, this.valueData, appId);
        this.showDateTimeType = false;
      }
      if (type == 'text' && field == 'role') {
        if (!data?.role) data.role = this.valueData; else data.role;
        // console.log(data,field,type,this.valueData)
        this.updateGrid(data, type, field, this.valueData);
        this.showDateTimeType = false;
      }
    }
    if (type == 'qrcode') {
      this.updateGrid(data,type,field,this.valueData);
      this.showDateTimeType = false; 
    }
  }

  async updatePasswordUser(data: any, value: any, appId: string) {
    this.globalService.changeBlockui(true);
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${this.user?.accessToken}` });
    const body = { projectId: appId, password: value }
    await this.globalService.methodPutOptions(environment.be_dev + `/api/v1/fibr-app/auth/password/update/${data.id}`, body, this.headers)
      .subscribe((res: any) => {
        this.toastNotificationService.showNotification(res.status, res.message);
        setTimeout(() => {
          this.globalService.changeBlockui(false);
        }, 1000);
      },
        (err: any) => {
          let event = { body: body, id: data.id }
          this.errResponse(err, event, 'updatePassword')
        });
  }

  errResponse(err: any, event: any, action: any) {
    if (err?.status >= 400 && err?.status < 500) {
      if (err?.status == 401) {
        if (this.countGetToken < 3) {
          setTimeout(() => {
            this.refreshToken(event, action);
          }, 2000);
          this.countGetToken++;
        } else {
          this.toastNotificationService.showNotification('error', 'Invalid Token!');
          this.globalService.changeBlockui(false);
        }
      } else if (err?.status == 403) {
        this.globalService.changeBlockui(false);
        this.err403();
      } else {
        this.toastNotificationService.showNotification(err?.error?.status, err?.error?.message);
        this.globalService.changeBlockui(false);
      }
    } else {
      this.toastNotificationService.showNotification(err?.error?.status, err?.error?.message);
      this.globalService.changeBlockui(false);
    }
  }

  async refreshToken(event: any, action: any) {
    this.globalService.changeBlockui(true);
    const body = { uid: this.user?.uid, refresh_token: this.user?.refreshToken };
    await this.globalService.methodPostPromise(environment.be_dev + `/api/v1/fibr-app/refreshtoken`, body)
      .subscribe(async (res) => {
        this.user.accessToken = res?.data?.accessToken;
        this.user.refreshToken = res?.data?.refreshToken;
        this.globalService.setRefDataToLocalstorage("user_profile", this.user);
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${res?.data?.accessToken}` });
        if (action == 'updatePassword') {
          await this.globalService.methodPutOptions(environment.be_dev + `/api/v1/fibr-app/auth/password/update/${event.id}`, event.body, this.headers)
            .subscribe((resCek) => {
              setTimeout(() => {
                this.toastNotificationService.showNotification(resCek.status, resCek.message);
                this.globalService.changeBlockui(false);
              }, 1000);
            });
        }
      },
        (err: any) => {
          this.errResponse(err, event, action)
        });
  }

  async err403() {
    this.confirmationTemplate = {
      header: "Confirmation Session Expired !",
      content: "Click YES to logout and log back in to re-new your session",
      rejectButtonStyleClass: "p-button-secondary",
      dismissableMask: true
    };
    this.confirmService.confirm({
      message: this.confirmationTemplate.content,
      header: this.confirmationTemplate.header,
      rejectButtonStyleClass: this.confirmationTemplate.rejectButtonStyleClass,
      dismissableMask: this.confirmationTemplate.dismissableMask,
      accept: () => {
        setTimeout(() => {
          localStorage.removeItem('user_profile');
        }, 1000);
        this.authService.clearAuthToken();
        this.authService.SignOutFire();
      },
      reject: () => { }
    });
  }

  createFormDataLogo(field: any, tableName: any) {
    const formData = new FormData();
    let appConfig: any = this.globalService.appConfig.value;
    formData.append("user_type", "partner");
    formData.append("user_id", this.user.uid);
    formData.append("project_id", appConfig.app_id);
    formData.append("data_id", tableName);
    formData.append("field_id", field);
    for (let item = 0; item < this.fileImage.length; item++) {
      formData.append(`file`, this.fileImage[item].File);
    }
    return formData;
  }

  createFormDataImageReJpeg(tableName: any, field: any, file: any) {
    const formData = new FormData();
    let appConfig: any = this.globalService.appConfig.value;
    formData.append("user_type", "partner");
    formData.append("user_id", this.user.uid);
    formData.append("project_id", appConfig.app_id);
    formData.append("data_id", tableName);
    formData.append("field_id", field);
    formData.append(`file`, file);
    return formData;
  }

  // async onSelectImage(event: any, data: any, fubauto: any, type?:string, field?:string, tableName?:any) {
  //   const image = this.createListFileArr(event.currentFiles);
  //   this.globalService.addConfigRunning(this.globalService.appConfig.value, environment);
  //   // let maxUpload = 0;
  //   // maxUpload = this.globalService.checkFileUpload(this.globalService.appConfig.value, environment);
  //   if (image[0].File.size < this.max_upload) {
  //     this.isUploadImage = true;
  //     const body = data;
  //     this.globalService.changeBlockui(true);
  //     if (this.isUploadImage) {
  //       const fromData = this.createFormDataLogo(field, tableName);
  //       await this.globalService.methodPost(environment.be_dev + '/api/v1/files/upload', fromData)
  //         .toPromise()
  //         .then(async (x) => {
  //           const key  = field!;
  //           const value = environment.image_path + x.data[0].value;
  //           body[key] = value;
  //           this.isUploadImage = false;
  //           this.updateGrid(data,type,field);
  //           this.showImageUploadOpt = false;
  //           this.globalService.changeBlockui(false);
  //           fubauto.clear();
  //         },
  //         (err: any) => {
  //             this.toastNotificationService.showNotification(err?.error?.status, err?.error?.message);
  //             this.globalService.changeBlockui(false);
  //             fubauto.clear();
  //         });
  //     }
  //   } else {
  //     this.toastNotificationService.showNotification("error", "file is too big, maximum "+this.max_upload/1000+" KB!");
  //     // this.updateGrid(data);
  //     fubauto.clear();
  //   }
  // }

  /** upload Image */
  async onFileChange(pFileList: File[], data: any, type?: string, field?: string, tableName?: any) {
    const image = this.createListFileArr(pFileList);
    this.globalService.addConfigRunning(this.globalService.appConfig.value, environment);
    // let maxUpload = 0;
    // maxUpload = this.globalService.checkFileUpload(this.globalService.appConfig.value, environment);
    if (image[0].File.size < this.max_upload) {
      this.isUploadImage = true;
      const body = data;
      if (this.isUploadImage) {
        this.uploadFile(image, body, field, type, data, tableName);
        // setTimeout(() => { this.globalService.changeBlockui(false) }, 2000);
      }
    } else {
      this.toastNotificationService.showNotification("error", "file is too big, maximum " + this.max_upload / 1000 + " KB!");
    }
  }

  async onSelectImage(event: any, data: any, fubauto: any, type?: string, field?: string, tableName?: any) {
    const image = this.createListFileArr(event.currentFiles);
    this.globalService.addConfigRunning(this.globalService.appConfig.value, environment);
    if (image[0].File.size < this.max_upload) {
      this.isUploadImage = true;
      const body = data;
      // this.globalService.changeBlockui(true);
      if (this.isUploadImage) {
        this.uploadFile(image, body, field, type, data, tableName);
        // setTimeout(() => { this.globalService.changeBlockui(false) }, 2000);
        fubauto.clear();
      }
    } else {
      this.toastNotificationService.showNotification("error", "file is too big, maximum " + this.max_upload / 1000 + " KB!");
      // this.updateGrid(data);
      fubauto.clear();
    }
  }

  async uploadFile(image: any, body: any, field: any, type: any, data: any, tableName: any) {
    // console.log('file sebelum dicompress', image[0].File.size)
    if (image[0].File.type == "image/jpeg" || image[0].File.type == "image/gif") {
      this.compressImage.compress(image[0].File)
        .pipe(take(1))
        .subscribe(async (compressedImage: File) => {
          // console.log('file setelah compress', compressedImage);
          const formData = this.createFormDataImageReJpeg(tableName, field, compressedImage);
          await this.globalService.methodPost(environment.be_dev + '/api/v1/files/upload', formData)
            .toPromise()
            .then(async (x) => {
              const key = field!;
              const value = environment.image_path + x.data[0].value;
              body[key] = value;
              this.isUploadImage = false;
              this.updateGrid(data, type, field);
              this.globalService.changeBlockui(true);
              setTimeout(() => { this.refresh(); this.globalService.changeBlockui(false); }, 2000);
              this.showImageUploadOpt = false;
            },
              (err: any) => {
                this.toastNotificationService.showNotification(err?.error?.status, err?.error?.message);
                // this.globalService.changeBlockui(false);
                // fubauto.clear();
              });
        });
    } else {
      // console.log('file belum bisa dicompress', image[0].File);
      const fromData = this.createFormDataLogo(tableName, field);
      await this.globalService.methodPost(environment.be_dev + '/api/v1/files/upload', fromData)
        .toPromise()
        .then(async (x) => {
          const key = field!;
          const value = environment.image_path + x.data[0].value;
          body[key] = value;
          this.isUploadImage = false;
          this.updateGrid(data, type, field);
          this.globalService.changeBlockui(true);
          setTimeout(() => { this.refresh(); this.globalService.changeBlockui(false); }, 2000);
          this.showImageUploadOpt = false;
        },
          (err: any) => {
            this.toastNotificationService.showNotification(err?.error?.status, err?.error?.message);
            // this.globalService.changeBlockui(false);
            // fubauto.clear();
          });
    }
  }

  createListFileArr(fileImage: any): FileDocumentModel[] {
    this.fileImage = [];
    for (let i = 0; i < fileImage?.length; i++) {
      const item = fileImage[i];
      this.fileImage.push({
        FileName: item.name,
        File: item
      });
    }
    return this.fileImage;
  }

  cekUpdateColumn(act: any, col?: any) {
    const split = this.gridModel.APIEndpoint!.split('/');
    if (split[3] == 'userapp_fibr') {
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].field == col) this.columns[i].header = col;
      }
      this.toastNotificationService.showNotification('error', 'columns cannot be edited!');
    } else {
      if (col == 'created_date' || col == 'created_by' || col == 'modify_date' || col == 'modified_by') {
        this.toastNotificationService.showNotification('error', 'columns cannot be updated!');
        for (let i = 0; i < this.columns.length; i++) {
          if (this.columns[i].field == 'created_date') this.columns[i].header = 'created_date';
          if (this.columns[i].field == 'created_by') this.columns[i].header = 'created_by';
          if (this.columns[i].field == 'modify_date') this.columns[i].header = 'modify_date';
          if (this.columns[i].field == 'modified_by') this.columns[i].header = 'modified_by';
        }
      } else {
        this.updateHeaderGrid(act);
      }
    }
  }

  async updateHeaderGrid(act: any, col?: any) {
    const split = this.gridModel.APIEndpoint!.split('/');
    split.splice(split.length - 2, 2);
    const path = split.join('/');
    this.globalService.changeBlockui(true);
    let colStruct = this.globalService.cekAppId(this.gridModel.GridName!)

    // this.columns = await this.globalCrudService.getDynamicCollectionwithidPromise(path, colStruct)
    await this.globalCrudService.getDynamicCollectionwithidPromise(path, colStruct)
      .then((res) => {
        for (let i = 0; i < res.columns.length; i++) {
          if (act == 'edit') {
            for (let ii = 0; ii < this.columns.length; ii++) {
              if (res.columns[i]?.field == this.columns[ii].field) res.columns[i] = this.columns[ii];
            }
          } else {
            if (res.columns[i]?.field == col) res.columns.splice(i, 1);
          }
        }

        setTimeout(() => {
          this.globalCrudService.modifyDynamicColection({ columns: res?.columns }, path, colStruct).then(() => {});
          const splitA = this.gridModel.APIEndpoint!.split('/');
          const appId = splitA[4];
          this.pathProjectFirebase = `/users/${this.user.uid}/projects/${appId}/firebase`;
          this.globalCrudService.getcollection(this.pathProjectFirebase).subscribe((resRoot: any) => {
            if (resRoot[0]?.state == 'ACTIVE') {
              this.globalCrudService.modifyCollection({ columns: res?.columns }, path, colStruct)
                .then(() => console.log());
            }
          });
          this.columns = [];
          for (let x = 0; x < res?.columns.length; x++) {
            if (res?.columns[x].showInGrid) this.columns.push(res?.columns[x]);
          }
          this.globalService.setColumnGridAfterEdit({act: 'edit_column',column:this.columns});
          this.globalService.changeBlockui(false);
        }, 500);
      });
  }

  addDataRowGrid(data: any) {
    delete data.id;
    data['modify_date'] = new Date();
    this.globalCrudService.dynamicCreatecollectionWithId(data, this.gridModel.APIEndpoint!)
      .then((res: any) => {
        this.removeTableGrid.emit();
        setTimeout(() => {
          this.addTableGrid.emit({ data: this.gridModel.GridName, params: this.params });
        }, 300);
      });
  }

  addBlankRow(row: any) {
    let nRow: any = {};
    Object.keys(row).map((key: string) => {
      nRow[key] = null;
    });
    this.gridModel.Data.push(nRow);
  }

  createNewRow() {
    this.fetchData.emit({ event: { Name: 'createnew', Title: "Tambah Data" }, data: {}, navigationLink: "", type: 'buttonAction' })
  }

  getfirebaseSearch(value: any, field: string) {
    this.gridModel.Data = [];
    this.globalCrudService.get_search_dynamicColection(this.gridModel.APIEndpoint!, value, `${field}_insensitive`)
      .subscribe((results: any) => {
        this.configurationGrid(results);
      })
  }

  getfirebaseSearchEqual(value: any, field: string) {
    this.gridModel.Data = [];
    this.globalCrudService.get_search_equal_dynamicColection(this.gridModel.APIEndpoint!, value, `${field}_insensitive`)
      .subscribe((results: any) => {
        this.configurationGrid(results);
      })
  }

  getfirebaseSearchNotEqual(value: any, field: string) {
    this.gridModel.Data = [];
    this.globalCrudService.get_search_notequal_dynamicColection(this.gridModel.APIEndpoint!, value, `${field}_insensitive`)
      .subscribe((results: any) => {
        this.configurationGrid(results);
      })
  }

  getfirebaseOrderBy(field: string, sortOrder: string) {
    this.gridModel.Data = [];
    this.globalCrudService.get_id_dynamicColection_with_order(this.gridModel.APIEndpoint!, `${field}`, sortOrder)
      // this.globalCrudService.get_id_dynamicColection_with_order(this.gridModel.APIEndpoint!, `${field}_insensitive`, sortOrder)
      .subscribe((results: any) => {
        this.configurationGrid(results);
      })
  }

  uploadButton() {
    this.showUpload.emit(true);
  }

  removeButton() {
    this.removeMultipleRow.emit({ table: '', data: [] });
  }

  onRowSelects(event: any) {
    this.multipleSelection.push(event?.data);
    this.multipleSelectionReturn.emit(this.multipleSelection);
  }

  onRowUnSelects(event: any) {
    this.multipleSelection = this.multipleSelection.filter((x: any) => x.RecordId != event?.data.RecordId);
    this.multipleSelectionReturn.emit(this.multipleSelection);
    this.isMultipleSelection = false;
  }

  onRowMultipleSelect(event: any) {
    this.multipleSelection = this.isMultipleSelection ? this.gridModel.Data : [];
    this.multipleSelectionReturn.emit(this.multipleSelection);
  }

  submitEvent(action: string) {
    this.isSubmit.emit({ action: action, tablename: this.gridModel.GridName, data: this.multipleSelection });
    this.globalCrudService.getCountCollection(this.gridModel.APIEndpoint!)
      .then(x => this.totalRecords = x);
  }

  async settingColumns() {
    // console.log(this.gridModel);
    let columns: any[] = [];
    const split = this.gridModel.APIEndpoint!.split('/');
    split.splice(split.length - 2, 2);
    const path = split.join('/');
    let colStruct = this.globalService.cekAppId(this.gridModel.GridName!)
    this.columnForSetting = [];
    this.columnShow = [];
    await this.globalCrudService.getDynamicCollectionwithidPromise(path, colStruct)
      .then((res) => {
        this.columnForSetting = res.columns;
        for (let x = 0; x < res?.columns.length; x++) {
          if (res?.columns[x]?.showInGrid)
            this.columnShow.push(res?.columns[x]);
        }
      })
    // console.log(this.columnForSetting, this.columnShow);
    this.showSettingColumn = true;
  }

  changeColumns(col: any) {
    for (let x = 0; x < this.columnForSetting.length; x++) {
      // const cekColumn = event.filter((x: any) => x?.field == this.columnForSetting[x]?.field);
      if (this.columnForSetting[x]?.field == col?.field) {
        if (this.columnForSetting[x].showInGrid)
          this.columnForSetting[x].showInGrid = false;
        else
          this.columnForSetting[x].showInGrid = true;
      }
    }
  }

  saveSettingColumns() {
    const split = this.gridModel.APIEndpoint!.split('/');
    const appId = split[4];
    split.splice(split.length - 2, 2);
    const path = split.join('/');
    let colStruct = this.globalService.cekAppId(this.gridModel.GridName!)
    this.showSettingColumn = false;
    this.globalService.changeBlockui(true);
    // this.connectionService.swicthFirebaseGrid(split[2], split[4], this.gridModel.GridName);
    this.globalCrudService.createDynamiccollectionwithdocumentid({ columns: this.columnForSetting }, path, colStruct)
      .then(() => {
        this.pathProjectFirebase = `/users/${this.user.uid}/projects/${appId}/firebase`;
        this.globalCrudService.getcollection(this.pathProjectFirebase).subscribe((res: any) => {
          if (res[0]?.state == 'ACTIVE') {
            this.globalCrudService.createcollectionwithdocumentname({ columns: this.columnForSetting }, path, colStruct);
          }
        });
        this.removeTableGrid.emit();
        this.addTableGrid.emit({ data: colStruct, params: this.params });
        setTimeout(() => {
          this.refresh()
          this.globalService.changeBlockui(false);
        }, 1000)
      },
        (err: any) => {
          this.globalService.changeBlockui(false);
        });
  }

  async getFilesByParam(param: { projectId: any; table?: string; }): Promise<any> {
    try {
      const result = await (await this._gsheetService.getGsheetFilesForExport(param.projectId)).toPromise();
      if (result.status == 200) {
        return {
          status: true,
          msg: result.body.message,
          data: result.body.data.find((item: { keyName: string | undefined; }) => item.keyName == param.table)
        };
      } else {
        console.log('result', result.body.message);
        return {
          status: false,
          msg: 'file config not found'
        };
      }
    } catch (error: any) {
      console.error('Error:', error);
      return {
        status: false,
        msg: error.error.message
      };
    }
  }

  async importTable() {
    try {
      if (this.gridModel.APIEndpoint != undefined) {
        this.globalService.changeBlockui(true);
        let splitPath = this.gridModel.APIEndpoint.split("/");
        const userId = splitPath[2];
        const projectId = splitPath[4];
        const table = splitPath[6];

        console.log({ userId, projectId, splitPath })
        let obj = await this.getFilesByParam({ projectId, table });

        if (obj.status && obj.data != undefined) {
          const objData = obj.data

          const payload = {
            fileIds: [objData.fileId],
            action: "import",
            projectId: projectId,
            selectedFiles: [
              {
                fileId: objData.fileId,
                sheetIds: [objData.sheetId]
              }
            ],
            importProcess: true,
            replace: true
          };

          const result = await (await this._gsheetService.importProcess(payload)).toPromise();

          if (result.status == 200 || result.status == 201) {
            console.log('result success', result)
            this.toastNotificationService.showNotification("success", result.body.message);
            location.reload();
          } else {
            console.log('result', result)
            this.toastNotificationService.showNotification('error', obj.msg);
          }
        } else {
          this.toastNotificationService.showNotification('error', 'Gsheet config not found');
        }
      }
    } catch (error: any) {
      this.toastNotificationService.showNotification('error', error);
    } finally {
      this.globalService.changeBlockui(false);
      this.showImportTable = false;
    }
  }

  async exportTable() {
    try {
      if (this.gridModel.APIEndpoint != undefined) {
        this.globalService.changeBlockui(true);
        let splitPath = this.gridModel.APIEndpoint.split("/");
        const userId = splitPath[2];
        const projectId = splitPath[4];
        const table = splitPath[6];

        console.log({ userId, projectId, splitPath })
        let obj = await this.getFilesByParam({ projectId, table });

        if (obj.status && obj.data != undefined) {
          const objData = obj.data

          const payload = {
            projectId: projectId,
            selectedFiles: [
              {
                fileId: objData.fileId,
                sheetId: objData.sheetId
              }
            ],
            exportProcess: "force"
          };

          const result = await (await this._gsheetService.exportProcess(payload)).toPromise();

          if (result.status == 200) {
            console.log('result success', result)
            this.toastNotificationService.showNotification("success", result.body.message);
          } else {
            console.log('result', result)
            this.toastNotificationService.showNotification('error', obj.msg);
          }
        } else {
          this.toastNotificationService.showNotification('error', 'Gsheet config not found');
        }
      }
    } catch (error: any) {
      this.toastNotificationService.showNotification('error', error);
    } finally {
      this.globalService.changeBlockui(false);
      this.showExportTable = false;
    }
  }

  saveAsImage(parentQrCode: any) {
    const parentElement = parentQrCode.qrcElement.nativeElement.querySelector("img").src;
    let blobData = this.convertBase64ToBlob(parentElement);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE
      window.navigator.msSaveOrOpenBlob(blobData, 'Qrcode');
    } else { // chrome
      const blob = new Blob([blobData], { type: "image/png" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'QrcodeFibr';
      link.click();
    }

  }

  private convertBase64ToBlob(Base64Image: any) {
    const parts = Base64Image.split(';base64,');
    const imageType = parts[0].split(':')[1];
    const decodedData = window.atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: imageType });
  }

}
