import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { GlobalcrudService, GlobalService } from '@core-fibr/shared';
import { environment } from 'libs/auth/src/environments/environment';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'ui-fibr-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  
  items: MenuItem[] = [];
  uploadedFiles: any[] = [];
  userProfile :any;
  dataUsers: any;

  appName = environment.app_name;

  constructor(private titleService: Title, private route:ActivatedRoute, private globalService : GlobalService, private globalCrudService : GlobalcrudService) { 
    this.titleService.setTitle(this.route.snapshot.data['title']);
  }

  ngOnInit(): void {
    this.globalService.changeSubHeaderShow(true);
    this.globalService.setBreadcrumb([
        {label:'Dashboard', routerLink: 'transaction/layanan/janjitemu', routerLinkActiveOptions: { exact: true }},
        {label:'Users'}
    ]);
    this.userProfile = this.globalService.getCurrReffDataParsed('user_profile');
    const pathProfile = `users/${this.userProfile.uid}/profile`;
    this.globalCrudService.getcollection(pathProfile).subscribe(res =>{
      this.dataUsers = res[0];
    });
    this.items = [
      {
          label: 'Options',
          items: [{
              label: 'Update',
              icon: 'pi pi-refresh',
              command: () => {
                  this.update();
              }
          },
          {
              label: 'Reset Password',
              icon: 'pi pi-discord',
              command: () => {
                  this.resetPassword();
              }
          }
      ]}
    ];
  }

  onUpload(event:any){
    for(const file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  update() {
    // this.messageService.add({severity:'success', summary:'Success', detail:'Data Updated'});
  }

  resetPassword() {
      // his.messageService.add({severity:'warn', summary:'Delete', detail:'Data Deleted'});
  }

  print(){
    const printContents = document?.getElementById('section-to-print')?.outerHTML;
    if(printContents != undefined) {
      const win = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top="+(screen.height-400)+",left="+(screen.width-840));
      win?.document.write(printContents);
      win?.window.print();
      win?.window.close();
    }
  }

}
