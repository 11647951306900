<!-- <form #f="ngForm" (ngSubmit)="onSubmit(f)"> -->
<form #f="ngForm">
<div  class="mobile-comp mobile-form">
    <div class="p-grid p-fluid">

        <div class="camera-container" [ngClass]="action.isStart ? '' : 'hidden'">
          <div class="mobile-header camera-header">
            <div class="left-comp">
                <!-- <button pButton pRipple type="button" icon="pi pi-arrow-left" class="p-button-icon-only p-button-text m-0 mr-2" -->
                <button pButton pRipple type="button" icon="pi pi-arrow-left" class="p-button-icon-only p-button-text m-0 mr-2 bg-transparent border-transparent"
                (click)="handle(action, action.isStart ? 'stop' : 'start')">
                </button>
                <p class="title-page text-xl">QR Scanner</p>
            </div>
            <div class="right-comp">
              <button pButton pRipple type="button" icon="pi pi-sun" class="p-button-icon-only p-button-text bg-transparent border-transparent" 
              (click)="action.isTorch = !action.isTorch; handle(action, 'torcher')"></button>
            </div>
          </div>

          <div class="camera-focus">
            <span class="camera-desc text-xs">Direct the camera at the whole QR code to start scanning</span>
            <div class="overlay">
              <div class="overlay-element top-left"></div>
              <div class="overlay-element top-right"></div>
              <div class="overlay-element bottom-left"></div>
              <div class="overlay-element bottom-right"></div>
            </div>
          </div>

          <ngx-scanner-qrcode #action="scanner" class="qr-scanner" (event)="onEvent($event, action)"></ngx-scanner-qrcode>
        </div>

        <!-- <div class="map" 
          leaflet [leafletOptions]="options">
        </div> -->
        <!-- <div class="field" *ngFor="let item of config?.formConfiguration?.formContent"> -->
        <div class="field" *ngFor="let item of elementForm">
            <ng-container *ngIf="item.type === 'input' || item.type === 'text'">
                <span>
                    <p class="form-label" for="inputtext">{{item.label}}<sub *ngIf="item.validations ==='required'" class="text-magenta">*</sub></p>
                    <input placeholder="Input text" [name]="item.field" type="text" id="inputtext" pInputText [(ngModel)]="item.component">
                </span>
            </ng-container>
            <ng-container *ngIf="item.type === 'number'">
              <p class="form-label">{{item.label}}<sub *ngIf="item.validations ==='required'" class="text-magenta">*</sub></p>
              <!-- <input [name]="item.field" type="text" id="inputtext" pInputText class="p-inputtext p-component" [(ngModel)]="item.component"> -->
              <p-inputNumber [name]="item.field" [(ngModel)]="item.component" inputId="minmaxfraction"
                mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="2" placeholder="Input number">
              </p-inputNumber>
            </ng-container>
            <ng-container *ngIf="item.type === 'select' && item.data_type === 'static'">
              <p class="form-label">{{item.label}}<sub *ngIf="item.validations ==='required'" class="text-magenta">*</sub></p>
              <!-- <p-dropdown  optionLabel="label" optionValue="id" [(ngModel)]="viewDateValue" optionLabel="label" optionValue="value"></p-dropdown> -->
              <p-dropdown [name]="item.field" [options]="item.values" [(ngModel)]="item.component" placeholder="Select"></p-dropdown>
            </ng-container>
            <ng-container *ngIf="item.type === 'select' && item.data_type === 'dynamic'">
              <p class="form-label">{{item.label}}<sub *ngIf="item.validations ==='required'" class="text-magenta">*</sub></p>
              <p-dropdown [name]="item.field" [options]="item.valueDynamic" [(ngModel)]="item.component" placeholder="Select"></p-dropdown>
            </ng-container>
            <ng-container *ngIf="item.type === 'calendar'">
                <span>
                    <p class="form-label" for="calendar">{{item.label}}<sub *ngIf="item.validations ==='required'" class="text-magenta">*</sub></p>
                    <p-calendar placeholder="Select date" [name]="item.field" inputId="{{item.label}}" [(ngModel)]="item.component" inputId="basic" inputId="touchui" class="m-cal" [monthNavigator]="true" [yearNavigator]="true" yearRange="1945:2040"></p-calendar>
                </span>
            </ng-container>
            <ng-container *ngIf="item.type === 'time'">
              <span>
                  <p for="time">{{item.label}}<sub *ngIf="item.validations ==='required'" class="text-magenta">*</sub></p>
                  <p-calendar placeholder="Select time" [name]="item.field" inputId="{{item.label}}" [(ngModel)]="item.component" inputId="touchui" class="m-cal"
                  [timeOnly]="true" [hourFormat]="'24'" [showTime]="true"></p-calendar>
              </span>
            </ng-container>
            <ng-container *ngIf="item.type === 'radio' && item.data_type === 'static'">
              <p class="form-label form-label-title">{{item.label}}<sub *ngIf="item.validations ==='required'" class="text-magenta">*</sub></p>
              <!-- <span class="p-float-label"> -->
                <div *ngFor="let radio of item?.values" class="field-radiobutton">
                  <p-radioButton [name]="item.field" [value]="radio" [(ngModel)]="item.component" [inputId]="radio"></p-radioButton>
                  <label for="{{ radio }}">{{radio}}</label>
                </div>
              <!-- </span> -->
            </ng-container>
            <ng-container *ngIf="item.type === 'radio' && item.data_type === 'dynamic'">
              <p class="form-label form-label-title">{{item.label}}<sub *ngIf="item.validations ==='required'" class="text-magenta">*</sub></p>
                <div *ngFor="let radio of item?.valueDynamic" class="field-radiobutton">
                  <p-radioButton [name]="item.field" [value]="radio" [(ngModel)]="item.component" [inputId]="radio"></p-radioButton>
                  <label for="{{ radio }}">{{radio}}</label>
                </div>
            </ng-container>
            <ng-container *ngIf="item.type === 'checkbox' && item.data_type === 'static'">
              <p class="form-label form-label-title">{{item.label}}<sub *ngIf="item.validations ==='required'" class="text-magenta">*</sub></p>
                <div *ngFor="let checkbox of item?.values" class="field-checkbox">
                  <p-checkbox [name]="item.field" value="checkbox" [(ngModel)]="item.component" [value]="checkbox" [inputId]="checkbox"></p-checkbox>
                  <label for="{{ checkbox }}">{{checkbox}}</label>
                </div>
            </ng-container>
            <ng-container *ngIf="item.type === 'checkbox' && item.data_type === 'dynamic'">
              <p class="form-label form-label-title">{{item.label}}<sub *ngIf="item.validations ==='required'" class="text-magenta">*</sub></p>
                <div *ngFor="let checkbox of item?.valueDynamic" class="field-checkbox">
                  <p-checkbox [name]="item.field" value="checkbox" [(ngModel)]="item.component" [value]="checkbox" [inputId]="checkbox"></p-checkbox>
                  <label for="{{ checkbox }}">{{checkbox}}</label>
                </div>
            </ng-container>
            <ng-container *ngIf="item.type === 'upload'">
                <p class="form-label">{{item.label}}<sub *ngIf="item.validations ==='required'" class="text-magenta">*</sub></p>
                <div class="p-inputgroup upload-field">
                  <!-- <input [name]="item.field" type="text" class="mr-2 rounded" pInputText placeholder="Upload file" [(ngModel)]="item.component" (blur)="initEdit(item)" /> -->
                  <input [name]="item.field" type="text" class="mr-2 rounded" pInputText placeholder="Upload file" [(ngModel)]="item.component" />
                  <!-- <p-fileUpload #fileUpload chooseLabel="Browse" mode="basic" (onSelect)="uploadFileRize($event, fileUpload, f, item.field)" 
                  accept="image/*" [auto]="true"></p-fileUpload> -->
                  <p-fileUpload #fileUpload chooseLabel="Browse" mode="basic" (onSelect)="uploadFileRize($event, fileUpload, f, item.field)" 
                  [accept]="item.extendFile" [auto]="true"></p-fileUpload>
                </div>
            </ng-container>

            <ng-container *ngIf="item.type === 'qrcode'">
              <p class="form-label">{{item.label}}<sub *ngIf="item.validations ==='required'" class="text-magenta">*</sub></p>
              <div class="p-inputgroup upload-field" (click)="handle(action, action.isStart ? 'stop' : 'start',item.field, f)">
                <input [name]="item.field" type="text" class="mr-2 rounded" pInputText placeholder="Scan QR" [(ngModel)]="item.component" />
                <button pButton pRipple class="px-4 w-auto rounded"><img class="p-button-icon" src="/assets/images/form/icon_qrcode.svg"></button>
              </div>
            </ng-container>
            <ng-container *ngIf="item.type === 'childQrcode'">
                <p class="form-label">{{item.label}}</p>
                  <!-- <input [name]="item.field" disabled="true" id="inputtext" type="text" pInputText [(ngModel)]="item.component" [ngModelOptions]="{standalone: true}"> -->
                <input [name]="item.field" id="inputtext" [attr.readonly]="true" type="text" pInputText [(ngModel)]="item.component">
            </ng-container>
            
            <ng-container *ngIf="item.type === 'cascading'">
              <p class="form-label">{{item.label}}<sub *ngIf="item.validations ==='required'" class="text-magenta">*</sub></p>
              <p-dropdown [name]="item.field" [options]="item.valueDynamic" [(ngModel)]="item.component" 
              (onChange)="getDataCascading($event.value,item,'parent'); clearChildCascading(0,item.field)" placeholder="Select"></p-dropdown>
            </ng-container>

            <ng-container *ngIf="item.type === 'childCascading'">
              <p class="form-label">{{item.label}}<sub *ngIf="item.validations ==='required'" class="text-magenta">*</sub></p>
              <p-dropdown [name]="item.field" [options]="item.valueDynamic" [(ngModel)]="item.component" 
              (onChange)="getDataCascading($event.value,item,'child'); clearChildCascading(item.childNo,item.config.field)" placeholder="Select"></p-dropdown>
            </ng-container>

            <ng-container *ngIf="item.type === 'calculation_form'">
              <span>
                <p class="form-label">{{item.label}}<sub *ngIf="item.validations ==='required'" class="text-magenta">*</sub></p>
                <p-dropdown (onChange)="getConfigCalculation(item,f)" [name]="item.field" [options]="item.valueDynamic" [(ngModel)]="item.component" placeholder="Select"></p-dropdown>
              </span>
            </ng-container>

            <ng-container *ngIf="item.type === 'childCalculation'">
                <p class="form-label" *ngIf="item.status === 'input' && !item.readOnly">{{item.label}}<sub *ngIf="item.validations ==='required'" class="text-magenta">*</sub></p>
                <p-inputNumber *ngIf="item.status === 'input' && !item.readOnly" [name]="item.field" [(ngModel)]="item.component" inputId="minmaxfraction"
                  mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="2" placeholder="Input number" (onBlur)="getDataFormula(item,f)">
                </p-inputNumber>
              <span *ngIf="item.status === 'input' && item.readOnly"> 
                <p class="form-label">{{item.label}}<sub *ngIf="item.validations ==='required'" class="text-magenta">*</sub></p>
                <input *ngIf="item.status === 'input' && item.readOnly" [name]="item.field" id="inputtext" type="text" pInputText [(ngModel)]="item.component" [attr.readonly]="true">
              </span> 
              <span *ngIf="item.status === 'result'">
                <p class="form-label">{{item.label}}<sub *ngIf="item.validations ==='required'" class="text-magenta">*</sub></p>
                <input *ngIf="item.status === 'result'" [name]="item.field" id="inputtext" type="text" pInputText [(ngModel)]="item.component" [attr.readonly]="true">
              </span>
            </ng-container>

            <ng-container *ngIf="item.type === 'text_editor'">
              <p class="form-label">{{item.label}}<sub *ngIf="item.validations ==='required'" class="text-magenta">*</sub></p>
              <p-editor [name]="item.field" [(ngModel)]="item.component" class="form-editor">
                <ng-template pTemplate="header">
                  <span class="ql-formats">
                      <button type="button" class="ql-bold" aria-label="Bold"></button>
                      <button type="button" class="ql-italic" aria-label="Italic"></button>
                      <button type="button" class="ql-underline" aria-label="Underline"></button>
                  </span>
                </ng-template>
              </p-editor>
            </ng-container>

            <ng-container *ngIf="item.type === 'hidden' && item.configHiddenField.type === 'location' && item.configHiddenField.location.IsMapPreview">
              <ui-fibr-map></ui-fibr-map>
            </ng-container>
        </div>
    </div>

    <div class="grid mb-3" *ngIf="!actEdit">
        <div class="col-6 p-0 pr-2">
            <button pButton pRipple class="p-button p-button-secondary2 w-100" (click)="onButtonFloatClick();">{{lblBtnCancel}}</button>
        </div>
        <div class="col-6 p-0">
            <button pButton pRipple class="p-button p-component w-100" [disabled]="isActFrom" (click)="submitData(f)">{{lblBtnSave}}</button>
        </div>
    </div>
    <div class="grid mb-3" *ngIf="actEdit">
      <div class="col-6 p-0 pr-2">
          <button pButton pRipple class="p-button p-button-secondary2 w-100" (click)="onButtonFloatClick();">{{lblBtnCancel}}</button>
      </div>
      <div class="col-6 p-0 pr-2">
          <button pButton pRipple class="p-button p-component w-100" [disabled]="isActFrom" (click)="editData(f)" >Update</button>
      </div>
      <!-- <div class="col-4 p-0">
        <button pButton pRipple class="p-button p-button-danger w-100" [disabled]="isActFrom" (click)="deleteData()" >Delete</button>
      </div> -->
    </div>
</div>
</form>