import { Component, OnInit, Renderer2, AfterViewInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '../../../services/session.service';

@Component({
  selector: 'ui-fibr-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit, AfterViewInit {
  helpMenuItems: MenuItem[] = [];
  watchVideo: boolean = false;
  images: any[] = [];
  responsiveOptions: any[] = [
    {
      breakpoint: '1500px',
      numVisible: 5
    },
    {
      breakpoint: '1024px',
      numVisible: 3
    },
    {
      breakpoint: '768px',
      numVisible: 2
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  constructor(
    private domSanitizer: DomSanitizer,
    private renderer: Renderer2,
    private translate: TranslateService,
    private sessionService: SessionService,
  ) { }

  ngOnInit(): void {
    this.translate.use(this.sessionService.getItem("fibr-language"));

    this.images = [
      {
        itemImageSrc: this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/oTtmvGodPWY'),
        thumbnailImageSrc: 'https://i.ytimg.com/vi/oTtmvGodPWY/maxresdefault.jpg',
      },
      {
        itemImageSrc: this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/pqu002lMa1s'),
        thumbnailImageSrc: 'https://i.ytimg.com/vi_webp/pqu002lMa1s/sddefault.webp',
      },
      {
        itemImageSrc: this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/AMAr1_nU94k'),
        thumbnailImageSrc: 'https://i.ytimg.com/vi_webp/AMAr1_nU94k/sddefault.webp',
      },
      {
        itemImageSrc: this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/fO6xdoXUG60'),
        thumbnailImageSrc: 'https://i.ytimg.com/vi_webp/fO6xdoXUG60/sddefault.webp',
      },
      {
        itemImageSrc: this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/d3ex3_t0wEc'),
        thumbnailImageSrc: 'https://i.ytimg.com/vi_webp/d3ex3_t0wEc/sddefault.webp',
      },
      {
        itemImageSrc: this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/LBE4iL7RHY4'),
        thumbnailImageSrc: 'https://i.ytimg.com/vi_webp/LBE4iL7RHY4/sddefault.webp',
      },
    ];
  }

  ngAfterViewInit(): void {
    this.translate.get([
      'help.how_to_use',
      'help.watch_video',
      'help.version.history'
    ]).subscribe((trx) => {
      this.helpMenuItems = [
        {
          label: trx['help.how_to_use'],
          icon: 'pi pi-fw pi-question-circle',
          url: 'https://appfibr.io/learn/',
          target: '_blank'
        },
        {
          label: trx['help.watch_video'],
          icon: 'pi pi-fw pi-video',
          command: () => {
            this.watchVideo = true;

            const divElement = document.querySelector('.sub-header .p-toolbar.p-component');
            if (divElement != null)
              this.renderer.setStyle(divElement, 'z-index', 2);
          }
          // url: 'https://www.youtube.com/@appfibr',
          // target: '_blank'
        },
        {
          label: trx['help.version.history'],
          icon: 'pi pi-fw pi-list',
          url: 'https://appfibr.io/versi-rilis/',
          target: '_blank'
        }
      ];
    })
  }
}
