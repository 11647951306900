<!-- <div class="grid container-info">
  <div class="col-6 detail-info">
    <span class="page-info">
      <a href="javascript:;" (click)="is_showFilter = true;">Filter</a>
    </span>
  </div>
  <div class="col-6 detail-info" *ngIf="dataSum.length > 0">
    <span class="page-info">
      <a href="javascript:;" (click)="downloadAsPDF()">Download</a>
    </span>
  </div>
</div> -->

<div class="mobile-comp" *ngIf="(config.layoutDesign?.layoutStyle === 'card' || config.layoutDesign?.layoutStyle === 'list') &&  data.length === 0">
  <div class="comp-col comp-col-1 loose rounded">
    <div class="card-content">
      <div class="card-detail">
        <img src="/assets/images/ani_empty.gif" alt="Icon Support">
      </div>
    </div>
  </div>
</div>

<div class="mobile-comp" *ngIf="config.layoutDesign?.layoutStyle === 'card' && 
(!config.layoutDesign?.summaryType || config.layoutDesign?.summaryType === 'data') && dataSum.length > 0" [id]="'mysummary'">

  <div class="comp-col comp-col-1 summary-group-list card-group" [ngClass]="{'total-bottom': config.layoutDesign?.layoutPosition === 'bottom'}">
      <div class="card-list card-list-default total-summary-group">
        <div class="card-content">
            <div class="card-detail">
                <div class="detail-right">
                  <div class="avatar">
                      <div class="card-ava-container">
                        <div class="ava-title font-bold">{{dataTotal[0].group}}</div>
                      </div>
                      <div class="card-ava-container text-right">
                        <div class="ava-detail">{{dataTotal[0].labelMain}} {{dataTotal[0].mainVal}}</div>
                        <div class="ava-title">{{dataTotal[0].result | number}}</div>
                      </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
      <div class="comp-col summary-group-container">
        <div class="comp-col summary-group">
          <div class="comp-col" *ngFor="let field of dataSumTop" [ngClass]="{'mt-3 border-round-top-xl': field.group !== '' && field.sub_group === '' && field.child_group === ''}">
            <div class="card-list card-list-default title-summary-group" 
            *ngIf="field.group !== '' && field.sub_group === '' && field.child_group === ''">
              <div class="card-content">
                  <div class="card-detail">
                      <div class="detail-right">
                        <div class="avatar">
                            <div class="card-ava-container">
                              <div class="ava-title font-bold">{{field.group}}</div>
                            </div>
                            <div class="card-ava-container text-right">
                              <div class="ava-detail">Total {{field.label}}</div>
                              <div class="ava-title">{{field.result | number}}</div>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            <div class="comp-col summary-subgroup-list">
              <div class="card-list card-list-default title-summary-subgroup"  
              *ngIf="field.group !== '' && field.sub_group !== '' && field.child_group === ''">
                <div class="card-content">
                    <div class="card-detail">
                        <div class="detail-right">
                          <div class="avatar">
                              <div class="card-ava-container">
                                <div class="ava-title font-bold">{{field.sub_group}}</div>
                              </div>
                              <div class="card-ava-container text-right">
                                <div class="ava-detail">Total {{field.label}}</div>
                                <div class="ava-title">{{field.result | number}}</div>
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="comp-col summary-subgroup" *ngIf="field.group !== '' && field.sub_group !== '' && field.child_group !== ''">
                <div class="card-list card-list-default">
                  <div class="card-content">
                      <div class="card-detail">
                          <div class="detail-right">
                            <div class="avatar">
                                <div class="card-ava-container">
                                  <div class="ava-title">{{field.child_group}}</div>
                                  <div class="ava-detail">{{field.labelVal1}} {{field.value1}}</div>
                                  <div class="ava-detail">{{field.labelVal2}} {{field.value2}}</div>
                                </div>
                                <div class="card-ava-container text-right">
                                  <div class="ava-detail">{{field.label}}</div>
                                  <div class="ava-title">{{field.result | number}}</div>
                                </div>
                            </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>

<div class="mobile-comp" *ngIf="config.layoutDesign?.layoutStyle === 'list' && 
(!config.layoutDesign?.summaryType || config.layoutDesign?.summaryType === 'data') && dataSum.length > 0" [id]="'mysummary'">

  <div class="comp-col comp-col-1 summary-group-list list-group" [ngClass]="{'total-bottom': config.layoutDesign?.layoutPosition === 'bottom'}">
      <div class="card-list card-list-default total-summary-group">
        <div class="card-content">
            <div class="card-detail">
                <div class="detail-right">
                  <div class="avatar">
                      <div class="card-ava-container">
                        <div class="ava-title font-bold">{{dataTotal[0].group}}</div>
                      </div>
                      <div class="card-ava-container text-right">
                        <div class="ava-detail">Total {{dataTotal[0].mainVal}}</div>
                        <div class="ava-title">{{dataTotal[0].result | number}}</div>
                      </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
      <div class="comp-col summary-group-container">
        <div class="comp-col summary-group" *ngFor="let field of dataSumTop">
            <div class="card-list card-list-default title-summary-group" 
            *ngIf="field.group !== '' && field.sub_group === '' && field.child_group === ''">
              <div class="card-content">
                  <div class="card-detail">
                      <div class="detail-right">
                        <div class="avatar">
                            <div class="card-ava-container">
                              <div class="ava-title font-bold">{{field.group}}</div>
                            </div>
                            <div class="card-ava-container text-right">
                              <div class="ava-detail">Total {{field.label}}</div>
                              <div class="ava-title">{{field.result | number}}</div>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            <div class="comp-col summary-subgroup-list">
              <div class="card-list card-list-default title-summary-subgroup"  
              *ngIf="field.group !== '' && field.sub_group !== '' && field.child_group === ''">
                <div class="card-content">
                    <div class="card-detail">
                        <div class="detail-right">
                          <div class="avatar">
                              <div class="card-ava-container">
                                <div class="ava-title font-bold">{{field.sub_group}}</div>
                              </div>
                              <div class="card-ava-container text-right">
                                <div class="ava-detail">Total {{field.label}}</div>
                                <div class="ava-title">{{field.result | number}}</div>
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="comp-col summary-subgroup" *ngIf="field.group !== '' && field.sub_group !== '' && field.child_group !== ''">
                <div class="card-list card-list-default">
                  <div class="card-content">
                      <div class="card-detail">
                          <div class="detail-right">
                            <div class="avatar">
                                <div class="card-ava-container">
                                  <div class="ava-title">{{field.child_group}}</div>
                                  <div class="ava-detail">{{field.labelVal1}} {{field.value1}}</div>
                                  <div class="ava-detail">{{field.labelVal2}} {{field.value2}}</div>
                                </div>
                                <div class="card-ava-container text-right">
                                  <div class="ava-detail">{{field.label}}</div>
                                  <div class="ava-title">{{field.result | number}}</div>
                                </div>
                            </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
  </div>
</div>

<div class="mobile-comp" *ngIf="config.layoutDesign?.summaryType === 'chart' &&  data.length > 0" [id]="'mysummary'">
  <div class="comp-col comp-col-1 shape-xs loose rounded float fs-md align-center">
    <div class="card-grid card-grid-default" *ngFor="let field of dataSumChart">
      <div class="card-content">
          <div class="card-title-container">
            <div class="card-title">{{field.chartTitle}}</div>
          </div>
          <div class="card-detail">
            <div class="detail-left">
              <div class="avatar w-100">
                <p-chart class="w-100" *ngIf="field.chartType === 'pie'" type="pie" [data]="field.chartData" [options]="chartOptions"></p-chart>
                <p-chart class="w-100" *ngIf="field.chartType === 'line'" type="line" [data]="field.chartData" [options]="chartOptions"></p-chart>
                <p-chart class="w-100" *ngIf="field.chartType === 'bar'" type="bar" [data]="field.chartData" [options]="chartOptions"></p-chart>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>

<div #mobileContent class="modal-mobile"></div>

<p-dialog [modal]="false" [appendTo]="mobileContent" [(visible)]="is_showFilter" position="bottom" [draggable]="false" [resizable]="false">
  <ng-template pTemplate = "header">
      <span class="mx-auto">Filter Data</span>
  </ng-template>
  <form #frm="ngForm">
    <div  class="mobile-comp mobile-form p-0">
      <div class="p-grid p-fluid">
        <ng-container>
          <div class="field">
            <span class="p-float-label">
              <p-dropdown [options]="arrFilter" name="mainFilter"  optionLabel="label" optionValue="column" placeholder="Select Filter" appendTo="body"
              (onChange)="getFilter($event.value);" [(ngModel)]="mainFilter"></p-dropdown>
            </span>
          </div>
        </ng-container>
        <!-- <ng-container>&nbsp;</ng-container> -->
        <ng-container *ngIf="propertyFilter[0].columnType === 'string' || propertyFilter[0].columnType === 'text' || propertyFilter[0].columnType === 'image'
        || propertyFilter[0].columnType === 'link' || propertyFilter[0].columnType === 'boolean' || propertyFilter[0].columnType === 'text_editor'">
          <div class="field">
            <span class="relative">
                <label for="inputtext">Filter</label>
                <input name="filterText" type="text" id="inputtext" pInputText [(ngModel)]="filterText">
            </span>
          </div>
        </ng-container>
        <ng-container *ngIf="propertyFilter[0].columnType === 'number'">
          <div class="field">
            <span class="p-float-label">
              <p-dropdown [options]="formulaNumber" name="formulaValue" [(ngModel)]="formulaValue"  optionLabel="val" optionValue="val" placeholder="Select Formula" appendTo="body"></p-dropdown>
            </span>
          </div>
        </ng-container>
        <!-- <ng-container>&nbsp;</ng-container> -->
        <ng-container *ngIf="propertyFilter[0].columnType === 'number'">
          <div class="field">
            <span class="p-float-label">
              <p-inputNumber name="filterNumber" [(ngModel)]="filterNumber" inputId="minmaxfraction"
              mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="2" placeholder="Input number"></p-inputNumber>
            </span>
          </div>
        </ng-container>
          <ng-container *ngIf="propertyFilter[0].columnType === 'date' || propertyFilter[0].columnType === 'time'">
            <div class="field">
              <span class="relative">
                <label for="calendar">Start</label>
                <p-calendar name="filterDate1" [(ngModel)]="filterDate1" appendTo="body" inputId="basic" inputId="touchui" class="m-cal" [monthNavigator]="true" [yearNavigator]="true" yearRange="1945:2040"></p-calendar>
              </span>
            </div>
          </ng-container>
          <!-- <ng-container>&nbsp;</ng-container> -->
          <ng-container *ngIf="propertyFilter[0].columnType === 'date' || propertyFilter[0].columnType === 'time'">
            <div class="field">
              <span class="relative">
                <label for="calendar">End</label>
                <p-calendar name="filterDate2" [(ngModel)]="filterDate2" appendTo="body" inputId="basic" inputId="touchui" class="m-cal" [monthNavigator]="true" [yearNavigator]="true" yearRange="1945:2040"></p-calendar>
              </span>
            </div>
          </ng-container>
      </div>
      <div class="grid mb-3">
        <div class="col-6 p-0 pr-2">
          <button pButton pRipple class="p-button p-button-secondary2 w-100" (click)="cancelFilter()">Cancel</button>
        </div>
        <div class="col-6 p-0 pr-2">
            <button pButton pRipple class="p-button p-component w-100" (click)="showFilter(frm,propertyFilter[0]);">Show</button>
        </div>
      </div>
    </div>
  </form>
</p-dialog>

<p-dialog [modal]="false" [appendTo]="mobileContent" [(visible)]="is_download" position="bottom" [draggable]="false" [resizable]="false">
  <ng-template pTemplate = "header">
    <span class="mx-auto">Download Preview</span>
  </ng-template>
  
  <div class="grid container-info m-0">
    <div class="col-12 detail-info">
      <span class="page-info">
        <a href="javascript:;" (click)="downloadAsPDF()">Download</a>
      </span>
    </div>
  </div>

  <div class="mobile-print" [id]="'mysummary_dialog'">
    <div class="comp-col comp-col-1 summary-group-list card-group" *ngIf="config.layoutDesign?.layoutStyle === 'card' && dataSum.length > 0" [ngClass]="{'total-bottom': config.layoutDesign?.layoutPosition === 'bottom'}">
      <div class="card-list card-list-default total-summary-group">
        <div class="card-content">
            <div class="card-detail">
                <div class="detail-right">
                  <div class="avatar">
                      <div class="card-ava-container">
                        <div class="ava-title font-bold">{{dataTotal[0].group}}</div>
                      </div>
                      <div class="card-ava-container text-right">
                        <div class="ava-detail">{{dataTotal[0].labelMain}} {{dataTotal[0].mainVal}}</div>
                        <div class="ava-title">{{dataTotal[0].result | number}}</div>
                      </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
      <div class="comp-col summary-group-container">
        <div class="comp-col summary-group">
          <div class="comp-col" *ngFor="let field of dataSumTop" [ngClass]="{'mt-3 border-round-top-xl': field.group !== '' && field.sub_group === '' && field.child_group === ''}">
            <div class="card-list card-list-default title-summary-group" 
            *ngIf="field.group !== '' && field.sub_group === '' && field.child_group === ''">
              <div class="card-content">
                  <div class="card-detail">
                      <div class="detail-right">
                        <div class="avatar">
                            <div class="card-ava-container">
                              <div class="ava-title font-bold">{{field.group}}</div>
                            </div>
                            <div class="card-ava-container text-right">
                              <div class="ava-detail">Total {{field.label}}</div>
                              <div class="ava-title">{{field.result | number}}</div>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            <div class="comp-col summary-subgroup-list">
              <div class="card-list card-list-default title-summary-subgroup"  
              *ngIf="field.group !== '' && field.sub_group !== '' && field.child_group === ''">
                <div class="card-content">
                    <div class="card-detail">
                        <div class="detail-right">
                          <div class="avatar">
                              <div class="card-ava-container">
                                <div class="ava-title font-bold">{{field.sub_group}}</div>
                                <!-- <div class="ava-detail">{{field.labelVal1}}  {{field.value1}}</div>
                                <div class="ava-detail">{{field.labelVal2}}  {{field.value2}}</div> -->
                              </div>
                              <div class="card-ava-container text-right">
                                <div class="ava-detail">Total {{field.label}}</div>
                                <div class="ava-title">{{field.result | number}}</div>
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="comp-col summary-subgroup" *ngIf="field.group !== '' && field.sub_group !== '' && field.child_group !== ''">
                <div class="card-list card-list-default">
                  <div class="card-content">
                      <div class="card-detail">
                          <div class="detail-right">
                            <div class="avatar">
                                <div class="card-ava-container">
                                  <div class="ava-title">{{field.child_group}}</div>
                                  <div class="ava-detail">{{field.labelVal1}}  {{field.value1}}</div>
                                  <div class="ava-detail">{{field.labelVal2}}  {{field.value2}}</div>
                                </div>
                                <div class="card-ava-container text-right">
                                  <div class="ava-detail">Total {{field.label}}</div>
                                  <div class="ava-title">{{field.result | number}}</div>
                                </div>
                            </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="comp-col comp-col-1 summary-group-list list-group"  *ngIf="config.layoutDesign?.layoutStyle === 'list' && dataSum.length > 0" [ngClass]="{'total-bottom': config.layoutDesign?.layoutPosition === 'bottom'}">
      <div class="card-list card-list-default total-summary-group">
        <div class="card-content">
            <div class="card-detail">
                <div class="detail-right">
                  <div class="avatar">
                      <div class="card-ava-container">
                        <div class="ava-title font-bold">{{dataTotal[0].group}}</div>
                      </div>
                      <div class="card-ava-container text-right">
                        <div class="ava-detail">Total {{dataTotal[0].mainVal}}</div>
                        <div class="ava-title">{{dataTotal[0].result | number}}</div>
                      </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
      <div class="comp-col summary-group-container">
        <div class="comp-col summary-group" *ngFor="let field of dataSumTop">
            <div class="card-list card-list-default title-summary-group" 
            *ngIf="field.group !== '' && field.sub_group === '' && field.child_group === ''">
              <div class="card-content">
                  <div class="card-detail">
                      <div class="detail-right">
                        <div class="avatar">
                            <div class="card-ava-container">
                              <div class="ava-title font-bold">{{field.group }}</div>
                            </div>
                            <div class="card-ava-container text-right">
                              <div class="ava-detail">Total {{field.label }}</div>
                              <div class="ava-title">{{field.result | number}}</div>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            <div class="comp-col summary-subgroup-list">
              <div class="card-list card-list-default title-summary-subgroup"  
              *ngIf="field.group !== '' && field.sub_group !== '' && field.child_group === ''">
                <div class="card-content">
                    <div class="card-detail">
                        <div class="detail-right">
                          <div class="avatar">
                              <div class="card-ava-container">
                                <div class="ava-title font-bold">{{field.sub_group}}</div>
                                <!-- <div class="ava-detail">{{field.labelVal1}}  {{field.value1}}</div>
                                <div class="ava-detail">{{field.labelVal2}}  {{field.value2}}</div> -->
                              </div>
                              <div class="card-ava-container text-right">
                                <div class="ava-detail">Total {{field.label }}</div>
                                <div class="ava-title">{{field.result | number}}</div>
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="comp-col summary-subgroup" *ngIf="field.group !== '' && field.sub_group !== '' && field.child_group !== ''">
                <div class="card-list card-list-default">
                  <div class="card-content">
                      <div class="card-detail">
                          <div class="detail-right">
                            <div class="avatar">
                                <div class="card-ava-container">
                                  <div class="ava-title">{{field.child_group }}</div>
                                  <div class="ava-detail">{{field.labelVal1 }}  {{field.value1 }}</div>
                                  <div class="ava-detail">{{field.labelVal2 }}  {{field.value2 }}</div>
                                </div>
                                <div class="card-ava-container text-right">
                                  <div class="ava-detail">{{field.label }}</div>
                                  <div class="ava-title">{{field.result | number}}</div>
                                </div>
                            </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</p-dialog>

