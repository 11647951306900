/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-fibr-toast-notification',
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.css']
})

export class ToastNotificationComponent {
  @Input() position = 'top-center';

  constructor() { }

}
