<button pButton pRipple type="button" *ngIf="countRead === 0" class="p-button-rounded p-button-primary mr-3 p-button-text p-button-icon-only" 
pTooltip="{{ 'menuNotification' | translate }}" tooltipPosition="bottom" (click)="notif.toggle($event)">
  <img alt="icon" src="/assets/images/icon/icon_notifications.svg" height="20">
</button>

<button pButton pRipple type="button" *ngIf="countRead > 0" class="p-button-rounded p-button-primary mr-3 p-button-text p-button-icon-only notification-trigger" 
  pTooltip="{{ 'menuNotification' | translate }}" tooltipPosition="bottom" (click)="notif.toggle($event)">
      <img alt="icon" src="/assets/images/icon/icon_notifications.svg" height="20">
      <p-badge [value]="countRead" severity="danger" class="notification-trigger__badge"></p-badge>
</button>

<p-overlayPanel #notif styleClass="notif-overlay-panel">
  <ng-template pTemplate="content">
    <h3 class="menu-title"><i class="pi pi-bell text-2xl mr-3"></i>{{ 'menuNotification' | translate }}</h3>
    <p-divider></p-divider>

    <div class="grid" *ngIf="notifUser.length === 0">
      <div class="col-12 text-center">
        <img src="/assets/images/ani_empty.gif" alt="Icon Support">
        <h5 class="menu-subtitle mt-0 mb-8">
          {{ 'notificationEmpty' | translate }}
        </h5>
      </div>
    </div>

    <div *ngIf="notifUser.length > 0">
        <div class="notification-item" *ngFor="let field of notifUser">
          <div class="card box notif-list" [ngClass]="field.read ? 'bg-transparent' : ''">
            <span class="custom-marker shadow-2" [ngClass]="field.result === 'success' ? 'bg-green' : 'bg-red'">
              <i class="pi" [ngClass]="field.result === 'success' ? 'pi-check' : 'pi-times'"></i>
            </span>
            <div class="notif-detail">
              <a href="javascript:;" (click)="changeData(field)">
                <h5 class="text-500 d-flex mb-2">{{field.title}}</h5>
                <p class="text-400">{{field.content}}</p>
              </a>

              <div class="flex justify-content-between align-items-center mt-2">
                <div class="flex align-items-center">
                  <button *ngIf="field.result === 'error' && field.type === 'build_app'"
                  type="button" pButton class="p-button-sm" (click)="navigateToProject(field)">
                    Rebuild
                  </button>

                  <button *ngIf="field.type === 'import_gsheet' || field.type === 'export_gsheet'"
                  type="button" pButton class="p-button-sm" (click)="navigateToProject(field)">
                    Redirect To Project
                  </button>                  
                </div>

                <div>
                  <span class="text-300">{{(field.created_date['seconds']*1000) | date : 'dd MMM yyyy HH:mm' }}</span>
                </div>
              </div>
            </div>

            <button pButton pRipple type="button"
            icon="pi pi-times" class="p-button-rounded p-button-text notification-item__button-close"
            (click)="hideNotification(field)"></button>
          </div>
        </div>
    </div>
  </ng-template>
</p-overlayPanel>

<p-dialog [(visible)]="showProject" *ngIf="showProject" [modal]="true" [blockScroll]="true" [draggable]="false" [dismissableMask]="true" position="top" appendTo="body" [style]="{width: '700px'}" styleClass="dialog-no-footer">
  <ng-template pTemplate = "header">
    <span class="p-dialog-title"><img alt="icon" src="/assets/images/icon-lg/icon_project-lg.svg" height="20" class="align-self-center mr-3">Project Details</span>
  </ng-template>
  <div class="grid">
    <div class="col-12">
        <h6>Project Publish</h6>
        <div class="grid">
            <div class="col-12 md:col-3 pl-5">
                <label class="label-info">App Name</label>
            </div>
            <div class="col-12 md:col-9">
                <b>{{detailProject?.app_name}}</b>
            </div>
            <div class="col-12 md:col-3 pl-5">
              <label class="label-info">Package Name</label>
            </div>
            <div class="col-12 md:col-9">
                <b>{{detailProject?.package_name}}</b>
            </div>
            <div class="col-12 md:col-3 pl-5">
              <label class="label-info">Publish Date</label>
            </div>
            <div class="col-12 md:col-9">
                <b *ngIf="detailProject?.published_date && detailNotif.result === 'success'">
                  {{(detailProject?.published_date['seconds']*1000) | date : 'dd MMM yyyy HH:mm'}}
                </b>
            </div>
            <div class="col-12 md:col-3 pl-5">
              <label class="label-info">Url App</label>
            </div>
            <div class="col-12 md:col-9">
                <!-- <a *ngIf="detailProject?.file_url && detailNotif.result === 'success'" [href]="detailProject?.file_url"
                style="line-break: anywhere;">
                  {{detailProject?.file_url}}
                </a> -->
                <button *ngIf="detailNotif.result ==='error'" (click)="navigateToProject(detailNotif)" pButton type="button" label="Rebuild" icon="pi pi-replay" iconPos="right" class="p-button p-component d-flex"></button>
                <button *ngIf="detailNotif.result ==='success'" (click)="downloadApk(detailProject?.file_url)" pButton type="button" label="Download" icon="pi pi-cloud-download" iconPos="right" class="p-button p-component d-flex"></button>
            </div>
        </div>
    </div>
  </div>
</p-dialog>

<!-- <a type="button" href="javascript:void(0)" (click)="notifMenu.toggle($event)" pButton pRipple type="button" 
class="p-button-rounded p-button-primary mr-3 p-button-text p-button-icon-only" pTooltip="Notification" tooltipPosition="bottom">
  <img alt="icon" src="/assets/images/icon/icon_notifications.svg" height="20">
</a>
<p-menu #notifMenu [popup]="true" [model]="notifUser" appendTo="body"></p-menu> -->
