<div class="grid">
  <div class="col-12">
      <p class="">{{'editor.add.table.name' | translate}}</p>
      <input type="text" pInputText class="w-100" placeholder="Field title" [(ngModel)]="fields.newName"> 
  </div>
  <!-- <div class="col-12">
    <p class="">Header Label</p>
    <input type="text" disabled pInputText class="w-100" placeholder="Field title" [(ngModel)]="fields.fieldHeader"> 
  </div>
  <div class="col-12">
      <p class="">Field Type</p>
      <p-dropdown styleClass="mb-3" [options]="dataSource" optionLabel="name" optionValue="value" [(ngModel)]="fields.fieldType" (onChange)="fieldTypeChange($event)"></p-dropdown>   
  </div> -->
</div>
<div class="grid">
  <div class="col-12 text-right">
    <button (click)="cancelDialog()" pButton type="button" label="Cancel" icon="" iconPos="right" class="p-button p-button-secondary p-component p-ripple mr-2 mb-2"></button>
    <button (click)="doItDialog()" pButton type="button" label="Update" icon="" iconPos="right" class="p-button p-component p-ripple mb-2"></button>
  </div>
</div>
