  <!-- <div *ngIf="statusApp === 'prod-fibr'">
    <div *ngIf="isDataExists">
      <div class="grid my-5">
        <div class="col-12">
            <h5 class="text-center menu-subtitle mb-3"><b>You're on free trial mode!</b></h5>
            <p class="text-center">You can only create I project on this free trial mode</p>
        </div>
      </div>
    </div>
    <div *ngIf="!isDataExists">
      <div *ngIf="!isGoogleFiles">
        <div class="grid">
            <div class="col-12 mb-2">
                <h5 class="text-center menu-subtitle mb-1">You can copy the template</h5>
                <p class="text-center">by copied the template you can re-use design app and replace existing data with your new data</p>
            </div>
        </div>
        <p-divider></p-divider>
        <div class="grid">
            <div class="col-12 px-5">
                <p class="">Your project Name<sup>*</sup></p>
                <input type="text" pInputText class="w-100" [(ngModel)]="appConfig.app_name" placeholder="Project title"> 
                <sub *ngIf="showMsg" class="text-magenta">field required</sub>
            </div>
        </div>
        <p-divider></p-divider>
        <div class="grid center mb-5">
            <div class="col-auto center flex-column mx-4 mt-4" *ngIf="false">
                <button [disabled]="appConfig.app_name === null" (click)="withTemplate()" pButton type="button" label="Blank Table" icon="pi pi-chevron-right" iconPos="right" class="p-button-secondary p-button-outlined p-button-sm mr-2 mb-2 bg-dark-blue text-white"></button>
            </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- <div *ngIf="statusApp === 'dev-fibr' || statusApp === 'staging-fibr'"> -->
  <div>
      <div *ngIf="!isGoogleFiles">
        <div class="grid">
            <div class="col-12 mb-2">
                <h5 class="text-center menu-subtitle mb-1">{{'project.template.popup.subtitle' | translate}}</h5>
                <p class="text-center">{{'project.template.popup.desc' | translate }}</p>
            </div>
        </div>
        <p-divider></p-divider>
        <div class="grid">
            <div class="col-12 px-5">
                <p class="">{{'project.template.form.name' | translate }}<sup>*</sup></p>
                <input type="text" pInputText class="w-100" [(ngModel)]="appConfig.app_name" placeholder="{{'project.template.form.name.placeholder'|translate}}"> 
                <sub *ngIf="showMsg" class="text-magenta">field required</sub>
            </div>
        </div>
        <p-divider></p-divider>
        <div class="grid center mb-5">
            <div class="col-auto center flex-column mx-4 mt-4">
                <button [disabled]="appConfig.app_name === null" (click)="withTemplate()" pButton type="button" label="{{'project.template.copy.button' | translate}}" icon="pi pi-chevron-right" iconPos="right" class="p-button p-button-sm mr-2 mb-2"></button>
            </div>
        </div>
      </div>
  </div>