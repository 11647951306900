import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeString'
})
export class TimeStringPipe implements PipeTransform {
  pipe = new DatePipe('en-US');
  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }
  transform(value: any): any {
    // let data: any;
    // if (typeof(value) === 'object' && value.toString().substring(0,9) === 'Timestamp') {
    //   const frmTgl = value.toDate();
    //   const bucket = this.pipe.transform(frmTgl, 'dd MMM yyyy HH:mm');
    //   data = bucket;
    // } else {
    //   data = value;
    // }
    let data: any = new Date(value);
    if (data != 'Invalid Date') {
      data = this.pipe.transform(data, 'dd MMM yyyy HH:mm');
    } else {
      data = value;
    }
    return data;
  }
}
