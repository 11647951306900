<div class="card box overview-box white bg-dark-blue summary-card profile-card" *ngIf="config.dataContent?.isProfileBanner && this.userProject !== null">
  <div class="overview-info d-flex">
      <img alt="icon" class="mr-3" src="/assets/images/icon/icon_user.svg">
      <div class="overview-info align-self-center">
          <!-- <h6 class="text-white text-sm">Hi, <b class="text-orange">Username</b></h6>
          <span class="text-white text-xs">email@domain.com</span> -->
          <h6 class="text-white text-sm">Hi, <b class="text-orange">{{userProject.fullname}} </b></h6>
          <span class="text-white text-xs">{{userProject.email}}</span>
      </div>
  </div>
</div>

<!-- <div class="grid container-info">
  <div class="col-12 detail-info">
    <span *ngIf="config.dataContent?.isNumberOfLines">Total {{data.length}} Data</span>
    <span *ngIf="config.dataContent?.pageInfo?.status" class="page-info"><a href="javascript:;">{{config.dataContent?.pageInfo?.label}}</a></span>
  </div>
</div> -->
<div class="grid container-info" *ngIf="config.dataContent?.isNumberOfLines || config.dataContent?.pageInfo?.status">
  <div class="col-12 detail-info">
    <span *ngIf="config.dataContent?.isNumberOfLines">Total {{data.length}} Data</span>
    <span *ngIf="!config.dataContent?.isNumberOfLines">&nbsp;</span>
    <span class="page-info" *ngIf="config.dataContent?.pageInfo?.status">
      <a href="javascript:;" (click)="getSumGroupData()">{{config.dataContent?.pageInfo?.label}}</a>
    </span>
    <span class="page-info" *ngIf="!config.dataContent?.pageInfo?.status">&nbsp;</span>
  </div>
</div>

<div class="mobile-comp " [ngClass]="{'horz' : layoutStyle.orientation === 'horizontal'}">
  <div class="comp-col shape-xs" *ngIf="data.length === 0" [ngClass]="{
    'comp-col-1': layoutStyle.column == '1',
    'loose': layoutStyle.padding == 'loose', 
    'rounded': layoutStyle.corners == 'rounded',
    'outline' : layoutStyle.border == 'outline',
    'float' : layoutStyle.border == 'float',
    'shape-xs' : layoutStyle.shape == '3:1',
    'shape-sm': layoutStyle.shape == '3:2',
    'shape-md': layoutStyle.shape == 'sq',
    'shape-lg': layoutStyle.shape == '3:4',
    'shape-xl': layoutStyle.shape == '3:5',
    'fs-md': layoutText.fontsize == 'medium',
    'fs-lg': layoutText.fontsize == 'large',
    'align-right': layoutText.aligment == 'right',
    'align-center': layoutText.aligment == 'center',
    'text-lower': layoutText.lettercase == 'lowercase',
    'text-upper': layoutText.lettercase == 'uppercase'
}">
    <ng-container>
      <div class="card-grid card-grid-default" >
        <!-- <div class="card-img"> -->
          <img src="/assets/images/ani_empty.gif" alt="Icon Support">
        <!-- </div> -->
      </div>
    </ng-container>
  </div>

    <div class="comp-col shape-xs" *ngIf="data.length > 0" [ngClass]="{
            'comp-col-1': layoutStyle.column == '1',
            'comp-col-2': layoutStyle.column == '2', 
            'comp-col-3': layoutStyle.column == '3',
            'comp-col-4': layoutStyle.column == '4',
            'loose': layoutStyle.padding == 'loose', 
            'rounded': layoutStyle.corners == 'rounded',
            'outline' : layoutStyle.border == 'outline',
            'float' : layoutStyle.border == 'float',
            'shape-xs' : layoutStyle.shape == '3:1',
            'shape-sm': layoutStyle.shape == '3:2',
            'shape-md': layoutStyle.shape == 'sq',
            'shape-lg': layoutStyle.shape == '3:4',
            'shape-xl': layoutStyle.shape == '3:5',
            'fs-md': layoutText.fontsize == 'medium',
            'fs-lg': layoutText.fontsize == 'large',
            'align-right': layoutText.aligment == 'right',
            'align-center': layoutText.aligment == 'center',
            'text-lower': layoutText.lettercase == 'lowercase',
            'text-upper': layoutText.lettercase == 'uppercase'
        }">
        <ng-container *ngIf="layoutText.position === 'below';else overlay">
            <a href="javascript:;" class="card-grid card-grid-default" *ngFor="let field of data"
                (click)="onButtonDetailClick(field)">
                <div class="card-img" *ngIf="field[config.dataContent?.images!] !== null">
                  <img *ngIf="config.dataContent?.isImageUrl" class="img" [src]="field[config.dataContent?.images!] | gdriveConvert"
                    onerror="this.src='/assets/icon_no_image.png'">
                  <div *ngIf="!config.dataContent?.isImageUrl" class="img">
                      {{field[config.dataContent?.images!] !== null? ((field[config.dataContent?.images!] | stripTags) | slice:0:1) : ''}}
                  </div>
                </div>
                <div class="card-content">
                    <div class="card-title-container" *ngIf="field[config.dataContent?.header!] !== null || field[config.dataContent?.mainTitle!] !== null">
                      <div class="card-header" *ngIf="headerType !== 'number' && headerType !== 'calculation'">{{field[config.dataContent?.header!] | stripTags}}</div> 
                      <div class="card-header" *ngIf="headerType === 'number' || headerType === 'calculation'">{{field[config.dataContent?.header!] | number}}</div> 
                      <!-- <div class="card-header" *ngIf="headerType !== 'text_editor'">{{field[config.dataContent?.header!]}}</div>
                        <div class="card-header" *ngIf="headerType === 'text_editor'">
                          <p-editor styleClass="editor-content-only" [(ngModel)]="field[config.dataContent?.header!]" [readonly]="true">
                            <ng-template pTemplate="header">
                                <span class="ql-formats"></span>
                            </ng-template>
                          </p-editor>
                        </div> -->
                      <div class="card-title" *ngIf="mainTitleType !== 'number' && mainTitleType !== 'calculation'">{{field[config.dataContent?.mainTitle!] | stripTags}}</div>
                      <div class="card-title" *ngIf="mainTitleType === 'number' || mainTitleType === 'calculation'">{{field[config.dataContent?.mainTitle!] | number}}</div>
                    </div>

                    <div class="card-desc" *ngIf="descriptionType !=='number' && descriptionType !== 'calculation'">{{field[config.dataContent?.description!] | stripTags}}</div>
                    <div class="card-desc" *ngIf="descriptionType ==='number' || descriptionType === 'calculation'">{{field[config.dataContent?.description!] | number}}</div>

                    <div *ngIf="config.dataContent?.additionalImage" class="card-detail">
                      <div class="detail-left">
                        <div class="avatar">
                          <img *ngIf="config.dataContent?.isImageUrl && field[config.dataContent?.additionalImage!] !== null" class="ava-img"
                            [src]="field[config.dataContent?.additionalImage!] | gdriveConvert" onerror="this.src='/assets/icon_no_image.png'">
                          <div *ngIf="!config.dataContent?.isImageUrl && field[config.dataContent?.additionalImage!] !== null" class="ava-img">
                            {{field[config.dataContent?.additionalImage!] !== null?
                            ((field[config.dataContent?.additionalImage!]) | slice:0:1) : ''}}</div>
                          <div class="card-ava-container">
                            <div class="ava-title" *ngIf="additionalTextType !== 'number' && additionalTextType !== 'calculation'">{{field[config.dataContent?.additionalText!] | stripTags}}</div>
                            <div class="ava-title" *ngIf="additionalTextType === 'number' || additionalTextType === 'calculation'">{{field[config.dataContent?.additionalText!] | number}}</div>
                            <div class="ava-detail" *ngIf="additionalLabelType !== 'number' && additionalLabelType !== 'calculation'">{{field[config.dataContent?.additionalLabel!] | stripTags}}</div>
                            <div class="ava-detail" *ngIf="additionalLabelType === 'number' || additionalLabelType === 'calculation'">{{field[config.dataContent?.additionalLabel!] | number}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="detail-right">
                        <div class="avatar" *ngIf="detailType !== 'number' && detailType !== 'calculation'"><div>{{field[config.dataContent?.details!] | stripTags}}</div></div>
                        <div class="avatar" *ngIf="detailType === 'number' || detailType === 'calculation'"><div>{{field[config.dataContent?.details!] | number}}</div></div>
                      </div>
                    </div>
                </div>
                <div class="card-overlay" >
                  <div class="label-overlay">
                    <div class="label-content top-right-label">
                      <div class="label-text" *ngIf="labelType !=='number' && labelType !== 'calculation'">{{field[config.dataContent?.label!] | stripTags}}</div>
                      <div class="label-text" *ngIf="labelType ==='number' || labelType === 'calculation'">{{field[config.dataContent?.label!] | number}}</div>
                    </div>
                  </div>
                </div>
            </a>
        </ng-container>

        <ng-template #overlay>
            <a href="javascript:;" class="card-grid card-grid-overlay" *ngFor="let field of data"
                (click)="onButtonDetailClick(field)">
                <div class="card-img">
                    <img *ngIf="config.dataContent?.isImageUrl" class="img" [src]="field[config.dataContent?.images!] | gdriveConvert"
                        onerror="this.src='/assets/icon_no_image.png'">
                    <div *ngIf="!config.dataContent?.isImageUrl" class="img">{{field[config.dataContent?.images!] !==
                        null? ((field[config.dataContent?.images!] | stripTags) | slice:0:1) : ''}}</div>
                </div>
                <div class="card-overlay">
                    <div class="label-overlay">
                        <div class="label-content top-right-label">
                          <!-- <div class="label-text">{{field[config.dataContent?.label! | stripTags]}}</div> -->
                          <div class="label-text" *ngIf="labelType !=='number' && labelType !== 'calculation'">{{field[config.dataContent?.label!] | stripTags}}</div>
                          <div class="label-text" *ngIf="labelType ==='number' || labelType === 'calculation'">{{field[config.dataContent?.label!] | number}}</div>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="card-title-container">
                          <!-- <div class="card-header">{{field[config.dataContent?.header!] | stripTags}}</div> -->
                          <div class="card-header" *ngIf="headerType !== 'number' && headerType !== 'calculation'">{{field[config.dataContent?.header!] | stripTags}}</div> 
                          <div class="card-header" *ngIf="headerType === 'number' || headerType === 'calculation'">{{field[config.dataContent?.header!] | number}}</div> 
                          <!-- <div class="card-title">{{field[config.dataContent?.mainTitle!] | stripTags}}</div> -->
                          <div class="card-title" *ngIf="mainTitleType !== 'number' && mainTitleType !== 'calculation'">{{field[config.dataContent?.mainTitle!] | stripTags}}</div>
                          <div class="card-title" *ngIf="mainTitleType === 'number' || mainTitleType === 'calculation'">{{field[config.dataContent?.mainTitle!] | number}}</div>
                        </div>
                        <!-- <div class="card-desc">{{field[config.dataContent?.description!] | stripTags}}</div> -->
                        <div class="card-desc" *ngIf="descriptionType !=='number' && descriptionType !== 'calculation'">{{field[config.dataContent?.description!] | stripTags}}</div>
                        <div class="card-desc" *ngIf="descriptionType ==='number' || descriptionType === 'calculation'">{{field[config.dataContent?.description!] | number}}</div>

                        <div *ngIf="config.dataContent?.additionalImage" class="card-detail">
                          <div class="detail-left">
                            <div class="avatar">
                              <img *ngIf="config.dataContent?.isImageUrl && field[config.dataContent?.additionalImage!] !== null" class="ava-img"
                                [src]="field[config.dataContent?.additionalImage!] | gdriveConvert" onerror="this.src='/assets/icon_no_image.png'">
                              <div *ngIf="!config.dataContent?.isImageUrl && field[config.dataContent?.additionalImage!] !== null" class="ava-img">
                                {{field[config.dataContent?.additionalImage!] !== null?
                                ((field[config.dataContent?.additionalImage!]) | slice:0:1) : ''}}</div>
                              <div class="card-ava-container">
                                <!-- <div class="ava-title">{{field[config.dataContent?.additionalText!]}}</div> -->
                                <div class="ava-title" *ngIf="additionalTextType !== 'number' && additionalTextType !== 'calculation'">{{field[config.dataContent?.additionalText!] | stripTags}}</div>
                                <div class="ava-title" *ngIf="additionalTextType === 'number' || additionalTextType === 'calculation'">{{field[config.dataContent?.additionalText!] | number}}</div>
                                <!-- <div class="ava-detail">{{field[config.dataContent?.additionalLabel!]}}</div> -->
                                <div class="ava-detail" *ngIf="additionalLabelType !== 'number' && additionalLabelType !== 'calculation'">{{field[config.dataContent?.additionalLabel!] | stripTags}}</div>
                                <div class="ava-detail" *ngIf="additionalLabelType === 'number' || additionalLabelType === 'calculation'">{{field[config.dataContent?.additionalLabel!] | number}}</div>
                              </div>
                            </div>
                          </div>
                          <div class="detail-right">
                            <!-- <div class="avatar"><div>{{field[config.dataContent?.details!]}}</div></div> -->
                            <div class="avatar" *ngIf="detailType !== 'number' && detailType !== 'calculation'"><div>{{field[config.dataContent?.details!] | stripTags}}</div></div>
                            <div class="avatar" *ngIf="detailType === 'number' || detailType === 'calculation'"><div>{{field[config.dataContent?.details!] | number}}</div></div>
                          </div>
                        </div>
                    </div>
                </div>
            </a>
        </ng-template>
    </div>
</div>

<button *ngIf="config.formConfiguration?.add" pButton pRipple type="button" icon="pi pi-plus"
    class="p-button-rounded btn-float" (click)="onButtonFloatClick()"></button>

    <div #mobileContent class="modal-mobile"></div>

    <p-dialog [modal]="false" [appendTo]="mobileContent" [(visible)]="modalPageInfo" position="bottom" [draggable]="false" [resizable]="false">
      <ng-template pTemplate = "header">
          <span class="mx-auto">Page Info</span>
      </ng-template>
      <div class="comp-col comp-col-1 loose outline rounded" *ngIf="infoGroupData.length === 0">
        <div class="card-list card-list-default" *ngIf="config.dataContent?.pageInfo?.sum?.column !== ''">
          <div class="card-content">
            <div class="card-detail">
              <div class="detail-right">
                <div class="avatar">
                  <div class="ava-title text-base">All {{fieldSum}}</div>
                  <div class="card-ava-container text-right">
                    <div class="ava-detail">Total {{infoHeaderGroup[0]?.name}}</div>
                    <div class="ava-title">{{totalSum | number}} </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="comp-col comp-col-1 loose outline rounded" *ngIf="infoGroupData.length > 0">
        <div class="card-list card-list-default" *ngIf="config.dataContent?.pageInfo?.sum?.column !== ''">
          <div class="card-content">
            <div class="card-detail">
              <div class="detail-right">
                <div class="avatar">
                  <div class="ava-title text-base">All {{fieldSum}}</div>
                  <div class="card-ava-container text-right">
                    <div class="ava-detail">Total {{infoHeaderGroup[0]?.name}}</div>
                    <div class="ava-title">{{totalSum | number}} </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-list card-list-default" *ngFor="let group of infoGroupData">
          <div class="card-content">
            <div class="card-detail">
              <div class="detail-right">
                <div class="avatar">
                  <div class="ava-title text-base">{{group.header}}</div>
                  <div class="card-ava-container text-right">
                    <div class="ava-detail">Total {{infoHeaderGroup[0]?.name}}</div>
                    <div class="ava-title">{{group.groupData | number}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-dialog>