<div class="card box overview-box white bg-dark-blue summary-card profile-card" *ngIf="config.dataContent?.isProfileBanner && this.userProject !== null">
  <div class="overview-info d-flex">
      <img alt="icon" class="mr-3" src="/assets/images/icon/icon_user.svg">
      <div class="overview-info align-self-center">
          <h6 class="text-white text-sm">Hi, <b class="text-orange">{{userProject.fullname}} </b></h6>
          <span class="text-white text-xs">{{userProject.email}}</span>
      </div>
  </div>
</div>

<div class="grid container-info" *ngIf="config.dataContent?.isNumberOfLines || config.dataContent?.pageInfo?.status">
  <div class="col-12 detail-info">
    <span *ngIf="config.dataContent?.isNumberOfLines">Total {{data.length}} Data</span>
    <span *ngIf="!config.dataContent?.isNumberOfLines">&nbsp;</span>
    <span class="page-info" *ngIf="config.dataContent?.pageInfo?.status">
      <a href="javascript:;" (click)="getSumGroupData();">{{config.dataContent?.pageInfo?.label}}</a>
    </span>
    <span class="page-info" *ngIf="!config.dataContent?.pageInfo?.status">&nbsp;</span>
  </div>
</div>

<div class="mobile-comp" *ngIf="(config.layoutDesign?.layoutStyle === 'card' || config.layoutDesign?.layoutStyle === 'list'
|| config.layoutDesign?.layoutStyle === 'group_list') &&  data.length === 0">
  <div class="comp-col comp-col-1 loose rounded">
    <div class="card-content">
      <div class="card-detail">
        <img src="/assets/images/ani_empty.gif" alt="Icon Support">
      </div>
    </div>
  </div>
</div>

<div class="mobile-comp" *ngIf="config.layoutDesign?.layoutStyle === 'card' &&  data.length > 0"  #uiElement (scroll)="onScrollLoadData()" style="overflow-y: auto;max-height:1000px;">
    <div class="comp-col comp-col-1 loose outline rounded" *ngIf="data.length > 0">
      <a href="javascript:;" (click)="onButtonDetailClick(field)" class="card-list card-list-default"
            *ngFor="let field of data">
            <div class="card-content" [ngClass]="{'pt-5': field[config.dataContent?.label!]}">
                <div class="card-detail">
                    <div [ngClass]="{'detail-left': config.layoutDesign?.layoutImagePosition === 'left', 'detail-right': config.layoutDesign?.layoutImagePosition === 'right'}">
                        <div class="avatar" *ngIf="config.dataContent?.images !== ''">
                              <img *ngIf="config.dataContent?.isImageUrl && field[config.dataContent?.images!] !== null" class="ava-img"
                                [src]="field[config.dataContent?.images!] | gdriveConvert" onerror="this.src='/assets/icon_no_image.png'">
                              <div *ngIf="!config.dataContent?.isImageUrl && field[config.dataContent?.images!] !== null" class="ava-img">
                                {{field[config.dataContent?.images!] !== null ?
                                ((field[config.dataContent?.images!] | stripTags) | slice:0:1) : ''}}
                              </div>
                            <div class="card-ava-container">
                              <div class="ava-title" *ngIf="mainTitleType !=='number' && mainTitleType !=='calculation'">{{field[config.dataContent?.mainTitle!] | stripTags}}</div>
                              <div class="ava-title" *ngIf="mainTitleType ==='number' || mainTitleType ==='calculation'">{{field[config.dataContent?.mainTitle!] | number}}</div>
                              <div class="ava-detail" *ngIf="descriptionType !=='number' && descriptionType !=='calculation'">{{field[config.dataContent?.description!] | stripTags}}</div>
                              <div class="ava-detail" *ngIf="descriptionType ==='number' || descriptionType ==='calculation'">{{field[config.dataContent?.description!] | number}}</div>
                            </div>
                        </div>
                        <div class="avatar" *ngIf="config.dataContent?.images === ''">
                          <div class="card-ava-container">
                            <div class="ava-title" *ngIf="mainTitleType !=='number' && mainTitleType !=='calculation'">{{field[config.dataContent?.mainTitle!] | stripTags}}</div>
                            <div class="ava-title" *ngIf="mainTitleType ==='number' || mainTitleType ==='calculation'">{{field[config.dataContent?.mainTitle!] | number}}</div>
                            <div class="ava-detail" *ngIf="descriptionType !=='number' && descriptionType !=='calculation'">{{field[config.dataContent?.description!] | stripTags}}</div>
                            <div class="ava-detail" *ngIf="descriptionType ==='number' || descriptionType ==='calculation'">{{field[config.dataContent?.description!] | number}}</div>
                              <!-- <div class="ava-title" *ngIf="mainTitleType !=='text_editor'">{{field[config.dataContent?.mainTitle!]}}</div>
                              <div class="ava-title" *ngIf="mainTitleType ==='text_editor'">
                                <p-editor styleClass="editor-content-only" [(ngModel)]="field[config.dataContent?.mainTitle!]" [readonly]="true" [style]="{'width':'200px'}">
                                  <ng-template pTemplate="header">
                                      <span class="ql-formats"></span>
                                  </ng-template>
                                </p-editor>
                              </div>
                              <div class="ava-detail" *ngIf="descriptionType !=='text_editor'">{{field[config.dataContent?.description!]}}</div>
                              <div class="ava-detail" *ngIf="descriptionType ==='text_editor'">
                                <p-editor styleClass="editor-content-only" [(ngModel)]="field[config.dataContent?.description!]" [readonly]="true" [style]="{'width':'200px'}">
                                  <ng-template pTemplate="header">
                                    <span class="ql-formats"></span>
                                  </ng-template>
                                </p-editor>
                              </div> -->
                          </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-overlay" *ngIf="config.dataContent?.label !== '' && field[config.dataContent?.label!]">
              <div class="label-overlay">
                <div class="label-content top-right-label">
                  <div class="label-text" *ngIf="labelType !=='number' && labelType !=='calculation'">{{field[config.dataContent?.label!] | stripTags}}</div>
                  <div class="label-text" *ngIf="labelType ==='number' || labelType ==='calculation'">{{field[config.dataContent?.label!] | number}}</div>
                </div>
              </div>
            </div>
      </a>
    </div>
</div>

<div class="mobile-comp" *ngIf="config.layoutDesign?.layoutStyle === 'list' &&  data.length > 0" #uiElement (scroll)="onScrollLoadData()" style="overflow-y: auto;max-height:1000px;">
    <div class="comp-col comp-col-1 outline">
        <a href="javascript:;" (click)="onButtonDetailClick(field)" class="card-list card-list-default list-type1"
            *ngFor="let field of data">
            <div class="card-content" [ngClass]="{'pt-5': field[config.dataContent?.label!]}">
                <div class="card-detail">
                    <div
                        [ngClass]="{'detail-left': config.layoutDesign?.layoutImagePosition === 'left', 'detail-right': config.layoutDesign?.layoutImagePosition === 'right'}">
                        <div class="avatar" *ngIf="config.dataContent?.images !== ''">
                            <img *ngIf="config.dataContent?.isImageUrl" class="ava-img"
                                [src]="field[config.dataContent?.images!] | gdriveConvert" onerror="this.src='/assets/icon_no_image.png'">
                            <div *ngIf="!config.dataContent?.isImageUrl" class="ava-img">
                                {{field[config.dataContent?.images!] !== null ?
                                  ((field[config.dataContent?.images!] | stripTags) | slice:0:1) : ''}}
                            </div>
                            <div class="card-ava-container">
                              <!-- <div class="ava-title">{{field[config.dataContent?.mainTitle!] | stripTags}}</div>
                              <div class="ava-detail">{{field[config.dataContent?.description!] | stripTags}}</div> -->
                              <div class="ava-title" *ngIf="mainTitleType !=='number' && mainTitleType !=='calculation'">{{field[config.dataContent?.mainTitle!] | stripTags}}</div>
                              <div class="ava-title" *ngIf="mainTitleType ==='number' || mainTitleType ==='calculation'">{{field[config.dataContent?.mainTitle!] | number}}</div>
                              <div class="ava-detail" *ngIf="descriptionType !=='number' && descriptionType !=='calculation'">{{field[config.dataContent?.description!] | stripTags}}</div>
                              <div class="ava-detail" *ngIf="descriptionType ==='number' || descriptionType ==='calculation'">{{field[config.dataContent?.description!] | number}}</div>
                            </div>
                        </div>
                        <div class="avatar" *ngIf="config.dataContent?.images === ''">
                            <div class="card-ava-container">
                              <!-- <div class="ava-title">{{field[config.dataContent?.mainTitle!] | stripTags}}</div>
                              <div class="ava-detail">{{field[config.dataContent?.description!] | stripTags}}</div> -->
                              <div class="ava-title" *ngIf="mainTitleType !=='number' && mainTitleType !=='calculation'">{{field[config.dataContent?.mainTitle!] | stripTags}}</div>
                              <div class="ava-title" *ngIf="mainTitleType ==='number' || mainTitleType ==='calculation'">{{field[config.dataContent?.mainTitle!] | number}}</div>
                              <div class="ava-detail" *ngIf="descriptionType !=='number' && descriptionType !=='calculation'">{{field[config.dataContent?.description!] | stripTags}}</div>
                              <div class="ava-detail" *ngIf="descriptionType ==='number' || descriptionType ==='calculation'">{{field[config.dataContent?.description!] | number}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-overlay" *ngIf="config.dataContent?.label !== '' && field[config.dataContent?.label!]">
              <div class="label-overlay">
                <div class="label-content top-right-label">
                  <div class="label-text" *ngIf="labelType !=='number' && labelType !=='calculation'">{{field[config.dataContent?.label!] | stripTags}}</div>
                  <div class="label-text" *ngIf="labelType ==='number' || labelType ==='calculation'">{{field[config.dataContent?.label!] | number}}</div>
                </div>
              </div>
            </div>
        </a>
    </div>
</div>

<div class="mobile-comp" *ngIf="config.layoutDesign?.layoutStyle === 'group_list' && data.length > 0" #uiElement (scroll)="onScrollLoadData()" style="overflow-y: auto;max-height:1000px;">
    <div class="comp-col comp-col-1 group-list">
        <a href="javascript:;" (click)="onButtonDetailClick(field)" class="card-list card-list-default"
            *ngFor="let field of data">
            <div class="card-content" [ngClass]="{'pt-5': field[config.dataContent?.label!]}">
                <div class="card-detail">
                    <div
                        [ngClass]="{'detail-left': config.layoutDesign?.layoutImagePosition === 'left', 'detail-right': config.layoutDesign?.layoutImagePosition === 'right'}">
                        <div class="avatar" *ngIf="config.dataContent?.images !== ''">
                            <img *ngIf="config.dataContent?.isImageUrl" class="ava-img"
                                [src]="field[config.dataContent?.images!] | gdriveConvert" onerror="this.src='/assets/icon_no_image.png'">
                            <div *ngIf="!config.dataContent?.isImageUrl" class="ava-img">
                                {{field[config.dataContent?.images!] !== null ?
                                  ((field[config.dataContent?.images!] | stripTags) | slice:0:1) : ''}}
                            </div>
                            <div class="card-ava-container">
                              <!-- <div class="ava-title">{{field[config.dataContent?.mainTitle!] | stripTags}}</div>
                              <div class="ava-detail">{{field[config.dataContent?.description!] | stripTags}}</div> -->
                              <div class="ava-title" *ngIf="mainTitleType !=='number' && mainTitleType !=='calculation'">{{field[config.dataContent?.mainTitle!] | stripTags}}</div>
                              <div class="ava-title" *ngIf="mainTitleType ==='number' || mainTitleType ==='calculation'">{{field[config.dataContent?.mainTitle!] | number}}</div>
                              <div class="ava-detail" *ngIf="descriptionType !=='number' && descriptionType !=='calculation'">{{field[config.dataContent?.description!] | stripTags}}</div>
                              <div class="ava-detail" *ngIf="descriptionType ==='number' || descriptionType ==='calculation'">{{field[config.dataContent?.description!] | number}}</div>
                            </div>
                        </div>
                        <div class="avatar" *ngIf="config.dataContent?.images === ''">
                            <div class="card-ava-container">
                              <!-- <div class="ava-title">{{field[config.dataContent?.mainTitle!] | stripTags}}</div>
                              <div class="ava-detail">{{field[config.dataContent?.description!] | stripTags}}</div> -->
                              <div class="ava-title" *ngIf="mainTitleType !=='number' && mainTitleType !=='calculation'">{{field[config.dataContent?.mainTitle!] | stripTags}}</div>
                              <div class="ava-title" *ngIf="mainTitleType ==='number' || mainTitleType ==='calculation'">{{field[config.dataContent?.mainTitle!] | number}}</div>
                              <div class="ava-detail" *ngIf="descriptionType !=='number' && descriptionType !=='calculation'">{{field[config.dataContent?.description!] | stripTags}}</div>
                              <div class="ava-detail" *ngIf="descriptionType ==='number' || descriptionType ==='calculation'">{{field[config.dataContent?.description!] | number}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-overlay" *ngIf="config.dataContent?.label !== '' && field[config.dataContent?.label!]">
              <div class="label-overlay">
                <div class="label-content top-right-label">
                  <div class="label-text" *ngIf="labelType !=='number' && labelType !=='calculation'">{{field[config.dataContent?.label!] | stripTags}}</div>
                  <div class="label-text" *ngIf="labelType ==='number' || labelType ==='calculation'">{{field[config.dataContent?.label!] | number}}</div>
                </div>
              </div>
            </div>
        </a>
    </div>
</div>

<button *ngIf="config.formConfiguration?.add" pButton pRipple type="button" icon="pi pi-plus"
    class="p-button-rounded btn-float" (click)="onButtonFloatClick()"></button>


<div #mobileContent class="modal-mobile"></div>

<p-dialog [modal]="false" [appendTo]="mobileContent" [(visible)]="modalPageInfo" position="bottom" [draggable]="false" [resizable]="false">
  <ng-template pTemplate = "header">
      <span class="mx-auto">Page Info</span>
  </ng-template>
  <div class="comp-col comp-col-1 loose outline rounded" *ngIf="infoGroupData.length === 0">
    <div class="card-list card-list-default" *ngIf="config.dataContent?.pageInfo?.sum?.column !== ''">
      <div class="card-content">
        <div class="card-detail">
          <div class="detail-right">
            <div class="avatar">
              <div class="ava-title text-base">All {{fieldSum}}</div>
              <div class="card-ava-container text-right">
                <div class="ava-detail">Total {{infoHeaderGroup[0]?.name}}</div>
                <div class="ava-title">{{totalSum | number}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="comp-col comp-col-1 loose outline rounded" *ngIf="infoGroupData.length > 0">
    <div class="card-list card-list-default" *ngIf="config.dataContent?.pageInfo?.sum?.column !== ''">
      <div class="card-content">
        <div class="card-detail">
          <div class="detail-right">
            <div class="avatar">
              <div class="ava-title text-base">All {{fieldSum}}</div>
              <div class="card-ava-container text-right">
                <div class="ava-detail">Total {{infoHeaderGroup[0]?.name}}</div>
                <div class="ava-title">{{totalSum | number}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-list card-list-default" *ngFor="let group of infoGroupData">
      <div class="card-content">
        <div class="card-detail">
          <div class="detail-right">
            <div class="avatar">
              <div class="ava-title text-base">{{group.header}}</div>
              <div class="card-ava-container text-right">
                <div class="ava-detail">Total {{infoHeaderGroup[0]?.name}}</div>
                <div class="ava-title">{{group.groupData | number}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-dialog>