import { Component, Input, OnInit } from '@angular/core';
import { customMenuItem } from '../../clases/global';
import { QueryParamsHandling } from '@angular/router';
interface MenuItem {
  label?: string;
  icon?: string;
  command?: (event?: any) => void;
  url?: string;
  items?: MenuItem[];
  expanded?: boolean;
  disabled?: boolean;
  visible?: boolean;
  target?: string;
  escape?: boolean;
  routerLinkActiveOptions?: any;
  separator?: boolean;
  badge?: string;
  tooltip?: string;
  tooltipPosition?: string;
  badgeStyleClass?: string;
  style?: any;
  styleClass?: string;
  title?: string;
  id?: string;
  automationId?: any;
  tabindex?: string;
  routerLink?: any;
  queryParams?: {
      [k: string]: any;
  };
  fragment?: string;
  queryParamsHandling?: QueryParamsHandling;
  preserveFragment?: boolean;
  skipLocationChange?: boolean;
  replaceUrl?: boolean;
  state?: {
      [k: string]: any;
  };
  order_no?:string|undefined;
}
@Component({
  selector: 'ui-fibr-leftmenu,[uiFibrLeftmenu]',
  templateUrl: './leftmenu.component.html',
  styleUrls: ['./leftmenu.component.scss']
})
export class LeftmenuComponent implements OnInit {
  @Input() customMenu: customMenuItem | undefined;
  @Input() items: MenuItem[] = [
    {
        label: 'Summary',
        icon: 'pi pi-fw pi-file',
        routerLink: 'transaction/layanan/janjitemu',
        routerLinkActiveOptions: { exact: true },
        styleClass : 'active'
        
    },
    {
        label: 'Transactions',
        icon: 'pi pi-fw pi-pencil',
        routerLink: 'transactions',
        routerLinkActiveOptions: { exact: true }
        
    },
    {
        label: 'Users',
        icon: 'pi pi-fw pi-user',
        routerLink: 'users',
        routerLinkActiveOptions: { exact: true }
    }
  ];

  collapsed:any;
  constructor() { 
    console.log();
  }

  ngOnInit(): void {
    console.log();
  }

}
