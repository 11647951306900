import { Component, OnInit } from '@angular/core';
import { GlobalcrudService, GlobalService } from "@core-fibr/shared";
@Component({
  selector: 'ui-fibr-global',
  templateUrl: './global.component.html',
  styleUrls: ['./global.component.scss']
})
export class GlobalComponent implements OnInit {
  disbursementHistoryData: any[] = [];
  page_id: any;
  page_from: any;
  disbursed_amount: any;
  transaction_amount: any;

  constructor(private globalServiceCrud: GlobalcrudService, private globalService: GlobalService,) { }

  ngOnInit(): void {
    this.globalService.disbursementHistory.subscribe((val: any) => {
      console.log(val)
      this.page_id = val.page_id;
      this.page_from = val.from;
      const user_id = val.user_id;
      const app_id = val.app_id;
      const doc_id = val.doc_id;
      this.disbursed_amount = val.disbursed_online;
      this.transaction_amount = val.transaction_online;
      const pathDisbursementHistory = `/users/${user_id}/summary_detail_page_payment_transaction_fibr/${app_id}/data/${doc_id}/history`;
      this.globalServiceCrud.getcollectionorderbydesc(pathDisbursementHistory, 'modify_date').subscribe((resp: any) => {
        this.disbursementHistoryData = resp;
      });

      // const pathDisbursement = `/users/${user_id}/summary_detail_page_payment_transaction_fibr/${app_id}/data`;
      // this.globalServiceCrud.getcollectionwithid(pathDisbursement, doc_id).subscribe((resp: any) => {
      //   this.disbursed_amount = resp.disbursed_amount;
      //   this.transaction_amount = resp.transaction_amount;
      // });
    });

  }
  formatCurrency(amount: number | string): string {
    if (amount == null) return '';

    const numericAmount = +amount; // Convert to number if it's a string
    return numericAmount.toLocaleString('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0, maximumFractionDigits: 0 });
  }

}
