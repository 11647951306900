<div class="login-body layout-light">
    <div class="login-content">
        <div class="login-panel">
            <div class="login-panel-content text-left">
              <div class="col login-header">
                <div class="logo">
                  <img [src]="logo" alt="{{labelAlt}}" />
                </div>
                <h4 class="mb-2">{{'ResetPasswordMessage' | translate}}</h4>
                <span>{{'ResetPasswordSubMessage' | translate}}</span>
              </div>
              <div class="col">
                <div class="forms mt-0">

                    <div class="login-input-wrapper mb-3">
                      <label for="password">Password</label>
                      <input [type]="fieldTextType ? 'text' : 'password'" [(ngModel)]="password" id="password" placeholder="{{'placeholderPassword' | translate}}" ppassword="" class="p-inputtext p-component line-input py-3" />
                      <i [class]="fieldTextType? 'pi pi-eye': 'pi pi-eye-slash'" (click)="toggleFieldTextType()"></i>
                    </div>

                    <div class="login-input-wrapper mb-3">
                      <label for="password">Confirm Password</label>
                      <input [type]="fieldTextTypes ? 'text' : 'password'" [(ngModel)]="cpassword" id="cpassword" placeholder="{{'confirmpassword' | translate}}" ppassword="" class="p-inputtext p-component line-input py-3" />
                      <i [class]="fieldTextTypes? 'pi pi-eye': 'pi pi-eye-slash'" (click)="toggleFieldTextTypes()"></i>
                    </div>
                    
                    <div class="buttons">
                        <button pbutton="" [disabled]="isProgressSpinner" type="button" label="Login" tabindex="0" class="col-12 py-3 p-button text-lg" (click)="onSubmit()"
                        [ngStyle]="{ 'background-color': primaryButtonColor}"
                        >
                          <span class="p-button-label">{{'ResetPassword' | translate}}</span>
                          <p-progressSpinner *ngIf="isProgressSpinner" strokeWidth="3" styleClass="progress-spinner-btn" [style]="{width: '20px', height: '20px'}" ></p-progressSpinner>
                        </button>
                    </div>
                    <div class="col-12 center">
                      <a href="javascript:;" [routerLink]="['/auth/login']" pbutton="" type="button" label="Register" tabindex="0" class="p-button-text mt-3"
                        [ngStyle]="{ 'color': primaryButtonColor}"
                      >
                        <span class="p-button-label">{{'backtologin' | translate}}</span>
                      </a>
                    </div>
                </div>
              </div>
            </div>
        </div>
        <div class="login-panel right " 
          [ngStyle]="{
            'background-image': 'url(' + image_forgotPassword + ')',
            'background-size': 'contain',
            'background-position': 'center',
            'background-repeat': 'no-repeat'}"
            >
          <div *ngIf="env.app_name === 'fibr'" class="login-panel-content">
            <h4 class="mb-2">{{env.login_right.h4_forgotPassword | translate}}</h4>
            
            <div class="mt-auto">
              <span>Copyright © 2023 <img *ngIf="env.app_name === 'fibr'" src="/assets/logo_fibr_footer.svg" height="14">
              </span>
            </div>
          </div>
        </div>
    </div>
</div>
