<div class="layout-main full m-0 hidden lg:flex"  [ngStyle]="{'background-image': 'url(' + appConfig.splash_screen + ')'}">
  <div class="layout-content flex">
      <div class="grid absolute" *ngIf="!isPaid">
          <div class="col text-center">
              <p class="text-xs">Powered by</p>
              <a href="https://appfibr.io/"><img src="/assets/logo_appfibr.svg" alt="AppFibr Logo" height="30"/></a>
          </div>
      </div>
      <div class="grid align-items-center justify-content-center w-100">
          <div class="col-12 lg:col-5 ml-auto align-self-center">
              <div class="list-item p-0 mb-3">
                  <div class="list-detail d-flex flex-row align-items-center">
                      <img alt="Project Image" class="img-template" [src]="appConfig.app_icon" onerror="this.src = './assets/iconic_logo.png'">
                      <div class="d-block">
                          <!-- <p>05/01/2023</p> -->
                          <h3 class="list-title m-0">{{appConfig.app_name}}</h3>
                      </div>
                  </div>
              </div>
              <p class="list-desc mb-5">{{appConfig.app_desc}}</p>
              <div class="flex">
                  <div class="m-auto w-100">
                      <p class="text-sm text-center">Share App with link</p>
                      <p-button (click)="copyLinkClick(linkApp)" styleClass="p-button p-button-primary w-100" label="Copy Link" icon="pi pi-link" iconPos="right" > </p-button>
                  </div>
                  <p-divider class="mx-5" layout="vertical">
                      <b>OR</b>
                  </p-divider>
                  <div class="m-auto w-100">
                      <p class="text-sm text-center">Scan QR Code</p>
                      <div class="card box inline-flex p-2">
                        <qrcode [qrdata]="linkApp" [errorCorrectionLevel]="'M'" [elementType]="'img'" [allowEmptyString]="true"></qrcode>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-12 lg:col-5 mr-auto">
              <div class="grid">
                  <div class="col-12 preview-app">
                      <div class="iphone">
                          <div class="content">
                            <div *ngIf="actFrom === 'pwa'" class="splash-screen" [ngStyle]="{'background-image': 'url(' + appConfig.splash_screen + ')'}">
                              <!-- <img class="img-app" [src]="logo" alt="AppFibr Logo"/> -->
                              <div class="grid justify-content-center" *ngIf="!isPaid">
                                  <div class="col-auto splash-powered">
                                      <p>Powered by</p>
                                      <a href="https://appfibr.io/"><img src="/assets/logo_appfibr.svg" alt="AppFibr Logo"/></a>
                                  </div>
                              </div>
                            </div>
                            <div  class="mobile-comp mobile-form">
                              <div class="grid" >
                                <div class="col-12 mt-2 z-1">
                                  <span class="p-button-label center">the application cannot be used!</span>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<ui-fibr-blockui [blocked]="blocked"></ui-fibr-blockui>