import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'ui-fibr-header,[uiFibrHeader]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() items: MenuItem[] = [];
  @Input() srcImg!: string;
  @Input() userProfilePath= '/portal/config/user-profile';
  @Input() aksesFrom!: string;
  
  constructor() {
    //
  }

  ngOnInit(): void {
    console.log();
  }

}
