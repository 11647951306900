/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, NgZone, PLATFORM_ID } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { SessionService } from "../session.service";
import { HttpClient } from "@angular/common/http";
import { first } from "rxjs/operators";
import { ToastNotificationService } from "../toast-notification.service";
import { environment } from "libs/auth/src/environments/environment";
import firebase from "firebase";
import { GlobalService } from "../global.service";

@Injectable({
  providedIn: "root",
})
export class GlobalCrudPromisesService {
  // auth_dynamic = new AngularFireAuth(
  //   environment.firebase,
  //   environment.firebase.projectId,
  //   PLATFORM_ID,
  //   this.zone,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null
  // );
  // firebase_dynamic = new AngularFirestore(
  //   environment.firebase,
  //   environment.firebase.projectId,
  //   false,
  //   null,
  //   PLATFORM_ID,
  //   this.zone,
  //   null,
  //   null,
  //   null
  // );

  // auth_sifa = new AngularFireAuth(
  //   environment.firebase_additional,
  //   environment.firebase_additional.projectId,
  //   PLATFORM_ID,
  //   this.zone,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null
  // );
  // firebase_additional = new AngularFirestore(
  //   environment.firebase_additional,
  //   environment.firebase_additional.projectId,
  //   false,
  //   null,
  //   PLATFORM_ID,
  //   this.zone,
  //   null,
  //   null,
  //   null
  // );
  auth_dynamic= new AngularFireAuth({},null,PLATFORM_ID,this.zone,null,null,null,null,null,null);
  firebase_dynamic= new AngularFirestore({},null,false,null,PLATFORM_ID,this.zone,null,null,null);
  auth_sifa= new AngularFireAuth({},null,PLATFORM_ID,this.zone,null,null,null,null,null,null);
  firebase_additional= new AngularFirestore({},null,false,null,PLATFORM_ID,this.zone,null,null,null);

  lastVisible!: any;
  size!: number;
  constructor(
    private firestore: AngularFirestore,
    private sessionService: SessionService,
    private zone: NgZone,
    private httpClient: HttpClient,
    public notificationService: ToastNotificationService,private globalService: GlobalService,
  ) {
    const env = this.globalService.decryptEnv(environment);
    this.auth_dynamic = new AngularFireAuth(
      env.firebase,env.firebase.projectId,PLATFORM_ID,this.zone,null,null,null,null,null,null
    );
    this.firebase_dynamic = new AngularFirestore(
      env.firebase,env.firebase.projectId,false,null,PLATFORM_ID,this.zone,null,null,null
    );
  
    this.auth_sifa = new AngularFireAuth(
      env.firebase_additional,env.firebase_additional.projectId,PLATFORM_ID,this.zone,null,null,null,null,null,null
    );
    this.firebase_additional = new AngularFirestore(
      env.firebase_additional,env.firebase_additional.projectId,false,null,PLATFORM_ID,this.zone,null,null,null
    );
  }

  dynamicFirebase(config: any, isAuth: boolean = false, credential: any) {
    this.auth_dynamic = new AngularFireAuth(
      config,
      config.projectId,
      PLATFORM_ID,
      this.zone,
      null,
      null,
      null,
      null,
      null,
      null
    );
    this.firebase_dynamic = new AngularFirestore(
      config,
      config.projectId,
      false,
      null,
      PLATFORM_ID,
      this.zone,
      null,
      null,
      null
    );
    if (isAuth) {
      this.auth_dynamic.signInWithEmailAndPassword(
        credential.email,
        credential.password
      );
    }
  }

  createcollection(data: any, collection: string): any {
    return new Promise<any>((resolve, reject) => {
      return this.firebase_dynamic
        .collection(collection)
        .add(data)
        .then(
          () => {
            console.log();
          },
          (err) => reject(err)
        );
    });
  }

  createcollectionwithdocumentname(
    data: any,
    collection: string,
    docname: string
  ): any {
    return new Promise<any>((resolve, reject) => {
      return this.firebase_dynamic
        .collection(collection)
        .doc(docname)
        .set(data)
        .then(
          (_) => {
            resolve(_);
          },
          (err) => reject(err)
        );
    });
  }

  modifyCollection(data: any, path: string, docId: string) {
    return new Promise<any>((resolve, reject) => {
      return this.firebase_dynamic
        .collection(path)
        .doc(docId)
        .set(data)
        .then(
          (_) => {
            resolve(_);
          },
          (err) => reject(err)
        );
    });
  }

  get_id_collection(collection: string) {
    return new Promise<any>((resolve, reject) => {
      return this.firebase_dynamic
        .collection(collection)
        .get()
        .toPromise()
        .then(
          (_) => {
            resolve(_);
          },
          (err) => reject(err)
        );
    });
  }

  getcollectionsbyid(collection: string, id: string) {
    return new Promise<any>((resolve, reject) => {
      return this.firebase_dynamic
        .collection(collection)
        .doc(id)
        .get()
        .toPromise()
        .then(
          (_) => {
            resolve(_);
          },
          (err) => reject(err)
        );
    });
  }

  getcollectionsbyidpromise(collection: string, id: string): Promise<any> {
    return this.firebase_dynamic
      .collection(collection)
      .doc(id)
      .valueChanges()
      .pipe(first())
      .toPromise();
  }

  getCollectionsMultiWhereField(collection: string, body: any) {
    if (body.first == 0) {
      const first = this.firebase_dynamic
        .collection(collection, (ref) =>
          ref
            .where(body.whereField[0], "==", body[body.whereField[0]])
            .where(body.whereField[1], "==", body[body.whereField[1]])
            .where(body.whereField[2], "==", body[body.whereField[2]])
            .limit(body.rows)
        )
        .get()
        .toPromise();

      first.then((documentSnapshots: any) => {
        this.lastVisible =
          documentSnapshots.docs[documentSnapshots.docs.length - 1];
      });
      return new Promise<any>((resolve, reject) => {
        return first.then(
          (_) => {
            resolve(_);
          },
          (err) => reject(err)
        );
      });
    } else {
      const next = this.firebase_dynamic
        .collection(collection, (ref) =>
          ref
            .orderBy(body.sortField, body.sortOrder)
            .startAfter(this.lastVisible)
            .limit(body.rows)
        )
        .get()
        .toPromise();
      next.then((documentSnapshots: any) => {
        this.lastVisible =
          documentSnapshots.docs[documentSnapshots.docs.length - 1];
      });
      return new Promise<any>((resolve, reject) => {
        return next.then(
          (_) => {
            resolve(_);
          },
          (err) => reject(err)
        );
      });
    }
  }

  getFirstDayOfMonth(year: number, month: number) {
    const start = new Date(year, month, 1);
    const end = new Date(year, month + 1, 1);
    return { start_date: start, end_date: end };
  }

  async getCollections(collection: string, body: any) {
    if (typeof body.whereField == "object")
      return this.getCollectionsMultiWhereField(collection, body);
    if (body.first == 0) {
      body.sortOrder = body.sortField == "id" ? "desc" : body.sortOrder;
      body.sortField = body.sortField == "id" ? "created_date" : body.sortField;

      let first = this.firebase_dynamic
        .collection(collection, (ref) =>
          ref.orderBy(body.sortField, body.sortOrder).limit(body.rows)
        )
        .get()
        .toPromise();

      if (
        body.whereField &&
        body.whereField !== "faskes" &&
        body.whereField !== "date"
      )
        first = this.firebase_dynamic
          .collection(collection, (ref) =>
            ref
              .where(body.whereField, "==", body[body.whereField])
              .orderBy(body.sortField, body.sortOrder)
              .limit(body.rows)
          )
          .get()
          .toPromise();
      else if (body.whereField === "faskes")
        first = this.firebase_dynamic
          .collection(collection, (ref) =>
            ref
              .where(body.whereField, "array-contains", body[body.whereField])
              .orderBy(body.sortField, body.sortOrder)
              .limit(body.rows)
          )
          .get()
          .toPromise();
      else if (body.whereField === "date") {
        const date = new Date(body[body.whereField]);
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const lastDay = new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          0,
          23,
          59,
          59
        );
        first = this.firebase_dynamic
          .collection(collection, (ref) =>
            ref
              .where(body.whereField, ">=", new Date(firstDay))
              .where(body.whereField, "<=", new Date(lastDay))
              // .orderBy(body.sortField, body.sortOrder)
              .limit(body.rows)
          )
          .get()
          .toPromise();
      }

      first.then((documentSnapshots: any) => {
        this.lastVisible =
          documentSnapshots.docs[documentSnapshots.docs.length - 1];
      });
      return new Promise<any>((resolve, reject) => {
        return first.then(
          (_) => {
            resolve(_);
          },
          (err) => reject(err)
        );
      });
    } else {
      body.sortOrder = body.sortField == "id" ? "desc" : body.sortOrder;
      body.sortField = body.sortField == "id" ? "created_date" : body.sortField;
      const all = await this.firebase_dynamic
        .collection(collection, (ref) =>
          ref.orderBy(body.sortField, body.sortOrder)
        )
        .get()
        .toPromise()
        .then((documentSnapshots: any) => {
          this.lastVisible = documentSnapshots.docs[body.first];
        });
      const next = this.firebase_dynamic
        .collection(collection, (ref) =>
          ref
            .orderBy(body.sortField, body.sortOrder)
            .startAfter(this.lastVisible)
            .limit(body.rows)
        )
        .get()
        .toPromise();

      return new Promise<any>((resolve, reject) => {
        return next.then(
          (_) => {
            resolve(_);
          },
          (err) => reject(err)
        );
      });
    }
  }

  getCollections_1(collection: string, body: any) {
    if (typeof body.whereField == "object")
      return this.getCollectionsMultiWhereField(collection, body);
    if (body.first == 0) {
      body.sortOrder = body.sortField == "id" ? "desc" : body.sortOrder;
      body.sortField = body.sortField == "id" ? "created_date" : body.sortField;

      let first = this.firebase_dynamic
        .collection(collection, (ref) =>
          ref.orderBy(body.sortField, body.sortOrder).limit(body.rows)
        )
        .get()
        .toPromise();
      if (
        body.whereField &&
        body.whereField !== "faskes" &&
        body.whereField !== "date"
      )
        first = this.firebase_dynamic
          .collection(collection, (ref) =>
            ref
              .where(body.whereField, "==", body[body.whereField])
              .orderBy(body.sortField, body.sortOrder)
              .limit(body.rows)
          )
          .get()
          .toPromise();
      else if (body.whereField === "faskes")
        first = this.firebase_dynamic
          .collection(collection, (ref) =>
            ref
              .where(body.whereField, "array-contains", body[body.whereField])
              .orderBy(body.sortField, body.sortOrder)
              .limit(body.rows)
          )
          .get()
          .toPromise();
      else if (body.whereField === "date") {
        const date = new Date(body[body.whereField]);
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const lastDay = new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          0,
          23,
          59,
          59
        );
        first = this.firebase_dynamic
          .collection(collection, (ref) =>
            ref
              .where(body.whereField, ">=", new Date(firstDay))
              .where(body.whereField, "<=", new Date(lastDay))
              .limit(body.rows)
          )
          .get()
          .toPromise();
      }

      first.then((documentSnapshots: any) => {
        this.lastVisible =
          documentSnapshots.docs[documentSnapshots.docs.length - 1];
      });
      return new Promise<any>((resolve, reject) => {
        return first.then(
          (_) => {
            resolve(_);
          },
          (err) => reject(err)
        );
      });
    } else {
      body.sortOrder = body.sortField == "id" ? "desc" : body.sortOrder;
      body.sortField = body.sortField == "id" ? "created_date" : body.sortField;
      const next = this.firebase_dynamic
        .collection(collection, (ref) =>
          ref
            .orderBy(body.sortField, body.sortOrder)
            .startAfter(this.lastVisible)
            .limit(body.rows)
        )
        .get()
        .toPromise();
      next.then((documentSnapshots: any) => {
        this.lastVisible =
          documentSnapshots.docs[documentSnapshots.docs.length - 1];
      });
      return new Promise<any>((resolve, reject) => {
        return next.then(
          (_) => {
            resolve(_);
          },
          (err) => reject(err)
        );
      });
    }
  }

  getSearchCollectionsByColumn(collection: string, body: any) {
    if (body.first == 0) {
      const first = this.firebase_dynamic
        .collection(collection, (ref) =>
          ref.orderBy(body.sortField, body.sortOrder)
        )
        .get()
        .toPromise();
      first.then((documentSnapshots: any) => {
        this.lastVisible =
          documentSnapshots.docs[documentSnapshots.docs.length - 1];
      });
      return new Promise<any>((resolve, reject) => {
        return first.then(
          (docsnapshot) => {
            const docs: firebase.firestore.QueryDocumentSnapshot<unknown>[] = [];
            let length = 0;
            let fltr: any = [];
            docsnapshot.docs.map((doc) => {
              fltr = [];
              body.filters.map((filter: any) => {
                if (filter.propertyName == "uid") {
                  if (
                    doc.id
                      .toLocaleLowerCase()
                      .includes(filter.value.toLocaleLowerCase())
                  ) {
                    fltr.push({
                      propertyName: filter.propertyName,
                      value: filter.value,
                      matchMode: filter.matchMode,
                    });
                  }
                } else {
                  const data: any = doc.data();
                  const loop = Object.keys(data);
                  for (let index = 0; index < loop.length; index++) {
                    const key = loop[index];
                    if (
                      key != "created_date" &&
                      key != "modify_date" &&
                      data[key] !== null
                    ) {
                      const val = data[key];
                      if (
                        val
                          .toString()
                          .toLocaleLowerCase()
                          .includes(filter.value.toLocaleLowerCase())
                      ) {
                        fltr.push({
                          propertyName: filter.propertyName,
                          value: filter.value,
                          matchMode: filter.matchMode,
                        });
                      }
                    }
                  }
                }
              });

              const len = body.filters.length;
              let n = 0;
              for (let index = 0; index < len; index++) {
                const element = body.filters[index];
                if (
                  fltr.find((x: any) => x.propertyName == element.propertyName)
                )
                  n += 1;
              }

              if (docs.length < body.rows && n === len) {
                docs.push(doc);
                this.lastVisible = doc;
                length++;
              }
            });
            this.size = length;
            resolve({ docs: docs, size: length });
          },
          (err) => reject(err)
        );
      });
    } else {
      const next = this.firebase_dynamic
        .collection(collection, (ref) =>
          ref
            .orderBy(body.sortField, body.sortOrder)
            .startAfter(this.lastVisible)
            .limit(body.rows)
        )
        .get()
        .toPromise();

      return new Promise<any>((resolve, reject) => {
        return next.then(
          (docsnapshot) => {
            const docs: firebase.firestore.QueryDocumentSnapshot<unknown>[] = [];
            let fltr: any = [];
            docsnapshot.docs.map((doc) => {
              fltr = [];
              body.filters.map((filter: any) => {
                if (filter.propertyName == "uid") {
                  if (
                    doc.id
                      .toLocaleLowerCase()
                      .includes(filter.value.toLocaleLowerCase())
                  ) {
                    fltr.push({
                      propertyName: filter.propertyName,
                      value: filter.value,
                      matchMode: filter.matchMode,
                    });
                  }
                } else {
                  const data: any = doc.data();
                  const loop = Object.keys(data);
                  for (let index = 0; index < loop.length; index++) {
                    const key = loop[index];
                    if (
                      key != "created_date" &&
                      key != "modify_date" &&
                      data[key] !== null
                    ) {
                      const val = data[key];
                      if (
                        val
                          .toString()
                          .toLocaleLowerCase()
                          .includes(filter.value.toLocaleLowerCase())
                      ) {
                        fltr.push({
                          propertyName: filter.propertyName,
                          value: filter.value,
                          matchMode: filter.matchMode,
                        });
                      }
                    }
                  }
                }
              });
              const len = body.filters.length;
              let n = 0;
              for (let index = 0; index < len; index++) {
                const element = body.filters[index];
                if (
                  fltr.find((x: any) => x.propertyName == element.propertyName)
                )
                  n += 1;
              }

              if (docs.length < body.rows && n === len) {
                docs.push(doc);
                this.lastVisible = doc;
              }
            });
            resolve({ docs: docs, size: this.size });
          },
          (err) => reject(err)
        );
      });
    }
  }

  getSearchCollections(collection: string, body: any, value?: any) {
    if (body.first == 0) {
      const first = this.firebase_dynamic
        .collection(collection, (ref) =>
          ref.orderBy(body.sortField, body.sortOrder)
        )
        .get()
        .toPromise();
      return new Promise<any>((resolve, reject) => {
        return first.then(
          (docsnapshot) => {
            const docs: firebase.firestore.QueryDocumentSnapshot<unknown>[] = [];
            let length = 0;
            docsnapshot.docs.map((doc) => {
              if (collection.includes("models")) {
                if (
                  doc.id.toLocaleLowerCase().includes(value.toLocaleLowerCase())
                ) {
                  if (docs.length < body.rows) {
                    docs.push(doc);
                    this.lastVisible = doc;
                  }
                  length++;
                }
              } else {
                const data: any = doc.data();
                const loop = Object.keys(data);
                for (let index = 0; index < loop.length; index++) {
                  const key = loop[index];
                  if (
                    key != "created_date" &&
                    key != "modify_date" &&
                    data[key] !== null
                  ) {
                    const val = data[key];
                    if (
                      val
                        .toString()
                        .toLocaleLowerCase()
                        .includes(value.toLocaleLowerCase())
                    ) {
                      if (docs.length < body.rows) {
                        docs.push(doc);
                        this.lastVisible = doc;
                        length++;
                        break;
                      }
                    }
                  }
                }
              }
            });
            this.size = length;
            resolve({ docs: docs, size: length });
          },
          (err) => reject(err)
        );
      });
    } else {
      const next = this.firebase_dynamic
        .collection(collection, (ref) =>
          ref
            .orderBy(body.sortField, body.sortOrder)
            .startAfter(this.lastVisible)
            .limit(body.rows)
        )
        .get()
        .toPromise();

      return new Promise<any>((resolve, reject) => {
        return next.then(
          (docsnapshot) => {
            const docs: firebase.firestore.QueryDocumentSnapshot<unknown>[] = [];
            docsnapshot.docs.map((doc) => {
              if (collection.includes("models")) {
                if (
                  doc.id.toLocaleLowerCase().includes(value.toLocaleLowerCase())
                ) {
                  if (docs.length < body.rows) {
                    docs.push(doc);
                    this.lastVisible = doc;
                  }
                }
              } else {
                const data: any = doc.data();
                const loop = Object.keys(data);
                for (let index = 0; index < loop.length; index++) {
                  const key = loop[index];
                  if (
                    key != "created_date" &&
                    key != "modify_date" &&
                    data[key] !== null
                  ) {
                    const val = data[key];
                    if (
                      val
                        .toString()
                        .toLocaleLowerCase()
                        .includes(value.toLocaleLowerCase())
                    ) {
                      if (docs.length < body.rows) {
                        docs.push(doc);
                        this.lastVisible = doc;
                        break;
                      }
                    }
                  }
                }
              }
            });
            resolve({ docs: docs, size: this.size });
          },
          (err) => reject(err)
        );
      });
    }
  }

  getCountCollection(path: string) {
    return this.firebase_dynamic
      .collection(path)
      .get()
      .toPromise()
      .then((doc) => {
        return doc.size;
      });
  }

  getCountCollectionWhereField(path: string, body: any) {
    if (typeof body.whereField == "object") {
      return this.firebase_dynamic
        .collection(path, (ref) =>
          ref
            .where(body.whereField[0], "==", body[body.whereField[0]])
            .where(body.whereField[1], "==", body[body.whereField[1]])
            .where(body.whereField[2], "==", body[body.whereField[2]])
            .orderBy(body.sortField, body.sortOrder)
        )
        .get()
        .toPromise()
        .then((doc) => {
          return doc.size;
        });
    } else {
      return this.firebase_dynamic
        .collection(path, (ref) =>
          ref
            .where(body.whereField, "==", body[body.whereField])
            .orderBy(body.sortField, body.sortOrder)
        )
        .get()
        .toPromise()
        .then((doc) => {
          return doc.size;
        });
    }
  }

  getonecollection(collection: string) {
    return new Promise<any>((resolve, reject) => {
      return this.firebase_dynamic
        .collection(collection, (ref) => ref.limit(1))
        .valueChanges()
        .toPromise()
        .then(
          (_) => {
            resolve(_);
          },
          (err) => reject(err)
        );
    });
  }

  //admin
  a_createcollection(data: any, collection: string): any {
    return new Promise<any>((resolve, reject) => {
      return this.firestore
        .collection(collection)
        .add(data)
        .then(
          () => {
            console.log();
          },
          (err) => reject(err)
        );
    });
  }

  a_createcollectionwithdocumentname(
    data: any,
    collection: string,
    docname: string
  ): any {
    return new Promise<any>((resolve, reject) => {
      return this.firestore
        .collection(collection)
        .doc(docname)
        .set(data)
        .then(
          (_) => {
            resolve(_);
          },
          (err) => reject(err)
        );
    });
  }

  a_modifyCollection(data: any, path: string, docId: string) {
    return new Promise<any>((resolve, reject) => {
      return this.firestore
        .collection(path)
        .doc(docId)
        .set(data)
        .then(
          (_) => {
            resolve(_);
          },
          (err) => reject(err)
        );
    });
  }

  a_get_id_collection(collection: string) {
    return new Promise<any>((resolve, reject) => {
      return this.firestore
        .collection(collection)
        .get()
        .toPromise()
        .then(
          (_) => {
            resolve(_);
          },
          (err) => reject(err)
        );
    });
  }

  a_getcollectionsbyid(collection: string, id: string) {
    return new Promise<any>((resolve, reject) => {
      return this.firestore
        .collection(collection)
        .doc(id)
        .get()
        .toPromise()
        .then(
          (_) => {
            resolve(_);
          },
          (err) => reject(err)
        );
    });
  }

  a_getcollectionsbyidpromise(collection: string, id: string): Promise<any> {
    return this.firestore
      .collection(collection)
      .doc(id)
      .valueChanges()
      .pipe(first())
      .toPromise();
  }

  a_getCollections(collection: string, body: any) {
    if (body.first == 0) {
      const first = this.firestore
        .collection(collection, (ref) =>
          ref.orderBy(body.sortField, body.sortOrder).limit(body.rows)
        )
        .get()
        .toPromise();
      first.then((documentSnapshots: any) => {
        this.lastVisible =
          documentSnapshots.docs[documentSnapshots.docs.length - 1];
      });
      return new Promise<any>((resolve, reject) => {
        return first.then(
          (_) => {
            resolve(_);
          },
          (err) => reject(err)
        );
      });
    } else {
      const next = this.firestore
        .collection(collection, (ref) =>
          ref
            .orderBy(body.sortField, body.sortOrder)
            .startAfter(this.lastVisible)
            .limit(body.rows)
        )
        .get()
        .toPromise();
      next.then((documentSnapshots: any) => {
        this.lastVisible =
          documentSnapshots.docs[documentSnapshots.docs.length - 1];
      });
      return new Promise<any>((resolve, reject) => {
        return next.then(
          (_) => {
            resolve(_);
          },
          (err) => reject(err)
        );
      });
    }
  }

  a_getSearchCollectionsByColumn(collection: string, body: any) {
    if (body.first == 0) {
      const first = this.firestore
        .collection(collection, (ref) =>
          ref.orderBy(body.sortField, body.sortOrder)
        )
        .get()
        .toPromise();
      first.then((documentSnapshots: any) => {
        this.lastVisible =
          documentSnapshots.docs[documentSnapshots.docs.length - 1];
      });
      return new Promise<any>((resolve, reject) => {
        return first.then(
          (docsnapshot) => {
            const docs: firebase.firestore.QueryDocumentSnapshot<unknown>[] = [];
            let length = 0;
            let fltr: any = [];
            docsnapshot.docs.map((doc) => {
              fltr = [];
              body.filters.map((filter: any) => {
                if (filter.propertyName == "uid") {
                  if (
                    doc.id
                      .toLocaleLowerCase()
                      .includes(filter.value.toLocaleLowerCase())
                  ) {
                    fltr.push({
                      propertyName: filter.propertyName,
                      value: filter.value,
                      matchMode: filter.matchMode,
                    });
                  }
                } else {
                  const data: any = doc.data();
                  const loop = Object.keys(data);
                  for (let index = 0; index < loop.length; index++) {
                    const key = loop[index];
                    if (
                      key != "created_date" &&
                      key != "modify_date" &&
                      data[key] !== null
                    ) {
                      const val = data[key];
                      if (
                        val
                          .toString()
                          .toLocaleLowerCase()
                          .includes(filter.value.toLocaleLowerCase())
                      ) {
                        fltr.push({
                          propertyName: filter.propertyName,
                          value: filter.value,
                          matchMode: filter.matchMode,
                        });
                      }
                    }
                  }
                }
              });

              const len = body.filters.length;
              let n = 0;
              for (let index = 0; index < len; index++) {
                const element = body.filters[index];
                if (
                  fltr.find((x: any) => x.propertyName == element.propertyName)
                )
                  n += 1;
              }

              if (docs.length < body.rows && n === len) {
                docs.push(doc);
                this.lastVisible = doc;
                length++;
              }
            });
            this.size = length;
            resolve({ docs: docs, size: length });
          },
          (err) => reject(err)
        );
      });
    } else {
      const next = this.firestore
        .collection(collection, (ref) =>
          ref
            .orderBy(body.sortField, body.sortOrder)
            .startAfter(this.lastVisible)
            .limit(body.rows)
        )
        .get()
        .toPromise();

      return new Promise<any>((resolve, reject) => {
        return next.then(
          (docsnapshot) => {
            const docs: firebase.firestore.QueryDocumentSnapshot<unknown>[] = [];
            let fltr: any = [];
            docsnapshot.docs.map((doc) => {
              fltr = [];
              body.filters.map((filter: any) => {
                if (filter.propertyName == "uid") {
                  if (
                    doc.id
                      .toLocaleLowerCase()
                      .includes(filter.value.toLocaleLowerCase())
                  ) {
                    fltr.push({
                      propertyName: filter.propertyName,
                      value: filter.value,
                      matchMode: filter.matchMode,
                    });
                  }
                } else {
                  const data: any = doc.data();
                  const loop = Object.keys(data);
                  for (let index = 0; index < loop.length; index++) {
                    const key = loop[index];
                    if (
                      key != "created_date" &&
                      key != "modify_date" &&
                      data[key] !== null
                    ) {
                      const val = data[key];
                      if (
                        val
                          .toString()
                          .toLocaleLowerCase()
                          .includes(filter.value.toLocaleLowerCase())
                      ) {
                        fltr.push({
                          propertyName: filter.propertyName,
                          value: filter.value,
                          matchMode: filter.matchMode,
                        });
                      }
                    }
                  }
                }
              });
              const len = body.filters.length;
              let n = 0;
              for (let index = 0; index < len; index++) {
                const element = body.filters[index];
                if (
                  fltr.find((x: any) => x.propertyName == element.propertyName)
                )
                  n += 1;
              }

              if (docs.length < body.rows && n === len) {
                docs.push(doc);
                this.lastVisible = doc;
              }
            });
            resolve({ docs: docs, size: this.size });
          },
          (err) => reject(err)
        );
      });
    }
  }

  a_getSearchCollections(collection: string, body: any, value?: any) {
    if (body.first == 0) {
      const first = this.firestore
        .collection(collection, (ref) =>
          ref.orderBy(body.sortField, body.sortOrder)
        )
        .get()
        .toPromise();
      return new Promise<any>((resolve, reject) => {
        return first.then(
          (docsnapshot) => {
            const docs: firebase.firestore.QueryDocumentSnapshot<unknown>[] = [];
            let length = 0;
            docsnapshot.docs.map((doc) => {
              if (collection.includes("models")) {
                if (
                  doc.id.toLocaleLowerCase().includes(value.toLocaleLowerCase())
                ) {
                  if (docs.length < body.rows) {
                    docs.push(doc);
                    this.lastVisible = doc;
                  }
                  length++;
                }
              } else {
                const data: any = doc.data();
                const loop = Object.keys(data);
                for (let index = 0; index < loop.length; index++) {
                  const key = loop[index];
                  if (
                    key != "created_date" &&
                    key != "modify_date" &&
                    data[key] !== null
                  ) {
                    const val = data[key];
                    if (
                      val
                        .toString()
                        .toLocaleLowerCase()
                        .includes(value.toLocaleLowerCase())
                    ) {
                      if (docs.length < body.rows) {
                        docs.push(doc);
                        this.lastVisible = doc;
                        length++;
                        break;
                      }
                    }
                  }
                }
              }
            });
            this.size = length;
            resolve({ docs: docs, size: length });
          },
          (err) => reject(err)
        );
      });
    } else {
      const next = this.firestore
        .collection(collection, (ref) =>
          ref
            .orderBy(body.sortField, body.sortOrder)
            .startAfter(this.lastVisible)
            .limit(body.rows)
        )
        .get()
        .toPromise();

      return new Promise<any>((resolve, reject) => {
        return next.then(
          (docsnapshot) => {
            const docs: firebase.firestore.QueryDocumentSnapshot<unknown>[] = [];
            docsnapshot.docs.map((doc) => {
              if (collection.includes("models")) {
                if (
                  doc.id.toLocaleLowerCase().includes(value.toLocaleLowerCase())
                ) {
                  if (docs.length < body.rows) {
                    docs.push(doc);
                    this.lastVisible = doc;
                  }
                }
              } else {
                const data: any = doc.data();
                const loop = Object.keys(data);
                for (let index = 0; index < loop.length; index++) {
                  const key = loop[index];
                  if (
                    key != "created_date" &&
                    key != "modify_date" &&
                    data[key] !== null
                  ) {
                    const val = data[key];
                    if (
                      val
                        .toString()
                        .toLocaleLowerCase()
                        .includes(value.toLocaleLowerCase())
                    ) {
                      if (docs.length < body.rows) {
                        docs.push(doc);
                        this.lastVisible = doc;
                        break;
                      }
                    }
                  }
                }
              }
            });
            resolve({ docs: docs, size: this.size });
          },
          (err) => reject(err)
        );
      });
    }
  }

  a_getCountCollection(path: string) {
    return this.firestore
      .collection(path)
      .get()
      .toPromise()
      .then((doc) => {
        return doc.size;
      });
  }

  a_getonecollection(collection: string) {
    return new Promise<any>((resolve, reject) => {
      return this.firestore
        .collection(collection, (ref) => ref.limit(1))
        .valueChanges()
        .toPromise()
        .then(
          (_) => {
            resolve(_);
          },
          (err) => reject(err)
        );
    });
  }
}
