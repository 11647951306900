/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Confirmation } from '../../interfaces/confirmation';
import { ConfirmationsService } from '../../services/confirmations.service';
import {ConfirmationService as primengCS} from 'primeng/api';

@Component({
  selector: 'ui-fibr-comfirmation',
  templateUrl: './comfirmation.component.html',
  styleUrls: ['./comfirmation.component.scss']
})
export class ComfirmationComponent implements OnInit {
  @Input() configConfirmation: Confirmation| undefined;
  displayConfirmation: boolean = false;
  isDisplayConfirmation$: any;
  constructor(private confirmationService : ConfirmationsService, private primengCS : primengCS, private cdr: ChangeDetectorRef ) { }
  
  ngOnInit(): void {
    console.log();
      // this.configConfirmation = {
      //   header : 'header',
      //   content: "content",
      //   icon: 'pi pi-exclamation-triangle'
      // };
     
      // this.confirmationService.dataContentValue.subscribe( (val:Confirmation) =>  {
      //   this.dataConfirmation = val;
     
      //   this.confirmationService.displayConfirmationValue.subscribe( (val:Boolean) => {
      //     this.displayConfirmation = val;
      //     if(val){
      //       this.primengCS.confirm({
      //         message: this.dataConfirmation?.content,
      //         header: this.dataConfirmation?.header,
      //         icon: this.dataConfirmation?.icon,
      //         accept: () => {
      //           this.confirmationService.resultValue = true;
      //           this.confirmationService.displayConfirmationValue = false;
      //         },
      //         reject: () => {
      //           this.confirmationService.resultValue = false;
      //           this.confirmationService.displayConfirmationValue = false;
      //         }
      //       });
      //     }
      //     this.cdr.detectChanges();
      //   });
    // });
  }

}
