<p-menubar [model]="items">
    <ng-template pTemplate="start">
        <ui-fibr-brand class="d-flex" [srcImg]="srcImg"></ui-fibr-brand>
    </ng-template>
    <ng-template pTemplate="end">
        <ui-fibr-language *ngIf="isLanguage"></ui-fibr-language>
        <ui-fibr-notifications *ngIf="isNotif"></ui-fibr-notifications>
        <ui-fibr-help *ngIf="isHelp"></ui-fibr-help>
        <ui-fibr-welcome *ngIf="isGreeting"></ui-fibr-welcome>
        <ui-fibr-profile *ngIf="isProfile" [userProfilePath]="userProfilePath" [aksesFrom]="aksesFrom"></ui-fibr-profile>
    </ng-template>
</p-menubar>