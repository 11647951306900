import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { GlobalcrudService, GlobalService } from '@core-fibr/shared';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'ui-fibr-response-midtrans',
  templateUrl: './response-midtrans.component.html',
  styleUrls: ['./response-midtrans.component.scss']
})
export class ResponseMidtransComponent implements OnInit {
  userProfile: any;
  redirectUrl = '/';

  constructor(
    private router: Router,
    private globalService: GlobalService,
    private globalFirebase: GlobalcrudService,
    private route: ActivatedRoute,
    private location: Location,
  ) {
    this.userProfile = this.globalService.getCurrReffDataParsed('user_profile');
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params?.order_id) {
        this.getViewTransaction(params?.order_id);
      }
    });
  }

  getViewTransaction(orderId: string) {
    this.globalFirebase.getonecollectionwithid('/document_callback', orderId)
      .subscribe((next) => {
        const response: any = next.data();
        if (!response) {
          this.redirectUrl = '/portal'
          return
        }
        this.getViewProject(response.project_id);
      })
  }

  getViewProject(projectId: string) {
    const pathProject = `/users/${this.userProfile.uid}/projects`;
    this.globalFirebase.getonecollectionwithid(pathProject, projectId)
      .subscribe((next) => {
        const response: any = next.data()
        if (!response) {
          this.redirectUrl = '/portal'
          return
        }

        this.redirectUrl = '/portal';
        this.router.navigate(["/portal/project/editor"], {
          queryParams: {
            filename: response.file_name,
            fileid: response.file_id,
            appid: response.app_id,
          },
        });
        // this.location.back();
      })
  }

  closeBrowser() {
    // window.open("app-fibr-close", "_self");
    window.close();
  }

  doBeforeUnload() {
    // Alert the user window is closing 
    return false;
  }

}
