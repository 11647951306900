import { Injectable } from '@angular/core';
import { environment } from "@core-fibr/auth";
import { GlobalcrudService } from '@core-fibr/shared';
@Injectable({
  providedIn: 'root'
})
export class PaymentGateway {
  constructor(
    private globalCrudService: GlobalcrudService,
  ) { }

  init(injectScript?: any) {
    this.globalCrudService.getcollectionwithid('config', 'payment_gateway').subscribe((resp: any) => {
      const midtransScriptUrl = environment.midtrans_snap;
      let s = document.createElement("script");
      s.type = "text/javascript";
      s.src = midtransScriptUrl;
      s.setAttribute('data-client-key', resp?.client_key);

      injectScript.nativeElement.appendChild(s);
    })
  }
}