import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {Component,DoCheck,EventEmitter,Input,Output,} from "@angular/core";
import { NgForm } from "@angular/forms";
import { GlobalcrudService, GlobalService, ToastNotificationService } from "@core-fibr/shared";
import { ConfirmationService } from "primeng/api";
import {configMobile,GridModelStyle,GridModelText,layoutDesign,} from "../../../interfaces/global";
import { environment } from "@core-fibr/auth";
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'ui-fibr-mobile-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements DoCheck {
  @Input() config: configMobile = {};
  @Input() data: any[] = [];
  @Input() column: any;
  @Input() dataColumn: any;
  @Input() params: any;
  @Input() actFrom: any
  @Input() itemSelected: any;

  @Output() buttonFloatOnclick = new EventEmitter<any>();
  @Output() buttonDetailOnclick = new EventEmitter<any>();
  @Output() buttonSearchClick = new EventEmitter<any>();

  isHorizontal!: boolean;
  layoutStyle!: GridModelStyle;
  layoutText!: GridModelText;
  pipe = new DatePipe('en-US');
  appConfig:any;
  logo = '';
  elementForm: any[]=[];
  consumentId = '';
  generatedId = '';
  isOrder = false;
  isToCart = false;
  dataHeaderOrder:any;
  dataDetailOrder:any;
  tempDt:any;
  dataMenu:any;
  showDetailOrder = false;
  showSummaryOrder = false;
  fieldDetail: any = [];

  mainTitleType: any;
  descriptionType: any;
  headerType: any;
  labelType:any;
  additionalTextType: any;
  additionalLabelType: any;
  detailType: any;

  mainTitleData: any;
  descriptionData: any;
  headerData: any;
  labelData:any;
  additionalTextData: any;
  additionalLabelData: any;
  detailData: any;
  imageData: any;
  qtyData: any;

  userProject: any;
  sourceItemPrice: any;
  sourceItemName: any ;
  sourceItemPicture: any;
  sourceItemDesc: any;
  totalItem = 0;
  value_1 = 0;
  value_2 = 0;
  grandTotal = 0;
  PriceCalculation:any;
  paymentInfo:any;
  myWindow: any;
  paySuccess = '/assets/images/payment/icon_success.svg';
  payFailed = '/assets/images/payment/icon_failed.svg';
  payExpired = '/assets/images/payment/icon_expired.svg';
  hashData:any = '';
  bucketStatus = false;
  constructor(private httpClient: HttpClient, private globalService: GlobalService,
    private ts: ToastNotificationService, private cs : ConfirmationService, private globalServiceCrud: GlobalcrudService,) 
  { 
    
  }

  ngOnInit(): void {
    if (this.itemSelected == undefined) {
      /**ini untuk handle app preview */
      this.globalService.pageMenuSelected.subscribe((val: any) => this.itemSelected = val);
    }
    for (let i=0; i<this.column.length; i++) {
      if (this.column[i].value == this.config.dataContent?.mainTitle ) this.mainTitleType = this.column[i].type;
      if (this.column[i].value == this.config.dataContent?.description ) this.descriptionType = this.column[i].type;
      if (this.column[i].value == this.config.dataContent?.header ) this.headerType = this.column[i].type;
      if (this.column[i].value == this.config.dataContent?.label ) this.labelType = this.column[i].type;
      if (this.column[i].value == this.config.dataContent?.additionalText ) this.additionalTextType = this.column[i].type;
      if (this.column[i].value == this.config.dataContent?.additionalLabel ) this.additionalLabelType = this.column[i].type;
      if (this.column[i].value == this.config.dataContent?.details ) this.detailType = this.column[i].type;
    }
    this.userProject = this.globalService.getCurrReffDataParsed("user_project");
    // this.infoHeaderGroup = this.column.filter((x:any) => x.value == this.config.dataContent?.pageInfo?.sum?.column);
    this.sourceItemPrice = this.config?.cartConfig?.cartContent?.columns?.itemPrice?.source;
    this.sourceItemName = this.config?.cartConfig?.cartContent?.columns?.itemName?.source;
    this.sourceItemPicture = this.config?.cartConfig?.cartContent?.columns?.itemPicture?.source;
    this.sourceItemDesc = this.config?.cartConfig?.cartContent?.columns?.itemDescription?.source;
    this.PriceCalculation = this.config?.cartConfig?.pricingCalculation;

    this.globalService.appConfig.subscribe((val: any) => this.appConfig = val);
    if (this.appConfig.is_paid && this.appConfig.app_logo != '') this.logo = this.appConfig.app_logo; else this.logo = '/assets/logo_appfibr_vertical.svg';
    this.goToMenu();
    this.dataHeaderOrder = this.globalService.getCurrReffDataParsed("cart_order_"+this.itemSelected?.id);
    if (this.dataHeaderOrder == null) {
      this.globalService.setHeaderCart({act: 'caseCart',info: 'Order',order: this.dataHeaderOrder});
    }
    if (this.dataHeaderOrder?.isToCart && !this.dataHeaderOrder?.isToSummary) {
      this.goToCart();
    }
    if (this.dataHeaderOrder?.isToCart && this.dataHeaderOrder?.isToSummary) {
      this.globalService.setHeaderCart({act: 'caseCart',info: 'Purchase Summary',order: this.dataHeaderOrder});
      this.goToCountItem();
    }
    if (this.dataHeaderOrder != null) {
       this.getDataCart();
    }
  }

  ngDoCheck() {
    const n: any = this.config.layoutDesign?.layoutStyle;
    const m: any = this.config.layoutDesign?.layoutText;
    this.layoutStyle = n;
    this.layoutText = m;
  }

  initAdjustMenu() {
    this.dataMenu = [];
    for (let i=0; i<this.data.length; i++) {
      this.dataMenu.push(this.data[i])
    }

    this.dataDetailOrder = this.globalService.getCurrReffDataParsed("cart_detailorder_"+this.itemSelected?.id);
    if (this.dataDetailOrder == null) {
      for (let i=0; i<this.dataMenu.length; i++) {
        this.dataMenu[i].qty = 0;
        this.dataMenu[i].index = i;
      }
    } else {
      for (let i=0; i<this.dataMenu.length; i++) {
        // const dtLocal = this.dataDetailOrder.find((y:any) => y.index == i)
        const dtLocal = this.dataDetailOrder.find((y:any) => y?.id == this.dataMenu[i]?.id)
        if (dtLocal != undefined) {
          this.dataMenu[i].qty = dtLocal.qty;
          this.dataMenu[i].index = i;
        } else {
          this.dataMenu[i].qty = 0;
          this.dataMenu[i].index = i;
        }
      }
    }
    this.cekItemBucket(this.dataMenu);
  }

  cekItemBucket(dataMenu: any) {
    const cekQty = dataMenu.filter((y:any) => y.qty > 0)
    if (cekQty.length == 0 && this.dataDetailOrder == null) this.bucketStatus = false; else this.bucketStatus = true;
  }

  initCustomerId() {
    this.elementForm = [
      {field: 'customer_identity', label: this.config?.cartConfig?.startOfPage?.userInputLabel, value: this.consumentId, required: true}
    ];
  }

  onClickOrder(f: NgForm) {
    let err = '';
    err = this.validasiOrder(f.value);
    if (err != '') {
      this.ts.showNotification('error',err);
    } else {
      this.setOrder(f.value)
    }
  }

  setOrder(frm?: any) {
    this.refreshData();
    this.globalService.changeBlockui(true);
    const dtId = this.getOrderId();
    const dtOrder: any = {};
    dtOrder.appid = this.params.appid;
    dtOrder.order_id = dtId;
    if (this.config?.cartConfig?.startOfPage?.isLogin)
      dtOrder.customer_identity = '';
    else 
      dtOrder.customer_identity = frm.customer_identity;
    dtOrder.isOrder = true;
    dtOrder.isToCart = false;
    dtOrder.isToSummary = false;
    dtOrder.isToPayment = false;
    dtOrder.page_id = this.itemSelected?.id
    dtOrder.docId = '';
    dtOrder.hAsh = '';
    this.globalService.setRefDataToLocalstorage("cart_order_"+this.itemSelected?.id, dtOrder);
    setTimeout(() => {
      this.goToMenu();
      this.globalService.changeBlockui(false);
    },2000);  
  }

  getOrderId() {
    const orderId: string = window.navigator.userAgent + this.params.appid + this.params.id + this.itemSelected?.id + (new Date().getTime()).toString();
    const sh1OrderId = CryptoJS.SHA1(orderId).toString();
    return sh1OrderId;
  }

  validasiOrder(dtForm:any) {
    let err = ''
    if (dtForm.customer_identity == '') err = this.config?.cartConfig?.startOfPage?.userInputLabel + ' must be filled!';
    return err; 
  }

  goToMenu() {
    this.dataHeaderOrder = this.globalService.getCurrReffDataParsed("cart_order_"+this.itemSelected?.id);
    if (this.dataHeaderOrder == null) {
      if (this.config?.cartConfig?.startOfPage?.isLogin)
        this.setOrder();
      else
        this.initCustomerId();
    } else {
      this.isOrder = this.dataHeaderOrder.isOrder;
      this.globalService.setHeaderCart({act: 'caseCart',info: 'Order',order: this.dataHeaderOrder});
      this.initAdjustMenu();
    }
  }

  onButtonCartClick() {
    const dataLocal = this.globalService.getCurrReffDataParsed("cart_detailorder_"+this.itemSelected?.id);
    const cekQty = this.dataMenu.filter((y:any) => y.qty > 0)
    if (cekQty.length == 0 && dataLocal == null) {
      this.ts.showNotification('error','You have not selected an item!');
      this.bucketStatus = false;
    } else {
      this.globalService.changeBlockui(true);
      const dtToBucket = [];

      for (let i=0; i<this.dataMenu.length; i++) {
        if (this.dataMenu[i].qty > 0) {
          dtToBucket.push(this.dataMenu[i]);
        }
      }
      for (let y=0; y<dtToBucket.length; y++) {
        dtToBucket[y].order_id = this.dataHeaderOrder.order_id;
        dtToBucket[y].customer_identity = this.dataHeaderOrder.customer_identity;
        dtToBucket[y].total_price = dtToBucket[y].qty * dtToBucket[y][this.sourceItemPrice];
      }
      /**data local storage */
      if (dataLocal != null) {
        for (let x=0; x<dataLocal.length; x++) {
          const cekLocal = dtToBucket.filter((dt:any) => dt?.id == dataLocal[x]?.id)
          if (cekLocal.length == 0) dtToBucket.push(dataLocal[x])
        }
      }
      this.globalService.setRefDataToLocalstorage("cart_detailorder_"+this.itemSelected?.id, dtToBucket);
      setTimeout(() => {
        this.globalService.changeBlockui(false);
        this.goToCart();
        this.bucketStatus = true;
      },1000);
    }
  }

  refreshData() {
    this.buttonSearchClick.emit({search: ''});
  }

  goToCart() {
    this.dataHeaderOrder.isToCart = true;
    this.globalService.setRefDataToLocalstorage("cart_order_"+this.itemSelected?.id, this.dataHeaderOrder);
    this.dataDetailOrder = this.globalService.getCurrReffDataParsed("cart_detailorder_"+this.itemSelected?.id);
    this.goToCountItem();
    this.globalService.setHeaderCart({act: 'caseCart',info: 'Cart',order: this.dataHeaderOrder});
  }

  adjustQty(event:any, data:any) {
    // console.log(event,data,this.config)
    if (event == 0) {
      this.cs.confirm({
        message: "Are you sure to remove this item from cart?",
        header: "Confirm Delete Item",
        rejectButtonStyleClass: "p-button-secondary",
        dismissableMask: true,
        accept:  () => {
          // this.dataDetailOrder.splice(data.index, 1);
          this.dataDetailOrder.splice(data?.id, 1);
          this.globalService.setRefDataToLocalstorage("cart_detailorder_"+this.itemSelected?.id, this.dataDetailOrder);
          setTimeout(() => {
            this.goToCountItem();
            this.globalService.changeBlockui(false);
          },1000);
        },
        reject: () => {
          data.qty = 1
          data.total_price = data[this.sourceItemPrice];
          this.adjustQty(1,data)
        }
      });
    } else {
      this.globalService.changeBlockui(true);
      data.total_price = data.qty * data[this.sourceItemPrice];
      for (let y=0; y<this.dataDetailOrder.length; y++) {
        // if(data.index == this.dataDetailOrder[y].index) {
        if(data?.id == this.dataDetailOrder[y]?.id) {
          this.dataDetailOrder[y].total_price = data.total_price;
          this.dataDetailOrder[y].qty = data.qty;
        }
      }
      this.globalService.setRefDataToLocalstorage("cart_detailorder_"+this.itemSelected?.id, this.dataDetailOrder);
      setTimeout(() => {
        this.globalService.changeBlockui(false);
        this.goToCountItem();
      },1000);
    }
  }

  goToCountItem() {
    this.totalItem = 0;
    this.value_1 =0;
    this.value_2 = 0;
    this.grandTotal = 0;
    for (let x=0; x<this.dataDetailOrder.length; x++) {
      this.totalItem = this.totalItem + this.dataDetailOrder[x].total_price
    }
    
    this.value_1 = this.totalItem * (this.PriceCalculation[0].percentage / 100);
    this.value_2 = this.totalItem * (this.PriceCalculation[1].percentage / 100);
    if (this.PriceCalculation[0].calculate == 'increase' && this.PriceCalculation[1].calculate == 'increase') {
      this.grandTotal = this.totalItem + this.value_1 + this.value_2;
    }
    if (this.PriceCalculation[0].calculate == 'decrease' && this.PriceCalculation[1].calculate == 'decrease') {
      this.grandTotal = this.totalItem - this.value_1 - this.value_2;
    }
    if (this.PriceCalculation[0].calculate == 'increase' && this.PriceCalculation[1].calculate == 'decrease') {
      this.grandTotal = (this.totalItem + this.value_1) - this.value_2;
    }
    if (this.PriceCalculation[0].calculate == 'decrease' && this.PriceCalculation[1].calculate == 'increase') {
      this.grandTotal = (this.totalItem + this.value_2) - this.value_1;
    }
  }

  onButtonDetailClick(data: any) {
    this.fieldDetail = Object.keys(data).map(key => ({field: key, value: data[key]}));
    this.showDetailOrder = true;
    // console.log(data,this.fieldDetail, this.config)
    for (let x=0; x<this.fieldDetail.length; x++) {
      if (this.config.dataContent?.mainTitle == this.fieldDetail[x].field) this.mainTitleData = this.fieldDetail[x].value;
      if (this.config.dataContent?.description == this.fieldDetail[x].field) this.descriptionData = this.fieldDetail[x].value;
      if (this.config.dataContent?.header == this.fieldDetail[x].field) this.headerData = this.fieldDetail[x].value;
      if (this.config.dataContent?.label == this.fieldDetail[x].field) this.labelData = this.fieldDetail[x].value;
      if (this.config.dataContent?.additionalText == this.fieldDetail[x].field) this.additionalTextData = this.fieldDetail[x].value;
      if (this.config.dataContent?.additionalLabel == this.fieldDetail[x].field) this.additionalLabelData = this.fieldDetail[x].value;
      if (this.config.dataContent?.details == this.fieldDetail[x].field) this.detailData = this.fieldDetail[x].value;
      if (this.config.dataContent?.images == this.fieldDetail[x].field) this.imageData = this.fieldDetail[x].value;
      if (this.fieldDetail[x].field == 'qty') this.qtyData = this.fieldDetail[x].value
    }
  }

  onButtonDetailCartClick(field:any,qty:number) {
    const index = field.find((y:any) => y.field == 'index')
    this.dataMenu[index.value].qty = qty;
    this.showDetailOrder = false;
  }

  onButtonBackOrderClick() {
    this.refreshData();
    this.dataHeaderOrder.isToCart = false;
    this.globalService.setRefDataToLocalstorage("cart_order_"+this.itemSelected?.id, this.dataHeaderOrder);
    this.initAdjustMenu();
    this.globalService.setHeaderCart({act: 'caseCart',info: 'Order',order: this.dataHeaderOrder});
  }

  onConfirmPurchaseClick() {
    let jmlQty = 0;
    for (let x=0; x<this.dataDetailOrder.length; x++) {
      if (this.dataDetailOrder[x].qty == null) {
        this.dataDetailOrder.splice(x, 1);
        jmlQty = jmlQty + 0;
      } else {
        jmlQty = jmlQty + this.dataDetailOrder[x].qty;
      }
    }
    this.globalService.setRefDataToLocalstorage("cart_detailorder_"+this.itemSelected?.id, this.dataDetailOrder);
    if (jmlQty == 0) {
      this.ts.showNotification('error','no items ordered!');
    } else {
      this.cs.confirm({
        message: "Are you sure to confirm this order?",
        header: "Confirm Order",
        rejectButtonStyleClass: "p-button-secondary",
        dismissableMask: true,
        accept:  () => {
          this.dataDetailOrder = this.globalService.getCurrReffDataParsed("cart_detailorder_"+this.itemSelected?.id);
          const jsonOrder = JSON.stringify(this.dataDetailOrder)
          this.dataHeaderOrder.hAsh = jsonOrder;
          this.dataHeaderOrder.isToSummary = true;
          this.goToCountItem();
          this.globalService.setHeaderCart({act: 'caseCart',info: 'Purchase Summary',order: this.dataHeaderOrder});
          if (this.actFrom == 'pwa') {
            if (this.hashData == '') {
              this.insertToDb()
            } else {
              if (this.dataHeaderOrder.hAsh != this.hashData) {
                // this.dataHeaderOrder.order_id = (new Date().getTime()).toString();
                this.dataHeaderOrder.order_id = this.getOrderId();
                this.insertToDb()
              }
            }
            this.globalService.setRefDataToLocalstorage("cart_order_"+this.itemSelected?.id, this.dataHeaderOrder);
            this.getDataCart()
          }
        },
        reject: () => {
          // 
        }
      });
    }
  }

  onButtonBackPurchaseClick() {
    // this.dataHeaderOrder = this.globalService.getCurrReffDataParsed("cart_order_"+this.itemSelected?.id);
    this.dataHeaderOrder.isToSummary = false;
    this.globalService.setRefDataToLocalstorage("cart_order_"+this.itemSelected?.id, this.dataHeaderOrder);
    this.globalService.setHeaderCart({act: 'caseCart',info: 'Cart',order: this.dataHeaderOrder});
    this.hashData = JSON.stringify(JSON.parse(this.dataHeaderOrder.hAsh))
  }

  insertToDb() {
    // console.log(this.dataDetailOrder)
    const tblName = 'cart_transactions';
    const tablePath = `/users/${this.params.id}/data_projects/${this.params.appid}/tables/${tblName}/data`;
    this.tempDt = [];
    for (let i=0; i<this.dataDetailOrder.length; i++) {
      const temp:any = {};
        temp.order_id = this.dataHeaderOrder.order_id;
        temp.customer_identity = this.dataHeaderOrder.customer_identity;
        temp.item_name = this.dataDetailOrder[i][this.sourceItemName];
        temp.item_price = this.dataDetailOrder[i][this.sourceItemPrice];
        if (this.sourceItemPicture == '') {
          temp.item_picture = '';
        } else {
          temp.item_picture = this.dataDetailOrder[i][this.sourceItemPicture];
        }
        if (this.sourceItemDesc == '') {
          temp.item_description = '';
        } else {
          temp.item_description = this.dataDetailOrder[i][this.sourceItemDesc];
        }
        temp.qty = this.dataDetailOrder[i].qty;
        temp.total_price = this.dataDetailOrder[i].total_price;
        temp.value_1 = this.value_1;
        temp.value_2 = this.value_2;
        temp.modify_date = null;
        if (this.userProject != null || this.userProject != undefined) {
          temp.created_by = this.userProject?.email;
        } else {
          temp.created_by = '';
        }
        temp.modified_by = '';
        this.tempDt.push(temp)
    }

    for (let i=0; i<this.tempDt.length; i++) {
      const body:any = {}
      const mappedForm = Object.keys(this.tempDt[i]).map(key => ({field: key, value: this.tempDt[i][key]}));
      mappedForm.forEach((v: { field: string | number; value: any; }) => body[v?.field] = v?.value);
      this.globalServiceCrud.createDynamicCollectionWithId(body, tablePath)
    }
  }

  goReOrder () {
    this.globalService.changeBlockui(true);
    this.isOrder = false;
    localStorage.removeItem("cart_detailorder_"+this.itemSelected?.id);
    localStorage.removeItem("cart_order_"+this.itemSelected?.id);
    setTimeout(() => {
      this.globalService.changeBlockui(false);
      this.goToMenu();
      this.hashData = '';
      this.globalService.setHeaderCart({act: 'caseCart',info: 'Order',order: this.dataHeaderOrder});
    },1000);
  }

  proceedToPayment(act:any) {
    if (this.actFrom == 'pwa') {
      if (act == 'xendit') this.insertToHeaderDb(act);
      if (act == 'cash') {
        this.cs.confirm({
          message: "You Will make a cash transaction, continue the transaction?",
          header: "Confirm Cash Transaction",
          rejectButtonStyleClass: "p-button-secondary",
          dismissableMask: true,
          accept:  () => {
            this.insertToHeaderDb(act);
          },
          reject: () => {}
        });
      }
    }
    this.globalService.changeBlockui(true);
    setTimeout(() => {
      // this.globalService.setHeaderCart({act: 'caseCart',info: 'Purchase Payment'});
      this.globalService.changeBlockui(false);
    },1000)

  }

  async insertToHeaderDb(act?:any) {
    this.dataHeaderOrder = this.globalService.getCurrReffDataParsed("cart_order_"+this.itemSelected?.id);
    setTimeout(() => {
      /**hit api untuk pop up */
      let customer : any = null
      if (this.userProject != null || this.userProject != undefined) {
        if (this.userProject?.email != 'login_as_guest') {
          customer = {first_name: this.userProject?.fullname,last_name: this.userProject?.fullname,email: this.userProject?.email}
        }
      }
      const bodyCart = {
        user_id: this.params.id,
        project_id: this.params.appid,
        order_id: this.dataHeaderOrder.order_id,
        // doc_id: this.dataHeaderOrder.docId, // dipakai untuk v1, v2 tidak pakai
        page_id: this.dataHeaderOrder.page_id,
        from: "add_to_cart_module",
        amount: this.grandTotal,
        description: this.config.cartConfig?.inAppPaymentConfig?.description,
        customer: customer,
        payment_option: act
      }
      // this.globalService.methodPost(environment.payment + `/v1/invoices`, bodyCart)
      this.globalService.methodPost(environment.payment + `/v2/invoices`, bodyCart)
      .subscribe((resp) => {
        this.globalService.changeBlockui(false);
        if (resp?.code == 200 && resp?.status == 'success') {
          this.dataHeaderOrder.docId = resp.data.doc_id;                                  // setelah v2 naik
          this.globalService.setRefDataToLocalstorage("cart_order_"+this.itemSelected?.id, this.dataHeaderOrder); // setelah v2 naik
          if (act == 'xendit') {
            this.myWindow = this.globalService.windowsOpen(resp.data.invoice_url, '_blank', 500, 500);
          }
        } else {
          this.ts.showNotification('error','Create Invoice Failed!');
        }
        this.getDataCart();
      },
      (err: any) => {
        this.ts.showNotification('error',err?.error?.message);
        this.goReOrder();
      });
    },1000);
  }

  getDataCart() {
    this.dataHeaderOrder = this.globalService.getCurrReffDataParsed("cart_order_"+this.itemSelected?.id);
    if (this.dataHeaderOrder?.docId == '') {
      this.paymentInfo = {is_paid: false,status_payment: 'UNPAID'};
    } else {
      const tablePath = `/users/${this.params.id}/detail_payment_transaction_fibr/${this.params.appid}/data`;
      this.globalServiceCrud.getcollectionwithid(tablePath, this.dataHeaderOrder?.docId).subscribe((res:any) => { 
        if (res == undefined) {
          this.paymentInfo = {is_paid: false,status_payment: 'UNPAID'};
        } else {
          if (res.order_id == this.dataHeaderOrder.order_id) {
            this.paymentInfo = res;
          }
        }
      })
    }
  }

  downloadInvoice() {
    window.open(this.paymentInfo?.payment_invoice);
  }

  checkType(val: any): boolean {
    return typeof(val) === 'object';
  }

}
