/* eslint-disable @typescript-eslint/no-explicit-any */
import { DatePipe, TitleCasePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { QRCodeComponent, QRCodeModule } from 'angularx-qrcode';
import {
  appConfig,
  configMobile,
  ConnectionService,
  dataContent,
  formConfiguration,
  GlobalcrudService,
  GlobalService,
  GridModelStyle,
  GridModelText,
  layoutDesign,
  pageProperty,
  ShowMockupService,
  ToastNotificationService,
} from "@core-fibr/shared";
import { ConfirmationService, MenuItem } from "primeng/api";
import { environment } from "@core-fibr/auth";

@Component({
  selector: "ui-fibr-progresive-web-application",
  templateUrl: "./progresive-web-application.component.html",
  styleUrls: ["./progresive-web-application.component.scss"],
})
export class ProgresiveWebApplicationComponent implements OnInit {
  data: any[] = [];
  dataSummary: any[] = [];
  user: any = {};
  columnData: any[] =[];
  infoSumData: any[] = [];
  infoGroupData: any[] = [];

  urlPathFirebase = "";
  urlDataPathFirebase = "";

  appConfig: appConfig = {};
  configMobile: configMobile = {};
  pageProperty: pageProperty = {};
  layoutDesign!: layoutDesign;
  dataContent: dataContent = {};
  formConfiguration: formConfiguration = {};
  cartConfig: any;
  layoutStyle!: GridModelStyle;
  layoutText!: GridModelText;
  itemPages: MenuItem[] = [];
  paramater: any = {};
  actFrom = "pwa";
  isTemplate = false;
  linkApp!: string ;
  isPaid: boolean | undefined = false;
  isLogin: boolean | undefined = false;
  colTable: any;

  blocked = false;
  pipe = new DatePipe('en-US');
  isExternalDataSource = false;
  showFullScreen = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private globalServiceCrud: GlobalcrudService,
    private showPageDetailService: ShowMockupService,
    private toastNotificationService : ToastNotificationService,
    private connectionService: ConnectionService,
    private titlecase: TitleCasePipe,
    private cs: ConfirmationService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.paramater = params;
      const pathProfile = `users/${params.id}/profile`;
      this.globalServiceCrud.getcollection(pathProfile).subscribe((res) => {
        this.connectionService.swicthFirebase(res[0], params.appid);
      });
      this.globalService.setProjectEditStatus(true);
      this.urlPathFirebase = `/users/${params.id}/projects`;
      this.urlDataPathFirebase = `/users/${params.id}/data_projects`;
      this.globalServiceCrud
        .getcollectionwithid(this.urlPathFirebase, params.appid).subscribe((res: any) => {
        // .getCollectionwithidPromise(this.urlPathFirebase, params.appid).then((res: any) => {
          if (res?.app_id) {
            const appConfig: appConfig = {
              app_id: res.app_id,
              app_name: res.app_name,
              app_icon: res.app_icon,
              file_id: res.file_id,
              file_name: res.file_name,
              version: res.version,
              build_number: res.build_number,
              is_suspend: res.is_suspend,
              is_publish: res.is_publish,
              config: res.config,
              app_desc: res.app_desc,
              package_name: res.package_name,
              app_type: res.app_type,
              publish_option: res.publish_option,
              app_logo: res.app_logo,
              primary_color: res.primary_color,
              secondary_color: res.secondary_color,
              font_color: res.font_color,
              splash_screen: res.splash_screen,
              login_registration: res.login_registration,
              updated_date: res.updated_date,
              broadcast_content: res.broadcast_content,
              broadcast_title: res.broadcast_title,
              is_paid: res?.is_paid,
              max_rows: res?.max_rows,
              max_tables: res?.max_tables,
              max_pages: res?.max_pages,
              is_login: res?.is_login,
              is_publish_pwa: res?.is_publish_pwa,
              publish_pwa_date: res?.publish_pwa_date,
              upgrade_pro_date: res?.upgrade_pro_date,
              is_role: res?.is_role,
              status_project: res?.status_project,
              is_registration: res?.is_registration,
              paymentConfig: res?.paymentConfig,
              is_login_guest: res?.is_login_guest,
              users_config: res?.users_config,
              max_storage: res?.max_storage,
              max_upload: res?.max_upload,
              roles_config: res?.roles_config
            };

            this.appConfig = appConfig;
            this.globalService.setAppConfig(appConfig);
            this.linkApp = environment.domain + `/app-fibr?appid=${res.app_id}&id=${params.id}`;
            if (res.config == "") {
              console.log();
            } else {
              const con: any = JSON.parse(res.config);
              this.itemPages = con.itemPages ?? {};
              this.addCommandForModifyItemPages();
            }
            this.showPageDetailService.showAppConfig(res);
            this.globalService.addConfigRunning(this.appConfig, environment);
            this.isPaid = this.appConfig.is_paid;
            this.isLogin = this.appConfig.is_login;
          }
          if (res?.paymentConfig?.on_review) {
            this.router.navigate(["app-fibr-unwearable"], {
              queryParams: {
                id: params.id,
                appid: params.appid,
              },
            });
          }
        });
        // buat methode post ke api HOST/api/v1/fibr-app/traffic-apps
        this.addTrafficData(params);
    });
  }

  ngOnInit(): void {
    this.globalService.isBlocked.subscribe(
      (val: boolean) => (this.blocked = val)
    );
    this.globalService.isStatusFullScreen.subscribe((val:boolean) => {
      this.showFullScreen = val
    })
  }

  initPageProperty() {
    this.pageProperty = {
      pageId: "",
      pageTitle: "",
      pageIcon: "",
      pagePadding: "",
      pageImagePosition: "",
      isMenu: false,
    };
  }

  initLayoutDesign() {
    this.layoutDesign = {
      pageId: "",
      layoutTemplate: "",
      layoutStyle: "card",
      layoutText: "",
      layoutPadding: "",
      layoutImagePosition: "left",
    };

    this.layoutStyle = {
      orientation: "vertical",
      column: "1",
      shape: "sq",
      border: "float",
      corners: "sharp",
      padding: "loose",
    };

    this.layoutText = {
      position: "below",
      fontsize: "small",
      aligment: "left",
      lettercase: "titlecase",
    };
  }

  initDataContent() {
    this.dataContent = {
      pageId: "",
      contentDataSource: "",
      contentPadding: "",
      contentImagePosition: "",
      contentTemplate: {},
      mainTitle: "",
      description: "",
      images: undefined,
      linkToDetails: "default",
      sectionDetails: [],
    };
  }

  initForm() {
    this.formConfiguration = {
      isEnable: false,
      add: false,
      edit: false,
      delete: false,
      formContent: [],
    };
  }

  initConfigMobile() {
    this.configMobile = {
      pageProperty: this.pageProperty,
      layoutDesign: this.layoutDesign,
      dataContent: this.dataContent,
      formConfiguration: this.formConfiguration,
    };
  }

  addCommandForModifyItemPages() {
    this.itemPages.forEach((element) => {
      if (element.id == "_" || element.id == "") {
        console.log();
      } else {
        element["command"] = (event) => {
          this.modifyPageMenu(event);
        };
      }
    });
  }

  modifyPageMenu($event: any) {
    // console.log($event)
    let addFilter = {field: '', value: ''}
    if ($event?.params != undefined) {
      if ($event.from == 'link_detail') {
        const dtValue = $event.data[$event.params.dataContent.linkToDetailsConfig.relationField];
        const dtField = $event.params.dataContent.linkToDetailsConfig.foreignKey;
        addFilter = {field: dtField, value: dtValue}
      }
      if ($event.from == 'button_page') {
        const dtValue = $event.data[$event.params.relationField];
        const dtField = $event.params.foreignKey;
        addFilter = {field: dtField, value: dtValue}
      }
    }
    this.globalService.changeBlockui(true);
    const items = $event.item;

    this.initPageProperty();
    this.initLayoutDesign();
    this.initDataContent();
    this.initForm();
    this.initConfigMobile();

    this.layoutDesign = items.state.layoutDesign;
    if (items.state.dataContent.contentDataSource != "") {
      // this.dataSourceChange({value: items.state.dataContent.contentDataSource,});
      this.isExternalDataSource = items.state?.dataContent?.isExternalDataSource;
      this.dataSourceChange({value: items.state, pwa: $event.bodyPwa, addFilter: addFilter});
      setTimeout(() => {this.getColumnData(items.state)},500);
    }

    setTimeout(() => {
      this.formConfiguration.add = items.state.formConfiguration.add;
      this.formConfiguration.delete = items.state.formConfiguration.delete;
      this.formConfiguration.edit = items.state.formConfiguration.edit;

      this.pageProperty = items.state.pageProperty;
      this.layoutDesign = items.state.layoutDesign;
      this.dataContent = items.state.dataContent;
      this.formConfiguration = items.state.formConfiguration;
      this.cartConfig = items.state.cartConfig;

      this.configMobile = {
        pageProperty: this.pageProperty,
        layoutDesign: this.layoutDesign,
        dataContent: this.dataContent,
        formConfiguration: this.formConfiguration,
        cartConfig: this.cartConfig
      };

      if (this.layoutDesign.layoutTemplate == "grid") {
        const layoutStyle: any = this.layoutDesign.layoutStyle;
        const layoutText: any = this.layoutDesign.layoutText;
        this.layoutStyle = layoutStyle;
        this.layoutText = layoutText;
      }
      this.globalService.changeBlockui(false);
    }, 2000);
  }

  dataSourceChange(event: any) {
    const userProject = this.globalService.getCurrReffDataParsed("user_project");
    let url = ''
    if (this.isExternalDataSource) {
      url = this.globalService.getPathDataExternalSource(event?.value?.dataContent?.contentDataSource, this.paramater);
    } else {
      url = this.urlDataPathFirebase + `/${this.appConfig.app_id}/tables/${event.value.dataContent.contentDataSource}/data`;
    }
    
    if (event.value.layoutDesign.layoutTemplate == 'grid' || event.value.layoutDesign.layoutTemplate == 'list'
    || event.value.layoutDesign.layoutTemplate == 'cart') {
      let filterCol = '';
      let sortCol = '';
      if (!event.value.dataContent?.filter) filterCol = ''; else filterCol = event.value.dataContent?.filter[0]?.column;
      if (!event.value.dataContent?.sort) sortCol = ''; else sortCol = event.value.dataContent?.sort[0]?.column;
      
      if (filterCol == '' && sortCol == '') {
        this.data = [];
        let body: any = {};
        body = this.bodyDataNoFilterNoSort(event)
        if (event.addFilter.field == '') {
          // this.showData(url,body, event.value);
          /** test with lazy load no body */
          if (body.filters.length > 0) {
            this.showData(url,body, event.value);
          } else {
            if (this.appConfig.is_login && 
              (event.value.dataContent?.accessible?.type == 'user_created' || event.value.dataContent?.accessible?.type == 'specific_user')) {
              this.showData(url,body, event.value);
            } else {
              this.getDataLazy(url,body);
            }
          }        
        } else {
          this.showData(url,body, event.value, event.addFilter);
        }
      } else {
        this.data = [];
        let body: any = {};
          body = this.bodyData(event);
          if (filterCol != '' && sortCol != '')
            this.showData(url,body, event.value, event.addFilter);
          if (filterCol != '' && sortCol == '')
            this.showData(url,body, event.value, event.addFilter);
          if (filterCol == '' && sortCol != '') 
            this.showData(url,body, event.value, event.addFilter);
      }
    }
    if (event.value.layoutDesign.layoutTemplate == 'summary') {
      this.data = [];
        let body: any = {};
        body = this.bodyDataSummary(event)
        if (this.isExternalDataSource) {
          this.globalServiceCrud.getColectionWithBody(url,body).subscribe((result: any) => {
            if (result != null) {
              this.data = result;
            }
          });
          this.globalServiceCrud.getColectionWithBody(url,body).subscribe((result: any) => {
            if (result != null) {
              this.dataSummary = result;
            }
          });
        } else {
          this.globalServiceCrud.dynamicColectionWithBody(url,body).subscribe((result: any) => {
            if (result != null) {
              this.data = result;
            }
          });
          this.globalServiceCrud.dynamicColectionWithBody(url,body).subscribe((result: any) => {
            if (result != null) {
              this.dataSummary = result;
            }
          });
        }
    }
    if (event.value.layoutDesign.layoutTemplate == 'details') {
      // 
    }
  }

  async getColumnData(e: any) {
    this.columnData = [];
    let tableName = '';
    if (this.isExternalDataSource) {
      tableName = e.dataContent.contentDataSource + '_struct'; 
    } else {
      tableName = e.dataContent.contentDataSource;
    }

    let urlColumn = '';
    if (this.isTemplate) {
      urlColumn = `/templates/${this.paramater.appid}/tables`
    } else {
      if (this.isExternalDataSource) {
        urlColumn = this.globalService.getPathColumnExternalSource(e?.dataContent?.contentDataSource,this.paramater.id)
      } else {
        urlColumn = `/users/${this.paramater.id}/data_projects/${this.paramater.appid}/tables`;
      }
    }

    if (this.isExternalDataSource) {
      this.columnData = await this.globalServiceCrud.getCollectionwithidPromise(urlColumn, tableName)
      .then((res) => { 
        return res?.columns 
      });
    } else {
      this.columnData = await this.globalServiceCrud.getDynamicCollectionwithidPromise(urlColumn, tableName)
      .then((res) => { 
        return res?.columns 
      });
    }
  }

  showData(url: any, body: any, config: any, addFilter?: any){
    // console.log(addFilter,addFilter?.field)
    if (addFilter == undefined || addFilter?.field == '') {
      if (!this.appConfig.is_login) {
        this.getAllData(url,body);
      } else {
        const userProject = this.globalService.getCurrReffDataParsed("user_project");
        if (config.dataContent?.accessible?.type == 'all_users') {
          this.getAllData(url,body);
        }
        if (config.dataContent?.accessible?.type == 'user_created') {
          if (userProject?.role == 'fibr_creator') {
            this.getAllData(url,body);
          } else {
            this.globalServiceCrud.dynamicColectionSeacrh(url,body,this.isExternalDataSource)?.then((result:any) => {
              if (result.docs != null) {
                this.data = [];
                result.docs.forEach((doc: any) => {
                  const rowdata: any = doc.data();
                  if (rowdata?.created_by == userProject.email) {
                    rowdata.id = doc.id;
                    this.data.push(rowdata);
                  }
                });
                this.globalService.convertDataApp(this.data)
              }
            });
          }
        }
        if (config.dataContent?.accessible?.type == 'specific_user') {
          if (userProject?.role == 'fibr_creator') {
            this.getAllData(url,body);
          } else {
            this.globalServiceCrud.dynamicColectionSeacrh(url,body,this.isExternalDataSource)?.then((result:any) => {
              if (result.docs != null) {
                this.data = [];
                result.docs.forEach((doc: any) => {
                  const rowdata: any = doc.data();
                  // console.log(rowdata, rowdata[config.dataContent?.accessible?.specificUserColumn])
                  if (rowdata[config.dataContent?.accessible?.specificUserColumn] == userProject.email) {
                    rowdata.id = doc.id;
                    this.data.push(rowdata);
                  }
                });
                this.globalService.convertDataApp(this.data)
              }
            });
          }
        }
      }
    } else {
      this.getDataWithAddFilter(url,body,config,addFilter);
    }
  }

  getAllData(url:any, body:any) {
    this.globalServiceCrud.dynamicColectionSeacrh(url,body,this.isExternalDataSource)?.then((result:any) => {
      if (result.docs != null) {
        this.data = [];
        result.docs.forEach((doc: any) => {
          this.pushData(doc);
        });
        this.globalService.convertDataApp(this.data)
      }
    });
  }

  getDataLazy(url:any, body:any) {
    this.globalServiceCrud.getDynamicCollectionLazy(url,body,this.isExternalDataSource)?.then((result:any) => {
      if (result.docs != null) {
        this.data = [];
        result.docs.forEach((doc: any) => {
          this.pushData(doc);
        });
        this.globalService.convertDataApp(this.data)
        // console.log(this.data)
      }
    }); 
  }

  getDataWithAddFilter(url:any, body:any, config:any, addFilter: any) {
    this.globalServiceCrud.dynamicColectionSeacrh(url,body,this.isExternalDataSource)?.then((result:any) => {
      if (result.docs != null) {
        this.data = [];
        if (!this.appConfig.is_login) {
          result.docs.forEach((doc: any) => {
            const rowdata: any = doc.data();
            if (rowdata[addFilter?.field] == addFilter.value) {
              rowdata.id = doc.id;
              this.data.push(rowdata);
            }
          });
        } else {
          const userProject = this.globalService.getCurrReffDataParsed("user_project");
          if (config.dataContent?.accessible?.type == 'all_users') {
            result.docs.forEach((doc: any) => {
              const rowdata: any = doc.data();
              if (rowdata[addFilter?.field] == addFilter.value) {
                rowdata.id = doc.id;
                this.data.push(rowdata);
              }
            });
          }
          if (config.dataContent?.accessible?.type == 'user_created') {
            if (userProject?.role == 'fibr_creator') {
              result.docs.forEach((doc: any) => {
                const rowdata: any = doc.data();
                if (rowdata[addFilter?.field] == addFilter.value) {
                  rowdata.id = doc.id;
                  this.data.push(rowdata);
                }
              });
            } else {
              result.docs.forEach((doc: any) => {
                const rowdata: any = doc.data();
                if (rowdata?.created_by == userProject.email && rowdata[addFilter?.field] == addFilter.value) {
                  rowdata.id = doc.id;
                  this.data.push(rowdata);
                }
              });
            }
          }
          if (config.dataContent?.accessible?.type == 'specific_user') {
            if (userProject?.role == 'fibr_creator') {
              result.docs.forEach((doc: any) => {
                const rowdata: any = doc.data();
                if (rowdata[addFilter?.field] == addFilter.value) {
                  rowdata.id = doc.id;
                  this.data.push(rowdata);
                }
              });
            } else {
              result.docs.forEach((doc: any) => {
                const rowdata: any = doc.data();
                if (rowdata[config.dataContent?.accessible?.specificUserColumn] == userProject.email && rowdata[addFilter?.field] == addFilter.value) {
                  rowdata.id = doc.id;
                  this.data.push(rowdata);
                }
              });
            }
          }
        }
        this.globalService.convertDataApp(this.data)
      }
    });
  }

  showDataSort(url: any, body: any, config: any){
    if (config.pageProperty?.isShowAllData || !this.appConfig.is_login) {
      this.globalServiceCrud.dynamicColectionSort(url,body)?.then((result:any) => {
        if (result.docs != null) {
          this.data = [];
          // result.docs.forEach((doc: any) => {
          //   this.pushData(doc);
          // });
          this.data = this.globalService.makeData(result);
          this.globalService.convertDataApp(this.data)
        }
      });
    } else {
      const userProject = this.globalService.getCurrReffDataParsed("user_project");
      if (userProject.level == 'admin') {
        this.globalServiceCrud.dynamicColectionSort(url,body)?.then((result:any) => {
          if (result.docs != null) {
            this.data = [];
            this.data = this.globalService.makeData(result);
            this.globalService.convertDataApp(this.data)
          }
        });
      } else {
        this.globalServiceCrud.dynamicColectionSortWithUser(url,body,userProject.email)?.then((result:any) => {
          if (result.docs != null) {
            this.data = [];
            this.data = this.globalService.makeData(result);
            this.globalService.convertDataApp(this.data)
          }
        });
      }
    }
  }

  pushData(doc: any) {
    const rowdata = doc.data();
    rowdata.id = doc.id;
    this.data.push(rowdata);
  }

  bodyData(event: any) {
    const body: any = {};
    body.first = 0;
    body.rows = 10;
    body.filters = [];
    if (event.pwa != undefined) body.reqFrom = 'pwa'; else body.reqFrom = 'config';
    // if (!event.value.dataContent?.sort || event.value.dataContent?.sort[0].column == '') {
    //   body.sortField = 'created_date';
    //   body.sortOrder ='desc';
    // } else {
      if (event.pwa != undefined) {
        if (event.value.dataContent?.sort[0].column == event.pwa.sortField) {
          // console.log('kesini e')
          body.sortField = event.value.dataContent?.sort[0].column;
          body.sortOrder = event.value.dataContent?.sort[0].sortType;
        } else {
          // console.log('kesini b')
          body.sortField = event.pwa.sortField;
          body.sortOrder = event.pwa.sortOrder;
        }
      } else {
        if (!event.value.dataContent?.sort || event.value.dataContent?.sort[0].column == '') {
          body.sortField = 'created_date';
          body.sortOrder ='desc';
        } else {
          body.sortField = event.value.dataContent?.sort[0].column;
          body.sortOrder = event.value.dataContent?.sort[0].sortType;
        }
      }
    // }
    const column = event.value.dataContent?.filter[0].column;
    const valColumn = event.value.dataContent?.filter[0].value;
    const colType = event.value.dataContent?.filter[0].filterType;
    const filterDate = event.value.dataContent?.filter[0].filterDateType;
    if (event.pwa != undefined) {
      if (event.pwa.filters.length == 0) {
        body.reqFrom = 'config'
        body.filters.push({propertyName: column, value: valColumn, matchMode: 'equals', columnType: colType, reqFrom: 'config', filterDate: filterDate});
        // console.log('kesini c', body)
      } else {
        const cekValue = event.pwa.filters.filter((x:any) => x.value != "");
        // console.log(cekValue)
        if (cekValue.length == 0) {
          body.reqFrom = 'config'
          body.filters.push({propertyName: column, value: valColumn, matchMode: 'equals', columnType: colType, reqFrom: 'config', filterDate: filterDate});
          // console.log('kesini a', body)
        } else {
          body.reqFrom = 'pwa'
          for (let i = 0; i < event.pwa.filters.length; i++) {
            if (column == event.pwa.filters[i].propertyName) {
              if (colType != 'date' || colType != 'time') {
                body.filters.push({propertyName: column, value: valColumn, matchMode: 'equals', columnType: '', reqFrom: 'pwa', filterDate: filterDate});
              } else {
                body.filters.push({propertyName: event.pwa.filters[i].propertyName, value: event.pwa.filters[i].value, matchMode: 'equals', 
                columnType: '', reqFrom: 'pwa', filterDate: filterDate});
              }
            } else {
              body.filters.push({propertyName: event.pwa.filters[i].propertyName, value: event.pwa.filters[i].value, matchMode: 'equals', 
                columnType: '', reqFrom: 'pwa', filterDate: filterDate});
            }
          }
        }
        // console.log('kesini b', body)
      }
    } else {
      body.reqFrom = 'config'
      body.filters.push({propertyName: column, value: valColumn, matchMode: 'equals', columnType: colType, reqFrom: 'config', filterDate: filterDate});
      // console.log('kesini h', body)
    }
    return body;
  }

  bodyDataNoFilterNoSort(event: any) {
    const body: any = {};
    body.first = 0;
    body.rows = 10;
    body.filters = [];
    if (event.pwa != undefined) {
      if (event.pwa?.sortField != '') body.sortField = event.pwa?.sortField; else body.sortField = 'created_date';
      if (event.pwa?.sortOrder != '') body.sortOrder = event.pwa?.sortOrder; else body.sortOrder = 'desc';
      for (let i = 0; i < event.pwa?.filters.length; i++) {
        if (event.pwa.filters[i].value != '' && event.pwa.filters[i].value != undefined)
          body.filters.push({propertyName: event.pwa.filters[i].propertyName, value: event.pwa.filters[i].value, matchMode: 'equals'});
      }
    } else {
      body.sortField = 'created_date';
      body.sortOrder ='desc';
    }
    return body;
  }

  bodyDataSummary(event: any) {
    const body: any = {};
    body.first = 0;
    body.rows = 10;
    body.filters = [];
    if (!event.value.layoutDesign?.summaryType || event.value.layoutDesign?.summaryType == 'data') {
      if (event.value.dataContent?.configSummaryTable?.content?.group?.column == undefined || 
        event.value.dataContent?.configSummaryTable?.content?.group?.column == '') {
        body.sortField = 'created_date';
        body.sortOrder ='desc';
      } else {
        body.sortField = event.value.dataContent?.configSummaryTable?.content?.group?.column;
        body.sortOrder = event.value.dataContent?.configSummaryTable?.content?.group?.sortType;
      }
    } else {
      body.sortField = 'created_date';
      body.sortOrder ='desc';
    }
    return body;
  }

  convertDataWithField(urlColumn: any, event: any) {
    this.globalServiceCrud.getDynamicCollectionwithidPromise(urlColumn, event.value.dataContent.contentDataSource)
    .then((response) => { 
          const fields: any = [];
          response?.columns.forEach( (col: any) => {
              fields.push({ value: col?.field, type: col?.type });
          }); 
          this.data = this.globalService.convertData(this.data, fields);
    });
  }

  createBodyTraffic(params: any){
  const body:any = {
    userId: params.id,
    projectId: params.appid,
    type: 'pwa'
  }
  return body;
}

  async addTrafficData(params: any) {
    const body = this.createBodyTraffic(params);
    await this.globalService.methodPost(environment.be_dev + `/api/v1/fibr-app/traffic-apps`,body
    ).subscribe((res) => {
      // console.log(res, new Date()) 
    },
    (err: any) => {
      // 
    });
  }

  copyLinkClick(event: any){
    // this.globalService.appConfig.subscribe((val: appConfig) => this.appConfig = val);
    // this.linkApp = `https://dev-fibr.web.app/app-fibr?appid=${this.appConfig.app_id}`;
    // window.open(this.linkApp); event click buka tab url baru
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = event;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    if (event) {
      this.toastNotificationService.showNotification('success', 'link copied successfully');
    } else {
      this.toastNotificationService.showNotification('error', 'link failed to copy');
    }
  }

  actShowFullScreen(act:any) {
    if (act.fullScreen == true) {
      this.cs.confirm({
        message: 'Enabling full screen mode may cause display issues. Certain elements may not appear correctly or may temporarily disappear.',
        header: 'Full Screen Mode',
        rejectButtonStyleClass: "p-button-secondary",
        dismissableMask: true,
        accept: () => {
          this.showFullScreen = true
          this.globalService.setShowFullscreen(true)
        },
        reject: () => {}
      });
    } else {
      this.showFullScreen = false
      this.globalService.setShowFullscreen(false)
    }
  }
}
