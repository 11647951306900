<div class="p-toolbar-group-right" *ngIf="!isApptEdit && isCreateNew" >
    <p-button *ngIf="!hideButtonCreate" label="Create New" icon="pi pi-plus" iconPos="right" (onClick)="createProject()"> </p-button>
    <div class="grid" *ngIf="dataSource?.data.length > 0">
        <!-- <label for="{{dataSource.label}}" class="col-12"  i18n>{{dataSource.label}}</label> -->
        <!-- <p-button styleClass="p-button-rounded mt-1 p-button-primary p-button-text p-button-icon-only" label="" icon="pi pi-plus" iconPos="right" (onClick)="createNew()"> </p-button> -->
        <!-- <p-dropdown styleClass="col-12 mt-2" [options]="dataSource.data" [(ngModel)]="selectedDataSource" optionLabel="name" optionValue="value" (onChange)="onChange()"></p-dropdown>  -->
    </div>
</div>

<div id="default-toolbar" class="p-toolbar-group-right" *ngIf="isApptEdit">
    <!-- <p-button (onClick)="onDelete()" styleClass="p-button p-button-secondary2 text-magenta mr-2" label="" icon="pi pi-trash" iconPos="right" pTooltip="Delete Project" tooltipPosition="bottom"> </p-button> -->
    <p-button (onClick)="onProperties()" styleClass="p-button p-button-secondary2 mr-2 seventh-element"  label="" icon="pi pi-cog" iconPos="right" pTooltip="{{'project.properties.title' | translate}}" tooltipPosition="bottom"> </p-button>
    <p-button *ngIf="!loading" (onClick)="showMockup=false;onShowMockup()" styleClass="p-button p-button-secondary2 mr-2 px-5 eighth-element" label="{{'button.publish' | translate}}" icon="pi pi-cloud-download" iconPos="right" [disabled]="appConfig.is_suspend ?? false"> </p-button>
    <p-button *ngIf="loading" styleClass="p-button p-button-secondary2 mr-2 px-5 eighth-element" label="{{'button.publish' | translate}}" icon="pi pi-spin pi-spinner" iconPos="right" [disabled]="true"> </p-button>
    <p-button *ngIf="!buttonSave" (onClick)="onSave()" styleClass="mr-2 px-5" label="{{'button.save' | translate}}" icon="pi pi-save" iconPos="right"> </p-button>
</div>

<p-button id="dropdown-toolbar" *ngIf="isApptEdit && !appConfig.is_paid" (click)="more.toggle($event)" styleClass="p-button p-button-secondary2" label="" icon="pi pi-ellipsis-v" iconPos="right" style="display:none;"> </p-button>
<p-menu #more  appendTo="body"  [popup]="true" [model]="items"></p-menu>

<p-button id="dropdown-toolbar" *ngIf="isApptEdit && appConfig.is_paid" (click)="more2.toggle($event)" styleClass="p-button p-button-secondary2" label="" icon="pi pi-ellipsis-v" iconPos="right" style="display:none;"> </p-button>
<p-menu #more2  appendTo="body" [popup]="true" [model]="itemsPro"></p-menu>

<p-dialog [(visible)]="showMockup" [modal]="true" [blockScroll]="true" [draggable]="false" [dismissableMask]="true" (onHide)="onHideDialogPublish()" position="top" appendTo="body" styleClass="dialog-no-footer" [style]="{width: '500px'}">
    <ng-template pTemplate = "header">
        <span class="p-dialog-title"><i class="pi pi-cloud-download"></i>{{'publish.title' | translate}}</span>
    </ng-template>
    <p-tabView styleClass="tabview-standard" [(activeIndex)]="dialogPublishTabIndex" (onChange)="handleTabDialogPublishChange($event)">
        <p-tabPanel header="Web App">
            <ng-template pTemplate = "header">
                <i class="pi pi-globe mr-2"></i>
                <span>Web App</span>
            </ng-template>
            <div class="grid">
                <!-- <div class="col-12 text-center">
                    <p>Share your app to public with link</p>
                    <div class="p-inputgroup mt-2">
                        <input type="text" class="mr-2 rounded" pInputText [disabled]="disabled" [(ngModel)]="linkApp" value="appfibr.io/your-project-name" />
                        <button (click)="onCopyLink()" pButton type="button" label="Copy Link" icon="pi pi-link" iconPos="right" class="p-button p-component rounded"></button>
                    </div>
                </div>
                <div class="col-12 text-center">
                    <div class="p-divider p-component p-divider-horizontal">
                        <div class="p-divider-content">Direct Open Link</div>
                    </div>
                    <button (click)="onOpenLink()" pButton type="button" label="Open Web App" icon="pi pi-globe" iconPos="right" class="p-button p-component d-flex m-auto"></button>
                </div>
                <div class="col">
                    <div class="p-divider p-component p-divider-horizontal">
                      <div class="p-divider-content">or QR Code</div>
                    </div>
                </div>
                <div class="col-12 text-center">
                    <div class="card box inline-flex p-2 mb-3">
                        <qrcode #parent [qrdata]="linkApp" [width]="300" [errorCorrectionLevel]="'M'" [elementType]="'img'" [allowEmptyString]="true"></qrcode>
                    </div>
                    <button (click)="onDownloadQR(parent)" pButton type="button" label="Download QR Code" icon="pi pi-qrcode" iconPos="right" class="p-button p-component d-flex m-auto"></button>
                </div> -->

                <div class="col-12">
                  <p class="text-center">{{'publish.share' | translate}}</p>
                  <div class="card box d-flex">
                    <img [src]="logoApp" class="card box inline-flex border-0 shadow mr-3 mb-0" width="100" height="100">
                    <div class="d-block">
                        <h5 class="d-flex mb-2"><span class="p-tag p-component ml-2"><span class="p-tag-value">pwa</span></span></h5>
                        <h4 class="m-0 mb-2">{{appConfig.app_name}}</h4>
                        <p>{{appConfig.app_desc}}</p>
                    </div>
                  </div>
                  <p-divider></p-divider>
                  <div class="grid">
                        <div class="col-12">
                            <button (click)="onPublishPwa()" *ngIf="!appConfig.is_publish_pwa" pButton type="button" label="{{'publish.web_app' | translate}}" icon="pi pi-globe" iconPos="right" class="p-button p-component d-flex m-auto"></button>
                        </div>
                        <div class="col-6">
                          <button (click)="onCopyLink()" *ngIf="appConfig.is_publish_pwa" pButton type="button" label="{{'publish.copy' | translate}}" icon="pi pi-link" iconPos="right" class="p-button p-component d-flex m-auto"></button>
                        </div>
                        <div class="col-6">
                          <button (click)="onPublishPwa()" *ngIf="appConfig.is_publish_pwa" pButton type="button" label="{{'publish.open' | translate}}" icon="pi pi-globe" iconPos="right" class="p-button p-component d-flex m-auto"></button>
                        </div>
                  </div>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Android App">
            <ng-template pTemplate = "header">
                <i class="pi pi-android mr-2"></i>
                <span>Android App</span>
            </ng-template>
            <div class="grid">
                <div class="col-12">
                    <p class="text-center">{{'publish.share_android' | translate}}</p>
                    <div class="card box d-flex">
                        <img [src]="logoApp" class="card box inline-flex border-0 shadow mr-3 mb-0" width="100" height="100">
                        <div class="d-block">
                            <h5 class="d-flex mb-2">{{appConfig.version}} <span class="p-tag p-component ml-2"><span class="p-tag-value">.apk</span></span></h5>
                            <h4 class="m-0 mb-2">{{appConfig.app_name}}</h4>
                            <p>{{appConfig.app_desc}}</p>
                        </div>
                    </div>
                    <p-divider></p-divider>
                    <div class="grid">
                      <div class="col-12">
                          <div class="grid mb-3">
                              <div class="col-12">
                                  <p class="text-softgrey label-info">{{'publish.form.package_name' | translate}} <i class="pi pi-info-circle" pTooltip="Uniquely identifies your app on the device, in Google Play Store, and in supported third-party Android stores"></i></p>
                                  <div class="p-inputgroup">
                                    <input type="text" pInputText [(ngModel)]="appConfig.package_name" (blur)="onBlur()" [disabled]="disabledPackage" class="mr-2 rounded" placeholder="com.example.myapp" value="" />
                                      <!-- <p-inputMask [(ngModel)]="appConfig.package_name" (blur)="onBlur()" [disabled]="disabledPackage" class="mr-2 rounded" placeholder="com.example.myapp" value="" mask="a*****.*****.*****"></p-inputMask> -->
                                      <button pButton type="button" (click)="checkPackageClick()" label="{{'publish.form.check_package' | translate}}" icon="pi pi-search" iconPos="right" class="p-button p-component rounded"></button>
                                  </div>
                              </div>
                          </div>
                          <div class="grid mb-3">
                              <div class="col-12">
                                  <p class="text-softgrey label-info">{{'publish.form.version' | translate}} <i class="pi pi-info-circle" pTooltip="Unique sequence of numbers which identifies the state of app development"></i></p>
                                  <!-- <input type="text" mask="1.0.0.0" placeholder="1.0.0.0" pInputText [(ngModel)]="appConfig.version" (blur)="onBlur()" class="mr-2 rounded" value="" /> -->
                                  <p-inputMask mask="9.9.9" [(ngModel)]="appConfig.version" placeholder="9.9.9" (blur)="onBlur()" ></p-inputMask>
                                </div>
                          </div>
                          <div class="grid">
                              <div class="col-12">
                                  <p class="text-softgrey">{{'publish.export.type' | translate}}</p>
                                  <div class="p-inputgroup">
                                      <p-selectButton [options]="exportOptions" [(ngModel)]="appConfig.app_type" (blur)="onBlur()" optionLabel="label" optionValue="value"></p-selectButton>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                    <p-divider></p-divider>
                    <p class="text-magenta text-left">{{'publish.app.disclaimer' | translate}}</p>
                    <button (click)="onPublishApp()" [disabled]="disabledReqApp" pButton type="button" label="Request App" icon="pi pi-th-large" iconPos="right" class="p-button p-component d-flex m-auto"></button>
                    <p class="text-softgrey text-center" *ngIf="disabledReqApp">This Feature will be active soon</p>
                  </div>
            </div>
        </p-tabPanel>
    </p-tabView>
</p-dialog>