import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-fibr-blockui-login',
  templateUrl: './blockui-login.component.html',
  styleUrls: ['./blockui-login.component.scss']
})
export class BlockuiLoginComponent implements OnInit {
  @Input() blocked = false;
  constructor() { 
    // 
  }

  ngOnInit(): void {
    console.log() 
  }

}
