import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
// import { appConfig } from '../interfaces/global';

@Injectable({
  providedIn: 'root',
})
export class ShowMockupService {
  
  private showMockupSource = new Subject<boolean>();
  private showPageDetailSource = new Subject<boolean>();
  private hidddenButtonSource = new Subject<boolean>();
  private showAppConfigSource = new Subject<string>();
  private showPagePropertiesSource = new Subject<boolean>();
  private showSettingAppSource = new Subject<boolean>();
  private showChooseTableSource = new Subject<any>();

  mockupShow$ = this.showMockupSource.asObservable();
  pageDetailShow$ = this.showPageDetailSource.asObservable();
  appConfigShow$ = this.showAppConfigSource.asObservable();
  hiddenAct$ = this.hidddenButtonSource.asObservable();
  pageProperties$ = this.showPagePropertiesSource.asObservable();
  settingApp$ = this.showSettingAppSource.asObservable();
  // chooseTable$ = this.showChooseTableSource.asObservable();

  showMockup(mockup: boolean) {
    this.showMockupSource.next(mockup);
  }

  showPageDetail(showPage: boolean) {
    this.showPageDetailSource.next(showPage);
  }

  showAppConfig(appConfig: string) {
    this.showAppConfigSource.next(appConfig);
  }

  hiddenBotton(button: boolean) {
    this.hidddenButtonSource.next(button);
  }

  showPageProperties(showProperties: boolean) {
    this.showPagePropertiesSource.next(showProperties);
  }

  showSettingApp(showSettingApp: boolean) {
    this.showSettingAppSource.next(showSettingApp);
  }

  // showChooseTable(tableSelect: any) {
  //   this.showChooseTableSource.next(tableSelect);
  // }
}
