/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { ConnectionService } from '@core-fibr/shared';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GridModel } from '../../../clases/global';
import { appConfig } from '../../../interfaces/global';
import { GlobalcrudService } from '../../../services/crud/globalcrud.service';
import { GlobalService } from '../../../services/global.service';
import { ToastNotificationService } from '../../../services/toast-notification.service';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from '@core-fibr/auth';

@Component({
  selector: 'ui-fibr-add-table',
  templateUrl: './add-table.component.html',
  styleUrls: ['./add-table.component.scss']
})
export class AddTableComponent implements OnInit {
  gridModel: GridModel = new GridModel();
  fields: any = {
    tableName: ''
  };

  showInputTableName = false;
  isGoogleFiles = false;
  user: any;
  appConfig: appConfig = {};
  pathProjectFirebase = "";
  freeVersion: any;
  paidVersion: any;

  constructor( 
    public ref: DynamicDialogRef, 
    private toastNotificationService: ToastNotificationService,
    public config: DynamicDialogConfig,
    private globalService: GlobalService,
    private connectionService: ConnectionService,
    private globalCrudService: GlobalcrudService,
    ) { }

  ngOnInit(): void {
    this.user = this.globalService.userProfile;
    this.isGoogleFiles = this.config.data?.isGoogleFiles? this.config.data?.isGoogleFiles : false;
    this.gridModel.APIEndpoint = this.config.data?.url ?? '';
    this.globalService.appConfig.subscribe((res: appConfig) => {
      this.appConfig = res;
    });
    this.globalCrudService.getcollectionwithid('/config/facilities/data', 'free').subscribe((res: any)=>{
      this.freeVersion = res;
    });
    this.globalCrudService.getcollectionwithid('/config/facilities/data', 'paid').subscribe((res: any)=>{
      this.paidVersion = res;
    });
  }

  cancelDialog(){
    this.ref.close({action : 'cancel'});
  }

  doItDialog(){
    if(this.fields.tableName == ''){
      this.toastNotificationService.showNotification("error", "Table Name couldn't empty!")
    }else{
      this.ref.close({action : 'newTable', tableName: this.fields.tableName});
    }
  }

  googleSheets(): void{
    this.ref.close({action: 'googleSheets'});
  }

  googleFilesFetch(e: any){
    // this.connectionService.swicthFirebase(this.user);
    this.connectionService.swicthFirebase(this.user,this.appConfig.app_id);
    this.globalService.changeBlockui(true);
    // console.log(this.appConfig)
    // console.log(this.config.data?.tableKey.length)
    gapi.load('client', () => {
      const options: gapi.client.RequestOptions = {
          path: `https://sheets.googleapis.com/v4/spreadsheets/${e.data.id}`
      };
      const request: gapi.client.HttpRequest<any> = gapi.client.request(options);
      request.execute((res: any) => {
        const countTable = this.config.data?.tableKey.length + res.sheets.length;
        // if (!this.appConfig.is_paid && countTable > environment.addPackage.max_table_free) {
        if (!this.appConfig.is_paid && countTable > this.freeVersion.max_tables) {
          this.ref.close({action: 'close', tableNames : [], limit: true, info: 'free'});
          setTimeout(() => {
            this.globalService.changeBlockui(false);
          }, 1000);
        } else { 
          const array = res.sheets;
          let countSheet: number;
          // const is_paid: any = this.appConfig.is_paid
          const max_table_config: any = this.appConfig.max_tables;
          let limitTable: any;
          // if (max_table_config > environment.addPackage.max_table_paid) 
          if (max_table_config > this.paidVersion.max_tables) 
            limitTable = max_table_config; 
          else 
            limitTable = this.paidVersion.max_tables;

          if (countTable > limitTable) {
            this.ref.close({action: 'close', tableNames : [], limit: true, info: 'paid'});
            setTimeout(() => {
              this.globalService.changeBlockui(false);
            }, 1000); 
          } else {
            let forloop: any;
            if (array.length > limitTable) forloop = limitTable; else forloop = array.length;
            // const pathData = this.config.data?.pathData; //before projects
            // const pathDataModel = `/users/${this.user.uid}/data_projects`;//before projects
            const dataTitle:any = {};
            for (let index = 0; index < forloop; index++) {
              const element = array[index].properties;
              let title = this.globalService.replaceString(element.title);
              if(this.config.data?.tableKey.includes(title)) title = title+`_${this.config.data?.tableKey.length + index}`;
              const collectionPath = this.config.data?.collectionPath + '/' + title + '/data' ; 
              const collectionColums = this.config.data?.collectionPath;
              this.config.data?.tableKey.push(title);
              const options2: gapi.client.RequestOptions = {
                  path: `https://sheets.googleapis.com/v4/spreadsheets/${e.data.id}/values/${element.title}`
              };
              const request: gapi.client.HttpRequest<any> = gapi.client.request(options2);
              
              request.execute((result: any) => {
                const array2 = result.values;
                const convertArrayToObject:any = {};
                const dataModel:any = {};
                const columns: any[] = [];
                for (let i=0; i < array2[0].length; i++) {
                  array2[0][i] = this.globalService.replaceString(array2[0][i]);
                  columns.push({ "field" : array2[0][i], "header" : array2[0][i], "sortable" : true, "filterable": true, "col_order": i, 
                  type: this.globalService.getType(array2[0][i]), "showInGrid": true, "showInForm": true });
                }
                const addCol = ['created_date','created_by','modify_date','modified_by'];
                let st = array2[0].length;
                for (let ii=0; ii < addCol.length; ii++) {
                  columns.push({ "field" : addCol[ii], "header" : addCol[ii], "sortable" : true, "filterable": true, "col_order": st, 
                  type: this.globalService.getType(addCol[ii]), "showInGrid": false, "showInForm": false });
                  st++;
                }
                
                const column = array2[0];
                this.globalCrudService.createDynamiccollectionwithdocumentid({columns : columns}, collectionColums, title)
                .then(() => console.log())
                this.pathProjectFirebase = `/users/${this.user.uid}/projects/${this.appConfig.app_id}/firebase`;
                this.globalCrudService.getcollection(this.pathProjectFirebase).subscribe((res: any) => {
                  if (res[0]?.state == 'ACTIVE') {
                    this.globalCrudService.createcollectionwithdocumentname({columns : columns}, collectionColums, title)
                    .then(() => console.log());
                  }
                });

                const rowsConfig: any = this.appConfig.max_rows;
                let countRow: number;
                // eslint-disable-next-line prefer-const, @typescript-eslint/no-non-null-assertion
                countRow = this.globalService.checkGoogleSheetRows(this.appConfig.is_paid!, this.freeVersion.max_rows, this.paidVersion.max_rows, array2.length, rowsConfig);
                // countRow = this.globalService.checkGoogleSheetRows(this.appConfig.is_paid!, environment.addPackage.max_rows_free, environment.addPackage.max_rows_paid, array2.length, rowsConfig);
                  for (let indexs = 1; indexs < countRow + 1; indexs++) {
                  // for (let indexs = 1; indexs < array2.length; indexs++) {
                    const elements2 = array2[indexs];
                    // for (let i=0; i < elements2.length; i++) {
                    for (let i=0; i < column.length; i++) {
                      if (elements2[i] !== undefined) {
                        convertArrayToObject[array2[0][i]] = elements2[i];
                        dataModel[array2[0][i]] = 'text'
                      } else {
                        convertArrayToObject[array2[0][i]] = '';
                        dataModel[array2[0][i]] = 'text'
                      }
                    }
                    convertArrayToObject['modify_date']= new Date();
                    convertArrayToObject['created_by']= '';
                    convertArrayToObject['modified_by']= '';
                    dataModel['modify_date'] = 'date';
                    dataTitle[title] = dataModel;
                    /** awalnya pakai fungsi dynamicCreatecollection = row tidak ada idnya
                     * diganti pakai fungsi dynamicCreatecollectionWithId
                    */
                    this.globalCrudService.dynamicCreatecollectionWithId(convertArrayToObject, collectionPath);
                    if (indexs < 3) {
                      this.pathProjectFirebase = `/users/${this.user.uid}/projects/${this.appConfig.app_id}/firebase`;
                      this.globalCrudService.getcollection(this.pathProjectFirebase).subscribe((res: any) => {
                        if (res[0].state == 'ACTIVE') {
                          this.globalCrudService.createcollection(convertArrayToObject, collectionPath);
                        }
                      });
                    }
                  } 
                  // setTimeout(() => {
                  //   this.globalService.changeBlockui(false);
                  // }, countRow * 7);
              });
            }
            this.ref.close({action: 'close', tableNames : this.config.data.tableKey, limit: false, info: 'success'});
            setTimeout(() => {
              this.globalService.changeBlockui(false);
            }, array.length * 1000);
          }
        }
      });
    });
  }

}
