<!-- <div *ngIf="blocked && (osPlatform ==='MacOS' || osPlatform ==='iOS')" styleClass="progress-spinner center-screen">
  <p-progressSpinner strokeWidth="4" [style]="{width: '6rem', height: '6rem'}"  styleClass="progress-spinner center-screen"></p-progressSpinner>
</div> -->

<!-- <div *ngIf="blocked && osPlatform !=='MacOS' && osPlatform !=='iOS'"> -->
<div>
  <p-blockUI [blocked]="blocked" class="hidden">
    <p-progressSpinner strokeWidth="4" [style]="{width: '6rem', height: '6rem'}"  styleClass="progress-spinner center-screen"></p-progressSpinner>
  </p-blockUI>
</div>
