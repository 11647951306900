import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { SharedModule } from "@core-fibr/shared";
import { AuthModule } from "@core-fibr/auth";
import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { CommonModule } from "@angular/common";
import { SampleComponent } from "./components/sample/sample.component";
import { BrowserModule } from "@angular/platform-browser";
import { TokenInterceptor } from "./token.interceptor";
import { TokenProjectInterceptor } from "./token-project.interceptor"
@NgModule({
  declarations: [AppComponent, SampleComponent],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    AuthModule,
    AppRoutingModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
    // ,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: TokenProjectInterceptor,
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
