/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-extra-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { appConfig } from '../../../interfaces/global';
import { GlobalcrudService } from '../../../services/crud/globalcrud.service';
import { GlobalService } from '../../../services/global.service';
// import { environment } from '../../../../../../auth/src/environments/environment';
import { environment } from '@core-fibr/auth';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GridModel } from '../../../clases/global';
import { Router } from '@angular/router';
import { LowerCasePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConnectionService } from '@core-fibr/shared';

@Component({
  selector: 'ui-fibr-createnew',
  templateUrl: './createnew.component.html',
  styleUrls: ['./createnew.component.scss']
})
export class CreatenewComponent implements OnInit {
  gridModel: GridModel = new GridModel();
  appConfig: appConfig = {};
  isGoogleFiles: boolean | undefined;
  loadGsheetByBackend: boolean = false;
  columns: any[] = [];
  isLoading: boolean = false;

  tableId: String = "gsheetFiles";


  data: any = {};
  user: any;
  waitProcess: any = {};
  urlWait = "";
  showMsg = false;
  isDataExists = false;
  statusApp = "";
  pathProjectFirebase = "";
  freeVersion: any;
  paidVersion: any;
  env: any;
  constructor(
    private router: Router,
    private globalService: GlobalService,
    private globalCrudService: GlobalcrudService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private tolowercase: LowerCasePipe,
    private connectionService: ConnectionService,
    private httpClient: HttpClient
  ) {
    // this.pathProjectFirebase = `/users/${this.user.uid}/projects/${this.appConfig.app_id}/firebase`;
    this.env = this.globalService.decryptEnv(environment);
  }

  ngOnInit(): void {
    this.isGoogleFiles = this.config.data?.isGoogleFiles ? this.config.data?.isGoogleFiles : false;
    this.loadGsheetByBackend = this.config.data?.loadGsheetByBackend ? this.config.data?.loadGsheetByBackend : false;
    this.isLoading = true;
    this.gridModel.APIEndpoint = this.config.data?.url ?? '';
    this.globalService.appConfig.subscribe((res: appConfig) => {
      this.appConfig = res;
    });
    this.user = this.globalService.userProfile;
    /** for need trial */
    this.globalCrudService
      .getcollection(`users/${this.user.uid}/projects`)
      .subscribe((res: any) => {
        this.isDataExists = res.length > 0;
      });
    // this.statusApp = environment.firebase.projectId;
    this.statusApp = this.env.firebase.projectId;
    this.globalCrudService.getcollectionwithid('/config/facilities/data', 'free').subscribe((res: any) => {
      this.freeVersion = res;
    });
    this.globalCrudService.getcollectionwithid('/config/facilities/data', 'paid').subscribe((res: any) => {
      this.paidVersion = res;
    });

    this.columns = [
      { field: '', header: '#' },
      { field: 'mimeType', header: 'Type' },
      { field: 'name', header: 'FileName' },
      { field: 'action', header: 'Action' }
    ];
  }

  googleSheets(): void {
    // console.log(this.freeVersion)
    // console.log(this.paidVersion)
    if (this.appConfig.app_name == null || this.appConfig.app_name == '') {
      this.showMsg = true;
      return;
    }
    this.showMsg = false;
    this.ref.close('googleSheets');
    this.globalService.setAppConfig(this.appConfig);
  }

  googleFilesFetch(e: any) {
    this.globalService.changeBlockui(true);
    let appName: any = this.appConfig.app_name;
    this.appConfig.app_name = this.globalService.replaceString(appName);
    this.appConfig.app_id = this.tolowercase.transform((this.appConfig.app_name)?.split(' ').join('') + this.globalCrudService.generateId());
    this.appConfig.file_id = e.data.id;
    this.appConfig.file_name = e.data.name;
    this.appConfig.version = '1.0.0';
    this.appConfig.app_icon = '';
    this.appConfig.build_number = 1;
    this.appConfig.is_suspend = false;
    this.appConfig.is_publish = false;
    this.appConfig.created_date = new Date();
    this.appConfig.updated_date = new Date();
    this.appConfig.config = '';
    this.appConfig.app_desc = '';
    this.appConfig.package_name = '';
    this.appConfig.app_type = '';
    this.appConfig.publish_option = '';
    this.appConfig.app_logo = '';
    this.appConfig.primary_color = '#97DDFF';
    this.appConfig.secondary_color = '#4394D8';
    this.appConfig.font_color = '#1A1A1A';
    this.appConfig.splash_screen = '';
    this.appConfig.login_registration = '';
    this.appConfig.broadcast_content = '';
    this.appConfig.broadcast_title = '';
    this.appConfig.is_paypwa = false;
    this.appConfig.is_payapps = false;
    this.appConfig.category = '';
    this.appConfig.created_by = '';
    this.appConfig.is_paid = false;
    // this.appConfig.max_rows = environment.addPackage.max_rows_free;
    // this.appConfig.max_pages = environment.addPackage.max_page_free;
    // this.appConfig.max_tables = environment.addPackage.max_table_free;
    this.appConfig.max_rows = this.freeVersion.max_rows;
    this.appConfig.max_pages = this.freeVersion.max_pages;
    this.appConfig.max_tables = this.freeVersion.max_tables;
    this.appConfig.max_upload = this.freeVersion.max_upload;
    this.appConfig.is_login = false;
    this.appConfig.email = this.user.email;
    this.appConfig.uid = this.user.uid;
    this.appConfig.number_logged = 0;
    this.appConfig.number_registered = 0;
    this.appConfig.fullName = this.user.fullName;
    this.appConfig.phoneNumber = this.user.phoneNumber;
    this.appConfig.is_process_status = '';
    this.appConfig.upgrade_pro_date = null;
    this.appConfig.membership_expired_date = null;
    this.appConfig.published_date = null;
    this.appConfig.traffic = 0;
    this.appConfig.traffic_app = 0;
    this.appConfig.traffic_pwa = 0;
    this.appConfig.max_storage = 0;
    this.appConfig.is_publish_pwa = false;
    this.appConfig.publish_pwa_date = null;
    this.appConfig.is_role = false;
    this.appConfig.status_project = 'basic';
    this.appConfig.is_registration = false;
    this.globalService.setAppConfig(this.appConfig);
    this.globalService.setProjectEditStatus(true);
    this.ref.close(e);
    this.pathProjectFirebase = `/users/${this.user.uid}/projects/${this.appConfig.app_id}/firebase`;
    // this.connectionService.swicthFirebase(this.user);
    this.connectionService.swicthFirebase(this.user, this.appConfig.app_id);
    const path = `/users/${this.user.uid}/projects`;
    const profilePath = `/users/${this.user.uid}/profile`;

    this.globalCrudService.createcollectionwithdocumentname(this.appConfig, path, this.appConfig.app_id!);
    gapi.load('client', () => {
      // const batch: gapi.client.HttpBatch = gapi.client.newBatch();
      // contoh this.appConfig.file_id! = 1c-ZWF5lhWNKG_Wa669CaPXAmHvnBKiytVsbQ0d8D0Sg
      const options: gapi.client.RequestOptions = {
        path: `https://sheets.googleapis.com/v4/spreadsheets/${this.appConfig.file_id!}`
      };
      const request: gapi.client.HttpRequest<any> = gapi.client.request(options);
      request.execute((res: any) => {
        const array = res.sheets;
        let countSheet: number;
        // eslint-disable-next-line prefer-const
        // countSheet = this.globalService.checkGoogleSheets(false, environment.addPackage.max_table_free, environment.addPackage.max_table_paid, array.length, 0);
        countSheet = this.globalService.checkGoogleSheets(false, this.freeVersion.max_tables, this.paidVersion.max_tables, array.length, 0);
        // if (array.length > 3) countSheet = 3; else countSheet = array.length;
        const pathData = `/users/${this.user.uid}/data_projects/${this.appConfig.app_id}/tables`;
        const pathDataModel = `/users/${this.user.uid}/data_projects`;//before projects
        const arraySheetsID: any[] = [];
        // for (let index = 0; index < array.length; index++) {
        for (let index = 0; index < countSheet; index++) {
          const element = array[index].properties;
          arraySheetsID.push(element.title);
        }
        // this.globalCrudService.createDynamiccollectionwithdocumentname({key: arraySheetsID }, pathData, this.appConfig.file_id!); // ######
        this.globalCrudService.createDynamiccollectionwithdocumentname({ key: arraySheetsID }, pathDataModel, this.appConfig.app_id!); // ######
        /**untuk kebutuhan config dev /prod */
        this.globalCrudService.getcollection(this.pathProjectFirebase).subscribe((res: any) => {
          if (res[0]?.state == 'ACTIVE') {
            this.globalCrudService.createcollectionwithdocumentname({ key: arraySheetsID }, pathDataModel, this.appConfig.app_id!);
          }
        });

        const dataTitle: any = {};
        // for (let index = 0; index < array.length; index++) {
        for (let index = 0; index < countSheet; index++) {
          const element = array[index].properties;
          // const collectionPath = pathData + '/' + element.title + '/data' ; 
          const title = this.globalService.replaceString(element.title);
          const collectionPath = pathData + '/' + title + '/data';
          // get data from google sheets
          const options2: gapi.client.RequestOptions = {
            path: `https://sheets.googleapis.com/v4/spreadsheets/${this.appConfig.file_id!}/values/${element.title}`
          };
          const request: gapi.client.HttpRequest<any> = gapi.client.request(options2);
          request.execute((result: any) => {
            const array2 = result.values;
            const convertArrayToObject: any = {};
            const dataModel: any = {};

            let columns: any[] = [];
            for (let i = 0; i < array2[0].length; i++) {
              array2[0][i] = this.globalService.replaceString(array2[0][i]);
              columns.push({
                "field": array2[0][i], "header": array2[0][i], "sortable": true, "filterable": true, "col_order": i,
                type: this.globalService.getType(array2[0][i]), "showInGrid": true, "showInForm": true
              })
            }
            const column = array2[0];
            const addCol = ['created_date', 'created_by', 'modify_date', 'modified_by'];
            let st = array2[0].length;
            for (let ii = 0; ii < addCol.length; ii++) {
              columns.push({
                "field": addCol[ii], "header": addCol[ii], "sortable": true, "filterable": true, "col_order": st,
                type: this.globalService.getType(addCol[ii]), "showInGrid": false, "showInForm": false
              });
              st++;
            }

            this.globalCrudService.createDynamiccollectionwithdocumentid({ columns: columns }, pathData, element.title)
              .then(() => console.log())
            this.pathProjectFirebase = `/users/${this.user.uid}/projects/${this.appConfig.app_id}/firebase`;
            this.globalCrudService.getcollection(this.pathProjectFirebase).subscribe((res: any) => {
              if (res[0]?.state == 'ACTIVE') {
                this.globalCrudService.createcollectionwithdocumentname({ columns: columns }, pathData, element.title)
                  .then(() => console.log());
              }
            });

            let countRow: number;
            // countRow = this.globalService.checkGoogleSheetRows(false, environment.addPackage.max_rows_free, environment.addPackage.max_rows_paid, array2.length, 0);
            countRow = this.globalService.checkGoogleSheetRows(false, this.freeVersion.max_rows, this.paidVersion.max_rows, array2.length, 0);
            for (let indexs = 1; indexs < countRow + 1; indexs++) {
              // for (let indexs = 1; indexs < array2.length; indexs++) {
              const elements2 = array2[indexs];
              // console.log(elements2)
              // for (let i=0; i < elements2.length; i++) {
              for (let i = 0; i < column.length; i++) {
                if (elements2[i] !== undefined) {
                  convertArrayToObject[array2[0][i]] = elements2[i];
                  dataModel[array2[0][i]] = 'text'
                } else {
                  convertArrayToObject[array2[0][i]] = '';
                  dataModel[array2[0][i]] = 'text'
                }
              }
              convertArrayToObject['modify_date'] = new Date();
              convertArrayToObject['created_by'] = '';
              convertArrayToObject['modified_by'] = '';
              dataModel['modify_date'] = 'date';
              dataTitle[title] = dataModel;

              /** dynamicCreatecollection = row tidak ada idnya*/
              // this.globalCrudService.dynamicCreatecollection(convertArrayToObject, collectionPath);
              this.globalCrudService.dynamicCreatecollectionWithId(convertArrayToObject, collectionPath);
              this.globalCrudService.getcollection(this.pathProjectFirebase).subscribe((res: any) => {
                if (res[0]?.state == 'ACTIVE' && indexs < 3) {
                  this.globalCrudService.createcollection(convertArrayToObject, collectionPath);
                }
              });
            }
          });
        }
        // setTimeout(() => {
        //   this.globalService.changeBlockui(true);
        //   this.router.navigate(['/portal/project/editor'], {
        //     queryParams: { filename: e.data.name, fileid: e.data.id, appid: this.appConfig.app_id }
        //   });
        // }, array.length * 2000);
        this.importDataFinisih(array.length, this.appConfig);
      });
    });
  }

  importDataFinisih(arr: any, appConfig: any) {
    this.globalService.changeBlockui(true);
    setTimeout(() => {
      this.globalService.changeBlockui(false);
      this.router.navigate(['/portal/project/editor'], {
        queryParams: { filename: appConfig.file_name, fileid: appConfig.file_id, appid: appConfig.app_id }
      });
    }, arr * 2000);
  }

}
