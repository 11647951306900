<div class="login-body layout-light">
    <div class="login-content">
        <div class="night-switch">
            <div class="language-menu">
                <p-dropdown (onChange)="onLanguageChange()" [options]="country" [(ngModel)]="selectedLanguage" placeholder="{{'selectLanguage' | translate}}" optionLabel="name" [showClear]="true"></p-dropdown>
            </div>
        </div>
        <div class="login-panel">
            <div class="login-panel-content">
                <div class="logo"><img [src]="logo" alt="fibr-logo" /></div>
                <h4 class="mb-2">{{'Fibr-portalRegister' | translate}}</h4>
                <span>Tambahkan fasilitas kesehatan anda(bisa lebih dari satu Faskes)</span>
                <div class="col">
                    <div class="forms">
                        <div class="grid">
                            <label class="col-12" for="faskesName">Nama Faskes</label>
                            <ng-container *ngFor="let fsks of faskes; let i=index">
                                <div class="col-9 login-input-wrapper mb-3" >
                                    <input type="text" [(ngModel)]="fsks.name" placeholder="Input Faskes Name" class="p-inputtext p-component line-input py-3" />
                                </div>
                                <div class="col-3 ">
                                    <p-button type="button" label="" icon="pi pi-trash" styleClass="p-button-danger p-button-text" class="float-right" (onClick)="removeFaskes(i)" > </p-button>
                                </div>
                            </ng-container>
                        </div>
                        
                        <div class="col-12">
                            <button type="button" (click)="addNewFaskes()" class="p-button p-button-primary" pButton label="Add New Faskes" icon="pi pi-plus"></button>
                        </div>

                        <div class="col-12">
                            <p-checkbox name="groupname" [binary]="true" [(ngModel)]="selectedValues"></p-checkbox>      
                            <label class="p-checkbox-label"> {{'checkboxRegister' | translate}} <a [ngStyle]="{color:'var(--primary-color)'}">{{'termofservice' | translate}}</a> {{'and' | translate}} <a [ngStyle]="{color:'var(--primary-color)'}">{{'privacypolicy' | translate}}</a></label>               
                        </div>
                        <div class="buttons">
                            <button pbutton="" type="button" label="Login" tabindex="0" class="col-12 p-button p-component" (click)="onClickRegisterUseFireEmailandPassword()">
                            <span class="p-button-label">{{'REGISTER' | translate}}</span>
                            <p-progressSpinner *ngIf="isProgressSpinner" strokeWidth="8" class="progress-spinner-btn p-spin-sm"></p-progressSpinner>
                            </button>
                        </div>
                        <div class="col-12 center">
                            <a href="javascript:;" [routerLink]="['/auth/register']" [queryParams]="{fullName: fullName, userName: userName, password: password}"  pbutton="" type="button" label="Register" tabindex="0" class="p-button-text mt-4"><span class="p-button-label">back</span></a>
                        </div>
                    </div>
                </div>
                <div class="col center mt-4">
                  <span>Copyright @2021 <img src="/assets/logo_fibr_footer.svg" height="22" class="ml-1"></span>
                  <!-- <div>Version : {{version}}</div> -->
                </div>
            </div>
        </div>

        <div class="login-panel right" 
        [ngStyle]="{
            'background-image': 'url(' + image_login + ')',
            'background-size': 'cover',
            'background-position': 'center',
            'background-repeat': 'no-repeat'}"
            >
            <div class="login-panel-content">
                <div class="logo"><img src="/assets/logo-fibr.png" alt="fibr-logo" /></div>
                <h4 class="mb-2">How it works?</h4>
                <p class="text-xs text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
        </div>
    </div>
</div>

