import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countdataboolean'
})
export class CountdatabooleanPipe implements PipeTransform {

  transform(value: any, field: string): string {
    let filter = value.filter((x:any) => x[field] == true);
    return filter == null ? 0 : filter.length;
  }

}
