<div class="grid">
    <div class="col-12">
      <!-- styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped" -->
      <p-table #table [columns]="columns" 
      [id]="tableId??'table-el'"
      [value]="gridModel?.Data!" 
      [lazy]="true" 
      selectionMode="single" 
      [(selection)]="multipleSelection" 
      dataKey="id"
      styleClass="p-datatable-gridlines" 
      responsiveLayout="scroll"
      [paginator]="gridModel.isPaginator" 
      [rows]="rowsPerPage" 
      [showCurrentPageReport]="showCurrentPageReport"
      [currentPageReportTemplate]="currentPageReportTemplate" 
      [rowsPerPageOptions]="rowsPerPageOptions"
      [loading]="isLoading" 
      [totalRecords]="totalRecords" 
      (onLazyLoad)="lazyLoadData($event)" 
      [resizableColumns]="false"
      [scrollable]="true" 
      [scrollHeight]="'flex'" 
      *ngIf="isVisible">
      <!-- [scrollHeight]="isOpenInModal ? 'flex' : '100%'" -->
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <ng-container *ngFor="let col of columns">
            <ng-container *ngIf="checkArray(col.field) === true; else notArrayColumnTemplate">
              <col class="button-column-width">
            </ng-container>
            <ng-template #notArrayColumnTemplate>
              <col class="resizable-column-width">
            </ng-template>
          </ng-container>
        </colgroup>
      </ng-template>
      <ng-template pTemplate="caption">
        <div class="flex p-flex-column p-flex-md-row" *ngIf="gridModel.GridName">
            <img class="align-self-center mr-3" alt="icon" src="/assets/images/icon-lg/icon_table-lg.svg" height="20">
            <h3 class="menu-title">{{gridModel.GridName}}</h3>
        </div>
        <div class="flex flex-column justify-content-between flex-wrap md:flex-row p-jc-md-between mt-3">
          <div class="p-inputgroup md:col-4 col-12 my-0 md:my-3 p-0" *ngIf="!isGoogleFiles && gridModel.isShowFilter">
              <input type="text" pInputText placeholder="Search" (keydown.enter)="filterGlobal($any($event.target).value, 'contains')"> 
              <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>        
          </div>
          <div class="col-auto ml-auto p-0 mt-3">
            <p-button *ngIf="gridModel.isAddNewData && !isGoogleFiles" label="Tambah Data" icon="pi pi-plus" iconPos="right" (onClick)="createNewRow()" styleClass="mr-2 mb-2"> </p-button>
            <button *ngIf="multipleSelection.length > 0" type="button" pButton pRipple (click)="submitEvent()" icon="pi pi-save" iconPos="left" label="Submit"
                class="p-button-secondary mr-2 mb-2" pTooltip="add column" tooltipPosition="top"></button>
            <button *ngIf="gridModel.isAddNewColumn && !isGoogleFiles" type="button" pButton pRipple (click)="addNewColumns()" icon="pi pi-plus" iconPos="left" label="add column"
                class="p-button-secondary mr-2 mb-2" pTooltip="add column" tooltipPosition="top"></button>
            <button *ngIf="gridModel.isUpload" type="button" pButton pRipple (click)="uploadButton()" icon="pi pi-upload" iconPos="left" label=""
                class="p-button-warning mr-2 mb-2" pTooltip="upload data" tooltipPosition="top"></button>
                <button *ngIf="gridModel.isUpload" type="button" pButton pRipple (click)="removeButton()" icon="pi pi-trash" iconPos="left" label=""
                class="p-button-danger mr-2 mb-2" pTooltip="remove all data" tooltipPosition="top"></button>
                <span class="p-buttonset"  *ngIf="gridModel.IsImport">
                  <button type="button" pButton pRipple icon="pi pi-download" iconPos="left" (click)="downloadButton()"
                    class="p-button-secondary mr-2 mb-2" pTooltip="DownLoad Template" tooltipPosition="top"></button>
                  <button type="button" pButton pRipple icon="pi pi-upload" iconPos="left" (click)="uploadButton()"
                    class="p-button-secondary mr-2 mb-2" pTooltip="Upload File" tooltipPosition="top">
                  </button>
                </span>
              </div>
        </div>
        <div class="flex" *ngIf="gridModel.IsExport">
          <span class="p-buttonset">
            <button *ngIf="gridModel.IsExport" type="button" pButton pRipple (click)="refresh()" icon="pi pi-refresh" iconPos="left"
              class="p-button-secondary mr-2 mb-2" pTooltip="Refresh" tooltipPosition="top"></button>
            <button *ngIf="gridModel.IsExport" type="button" pButton pRipple (click)="table.exportCSV()" icon="pi pi-file-o" iconPos="left"
              class="p-button-secondary mr-2 mb-2" pTooltip="CSV" tooltipPosition="top"></button>
            <button *ngIf="gridModel.IsExport" type="button" pButton pRipple (click)="exportExcel()" icon="pi pi-file-excel" iconPos="left"
              class="p-button-secondary mr-2 mb-2" pTooltip="XLS" tooltipPosition="top"></button>
            <button *ngIf="gridModel.IsPDF" type="button" pButton pRipple (click)="exportPdf()" icon="pi pi-file-pdf" iconPos="left"
              class="p-button-secondary mr-2 mb-2" pTooltip="PDF" tooltipPosition="top"></button>
          </span>
        </div>
        
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th [ngClass]="{'item-center': gridModel.isShowCheckbox}">
            <ng-container *ngIf="gridModel.isShowCheckbox">
              <p-tableHeaderCheckbox (click)='isMultipleSelection = !isMultipleSelection;onRowMultipleSelect($event);'>
              </p-tableHeaderCheckbox>
            </ng-container>
          </th>
          <ng-container *ngFor="let col of columns;let i=index">
            <th pResizableColumn [ngClass]="{'headcol': i===0}" *ngIf="col.sortable" [pSortableColumn]="col.field" [ngStyle]="{'min-width': (col.type === 'empty' || col.type === 'button')? '116px' : (col.type === 'button2'? '15rem' : '200px'), 'width': (col.type === 'empty' || col.type === 'button')? '116px' : (col.type === 'button2'? '15rem' : '200px')}">
              {{ col.header }} <p-sortIcon [field]="col.field" *ngIf="col.sortable" style="float: right;"></p-sortIcon>
            </th>
            <th pResizableColumn [ngClass]="{'headcol': i===0}"  *ngIf="!col.sortable" [ngStyle]="{'min-width': (col.type === 'empty' || col.type === 'button' || col.type === 'icon')? '116px' : (col.type === 'button2'? '15rem' : '200px'), 'text-align': (col.type === 'button2'? 'center' : 'unset'), 'width': (col.type === 'empty' || col.type === 'button')? '116px' : (col.type === 'button2'? '15rem' : '200px')}">
              {{ col.header }}
            </th>
          </ng-container>
        </tr>
        <tr *ngIf="gridModel.isShowFilter">
          <th></th>
          <th *ngFor="let col of columns;let i=index" [ngClass]="{'headcol': i===0}" [ngStyle]="{'min-width': (col.type === 'empty' || col.type === 'button' || col.type === 'icon')? '116px' : (col.type === 'button2'? '15rem' : '200px'), 'width': (col.type === 'empty' || col.type === 'button')? '116px' : (col.type === 'button2'? '15rem' : '200px'), 'text-align': (col.type === 'button2'? 'center' : 'unset')}">
            <ng-container *ngIf="col.filterable === null || col.filterable">
              <p-columnFilter type="text" matchMode="equals" [field]="col.field" [matchModeOptions]="matchModeOptions"
                *ngIf="col.type !== 'button' && col.type !== 'button2'">
              </p-columnFilter>
            </ng-container>
          </th>
        </tr>
      </ng-template>
      <!-- Can edit/ modify -->
      <ng-template pTemplate="body" let-data let-index let-columns="columns" *ngIf="gridModel.isEditor">
        <tr>
          <td ></td>
          <td pEditableColumn *ngFor="let col of columns" [ngClass]="{'text-center': col.type === 'checkbox'}" [ngStyle]="{'max-width': (col.type === 'empty' || col.type === 'button' || col.type === 'icon' )? '10%' : (col.type === 'button2'? '15%' : 'auto'), 'text-align': (col.type === 'button2'|| col.type === 'icon')? 'center' : 'unset'}">
            <ng-container *ngIf="col.type !== 'button' && col.type !== 'image' && col.type !== 'date' && col.type !== 'datepicker' && col.type !== 'checkbox' && col.type !== 'link'">
              <p-cellEditor>
                <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="data[col.field]" (keydown.enter)="updateGrid(data)" (keydown.tab)="updateGrid(data)" >
                </ng-template>
                <ng-template pTemplate="output">
                  {{ data[col.field] }}
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.type === 'date'">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-calendar [(ngModel)]="data[col.field]" [showIcon]="true" inputId="icon" (ngModelChange)="updateGrid(data)"></p-calendar>
                </ng-template>
                <ng-template pTemplate="output">
                  <span class="p-column-title" *ngIf="data[col.field] !== null">
                    {{(data[col.field]/1000) | date : 'dd MMM yyyy' }}
                  </span>
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.type === 'checkbox'">
              <p-cellEditor>
                <ng-template pTemplate="input">
                    <p-checkbox [(ngModel)]="data[col.field]" [binary]="true" [disabled]="false" (ngModelChange)="updateGrid(data)"></p-checkbox>
                </ng-template>
                <ng-template pTemplate="output">
                  <p-checkbox [(ngModel)]="data[col.field]" [binary]="true" [disabled]="true"></p-checkbox>
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.type === 'link'">
              <span class="p-column-title">{{col.header}}</span>
              <button class="btn btn-link mr-2 link-font-size"
                (click)="onButtonLinkActionClick(col, data)">{{data[col.field] | linkcolumn:col.field}}</button>
            </ng-container>
            <ng-container *ngIf="col.type === 'button'">
              <span class="p-column-title">{{col.header}}</span>
              <ng-container *ngFor="let button of col.field">
                <button type="button" pButton [icon]="button.icon" class="p-button-raised p-button-secondary p-button-text mr-2 mb-2"
                  (click)="onButtonActionClick(button, data)">{{button.Title}}
                </button>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="col.type === 'button2'">
              <ng-container *ngFor="let button of col.field">
                <button pButton class="p-button-primary" [icon]="button.icon"
                  (click)="onButtonActionClick2(button, data)">{{button.Title}}</button>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="col.type === 'icon'">
              <img src="/assets/images/icon/icon_g-sheet.svg" height="20px"/>
            </ng-container>
            <ng-container *ngIf="col.type === 'image'">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="data[col.field]" (blur)="updateGrid(data)" (keydown.enter)="updateGrid(data)" (keydown.tab)="updateGrid(data)" >
                </ng-template>
                <ng-template pTemplate="output">
                  <img *ngIf="data[col.field] !== null" [src]="data[col.field]" width="25%" height="25%" onerror="this.src = './assets/iconic_logo.png'" />
                </ng-template>
              </p-cellEditor>
            </ng-container>
          </td>
      </tr>
      </ng-template>
      <!-- Can't edit/ modify -->
      <ng-template pTemplate="body" let-data let-index let-columns="columns" *ngIf="!gridModel.isEditor">
        <!-- <tr [ngStyle]="{'background-color': data === null ? null : data['IsLate'] ? 'red' : null }"> -->
          <tr>
          <td [ngClass]="{'item-center': gridModel.isShowCheckbox}">
            <ng-container *ngIf="gridModel.isShowCheckbox">
              <p-tableCheckbox [value]='data' (click)="onRowSelects($event)"></p-tableCheckbox>
            </ng-container>
          </td>
          <td *ngFor="let col of columns;let i=index" [ngClass]="{'justify-content': col.type === 'checkbox', 'text-right': col.type === 'button', 'headcol': i===0}" [ngStyle]="{'text-align' : col.type === 'link'?'center': 'unset', 'min-width': (col.type === 'empty' || col.type === 'button' || col.type === 'icon' )? '116px' : (col.type === 'button2'? '15rem' : '200px'), 'width': (col.type === 'empty' || col.type === 'button')? '116px' : (col.type === 'button2'? '15rem' : '200px'), 'justify-content': (col.type === 'button'||col.type === 'button2'|| col.type === 'icon' || col.type === 'image' || col.type === 'upload')? 'center' : 'unset', 'flex-wrap': col.type === 'multiselect'? 'wrap': 'unset'}">
            <ng-container 
              *ngIf="col.type !== 'button' && 
              col.type !== 'image' && 
              col.type !== 'upload' && 
              col.type !== 'date' && 
              col.type !== 'time' && 
              col.type !== 'datepicker' && 
              col.type !== 'checkbox' && 
              col.type !== 'link' &&
              col.type !== 'geopoint' &&
              col.type !== 'array' &&
              col.type !== 'multiselect'">
              <ng-template [ngIf]="data[col.field] && data[col.field].length > 20" [ngIfElse]="notelipsis" >
                <p uiFibrElipsisMe>{{ data[col.field] }}</p>
              </ng-template>
              <ng-template #notelipsis >
                <span [class]="getLabelClass(data[col.field])">
                  {{ data[col.field] && checkIsString(data[col.field]) && col.type !== 'number'? (data[col.field] | titlecase) : data[col.field] | number}}
                </span>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="col.type === 'array' || col.type ==='multiselect'">
              <!-- <span class="p-column-title">{{col.header}}</span> -->
              <div *ngFor="let item of data[col.field]" [class]="getLabelClass(col.header, item)" [style]="{'margin-bottom': col.type === 'multiselect'? '2px': 'unset'}">
                  {{ item | titlecase}}
              </div>
              <!-- {{ (data[col.field]).join(', ') }} -->
            </ng-container>
            <ng-container *ngIf="col.type === 'geopoint'">
              <!-- <span class="p-column-title">{{col.header}}</span> -->
              {{ (data[col.field]) | json }}
            </ng-container>
            <ng-container *ngIf="col.type === 'date'">
              <!-- <span class="p-column-title">{{col.header}}</span> -->
              {{ (data[col.field].seconds * 1000) | date:'dd MMM yyyy' }}
            </ng-container>
            <ng-container *ngIf="col.type === 'time'">
              <!-- <span class="p-column-title">{{col.header}}</span> -->
              {{ (data[col.field]?.seconds * 1000) | date:'HH:mm' }}
            </ng-container>
            <ng-container *ngIf="col.type === 'image' || col.type === 'upload'" >
              <img [src]="data[col.field]" width="50" onerror="this.src = './assets/iconic_logo.png'"  />
            </ng-container>
            <ng-container *ngIf="col.type === 'datepicker'">
              <!-- <span class="p-column-title">{{col.header}}</span> -->
              {{ data[col.field]/1000 |date:'dd/MM/yyyy' }}
            </ng-container>
            <ng-container *ngIf="col.type === 'button'">
              <!-- <span class="p-column-title">{{col.header}}</span> -->
              <ng-container *ngFor="let button of col.field">
                <button type="button" pButton class="p-button-rounded p-button-text mr-2 mb-2" [label]="button.Title" [icon]="button.icon || 'pi pi-file-o'" [attr.class]="button.class"
                  (click)="onButtonActionClick(button, data)"></button>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="col.type === 'button2'">
              <ng-container *ngFor="let button of col.field">
                <button pButton class="p-button-primary"
                  (click)="onButtonActionClick2(button, data)">{{button.Title}}</button>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="col.type === 'checkbox'">
              <p-checkbox [(ngModel)]="data[col.field]" [binary]="true" [disabled]="true"></p-checkbox>
            </ng-container>
            <ng-container *ngIf="col.type === 'link'">
              <!-- <span class="p-column-title">{{col.header}}</span> -->

              <button *ngIf="data[col.field]" pButton [class]="getLabelClass(data[col.field])"
                (click)="onButtonLinkActionClick(col, data)">{{ data[col.field] | titlecase}}</button>
                <button *ngIf="!data[col.field]" pButton [class]="'label-softgrey'"
                (click)="onButtonLinkActionClick(col, data)">{{'no data' | titlecase}}</button>
            </ng-container>
            <ng-container *ngIf="col.type === 'icon'">
              <img src="/assets/images/icon/icon_g-sheet.svg" height="20px">
            </ng-container>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <!-- <td></td> -->
          <td class="mw-100" [attr.colspan]="columns.length">No data found</td>
        </tr>
      </ng-template>
    </p-table>
    </div>
  </div>
  