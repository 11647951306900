import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from '@core-fibr/shared';
import { appConfig } from '../../../interfaces/global';

@Component({
  selector: 'ui-fibr-iconapps',
  templateUrl: './iconapps.component.html',
  styleUrls: ['./iconapps.component.scss']
})
export class IconappsComponent implements OnInit {
  @Input() appConfig: appConfig = {};
  icon: any;
  constructor(private globalService: GlobalService,) { 
    // setTimeout(() => {
    //   this.icon = this.appConfig.app_icon;
    // },1000);
    this.globalService.appConfig.subscribe((res) => {
      setTimeout(() => {
        this.icon = res.app_icon;
      },500);
    });
  }

  ngOnInit(): void {
  }

}
