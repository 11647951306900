import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReferenceDataService {
  private _currentReferenceDataCache: any;

  constructor() {
    console.log();
   }

  public getDescriptionForReferenceType(referenceType: string, code: string): string {
    if (referenceType == null || referenceType.length == 0) {
      throw 'Cannot have null or empty reference data type.';
    }
    if (code == null || code.length == 0) {
      return code;
    }
    let currentReferenceData: any = null;
    const split = referenceType.split('|');
    if(split?.length > 1){
      split.map(key => {
        currentReferenceData = this._currentReferenceDataCache.getByKey(key);
        if (currentReferenceData != null) {
          return;
        }
      })
    }else{
      currentReferenceData = this._currentReferenceDataCache.getByKey(referenceType);
    }
    
    if (currentReferenceData == null) {
      return code;
    }
    return currentReferenceData.Details?.find((p:any) => p.Code == code)?.CodeDescription ?? code;
  }
}
