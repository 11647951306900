import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from 'lodash';

@Pipe({
  name: 'stripTags'
})
export class StripTagsPipe implements PipeTransform {
  // transform(value: string): string {
  //   const regex = /(<([^>]+)>|&nbsp;)/ig;
  //   return value?.replace(regex, '');
  // }
  transform(value: any): any {
    let data: any;
    if (!isNumber(value)) {
      const regex = /(<([^>]+)>|&nbsp;)/ig;
      data = value?.replace(regex, '');
    } else {
      // if (value.toString().substring(0,1) == '0') data = value; else data = (value).toLocaleString('en-us', {minimumFractionDigits: 0});
      data = value.toString();
    }
    return data;
  }
}
