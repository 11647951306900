import { Component, OnInit } from '@angular/core';
import { Confirmation, ConnectionService, SessionService } from '@core-fibr/shared';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GridModel } from '../../../clases/global';
import { appConfig } from '../../../interfaces/global';
import { GlobalcrudService } from '../../../services/crud/globalcrud.service';
import { GlobalService } from '../../../services/global.service';
import { ToastNotificationService } from '../../../services/toast-notification.service';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { AuthenticationService, environment } from '@core-fibr/auth';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { ConfirmationService } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { DatePipe } from '@angular/common';
import { SocketService } from "../../../../../../../apps/fibr-portal/src/app/components/_shared/services/socket.service";
import { PaymentGateway } from '../../../../../../../apps/fibr-portal/src/app/components/_shared/services/payment_gateway.service'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ui-fibr-notif-pro',
  templateUrl: './notif-pro.component.html',
  styleUrls: ['./notif-pro.component.scss']
})
export class NotifProComponent implements OnInit {

  showUpgradeToPro = true;
  showOrderSummary = false;
  showInvoice = false;
  dataInv: any = {};
  realPay: any = {};
  headers: any = "";
  user: any = {};
  countGetToken = 0;
  confirmationTemplate: Confirmation | undefined;
  invForm: any = {};
  myWindow: any
  vaucher = '';
  statusPromo = '';
  max_pages_free = 0;
  max_rows_free = 0;
  max_tables_free = 0;
  max_pages_pro = 0;
  max_rows_pro = 0;
  max_tables_pro = 0;
  pipe = new DatePipe('en-US');
  max_day = 0;
  app_price = 0;
  app_discount = 0;
  promoActive: any;
  monthOptions: any[] = [{label: '1 Month', value: '1month'}, {label: '3 Month', value: '3month'},
  {label: '6 Month', value: '6month'}, {label: '12 Month', value: '12month'}];
  selectedMonthOptions: string = '1month';
  paymentMethod: any[] = [
      { label: 'One Time Payment', desc: 'Payment using midtrans', value: 'onetime'},
      // { label: 'Recurring Payment', desc: 'Add your credit card for recurring payment', value: 'recurring' }
  ];
  selectedPaymentMethod: string = 'onetime';
  paymentPackage: any = [];
  // [
  //     { label: '12 Month', desc: 'Rp 75.000/Month', value: '12month'},
  //     { label: '6 Month', desc: 'Rp 83.000/Month', value: '6month' },
  //     { label: '3 Month', desc: 'Rp 100.000/Month', value: '3month'},
  //     { label: '1 Month', desc: 'Rp 100.000/Month', value: '1month' }
  // ];
  selectedpaymentPackage: any = '';
  showCreditCardForm = false;
  paymentDesc = 'per month (cancel anytime)';
  app_priceInFront = 0;
  priceMonth:any;

  constructor(
    public ref: DynamicDialogRef,
    public socketService: SocketService,
    private toastNotificationService: ToastNotificationService,
    public config: DynamicDialogConfig,
    private globalService: GlobalService,
    private connectionService: ConnectionService,
    private globalCrudService: GlobalcrudService,
    private cs: ConfirmationService,
    private authService: AuthenticationService,
    private paymentGateway: PaymentGateway,
    private translate: TranslateService,
    private sessionService: SessionService,
  ) { 
    this.translate.use(this.sessionService.getItem('fibr-language'));
  }

  ngOnInit(): void {
    this.paymentDesc = this.translate.instant('table.upgrade.per_month_cancel');
    if (this.config.data.act == 'upgrade') this.showOrderSummary = false; else this.showOrderSummary = true;
    this.socketService.getRefreshStateData().subscribe(async (states: any) => {
      // console.log('state : ', states);
      if (states.content.billing == 'refresh') {
        // console.log(`refresh for ${states.to}`)
        if (this.myWindow)
          this.myWindow.close();
        this.showInvoice = false;
        this.showOrderSummary = false;
        this.ref.close();
        
      }
      this.socketService.setRefreshStateData(null);
    })
    // console.log(this.config)
    this.user = this.globalService.userProfile;
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${this.user?.accessToken}` });
    const dateNow = new Date()
    this.initPricePackage();
    setTimeout(() => {
      // console.log(this.paymentPackage)
      this.initInvoice();
    },1000)
    this.promoActive = [];
    this.globalCrudService.getcollection('config/price/app_event').subscribe((res: any) => {
      for (let i=0; i<res.length; i++) {
        if ((res[i].end_time.toDate()).getTime() > dateNow.getTime()) 
          this.promoActive.push(res[i]);
      }
    });
    
    this.limitTable('/config/facilities/data', 'free');
    this.limitTable('/config/facilities/data', 'paid');

    this.paymentGateway.init();
    this.paymentMethod = [
        {
          label: this.translate.instant('editor.summary.payment_method_options.one_time.label'),
          desc: this.translate.instant('editor.summary.payment_method_options.one_time.description'),
          value: 'onetime'
        },
        // {
        //   label: this.translate.instant('editor.summary.payment_method_options.recurring.label'),
        //   desc: this.translate.instant('editor.summary.payment_method_options.recurring.description'),
        //   value: 'recurring'
        // }
    ]
  }

  async initPricePackage() {
    this.paymentPackage = await this.globalCrudService.getCollectionPromiseOrderByDesc('config/price/custom_payment', 'order_no').then((result: any) => {
      if (result.docs != null) {
        this.paymentPackage = [];
        const cc: any = [];
        result.docs.forEach((doc: any) => {
          const rowdata = doc.data();
          rowdata.id = doc.id
          cc.push(rowdata)
        });
        this.priceMonth = cc.filter((x:any) => x.package_month == 1);
        return cc;
      }
    });
  }

  initInvoice() {
    const pathInv = `/users/${this.user?.uid}/projects_invoice`;
    this.globalCrudService.getcollectionwithid(pathInv, this.config.data.e.app_id).subscribe((res: any) => {
      // console.log(res, this.config.data)
      if (res && res.status == 'unpaid') {
        const packet = this.paymentPackage.filter((x:any) => x.id == res?.paymentPackage);
        if (packet.length > 0) {
          this.setValuePricePerMonth(packet[0]);
        } else {
          this.app_priceInFront = res.app_price;
          this.paymentDesc='';
        }
        this.app_price = res.app_price;
        this.max_day = res.app_max_num_days;
        this.app_discount = res.app_discount;
        this.realPay = res;
        this.vaucher = res.voucher;
        if (!res.paymentPackage) this.selectedpaymentPackage = this.paymentPackage[0].id; else this.selectedpaymentPackage = res.paymentPackage;
        if (!res.paymentMethod) this.selectedPaymentMethod = this.paymentMethod[0].value; else this.selectedPaymentMethod = res.paymentMethod;
      } else {
        // this.globalCrudService.getcollectionwithid('config', 'price').subscribe((resp: any) => {
        //   this.initPromo(resp)
        //   this.vaucher = '';
        // });
        this.initPromo(this.paymentPackage[0])
        this.vaucher = '';
        this.selectedpaymentPackage = this.paymentPackage[0].id;
        this.selectedPaymentMethod = this.paymentMethod[0].value;
      }
    });
  }

  limitTable(path: any, collection: any) {
    if (collection == 'free') {
      this.globalCrudService.getcollectionwithid(path, collection).subscribe((resp: any) => {
        this.max_pages_free = resp.max_pages;
        this.max_tables_free = resp.max_tables;
        this.max_rows_free = resp.max_rows;
      });
    } else {
      this.globalCrudService.getcollectionwithid(path, collection).subscribe((resp: any) => {
        this.max_pages_pro = resp.max_pages;
        this.max_tables_pro = resp.max_tables;
        this.max_rows_pro = resp.max_rows;
      });
    }
  }

  cekInvNoPaid() {
    this.showUpgradeToPro = false;
    this.showOrderSummary = true;
    this.showInvoice = false;
  }

  async updatePro(f: NgForm) {
    // console.log(f.value)
    this.globalService.changeBlockui(true);
    const body = { projectId: this.config.data.e.app_id, voucher: f.value.voucher, 
      paymentMethod: f.value.paymentMethod, paymentPackage: f.value.paymentPackage };
    // this.headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer` });
    this.payApp(body, this.headers);
  }

  async payApp(body: any, header: any) {
    this.globalService.changeBlockui(true);
    await this.globalService.methodPostOptions(environment.be_dev + `/api/v2/fibr-app/payapp`, body, header)
      .subscribe((res) => {
        // console.log('http',res)
        setTimeout(() => {
          this.globalService.changeBlockui(false);
            this.showOrderSummary = false;
            if (res?.code == 200) {
              if (res.data.created_date != null) res.data.created_date; else res.data.created_date = null;
              this.invForm = res.data;
              if (res.data.redirect_url != '') this.showInvoice = true; else this.showInvoice = false;
            }
            if (res?.code == 202) {
              this.extendPro(res,body);
            }
        }, 3000);
      },
      (err: any) => {
        this.errResponse(err,this.config.data.e, body)
      });
  }

  /**contoh test untuk response body dan header */
  async fnc(body: any, actPay?: any) {
    this.globalService.changeBlockui(true);
    await this.globalService.methodPostObserve(environment.be_dev + `/api/v1/fibr-app/payapp`, body, this.headers)
      .subscribe((res) => {
        console.log('http',res)
        setTimeout(() => {
          this.globalService.changeBlockui(false);
        }, 3000);
      },
      (err: any) => {
          console.log()
      });
  }

  extendPro(res: any, body: any){
    if (res?.data?.type == 'extend') {
      this.cs.confirm({
        header: 'Extend Pro Plan',
        message: res.message,
        dismissableMask: true,
        acceptButtonStyleClass: "p-button-primary",
        rejectButtonStyleClass: 'p-button-secondary',
        accept: () => {
          // const bodyExtend = {projectId: body.projectId, voucher: body.voucher, extend: true}
          const bodyExtend = { projectId: body.projectId, voucher: body.voucher, 
            paymentMethod: body.paymentMethod, paymentPackage: body.paymentPackage, extend: true};
          this.payApp(bodyExtend, this.headers);
        },
        reject: () => {
          console.log();  
        }
      });
    }
    if (res?.data?.type == 'createInvoice') {
      this.cs.confirm({
        header: 'Create Invoice?',
        message: res.message,
        dismissableMask: true,
        acceptLabel: 'Create New',
        rejectLabel: 'Continue Payment',
        acceptButtonStyleClass: "p-button-primary",
        rejectButtonStyleClass: 'p-button-secondary',
        accept: () => {
          // const bodyExtend = {projectId: body.projectId, voucher: body.voucher, createInvoice: true}
          const bodyExtend = {projectId: body.projectId, voucher: body.voucher, 
            paymentMethod: body.paymentMethod, paymentPackage: body.paymentPackage, createInvoice: true}
          this.payApp(bodyExtend, this.headers);
        },
        reject: () => { 
          const pathInv = `/users/${this.user?.uid}/projects_invoice`;
          this.globalCrudService.getcollectionwithid(pathInv, this.config.data.e.app_id).subscribe((res: any) => {
            if (res) {
              this.invForm = res;
              this.showInvoice = true;
              this.showOrderSummary = false;
            } else {
              this.showUpgradeToPro = false;
              this.showOrderSummary = true;
              this.showInvoice = false;
            }
          });
        }
      });
    }
  }

  async refreshToken(event: any, bodyForm: any) {
    this.globalService.changeBlockui(true);
    const body = { uid: this.user?.uid, refresh_token: this.user?.refreshToken };
    await this.globalService.methodPostPromise(environment.be_dev + `/api/v1/fibr-app/refreshtoken`, body)
      .subscribe(async (res) => {
        this.user.accessToken = res?.data?.accessToken;
        this.user.refreshToken = res?.data?.refreshToken;
        this.globalService.setRefDataToLocalstorage("user_profile", this.user);
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${res?.data?.accessToken}` });
        // const bodyCek = { projectId: this.config.data.e.app_id, voucher: voucher };
        const bodyCek = { projectId: this.config.data.e.app_id, voucher: bodyForm.voucher, 
          paymentMethod: bodyForm.paymentMethod, paymentPackage: bodyForm.paymentPackage };
        this.payApp(bodyCek, this.headers);
      },
      (err: any) => {
        this.errResponse(err,event,bodyForm)
      });
  }

  errResponse(err: any, event: any, bodyForm: any) {
    if (err?.status >= 400 && err?.status < 500) {
      if (err?.status == 401) {
        if (this.countGetToken < 3) {
          setTimeout(() => {
            this.refreshToken(event, bodyForm);
          }, 2000);
          this.countGetToken++;
        } else {
          this.toastNotificationService.showNotification('error', 'Invalid Token!');
          this.globalService.changeBlockui(false);
        }
      } else if (err?.status == 403) {
        this.globalService.changeBlockui(false);
        this.err403();
      } else {
        this.toastNotificationService.showNotification(err?.error?.status, err?.error?.message);
        this.globalService.changeBlockui(false);
      }
    } else {
      this.toastNotificationService.showNotification(err?.error?.status, err?.error?.message);
      this.globalService.changeBlockui(false);
    }
  }

  async err403() {
    this.confirmationTemplate = {
      header: "Confirmation Session Expired !",
      content: "Click YES to logout and log back in to re-new your session",
      rejectButtonStyleClass: "p-button-secondary",
      dismissableMask: true
    };
    this.cs.confirm({
      message: this.confirmationTemplate.content,
      header: this.confirmationTemplate.header,
      rejectButtonStyleClass: this.confirmationTemplate.rejectButtonStyleClass,
      dismissableMask: this.confirmationTemplate.dismissableMask,
      accept: () => {
        setTimeout(() => {
          localStorage.removeItem('user_profile');
        }, 1000);
        this.authService.clearAuthToken();
        this.authService.SignOutFire();
      },
      reject: () => {
        // 
      }
    });
  }

  actPay(event: any, action: any) {
    const url = event?.redirect_url;
    if (action == 'pay') {
      // this.headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${this.user?.accessToken}` });
      // const body = { projectId: this.config.data.e.app_id, voucher: event?.voucher };
      // this.payApp(body, 'actPay')

      // (window as any).snap.pay(event?.token);
      this.myWindow = this.globalService.windowsOpen(url, '_blank', 500, 500);
      this.showInvoice = false;
      this.ref.close(); 
    } else {
      if (this.myWindow)
        this.myWindow.close();
      this.showInvoice = false;
      this.ref.close();
    }
  }

  cekPromo(promo: any){
    const body: any = [];
    let stPromo = false;
    // console.log(this.promoActive)
    for (let i=0; i<this.promoActive.length; i++) {
      if (this.promoActive[i].voucher == promo) stPromo = true;  
    }
    if (!stPromo) {
      this.initInvoice();
      this.toastNotificationService.showNotification('error', 'Voucher Not Found Or Already Expired!');
    } else {
        body.push({propertyName: 'voucher', value: promo, matchMode: 'equals'});
        this.globalCrudService.getCollectionsWhere1('/config/price/app_event', body).subscribe((resp: any) => {
          this.initPromo(resp[0], 'cekpromo');
        });
    }
  }

  initPromo(resp: any, act?: any) {
    // console.log(resp)
    // if (act != 'cekpromo') {
      this.app_price = resp.app_price;
      this.setValuePricePerMonth(resp);
    // }
    this.max_day = resp.app_max_num_days;
    this.app_discount = resp.app_discount;
    this.realPay = resp;
  }

  setValuePricePerMonth(resp:any) {
    if (resp.order_no == 4) this.app_priceInFront = resp.app_price/12; 
    if (resp.order_no == 3) this.app_priceInFront = resp.app_price/6; 
    if (resp.order_no == 2) this.app_priceInFront = resp.app_price/3; 
    if (resp.order_no == 1) this.app_priceInFront = resp.app_price; 
    this.paymentDesc = this.translate.instant('table.upgrade.per_month_cancel')
  }

  exportPdf(): void {
    const source: any = window.document.getElementById("myinvoice");
    html2canvas(source, { allowTaint: false, useCORS: true }).then(canvas => {
      // Few necessary setting options
      const contentDataURL = canvas.toDataURL('image/png')
      const pdf = new jsPDF('l', 'mm', 'a4'); // A4 size page of PDF
      const width = pdf.internal.pageSize.getWidth();
      const height = canvas.height * width / canvas.width;
      pdf.addImage(contentDataURL, 'PNG', 0, 0, width, height)
      pdf.save(`${this.invForm.order_id}.pdf`); // Generated PDF
    });
  }

  print(): void {
    const source: any = window.document.getElementById("myinvoice");
    html2canvas(source, { allowTaint: false, useCORS: true }).then(canvas => {
      // Few necessary setting options
      const contentDataURL = canvas.toDataURL('image/png')
      const pdf = new jsPDF('l', 'mm', 'a4'); // A4 size page of PDF
      const width = pdf.internal.pageSize.getWidth();
      const height = canvas.height * width / canvas.width;
      pdf.addImage(contentDataURL, 'PNG', 0, 0, width, height)
      // pdf.output("dataurlnewwindow");
      const reportpdf = btoa(pdf.output());
      if (reportpdf) {
        const blobURL = URL.createObjectURL(this.pdfBlobConversion(reportpdf, 'application/pdf'));
        window.open(blobURL);
      }
    });
  }

  pdfBlobConversion(b64Data: string, contentType: string) {
    contentType = contentType || '';
    const sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset = offset + sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  chosePacket(event: any) {
    const packet = this.paymentPackage.filter((x:any) => x.id == event.value );
    this.initPromo(packet[0])
  }

}