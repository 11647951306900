<div class="grid" *ngIf="!showInputTableName && !isGoogleFiles">
    <div class="col-12 mb-2">
        <p class="text-center">{{'editor.add.table.description' | translate}}</p>
    </div>
</div>
<div class="grid text-center justify-content-center p-5" *ngIf="!showInputTableName && !isGoogleFiles">
    <div class="col-auto center flex-column mx-4">
        <a href="javascript:;" (click)="googleSheets()">
        <img src="/assets/images/icon/icon_g-sheet.svg" height="80px" class="py-3"/>
        <p class="text-grey">Google Sheets</p>
        </a>
    </div>
    <div class="col-auto center flex-column mx-4">
        <a href="javascript:;" (click)="showInputTableName=true">
            <img src="/assets/images/icon/icon_blank-table.svg" height="80px" class="py-3"/>
            <p class="text-grey">{{'editor.add.table.blank' | translate}}</p>
        </a>
    </div>
</div>

<div class="grid" *ngIf="showInputTableName && !isGoogleFiles">
    <div class="col-12">
        <p class="">{{'editor.add.table.name' | translate}}</p>
        <input type="text" pInputText class="w-100 mb-3" placeholder="{{'editor.add.table.name' | translate}}" [(ngModel)]="fields.tableName"> 
    </div>
</div>
<div class="grid" *ngIf="showInputTableName  && !isGoogleFiles">
    <div class="col-12 text-right">
        <button (click)="cancelDialog()" pButton type="button" label="{{'project.popup.cancel' | translate}}" icon="" iconPos="right" class="p-button-secondary mr-2 mb-2"></button>
        <button (click)="doItDialog()" pButton type="button" label="{{'button.create' | translate}}" icon="pi pi-plus"iconPos="right" class="p-button-primary mb-2"></button>
    </div>
</div>

<div class="grid block p-5" *ngIf="isGoogleFiles">
    <div class="col-12 mb-2">
        <p class="text-center">{{'choose_data' | translate}} Google Sheets</p>
    </div>
    <ui-fibr-grid [isGoogleFiles]="isGoogleFiles" [isMockData]="false" [gridModel]="gridModel" (dataSelect)="googleFilesFetch($event)"></ui-fibr-grid>
</div>
