import { Component, Input, OnInit } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/angular';

@Component({
  selector: 'ui-fibr-full-calendar',
  templateUrl: './full-calendar.component.html',
  styleUrls: ['./full-calendar.component.scss']
})
export class FullCalendarComponent implements OnInit {

  @Input() options:CalendarOptions  = {
    headerToolbar: {
      left: 'prev,next',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    },
    buttonText:{
      today:    'Today',
      month:    'Month',
      week:     'Week',
      day:      'Day',
    },
    slotMinTime: '08:00',
    slotMaxTime: '18:00',
    initialView: 'dayGridMonth',
    events: 'https://fullcalendar.io/api/demo-feeds/events.json',
    height: 'auto', // will activate stickyHeaderDates automatically
    editable: true,
    selectable:true,
    expandRows: true,
    nowIndicator: true,
    navLinks: true, // can click day/week names to navigate views
    selectMirror: true,
    dayMaxEvents: true,
  };

  constructor() { 
    console.log();
  }

  ngOnInit(): void {
    console.log();
  }

}
