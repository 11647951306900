/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable, NgZone } from '@angular/core';
import { Authenticate, Register, ResetAuthenticate } from '../../models/authenticate';
import { HttpClient, HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { User, UserInfoFire, addUserInfo } from '../../models/user-info';
import { Router } from '@angular/router';
import { AngularFireAuth, } from "@angular/fire/auth";
import * as firebaseapp from "firebase/app";
import { Confirmation, GlobalCrudPromisesService, GlobalcrudService, GlobalService, ToastNotificationService } from '@core-fibr/shared';
import { GoogleApiService, GoogleAuthService } from "ng-gapi";

import GoogleUser = gapi.auth2.GoogleUser;
import { environment } from 'libs/auth/src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public static readonly SESSION_STORAGE_KEY: string = "accessToken";
  public static readonly SESSION_STORAGE_KEY_GOOGLE_SHEET: string = "accessTokenGoogleSheets";
  user: User | undefined;
  userInfo: UserInfoFire | undefined;
  isAuthenticated: boolean | undefined;
  userGoogle: GoogleUser | undefined;
  dataQuota: any = {};
  env: any;
  userProfile: any;
  confirmationTemplate: Confirmation | undefined;
  myWindow: any;
  userProject: any;

  constructor(private httpClient: HttpClient, private router: Router,
    private authFire: AngularFireAuth, private notificationService: ToastNotificationService,
    private globalCrudService: GlobalcrudService, private googleAuthService: GoogleAuthService,
    private googleApiService: GoogleApiService, private globalCrudPromiseService: GlobalCrudPromisesService,
    private globalService: GlobalService,
    public cs: ConfirmationService,
    private ngZone: NgZone) {
    this.env = this.globalService.decryptEnv(environment);
    this.userProfile = this.globalService.getCurrReffDataParsed('user_profile');
    this.userProject = this.globalService.getCurrReffDataParsed('user_project');
  }

  get userAccessToken() {
    return this.userProfile?.accessToken;
  }

  get userProjectToken() {
    return this.userProject?.accessToken;
  }

  refreshToken(): Observable<any> {
    const rfToken = this.userProfile?.refreshToken;
    const uid = this.userProfile?.uid;
    let endpoint = `${environment.be_dev}/api/v1/fibr-app/refreshtoken`;

    if (!rfToken) {
      return throwError('Refresh token not found');
    }

    const payload = {
      uid: uid,
      refresh_token: rfToken
    };

    return this.globalService.methodPost(endpoint, payload)
      .pipe(
        tap((response: any) => {
          const newAccessToken = response.data.accessToken;
          this.userProfile.accessToken = newAccessToken;

          const newRefreshToken = response.data.refreshToken;
          if (newRefreshToken) {
            this.userProfile.refreshToken = newRefreshToken;
          }
        })
      );

  }

  refreshTokenProject(): Observable<any> {
    const rfToken = this.userProject?.refreshToken;
    const uid = this.userProject?.uid;
    const endpoint = `${environment.be_dev}/api/v1/fibr-app/refreshtoken`;

    if (!rfToken) {
      return throwError('Refresh token not found');
    }

    const payload = {
      uid: uid,
      refresh_token: rfToken
    };

    return this.globalService.methodPost(endpoint, payload)
      .pipe(
        tap((response: any) => {
          const newAccessToken = response.data.accessToken;
          this.userProject.accessToken = newAccessToken;

          const newRefreshToken = response.data.refreshToken;
          if (newRefreshToken) {
            this.userProject.refreshToken = newRefreshToken;
          }
        })
      );
  }

  handle403() {
    this.confirmationTemplate = {
      header: "Confirmation Session Expired !",
      content: "Click YES to logout and log back in to re-new your session",
      rejectButtonStyleClass: "p-button-secondary",
      dismissableMask: true
    };
    this.cs.confirm({
      message: this.confirmationTemplate.content,
      header: this.confirmationTemplate.header,
      rejectButtonStyleClass: this.confirmationTemplate.rejectButtonStyleClass,
      dismissableMask: this.confirmationTemplate.dismissableMask,
      accept: () => {
        setTimeout(() => {
          localStorage.removeItem('user_profile');
        }, 1000);
        this.clearAuthToken();
        this.SignOutFire();
      },
      reject: () => { }
    });
  }

  handle403Project() {
    this.confirmationTemplate = {
      header: "Confirmation Session Expired !",
      content: "Click YES to logout and log back in to re-new your session",
      rejectButtonStyleClass: "p-button-secondary",
      dismissableMask: true
    };
    this.cs.confirm({
      message: this.confirmationTemplate.content,
      header: this.confirmationTemplate.header,
      rejectButtonStyleClass: this.confirmationTemplate.rejectButtonStyleClass,
      dismissableMask: this.confirmationTemplate.dismissableMask,
      accept: () => {
        setTimeout(() => {
          localStorage.removeItem('user_project');
        }, 1000);
      },
      reject: () => { }
    });
  }

  handle417() {
    const uid = this.userProfile?.uid;
    const url = `${environment.be_dev}/api/v1/fibr-app/projects/google/login?userId=${uid}`;

    this.myWindow = this.globalService.windowsOpen(url, '_blank', 500, 500);
  }

  get isAuthenticatedValue(): boolean {
    return this.isAuthenticated!;
  }

  set isAuthenticatedValue(val: boolean) {
    this.isAuthenticated = val;
  }

  login(authenticate: Authenticate) {
    return this.httpClient
      .post('api/login', authenticate)
      .pipe(
        tap((user: any) => {
          this.isAuthenticated = true;
          this.user = user;
          this.setAuthToken(user.token);
          this.isAuthenticated = (true);
        })
      );
  }

  async registerFireEmailandPassword(authenticate: Register) {
    return this.authFire.createUserWithEmailAndPassword(authenticate.email!, authenticate.password!)
      .catch(error => {
        console.log(error);
        this.notificationService.showNotification('error', error.message);
        return;
      })
      .then((res: any) => {
        // console.log(res)
        const user: any = res?.user!;
        if (user) {
          const projectNames: any = (authenticate.email)?.split('.').join('').split('@');
          let projectName;
          if (projectNames[0].length > 21) {
            projectName = `${environment.app_name}-` + projectNames[0].toLowerCase().substring(0, 21);
          } else {
            projectName = `${environment.app_name}-` + projectNames[0].toLowerCase();
          }

          if (environment.production) projectName = projectName.replace(environment.app_name, environment.app_name + '-app')
          const userInfoAddFire: addUserInfo = {
            partner_name: authenticate.fullName,
            uid: user?.uid,
            photoURL: "",
            displayName: authenticate.fullName,
            fullName: authenticate.fullName,
            email: authenticate.email,
            phoneNumber: "",
            // password: authenticate.password,
            password: "*******",
            GCPInstance: projectName,
            firebaseProject: projectName,
            webAppProjectFirebase: projectName,
            firebase: {},
            role_id: environment.role,
            role: environment?.role_id,
            appId: "",
            // quota: 0,
            created_date: new Date(),
            last_login: null,
            questionUser: false
          }
          const params = new HttpParams()
            .set('projectName', projectName)
            .set('userId', user?.uid)
            .set('email', authenticate.email ?? '')
            .set('fullName', authenticate.fullName ?? '')
          this.httpClient.get(`/api/creategcpfireweb`, { params: params })
            .toPromise()
            .then((res: any) => {
              // console.log('res: ', res);
              userInfoAddFire.firebase = res['data']['web'];
              userInfoAddFire.GCPInstance = res['data']['create_gcp']['name'];
              userInfoAddFire.firebaseProject = res['data']['create_firebase']['name'];
              userInfoAddFire.appId = res['data']['appId'];
              userInfoAddFire.activated = false;
              this.globalCrudService.getcollectionwithid('config', 'build').subscribe((resp: any) => {
                userInfoAddFire.quota = resp.free;
                this.addNewUserToFireBase(userInfoAddFire, user?.uid + '/profile/data', 'register by email and password');
              });
            });
          return user;
        }
      })
  }

  async registerFireEmailandPasswordAdditional(authenticate: Register) {
    return this.authFire.createUserWithEmailAndPassword(authenticate.email!, authenticate.password!)
      .catch(error => {
        console.log(error);
        this.notificationService.showNotification('error', error.message);
        return;
      })
      .then((res: any) => {
        const user: any = res?.user!;
        if (user) {
          this.globalCrudService.getcollectionwithid('config', 'build').subscribe((resp: any) => {
            this.dataQuota = resp;
          });
          const userInfoAddFire: addUserInfo = {
            partner_name: authenticate.fullName,
            uid: user?.uid,
            photoURL: "",
            displayName: authenticate.fullName,
            fullName: authenticate.fullName,
            email: authenticate.email,
            phoneNumber: "",
            // password: authenticate.password,
            password: "*******",
            GCPInstance: 'projectName',
            firebaseProject: 'projectName',
            webAppProjectFirebase: 'projectName',
            firebase: {},
            role_id: environment.role_id,
            role: environment?.role,
            activated: true,
            // quota: 0,
            created_date: new Date(),
            last_login: null,
            questionUser: false
          }
          if (environment.app_name == 'fibr') {
            userInfoAddFire.app_name = environment.app_name;
            // userInfoAddFire.firebase = environment.firebase;
            // userInfoAddFire.GCPInstance = environment.firebase.projectId;
            // userInfoAddFire.firebaseProject = environment.firebase.projectId;
            // userInfoAddFire.appId = environment.firebase.appId;
            userInfoAddFire.firebase = this.env.firebase;
            userInfoAddFire.GCPInstance = this.env.firebase.projectId;
            userInfoAddFire.firebaseProject = this.env.firebase.projectId;
            userInfoAddFire.appId = this.env.firebase.appId;
            userInfoAddFire.activated = false;
            this.globalCrudService.getcollectionwithid('config', 'build').subscribe((resp: any) => {
              userInfoAddFire.quota = resp.free;
              this.addNewUserToFireBase(userInfoAddFire, user?.uid + '/profile/data', 'register by email and password');
            });
          } else {
            this.addNewUserToFireBase(userInfoAddFire, user?.uid + '/profile/data', 'register by email and password');
          }
          return user;
        }
      })
  }

  async addNewUserToFireBase(authenticate: addUserInfo, docname: string, type: string) {
    const split = docname.split('/');
    // this.globalCrudService.createcollectionwithdocumentname({type : type}, 'users', split[0]);
    // this.globalCrudService.createcollectionwithdocumentname(authenticate, 'users', docname);
    await this.globalCrudPromiseService.a_createcollectionwithdocumentname({ type: type }, 'users', split[0]);
    await this.globalCrudPromiseService.a_createcollectionwithdocumentname(authenticate, 'users', docname);
  }

  loginFireEmailandPassword(authenticate: Authenticate) {
    return this.authFire.signInWithEmailAndPassword(authenticate.email!, authenticate.password!)
      .catch(error => {
        // console.log(error);
        this.notificationService.showNotification('error', error.message);
      })
      .then((res: any) => {
        const user: any = res?.user!;
        // console.log('user: ', user);
        this.userInfo = {
          uid: user?.uid,
          displayName: user?.displayName!,
          email: user?.email!,
          phoneNumber: user?.phoneNumber!,
          photoURL: user?.photoURL!,
          token: user?.refreshToken,
          refreshToken: user?.refreshToken,
          isExists: true
        }
        this.isAuthenticatedValue = true;
        this.setAuthToken(user?.refreshToken!);
        return this.userInfo;
      })

  }

  signInWithPopup() {
    const provider = new firebaseapp.default.auth.GoogleAuthProvider();
    return this.authFire.signInWithPopup(provider)
      .then(async (res) => {
        this.globalService.setStatusLogin(true);
        const user: any = res?.user!;
        let isExists = false;
        await this.globalCrudPromiseService.get_id_collection('/users').then(async (datas: any) => {
          datas.docs.map((doc: any) => {
            if (doc.id == user?.uid) isExists = true;
          });
          this.userInfo = {
            uid: user?.uid,
            displayName: user?.displayName!,
            email: user?.email!,
            phoneNumber: user?.phoneNumber!,
            photoURL: user?.photoURL!,
            token: user?.refreshToken,
            refreshToken: user?.refreshToken,
            isExists: isExists
          };
          // if(!isExists){
          //   const projectNames:any = (user?.email)?.split('.').join('').split('@');
          //   let projectName = `${environment.app_name}-` + projectNames[0].toLowerCase();
          //   if(environment.production) projectName = projectName.replace(environment.app_name, environment.app_name + '-app')
          //   const userInfoAddFire : addUserInfo = {
          //     partner_name: user?.displayName,
          //     uid : user?.uid,
          //     photoURL: "",
          //     displayName: user?.displayName,
          //     fullName: user?.displayName,
          //     email: user?.email,
          //     phoneNumber : "",
          //     password: '*******',
          //     GCPInstance : projectName,
          //     firebaseProject: projectName,
          //     webAppProjectFirebase: projectName,
          //     firebase: {},
          //     role_id: environment.role,
          //     role: environment?.role_id,
          //     appId : "",
          //     created_date: new Date(),
          //     last_login: null,
          //     activated: true,
          //     questionUser: false
          //   }

          //   if(environment.app_name == 'fibr'){
          //     userInfoAddFire.app_name = environment.app_name;
          //     userInfoAddFire.firebase = environment.firebase;
          //     userInfoAddFire.GCPInstance = environment.firebase.projectId;
          //     userInfoAddFire.firebaseProject = environment.firebase.projectId;
          //     userInfoAddFire.appId = environment.firebase.appId;
          //     userInfoAddFire.activated = false;
          //     this.globalCrudService.getcollectionwithid('config', 'build').subscribe((resp: any)=>{
          //       this.dataQuota = resp;
          //       userInfoAddFire.quota = this.dataQuota?.free;
          //       this.addNewUserToFireBase(userInfoAddFire, user?.uid + '/profile/data', 'register by google');
          //     });
          //     // this.addNewUserToFireBase(userInfoAddFire, user?.uid + '/profile/data');
          //   }else{
          //     const params = new HttpParams()
          //       .set('projectName', projectName)
          //       .set('userId', user?.uid)
          //     await this.httpClient.get(`/api/creategcpfireweb`, {params: params})
          //     .toPromise()
          //     .then((res: any) => {
          //       userInfoAddFire.firebase = res['data']['web'];
          //       userInfoAddFire.GCPInstance = res['data']['create_gcp']['name'];
          //       userInfoAddFire.firebaseProject = res['data']['create_firebase']['name'];
          //       userInfoAddFire.appId = res['data']['appId'];
          //       this.addNewUserToFireBase(userInfoAddFire, user?.uid + '/profile/data', 'register by google');
          //     });
          //   }
          // }
        });

        console.log("user?.displayName", user?.displayName!)
        console.log("user?.email", user?.email!)

        try {
          //dev
          mixpanel.init("9ca962da62d0f86a324432b2423d58ba", { debug: true, track_pageview: true, persistence: 'localStorage' });

          //prod
          // mixpanel.init("6d431289594ff534ccd663c3ca8ce3c5", { debug: true, track_pageview: true, persistence: 'localStorage' });
          await mixpanel.identify(user?.uid);
          mixpanel.people.set({ '$name': user?.displayName!, '$email': user?.email!, 'User Detail Info': user });
          mixpanel.track('Sign Up', {
            "distinct_id": user?.uid
          });

          console.info("mix panel sucess");
        } catch (error) {
          console.log("error mix panel", error)
        }

        this.signInSuccessHandler(res);
        return this.userInfo;
      }).catch((error) => {
        this.globalService.setStatusLogin(false);
        // this.notificationService.showNotification('error', error.message);
      });

  }

  signInWithApple() {
    const provider = new firebaseapp.default.auth.OAuthProvider('apple.com');
    return this.authFire.signInWithPopup(provider).then((res) => {
      const user: any = res?.user!;
      this.userInfo = {
        uid: user?.uid,
        displayName: user?.displayName!,
        email: user?.email!,
        phoneNumber: user?.phoneNumber!,
        photoURL: user?.photoURL!,
        token: user?.refreshToken,
        refreshToken: user?.refreshToken,
        isExists: true
      };
      this.signInSuccessHandler(res);
      return this.userInfo;
    }).catch((error) => {
      this.notificationService.showNotification('error', error.message);
    });

  }

  signInWithMicrosoft() {
    const provider = new firebaseapp.default.auth.OAuthProvider('microsoft.com');
    return this.authFire.signInWithPopup(provider).then((res) => {
      const user: any = res?.user!;
      this.userInfo = {
        uid: user?.uid,
        displayName: user?.displayName!,
        email: user?.email!,
        phoneNumber: user?.phoneNumber!,
        photoURL: user?.photoURL!,
        token: user?.refreshToken,
        refreshToken: user?.refreshToken,
        isExists: true
      };
      this.signInSuccessHandler(res);
      return this.userInfo;
    }).catch((error) => {
      this.notificationService.showNotification('error', error.message);
    });

  }

  SignOutFire() {
    return this.authFire.signOut().then(() => {
      sessionStorage.clear();
      localStorage.clear();
      gapi.auth2.getAuthInstance().disconnect();
      this.router.navigate([`/auth/login`]);
      location.reload();
    })
  }

  public getTokenGoogleSheet(): string {
    const token: string | null = sessionStorage.getItem(AuthenticationService.SESSION_STORAGE_KEY_GOOGLE_SHEET);
    if (!token) {
      // throw new Error("no token set , authentication required");
      return ''
    }
    return sessionStorage.getItem(AuthenticationService.SESSION_STORAGE_KEY_GOOGLE_SHEET)!;
  }

  public signInGoogleSheets() {
    return this.googleAuthService.getAuth()
      .subscribe((auth) => {
        return auth.signIn().then(res => this.signInSuccessHandler(res));
      });
  }

  private signInSuccessHandler(res: any) {
    this.ngZone.run(() => {
      sessionStorage.setItem(
        AuthenticationService.SESSION_STORAGE_KEY, JSON.stringify(res.credential.accessToken)
      );
    });
  }

  public signInSuccessGoogleSheetsHandler(res: any) {
    this.ngZone.run(() => {
      sessionStorage.setItem(
        AuthenticationService.SESSION_STORAGE_KEY_GOOGLE_SHEET, res.getAuthResponse().access_token
      );
    });
  }

  private signInErrorHandler(err: any) {
    console.warn(err);
  }

  setAuthToken(token: string) {
    localStorage.setItem('token', token);
  }

  getAuthToken(): string | null {
    return localStorage.getItem('token');
  }

  clearAuthToken() {
    this.isAuthenticated = (false);
    localStorage.removeItem('token');
    // this.router.navigateByUrl('/auth/login');
  }

  async registerExistingFireBase(authenticate: Register, role: any, dataUsers: any) {
    return this.authFire.createUserWithEmailAndPassword(authenticate.email!, authenticate.password!)
      .catch(error => {
        console.log(error);
        this.notificationService.showNotification('error', error.message);
        return;
      })
      .then((res: any) => {
        const user: any = res?.user!;
        if (user) {
          const userInfoAddFire: addUserInfo = {
            partner_name: authenticate.fullName,
            uid: user?.uid,
            photoURL: "",
            displayName: authenticate.fullName,
            fullName: authenticate.fullName,
            email: authenticate.email,
            phoneNumber: "",
            password: authenticate.password,
            GCPInstance: dataUsers.GCPInstance,
            firebaseProject: dataUsers.firebaseProject,
            webAppProjectFirebase: dataUsers.webAppProjectFirebase,
            firebase: dataUsers.firebase,
            role_id: role.role_id,
            role: role?.role,
            appId: dataUsers.appId,
            additional_profile_id: role.docId,
            additional_profile_collection: role.collectionName,
            partner_id: role.partner_id,
            activated: true
          }
          // console.log('userInfoAddFire: ', userInfoAddFire);

          this.addNewUserToFireBase(userInfoAddFire, user?.uid + '/profile/data', 'register by email and password');
          return user;
        }
      })
  }

  async updatePasswordFirebaseWithCode(password: string, code: string) {
    this.authFire
      .confirmPasswordReset(code, password!)
      .then(() => {
        this.router.navigate(['auth/login']);
      })
      .catch(err => {
        this.notificationService.showNotification('error', err.code)
      });
  }

  async updatePasswordFirebaseWithNewCredentials(authenticate: ResetAuthenticate, uid: string) {
    const cpUser = firebaseapp.default.auth().currentUser;
    const credentials = firebaseapp.default.auth.EmailAuthProvider.credential(
      cpUser?.email!, authenticate.old_password!);

    return cpUser?.reauthenticateWithCredential(credentials)
      .then(async () => {
        return cpUser.updatePassword(authenticate.new_password!)
          .then(async () => {
            await this.globalCrudService.modifyDataRefCollection({ password: authenticate.new_password }, `/users/${uid}/profile`, 'data')
            await this.clearAuthToken();
            await this.SignOutFire();
            await localStorage.removeItem('user_profile');
            this.router.navigate(['auth/login']);
            return;
          }).catch((error) => {
            this.notificationService.showNotification('error', ' Update ' + error.code);
            return;
          });
      })
      .catch(err => {
        this.notificationService.showNotification('error', 'Verify ' + err.code);
        return;
      });

  }

  async sendLinkForgotPassword(userEmail: string) {
    return this.authFire
      .sendPasswordResetEmail(userEmail)
      .then(() => {
        this.notificationService.showNotification("success", "Link has been sent to your email!");
        this.router.navigate(['auth/login']);
      })
      .catch(err => {
        // console.log(err);
        this.notificationService.showNotification('error', err.code)
      });
  }

  async resetPasswordUserGoogleClick(password: any) {
    const cpUser: any = firebaseapp.default.auth().currentUser;
    return cpUser.updatePassword(password)
      .then(async () => {
        this.notificationService.showNotification('success', ' Reset Password Success');
        return true;
      }).catch((error: any) => {
        // console.log(error)
        this.notificationService.showNotification('error', ' Reset Password ' + error.message);
        return false;
      });
  }

}
