import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import { AngularFireModule } from "@angular/fire";
import {
  GoogleApiModule,
  NgGapiClientConfig, 
  NG_GAPI_CONFIG
} from "ng-gapi";

import { PanelModule } from 'primeng/panel';
import { MessagesModule } from 'primeng/messages';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import {BlockUIModule} from 'primeng/blockui';

import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { environment } from '../environments/environment';
import { RegisterComponent } from './components/register/register.component';
import { AddFaskesComponent } from './components/add-faskes/add-faskes.component';
import { CaptchaModule } from './components/captcha/captcha.module';
import { SendEmailForgotPasswordComponent } from './components/send-email-forgot-password/send-email-forgot-password.component';
import { InputForgotPasswordComponent } from './components/input-forgot-password/input-forgot-password.component';
import { LoginAdminComponent } from './components/login-admin/login-admin.component';
import { BlockuiLoginComponent } from './components/blockui-login/blockui-login.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const gapiClientConfig: NgGapiClientConfig = environment.googleapis;
let firebase: any = {};
if (environment.status == 'dev') {
  firebase = {
    apiKey: "AIzaSyA_DkX0qh3cFAFhmTOlznL7qx4zAusUwi8",
    authDomain: "dev-fibr.firebaseapp.com",
    projectId: "dev-fibr",
    storageBucket: "dev-fibr.appspot.com",
    messagingSenderId: "308692921820",
    appId: "1:308692921820:web:201e323569808aca7ab319",
    measurementId: "G-00R75TSJKP"
  }
} else {
  firebase ={
    apiKey: "AIzaSyD5lVZr-aAoLD1gxRPFPQ0GTYrENsxLIYM",
    authDomain: "prod-fibr.firebaseapp.com",
    projectId: "prod-fibr",
    storageBucket: "prod-fibr.appspot.com",
    messagingSenderId: "1026420756455",
    appId: "1:1026420756455:web:4e23b6e269a2161247f742",
    measurementId: "G-FVSH7DNKT1"
  }
}

@NgModule({
  imports: [ CommonModule, FormsModule, RouterModule,BlockUIModule,
    PanelModule, MessagesModule, ButtonModule, CheckboxModule, DropdownModule, ProgressSpinnerModule, CaptchaModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    // AngularFireModule.initializeApp(environment.firebase),
    AngularFireModule.initializeApp(firebase),
    GoogleApiModule.forRoot({
      provide: NG_GAPI_CONFIG,
      useValue: gapiClientConfig
    })
  ],
  declarations: [
    LoginComponent,
    AuthCallbackComponent,
    RegisterComponent,
    AddFaskesComponent,
    SendEmailForgotPasswordComponent,
    InputForgotPasswordComponent,
    LoginAdminComponent,
    BlockuiLoginComponent,
  ],
  exports:[BlockUIModule]
})
export class AuthModule {}
