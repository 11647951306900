import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberString'
})
export class NumberStringPipe implements PipeTransform {

  transform(value: any): any {
    let dt:any
    if (value != null) {
      dt = value.toString();
    } else {
      dt = ''
    }
    return dt;
  }

}
