<form #f="ngForm" style="background-image: url('/assets/images/img_splash-default.svg'); background-size:cover; background-position:center; background-repeat:no-repeat;">
  <div class="splash-screen bg-app" style="background-image: url('/assets/images/img_splash-default.svg'); background-size:cover; background-position:center; background-repeat:no-repeat;">
    <div class="grid justify-content-center">
        <div class="col-auto splash-powered" *ngIf="!appConfig.is_paid">
            <p>Powered by</p>
            <a href="https://appfibr.io/"><img src="/assets/logo_appfibr.svg" alt="AppFibr Logo"/></a>
        </div>
    </div>
  </div>

  <div  class="mobile-comp mobile-form">
    <div class="p-grid p-fluid">
      <!-- <img class="img-app" src="/assets/logo_appfibr_vertical.svg" alt="AppFibr Logo"/> -->
      <img class="img-app" [src]="logo" alt="AppFibr Logo"/>
      <div class="field" *ngFor="let item of elementForm">
        <ng-container *ngIf="item.field === 'token'">
            <span class="relative">
              <p class="form-label">{{item.label}}</p>
              <input [name]="item.field" type="text" pInputText [(ngModel)]="item.value">
          </span>
        </ng-container>
      </div>
      <div class="grid">
          <div class="col-12 p-0">
              <button pButton pRipple class="p-button p-component w-100" (click)="onClickActivateEmail(f)">Activate Email</button>
          </div>
          <div class="col-12 mt-2 z-1">
            <a href="javascript:;" (click)="onClickLogin()" pbutton="" type="button" label="Login" tabindex="0" class="p-button-text mt-3">
              <span class="p-button-label center">Back to Login</span>
            </a>
          </div>
      </div>
    </div>
  </div>
</form>