/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { QRCodeComponent, QRCodeModule } from 'angularx-qrcode';
import { DynamicdialogService } from '../../../services/dynamicdialog.service';
// import { CreatenewComponent } from '../../dynamicdialogs/createnew/createnew.component';
import { 
  CreatenewComponent, CreatenewBlankComponent, CreatenewOptionComponent, ShowMockupService, ToastNotificationService, GlobalcrudService,
  NotifProComponent , InAppPaymentApprovalComponent
} from "@core-fibr/shared";
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { GlobalService } from '../../../services/global.service';
import { appConfig } from '../../../interfaces/global';
import { MenuItem } from 'primeng/api';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from '@core-fibr/auth';
import { Subscription } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ui-fibr-right-action-button',
  templateUrl: './right-action-button.component.html',
  styleUrls: ['./right-action-button.component.scss']
})
export class RightActionButtonComponent implements OnInit {
  items: MenuItem[] = [];
  itemsPro: MenuItem[] = [];
  user: any = {};

  @Input() loading:boolean = false;
  @Input() hideButtonCreate:boolean = false;
  @Input() appConfig: appConfig = {};
  @Input() dataSource: any = {};
  @Input() isCreateNew: any;
  
  @Output() properties: EventEmitter<any> = new EventEmitter<any>();
  @Output() save: EventEmitter<any> = new EventEmitter<any>();
  @Output() publish: EventEmitter<any> = new EventEmitter<any>();
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventOnchange: EventEmitter<any> = new EventEmitter<any>();
  @Output() publishapp: EventEmitter<any> = new EventEmitter<any>();
  @Output() copylink: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadqr: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkpackage: EventEmitter<any> = new EventEmitter<any>();

  selectedDataSource: any;
  showMockup = false;
  disabled: boolean = true;
  linkApp!: string ;
  versionApp!: string | undefined;
  nameApp!: string | undefined;
  descApp!: string | undefined;
  logoApp!: string | undefined;
  subscription: Subscription | undefined;
  buttonSave = false;
  pageProperties = true;
  settingApp = true;
  exportOptions: any[] = [];
  disabledPackage: boolean = false;
  disabledReqApp: boolean = false;
  dialogPublishTabIndex = 0;
  headers: any = "";

  constructor(
    private dynamicDialogService : DynamicdialogService, 
    private globalService: GlobalService, 
    private toastNotificationService: ToastNotificationService,
    public createNewConfig: DynamicDialogConfig,
    private globalServiceCrud: GlobalcrudService,
    private showMockupService: ShowMockupService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    ) { 
      this.subscription = showMockupService.hiddenAct$.subscribe(
        display => {
          this.buttonSave = display;
      });

      this.route.queryParams.subscribe((params) => {
        if (params?.notification_action === 'display_dialog_publish') {
          this.showMockup = true;
          this.dialogPublishTabIndex = 1;
        }
      })
    }
  @Input() isApptEdit: boolean = false;
  
  ngOnInit(): void {
    this.user = this.globalService.getCurrReffDataParsed("user_profile");
    this.headers  = new HttpHeaders({ 'Content-Type':  'application/json', Authorization: `Bearer ${this.user?.accessToken}`});
    /** kondisi untuk tombol request App dev dan prod*/
    // if (this.user.firebase.storageBucket == 'dev-fibr.appspot.com' || this.user.firebase.storageBucket == 'staging-fibr.appspot.com')
    //   this.disabledReqApp = false; 
    // else 
    //   this.disabledReqApp = true;

    this.globalService.appConfig.subscribe((val: appConfig) => {
      this.linkApp = environment.domain + `/app-fibr?appid=${val.app_id}&id=${this.user.uid}`;
      this.nameApp = val.app_name;
      if (val.version == '' || val.version == '0.0.0') {
        this.versionApp = '1.0.0';
      } else {
        this.versionApp =  val.version;
      }
      
      this.descApp = val.app_desc;
      if (val.app_logo == '') {
        this.logoApp = '/assets/logo_appfibr.svg';
      } else {
        this.logoApp = val.app_logo;
      }
      this.appConfig = val;
      this.appConfig.version = this.versionApp;
      const appid: any = val.app_id;
      this.globalServiceCrud
        .getcollectionwithid(`/users/${this.user.uid}/projects`, appid)
        .subscribe((res: any) => {
          if (res?.package_name != '' && res?.is_publish == true) this.disabledPackage = true; else this.disabledPackage = false;
      });
    });
    
    this.items = [
      {
        label: 'Save', 
        icon: 'pi pi-fw pi-save',
        styleClass: "menu-blue",
        command: () => {
          this.onSave();
        }
      },
      {
          separator:true
      },
      {
        label: 'Upgrade', 
        icon: 'pi pi-fw pi-check-circle',
        styleClass: "menu-green",
        command: () => {
          this.ugradePro('upgrade')
        }
      },
      {
          separator:true
      },
      {
        label: 'Publish', 
        icon: 'pi pi-fw pi-cloud-download',
        command: () => {
          this.onPublish();
        }
      },
      {
        label: 'Properties', 
        icon: 'pi pi-fw pi-cog',
        command: () => {
          this.onProperties();
        }
      },
      // {
      //     separator:true
      // },
      // {
      //   label: 'Delete', 
      //   icon: 'pi pi-fw pi-trash',
      //   styleClass: "menu-magenta",
      //   command: () => {
      //     this.onDelete();
      //   }
      // }
    ];

    this.itemsPro = [
      {
        label: 'Save', 
        icon: 'pi pi-fw pi-save',
        styleClass: "menu-blue",
        command: () => {
          this.onSave();
        }
      },
      {
          separator:true
      },
      {
        label: '+ Extend Pro Plan', 
        icon: 'pi pi-fw pi-check-circle',
        styleClass: "menu-green",
        command: () => {
          this.ugradePro('extend')
        }
      },
      {
          separator:true
      },
      {
        label: 'Publish', 
        icon: 'pi pi-fw pi-cloud-download',
        command: () => {
          this.onPublish();
        }
      },
      {
        label: 'Properties', 
        icon: 'pi pi-fw pi-cog',
        command: () => {
          this.onProperties();
        }
      },
      // {
      //     separator:true
      // },
      // {
      //   label: 'Delete', 
      //   icon: 'pi pi-fw pi-trash',
      //   styleClass: "menu-magenta",
      //   command: () => {
      //     this.onDelete();
      //   }
      // }
    ];

    this.exportOptions = [
      { label: ".apk", value: "apk" },
      // { label: ".aab", value: "aab" }
      { label: ".aab", value: "bundle" }
    ];
  }

  createProject() {
    this.createNewConfig = {
      header: this.translate.instant("project.add.new"),
      width: "500px",
      contentStyle: { "max-height": "500px", overflow: "auto" },
      styleClass: "dialog-no-footer create-new",
      dismissableMask: true,
      baseZIndex: 10000,
      showHeader: true,
      closable: true
    };
    this.dynamicDialogService.show(CreatenewOptionComponent, this.createNewConfig)
      .onClose.subscribe((res) => {
        if (!res) return;
        switch (res.action) {
          case "googleSheets":
            this.submitNewDataGoogleSheets();
            break;
          case "newTable":
            this.submitNewTable();
            break;
        }
      });
  }

  submitNewDataGoogleSheets() {
    this.createNewConfig = {
      header: 'Create New Project',
      width: '500px',
      contentStyle: {"max-height": "500px", "overflow": "auto"},
      styleClass : 'dialog-no-footer create-new', 
      dismissableMask: true,
      baseZIndex: 10000,
      showHeader : true,
      closable : true,
      data: {
        isGoogleFiles: false
      }
    }
    this.dynamicDialogService.show(CreatenewComponent, this.createNewConfig)
    .onClose.subscribe((res: any) => {
      if(res){
        this.createNewConfig.width = '1000px';
        // this.createNewConfig.height = "30rem",
        this.createNewConfig.data = {
            isGoogleFiles: true,
            url:"./assets/json/googlefiles.json"
        }
        this.dynamicDialogService.show(CreatenewComponent, this.createNewConfig);
      }
    });
  }

  submitNewTable() {
    this.createNewConfig = {
      header: "Create New Project",
      width: "500px",
      contentStyle: { "max-height": "500px", overflow: "auto" },
      styleClass: "dialog-no-footer",
      dismissableMask: true,
      baseZIndex: 10000,
      showHeader: true,
      closable: true,
      data: {
        isGoogleFiles: false,
      },
    };
    this.dynamicDialogService.show(CreatenewBlankComponent, this.createNewConfig)
  }

  onProperties() {
    // this.properties.emit();
    const pageProperties = this.pageProperties;
    this.showMockupService.showPageProperties(pageProperties);
  }

  onPublish() {
    // this.publish.emit();
    this.showMockup = true;
  }

  onSave() {
    this.save.emit();
  }

  onDelete() {
    this.delete.emit();
  }

  onChange(){
    this.eventOnchange.emit(this.selectedDataSource);
  }

  onCopyLink() {
    this.copylink.emit({data: this.linkApp});
  }

  onOpenLink() {
    window.open(this.linkApp);
  }

  onPublishPwa() {
    // console.log(this.appConfig)
    let con: any = {};
    if (this.appConfig.config != '') {
      const config: any = this.appConfig.config
      con = JSON.parse(config);
    }
    if (this.appConfig.config == '' || con.itemPages.length < 3) {
      this.toastNotificationService.showNotification('error', "can't publish web app! because there is no Page config yet");
    } else {
      const appid: any = this.appConfig.app_id;
      this.appConfig.is_publish_pwa = true;
      this.globalService.addConfigRunning(this.appConfig, environment);
      if (!this.appConfig.publish_pwa_date) this.appConfig.publish_pwa_date = new Date();
      this.globalServiceCrud.modifyRefMergeCollection(this.appConfig,`/users/${this.user.uid}/projects`, appid)
      .then((result: any) => {
        this.globalService.changeBlockui(true);
        setTimeout(() => {
          window.open(this.linkApp);
          this.globalService.changeBlockui(false);
        }, 2000);
      });
    }
    
  }

  onDownloadQR(parent: any) {
    // console.log(parent.qrcElement.nativeElement.__ngContext__[0]);
    const downloadContents = parent.qrcElement.nativeElement.__ngContext__[0].querySelector("img").src;
    this.downloadqr.emit({qrcode: downloadContents});
  }

  onPublishApp() {
    this.publishapp.emit({data: this.appConfig});
    this.globalService.isStatusPublish.subscribe((res: boolean) =>{
      if (!res) this.showMockup = false;
    })
    
  }

  onShowMockup() {
    const body:any = {projectId: this.appConfig.app_id};
    this.globalService.methodPostOptions(environment.be_dev + `/api/v1/fibr-app/buildinstaller/checkcomponent`, body, this.headers)
    .subscribe(( res: any) => {
      if (res.code === 200){
        this.showMockup = true;
        const settingApp = this.settingApp;
        this.showMockupService.showSettingApp(settingApp);
      }
    },
    (err: any) => {
      if(err.status === 412){
        this.onShowApproval();
      }
    });
  }

  onShowApproval() {
    this.createNewConfig = {
      header: this.translate.instant('approval'),
      width: "720px",
      contentStyle: { overflow: "auto" },
      styleClass: "dialog-no-footer",
      dismissableMask: true,
      baseZIndex: 10000,
      showHeader: true,
      closable: true,
      data: {},
    };
    this.dynamicDialogService.show(InAppPaymentApprovalComponent, this.createNewConfig);
  }

  checkPackageClick() {
    this.checkpackage.emit({data: this.appConfig});
  }

  onBlur() {
    const st = this.cekString(this.appConfig, false);
    if (!st)
      this.globalService.setAppConfig(this.appConfig);
  }

  cekString(data: any, st: boolean) {
    const cekAppName = this.globalService.replaceString(data.app_name);
    const cekAppDesc = this.globalService.replaceString(data.app_desc);
    const cekAppPack = this.globalService.replacePackageName(data.package_name);
    if (data.app_name == '') {
      this.toastNotificationService.showNotification('error', 'App Name can not be empty');
      st = true;
    }
    if (cekAppName != data.app_name) {
      this.toastNotificationService.showNotification('error', 'App Name cannot contain characters ( " ; { } [ ] ! @ # $ % ^ & * ( ) = + ** )');
      st = true;
    }
    if (cekAppDesc != data.app_desc) {
      this.toastNotificationService.showNotification('error', 'App Description cannot contain characters ( " ; { } [ ] ! @ # $ % ^ & * ( ) = + ** )');
      st = true
    }
    if (cekAppPack != data.package_name) {
      this.toastNotificationService.showNotification('error', 'Package Name wrong format!');
      st = true
    }
    return st;
  }

  ugradePro(act: any) {
    const e = this.appConfig;
    this.createNewConfig = {
      // header: "Upgrade Project Plan",
      header: act == "upgrade" ? "Upgrade Project Plan" : "Extend Pro Plan",
      width: "1000px",
      contentStyle: { overflow: "auto" },
      styleClass: "dialog-no-footer create-new",
      dismissableMask: true,
      baseZIndex: 10000,
      showHeader: true,
      closable: true,
      data: {e}
    };
    this.dynamicDialogService.show(NotifProComponent, this.createNewConfig)
  }

  handleTabDialogPublishChange(event: any) {
    this.dialogPublishTabIndex = event.index;
  }

  onHideDialogPublish() {
    this.route.queryParams.subscribe((r) => {
      this.router.navigateByUrl(`/portal/project/editor?filename=${r.filename}&fileid=${r.fileid}&appid=${r.appid}`, {
        replaceUrl: true,
      })
    }).unsubscribe()
  }
}
