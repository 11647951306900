import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from 'libs/auth/src/environments/environment';
import { SessionService } from '../../services/session.service';


@Component({
  selector: 'ui-fibr-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() versionInfo: any;
  currentYear: number;
  app = environment.app_name;
  constructor(
    private translate: TranslateService,
    private sessionService: SessionService,
  ) { this.currentYear = new Date().getFullYear(); }

  ngOnInit(): void {
    this.translate.use(this.sessionService.getItem('fibr-language'));
  }

}
