import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'kiloFormater' })
export class KiloformaterPipe implements PipeTransform {
    transform(num: number, type: string = 'bulat'): string {
      if(type == 'bulat'){
        if (num >= 1e9) return (num / 1e9).toString() + 'B';
        if (num >= 1e6) return (num / 1e6).toString() + 'M';
        if (num >= 1e3) return (num / 1e3).toString() + 'K';
      }

      if(type=='decimal'){
        if (num >= 1e9) return (num / 1e9).toFixed(1) + 'B'
        if (num >= 1e6) return (num / 1e6).toFixed(1) + 'M'
        if (num >= 1e3) return (num / 1e3).toFixed(1) + 'K'
      }

      return num.toString(); 
    }
}