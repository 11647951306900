<div *ngIf="!statusPublish">
  <span  class="p-tag p-component" [ngClass]="dataProject.is_publish || dataProject.is_publish_pwa ? 'p-tag-success' : 'p-tag-secondary'">
    <!-- <span class="p-tag-value">{{(dataProject.is_publish ? 'Published' :  'Draft') | uppercase}}</span> -->
    <span class="p-tag-value" *ngIf="!dataProject.is_publish && !dataProject.is_publish_pwa">{{'Draft'| uppercase}}</span>
    <span class="p-tag-value" *ngIf="dataProject.is_publish && !dataProject.is_publish_pwa">{{'Published Android'| uppercase}}</span>
    <span class="p-tag-value" *ngIf="!dataProject.is_publish && dataProject.is_publish_pwa">{{'Published Wep App'| uppercase}}</span>
    <span class="p-tag-value" *ngIf="dataProject.is_publish && dataProject.is_publish_pwa">{{'Published Wep App & Android'| uppercase}}</span>
  </span>
  <!-- <span class="p-tag p-component p-tag-basic ml-2">
    <span class="p-tag-value">{{'Basic' | uppercase}}</span>
  </span> -->
  <span class="p-tag p-component" [ngClass]="dataProject.is_paid || dataProject?.status_project === 'business' ? 'p-tag-pro ml-2' : 'p-tag-basic ml-2'" >
    <!-- <span class="p-tag-value" pTooltip="{{dataProject.is_paid ? 'Pro plan 30 days remaining' : ''}}" tooltipPosition="bottom">{{(dataProject.is_paid ? 'Pro' :  'Basic') | uppercase}}</span> -->
    <!-- <span class="p-tag-value" pTooltip="{{dataProject.is_paid ? notifExpireInvoice : ''}}" tooltipPosition="bottom">{{(dataProject.is_paid ? 'Pro' :  'Basic') | uppercase}}</span> -->
    <span class="p-tag-value" pTooltip="{{dataProject.is_paid ? notifExpireInvoice : ''}}" tooltipPosition="bottom">{{statusProject | uppercase}}</span>
  </span>
</div>
<div *ngIf="statusPublish">
  <!-- <span  class="p-tag p-component" [ngClass]="dataProject.is_publish ? 'p-tag-danger' : 'p-tag-secondary'"> -->
    <span  class="p-tag p-component p-tag-danger">
    <span class="p-tag-value">{{descPublish | uppercase}}</span>
  </span>
  <span class="p-tag p-component" [ngClass]="dataProject.is_paid || dataProject?.status_project === 'business' ? 'p-tag-pro ml-2' : 'p-tag-basic ml-2'" >
    <!-- <span class="p-tag-value" pTooltip="{{dataProject.is_paid ? 'Pro plan 30 days remaining' : ''}}" tooltipPosition="bottom">{{(dataProject.is_paid ? 'Pro' :  'Basic') | uppercase}}</span> -->
    <!-- <span class="p-tag-value" pTooltip="{{dataProject.is_paid ? notifExpireInvoice : ''}}" tooltipPosition="bottom">{{(dataProject.is_paid ? 'Pro' :  'Basic') | uppercase}}</span> -->
    <span class="p-tag-value" pTooltip="{{dataProject.is_paid ? notifExpireInvoice : ''}}" tooltipPosition="bottom">{{statusProject | uppercase}}</span>
  </span>
</div>

