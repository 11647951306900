import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  GlobalService,
  GlobalcrudService,
  LoaderService,
  SessionService,
  ToastNotificationService,
} from "@core-fibr/shared";
import { TranslateService } from "@ngx-translate/core";
import { environment, AuthenticationService } from "@core-fibr/auth";
import { NgForm } from "@angular/forms";

@Component({
  selector: 'ui-fibr-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit {

  // registerForm!: FormGroup;
  // submitted = false;
  elementForm: any[]=[];
  appConfig: any;
  logo = '';
  @Input() params: any;
  @Output() registerClick = new EventEmitter<any>();
  @Output() loginClick = new EventEmitter<any>();
  fieldTextType = false;
  confirmPwd = false;
  
  constructor(private router: Router,
    public translate: TranslateService,
    public sessionService: SessionService,
    public loaderService: LoaderService,
    public authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private globalCrudService: GlobalcrudService,
    // private captchaService: CaptchaService,
    private notif: ToastNotificationService,
    private authService: AuthenticationService) { }

  ngOnInit(): void {
    this.elementForm = [
      {field: 'fullname', label: 'Fullname', value: '', required: true},
      {field: 'email', label: 'Email', value: '', required: true},
      {field: 'password', label: 'Password', value: '', required: true},
      {field: 'confirmPassword', label: 'Confirm Password', value: '', required: true}
    ];
    this.globalService.appConfig.subscribe((val: any) => this.appConfig = val);
    if (this.appConfig.is_paid && this.appConfig.app_logo != '') this.logo = this.appConfig.app_logo; else this.logo = '/assets/logo_appfibr_vertical.svg';
  }

  onClickRegister(f: NgForm) {
    const errVal = this.validasi(f.value)
    if (errVal != '') {
      this.notif.showNotification("error", errVal);
    } else {
      const mappedForm = Object.keys(f.value).map(key => ({field: key, value: f.value[key]}));
      const addCol = [
        // {field: 'created_date',  value: new Date()},
        // {field: 'created_by', value: ''},
        // {field: 'modify_date', value: new Date()},
        // {field: 'modified_by', value: ''}
        {field: 'userId',  value: this.params.id}, {field: 'projectId', value: this.params.appid}
      ];
      for (let i=0; i < addCol.length; i++) {
        mappedForm.push({field: addCol[i].field, value: addCol[i].value});     
      }
      const item:any = {}
      mappedForm.forEach((v: { field: string | number; value: any; }) => item[v?.field] = v?.value);
        for (let i=0; i < mappedForm.length; i++) {
          if (mappedForm[i].field == 'confirmPassword') 
            mappedForm.splice(i, 1);    
        }
        const body:any = {}
        mappedForm.forEach((v: { field: string | number; value: any; }) => body[v?.field] = v?.value);
        this.registerClick.emit(body);
    }
  }

  onClickLogin() {
    this.loginClick.emit(false);
  }

  validasi(val: any)  {
    let isEmail = false;
    let errValidasi2 = '';
    if(val.fullname == ''){
      errValidasi2 = errValidasi2 + 'User name is required!';
    }
    if(val.email == '') {
      errValidasi2 = errValidasi2 + 'Email is required!';
    } else {
      const regexp = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
      isEmail = regexp.test(val.email);
      if (!isEmail) {
        errValidasi2 = errValidasi2 + 'Email invalid Format!';
      }
    }
    if(val.password == '') {
      errValidasi2 = errValidasi2 + 'password cannot be empty!';
    } else {
      if (val.password != val.confirmPassword){
        errValidasi2 = errValidasi2 + 'Those passwords didn’t match. Try again.';
      }
    } 
    return errValidasi2;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleConfirmPwd() {
    this.confirmPwd = !this.confirmPwd;
  }

}
