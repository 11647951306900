// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  status: 'dev',
  mode: 'dev',
  app_name: 'fibr',
  version : '0.0.1-MPV',
  localization: 'en',
  apiEndpoint: 'fibr',
  logo: '/assets/logo_appfibr.svg',
  logo_right: '/assets/logo_appfibr-w.svg',
  logo_footer: '/assets/logo_fibr_footer.svg',
  image_login: '/assets/images/img_login-forgot-fibr-new.jpg',
  image_register: '/assets/images/img_register-fibr-new.jpg',
  image_forgotPassword: '/assets/images/img_login-forgot-fibr-new.jpg',
  isGoogleSignIn: true,
  isAppleSignIn: true,
  isMicrosoftSignIn: true,
  registerNameLabel_en: 'Full Name',
  registerNameLabel_id: 'Nama Lengkap',
  role: 'partner',
  role_id: 'wzbcwFfUZF3Li2buWD6b',
  nextStage: { isNextPage: false, url: '', queryParams: {}},
  topbar: { isNotif: true, isLanguage: true, isHelp: true, isGreeting: false, isProfile: true},
  addPackage: { 
    max_rows_free: 100, max_rows_paid: 5000, max_page_free: 3, max_page_paid: 10, max_table_free: 3, max_table_paid: 20,
    min_upload: 512000, max_upload: 20480000, max_etc_upload: 2048000
  },
  login_right : {
    h4 : 'Create Your App Without Code!',
    h4_register : 'Create an Account',
    h4_forgotPassword : 'Oops... Forgot your password?',
    h4_admin : 'Simple! Start with AppFibr',
    p: 'Simply! Just prepare your data with Google Sheets, import it to our dashboard, then you can easily create and customize your own mobile app.\r\nLet`s having fun and creative on making any apps with us!',
    p_admin: "It's easy to create your own apps. Let's have fun on making any apps with us!"
  },
  config: {
    app_name: 'fibr',
    login_style: 1,
    background_image: '',
    username: 'fadjrin@gmail.com',
    password: '123456'
  },

  /**dev */
  firebase: 'U2FsdGVkX1%2FqLeMJimWl7Vym1HVuKSV7lmIT3JOX7noJg6PIiOEHlLh9XB9ix33FBjqUXHwBqSqR7QB96YPIMh3w9oZGMPcYRWSfy5BIO5zEXi%2BeJTWFk5Q7zukN20NDiZcjhy3X3flRhgBcJJmi3a1G3Wxbf1d0rSiWx1f0tAr1O6hVg%2B7eC06bjd36c%2F9qGnLwJNgyQXCt8yf0L3UzdTC%2FF6k4bicBCZzI9WB%2BJZPBlnEroA4NYVbZoI8KGChuzwbwca0UaLI3T3UbGkGCXz%2FQ%2BbNvTD%2BK4KI1quNhSeMwX%2Fcnf4W52OiiL1TdBIJz%2FoWs%2FfTqPPYZjjk40oX7xPsUMQAZx%2BfZ%2FvFv5fGQFbJU7CnLK5ndCz1rym%2BjVqwM1zszNEJb%2FmpdTe6zCa1uwA%3D%3D',
  firebase_additional: 'U2FsdGVkX1%2F3esfCTU9M5CkSV77JNNISlQIWjv641wXezsug%2BoKR%2BBK17UzVy6kA9rk0jQYwO1Ft2%2F2Wj0jVvkERuY04iy4nl8VpwbUTPSDf6w4PsqVQCJolV1qwHoHWxc4LXmaJdYSWzBB5dl0P4bGX8ETgii%2F1thuV%2FcT4kB1HIT918333BbCgFP92n5ruMHcqBWmsx9cl8wYiGcsYHUrAGMwYYoNjgmkEgFkbaaJ8%2FhZpBTRpX%2FwFWkHbhaQ54mzJQdaemTQSfE2OUvZgDstQoEGksstZCU7zty%2F535RkdeONK6mNKAdUJtXDr2NNlnmjZjuNqNuhjA5GDIwvScS6iCchNhhzPdRoGvDay9RncjR4DbIK95q3fBB9icSmiExnP4ghU4PKt8Jshd6oOQ%3D%3D',
  /**prod */
  // firebase: 'U2FsdGVkX1%2FHvdeh2eucZnCpdmDZL7LaSArOOhpq7kbxf09j0F32q%2BT2rMsELCB6IcSazN11R8kJ0d7gmKYR22KWFt8%2BS6KLNN2Bg2AfS0gizzn5kB3ZHd57xDHp%2B2FiEwRDCcm2bRMFOLJpN2IMTsjE2FEeBtro1z6yo19wJ%2Fux90q9Gr4g77z%2FcCA15GaGRIrwxgC9iBM%2Fun1hXG%2BikWjrOCKwW0s37vyvLBr6aV78gzHl76ELwZSdSlAI6u%2Bgz7GzYQYRrY13dtP3lCvwHEmDGtWiInU5%2FODxYQsOzfy40W8Mj6EndnLACwbXiQXZqgjUXg1EmCSWITllxgNmkh8ynZ8S9HcyNSHKdsErtAscDMraqvFgXKsY0NYa9yPxGcHYrpyjilH6KCJ%2FRluKzA%3D%3D',
  // firebase_additional: 'U2FsdGVkX1%2B3QizNnLiRAmN%2BwPrWawvSBH48Bfx2Q7oWay%2Fnt7JoH2lsW3H4K29X4pKKsIfxe8YEYd%2BoenzxcfSQrQFUb%2FiY%2BW7wzYLQPGB9Z4%2FyJQ3V4y4mO8SLwG2VxX%2Bfrg1sj%2Fzh66dF7SL2rhdsyp2f%2BiaauR8KPJTEiD7wIWNwfTAjv%2BDc%2FNxg5l097a0oRtVY7%2FNWJAHu3XQcAn%2F%2FJj%2Fz%2FUh%2BBaISDHc35umpYi8N%2Ba4cXVP0GcE2EVTn5n4lFs4pj7Igr75w%2BnSGh7%2BfqG9ArNpEKmVnXHl1%2BC0f%2B%2FSXjEOKrectbuPPWNTm7Ued7qQZzWM33o9Bj40iJC5WmVdxEiLqJkwpOR15%2BIVyrnooFWqWKioj2wrb1NLqPxQm7ZlVrEMG3OAjKY%2FsqA%3D%3D',
  
  googleapis: {
    /** dev-appfibr */
    client_id: "308692921820-dh96ot1qo2oc5em64ip6d43nb7iu31q4.apps.googleusercontent.com",
    client_secret:"5BhsZbTLDKorRR-Z4toxT7_w",

    /**prod */
    // client_id: "1026420756455-vm5il79ps2a6ml65dveuupuhge6q3gbh.apps.googleusercontent.com", 
    // client_secret: "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",

    /**stage-fibr */
    // client_id: "1026420756455-vm5il79ps2a6ml65dveuupuhge6q3gbh.apps.googleusercontent.com", 
    // client_secret: "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",

    "javascript_origins":["http://localhost:4200","https://dev-fibr.web.app","https://my.appfibr.io","https://stage.appfibr.io/"],
    discoveryDocs: ["https://analyticsreporting.googleapis.com/$discovery/rest?version=v4"],
    scope: [
    // "https://www.googleapis.com/auth/userinfo.profile",
    'https://www.googleapis.com/auth/drive', 
    'https://www.googleapis.com/auth/drive.readonly', 
    'https://www.googleapis.com/auth/drive.file', 
    'https://www.googleapis.com/auth/spreadsheets', 
    'https://www.googleapis.com/auth/spreadsheets.readonly'
    ].join(" "),
    plugin_name: 'streamy'
  },
  gradient: ['#8DE4EA', '#084B83'],
  primary_button_color: '#4394D8',
  text_color: '#479DB8',

  /**dev appfibr.io*/
  image_path: 'https://dev-be.appfibr.io/api/v1/files/download?image_path=',
  be_dev: 'https://dev-be.appfibr.io',
  be_socket: 'https://socket.appfibr.io',
  domain: 'https://dev.appfibr.io',
  payment: 'https://payment-dev.appfibr.io',
  midtrans_snap: "https://app.sandbox.midtrans.com/snap/snap.js"

  /**testing prod */
  // image_path: 'https://be.appfibr.io/api/v1/files/download?image_path=',
  // be_dev: 'https://stage-be.appfibr.io',
  // be_socket: 'https://socket-stage.appfibr.io',
  // domain: 'https://stage.appfibr.io',
  // midtrans_snap: "https://app.sandbox.midtrans.com/snap/snap.js"

  /**dev stage.appfibr.io*/
  // image_path: 'https://stage-be.appfibr.io/api/files/download?image_path=',
  // be_dev: 'https://stage-be.appfibr.io',
  // be_socket: 'https://stage-be.appfibr.io:63300',
  // domain: 'https://stage.appfibr.io',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
