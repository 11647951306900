import { Pipe, PipeTransform } from '@angular/core';
import { get, split } from 'lodash';

@Pipe({
  name: 'gdriveConvert'
})
export class GdriveConvertPipe implements PipeTransform {
  transform(value: any): any {
    // console.log(value)
    let data: any;
    if (value == '' || value == undefined) {
      data = '';
    } else {
      if (value.substring(0,24) == 'https://drive.google.com') {
        const fileId = get(split(value, "/"), 5)
        // console.log(fileId)
        data = 'https://drive.google.com/uc?id='+fileId;
      } else {
        data = value;
      }
    }
    return data;
  }
}
