import { Component, OnInit } from '@angular/core';
import { GlobalService } from '@core-fibr/shared';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ParserService } from '../../../services/parser.service';
import { ToastNotificationService } from '../../../services/toast-notification.service';

@Component({
  selector: 'ui-fibr-edit-columns',
  templateUrl: './edit-columns.component.html',
  styleUrls: ['./edit-columns.component.css']
})
export class EditColumnsComponent implements OnInit {

  dataSource : any[] = [];
  fields: any = {
    fieldName: '',
    fieldHeader: '',
    fieldTypeOld: '',
    fieldType: '',
    action: '',
    value: null,
    calculation: {}
  };
  alfabeth: any[] = [];
  columns: any;
  varList = [{column:'',alias:'a'}];
  formula: any = '';
  countVarCal = 0;
  constructor( public ref: DynamicDialogRef, public dynamicDialogConfig: DynamicDialogConfig, private parser: ParserService,
    private ts: ToastNotificationService, private globalService: GlobalService,) { }

  ngOnInit(): void {
    this.dataSource = [
      // {value: '', name: 'Select one'}, //Text, Number, Date, Image, Boolean
      {value: 'text', name: 'Text'},
      {value: 'number', name: 'Number'},
      {value: 'date', name: 'Date'},
      {value: 'time', name: 'Time'},
      {value: 'image', name: 'Image'},
      {value: 'link', name: 'Link'},
      {value: 'boolean', name: 'Boolean'},
      {value: 'text_editor', name: 'Text Editor'},
      {value: 'qrcode', name: 'QR Code'},
      {value: 'calculation', name: 'Calculation'},
      {value: 'autofill_calculation', name: 'Autofill Calculation'},
      {value: 'location', name: 'Location'},
    ];
    this.fields.fieldName = this.dynamicDialogConfig.data.field;
    this.fields.fieldHeader = this.dynamicDialogConfig.data.header
    this.fields.fieldTypeOld = this.dynamicDialogConfig.data.type;
    if (this.dynamicDialogConfig.data.type == 'string') {
      this.fields.fieldType = 'text';
    } else {
      this.fields.fieldType = this.dynamicDialogConfig.data.type;
    }
    
    this.fields.calculation = this.dynamicDialogConfig.data.calculation;
    if (this.fields.calculation?.varList == undefined) {
      this.varList = [{column:'',alias:'a'}];
      this.formula = '';
      this.countVarCal = 1;
    } else {
      this.varList = this.fields?.calculation?.varList;
      this.formula = this.fields?.calculation?.formula;
      this.countVarCal = this.varList.length;
    }
    
    this.alfabeth = this.parser.dataAlfabet();
    this.globalService.columnGrid.subscribe((res: any) => {
      this.columns = res.filter((x: any) => x.type == 'number' || x.type == 'calculation' || x.type == 'autofill_calculation');
    })
  }

  cancelDialog(){
    this.ref.close(false);
  }

  // doItDialog(){
  //   this.fields.action = 'update';
  //   this.ref.close(this.fields);
  // }

  doItDialog(){
    this.fields.action = 'update';
    const valDt = this.validasiData();
    if (valDt != '') {
      this.ts.showNotification("error", valDt);
    } else {
      if (this.fields.fieldType == 'calculation') {
        const err: any = this.matchFormula(this.formula,this.varList);
        let errList = '';
        if (err.length > 0) {
          this.ts.showNotification("error", "wrote the formula wrong!");
        } else {
          errList = this.validasiFormula(this.varList,this.formula,this.fields);
          if (errList != '') {
            this.ts.showNotification("error", errList);
          } else {
            this.fields.calculation = {varList: this.varList, formula: this.formula}
            // console.log(this.fields)
            this.ref.close(this.fields);
          }
        }
      } else {
        this.fields.calculation = {};
        this.ref.close(this.fields);
      }
    }
  }

  validasiData() {
    let err = '';
    if (this.fields.fieldHeader == '') err = err+' Field Name must be filled in!';
    if (this.fields.fieldType == '') err = err+' Field Type must be filled in!';
    return err;
  }

  doItDeleteDialog(){
    this.fields.action = 'delete';
    this.ref.close(this.fields);
  }

  fieldTypeChange(event: any){
    switch (event.value) {
      case 'text':
      case 'link':
      case 'image':
        this.fields.value = '';
        break;
      case 'number':
        this.fields.value = 0;
        break;
      case 'boolean':
        this.fields.value = false;
        break;
      case 'date':
        this.fields.value = new Date();
        break;
    }
  }

  addVar() {
    const urut = this.varList.length;
    // if (urut < 3)
      this.varList.push({column:'',alias:this.alfabeth[urut]});
      this.countVarCal = this.countVarCal + 1;
    // else 
    //   this.ts.showNotification("error", "max 3 column!");
  }

  delVar(i: number) {
    this.varList.splice(i, 1);
    this.countVarCal = this.countVarCal - 1;
  }

  checkFormula(formula: any, varList: any) {
    const err: any = this.matchFormula(formula,varList);
    if (err.length > 0) {
      this.ts.showNotification("error", "wrote the formula wrong!");
    } else {
      this.ts.showNotification("success", "wrote the formula valid");
    }
  }

  matchFormula(formula: any, varList: any) {
    const err: any = this.parser.parserCalculation(formula,varList)
    // console.log(err)
    return err
  }

  validasiFormula(varList:any, formula:any, fields: any) {
    let err: any ='';
    const varCount = varList;
    for (let x=0; x<varList.length; x++) {
      if (varList[x].column == '') err = err+' column must selected!';
      const cekAlias = varCount.filter((xy:any) => xy.alias == varList[x].alias);
      if (cekAlias.length > 1) err = err+' error duplicate alias';
      const cekCol = varCount.filter((xz:any) => xz.column == varList[x].column);
      if (cekCol.length > 1) err = err+' error duplicate column';
    }
    if (formula == '') err = err+' formula must be filled in!';
    return err;
  }

}
