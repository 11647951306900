import { Injectable } from '@angular/core';
import { io, Socket } from "socket.io-client";
import { environment } from "@core-fibr/auth";
import { GlobalService } from "@core-fibr/shared";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  socket!: Socket;
  userProfile: any;
  observer: any;
  public message$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private globalService: GlobalService,) {
    this.userProfile = this.globalService.getCurrReffDataParsed('user_profile');
    this.socket = io(environment.be_socket, { autoConnect: false });
    this.socket.auth = { sessionID: this.userProfile?.uid };
    this.socket.connect();
  }


  getRefreshStateData(): Observable<any> {
    this.socket.on('refresh-state', (message) => {
      this.message$.next(message);
    });

    return this.message$.asObservable();
  }

  setRefreshStateData(value: any) {
    this.message$.next(value);
  }  
}
