import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  GlobalService,
  GlobalcrudService,
  LoaderService,
  SessionService,
  ToastNotificationService,
} from "@core-fibr/shared";
import { TranslateService } from "@ngx-translate/core";
import { environment, AuthenticationService } from "@core-fibr/auth";
import { Observable, Subject } from "rxjs";
import { finalize, takeUntil, tap } from "rxjs/operators";
import { Lang } from "../../models/user-info";
import { CaptchaService } from "../captcha/captcha.service";

@Component({
  selector: 'ui-fibr-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.scss']
})
export class LoginAdminComponent implements OnInit {

  public env = environment;

  userName: string | undefined;
  password: string | undefined;
  locale: string | undefined;
  version: string | undefined;
  returnUrl = "";

  isProgressSpinner = false;
  selectedValues = false;
  msgs: any;
  country: any;

  logo = environment.logo;
  logoRight = environment.logo_right;
  labelAlt = environment.app_name;
  image_login = environment.image_login;
  image_forgotPassword = environment.image_forgotPassword;
  isGoogleSignIn = environment.isGoogleSignIn;
  isAppleSignIn = environment.isAppleSignIn;
  isMicrosoftSignIn = environment.isMicrosoftSignIn;
  isAppSifa = environment.app_name == "fibr";
  primaryButtonColor = environment.primary_button_color;
  textColorCopy = environment.text_color;

  selectedLanguage: Lang | undefined;
  private unsubscribe: Subject<any> | undefined;
  captchaStatus: any = null;
  captchaConfig: any = {
    length: 6,
    cssClass: "custom",
    back: {
      stroke: "#2F9688",
      solid: "#fff",
    },
    font: {
      color: "#000000",
      size: "35px",
    },
  };
  fieldTextType = false;
  isBlocked = false;
  countGetToken = 0;
  constructor(
    private router: Router,
    public translate: TranslateService,
    public sessionService: SessionService,
    public loaderService: LoaderService,
    public authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private globalCrudService: GlobalcrudService,
    private captchaService: CaptchaService,
    private notif: ToastNotificationService,
    private authService: AuthenticationService
  ) {
    this.unsubscribe = new Subject();
    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params.returnUrl || "/portal-admin"; 
    });
  }

  ngOnInit() {
    if (this.globalService.getCurrReffDataParsed("user_profile")) {
      this.router.navigate([``]);
    }
      this.country = [
        { name: "English", code: "en" },
        { name: "Indonesia", code: "id" },
      ];
      this.userName = "";
      this.password = "";
      this.locale = this.sessionService.getItem("fibr-language");
      this.selectedLanguage = this.country.filter(
        (x: any) => x.code == this.locale
      )[0];
      this.version = environment.version;
      this.msgs = [
        { severity: "info", detail: "UserName: admin" },
        { severity: "info", detail: "Password: password" },
      ];
      this.captchaService.captchStatus.subscribe((status) => {
        this.captchaStatus = status;
      });
    // }
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  onClickLogin() {
    if (!this.captchaStatus) {
      this.notif.showNotification("error", "Opps!\nCaptcha mismatch");
      return;
    }
    this.authenticationService
      .login({ email: this.userName, password: this.password })
      .pipe(
        tap(
          (result) => {
            const user = result;
            this.globalService.setRefDataToLocalstorage("user_profile", user);
            this.globalService.setRefDataToLocalstorage("token",user.access_token);
            this.router.navigateByUrl(this.returnUrl);
          }
        ),
        takeUntil(this.unsubscribe as Observable<any>),
        finalize(() => {
          this.cdr.markForCheck();
        })
      )
      .subscribe();
  }

  onClickLoginUseFireEmailandPassword() {
    this.isProgressSpinner = true;
    this.authenticationService.loginFireEmailandPassword({email: this.userName,password: this.password})
    .then((result) => {
      const pathProfile = `users/${result.uid}/profile`;
      this.globalCrudService.getcollection(pathProfile).subscribe((res) => {
      const userInfo: any = res[0];
        if (!userInfo?.is_admin) {
          this.authService.clearAuthToken();
          this.notif.showNotification('error','user is not administrator level!');
          setTimeout(() => {
            location.reload();
          }, 3000);
        } else {
          this.getToken(userInfo)
        }
      });
      setTimeout(() => {
        this.isProgressSpinner = false;
      }, 3000);
    }),
    takeUntil(this.unsubscribe as Observable<any>),
    finalize(() => {
      this.isProgressSpinner = false;
      this.cdr.markForCheck();
    });
  }

  getToken(userInfo: any) {
    const pathUser = `/users/${userInfo?.uid}/profile/`;
    this.globalService.methodPost(environment.be_dev + `/api/v1/fibr-app/token`, { uid: userInfo.uid }
    ).subscribe((response) => {
      setTimeout(() => {
        userInfo.accessToken = response?.data?.accessToken;
        userInfo.refreshToken = response?.data?.refreshToken;
        userInfo.last_login = new Date();
        // this.globalCrudService.modifyRefMergeCollection(userInfo,pathUser,'data');
        this.globalService.setRefDataToLocalstorage("user_profile", userInfo);
        this.router.navigateByUrl(this.returnUrl)
        .then(() => {
          window.location.reload();
        });
        this.isBlocked = false;
      }, 3000);
    },
      (err: any) => {
        if (this.countGetToken < 3) {
          setTimeout(() => {
            this.getToken(userInfo);
          }, 2000);
          this.countGetToken++;
        } else {
          this.authService.clearAuthToken();
            this.notif.showNotification('error', 'failed to get tokens!');
            setTimeout(() => {
              location.reload();
            }, 2000);
        }
      });
  }

  onLanguageChange() {
    this.locale = this.selectedLanguage?.code;
    if (
      this.locale == undefined ||
      this.locale == null ||
      this.locale.length == 0
    ) {
      this.locale = "en";
    }
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(this.locale);
    this.sessionService.setItem("fibr-language", this.locale);
  }

}
