import { HttpHeaders, HttpParams } from '@angular/common/http';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { appConfig, GlobalService } from '@core-fibr/shared';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { environment } from '../../../../../../auth/src/environments/environment';
import { ToastNotificationService } from '../../../services/toast-notification.service';

@Component({
  selector: 'ui-fibr-inapppyament-approval',
  templateUrl: './inapppyament-approval.component.html',
  styleUrls: ['./inapppyament-approval.component.scss']
})
export class InAppPaymentApprovalComponent implements OnInit {
  purpose_create = "";
  appConfig: appConfig = {};
  user: any = {};
  is_agree = false;
  submitClicked = false;
  blocked: boolean = false;
  env: any;
  headers: any = "";
  constructor(
    public ref: DynamicDialogRef,
    private globalService: GlobalService,
    private toastNotificationService : ToastNotificationService,
    private router: Router,
    ) { 
      this.env = this.globalService.decryptEnv(environment);
    }

  ngOnInit(): void {
    this.globalService.appConfig.subscribe((val: appConfig) => this.appConfig = val);
    this.user = this.globalService.userProfile;
    this.headers  = new HttpHeaders({ 'Content-Type':  'application/json', Authorization: `Bearer ${this.user?.accessToken}`});
    
  }
  createBodyBuild(){
    const body:any = {
      projectId: this.appConfig.app_id,
      purpose_create_an_app: this.purpose_create
    }
    return body;
  }
  onSubmit() {
    this.submitClicked = true;
    if (this.is_agree && this.purpose_create) {
      this.blocked = true;
      const body:any = this.createBodyBuild();
      setTimeout(async () => {
        await this.globalService.methodPostOptions(environment.be_dev + `/api/v1/fibr-app/buildinstaller/submitapproval`, body, this.headers)
        .subscribe(( res: any) => {
          this.blocked = false;
          this.toastNotificationService.showNotification(res.status, res.message);
          setTimeout(() => {
            this.ref.close();
            this.globalService.changeBlockui(false);
            this.router.navigateByUrl("portal/project");
          }, 500);
        },
        (err: any) => {
          this.blocked = false;
          this.toastNotificationService.showNotification(err?.error?.status, err?.error?.message);
          this.globalService.changeBlockui(false);
        });
      }, 500);
    }
  }

  onTextareaInput() {
    this.submitClicked = false;
  }

}
