import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@core-fibr/auth';
import { GlobalService } from '@core-fibr/shared';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class GsheetService {
  token: string | undefined;
  userProfile: any;

  constructor(
    private globalService: GlobalService,
    private http: HttpClient,
  ) {
    this.userProfile = this.globalService.getCurrReffDataParsed('user_profile');
    this.token = this.userProfile?.accessToken;
  }

  async gsheetFiles() {
    let endpoint = `${environment.be_dev}/api/v1/fibr-app/gsheet/files`;
    return this.http.post<any>(endpoint, {}, { observe: 'response' });
  }

  async importProcess(param: any) {
    let endpoint = `${environment.be_dev}/api/v1/fibr-app/gsheet/import`;

    return this.http.post<any>(endpoint, param, { observe: 'response' });
  }

  async exportProcess(param: any) {
    let endpoint = `${environment.be_dev}/api/v1/fibr-app/gsheet/export`;

    return this.http.post<any>(endpoint, param, { observe: 'response' });
  }

  async getGsheetFilesForExport(projectId: any) {
    let endpoint = `${environment.be_dev}/api/v1/fibr-app/gsheet/export/list`;
    return this.http.post<any>(endpoint, { projectId }, { observe: 'response' });
  }
}
