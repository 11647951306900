import { HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalcrudService, GlobalService, ToastNotificationService } from '@core-fibr/shared';
import { AuthenticationService, environment } from '@core-fibr/auth';
import { finalize, takeUntil, tap } from "rxjs/operators";
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'ui-fibr-invoice-notif',
  templateUrl: './invoice-notif.component.html',
  styleUrls: ['./invoice-notif.component.scss']
})
export class InvoiceNotifComponent implements OnInit {

  paramater: any = {};
  headers: any = "";
  private unsubscribe: Subject<any> | undefined;
  constructor(
    private route: ActivatedRoute, private globalService: GlobalService,private globalCrudService: GlobalcrudService,
    private authService: AuthenticationService,private notif: ToastNotificationService,private router: Router,
    private cdr: ChangeDetectorRef,
  ) { 
    this.route.queryParams.subscribe((params) => {
      console.log(params)
      this.paramater = params;
    });
  }

  ngOnInit(): void {
    // 
  }

  async redirectToken(token: string) {
    console.log(token)
    // this.headers  = new HttpHeaders({ 'Content-Type':  'application/json', Authorization: `Bearer ${token}`});
    this.headers  = new HttpHeaders({ 'Content-Type':  'application/json', 'x-access-token': `Bearer ${token}`});
    const body = {'token': `Bearer ${token}`}
    console.log(this.headers)
    await this.globalService.methodPostOptions(environment.be_dev + `/api/v1/fibr-app/tokenredirect`,null, this.headers)
    // await this.globalService.methodPost(environment.be_dev + `/api/v1/fibr-app/tokenredirect`,body)
    .subscribe((resp) => {
      console.log(resp)
      const userExsis = this.globalService.getCurrReffDataParsed("user_profile");
      console.log(userExsis)
      // if (userExsis == null) {
      // const pathProfile = `users/${resp?.data?.user_id}/profile`;
      // this.globalCrudService.getcollectionPromise(pathProfile).then(res => {
      //   // console.log(res)
      //   if (res.length > 0) {
      //     const userInfo: any = res[0];
      //     if (userInfo != undefined) { //kalau user ketemu *atau ada baru hit endpoint ini kalau gak jangan
      //       this.getToken(userInfo)
      //     }  
      //   } else {
      //     this.authService.clearAuthToken();
      //     this.notif.showNotification('error', 'User Not Found!');
      //     setTimeout(() => {
      //       location.reload();
      //     }, 3000);
      //   }
      // })
      // .catch((error) => {
      //   // 
      // });
      // } else {
        this.router.navigate(["auth/login"], {
          queryParams: {
            userid: resp?.data?.user_id,
            appid: resp?.data?.project_id,
            invid: resp?.data?.invoice_id,
          },
        });
      // }
     
    },
      (err: any) => {
        this.globalService.changeBlockui(false);
    });
    takeUntil(this.unsubscribe as Observable<any>),
    finalize(() => {
      // this.isProgressSpinner = false;
      // this.isBlocked = false;
      this.cdr.markForCheck();
    });
  }

  // getToken(userInfo: any) {
  //   const pathUser = `/users/${userInfo?.uid}/profile/`;
  //   this.globalService.methodPost(environment.be_dev + `/api/v1/fibr-app/token`, { uid: userInfo.uid }
  //   ).subscribe((response) => {
  //     setTimeout(() => {
  //       userInfo.accessToken = response?.data?.accessToken;
  //       userInfo.refreshToken = response?.data?.refreshToken;
  //       userInfo.last_login = new Date();
  //       this.globalCrudService.modifyRefMergeCollection(userInfo,pathUser,'data');
  //       this.globalService.setRefDataToLocalstorage("user_profile", userInfo);
  //       this.saveLog(userInfo);
  //       this.router.navigateByUrl(this.returnUrl)
  //       .then(() => {
  //         window.location.reload();
  //       });
  //       this.isBlocked = false;
  //     }, 3000);
  //   },
  //     (err: any) => {
  //       if (this.countGetToken < 3) {
  //         setTimeout(() => {
  //           this.getToken(userInfo);
  //         }, 2000);
  //         this.countGetToken++;
  //       } else {
  //         this.authService.clearAuthToken();
  //           this.notif.showNotification('error', 'failed to get tokens!');
  //           setTimeout(() => {
  //             location.reload();
  //           }, 2000);
  //       }
  //     });
  // }
}


