import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  GlobalService,
  GlobalcrudService,
  LoaderService,
  SessionService,
  ToastNotificationService,
} from "@core-fibr/shared";
import { TranslateService } from "@ngx-translate/core";
import { environment, AuthenticationService } from "@core-fibr/auth";
import { NgForm } from "@angular/forms";

@Component({
  selector: 'ui-fibr-verifi-email',
  templateUrl: './verifi-email.component.html',
  styleUrls: ['./verifi-email.component.scss']
})
export class VerifiEmailComponent implements OnInit {

  public env = environment;
  @Input() params: any;
  @Output() loginClick = new EventEmitter<any>();
  @Output() activeEmailClick = new EventEmitter<any>();

  userName: string | undefined;
  password: string | undefined;
  elementForm: any[]=[];
  appConfig:any;
  logo = '';

  constructor(
    private router: Router,
    public translate: TranslateService,
    public sessionService: SessionService,
    public loaderService: LoaderService,
    public authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private globalCrudService: GlobalcrudService,
    // private captchaService: CaptchaService,
    private notif: ToastNotificationService,
    private authService: AuthenticationService
  ) {
    
  }

  ngOnInit() {
    this.elementForm = [
      {field: 'token', label: 'Token', value: '', required: true}
    ];
    this.globalService.appConfig.subscribe((val: any) => this.appConfig = val);
    if (this.appConfig.is_paid && this.appConfig.app_logo != '') this.logo = this.appConfig.app_logo; else this.logo = '/assets/logo_appfibr_vertical.svg';
  }

  onClickActivateEmail(f: NgForm) {
    // console.log(f.value)
    this.activeEmailClick.emit(f.value);
  }

  onClickLogin() {
    this.loginClick.emit(false);
  }

}
