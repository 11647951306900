<div class="grid" >
  <div class="col-12 mb-2">
      <p class="text-center">{{'editor.add.table.description' | translate}}</p>
  </div>
</div>
<div class="grid text-center justify-content-center p-5">
  <div class="col-auto center flex-column mx-4">
    <a href="javascript:;" (click)="googleSheets()">
      <img src="/assets/images/icon/icon_g-sheet.svg" height="80px" class="py-3"/>
      <p class="text-grey">Google Sheets</p>
    </a>
  </div>
  <div class="col-auto center flex-column mx-4">
      <a href="javascript:;" (click)="blankTable()">
        <img src="/assets/images/icon/icon_blank-table.svg" height="80px" class="py-3"/>
        <p class="text-grey">Blank Table</p>
      </a>
  </div>
</div>

