<input class="line-input w-260 mr-3" type="text" pInputText [(ngModel)]="appConfig.app_name" (blur)="onBlur()"/>
<span class="p-tag p-component" [ngClass]="appConfig.is_publish ||  appConfig.is_publish_pwa? 'p-tag-success' : 'p-tag-secondary'">
    <!-- <span class="p-tag-value" *ngIf="appConfig.is_publish"> {{(appConfig.is_publish ? 'Published' : 'Draft')| uppercase}}</span> -->
    <span class="p-tag-value" *ngIf="!appConfig.is_publish && !appConfig.is_publish_pwa">{{'Draft'| uppercase}}</span>
    <span class="p-tag-value" *ngIf="appConfig.is_publish && !appConfig.is_publish_pwa">{{'Published Android'| uppercase}}</span>
    <span class="p-tag-value" *ngIf="!appConfig.is_publish && appConfig.is_publish_pwa">{{'Published Wep App'| uppercase}}</span>
    <span class="p-tag-value" *ngIf="appConfig.is_publish && appConfig.is_publish_pwa">{{'Published Wep App & Android'| uppercase}}</span>
</span>
<span class="p-tag p-component" [ngClass]="appConfig.is_paid || appConfig.status_project === 'business' ? 'p-tag-pro ml-2' : 'p-tag-basic ml-2'">
  <!-- <span class="p-tag-value" pTooltip="{{appConfig.is_paid ? notifExpireInvoice : ''}}" tooltipPosition="bottom">{{(appConfig.is_paid ? 'Pro' :  'Basic') | uppercase}}</span> -->
  <span class="p-tag-value" pTooltip="{{appConfig.is_paid ? notifExpireInvoice : ''}}" tooltipPosition="bottom">{{statusProject | uppercase}}</span>
</span>


<!-- <p-badge pButton class="btn-upgrade-pro ml-3 px-3 hidden lg:inline-flex" label="Upgrade to Pro" icon="pi pi-check-circle" iconPos="right" (click)="showUpgradeToPro=true;"></p-badge> -->
<button pButton *ngIf="!appConfig.is_paid" class="btn-upgrade-pro ml-3 px-3 hidden lg:inline-flex" label="{{'table.upgrade.project_plan' | translate}}" icon="pi pi-check-circle" iconPos="right" (click)="updatePro('upgrade')"></button>
<button pButton *ngIf="appConfig.is_paid" class="btn-upgrade-pro ml-3 px-3 hidden lg:inline-flex" label="+ {{'table.upgrade.extend_pro_plan' | translate}}" icon="pi pi-check-circle" iconPos="right" (click)="updatePro('extend')"></button>

<p-dialog [(visible)]="showUpgradeToPro" *ngIf="showUpgradeToPro" [modal]="true" [blockScroll]="true" [draggable]="false" [dismissableMask]="true" position="top" appendTo="body" [style]="{width: '800px'}" styleClass="dialog-no-footer">
    <ng-template pTemplate = "header">
        <span class="p-dialog-title"><i class="pi pi-check-circle"></i>Upgrade Project Plan</span>
    </ng-template>

    <div class="grid">
        <div class="col-12">
            <p-table responsiveLayout="scroll" styleClass="p-datatable-gridlines pricing-plan-table">
                <ng-template pTemplate="footer">
                    <tr>
                        <td></td>
                        <td>
                            <h4>Basic</h4>
                            <h2><small>Rp</small> 0 <small>,-</small></h2>
                            <p>per month</p>
                            <p-button styleClass="p-button p-button-secondary" label="You Are on Basic" [disabled]="true"></p-button>
                        </td>
                        <td>
                            <h4>Pro</h4>
                            <h2><small>Rp</small> 100.000 <small>,-</small></h2>
                            <p>per month (cancel anytime)</p>
                            <p-button styleClass="p-button p-button-primary" label="Pro Version Available Soon" icon="pi pi-chevron-right" iconPos="right" (click)="showUpgradeToPro=false; showOrderSummary=true" [disabled]="true"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td class="section-title">Projects</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Page of Apps</td>
                        <td>3</td>
                        <td>10</td>
                    </tr>
                    <tr>
                        <td>App Published (Web App)</td>
                        <td>1</td>
                        <td>5</td>
                    </tr>
                    <tr>
                        <td>App Published (APK or AAB)</td>
                        <td>1 (One time publish quota in user)</td>
                        <td>1 (One active app with multiple publish)</td>
                    </tr>
                    <tr>
                        <td>Public Users</td>
                        <td>Unlimited</td>
                        <td>Unlimited</td>
                    </tr>
                    <tr>
                        <td>Private Users</td>
                        <td>10</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>Rows per table in Project</td>
                        <td>100</td>
                        <td>5000</td>
                    </tr>
                    <tr>
                        <td>Tables in Project</td>
                        <td>3</td>
                        <td>20</td>
                    </tr>
                    <tr>
                        <td>Total Rows in Project</td>
                        <td>1000</td>
                        <td>10000</td>
                    </tr>
                    <tr>
                        <td>Storage Capacity</td>
                        <td>1GB</td>
                        <td>5GB</td>
                    </tr>
                    <tr>
                        <td class="section-title">Features</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Whitelabel</td>
                        <td>-</td>
                        <td><i class="pi pi-check-circle"></i></td>
                    </tr>
                    <!-- <tr>
                        <td>Custom domain</td>
                        <td>-</td>
                        <td><i class="pi pi-check-circle"></i></td>
                    </tr> -->
                    <tr>
                        <td>User login authentication</td>
                        <td><i class="pi pi-check-circle"></i></td>
                        <td><i class="pi pi-check-circle"></i></td>
                    </tr>
                    <tr>
                        <td>List, Grid, Details view</td>
                        <td><i class="pi pi-check-circle"></i></td>
                        <td><i class="pi pi-check-circle"></i></td>
                    </tr>
                    <tr>
                        <td>Calendar view</td>
                        <td>-</td>
                        <td><i class="pi pi-check-circle"></i></td>
                    </tr>
                    <!-- <tr>
                        <td>In-app Chat</td>
                        <td>-</td>
                        <td><i class="pi pi-check-circle"></i></td>
                    </tr> -->
                    <tr>
                        <td>Video conference</td>
                        <td>-</td>
                        <td><i class="pi pi-check-circle"></i></td>
                    </tr>
                    <!-- <tr>
                        <td>QR scanner</td>
                        <td>-</td>
                        <td><i class="pi pi-check-circle"></i></td>
                    </tr> -->
                    <tr>
                        <td>In App Purchase</td>
                        <td>-</td>
                        <td>Add-On</td>
                    </tr>
                    <!-- <tr>
                        <td>Analytics</td>
                        <td>-</td>
                        <td><i class="pi pi-check-circle"></i></td>
                    </tr> -->
                    <tr>
                        <td>Publish to Play Store</td>
                        <td>-</td>
                        <td><i class="pi pi-check-circle"></i> (AppFibr Play Store Account)</td>
                    </tr>
                    <tr>
                        <td class="section-title">Support</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>AAB to Playstore (Publish to Appfibr Playstore) </td>
                        <td>-</td>
                        <td><i class="pi pi-check-circle"></i></td>
                    </tr>
                    <tr>
                        <td>Public WA group support</td>
                        <td><i class="pi pi-check-circle"></i></td>
                        <td><i class="pi pi-check-circle"></i></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</p-dialog>


<p-dialog [(visible)]="showOrderSummary" *ngIf="showOrderSummary" [modal]="true" [blockScroll]="true" [draggable]="false" [dismissableMask]="true" position="top" appendTo="body" [style]="{width: '500px'}" styleClass="footer-default">
    <ng-template pTemplate = "header">
        <span class="p-dialog-title"><i class="pi pi-credit-card"></i>Order Summary</span>
    </ng-template>

    <div class="grid">
        <div class="col-12">
            <h6>Project Plan</h6>
            <div class="grid">
                <div class="col-6 pl-5">
                    <label class="label-info">Resto App (Pro Plan Monthly)</label>
                </div>
                <div class="col-6 text-right">
                    <span>Rp 100.000</span>
                </div>
            </div>
        </div>
    </div>

    <p-divider></p-divider>

    <div class="grid">
        <div class="col-12">
            <div class="grid">
                <div class="col-6 pl-5">
                    <label class="label-info">Sub Total</label>
                </div>
                <div class="col-6 text-right">
                    <span>Rp 100.000</span>
                </div>
            </div>
            <div class="grid">
                <div class="col-6 pl-5">
                    <label class="label-info">Tax</label>
                </div>
                <div class="col-6 text-right">
                    <span>Rp 10.000</span>
                </div>
            </div>
            <div class="grid">
                <div class="col-6 pl-5">
                    <label class="label-info">Discount</label>
                </div>
                <div class="col-6 text-right">
                    <span>Rp 0</span>
                </div>
            </div>
        </div>
    </div>

    <div class="grid">
        <div class="col-12">
            <div class="grid align-items-center bg-softblue">
                <div class="col-6 p-3 pl-5">
                    <label class="label-info">Total</label>
                </div>
                <div class="col-6 p-3 text-right">
                    <h3 class="mb-0 text-blue"><small class="text-lg font-light">Rp</small> 110.000 <small class="text-lg font-light">,-</small></h3>
                </div>
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button styleClass="p-button p-button-secondary" label="Back" (click)="showUpgradeToPro=true; showOrderSummary=false"></p-button>
        <p-button styleClass="p-button p-button-primary" label="Checkout" icon="pi pi-chevron-right" iconPos="right" (click)="showInvoice=true; showOrderSummary=false"></p-button>
    </ng-template>
</p-dialog>


<p-dialog [(visible)]="showInvoice" *ngIf="showInvoice" [modal]="true" [blockScroll]="true" [draggable]="false" [dismissableMask]="true" position="top" appendTo="body" [style]="{width: '800px'}" styleClass="footer-default">
    <ng-template pTemplate = "header">
        <span class="p-dialog-title"><i class="pi pi-file"></i>Invoice</span>
    </ng-template>

    <div class="grid">
        <div class="col-12 text-right mb-3">
            <p-button styleClass="p-button p-button-primary bg-softblue text-blue mr-2" label="Print" icon="pi pi-print" iconPos="right"></p-button>
            <p-button styleClass="p-button p-button-primary bg-softblue text-blue" label="Download" icon="pi pi-download" iconPos="right"></p-button>
        </div>
    </div>

    <div class="card box invoice-section">
        <div class="grid">
            <div class="col-12">
                <div class="grid align-items-center">
                    <div class="col-6">
                        <a href="https://appfibr.io/"><img src="/assets/logo_appfibr.svg" alt="AppFibr Logo" height="30"/></a>
                    </div>
                    <div class="col-6 text-right">
                        <h6 class="mb-0">INVOICE</h6>
                        <h5 class="mt-0"><a href="javascript:;">HFSF897HRT2</a></h5>
                        <p>Billing Date <b>Mon, 20 February 2023</b></p>
                    </div>
                </div>
            </div>
    
            <div class="col-12">
                <div class="grid">
                    <div class="col-12">
                        <div class="grid bg-softblue">
                            <div class="col-12 md:col-6 p-4">
                                <h6><b>Billed to</b></h6>
                                <p class="mb-0">Ramadhanis Putra</p>
                                <p>(Jl. M.T. Haryono No.27, RT.8/RW.9, Tebet)</p>
                            </div>
                            <div class="col-12 md:col-4 p-4">
                                <h6><b>Payment</b></h6>
                                <p class="mb-0">Method <b>GoPay</b></p>
                                <p>Payed Date <b>Tue, 21 February 2023</b></p>
                            </div>
                            <div class="col-12 md:col-2 p-4">
                                <h6><b>Status</b></h6>
                                <span class="p-tag p-component p-tag-success"><span class="p-tag-value">Paid</span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="grid">
                    <div class="col-12">
                        <p-divider styleClass="my-2"></p-divider>
                        <div class="grid">
                            <div class="col-3 md:col-6 pl-5">
                                <span><b>Item</b></span>
                            </div>
                            <div class="col-3 md:col-2 text-right">
                                <span><b>Cost</b></span>
                            </div>
                            <div class="col-3 md:col-2 text-right">
                                <span><b>Qty.</b></span>
                            </div>
                            <div class="col-3 md:col-2 text-right">
                                <span><b>Price</b></span>
                            </div>
                        </div>
                        <p-divider styleClass="my-2"></p-divider>
                    </div>
                </div>
                <div class="grid">
                    <div class="col-12">
                        <h6>Project Plan</h6>
                        <div class="grid">
                            <div class="col-3 md:col-6 pl-5">
                                <label class="label-info">Resto App (Pro Plan Monthly)</label>
                            </div>
                            <div class="col-3 md:col-2 text-right">
                                <span>Rp 100.000</span>
                            </div>
                            <div class="col-3 md:col-2 text-right">
                                <span>1</span>
                            </div>
                            <div class="col-3 md:col-2 text-right">
                                <span>Rp 100.000</span>
                            </div>
                        </div>
                        <p-divider styleClass="my-2"></p-divider>
                    </div>
                </div>
            </div>

            <div class="col-8 md:col-6 ml-auto">
                <div class="grid">
                    <div class="col-6">
                        <label class="label-info">Sub Total</label>
                    </div>
                    <div class="col-6 text-right">
                        <span>Rp 100.000</span>
                    </div>
                </div>
                <div class="grid">
                    <div class="col-6">
                        <label class="label-info">Tax</label>
                    </div>
                    <div class="col-6 text-right">
                        <span>Rp 10.000</span>
                    </div>
                </div>
                <div class="grid">
                    <div class="col-6">
                        <label class="label-info">Discount</label>
                    </div>
                    <div class="col-6 text-right">
                        <span>Rp 0</span>
                    </div>
                </div>
                <div class="grid p-2 mt-3 align-items-center bg-softblue">
                    <div class="col-6">
                        <label class="label-info">Total</label>
                    </div>
                    <div class="col-6 text-right">
                        <h5 class="mb-0 text-blue"><small class="text-lg font-light">Rp</small> 110.000 <small class="text-lg font-light">,-</small></h5>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button styleClass="p-button p-button-primary" label="Pay" icon="pi pi-chevron-right" iconPos="right"></p-button>
    </ng-template>
</p-dialog>