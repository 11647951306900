<div class="mobile-header">
    <div class="left-comp" [ngClass]="{'detail-width': showPageDetails}">
        <button pButton pRipple type="button" icon="pi pi-arrow-left" class="p-button-icon-only p-button-text m-0 mr-2"
        (click)="onButtonClick()" *ngIf="showPageDetails">
        </button>
        <p class="title-page" *ngIf="showPageDetails">Detail</p>
        <p class="title-page" *ngIf="!showPageDetails && 
        (config.layoutDesign?.layoutTemplate === 'cart' && cartInfo?.info === 'Order')">{{config?.pageProperty?.pageTitle}}</p>
        <p class="title-page" *ngIf="!showPageDetails && 
        (config.layoutDesign?.layoutTemplate === 'cart' && cartInfo?.info !== 'Order')">{{cartInfo?.info}}</p>
        <p class="title-page" *ngIf="!showPageDetails && config.layoutDesign?.layoutTemplate !== 'cart'">{{config?.pageProperty?.pageTitle}}</p>
    </div>
    <div class="right-comp">
      <!-- <button pButton pRipple type="button" icon="pi pi-search" class="p-button-icon-only p-button-text mr-2"
      *ngIf="!showPageDetails && (config.layoutDesign?.layoutTemplate!=='summary' && config.layoutDesign?.layoutTemplate!=='cart')" 
      (click)="modalSearch=true"></button>
      <button pButton pRipple type="button" icon="pi pi-sort-alt" class="p-button-icon-only p-button-text mr-2"
      *ngIf="!showPageDetails && (config.layoutDesign?.layoutTemplate!=='summary' && config.layoutDesign?.layoutTemplate!=='cart')" 
      (click)="modalSort=true"></button> -->

      <button pButton pRipple type="button" icon="pi pi-search" class="p-button-icon-only p-button-text mr-2"
      *ngIf="!showPageDetails && !showForm && (config.layoutDesign?.layoutTemplate ==='list' || config.layoutDesign?.layoutTemplate ==='grid' || (config.layoutDesign?.layoutTemplate ==='cart' && stOrder))" 
      (click)="modalSearch=true"></button>
      <button pButton pRipple type="button" icon="pi pi-sort-alt" class="p-button-icon-only p-button-text mr-2"
      *ngIf="!showPageDetails && !showForm && (config.layoutDesign?.layoutTemplate ==='list' || config.layoutDesign?.layoutTemplate ==='grid' || (config.layoutDesign?.layoutTemplate ==='cart' && stOrder))" 
      (click)="modalSort=true"></button>

      <button pButton pRipple type="button" icon="pi pi-filter" class="p-button-icon-only p-button-text mr-2"
      *ngIf="!showPageDetails && (config.layoutDesign?.layoutTemplate ==='summary')" 
      (click)="getSummaryFilter()"></button>
      <button pButton pRipple type="button" icon="pi pi-download" class="p-button-icon-only p-button-text mr-2"
      *ngIf="!showPageDetails && (config.layoutDesign?.layoutTemplate ==='summary')" 
      (click)="getSummaryDownload()"></button>

      <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-icon-only p-button-text p-button-danger mr-2"
      *ngIf="showPageDetails && config.formConfiguration?.delete" (click)="onDeleteClick()"></button>
      <button *ngIf="!isLogin" pButton pRipple type="button" icon="pi pi-ellipsis-v" class="p-button-icon-only p-button-text" 
      (click)="modalAppInfo = true; appAbout()"></button>
      <button *ngIf="isLogin" type="button" pButton pRipple (click)="actionTable.toggle($event)" class="p-button-icon-only p-button-text" label="" icon="pi pi-ellipsis-v" iconPos="right"> </button>
      <p-menu #actionTable appendTo="body" [popup]="true" [model]="itemHeader"></p-menu>
    </div>
</div>
<div #mobileContent class="modal-mobile"></div>

<p-dialog [modal]="false" [appendTo]="mobileContent" [(visible)]="modalAppInfo" position="bottom" [draggable]="false" [resizable]="false">
    <ng-template pTemplate = "header">
        <span class="mx-auto">App Info</span>
    </ng-template>
    <div class="card box d-flex p-2 mb-2">
        <img [src]="srcIcon" class="card box inline-flex border-0 shadow mr-3 mb-0 p-1" width="50" height="50">
        <div class="d-block">
            <label>{{detailAppInfo?.app_name}}</label>
            <p class="text-xs mt-1">{{detailAppInfo?.app_desc}}</p>
        </div>
    </div>
    <div class="grid align-items-center pt-2" [ngClass]="isPaid ? 'justify-content-center text-center' : 'justify-content-between'">
        <div class="col-auto">
            <label class="version-label">Version {{detailAppInfo?.version}}</label>
            <p class="text-xs">Published on {{detailAppInfo?.updated_date}}</p>
        </div>
        <div *ngIf="!isPaid" class="col-auto text-right">
            <p class="text-xs mb-1">Powered by</p>
            <a href="https://appfibr.io/"><img src="/assets/logo_appfibr.svg" alt="AppFibr Logo" class="appinfo-logo"/></a>
        </div>
    </div>
</p-dialog>

<p-dialog [modal]="false" [appendTo]="mobileContent" [(visible)]="modalProfileInfo" position="bottom" [draggable]="false" [resizable]="false">
  <ng-template pTemplate = "header">
      <span class="mx-auto">Profile</span>
  </ng-template>
  <div *ngIf="isLogin" class="grid">
    <div class="col-12">
      <div class="card box overview-box white bg-dark-blue summary-card mb-0 h-auto">
        <div class="overview-info d-flex">
            <img alt="icon" class="mr-3" src="/assets/images/icon/icon_user.svg" height="50">
            <div class="overview-info align-self-center">
                <h6 class="text-white text-sm">Hi, <b class="text-orange">{{userProject?.fullname}}</b></h6>
                <span class="text-white text-xs">{{userProject?.email}}</span>
            </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="userProject?.role !== 'fibr_creator' && userProject?.role !== 'fibr_guest'">
      <p-button styleClass="p-button p-button-secondary2 font-semibold w-100" label="Change Password" (click)="onButtonChangePasswordClick()"> </p-button>
    </div>
    <div class="col-12">
      <p-button styleClass="p-button p-button-secondary2 text-magenta font-semibold w-100" label="Logout" (click)="onLogoutClick()"> </p-button>
    </div>
  </div>
</p-dialog>

<p-dialog [modal]="false" [appendTo]="mobileContent" [(visible)]="modalChangePassword" position="bottom" [draggable]="false" [resizable]="false">
  <form #fr="ngForm">
    <div  class="mobile-comp mobile-form">
      <div class="p-grid p-fluid">
        <img class="img-app" [src]="logo" alt="Logo"/>
        <div class="field" *ngFor="let item of elementForm">
          <ng-container *ngIf="item.field === 'password'">
            <span class="p-float-label">
                <input [name]="item.field" type="password" id="inputtext" pInputText [(ngModel)]="item.value">
                <label for="inputtext">{{item.label}}</label>
            </span>
          </ng-container>
          <ng-container *ngIf="item.field === 'confirmPassword'">
            <span class="p-float-label">
                <input [name]="item.field" type="password" id="inputtext" pInputText [(ngModel)]="item.value">
                <label for="inputtext">{{item.label}}</label>
            </span>
          </ng-container>
        </div>
      </div>
  
      <div class="grid" >
        <div class="col-12 p-0">
          <button pButton pRipple class="p-button p-component w-100" (click)="onChangePassword(fr)">Save</button>
        </div>
      </div>
    </div>
  </form>
</p-dialog>

<!-- <p-dialog *ngIf="!showPageDetails" [modal]="false" [appendTo]="mobileContent" [(visible)]="modalSearch" [showHeader]="false" position="top-left" styleClass="modal-top" [draggable]="false" [resizable]="false"> -->
<p-dialog *ngIf="!showPageDetails && !showForm && (config.layoutDesign?.layoutTemplate ==='list' || config.layoutDesign?.layoutTemplate ==='grid' || (config.layoutDesign?.layoutTemplate ==='cart' && stOrder))" 
[modal]="false" [appendTo]="mobileContent" [(visible)]="modalSearch" [showHeader]="false" position="top-left" styleClass="modal-top" [draggable]="false" [resizable]="false">
    <!-- <ng-template pTemplate = "header">
      <span class="mx-auto">Search Data</span>
    </ng-template> -->
    <div class="flex align-items-center w-100">
      <!-- <div class="col-12">
        <p-dropdown name="field" [options]="fields" optionLabel="name" optionValue="value" [(ngModel)]="column"></p-dropdown>
      </div> --> 
      <div class="w-100">
        <form #f="ngForm">
          <div class="p-inputgroup mobile-search">
              <input name="search" type="text" id="inputtext" pInputText [(ngModel)]="textSearch" placeholder="Search data">
              <button pButton pRipple icon="pi pi-search" class="p-button p-button-primary" (click)="onButtonSearchClick(f)"></button>
              <button pButton pRipple icon="pi pi-refresh" class="p-button p-button-secondary2 clear-search text-grey" (click)="onButtonClearSearch()"></button>
          </div>
        </form>
      </div>
      <div>
        <button type="button" pButton pRipple icon="pi pi-times" class="p-button p-button-text bg-transparent" (click)="refreshSearch()"></button>
      </div>
    </div>
</p-dialog>

<!-- <p-dialog *ngIf="!showPageDetails" [modal]="false" [appendTo]="mobileContent" [(visible)]="modalSort" position="top" [showHeader]="false" position="top-left" styleClass="modal-top" [draggable]="false" [resizable]="false"> -->
<p-dialog *ngIf="!showPageDetails && !showForm && (config.layoutDesign?.layoutTemplate ==='list' || config.layoutDesign?.layoutTemplate ==='grid' || (config.layoutDesign?.layoutTemplate ==='cart' && stOrder))" 
[modal]="false" [appendTo]="mobileContent" [(visible)]="modalSort" position="top" [showHeader]="false" position="top-left" styleClass="modal-top" [draggable]="false" [resizable]="false">
  <div class="flex align-items-center w-100">
    <!-- <div class="col-12">
      <p-dropdown name="field" [options]="fields" optionLabel="name" optionValue="value" [(ngModel)]="column"></p-dropdown>
    </div> -->
    <div class="w-100">
      <form #fr="ngForm">
        <div class="flex align-items-center">
            <!-- <span class="mr-2">Sort</span> -->
            <button type="button" *ngIf="shorData === 1" pButton pRipple icon="pi pi-sort-alpha-down" (click)="onChangeShort(shorData); onButtonSortClick(fr,shorData)"
            class="p-button p-button-text bg-transparent"></button>
            <button type="button" *ngIf="shorData === 2" pButton pRipple icon="pi pi-sort-alpha-down-alt" (click)="onChangeShort(shorData); onButtonSortClick(fr,shorData)"
            class="p-button p-button-text bg-transparent"></button>
            <p-dropdown class="w-100" name="field" [options]="fields" optionLabel="name" optionValue="value" [(ngModel)]="column" placeholder="Select option" 
            (onChange)="onButtonSortClick(fr,shorData)"></p-dropdown>
            <!-- <button pButton pRipple class="p-button p-button-sm p-button-primary" (click)="onButtonSortClick(fr)">Sort</button> -->
        </div>
      </form>
    </div>
    <div>
      <button type="button" pButton pRipple icon="pi pi-times" class="p-button p-button-text bg-transparent" (click)="refreshSort()"></button>
    </div>
  </div>
</p-dialog>
