import { LowerCasePipe } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core-fibr/auth';
import { appConfig, Confirmation, ConnectionService, GlobalcrudService, GlobalService, GridModel, ToastNotificationService } from '@core-fibr/shared';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { environment } from '../../../../../../auth/src/environments/environment';

@Component({
  selector: 'ui-fibr-createnew-template',
  templateUrl: './createnew-template.component.html',
  styleUrls: ['./createnew-template.component.scss']
})
export class CreatenewTemplateComponent implements OnInit {

  gridModel: GridModel = new GridModel();
  appConfig: appConfig = {};
  isGoogleFiles: boolean | undefined;
  tableName = "";
  data: any = {};
  user: any;
  waitProcess: any = {};
  urlWait= "";
  showMsg = false;
  showMsgTable = false;
  isDataExists = false;
  statusApp = "";
  tableKey: any = [];
  pathProjectFirebase = "";
  confirmationTemplate: Confirmation | undefined;
  countGetToken = 0;
  env: any;
  constructor(
    private router: Router, 
    private globalService: GlobalService,
    private globalCrudService: GlobalcrudService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private tolowercase : LowerCasePipe,
    private connectionService: ConnectionService,
    private authService: AuthenticationService,
    private cs : ConfirmationService,
    private toastNotificationService: ToastNotificationService
    ) {
      this.env = this.globalService.decryptEnv(environment);
     }

  ngOnInit(): void {
    // console.log(this.config)
    // console.log(this.ref)
    this.isGoogleFiles = this.config.data?.isGoogleFiles? this.config.data?.isGoogleFiles : false;
    this.gridModel.APIEndpoint = this.config.data?.url ?? '';
    this.globalService.appConfig.subscribe((res: appConfig) => {
      this.appConfig = res;
    });
    this.user = this.globalService.userProfile;
    /** for need trial */
    this.globalCrudService
      .getcollection(`users/${this.user.uid}/projects`)
      .subscribe((res: any) => {
        this.isDataExists = res.length > 0; 
    });
    // this.statusApp = environment.firebase.projectId;
    this.statusApp = this.env.firebase.projectId;
    this.urlWait = `users/${this.user.uid}/profile`;
    this.globalCrudService.getcollection(this.urlWait).subscribe((res: any) => {this.waitProcess = res[0];});
  }

  createBody(projectName: any){
    const body:any = {
      // userId: this.user.uid,
      templateId: this.config.data?.template?.app_id,
      name: projectName
    }
    return body;
  }

  async withTemplate(): Promise<void>{
    if(this.appConfig.app_name == null || this.appConfig.app_name == ''){
      this.showMsg = true;
      return;
    }
    this.showMsg = false;
    this.showMsgTable = false;
    const headers  = new HttpHeaders({ 'Content-Type':  'application/json', Authorization: `Bearer ${this.user?.accessToken}`});
    const body = this.createBody(this.appConfig.app_name);
    // await this.globalService.methodPost(environment.be_dev + `/api/v1/fibr-app/template`,body)
    await this.globalService.methodPostOptions(environment.be_dev + `/api/v2/fibr-app/template`,body, headers)
    .subscribe((res) => {
      this.ref.close();
      this.globalService.changeBlockui(true);
        setTimeout(() => {
          this.globalService.changeBlockui(false);
          this.router.navigate(['/portal/project/editor'], {
            queryParams: { filename: res.data.app_name, fileid: res.data.file_id, appid: res.data.app_id }
          });
        }, 4000);
    },
    (err: any) => {
      this.errResponse(err);
      // if (err?.status == 401) {
      //   this.refreshToken();
      // } 
      // if (err?.status == 500) {
      //   this.toastNotificationService.showNotification(err?.error?.status, err?.error?.message);
      //   this.globalService.changeBlockui(false);
      // }
      // if (err?.status == 400) {
      //   this.toastNotificationService.showNotification(err?.error?.status, err?.error?.message);
      //   this.globalService.changeBlockui(false);
      // }
      // if (err?.status == 403) {
      //   this.globalService.changeBlockui(false);
      //   /** must logout - login */
      //   this.err403();
      // }
    });
  }

  errResponse(err: any, event?: any, action?: any) {
    if (err?.status >= 400 && err?.status < 500) {
      if (err?.status == 401) {
        if (this.countGetToken < 3) {
          setTimeout(() => {
            this.refreshToken();
          }, 2000);
          this.countGetToken++;
        } else {
          this.toastNotificationService.showNotification('error', 'Invalid Token!');
          this.globalService.changeBlockui(false);
        }
      } else if (err?.status == 403) {
        this.globalService.changeBlockui(false);
        this.err403();
      } else {
        this.toastNotificationService.showNotification(err?.error?.status, err?.error?.message);
        this.globalService.changeBlockui(false);
      }
    } else {
      this.toastNotificationService.showNotification(err?.error?.status, err?.error?.message);
      this.globalService.changeBlockui(false);
    }
  }

  async refreshToken(){
    this.globalService.changeBlockui(true);
    const body = { uid: this.user?.uid, refresh_token: this.user?.refreshToken};
    await this.globalService.methodPost(environment.be_dev + `/api/v1/fibr-app/refreshtoken`,body)
    .subscribe(async (res) => {
      this.user.accessToken = res?.data?.accessToken;
      this.user.refreshToken = res?.data?.refreshToken;
      this.globalService.setRefDataToLocalstorage("user_profile", this.user);
      const headers  = new HttpHeaders({ 'Content-Type':  'application/json', Authorization: `Bearer ${res?.data?.accessToken}`});
      const body = this.createBody(this.appConfig.app_name);

      await this.globalService.methodPostOptions(environment.be_dev + `/api/v2/fibr-app/template`,body, headers)
      .subscribe((res) => {
          this.ref.close();
          this.globalService.changeBlockui(true);
          setTimeout(() => {
            this.globalService.changeBlockui(false);
            this.router.navigate(['/portal/project/editor'], {
              queryParams: { filename: res.data.app_name, fileid: res.data.file_id, appid: res.data.app_id }
            });
          }, 4000);
      });
      this.globalService.changeBlockui(false);
    },
    (err: any) => {
      this.errResponse(err)
      this.globalService.changeBlockui(false);
    }); 
  }

async err403(){
    this.confirmationTemplate = {
      header: "Confirmation Session Expired !",
      content: "Click YES to logout and log back in to re-new your session",
      rejectButtonStyleClass: "p-button-secondary",
      dismissableMask: true
    };
    this.cs.confirm({
      message: this.confirmationTemplate.content,
      header: this.confirmationTemplate.header,
      rejectButtonStyleClass: this.confirmationTemplate.rejectButtonStyleClass,
      dismissableMask: this.confirmationTemplate.dismissableMask,
      accept:  () => {
          setTimeout(() => {
            localStorage.removeItem('user_profile');
          }, 1000);
          this.authService.clearAuthToken();
          this.authService.SignOutFire();
      },
      reject: () => {
        // 
      }
    });
  }

  blankTableFetch(appName: any, tableName: any){
    this.globalService.changeBlockui(true);
    this.appConfig.app_id = this.tolowercase.transform((appName)?.split(' ').join('') + this.globalCrudService.generateId());
    this.appConfig.file_id = this.tolowercase.transform((tableName)?.split(' ').join('') + this.globalCrudService.generateId());
    this.appConfig.file_name = appName;
    this.appConfig.version = '0.0.0.0';
    this.appConfig.app_icon = '';
    this.appConfig.build_number = 1;
    this.appConfig.is_suspend = false;
    this.appConfig.is_publish = false;
    this.appConfig.created_date = new Date();
    this.appConfig.updated_date = new Date();
    this.appConfig.config= '';
    this.appConfig.app_desc = '';
    this.appConfig.package_name = '';
    this.appConfig.app_type = '';
    this.appConfig.publish_option = '';
    this.appConfig.app_logo = '';
    this.appConfig.primary_color = '#97DDFF';
    this.appConfig.secondary_color = '#4394D8';
    this.appConfig.font_color = '#1A1A1A';
    this.appConfig.splash_screen = '';
    this.appConfig.login_registration = '';
    this.appConfig.broadcast_content = '';
    this.appConfig.broadcast_title = '';
    this.appConfig.is_paypwa = false;
    this.appConfig.is_payapps = false;
    this.appConfig.category = '';
    this.appConfig.created_by = '';
    this.globalService.setAppConfig(this.appConfig);
    this.globalService.setProjectEditStatus(true);
    this.ref.close();
    // this.connectionService.swicthFirebase(this.user);
    this.connectionService.swicthFirebase(this.user,this.appConfig.app_id);
    const path = `/users/${this.user.uid}/projects`;
    // const profilePath = `/users/${this.user.uid}/profile`;
    const urlDataPathFirebase = `/users/${this.user.uid}/data_projects`;
  
    this.globalCrudService.createcollectionwithdocumentname(this.appConfig, path, this.appConfig.app_id);

    const dataForAdd: any = {};
    const convertArrayToObject: any = {};
    convertArrayToObject[tableName] = {};
    const pathData = `/users/${this.user.uid}/data_projects/${this.appConfig.app_id}/tables`;
    const collectionPath = pathData + "/" + tableName + "/data"; // ######
    convertArrayToObject[tableName]["modify_date"] = new Date();
    convertArrayToObject[tableName]["created_date"] = new Date();
    dataForAdd["modify_date"] = new Date();
    this.tableKey.push(tableName);

    this.globalCrudService.createDynamiccollectionwithdocumentname(convertArrayToObject,urlDataPathFirebase,this.appConfig?.app_id??'');
    this.globalCrudService.createDynamiccollectionwithdocumentname({ key: this.tableKey },urlDataPathFirebase,this.appConfig.app_id??''); // ######
    
    this.globalCrudService.dynamicCreatecollectionWithId(dataForAdd, collectionPath);
    
    this.pathProjectFirebase = `/users/${this.user.uid}/projects/${this.appConfig.app_id}/firebase`;
    this.globalCrudService.getcollection(this.pathProjectFirebase).subscribe((res: any) => {
      if (res[0]?.state == 'ACTIVE') {
        this.globalCrudService.createcollectionwithdocumentname(convertArrayToObject,urlDataPathFirebase,this.appConfig?.app_id??'');
        this.globalCrudService.createcollectionwithdocumentname({ key: this.tableKey },urlDataPathFirebase,this.appConfig.app_id??''); // ######
        this.globalCrudService.createcollection(dataForAdd, collectionPath);
      }
    });

    setTimeout(() => {
      this.globalService.changeBlockui(false);
      this.router.navigate(['/portal/project/editor'], {
        queryParams: { filename: appName, fileid: this.appConfig.file_id, appid: this.appConfig.app_id }
      });
    }, 3000);
  }

}
