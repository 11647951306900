import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { GlobalService } from "@core-fibr/shared";
import { configMobile } from "../../../interfaces/global";

@Component({
  selector: "ui-fibr-mobile-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class ListComponent {
  @ViewChild('uiElement', { static: false }) uiElement: ElementRef | undefined;

  @Input() config: configMobile = {};
  @Input() data: any[] = [];
  @Input() column: any;
  @Input() dataColumn: any;

  @Output() buttonFloatOnclick = new EventEmitter<any>();
  @Output() buttonDetailOnclick = new EventEmitter<any>();
  @Output() scrollLazyLoadAct = new EventEmitter<any>();

  pipe = new DatePipe('en-US');
  dataList:any;
  mainTitleType: any;
  descriptionType: any;
  imageType: any;
  labelType: any;
  userProject: any;
  modalPageInfo: any;
  fieldSum = '';
  totalSum = 0;
  infoGroupData: any[] = [];
  infoHeaderGroup: any;

  constructor(private httpClient: HttpClient,private globalService: GlobalService,) {
    this.userProject = this.globalService.getCurrReffDataParsed("user_project");
  }
  ngOnInit(): void {
    // console.log(this.data, this.column,this.config)
    for (let i=0; i<this.column.length; i++) {
      if (this.column[i].value == this.config.dataContent?.mainTitle ) this.mainTitleType = this.column[i].type;
      if (this.column[i].value == this.config.dataContent?.description ) this.descriptionType = this.column[i].type;
      if (this.column[i].value == this.config.dataContent?.images ) this.imageType = this.column[i].type;
      if (this.column[i].value == this.config.dataContent?.label ) this.labelType = this.column[i].type;
    }
    this.infoHeaderGroup = this.column.filter((x:any) => x.value == this.config.dataContent?.pageInfo?.sum?.column);
  }

  onButtonFloatClick() {
    this.buttonFloatOnclick.emit(true);
  }

  onButtonDetailClick(field: any) {
    // console.log(field)
    this.buttonDetailOnclick.emit(field);
  }

  checkType(val: any): boolean {
    return typeof(val) === 'object';
  }

  getOneCharacter(character : string){
    return character.substring(0,1);
  }

  convertDataApp(result: any[], main:any, desc:any) {
    for (let index = 0; index < result.length; index++) {
      let c = 0, p;
      for (p in result[index]) {
        // console.log(p)
        if (p == this.config.dataContent?.mainTitle && main == 'text_editor') result[index][p].replace(/<[^>]*>/g, '');
        if (p == this.config.dataContent?.description && desc == 'text_editor') result[index][p].replace(/<[^>]*>/g, '');
      }
    }
    return result;
  }

  getSumGroupData() {
    this.modalPageInfo = true;
    let colTypeSum = [];
    let colTypeGroup = [];
    let infoSumData = [];
    /**sum all data in column */
    colTypeSum = this.globalService.cekTypeNumber(this.dataColumn, this.config.dataContent?.pageInfo?.sum?.column);
    infoSumData = this.globalService.getTotalSum(colTypeSum[0], this.data);
    this.fieldSum = infoSumData[0].header;
    this.totalSum = infoSumData[0].sumData;
    /**group */
    colTypeGroup = this.globalService.cekTypeNumber(this.dataColumn, this.config.dataContent?.pageInfo?.group?.column);
    this.infoGroupData = this.globalService.getGroupData(colTypeGroup[0],colTypeSum[0], this.data);
  }

  onScrollLoadData() {
    const nativeElement= this.uiElement?.nativeElement
    if(nativeElement.clientHeight + Math.ceil(nativeElement.scrollTop) === nativeElement.scrollHeight){
      this.scrollLazyLoadAct.emit();
      this.globalService.refreshScrollLazyLoad.subscribe((res: any) => {
        if (res == true) nativeElement.scrollTop=0;
      });
    }
  }
  
}
