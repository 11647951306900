/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { appConfig, historyPublish } from '../../interfaces/global';
import { GlobalcrudService } from '../../services/crud/globalcrud.service';
import { GlobalService } from '../../services/global.service';
import { ToastNotificationService } from '../../services/toast-notification.service';
import { Router } from '@angular/router';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfirmationService } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService, environment } from '@core-fibr/auth';
import { Confirmation, DynamicdialogService, NotifProComponent, InAppPaymentApprovalComponent, SessionService } from '@core-fibr/shared';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ui-fibr-subheader,[uiFibrSubheader]',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss']
})
export class SubheaderComponent implements OnInit {
  @Input() isApptEdit:boolean = true;
  @Input() hideButtonCreate:boolean= false;
  @Input() dataSource: any;

  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  
  loading:boolean= false;
  appConfig: appConfig = {};
  historyPublish: historyPublish = {};
  user: any = {};
  showMockup: boolean = true;
  isCreateNew: boolean = true;

  showLoadingSpinner = false;
  isUploadLogo = false;
  files: any[] = [];
  linkApp: string = '';
  headers: any = "";
  confirmationTemplate: Confirmation | undefined;
  countGetToken = 0;
  createNewConfig: DynamicDialogConfig = {};
  selectedLanguage = "";

  constructor(private globalService: GlobalService, 
    private globalCrudService: GlobalcrudService,
    private toastNotificationService : ToastNotificationService,
    private router: Router,
    private cs : ConfirmationService,
    private sanitizer: DomSanitizer,
    private authService: AuthenticationService,
    private dynamicDialogService: DynamicdialogService,
    private translate: TranslateService,
    public showPaymentApproval: DynamicDialogConfig,
    private sessionService: SessionService,
  ) { }

  ngOnInit(): void {
    this.globalService.isApptEditStatus.subscribe((val: boolean) => this.isApptEdit = val);
    this.globalService.appConfig.subscribe((val: appConfig) => this.appConfig = val);
    this.globalService.isAppCreateNew.subscribe((val: boolean) => this.isCreateNew = val);
    this.user = this.globalService.userProfile;
    this.headers  = new HttpHeaders({ 'Content-Type':  'application/json', Authorization: `Bearer ${this.user?.accessToken}`});
    // this.headers  = new HttpHeaders({ 'Content-Type':  'application/json', Authorization: `Bearer test`});
    this.selectedLanguage = this.sessionService.getItem('fibr-language');
  }

  propertiesClick(event: any){
    this.action.emit({action: 'click', value: true, field: 'properties'})
  }

  createBodyPublish(appConfig: appConfig){
    const body:any = {
      date_publish: new Date(),
      version: appConfig.version,
      app_type: appConfig.app_type,
      publish_at: this.user.partner_name
    }
    // if(this.category_id) body['id'] = this.category_id;
    // else body['id'] = this.globalCrudService.generateId();
    return body;
  }

  publishClick(event: any){
    this.loading = true;
      const path = `/users/${this.user.uid}/projects`;
      const pathPublish = `/users/${this.user.uid}/projects/${this.appConfig.app_id}/history`;
      this.appConfig.updated_date = new Date();
      this.appConfig.is_publish = true;
      // this.globalCrudService.modifyRefNoUpdateDateDynamicColection(this.appConfig, path, this.appConfig.app_id!)
      this.globalCrudService.modifyRefMergeCollection(this.appConfig, path, this.appConfig.app_id!)
      .then (async (result:any) => {
        const url = '/api/fBuild?userId=' + this.user.uid + '&projectId=' + this.appConfig.app_id;
        const url_download = '/api/fDownload?userId=' + this.user.uid + '&projectId=' + this.appConfig.app_id;

        const body = {
          userId : this.user.uid, projectId: this.appConfig.app_id!
        }

        const bodyPublish:any = this.createBodyPublish(this.appConfig);
        this.globalCrudService.createcollection(bodyPublish, pathPublish)
        this.toastNotificationService.showNotification('success', 'Data succesefully published, please wait for download apk!');

        await this.globalCrudService.get(url).subscribe((res:any) => {
          if(res.data){
            this.globalCrudService.get_form_urlencoded(url_download).subscribe((res:any) => {
              this.downLoadFile(res, 'application/vnd.android.package-archive', 'app-release');
            });
          }
        },
        error => {
          this.loading = false;
        })
      })
    // });
  }

  downLoadFile(data: any, type: string, name: string) {
    const blob = new Blob([data], { type: type});
    const url = window.URL.createObjectURL(blob);
    // Create a link element
    const link = document.createElement("a");

    link.href = url;
    link.download = name;
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent('click', { 
        bubbles: true, 
        cancelable: true, 
        view: window 
      })
    );
  
    // Remove link from body
    this.loading = false;
    document.body.removeChild(link);
  }

  saveClick(event: any){
    this.showLoadingSpinner = true;
    let errVal = '';
    const path = `/users/${this.user.uid}/projects`;
    this.appConfig.updated_date = new Date();
    this.globalService.appConfig.subscribe((val: appConfig) => this.appConfig = val);
    errVal = this.validasiPackage(this.appConfig,errVal,'checkAppName');
    if (errVal != '') {
      this.toastNotificationService.showNotification('error', errVal);
    } else {
      this.globalService.addConfigRunning(this.appConfig, environment);
      // this.globalCrudService.modifyRefNoUpdateDateDynamicColection(this.appConfig, path, this.appConfig.app_id!)
      this.globalCrudService.modifyRefMergeCollection(this.appConfig, path, this.appConfig.app_id!)
      .then ((res:any) : void => {
        this.toastNotificationService.showNotification('success', 'Data succesefully saved!');
      })
      .catch();
    }
  }

  /** delete permanent */
  deleteClick(event: any){
    // console.log(this.appConfig)
    if (this.appConfig.is_publish || this.appConfig.is_paid) {
      this.toastNotificationService.showNotification('info', "projects that have been Published or Pro cannot be deleted!, please contact the administrator..");
    } else {
      this.cs.confirm({
        key:"subheader",
        header: 'Delete Application Project',
        message: `Do you want to delete ${this.appConfig.app_name} project?`,
        dismissableMask: true,
        acceptLabel : 'Delete Project',
        rejectLabel : 'Cancel',
        acceptIcon: 'pi pi-trash',
        rejectIcon: 'null',
        acceptButtonStyleClass: "p-button-danger",
        rejectButtonStyleClass: 'p-button-secondary',
        accept: () => {
          const path: string = `/users/${this.user.uid}/projects`;
          const pathData = `users/${this.user.uid}/data_projects`;
          this.globalCrudService.deleteCollection(path, this.appConfig.app_id!)
          .then (() : void => {
              this.toastNotificationService.showNotification('success', 'Data succesefully Deleted!');
              this.globalCrudService
                .deleteCollection(pathData, this.appConfig.app_id!)
                .then((): void => console.log());
              this.router.navigateByUrl('portal/project');
          });
        },
        reject: () => {
          console.log();     
        }
      });
    }
  }

  /**tidak delete hanya kasih flag is_delete = true */
  deleteBeMarket(event: any) {
    if (this.appConfig.is_publish || this.appConfig.is_paid) {
      this.toastNotificationService.showNotification('info', "projects that have been Published or Pro cannot be deleted!, please contact the administrator..");
    } else {
      this.cs.confirm({
        key:"subheader",
        header: 'Delete Application Project',
        message: `Do you want to delete ${this.appConfig.app_name} project?`,
        dismissableMask: true,
        acceptLabel : 'Delete Project',
        rejectLabel : 'Cancel',
        acceptIcon: 'pi pi-trash',
        rejectIcon: 'null',
        acceptButtonStyleClass: "p-button-danger",
        rejectButtonStyleClass: 'p-button-secondary',
        accept: () => {
          const path = `users/${this.user.uid}/projects`;
          this.globalCrudService.modifyRefMergeCollection({is_delete: true, is_delete_date: new Date()}, path, this.appConfig.app_id)
          .then((): void => {
            this.toastNotificationService.showNotification("success","Data succesefully Deleted!");
            this.router.navigateByUrl("portal/project");
          });
        },
        reject: () => {
          console.log();     
        }
      });
    }
  }

  onChangeEvent(event: any){
    //
  }

  copyLinkClick(event: any){
    // this.globalService.appConfig.subscribe((val: appConfig) => this.appConfig = val);
    // this.linkApp = `https://dev-fibr.web.app/app-fibr?appid=${this.appConfig.app_id}`;
    // window.open(this.linkApp); event click buka tab url baru
    // console.log(event);
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = event.data;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    if (event.data) {
      this.toastNotificationService.showNotification('success', 'link copied successfully');
    } else {
      this.toastNotificationService.showNotification('error', 'link failed to copy');
    }
  }

  downloadQrClick(event: any){
    console.log(event.qrcode);
    const fileNameToDownload: string | any = this.appConfig.app_name;
    if(event.qrcode != undefined) {
      // converts base 64 encoded image to blobData
      const blobData = this.convertBase64ToBlob(event.qrcode);

      // saves as image
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE
        window.navigator.msSaveOrOpenBlob(blobData, fileNameToDownload);
      } else { // chrome
        const blob = new Blob([blobData], { type: "image/png" });
        const url = window.URL.createObjectURL(blob);
        // window.open(url);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileNameToDownload;
        link.click();
      }
    }
  }

  private convertBase64ToBlob(Base64Image: any) {
    // SPLIT INTO TWO PARTS
    const parts = Base64Image.split(';base64,');
    // HOLD THE CONTENT TYPE
    const imageType = parts[0].split(':')[1];
    // DECODE BASE64 STRING
    const decodedData = window.atob(parts[1]);
    // CREATE UNIT8ARRAY OF SIZE SAME AS ROW DATA LENGTH
    const uInt8Array = new Uint8Array(decodedData.length);
    // INSERT ALL CHARACTER CODE INTO UINT8ARRAY
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // RETURN BLOB IMAGE AFTER CONVERSION
    return new Blob([uInt8Array], { type: imageType });
  }

  createBodyBuild(params?:any){
    let body: any = {};
    if (!params) {
      body = {
        // userId: this.user.uid,
        output: this.appConfig.app_type,
        projectId: this.appConfig.app_id,
        version: this.appConfig.version,
        lang: this.selectedLanguage
      }
    } else {
      if (params?.status == '415') {
        body = {
          output: this.appConfig.app_type,
          projectId: this.appConfig.app_id,
          version: this.appConfig.version,
          lang: this.selectedLanguage,
          continueBuild: true
        }
      }
    }
    return body;
  }

  async publishAppClick(event: any, etcParams?:any){
    let errVal = '';
    errVal = this.validasiPackage(event.data,errVal,'publish');
    if (errVal != '') {
      this.toastNotificationService.showNotification('error', errVal);
      this.globalService.setStatusPublish(true);
    } else {
      this.globalService.logPageView('AppPublished');
      this.globalService.setStatusPublish(false);
      this.globalService.changeBlockui(true);
      const pathPublish = `/users/${this.user.uid}/projects/${this.appConfig.app_id}/history`;
      /**update collection project*/
      const path = `/users/${this.user.uid}/projects`;
      const AppName: any = this.appConfig.app_name;
      const AppDesc: any = this.appConfig.app_desc;
      const AppPack: any = this.appConfig.package_name; 
      this.appConfig.app_name = this.globalService.replaceString(AppName);
      this.appConfig.app_desc = this.globalService.replaceString(AppDesc);
      this.appConfig.package_name = this.globalService.replacePackageName(AppPack);
      
      this.appConfig.updated_date = new Date();
      this.globalService.addConfigRunning(this.appConfig, environment);
      // this.globalCrudService.modifyRefNoUpdateDateDynamicColection(this.appConfig, path, this.appConfig.app_id!)
      this.globalCrudService.modifyRefMergeCollection(this.appConfig, path, this.appConfig.app_id!)
      const body:any = this.createBodyBuild(etcParams);
      setTimeout(async () => {
        await this.globalService.methodPostOptions(environment.be_dev + `/api/v2/fibr-app/buildinstaller`, body, this.headers)
        .subscribe(( res: any) => {
          this.toastNotificationService.showNotification(res.status, res.message);
          setTimeout(() => {
            this.globalService.changeBlockui(false);
            this.router.navigateByUrl("portal/project");
            // this.router.navigate(["/portal/project"], {})
          }, 3000);
        },
        (err: any) => {
          if(err.status === 412){
            this.showPaymentApproval = {
              header: this.translate.instant('approval'),
              width: "720px",
              contentStyle: { overflow: "auto" },
              styleClass: "dialog-no-footer",
              dismissableMask: true,
              baseZIndex: 10000,
              showHeader: true,
              closable: true,
              data: {},
            };
            this.dynamicDialogService.show(InAppPaymentApprovalComponent, this.showPaymentApproval);
          }
          if(err.status === 415){
            this.cs.confirm({
              message: err?.error?.message,
              header: err?.error?.addon?.header_text,
              rejectButtonStyleClass: "p-button-secondary",
              dismissableMask: true,
              accept:  () => {
                const param = {status: '415'};
                this.publishAppClick(event, param);
              },
              reject: () => {
                // 
              }
            });
          }
          this.errResponse(err,event,'builder');
        });
      }, 1000);
    }
  }

  async checkPackageClick(event: any){
    let errVal = '';
    errVal = this.validasiPackage(event.data,errVal,'checkPackage');
    if (errVal != '') {
      this.toastNotificationService.showNotification('error', errVal);
    } else {
      this.globalService.changeBlockui(true);
      const body = { packageName: event.data.package_name };
      await this.globalService.methodPostOptions(environment.be_dev + `/api/v2/fibr-app/checkpackage`,body, this.headers)
      .subscribe((res) => {
        setTimeout(() => {
          this.globalService.changeBlockui(false);
          this.toastNotificationService.showNotification(res.status, res.message);
        }, 3000);
      },
      (err: any) => {
        this.globalService.changeBlockui(false);
        this.errResponse(err,event,'checkPackage');
      }); 
    }
  }

  async preparePublishApp(event: any) {
    let errVal = '';
    errVal = this.validasiPackage(event.data,errVal,'checkPackage');
    if (errVal != '') {
      this.toastNotificationService.showNotification('error', errVal);
      this.globalService.setStatusPublish(true);
    } else {
      this.globalService.changeBlockui(true);
      this.globalService.setStatusPublish(false);
      const body = { packageName: event.data.package_name };
      await this.globalService.methodPostOptions(environment.be_dev + `/api/v2/fibr-app/checkpackage`,body, this.headers)
      .subscribe((res) => {
        setTimeout(() => {
          // this.globalService.changeBlockui(false);
          this.publishAppClick(event)
        }, 1000);
      },
      (err: any) => {
        this.globalService.changeBlockui(false);
        this.errResponse(err,event,'builder');
      }); 
    }
  }

  validasiPackage(data: any, errVal: any, action: string) {
    if (action == 'publish') {
      if (data.config == '') {
        errVal = errVal + 'page has not been created yet!';
      } else {
        if (data.package_name == '') errVal = errVal + 'package name is required!\n';
        if (data.package_name != '') {
          const cekAppPack = this.globalService.replacePackageName(data.package_name);
          if (cekAppPack != data.package_name) 
            errVal = errVal + 'Package Name '+ data.package_name + ' wrong Format!'
          const spt = data.package_name.split('.');
          if (spt.length < 3) 
            errVal = errVal + 'Package Name '+ data.package_name + ' is not yet in format. for example: id.fiber.example\n'
        }
        // if (data.version == '') errVal = errVal + 'Version is not yet in format. for example: 1.0.0.0';
        if (data.app_name == '') errVal = errVal + 'app name is not empty!\n';
      } 
    }
    if (action == 'checkPackage') {
        if (data.package_name == '') errVal = errVal + 'package name is required!';
        if (data.package_name != '') {
          const cekAppPack = this.globalService.replacePackageName(data.package_name);
          if (cekAppPack != data.package_name) 
            errVal = errVal + 'Package Name '+ data.package_name + ' wrong Format!'
          const spt = data.package_name.split('.');
          if (spt.length < 3) 
            errVal = errVal + 'Package Name '+ data.package_name + ' is not yet in format. for example: id.fiber.example`'
        }
    }
    if (action == 'checkAppName') {
      if (data.app_name == '') errVal = errVal + 'app name is not empty!\n';
    }
    
    return errVal;
  }

  async refreshToken(event: any, action: any){
        this.globalService.changeBlockui(true);
        const body = { uid: this.user?.uid, refresh_token: this.user?.refreshToken};
        await this.globalService.methodPostPromise(environment.be_dev + `/api/v1/fibr-app/refreshtoken`,body)
        .subscribe(async (res) => {
          this.user.accessToken = res?.data?.accessToken;
          this.user.refreshToken = res?.data?.refreshToken;
          this.globalService.setRefDataToLocalstorage("user_profile", this.user);
          this.headers  = new HttpHeaders({ 'Content-Type':  'application/json', Authorization: `Bearer ${res?.data?.accessToken}`});
          if (action == 'cekPackage') {
            this.globalService.changeBlockui(false);
            this.checkPackageClick(event)            
          }
          if (action == 'builder') {
            this.publishAppClick(event);
          }
        },
        (err: any) => {
          this.errResponse(err,event,action);
        }); 
  }

  errResponse(err: any, event: any, action: any) {
    if (err?.status >= 400 && err?.status < 500) {
      if (err?.status == 401) {
        if (this.countGetToken < 3) {
          setTimeout(() => {
            this.refreshToken(event, action);
          }, 2000);
          this.countGetToken++;
        } else {
          this.toastNotificationService.showNotification('error', 'Invalid Token!');
          this.globalService.changeBlockui(false);
        }
      } else if (err?.status == 402) {
        this.updatePro('upgrade');
        this.globalService.changeBlockui(false);
      } else if (err?.status == 403) {
        this.globalService.changeBlockui(false);
        this.err403();
      } else if (err?.status == 415) {
        this.globalService.changeBlockui(false); 
      } else {
        this.toastNotificationService.showNotification(err?.error?.status, err?.error?.message);
        this.globalService.changeBlockui(false);
      }
    } else {
      if (action == 'builder') {
        this.errPackageExsist(event);
        this.globalService.changeBlockui(false);
      } else {
        this.toastNotificationService.showNotification(err?.error?.status, err?.error?.message);
        this.globalService.changeBlockui(false);
      }
    }
  }

  updatePro(act: any) {
    const e = this.appConfig
    this.createNewConfig = {
      header: act == "upgrade" ? this.translate.instant('table.upgrade.project_plan') : this.translate.instant('table.upgrade.extend_pro_plan'),
      width: "1000px",
      contentStyle: { overflow: "auto" },
      styleClass: "dialog-no-footer create-new",
      dismissableMask: true,
      baseZIndex: 10000,
      showHeader: true,
      closable: true,
      data: {e, act}
    };
    this.dynamicDialogService.show(NotifProComponent, this.createNewConfig)
  }

  async err403(){
    this.confirmationTemplate = {
      header: "Confirmation Session Expired !",
      content: "Click YES to logout and log back in to re-new your session",
      rejectButtonStyleClass: "p-button-secondary",
      dismissableMask: true
    };
    this.cs.confirm({
      message: this.confirmationTemplate.content,
      header: this.confirmationTemplate.header,
      rejectButtonStyleClass: this.confirmationTemplate.rejectButtonStyleClass,
      dismissableMask: this.confirmationTemplate.dismissableMask,
      accept:  () => {
          setTimeout(() => {
            localStorage.removeItem('user_profile');
          }, 1000);
          this.authService.clearAuthToken();
          this.authService.SignOutFire();
      },
      reject: () => {
        // 
      }
    });
  }

  async errPackageExsist(event: any){
    // console.log(event)
    this.confirmationTemplate = {
      header: "Confirmation Package Exsist !",
      content: "The package name already used, do you still want to continue?",
      rejectButtonStyleClass: "p-button-secondary",
      dismissableMask: true
    };
    this.cs.confirm({
      message: this.confirmationTemplate.content,
      header: this.confirmationTemplate.header,
      rejectButtonStyleClass: this.confirmationTemplate.rejectButtonStyleClass,
      dismissableMask: this.confirmationTemplate.dismissableMask,
      accept:  () => {
        this.publishAppClick(event);
      },
      reject: () => {
        // 
      }
    });
  }

  backProjectClick(){
    this.confirmationTemplate = {
      header: "Confirm Exit Project Editor",
      content: "Are you sure want to exit from project editor?",
      rejectButtonStyleClass: "p-button-secondary",
      dismissableMask: true
    };
    this.cs.confirm({
      message: this.confirmationTemplate.content,
      header: this.confirmationTemplate.header,
      rejectButtonStyleClass: this.confirmationTemplate.rejectButtonStyleClass,
      dismissableMask: this.confirmationTemplate.dismissableMask,
      accept:  () => {
        this.router.navigate(["/portal/project"], {});
      },
      reject: () => {
        // 
      }
    });
  }

}
