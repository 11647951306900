/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { GlobalService } from '../../../services/global.service';
import { ParserService } from '../../../services/parser.service';
import { ToastNotificationService } from '@core-fibr/shared';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ui-fibr-notif-html',
  templateUrl: './notif-html.component.html',
  styleUrls: ['./notif-html.component.scss']
})
export class NotifHtmlComponent implements OnInit {
  dataNotif:any;
  constructor( public ref: DynamicDialogRef,private globalService: GlobalService, 
    private ts: ToastNotificationService,) { }

  ngOnInit(): void {
    this.globalService.notifDataHtml.subscribe((res: any) => {
      this.dataNotif = res;
    })
  }

  cancelDialog(){
    this.ref.close(false);
  }

}
