import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import {TranslateService} from "@ngx-translate/core";
import {SessionService} from "@core-fibr/shared";

@Component({
  selector: 'ui-fibr-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {
  items: MenuItem[] = [];
  selectedLanguage = "";
  constructor(
    private translate: TranslateService,
    private sessionService: SessionService,
  ) {
    //
   }

  ngOnInit(): void {
    this.items = [
      {
        label: '<span class="flex align-items-center w-100"><img alt="icon" src="/assets/images/icon/icon_flag.svg" height="20"><span class="text-xl font-bold ml-3">ID</span></span>',
        escape: false,
        command: () => {
          this.changeLanguage('id');
        }
      },
      {
        label: '<span class="flex align-items-center w-100"><img alt="icon" src="/assets/images/icon/icon_flag-us.svg" height="20"><span class="text-xl font-bold ml-3">EN</span></span>',
        escape: false,
        command: () => {
          this.changeLanguage('en');
        }
      }
    ];
    this.selectedLanguage = this.sessionService.getItem('fibr-language');
  }

  changeLanguage(lang:string){
    this.translate.use(lang);
    this.sessionService.setItem('fibr-language', lang);
    window.location.reload();
  }

}
