import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Template } from "../../interfaces/template";
import { GlobalcrudService } from "./globalcrud.service";

@Injectable({
  providedIn: "root",
})
export class TemplateService {
  status: string[] = ["OUTOFSTOCK", "INSTOCK", "LOWSTOCK"];
  user: any;

  templateNames: string[] = [
    "Bamboo Watch",
    "Black Watch",
    "Blue Band",
    "Blue T-Shirt",
    "Bracelet",
    "Brown Purse",
    "Chakra Bracelet",
    "Galaxy Earrings",
    "Game Controller",
    "Gaming Set",
    "Gold Phone Case",
    "Green Earbuds",
    "Green T-Shirt",
    "Grey T-Shirt",
    "Headphones",
    "Light Green T-Shirt",
    "Lime Band",
    "Mini Speakers",
    "Painted Phone Case",
    "Pink Band",
    "Pink Purse",
    "Purple Band",
    "Purple Gemstone Necklace",
    "Purple T-Shirt",
    "Shoes",
    "Sneakers",
    "Teal T-Shirt",
    "Yellow Earbuds",
    "Yoga Mat",
    "Yoga Set",
  ];

  constructor(
    private http: HttpClient,
    private globalCrudService: GlobalcrudService
  ) {}

  getCollectionsPromise(collection: string, fieldName: string = "id") {
    collection = collection ?? "";
    return this.globalCrudService.getcollectionorderbydesc(
      collection,
      fieldName
    );
  }

  getCollections(url?: string) {
    url = url ?? "assets/json/templates.json";
    return this.http
      .get<any>(url)
      .toPromise()
      .then((res) => <Template[]>res.data)
      .then((data) => {
        return data;
      });
  }

  getTemplatesSmall() {
    return this.http
      .get<any>("assets/json/templates-small.json")
      .toPromise()
      .then((res) => <Template[]>res.data)
      .then((data) => {
        return data;
      });
  }

  getTemplates(url?: string) {
    url = url ?? "assets/json/templates.json";
    return this.http
      .get<any>(url)
      .toPromise()
      .then((res) => <Template[]>res.data)
      .then((data) => {
        return data;
      });
  }

  getTemplatesWithOrdersSmall() {
    return this.http
      .get<any>("assets/json/templates-orders-small.json")
      .toPromise()
      .then((res) => <Template[]>res.data)
      .then((data) => {
        return data;
      });
  }

  generatePrduct(): Template {
    const template: Template = {
      id: this.generateId(),
      name: this.generateName(),
      description: "Template Description",
      price: this.generatePrice(),
      quantity: this.generateQuantity(),
      category: "Template Category",
      inventoryStatus: this.generateStatus(),
      rating: this.generateRating(),
    };

    template.image = template.name
      ? template.name.toLocaleLowerCase().split(/[ ,]+/).join("-") + ".jpg"
      : "";
    return template;
  }

  generateId() {
    let text = "";
    const possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  generateName() {
    return this.templateNames[Math.floor(Math.random() * Math.floor(30))];
  }

  generatePrice() {
    return Math.floor(Math.random() * Math.floor(299) + 1);
  }

  generateQuantity() {
    return Math.floor(Math.random() * Math.floor(75) + 1);
  }

  generateStatus() {
    return this.status[Math.floor(Math.random() * Math.floor(3))];
  }

  generateRating() {
    return Math.floor(Math.random() * Math.floor(5) + 1);
  }
}
