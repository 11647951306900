import { DatePipe, TitleCasePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { QRCodeComponent, QRCodeModule } from 'angularx-qrcode';
import {
  appConfig,
  configMobile,
  ConnectionService,
  dataContent,
  formConfiguration,
  GlobalcrudService,
  GlobalService,
  GridModelStyle,
  GridModelText,
  layoutDesign,
  pageProperty,
  ShowMockupService,
  ToastNotificationService,
} from "@core-fibr/shared";
import { MenuItem } from "primeng/api";
import { environment } from "@core-fibr/auth";


@Component({
  selector: 'ui-fibr-pwa-unwearable',
  templateUrl: './pwa-unwearable.component.html',
  styleUrls: ['./pwa-unwearable.component.scss']
})
export class PwaUnwearableComponent implements OnInit {
  data: any[] = [];
  dataSummary: any[] = [];
  user: any = {};
  columnData: any[] =[];
  infoSumData: any[] = [];
  infoGroupData: any[] = [];

  urlPathFirebase = "";
  urlDataPathFirebase = "";

  appConfig: appConfig = {};
  configMobile: configMobile = {};
  pageProperty: pageProperty = {};
  layoutDesign!: layoutDesign;
  dataContent: dataContent = {};
  formConfiguration: formConfiguration = {};
  cartConfig: any;
  layoutStyle!: GridModelStyle;
  layoutText!: GridModelText;
  itemPages: MenuItem[] = [];
  paramater: any = {};
  actFrom = "pwa";
  isTemplate = false;
  linkApp!: string ;
  isPaid: boolean | undefined = false;
  isLogin: boolean | undefined = false;
  colTable: any;

  blocked = false;
  pipe = new DatePipe('en-US');
  paymentOnReview = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private globalServiceCrud: GlobalcrudService,
    private showPageDetailService: ShowMockupService,
    private toastNotificationService : ToastNotificationService,
    private connectionService: ConnectionService,
    private titlecase: TitleCasePipe,
  ) { 
    this.route.queryParams.subscribe((params) => {
      this.paramater = params;
      const pathProfile = `users/${params.id}/profile`;
      this.globalServiceCrud.getcollection(pathProfile).subscribe((res) => {
        this.connectionService.swicthFirebase(res[0], params.appid);
      });
      this.globalService.setProjectEditStatus(true);
      this.urlPathFirebase = `/users/${params.id}/projects`;
      this.urlDataPathFirebase = `/users/${params.id}/data_projects`;
      this.globalServiceCrud
        .getcollectionwithid(this.urlPathFirebase, params.appid).subscribe((res: any) => {
          if (res?.app_id) {
            const appConfig: appConfig = {
              app_id: res.app_id,
              app_name: res.app_name,
              app_icon: res.app_icon,
              file_id: res.file_id,
              file_name: res.file_name,
              version: res.version,
              build_number: res.build_number,
              is_suspend: res.is_suspend,
              is_publish: res.is_publish,
              config: res.config,
              app_desc: res.app_desc,
              package_name: res.package_name,
              app_type: res.app_type,
              publish_option: res.publish_option,
              app_logo: res.app_logo,
              primary_color: res.primary_color,
              secondary_color: res.secondary_color,
              font_color: res.font_color,
              splash_screen: res.splash_screen,
              login_registration: res.login_registration,
              updated_date: res.updated_date,
              broadcast_content: res.broadcast_content,
              broadcast_title: res.broadcast_title,
              is_paid: res?.is_paid,
              max_rows: res?.max_rows,
              max_tables: res?.max_tables,
              max_pages: res?.max_pages,
              is_login: res?.is_login,
              is_publish_pwa: res?.is_publish_pwa,
              publish_pwa_date: res?.publish_pwa_date,
              upgrade_pro_date: res?.upgrade_pro_date,
              is_role: res?.is_role,
              status_project: res?.status_project,
              is_registration: res?.is_registration,
              paymentConfig: res?.paymentConfig
            };
            this.appConfig = appConfig;
            this.globalService.setAppConfig(appConfig);
            this.linkApp = environment.domain + `/app-fibr?appid=${res.app_id}&id=${params.id}`;
          }
          if (!res?.paymentConfig?.on_review) {
            this.router.navigate(["app-fibr"], {
              queryParams: {
                id: params.id,
                appid: params.appid,
              },
            });
          }
        });
    });
  }

  ngOnInit(): void {
    this.globalService.isBlocked.subscribe(
      (val: boolean) => (this.blocked = val)
    );
  }

  copyLinkClick(event: any){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = event;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    if (event) {
      this.toastNotificationService.showNotification('success', 'link copied successfully');
    } else {
      this.toastNotificationService.showNotification('error', 'link failed to copy');
    }
  }

}
