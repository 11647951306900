<div class="sticky-phone">
  <div class="content">
      <!-- <ui-fibr-mobile *ngIf="itemPages.length > 0" [config]="configMobile" [data]="data" [itemPages]="itemPages" [params]="paramater"
          [actFrom]="actFrom" [isTemplate]="isTemplate" [appConfig]="appConfig" (menuClick)="modifyPageMenu($event)"></ui-fibr-mobile> -->
    <ui-fibr-mobile *ngIf="itemPages.length > 0" 
      [config]="configMobile" [data]="data" [dataSummary]="dataSummary" [itemPages]="itemPages" [params]="paramater"
      [actFrom]="actFrom" [isPaid]="isPaid" [isLogin]="isLogin" [dataColumn]="columnData" [appConfig]="appConfig" [isTemplate]="isTemplate"
      (menuClick)="modifyPageMenu($event)" (menuClickWithParams)="modifyPageMenu($event)" 
      (backParentClick)="modifyPageMenu($event)"></ui-fibr-mobile>
  </div>
</div>

<ui-fibr-blockui [blocked]="blocked"></ui-fibr-blockui>