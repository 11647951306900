import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private injector: Injector, private authService: AuthenticationService,
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const timeoutValue = req.headers.get('timeout') || this.defaultTimeout;
    const timeoutValueNumeric = Number(timeoutValue);
    const token = this.authService.getAuthToken();
    if (token) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', token)
      });
      // return next.handle(authReq).pipe(timeout(timeoutValueNumeric));
      return next.handle(authReq)
    }

    console.log('ini ya masuk')
    
    return next.handle(req).pipe(
      map((response: any) => {
        // Lakukan sesuatu dengan data dari respons POST di sini
        console.log('response next',response);
        return response; // Mengembalikan respons asli ke pemanggilan HttpClient
      })
    );
  }
}
