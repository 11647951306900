<button (click)="menu.toggle($event)" pButton pRipple type="button"
        class="p-button-rounded p-button-primary mr-3 p-button-text p-button-icon-only">
  <img alt="icon" src="/assets/images/icon/icon_flag.svg" height="20" *ngIf="selectedLanguage === 'id'">
  <img
      alt="icon" src="/assets/images/icon/icon_flag-us.svg"
      height="20"
      *ngIf="selectedLanguage === 'en'"
  />
</button>
<p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
