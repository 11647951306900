import { Component, OnInit } from '@angular/core';
import {MenuItem} from 'primeng/api';
import { GlobalService } from '../../../services/global.service';

@Component({
  selector: 'ui-fibr-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  items: MenuItem[]=[];
  constructor(private globalService: GlobalService) { }

  ngOnInit(): void {
    this.globalService.breadcrumb.subscribe(val => {
      this.items = val;
    });
  }

}
