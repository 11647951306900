<footer class="footer">
    <div class="p-footer grid">
        <div class="col-auto">
            <span *ngIf="app === 'fibr'">{{ 'footer.version' | translate }} {{versionInfo?.current}} <a class="ml-2" href="https://appfibr.io/privacy-policy/" target="_blank">{{ 'footer.privacy.policy' | translate }}</a> | <a href="https://appfibr.io/syarat-ketentuan/" target="_blank">{{ 'footer.tos' | translate }}</a></span>
            <span *ngIf="app !== 'fibr'">Powered by <img src="/assets/logo-fibr.png" height="22" class="ml-1"></span>
        </div>
        <div class="col-auto">
            <span>Copyright © {{currentYear}} <img [src]="app === 'fibr'?'/assets/logo_fibr_footer.svg' : 'assets/sifa/sifa_beta.svg'" [height]="app === 'fibr'?'14' : '22'"> </span>
        </div>
    </div>
</footer>