/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  LoaderService,
  SessionService,
  ToastNotificationService,
} from "@core-fibr/shared";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "libs/auth/src/environments/environment";
import { Subject } from "rxjs";
import { AuthenticationService } from "../../services/authentication/authentication.service";

@Component({
  selector: "ui-fibr-send-email-forgot-password",
  templateUrl: "./send-email-forgot-password.component.html",
  styleUrls: ["./send-email-forgot-password.component.scss"],
})
export class SendEmailForgotPasswordComponent implements OnInit {
  public env = environment;
  email!: string;
  returnUrl = "";

  isProgressSpinner = false;
  selectedValues = false;
  fieldTextType = false;
  fieldTextTypes = false;
  msgs: any;
  country: any;

  logo = environment.logo;
  logoRight = environment.logo_right;
  labelAlt = environment.app_name;
  image_login = environment.image_login;
  image_forgotPassword = environment.image_forgotPassword;
  primaryButtonColor = environment.primary_button_color;

  private unsubscribe: Subject<any> | undefined;

  constructor(
    private router: Router,
    public translate: TranslateService,
    public sessionService: SessionService,
    public loaderService: LoaderService,
    public authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private notif: ToastNotificationService
  ) {
    this.unsubscribe = new Subject();
    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params.returnUrl || "/portal";
    });
  }

  ngOnInit() {
    this.email = "";
  }

  onSubmit() {
    if (this.email == "") {
      this.notif.showNotification("warn", "Please input your email!");
      return;
    }
    this.isProgressSpinner = true;
    this.authenticationService
      .sendLinkForgotPassword(this.email)
      .then(() => {
        this.isProgressSpinner = false;
        // this.notif.showNotification("success","Link has been sent to your email!");
        // this.router.navigateByUrl("auth/login");
      });
  }
}
