<div class="page">
  <!--<div class="container">
    <h1 class="pageTitle">angular-shepherd</h1>

    <h4 class="first-element">Guide your users through a tour of your app.</h4>

     <h5>Installation</h5>

    <div class="install-element">
      Install instructions go here
    </div>

    <h5>Usage</h5>

    <div class="usage-element">
      Usage instructions go here
    </div>

    <div class="medium-8 columns medium-centered text-center">
      <a href="https://github.com/shipshapecode/shepherd" class="shepherd-logo-link">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" style="height: 330px; width: 330px; max-width: 80%;"
             viewBox="0 0 128 128">
          <g>
            <path
              style="fill:#C8C7D5;fill-rule:evenodd;stroke:#C8C7D5;stroke-width:5.87337351;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
              d="m 64.476602,12.02253 c -1.746998,0 -3.347433,0.657731 -4.649754,1.804839 -0.822661,-0.722292 -1.879102,-1.162439 -3.059049,-1.162439 -2.579308,0 -4.680346,2.101041 -4.680345,4.680345 0,0.187347 -0.02147,0.369024 0,0.550629 -0.671413,0.140434 -1.318206,0.35031 -1.957791,0.611809 -1.679568,-2.107155 -4.038671,-3.395544 -6.668726,-3.395544 -4.759401,0 -8.693202,4.304309 -9.177146,9.819547 -2.194415,1.167023 -3.701449,3.775777 -3.701449,6.821679 1e-6,3.50355 2.01045,6.468126 4.741525,7.280536 0.249727,0.722247 0.594366,1.367255 0.978896,1.988381 -4.821807,0.890347 -9.691708,2.858651 -14.132805,5.934555 -6.564517,4.546581 -16.0963443,12.398745 -17.0694924,25.420695 8.6598614,2.498312 17.7260754,0.945137 25.4818764,-3.089639 -1.278886,9.747759 -0.651719,19.02073 2.600191,25.634828 15.232378,30.981319 53.027554,23.090169 62.465776,-1.162438 2.452022,-6.300758 2.481064,-16.593564 0.458857,-27.470258 8.986584,3.211808 19.164344,3.394502 27.929114,-1.101258 -3.46495,-12.634262 -14.38049,-18.536897 -21.71925,-21.749836 -2.26826,-0.993059 -4.567118,-1.745281 -6.882855,-2.263696 10e-7,-0.552075 -0.06868,-1.068327 -0.244724,-1.560115 2.240272,-1.429678 3.76263,-4.252811 3.76263,-7.49467 0,-1.969248 -0.583372,-3.757329 -1.529524,-5.200382 0.472713,-0.730257 0.764762,-1.604961 0.764762,-2.539011 -2e-6,-2.579302 -2.101044,-4.680345 -4.680344,-4.680345 -0.35148,-1e-6 -0.680119,0.04868 -1.009487,0.122362 -1.179596,-3.135671 -4.188937,-5.383925 -7.739393,-5.383925 -2.438803,0 -4.630621,1.075737 -6.148688,2.753143 -1.311329,-0.859325 -2.832744,-1.376571 -4.435621,-1.376571 -1.038832,0 -2.03538,0.233808 -2.967277,0.611809 -1.412677,-2.649484 -3.905176,-4.40503 -6.729907,-4.40503 z"
              id="path3788"></path>
            <path
              style="fill:#C8C7D5;fill-rule:evenodd;stroke:#C8C7D5;stroke-width:2.8195641;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
              d="M 124.03601,65.175285 C 120.57106,52.541025 109.67037,46.643765 102.33162,43.430826 92.16977,38.981924 81.050068,39.382179 73.286856,43.860878 78.120142,62.148557 104.57192,75.159104 124.03601,65.175285 z"
              id="path3697" sodipodi:nodetypes="cscc"></path>
            <path id="path3695"
                  d="M 5.093262,72.390853 C 6.06641,59.368906 15.601955,51.515192 22.166472,46.968611 31.256246,40.673034 42.210475,38.937873 50.661284,41.834332 49.430819,60.648349 26.043136,78.434751 5.093262,72.390853 z"
                  style="fill:#C8C7D5;fill-rule:evenodd;stroke:#C8C7D5;stroke-width:2.8104136;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"></path>
            <path
              style="fill:#C8C7D5;fill-rule:evenodd;stroke:#C8C7D5;stroke-width:2.57602835ffffff10;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none"
              d="M 95.63903,93.760857 C 102.88633,75.138078 89.21093,21.576874 62.76847,21.457723 40.251424,21.35626 22.370189,72.920541 33.191684,94.930509 48.424064,125.91184 86.200808,118.01347 95.63903,93.760857 z"
              id="path3638" sodipodi:nodetypes="csss"></path>
            <path
              style="fill:#C8C7D5;fill-opacity:1;fill-rule:nonzero;stroke:#C8C7D5;stroke-width:2.46970391;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-opacity:1;stroke-dasharray:none;stroke-dashoffset:0;marker:none;visibility:visible;display:inline;overflow:visible;enable-background:accumulate"
              d="m 64.47414,12.010346 c -1.747001,0 -3.354101,0.679444 -4.656421,1.826552 -0.822662,-0.722291 -1.881457,-1.157674 -3.061404,-1.157674 -2.579306,0 -4.682148,2.077118 -4.682147,4.656422 0,0.187347 0.0042,0.384369 0.02573,0.565973 -0.671413,0.140434 -1.315596,0.355927 -1.955181,0.617426 -1.679569,-2.107155 -4.058727,-3.395843 -6.688782,-3.395843 -4.759402,0 -8.674541,4.2864 -9.158486,9.801638 -2.194415,1.167022 -3.730282,3.797237 -3.730282,6.843138 10e-7,3.503551 2.02825,6.442345 4.759326,7.254755 1.191105,3.444852 4.040276,5.891274 7.357659,5.891274 1.189741,1e-6 2.330453,-0.342205 3.344391,-0.900414 2.194762,2.245313 5.035345,3.601652 8.155169,3.601652 4.885245,0 9.134558,-3.309755 11.216572,-8.155168 1.56868,1.473279 3.534259,2.341073 5.685464,2.341073 1.725475,-10e-7 3.324079,-0.587206 4.707873,-1.569291 1.140011,3.573915 4.036084,6.122808 7.434838,6.122808 1.658692,0 3.200485,-0.604258 4.476338,-1.646469 0.822663,0.722291 1.881454,1.157673 3.061404,1.157673 2.579302,0 4.682148,-2.102845 4.682148,-4.682147 0,-0.552076 -0.08121,-1.077502 -0.257261,-1.569291 2.240268,-1.429678 3.756006,-4.244431 3.756006,-7.48629 0,-1.969248 -0.571685,-3.779341 -1.517837,-5.222395 0.472709,-0.730257 0.746056,-1.587106 0.746056,-2.521156 0,-2.579302 -2.07712,-4.682147 -4.65642,-4.682147 -0.351481,0 -0.699676,0.05495 -1.029044,0.128631 -1.1796,-3.135673 -4.193095,-5.376752 -7.743551,-5.376752 -2.4388,0 -4.630467,1.049558 -6.148534,2.726964 -1.311332,-0.859325 -2.822009,-1.363482 -4.424886,-1.363482 -1.03883,0 -2.026603,0.239425 -2.958499,0.617427 -1.412678,-2.649486 -3.915503,-4.424887 -6.740234,-4.424887 z"
              id="path3643"></path>
            <path
              style="fill: #323040;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:3;marker:none;visibility:visible;display:inline;overflow:visible;enable-background:accumulate"
              id="path3183" sodipodi:cx="72" sodipodi:cy="48" sodipodi:rx="4" sodipodi:ry="4"
              d="m 76,48 a 4,4 0 1 1 -8,0 4,4 0 1 1 8,0 z"
              transform="matrix(1.4077938,0.63339841,-0.63339841,1.4077938,-19.160477,-48.663084)"></path>
            <path transform="matrix(0.55763261,0.25089158,-0.25089158,0.55763261,25.499182,19.393293)"
                  d="m 76,48 a 4,4 0 1 1 -8,0 4,4 0 1 1 8,0 z" sodipodi:ry="4" sodipodi:rx="4" sodipodi:cy="48"
                  sodipodi:cx="72" id="path3185"
                  style="fill:#C8C7D5;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:3;marker:none;visibility:visible;display:inline;overflow:visible;enable-background:accumulate"
                  sodipodi:type="arc"></path>
            <path transform="matrix(1.4077938,0.63339841,-0.63339841,1.4077938,6.1884772,-48.714859)"
                  d="m 76,48 a 4,4 0 1 1 -8,0 4,4 0 1 1 8,0 z" sodipodi:ry="4" sodipodi:rx="4" sodipodi:cy="48"
                  sodipodi:cx="72" id="path3195"
                  style="fill: #323040;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:3;marker:none;visibility:visible;display:inline;overflow:visible;enable-background:accumulate"
                  sodipodi:type="arc"></path>
            <path sodipodi:type="arc"
                  style="fill:#C8C7D5;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:3;marker:none;visibility:visible;display:inline;overflow:visible;enable-background:accumulate"
                  id="path3197" sodipodi:cx="72" sodipodi:cy="48" sodipodi:rx="4" sodipodi:ry="4"
                  d="m 76,48 a 4,4 0 1 1 -8,0 4,4 0 1 1 8,0 z"
                  transform="matrix(0.55763261,0.25089158,-0.25089158,0.55763261,50.848137,19.341517)"></path>
            <path sodipodi:nodetypes="cscc" id="path3771"
                  d="m 62.730297,104.16926 c 0.254399,-2.9405 -0.94901,-4.815282 -2.342603,-6.794659 -1.795788,-2.550619 -3.837744,-2.891339 -5.969977,-3.468996 4.306096,2.273708 7.123485,6.232355 8.31258,10.263655 z"
                  style="fill: #323040;fill-rule:evenodd;stroke:#C8C7D5;stroke-width:0.91852134px;stroke-linecap:round;stroke-linejoin:round;stroke-opacity:1"
                  inkscape:connector-curvature="0"></path>
            <path
              style="fill: #C8C7D5;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:3;marker:none;visibility:visible;display:inline;overflow:visible;enable-background:accumulate"
              d="m 57.582794,99.189411 c -1.04691,0.675729 -2.670798,0.02461 -3.624766,-1.45337 -0.953969,-1.477982 -0.878544,-3.225919 0.168366,-3.901647 1.046902,-0.675725 2.670798,-0.02461 3.624764,1.453368 0.953968,1.477986 0.878539,3.225924 -0.168364,3.901649 z"
              id="path3775" inkscape:connector-curvature="0"></path>
            <path
              style="fill: #323040;fill-rule:evenodd;stroke:#C8C7D5;stroke-width:0.97772896px;stroke-linecap:round;stroke-linejoin:round;stroke-opacity:1"
              d="m 68.925035,104.54034 c -0.407054,-3.11525 0.785669,-5.164853 2.175787,-7.334513 1.791312,-2.795819 3.947005,-3.252955 6.187703,-3.966252 -4.473746,2.61788 -7.286107,6.958495 -8.36349,11.300765 z"
              id="path3773" sodipodi:nodetypes="cscc" inkscape:transform-center-x="-7.3416612"
              inkscape:transform-center-y="-3.1426362" inkscape:connector-curvature="0"></path>
            <path id="path3778"
                  d="m 77.646616,92.972253 c 1.066673,0.637145 1.202349,2.376414 0.302854,3.882303 -0.899499,1.505885 -2.495224,2.210956 -3.561897,1.573814 -1.066667,-0.637142 -1.202345,-2.376413 -0.302849,-3.882301 0.899495,-1.505888 2.495224,-2.210957 3.561892,-1.573816 z"
                  style="fill: #C8C7D5;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:3;marker:none;visibility:visible;display:inline;overflow:visible;enable-background:accumulate"
                  inkscape:transform-center-x="-2.0288668" inkscape:transform-center-y="1.2173202"
                  inkscape:connector-curvature="0"></path>
          </g>
        </svg>
      </a>
    </div>
  </div> -->
</div>
<div class="test-modal-container"></div>