import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgForm } from "@angular/forms";
import { environment } from "@core-fibr/auth";
import { Confirmation, GlobalcrudService, GlobalService, ToastNotificationService } from "@core-fibr/shared";
import { FileDocumentModel } from "libs/dynamic-form/src/lib/models/field-config";
import { ConfirmationService } from "primeng/api";
import { configMobile } from "../../../interfaces/global";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ResizeImageService } from "libs/shared/src/lib/services/resize-image.service";
import { Observable, Subscriber, Subscription } from "rxjs";
import { take } from 'rxjs/operators';
import { NgxScannerQrcodeService, ScannerQRCodeResult } from "ngx-scanner-qrcode";
import { DatePipe } from "@angular/common";
import { ParserService } from "../../../services/parser.service";

@Component({
  selector: "ui-fibr-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class FormComponent implements OnInit {
  public readonly _env = environment;
  @Input() config: configMobile = {};
  @Input() data: any[] = [];
  @Input() actEdit: boolean | undefined;
  @Input() dataDetail: any;
  @Input() params: any;
  @Input() actFrom: any;
  @Input() isTemplate: any;
  @Input() isLogin: any;
  @Input() isPaid: any;
  @Input() itemSelected: any;

  @Output() buttonFloatOnclick = new EventEmitter<any>();
  @Output() buttonSearchClick = new EventEmitter<any>();
  elementForm: any = [];
  bodyForm: any = {}
  listFile: any = [];
  fileUpload: any;
  isUploadImage = false;
  confirmationTemplate: Confirmation | undefined;
  tablePath = "";
  columnPath = "";
  fieldTable: any = [];
  fieldFormat: any = [];
  isActFrom!: boolean;
  userProject: any;
  max_pages = 0;
  max_rows = 0;
  max_tables = 0;
  max_upload = 0;
  statusProj = "";
  dataId: any;
  contohScan='';
  fieldQrCode = '';
  formQrCode: any;
  pipe = new DatePipe('en-US');
  
  // compressedImage : Observable<ICompressedImage> | undefined;
  selectedHour: any;
  tblName:any;
  tblColumn:any;
  isExternalDtSource:any = false;
  currentPosition:any;
  lblBtnSave:any;
  lblBtnCancel:any;
  constructor(
    private globalServiceCrud: GlobalcrudService,
    private globalService: GlobalService,
    private cs: ConfirmationService,
    private toastNotificationService: ToastNotificationService,
    private compressImage: ResizeImageService,
    private parser: ParserService,
  ) {
    // this.initMap()
  }

  async ngOnInit(): Promise<void> {
    this.initMap()
    this.isExternalDtSource = this.config.dataContent?.isExternalDataSource;
    this.isActFrom = this.actFrom == "app";
    this.dataId = this.dataDetail?.id;
    this.tblName = this.config.dataContent?.contentDataSource;
    if (this.isExternalDtSource) {
      this.tablePath = this.globalService.getPathDataExternalSource(this.tblName,this.params)
    } else {
      this.tablePath = `/users/${this.params.id}/data_projects/${this.params.appid}/tables/${this.tblName}/data`;
    }
    
    if (this.isTemplate) {
      this.columnPath = `/templates/${this.params.appid}/tables`; 
    } else {
      if (this.isExternalDtSource) {
        this.columnPath = this.globalService.getPathColumnExternalSource(this.tblName,this.params.id)
      } else {
        this.columnPath = `/users/${this.params.id}/data_projects/${this.params.appid}/tables`;
      }
    }
      

    const infoProject: any = await this.globalServiceCrud.getCollectionwithidPromise(`/users/${this.params.id}/projects`, this.params.appid);
    this.max_upload = infoProject?.max_upload;
    this.max_rows = infoProject?.max_rows;
    if (this.tblName != 'roleapp_fibr' && this.tblName != 'userapp_fibr') {
      this.max_rows = infoProject?.max_rows;
    } else {
      if (this.tblName == 'userapp_fibr') this.max_rows = infoProject?.users_config?.max;
      if (this.tblName == 'roleapp_fibr') this.max_rows = infoProject?.roles_config?.max;
    }
    this.max_pages = infoProject?.max_pages;
    this.max_tables = infoProject?.max_tables;

    // const col = await this.globalServiceCrud.getDynamicCollectionwithidPromise(this.columnPath, this.tblName);
    let col:any
    if (this.isExternalDtSource) {
      col = await this.globalServiceCrud.getCollectionwithidPromise(this.columnPath, this.tblName+'_struct');
    } else {
      col = await this.globalServiceCrud.getDynamicCollectionwithidPromise(this.columnPath, this.tblName);
    }
    this.tblColumn = col?.columns;
    this.elementForm = this.initForm(this.config?.formConfiguration?.formContent);

    if (this.actEdit) {
      if (this.isExternalDtSource) {
        this.globalServiceCrud.getCollectionwithidPromise(this.tablePath, this.dataId).then((res) => {
          this.inialEdit(res);
        });
      } else {
        this.globalServiceCrud.getDynamicCollectionwithidPromise(this.tablePath, this.dataId).then((res) => {
          this.inialEdit(res);
        });
      }
    }
    if (!this.actEdit) this.initClear();

    const basket: any = [] 
    this.fieldFormat = this.tblColumn;
    this.tblColumn.forEach( (col: any) => {
        basket.push(col?.field);
    });
    this.fieldTable = Object.values(basket).map(key => ({field: key, value: ""}));
      /**jika menggunakan cloud function kondisi other column */
    this.fieldTable.push({field: 'pageId', value: this.itemSelected?.id});
    
    this.userProject = this.globalService.getCurrReffDataParsed("user_project");
    if (!this.isPaid) { 
      this.statusProj = 'Free';
    } else {
      this.statusProj = 'Pro';
    }
    if (!this.config.formConfiguration?.button) {
      this.lblBtnSave = 'Save';
      this.lblBtnCancel = 'Cancel';
    } else {
      this.lblBtnSave = this.config.formConfiguration?.button?.save?.title;
      this.lblBtnCancel = this.config.formConfiguration?.button?.cancel?.title;
    }
  }

  inialEdit(res:any) {
      this.dataDetail = res;
      this.dataDetail.id = this.dataId;
      this.dataDetail = this.globalService.convertDataOneOriginal(this.dataDetail);
      this.initEdit(this.dataDetail);
  }

  clearChildForm(elementForm: any) {
    return elementForm.filter((x:any) => x.type !='childQrcode' && x.type !='childCascading');
  }

  initForm(elementForm: any) {
    /**adanya element form yang diambilkan dari  qrConfig?.fields.targetColumn menyebabkan element form tidak bisa realtime saat di drag n drop*/
    elementForm = this.clearChildForm(elementForm);
    // console.log(elementForm)
    const elFrm: any = [];
    for (let z=0; z<elementForm.length; z++) {
      elFrm.push(elementForm[z])
      if (elementForm[z].type == 'qrcode' && elementForm[z]?.qrConfig?.type == 'asform') {
        const childQr = elementForm[z]?.qrConfig?.fields.length;
        if (childQr > 0) {
          for (let x=0; x < elementForm[z]?.qrConfig?.fields.length; x++) {
            const ssCol = this.tblColumn.filter((cl:any) => cl.field == elementForm[z]?.qrConfig?.fields[x]?.targetColumn);
            elFrm.push({type: 'childQrcode',validations: '',field: elementForm[z]?.qrConfig?.fields[x].targetColumn, 
            label: ssCol[0]?.header, typeChild: ssCol[0]?.type, fieldParent: elementForm[z]?.field});
          }
        }
      } 
      if (elementForm[z].type == 'cascading') {
        const childCas = elementForm[z]?.configCascading?.child;
        if (childCas.length > 0) {
          for (let x=0; x < childCas.length; x++) {
            const ssCol = this.tblColumn.filter((cl:any) => cl.field == elementForm[z]?.configCascading?.child[x]?.targetColumn);
            elFrm.push({type: 'childCascading',validations:elementForm[z]?.validations,field: elementForm[z]?.configCascading?.child[x].targetColumn, 
            label: ssCol[0]?.header, typeChild: ssCol[0]?.type, valueDynamic:[], fieldParent:elementForm[z]?.configCascading?.parent?.sourceColumn, 
            config: elementForm[z], childNo: x});
          }
        }
      }
      if (elementForm[z].type == 'calculation_form') {
        const childCal = elementForm[z]?.configCalculation?.variableList;
        if (childCal.length > 0) {
          for (let x=0; x < elementForm[z]?.configCalculation?.variableList.length; x++) {
            let readonly = false;
            if (elementForm[z]?.configCalculation?.variableList[x]?.isDataReference) readonly = true; else readonly = false;
            const ssCol = this.tblColumn.filter((cl:any) => cl.field == elementForm[z]?.configCalculation?.variableList[x]?.targetColumn);
            elFrm.push({type: 'childCalculation',validations: elementForm[z]?.validations, field: elementForm[z]?.configCalculation?.variableList[x]?.targetColumn, 
            label: ssCol[0]?.header, typeChild: ssCol[0]?.type, fieldParent: elementForm[z]?.field, status: 'input', readOnly: readonly,
            configParent: elementForm[z]?.configCalculation});
          }
          elFrm.push({type: 'childCalculation',validations: elementForm[z]?.validations, field: elementForm[z]?.configCalculation?.configFormula?.targetColumn, 
          label: elementForm[z]?.configCalculation?.configFormula?.label, typeChild: '', fieldParent: elementForm[z]?.field, status: 'result',
          configParent: elementForm[z]?.configCalculation});
        }
      } 
    }

    for (let i = 0; i < elFrm.length; i++) {
      if (elFrm[i].type == 'select' || elFrm[i].type == 'checkbox' || elFrm[i].type == 'radio') {
        let pathDynamic = '';
        if (elFrm[i]?.data_type == 'dynamic' && elFrm[i]?.isExternalSource == false) {
          pathDynamic = `/users/${this.params.id}/data_projects/${this.params.appid}/tables/${elFrm[i]?.source}/data`;
          this.globalService.changeBlockui(true);
          this.globalServiceCrud.dynamicColection(pathDynamic)
          .subscribe((response) => {
            if (response) {
              elFrm[i].valueDynamic = [];
              const dataDinamic: any = []
              response.forEach((dt: any) => {
                if (dt[elFrm[i]?.sourceColumn] != null && dt[elFrm[i]?.sourceColumn] != '' && dt[elFrm[i]?.sourceColumn] != undefined) {
                  dataDinamic.push(dt[elFrm[i]?.sourceColumn]);
                }
              });
              elFrm[i].valueDynamic = dataDinamic;
              this.globalService.changeBlockui(false);
            }
          });
        } else if (elFrm[i]?.data_type == 'dynamic' && elFrm[i]?.isExternalSource == true){
          pathDynamic = `/users/${this.params.id}/${elFrm[i]?.source}/${this.params.appid}/data`;
          this.globalService.changeBlockui(true);
          this.globalServiceCrud.getcollection(pathDynamic)
          .subscribe((response) => {
            if (response) {
              elFrm[i].valueDynamic = [];
              const dataDinamic: any = []
              response.forEach((dt: any) => {
                if (dt[elFrm[i]?.sourceColumn] != null && dt[elFrm[i]?.sourceColumn] != '' && dt[elFrm[i]?.sourceColumn] != undefined) {
                 dataDinamic.push(dt[elFrm[i]?.sourceColumn]);
                }
              });
              elFrm[i].valueDynamic = dataDinamic;
              this.globalService.changeBlockui(false);
            }
          });
        }
      }
      if (elFrm[i].type == 'cascading') {
        const pathDynamic = `/users/${this.params.id}/data_projects/${this.params.appid}/tables/${elFrm[i]?.configCascading?.parent?.contentDataSource}/data`;
        this.globalServiceCrud.dynamicColection(pathDynamic).subscribe((response) => {
          if (response) {
            const dataDinamic: any = []
            response.forEach((dt: any) => {
              if (dt[elFrm[i]?.configCascading?.parent?.sourceColumn] != '' && dt[elFrm[i]?.configCascading?.parent?.sourceColumn] != null && 
              dt[elFrm[i]?.configCascading?.parent?.sourceColumn] != undefined) {
                dataDinamic.push(dt[elFrm[i]?.configCascading?.parent?.sourceColumn]);
              }
            });
            const readParent: any = [];
            let arrRead = [];
            for (let y=0; y<dataDinamic.length; y++) {
              arrRead = readParent.filter((xx:any) => xx == dataDinamic[y]);
              if (arrRead.length == 0) {
                readParent.push(dataDinamic[y])
              }
            }
            elFrm[i].valueDynamic = readParent;
          }
        });
      }
      if (elFrm[i].type == 'calculation_form') {
        const dtSource = elFrm[i]?.configCalculation?.dataReference;
        const pathDynamic = `/users/${this.params.id}/data_projects/${this.params.appid}/tables/${dtSource?.contentDataSource}/data`;
        this.globalService.changeBlockui(true);
        this.globalServiceCrud.dynamicColection(pathDynamic).subscribe((response) => {
          if (response) {
              elFrm[i].valueDynamic = [];
              const dataDinamic: any = []
              response.forEach((dt: any) => {
                if (dt[dtSource?.sourceColumn] != null && dt[dtSource?.sourceColumn] != '' && dt[dtSource?.sourceColumn] != undefined) {
                  dataDinamic.push(dt[dtSource?.sourceColumn]);
                } 
              });
              elFrm[i].valueDynamic = dataDinamic;
              this.globalService.changeBlockui(false);
          }
        });
      }
    }
    // console.log(elFrm)
    return elFrm;
  }

  getDataCascading(value: any, config:any, act:any) {
    // console.log(value,config,this.elementForm)
    if (act == 'parent') {
      if (config?.configCascading.child.length > 0) {
        const pathDynamic = `/users/${this.params.id}/data_projects/${this.params.appid}/tables/${config?.configCascading?.parent?.contentDataSource}/data`;
        this.globalService.changeBlockui(true);
        this.globalServiceCrud.dynamicColection(pathDynamic).subscribe((response) => {
          if (response) {
            const arrData_1: any = response.filter((y:any) => y[config?.configCascading?.parent?.sourceColumn] == value)
            const readChild: any = [];
            let arrRead = [];
            for (let y=0; y<arrData_1.length; y++) {
              arrRead = readChild.filter((xx:any) => xx == arrData_1[y][config?.configCascading?.child[0]?.sourceColumn]);
              if (arrRead.length == 0) {
                readChild.push(arrData_1[y][config?.configCascading?.child[0]?.sourceColumn])
              }
            }
            this.injectDataCascading(0,config?.field,readChild)
            this.globalService.changeBlockui(false);
          }
        });
      }
    }
    if (act == 'child') {
      const pathDynamic = `/users/${this.params.id}/data_projects/${this.params.appid}/tables/${config?.config?.configCascading?.parent?.contentDataSource}/data`;
      if (config?.childNo == 0) {
        if (config?.config?.configCascading.child.length > 1) {
          /** ambil parent value */
          const parentVal = this.elementForm.find((x:any) => x.type == 'cascading' && x.field == config.config.field);
          this.globalServiceCrud.dynamicColection(pathDynamic).subscribe((response) => {
            if (response) {
              const arrData: any = response.filter((y:any) => y[config?.config?.configCascading?.parent?.sourceColumn] == parentVal.component && 
              y[config?.config?.configCascading?.child[0]?.sourceColumn] == value)
              let readChild: any = [];
              let arrRead = [];
              if (config?.config?.configCascading.child.length == 1) {
                readChild = arrData;
              } else {
                for (let y=0; y<arrData.length; y++) {
                  arrRead = readChild.filter((xx:any) => xx == arrData[y][config?.config?.configCascading?.child[1]?.sourceColumn]);
                  if (arrRead.length == 0) {
                    readChild.push(arrData[y][config?.config?.configCascading?.child[1]?.sourceColumn])
                  }
                }
              }
              this.injectDataCascading(1,config?.config?.field,readChild)
              this.globalService.changeBlockui(false);
            }
          });
        }
      }
      if (config?.childNo == 1) {
        if (config?.config?.configCascading.child.length > 2) {
          /** ambil parent value */
          const parentVal = this.elementForm.find((x:any) => x.type == 'cascading' && x.field == config.config.field);
          const child1Val = this.elementForm.find((x:any) => x.type == 'childCascading' && x.config.field == config.config.field && x.childNo == 0);
          this.globalServiceCrud.dynamicColection(pathDynamic).subscribe((response) => {
            if (response) {
              const arrData: any = response.filter((y:any) => y[config?.config?.configCascading?.parent?.sourceColumn] == parentVal.component && 
              y[config?.config?.configCascading?.child[0]?.sourceColumn] == child1Val.component && 
              y[config?.config?.configCascading?.child[1]?.sourceColumn] == value)
              let readChild: any = [];
              let arrRead = [];
              if (config?.config?.configCascading.child.length == 2) {
                readChild = arrData;
              } else {
                for (let y=0; y<arrData.length; y++) {
                  arrRead = readChild.filter((xx:any) => xx == arrData[y][config?.config?.configCascading?.child[2]?.sourceColumn]);
                  if (arrRead.length == 0) {
                    readChild.push(arrData[y][config?.config?.configCascading?.child[2]?.sourceColumn])
                  }
                }
              }
              this.injectDataCascading(2,config?.config?.field,readChild)
              this.globalService.changeBlockui(false);
            }
          });
        }
      }
      if (config?.childNo == 2) {
        if (config?.config?.configCascading.child.length > 3) {
          const parentVal = this.elementForm.find((x:any) => x.type == 'cascading' && x.field == config.config.field);
          const child1Val = this.elementForm.find((x:any) => x.type == 'childCascading' && x.config.field == config.config.field && x.childNo == 0);
          const child2Val = this.elementForm.find((x:any) => x.type == 'childCascading' && x.config.field == config.config.field && x.childNo == 1);
          this.globalServiceCrud.dynamicColection(pathDynamic).subscribe((response) => {
            if (response) {
              const arrData: any = response.filter((y:any) => y[config?.config?.configCascading?.parent?.sourceColumn] == parentVal.component && 
              y[config?.config?.configCascading?.child[0]?.sourceColumn] == child1Val.component &&
              y[config?.config?.configCascading?.child[1]?.sourceColumn] == child2Val.component && 
              y[config?.config?.configCascading?.child[2]?.sourceColumn] == value)
              let readChild: any = [];
              let arrRead = [];
              if (config?.config?.configCascading.child.length == 3) {
                readChild = arrData;
              } else {
                for (let y=0; y<arrData.length; y++) {
                  arrRead = readChild.filter((xx:any) => xx == arrData[y][config?.config?.configCascading?.child[3]?.sourceColumn]);
                  if (arrRead.length == 0) {
                    readChild.push(arrData[y][config?.config?.configCascading?.child[3]?.sourceColumn])
                  }
                }
              }
              this.injectDataCascading(3,config?.config?.field,readChild)
              this.globalService.changeBlockui(false);
            }
          }); 
        }
      }
      if (config?.childNo == 3) {
        if (config?.config?.configCascading.child.length > 4) {
          /** ambil parent value */
          const parentVal = this.elementForm.find((x:any) => x.type == 'cascading' && x.field == config.config.field);
          const child1Val = this.elementForm.find((x:any) => x.type == 'childCascading' && x.config.field == config.config.field && x.childNo == 0);
          const child2Val = this.elementForm.find((x:any) => x.type == 'childCascading' && x.config.field == config.config.field && x.childNo == 1);
          const child3Val = this.elementForm.find((x:any) => x.type == 'childCascading' && x.config.field == config.config.field && x.childNo == 2);
          this.globalServiceCrud.dynamicColection(pathDynamic).subscribe((response) => {
            if (response) {
              const arrData: any = response.filter((y:any) => y[config?.config?.configCascading?.parent?.sourceColumn] == parentVal.component && 
              y[config?.config?.configCascading?.child[0]?.sourceColumn] == child1Val.component &&
              y[config?.config?.configCascading?.child[1]?.sourceColumn] == child2Val.component && 
              y[config?.config?.configCascading?.child[2]?.sourceColumn] == child3Val.component &&
              y[config?.config?.configCascading?.child[3]?.sourceColumn] == value)
              let readChild: any = [];
              let arrRead = [];
              if (config?.config?.configCascading.child.length == 4) {
                readChild = arrData;
              } else {
                for (let y=0; y<arrData.length; y++) {
                  arrRead = readChild.filter((xx:any) => xx == arrData[y][config?.config?.configCascading?.child[4]?.sourceColumn]);
                  if (arrRead.length == 0) {
                    readChild.push(arrData[y][config?.config?.configCascading?.child[4]?.sourceColumn])
                  }
                }
              }
              this.injectDataCascading(4,config?.config?.field,readChild)
              this.globalService.changeBlockui(false);
            }
          }); 
        }
      }
      if (config?.childNo == 4) {
        if (config?.config?.configCascading.child.length > 5) {
          // 
        }
      }
    }
  }

  injectDataCascading(childNo: number, column: any, data: any) {
    const arrDt = data.filter((x:any) => x != null && x != '' && x != undefined);
    for (let x=0; x<this.elementForm.length; x++) {
      if (this.elementForm[x].type == 'childCascading' && this.elementForm[x]?.config?.field == column && this.elementForm[x]?.childNo == childNo) {
        this.elementForm[x].valueDynamic = arrDt;
        const comp = data.filter((y:any) => y == this.elementForm[x].component);
        if (comp.length == 0) {
          this.elementForm[x].component = '';
        }
      }
    }
  }

  clearChildCascading(childNo: number, column: any) {
    for (let x=0; x<this.elementForm.length; x++) {
      if (this.elementForm[x].type == 'childCascading' && this.elementForm[x]?.config?.field == column && this.elementForm[x]?.childNo > childNo) {
        this.elementForm[x].valueDynamic = [];
        this.elementForm[x].component = '';
      }
    }
  }

  initClear() {
    this.elementForm.forEach((doc: any) => {
      if (!doc?.component)
        doc.component = '';
      else
        doc.component = '';

      if (doc.type == 'upload') {
          doc.extendFile = '';
          for (let x = 0; x < doc.extension.length; x++) {
            if (x == 0 )
              doc.extendFile = '.'+doc.extension[x];
            else 
              doc.extendFile = doc.extendFile + ',.'+ doc.extension[x];
          }
          doc.component = '';
      }
    });
  }

  initEdit(dataForm: any, status?:any) {
    this.elementForm.forEach((doc: any) => {
      // const mappedFormat = Object.keys(this.dataDetail).map(key => ({field: key, value: this.dataDetail[key]}));
      const mappedFormat = Object.keys(dataForm).map(key => ({field: key, value: dataForm[key]}));
      for (let i =0; i < mappedFormat.length; i++) {
        if (doc.field == mappedFormat[i].field) {
          /** convert string to array when type = checkbox */
          if (doc.type == 'checkbox') {
            if (mappedFormat[i].value == "" || mappedFormat[i].value == null) {
              doc.component = mappedFormat[i].value;
            } else {
              if (status != 'afterUpload' && status != 'actCalculation' && status != 'actQrcode') {
                if (!this.globalService.isJson(mappedFormat[i].value)) {
                  // doc.component = "";
                  doc.component = null;
                } else {
                  if (mappedFormat[i].value == "" || mappedFormat[i].value == null) {
                    // doc.component = mappedFormat[i].value;
                    doc.component = null;
                  } else {
                    doc.component = JSON.parse(mappedFormat[i].value);
                  }
                }
              } else {
                // doc.component = mappedFormat[i].value;
                if (mappedFormat[i].value == "" || mappedFormat[i].value == null) {
                  doc.component = null;
                } else {
                  doc.component = mappedFormat[i].value;
                }
              }
            }
          }
          else if (doc.type == 'calendar' || doc.type == 'time') {
            if (mappedFormat[i].value == "") {
              doc.component = mappedFormat[i].value;
            } else {
              doc.component = new Date(mappedFormat[i].value);
            }
          } 
          else if (doc.type == 'upload') {
            doc.extendFile = '';
            for (let x = 0; x < doc.extension.length; x++) {
              if (x == 0 )
                doc.extendFile = '.'+doc.extension[x];
              else 
                doc.extendFile = doc.extendFile + ',.'+ doc.extension[x];
            }
            doc.component = mappedFormat[i].value;
          }
          else if (doc.type == 'cascading') {
            doc.component = mappedFormat[i].value;
            this.getDataCascading(mappedFormat[i].value,doc,'parent');
          }
          else if (doc.type == 'childCascading') {
            doc.component = mappedFormat[i].value;
            this.getDataCascading(mappedFormat[i].value,doc,'child');
          }
          else {
            doc.component = mappedFormat[i].value;
          }
        } 
      } 
    });
    // console.log(this.elementForm)
  }

  initMap () {
    this.getCurrentPosition().subscribe((position: any) => {
      this.globalService.setMapLocation(position)
      this.currentPosition = position;
      navigator.permissions && navigator.permissions.query({name: 'geolocation'}).then((PermissionStatus) => {
        if(PermissionStatus.state == 'denied'){
          const frmArr: any = this.config?.formConfiguration?.formContent;
          let stLoc = false;
          for (let i=0; i<frmArr.length; i++) {
            if (frmArr[i].type == 'hidden' && frmArr[i].validations == 'required' && frmArr[i].configHiddenField.type == 'location') {
              stLoc = true;
            }
          }
          if (stLoc) {
            this.toastNotificationService.showNotification('error',position?.error?.message + ' please change it to Location Access Allow');
            this.onButtonFloatClick()
          }
        }
      })
    });
  }

  onButtonFloatClick() {
    this.buttonFloatOnclick.emit(false);
    // this.refreshData();
  }

  refreshData() {
    this.buttonSearchClick.emit({search: ''});
  }

  createBody(dataForm: any, action: any){
    const mappedForm = Object.keys(dataForm).map(key => ({field: key, value: dataForm[key]}));
    for (let ii=0; ii < mappedForm.length; ii++) {
      for (let iii=0; iii < this.elementForm.length; iii++) {
        /**jika buat kondisi berdasarkan type data */
        if (this.elementForm[iii].type == 'checkbox' && (this.elementForm[iii].field == mappedForm[ii].field)) {
          if (mappedForm[ii].value == "" || mappedForm[ii].value == null) {
            mappedForm[ii].value = null;
          } else {
            mappedForm[ii].value = JSON.stringify(mappedForm[ii].value);
          }
        }
        if (this.elementForm[iii].type == 'number' && this.elementForm[iii].field == mappedForm[ii].field && mappedForm[ii].value == '') {
          mappedForm[ii].value = 0;
        }
      }
    }
    
    const mappedFormat: any = this.fieldTable;
    for (let i =0; i < mappedFormat.length; i++) {
      for (let ii=0; ii < mappedForm.length; ii++) {
        if (mappedFormat[i].field == mappedForm[ii].field) {
          mappedFormat[i].value = mappedForm[ii].value;
        }  
      }
    }
    
    for (let i =0; i < mappedFormat.length; i++) {
      for (let i3 = 0; i3 < this.fieldFormat.length; i3++) {
        if (mappedFormat[i].field == this.fieldFormat[i3].field) {
          if (this.fieldFormat[i3].type == 'date' || this.fieldFormat[i3].type == 'time') {
            if (mappedFormat[i].value != '') {
              mappedFormat[i].value = new Date(mappedFormat[i].value);
            } else {
              mappedFormat[i].value = null;
            }
          }
          if (this.fieldFormat[i3].type == 'number' || this.fieldFormat[i3].type == 'calculation' 
          || this.fieldFormat[i3].type == 'autofill_calculation' || this.fieldFormat[i3].type == 'location') {
            if (mappedFormat[i].value == '') 
              mappedFormat[i].value = 0;
          }
          if (this.fieldFormat[i3].type == 'boolean') {
            if (mappedFormat[i].value == '') 
              mappedFormat[i].value = null;
          }
        }
      }
      mappedFormat[i] = this.insertAutoFill(mappedFormat[i]);
      if (action == 'addData' && mappedFormat[i].field == 'created_by' && this.isLogin && this.userProject != null) {
        mappedFormat[i].value = this.userProject.email;
      }
      if (action == 'addData' && mappedFormat[i].field == 'created_date' && this.isLogin && this.userProject != null) {
        mappedFormat[i].value = new Date();
      }
      if (action == 'editData' && mappedFormat[i].field == 'modified_by' && this.isLogin && this.userProject != null) {
        mappedFormat[i].value = this.userProject.email;
      }
      if (action == 'editData' && mappedFormat[i].field == 'modify_date' && this.isLogin && this.userProject != null) {
        mappedFormat[i].value = new Date();
      }
    }
    const body:any = {}
    mappedFormat.forEach((v: { field: string | number; value: any; }) => body[v?.field] = v?.value);
    return body;
  }

  insertAutoFill(data: any) {
    const formConfig: any = this.config.formConfiguration?.formContent;
    const dtForm = formConfig.filter((x:any) => x.field == data.field && x.type=='hidden');
    if (dtForm.length > 0) {
      if (dtForm[0]?.configHiddenField?.type == 'static') {
        if (dtForm[0]?.fieldType == 'number') {
          data.value = this.globalService.convertToNumber(dtForm[0]?.configHiddenField?.staticValue);
        } else {
          data.value = dtForm[0]?.configHiddenField?.staticValue;
        }
      }
      if (dtForm[0]?.configHiddenField?.type == 'time') data.value = new Date();
      if (dtForm[0]?.configHiddenField?.type == 'userapp') data.value = this.userProject[dtForm[0]?.configHiddenField?.column];
    }

    const dtFormLocationLt = formConfig.filter((x:any) => x.configHiddenField?.location?.latitude == data.field && x.type=='hidden');
    if (dtFormLocationLt.length > 0) {
      if (dtFormLocationLt[0]?.configHiddenField?.type == 'location') data.value = this.currentPosition?.latitude;
    }
    const dtFormLocationLn = formConfig.filter((x:any) => x.configHiddenField?.location?.longitude == data.field && x.type=='hidden');
    if (dtFormLocationLn.length > 0) {
      if (dtFormLocationLn[0]?.configHiddenField?.type == 'location') data.value = this.currentPosition?.longitude;
    }
    return data;
  }

  validasiData(elementForm: any, act:any, formValue: any) {
    const valForm = Object.keys(formValue).map(key => ({field: key, value: formValue[key]}));
    let errValidasi2 = '';

    for (let i= 0; i<elementForm.length; i++) {
      for (let ii=0; ii<valForm.length; ii++) {
        if ((elementForm[i].field == valForm[ii].field) && elementForm[i].type != 'checkbox' && elementForm[i].validations == 'required' && valForm[ii].value == '') {
          errValidasi2 = errValidasi2 + elementForm[i].label + ' must be filled! ';
        }
        if ((elementForm[i].field == valForm[ii].field) && elementForm[i].type == 'checkbox' && elementForm[i].validations == 'required' 
        && (valForm[ii]?.value?.length < elementForm[i].checkbox_max)) {
          errValidasi2 = errValidasi2 + elementForm[i].label + ' checklist cannot be smaller '+elementForm[i].checkbox_max+'!';
        }
      }
    }

    if (act == 'editData') {
      if (!this.dataDetail.id) errValidasi2 = errValidasi2 + ' data id is undefined, please contact administrator to edit data!';
    }
    
    if (act == 'addData') {
      if (this.data.length >= this.max_rows) errValidasi2 = 'max rows '+this.statusProj+' version is '+this.max_rows+' rows!';
    }
    return errValidasi2;
  }

  async submitData(f: NgForm){
    const errValidasi = this.validasiData(this.elementForm, 'addData', f.value);
    if (errValidasi != '') {
      this.toastNotificationService.showNotification('error',errValidasi);
      return;
    } else {
      this.globalService.changeBlockui(true);
      const body = this.createBody(f.value, 'addData');
      if (this.isExternalDtSource) {
        this.globalServiceCrud.createCollectionWithId_2(body, this.tablePath)
        .then((res:any) => {
          this.afterCrud('add', res);
        });
      } else {
        // this.globalServiceCrud.createDynamicCollectionWithId(body, this.tablePath)
        this.globalServiceCrud.createDynamicCollectionWithId2(body, this.tablePath)
        .then((res:any) => {
          this.afterCrud('add', res);
        });
      }
    }
  }

  async editData(f: NgForm){
    const errValidasi = this.validasiData(this.elementForm, 'editData', f.value);
    if (errValidasi != '') {
      this.toastNotificationService.showNotification('error',errValidasi);
      return;
    } else {
      this.globalService.changeBlockui(true);
      let dataForm: any = {};
      dataForm = this.prepareBody(f.value)
      /**jika menggunakan cloud function kondisi other column*/
      dataForm.pageId = this.itemSelected.id;
      const body = this.createBody(dataForm, 'editData');
      if (this.isExternalDtSource) {
        this.globalServiceCrud.modifyRefCollectionWithModifyDate(body, this.tablePath,this.dataDetail.id)
        .then((res:any) => {
          this.afterCrud('update', res);
        });
      } else {
        this.globalServiceCrud.modifyRefDynamicColection(body, this.tablePath,this.dataDetail.id)
        .then((res:any) => {
          this.afterCrud('update', res);
        });
      }
    }
  }

  afterCrud(act:string, res:any) {
    let bodyNotif:any
    if (act == 'add') {
      bodyNotif = this.bodyNotif('add', res.id)
        setTimeout(() => {
          this.globalService.changeBlockui(false);
          this.toastNotificationService.showNotification("success", "Data successfully added");
          this.initClear();
          this.onButtonFloatClick();
          this.refreshData();
        }, 2000);
    }
    if (act == 'update') {
      bodyNotif = this.bodyNotif('edit', this.dataDetail.id)
      setTimeout(() => {
        this.globalService.changeBlockui(false);
        this.toastNotificationService.showNotification("success", "Data successfully Update");
        this.initClear();
        this.onButtonFloatClick();
        this.refreshData();
      }, 2000);
    }
    setTimeout(() => {
      this.globalService.methodPost(environment.be_dev + `/api/v1/fibr-app/backend/process`,bodyNotif)
      .subscribe((resp) => { 
        // console.log(resp) 
      });
    },500);
  }

  bodyNotif(action:any, dataId:any) {
    const body = {
      "userId": this.params.id,
      "projectId": this.params.appid,
      "pageId": this.itemSelected.id,
      "action": action,
      "id": dataId
      }
    return body;
  }

  prepareBody(formValue: any) {
    const arrDetailData = Object.keys(this.dataDetail).map(key => ({field: key, value: this.dataDetail[key]}));
    const arrForm = Object.keys(formValue).map(key => ({field: key, value: formValue[key]}));
      for (let i =0; i < arrDetailData.length; i++) {
        for (let ii=0; ii < arrForm.length; ii++) {
            if (arrDetailData[i].field == arrForm[ii].field) {
              arrDetailData[i].value = arrForm[ii].value;
            } 
        }
      }
    const dataForm:any = {}
    arrDetailData.forEach((v: { field: string | number; value: any; }) => dataForm[v?.field] = v?.value);
    return dataForm;
  }

  createFormDataImage(tableName: any, field: any) {
    const formData = new FormData();
    formData.append("user_type", "partner");
    formData.append("user_id", this.params.id);
    formData.append("project_id", this.params.appid);
    formData.append("data_id", tableName);
    formData.append("field_id", field);
    for (let item = 0; item < this.listFile.length; item++) {
      formData.append(`file`, this.listFile[item].File);
    }
    return formData;
  }

  createFormDataImageReJpeg(tableName: any, field: any, file: any) {
    const formData = new FormData();
    formData.append("user_type", "partner");
    formData.append("user_id", this.params.id);
    formData.append("project_id", this.params.appid);
    formData.append("data_id", tableName);
    formData.append("field_id", field);
    formData.append(`file`, file);
    return formData;
  }

  createListFileArr(fileUpload:any): FileDocumentModel[] {
    this.listFile = [];
    for (let i = 0; i < fileUpload?.length; i++) {
      const item = fileUpload[i];
      this.listFile.push({
        FileName: item.name,
        File: item
      });
    }
    return this.listFile;
  }

  async uploadFileRize(event: any, fileUpload: any, f: NgForm, field: string) {
    if (this.isTemplate == false || this.isActFrom == false) {
      let image: any = [];
      image = this.createListFileArr(event.currentFiles);
      // console.log('file sebelum compress', image[0].File)
      if (image[0].File.size < this.max_upload) {
        this.globalService.changeBlockui(true);
        this.isUploadImage = true;
        const body = f.value;
        if (this.isUploadImage) {
          const tableName : any = this.config.dataContent?.contentDataSource;
          if ( (this.actEdit && this.dataDetail.id) || !this.actEdit) {
            const tablePath = `/users/${this.params.id}/data_projects/${this.params.appid}/tables/${tableName}/data`;
            if (image[0].File.type == "image/jpeg" || image[0].File.type == "image/gif") {
              this.compressImage.compress(image[0].File)
              .pipe(take(1))
              .subscribe(async (compressedImage: File) => {
                // console.log('file setelah compress',compressedImage);
                const formData = this.createFormDataImageReJpeg(tableName, field, compressedImage);
                await this.globalService.methodPost(environment.be_dev + '/api/v1/files/upload', formData)
                .toPromise()
                .then(async (x) => {
                  if (x) {
                    const key = field;
                    const value = environment.image_path + x.data[0].value;
                    body[key] = value;
                    this.isUploadImage = false;
                    this.initEdit(body,'afterUpload');
                    setTimeout(() => { this.globalService.changeBlockui(false) },1000);
                  }
                });
              });
            } else {
              const fromData = this.createFormDataImage(tableName, field);
              await this.globalService.methodPost(environment.be_dev + '/api/v1/files/upload', fromData)
              .toPromise()
              .then(async (x) => {
                if (x) {
                    const key = field;
                    const value = environment.image_path + x.data[0].value;
                    body[key] = value;
                    this.isUploadImage = false;
                    this.initEdit(body,'afterUpload');
                    setTimeout(() => { this.globalService.changeBlockui(false) },1000);
                }
              }); 
            }
          }
        }
      } else {
        this.toastNotificationService.showNotification("error", "file is too big, max "+this.max_upload / 1000+" kb!");
      }
    }
    fileUpload.clear();
  } 

  saveUpload(path:any, body: any, tableName: any) {
    const tablePath = `/users/${this.params.id}/data_projects/${this.params.appid}/tables/${tableName}/data`;
          if (this.actEdit) {
            if (this.dataDetail.id) {
              let dataForm: any = {};
              dataForm = this.prepareBody(body)
              const body2 = this.createBody(dataForm, 'editData');
              this.globalServiceCrud.modifyRefDynamicColection(body2, tablePath,this.dataDetail.id)
              .then((res:any) => {
                body2.id = this.dataDetail.id;
                this.refreshSaveImage(body2);
                  this.toastNotificationService.showNotification("success", "Image successfully Update");
                  this.initEdit(this.dataDetail)
              });
            } else {
              this.toastNotificationService.showNotification("error", "Data Invalid - No Id Data!");
            }
          } else {
            const body2 = this.createBody(body, 'addData');
            this.globalServiceCrud.dynamicCreatecollectionWithId(body2, this.tablePath)
            .then((res:any) => {
              this.refreshSaveImage(body2);
                this.toastNotificationService.showNotification("success", "Data successfully added");
                this.initEdit(this.dataDetail)
            });
          }
  }

  refreshSaveImage(data: any) {
    this.dataDetail = data;
    this.actEdit = true;
    this.elementForm = this.config?.formConfiguration?.formContent;
  }

  public handle(action: any, fn: string, field?: any, frm?: NgForm): void {
    // console.log(action,fn)
    const playDeviceFacingBack = (devices: any[]) => {
      // front camera or back camera check here!
      const device = devices.find(f => (/back|rear|environment/gi.test(f.label))); // Default Back Facing Camera
      action.playDevice(device ? device.deviceId : devices[0].deviceId);
    }

    if (fn === 'start') {
      action[fn](playDeviceFacingBack).subscribe((r: any) => {
        console.log(fn, r), alert
      });
      this.fieldQrCode = field;
      this.formQrCode = frm?.value;
    } else {
      action[fn]().subscribe((r: any) => console.log(fn, r), alert);
      this.fieldQrCode = '';
      this.formQrCode = null;
    }
  }

  public async onEvent(e: ScannerQRCodeResult[], action?: any): Promise<void> {
    // this.contohScan = e[0].value;
    // console.log(e,e[0].value, this.config?.formConfiguration?.formContent);
    const configFrm : any = this.config?.formConfiguration?.formContent;
    const dtForm = configFrm.filter((x:any) => x.field == this.fieldQrCode);
    const body = this.formQrCode;
    const key = this.fieldQrCode;
    const value = e[0].value;
    body[key] = value;
    if (dtForm[0]?.qrConfig?.type == 'asform') {
      const where = {field: dtForm[0]?.qrConfig?.sourceColumn, value: value}
      const dtqr = await this.globalServiceCrud.dynamicColectionwhereclausePromise(this.columnPath+`/${dtForm[0]?.qrConfig?.source}/data`, where)
      const dtField = this.globalService.convertDataOneOriginal(dtqr.docs[0].data());
      for (let x=0; x<dtForm[0]?.qrConfig?.fields.length; x++) {
        body[dtForm[0]?.qrConfig?.fields[x].targetColumn] = dtField[dtForm[0]?.qrConfig?.fields[x].sourceColumn]
      }
    }
    this.initEdit(body,'actQrcode');
    action['stop']().subscribe((r: any) => 
      // console.log('stop', r), 
      alert
    );
  }

  async getConfigCalculation(item:any, frm?: NgForm) {
    const body = frm?.value
    const dtSource = item?.configCalculation?.dataReference?.contentDataSource;
    const where = {field: item?.configCalculation?.dataReference?.sourceColumn, value: item?.component}
      const dtqr = await this.globalServiceCrud.dynamicColectionwhereclausePromise(this.columnPath+`/${dtSource}/data`, where)
      const dtField = this.globalService.convertDataOneOriginal(dtqr.docs[0].data());
      const childCal = item?.configCalculation?.variableList;
      if (childCal.length > 0) {
        for (let x=0; x < childCal.length; x++) {
          if (childCal[x].isDataReference == true) {
            body[childCal[x].targetColumn] = dtField[childCal[x].sourceColumn]
          } else {
            body[childCal[x].targetColumn] = 0
          }
        }
        const inData = []
        for (let y=0; y<childCal.length; y++) {
          const dataCal = body[childCal[y].targetColumn];
          inData.push({column: childCal[y].sourceColumn, value: dataCal, alias:childCal[y].variable});
        }
        body[item?.configCalculation?.configFormula?.targetColumn] = this.parser.getData(inData,item?.configCalculation?.configFormula?.formula);
      }
      this.initEdit(body,'actCalculation');
  }

  getDataFormula(item:any, frm?: NgForm) {
    const body = frm?.value 
    const childCal = item?.configParent?.variableList;
    if (childCal.length > 0) {
      for (let x=0; x < childCal.length; x++) {
        if (childCal[x].targetColumn == item.field) {
          body[childCal[x].targetColumn] = item.component
        } 
      }
      const inData = []  
      for (let y=0; y<childCal.length; y++) {
        const dataCal = body[childCal[y].targetColumn];
        inData.push({column: childCal[y].targetColumn, value: dataCal, alias:childCal[y].variable});
      }
      body[item?.configParent?.configFormula?.targetColumn] = this.parser.getData(inData,item?.configParent?.configFormula?.formula);
    }
    this.initEdit(body,'actCalculation');
  }

  getCurrentPosition(): any {
    return new Observable((observer: Subscriber<any>) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position: any) => {
          observer.next({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            access: 'form',
          });
          observer.complete();
        },
        (error: any) => {
          observer.next({
            latitude: 0,
            longitude: 0,
            access: 'form',
            error: error,
          });
          observer.complete();
        });
      } else {
        observer.error();
      }
    });
  }

}
