import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { appConfig, Confirmation, GlobalcrudService, GlobalService, ShowMockupService, ToastNotificationService } from '@core-fibr/shared';
import { isNull } from 'lodash';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
@Component({
  selector: 'ui-fibr-mobile-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() config: any;
  modalAppInfo: any;
  modalProfileInfo: any;
  modalSearch: any
  modalSort: any
  subscrAppConfig: Subscription | undefined;
  detailAppInfo: any;
  userProject: any;
  shorData = 1;
  @Input() showPageDetails: boolean | undefined;
  @Input() data: any;
  @Input() actFrom: any;
  @Input() params: any;
  @Input() fields: any;
  @Input() isPaid: any;
  @Input() isLogin: any;
  @Input() showForm: boolean | undefined;
  
  @Output() buttonOnclick = new EventEmitter<any>();
  @Output() buttonSearchClick = new EventEmitter<any>();
  @Output() buttonSortClick  = new EventEmitter<any>();
  @Output() logoutOnclick = new EventEmitter<any>();
  @Output() buttonChangePasswordClick = new EventEmitter<any>();
  
  urlPathFirebase = "";
  srcIcon = "";
  pipe = new DatePipe('en-US');
  confirmationTemplate: Confirmation | undefined;
  user: any = {};
  tablePath = "";
  column = "";
  textSearch = "";
  itemHeader: MenuItem[] = [];
  modalChangePassword: any;
  elementForm: any[]=[];
  logo = '';
  cartInfo:any;
  stOrder = false;

  constructor(
    private globalService: GlobalService,
    private globalServiceCrud: GlobalcrudService,
    private showMockupService :ShowMockupService,
    private cs: ConfirmationService,
    private toastNotificationService: ToastNotificationService
  ) { 
    // this.appAbout();
    this.userProject = this.globalService.getCurrReffDataParsed("user_project");
    this.globalService.headerCart.subscribe((res: any) => {
      this.cartInfo = res;
      if (this.cartInfo?.order != null) {
        if (this.cartInfo?.order?.isOrder && this.cartInfo?.order?.isToCart == false && this.cartInfo?.order?.isToSummary == false)
          this.stOrder = true;
        else
          this.stOrder = false;
      } else {
        this.stOrder = false;
      }
    })
  }

  ngOnInit(): void {
    this.user = this.globalService.getCurrReffDataParsed("user_profile");
    this.itemHeader = [
      {
        label: 'Profile', 
        icon: 'pi pi-fw pi-user',
        command: () => {
          this.modalProfileInfo = true;
          this.modalAppInfo = false;
          this.modalChangePassword = false;
          this.appAbout();
        }
      },
      {
          separator:true
      },
      {
        label: 'App Info', 
        icon: 'pi pi-fw pi-info-circle',
        command: () => {
          this.modalAppInfo = true;
          this.modalProfileInfo = false;
          this.modalChangePassword = false;
          this.appAbout();
        }
      }
    ];
    
    this.elementForm = [
      {field: 'password', label: 'Password', value: '', required: true},
      {field: 'confirmPassword', label: 'Confirm Password', value: '', required: true}
    ];
  }

  appAbout() {
    this.globalService.appConfig.subscribe((val: appConfig) => {
      this.detailAppInfo = val;
      this.logo = this.detailAppInfo.app_logo;
    });
    
    if (this.detailAppInfo.updated_date == null) {
      this.detailAppInfo.updated_date = ''; 
    } 
          if (typeof(this.detailAppInfo.updated_date) == 'object' && this.detailAppInfo.updated_date.toString().substring(0,9) == 'Timestamp') {
            const frmTgl = this.detailAppInfo.updated_date.toDate();
            this.detailAppInfo.updated_date = this.pipe.transform(frmTgl, 'dd MMM yyyy');
          } else {
            this.detailAppInfo.updated_date;
          }
          if (this.detailAppInfo.app_icon == "" || this.detailAppInfo.app_icon == null) {
            this.srcIcon = "/assets/iconic_logo.png";
          } else {
            this.srcIcon = this.detailAppInfo.app_icon;
          }
    // console.log(this.fields)
    // this.subscrAppConfig = this.showMockupService.appConfigShow$.subscribe(
    //   appConfig => {
    //     this.detailAppInfo = appConfig;
    //     console.log(this.detailAppInfo);
    //     if (this.detailAppInfo.updated_date == null) {
    //       this.detailAppInfo.updated_date = ''; 
    //     } 
    //     if (typeof(this.detailAppInfo.updated_date) == 'object' && this.detailAppInfo.updated_date.toString().substring(0,9) == 'Timestamp') {
    //       const frmTgl = this.detailAppInfo.updated_date.toDate();
    //       this.detailAppInfo.updated_date = this.pipe.transform(frmTgl, 'dd MMM yyyy');
    //     } else {
    //       this.detailAppInfo.updated_date;
    //     }
    //     if (this.detailAppInfo.app_icon == "" || this.detailAppInfo.app_icon == null) {
    //       this.srcIcon = "/assets/iconic_logo.png";
    //     } else {
    //       this.srcIcon = this.detailAppInfo.app_icon;
    //     }
    // });
  }

  onButtonClick() {
    // console.log(this.actFrom)
    // if (this.actFrom == 'pwa') {
    //   this.refreshSearch();
    //   this.refreshSort();
    // }
    this.buttonOnclick.emit(false);
  }

  onButtonSearchClick(f: NgForm) {
    // this.modalSearch = false;
    this.buttonSearchClick.emit(f.value);
  }

  refreshSearch() {
    this.modalSearch = false;
    this.onButtonClearSearch();
    this.buttonSearchClick.emit({search: ''});
  }

  onButtonClearSearch () {
    this.textSearch = "";
    this.buttonSearchClick.emit({search: ''});
  }

  onButtonSortClick(f: NgForm, shorData: number) {
    // this.modalSort = false;
    this.buttonSortClick.emit({field: f.value.field, short: shorData});
  }

  refreshSort() {
    this.modalSort = false;
    this.shorData = 1;
    this.column = " ";
    // this.buttonSortClick.emit({field: this.column, short: this.shorData});
    this.buttonSearchClick.emit({search: ''});
  }

  onDeleteClick() {
    // console.log('data', this.data)
    this.confirmationTemplate = {
      header: "Confirmation!",
      content: "Click yes if you want to Delete Data?",
      rejectButtonStyleClass: "p-button-secondary",
      dismissableMask: true
    };
    this.cs.confirm({
      message: this.confirmationTemplate.content,
      header: this.confirmationTemplate.header,
      rejectButtonStyleClass: this.confirmationTemplate.rejectButtonStyleClass,
      dismissableMask: this.confirmationTemplate.dismissableMask,
      accept: () => {
        if (this.actFrom == 'pwa') {
          this.globalService.changeBlockui(true);
          const tableName: any = this.config.dataContent?.contentDataSource;
          this.tablePath = `/users/${this.params.id}/data_projects/${this.params.appid}/tables/${tableName}/data`;
          console.log(this.tablePath)
          this.globalServiceCrud.deleteDynamicCollection(this.tablePath,this.data.id)
          .then((res:any) => {
            setTimeout(() => {
              this.globalService.changeBlockui(false);
              this.toastNotificationService.showNotification("success", "Data successfully Delete");
              this.buttonOnclick.emit(false);
              this.buttonSearchClick.emit({search: ''});
            }, 2000);
          });
        }
      },
      reject: () => {
        // 
      }
    });
  }

  onLogoutClick() {
    this.logoutOnclick.emit();
  }

  onChangeShort(shorData: number){
    if (shorData == 1) this.shorData = 2; else this.shorData = 1;
  }

  onButtonChangePasswordClick() {
    this.modalProfileInfo = false;
    this.modalChangePassword = true; 
  }

  onChangePassword(f: NgForm) {
    const errVal = this.validasi(f.value)
    if (errVal != '') {
      this.toastNotificationService.showNotification("error", errVal);
    } else {
      this.buttonChangePasswordClick.emit(f.value);
      this.modalProfileInfo = true;
      this.modalChangePassword = false; 
    }
  }

  validasi(val: any)  {
    let errValidasi2 = '';
    if(val.password == '') {
      errValidasi2 = errValidasi2 + 'password cannot be empty!';
    } else {
      if (val.password != val.confirmPassword){
        errValidasi2 = errValidasi2 + 'Those passwords didn’t match. Try again.';
      }
    } 
    return errValidasi2;
  }

  getSummaryFilter() {
    this.globalService.setSummaryFilter(true);
  }

  getSummaryDownload() {
    this.globalService.setSummaryDownload(true); 
  }
}
