<!-- <div *ngIf="waitProcess.process">
  <div class="grid">
    <div class="col-12 mb-2">
        <h5 class="text-center menu-subtitle mb-1">Please wait,..</h5>
        <p class="text-center text-sm">in the process of data adjustment <br />
          add the project will be done again after the process is complete</p>
    </div>
  </div>
</div> -->

  <!-- <div *ngIf="statusApp === 'prod-fibr'">
    <div *ngIf="isDataExists">
      <div class="grid my-5">
        <div class="col-12">
            <h5 class="text-center menu-subtitle mb-3"><b>You're on free trial mode!</b></h5>
            <p class="text-center text-sm">You can only create I project on this free trial mode</p>
        </div>
      </div>
    </div>
    <div *ngIf="!isDataExists">
      <div *ngIf="!isGoogleFiles">
        <div class="grid">
            <div class="col-12 mb-2">
                <h5 class="text-center menu-subtitle mb-1">You can create a new project</h5>
                <p class="text-center text-sm">by importing data from google sheets or create data using data fibr or you can start with an existing template from our market place</p>
            </div>
        </div>
        <p-divider></p-divider>
        <div class="grid">
            <div class="col-12 px-5">
                <p class="">Your project Name<sup>*</sup></p>
                <input type="text" pInputText class="w-100" [(ngModel)]="appConfig.app_name" placeholder="Project title"> 
                <sub *ngIf="showMsg" class="text-magenta">field required</sub>
            </div>
        </div>
        <p-divider></p-divider>
        <div class="grid center mb-5">
            <div class="col-auto center flex-column">
                <img src="/assets/images/icon/icon_g-sheet.svg" height="80px" class="py-3"/>
                <button [disabled]="appConfig.app_name === null" (click)="googleSheets()" pButton type="button" label="Google Sheets" icon="pi pi-chevron-right" iconPos="right" class="p-button-secondary p-button-outlined p-button-sm" [ngStyle]="{'background-color':'rgb(73,158,96)', 'border': 'none', 'color' : 'white'}"></button>
                </div>
        </div>
      </div>

      <div class="grid px-5" *ngIf="isGoogleFiles">
        <ui-fibr-grid [isGoogleFiles]="isGoogleFiles" [isMockData]="false" [gridModel]="gridModel" (dataSelect)="googleFilesFetch($event)"></ui-fibr-grid>
      </div>
    </div>
  </div> -->

  <!-- <div *ngIf="statusApp === 'dev-fibr' || statusApp === 'staging-fibr'"> -->
  <div>
      <div *ngIf="!isGoogleFiles">
        <div class="grid">
            <div class="col-12 mb-2">
                <h5 class="text-center menu-subtitle mb-1">{{'project.create.new' | translate}}</h5>
                <p class="text-center text-sm">{{'project.create.description' | translate}}</p>
            </div>
        </div>
        <p-divider></p-divider>
        <div class="grid">
            <div class="col-12 px-5">
                <p class="">{{'project.name' | translate}}<sup>*</sup></p>
                <input type="text" pInputText class="w-100" [(ngModel)]="appConfig.app_name" placeholder="{{'project.title' | translate}}"> 
                <sub *ngIf="showMsg" class="text-magenta">field required</sub>
            </div>
        </div>
        <p-divider></p-divider>
        <div class="grid text-center justify-content-center p-5">
            <div class="col-10 center flex-column mx-4">
              <!-- <a href="javascript:;" (click)="googleSheets()">
                <img src="/assets/images/icon/icon_g-sheet.svg" height="80px" class="py-3"/>
                <p class="text-grey">Google Sheets</p>
              </a> -->
              <div class="abcRioButton" (click)="googleSheets()">
                <div class="abcRioButtonContentWrapper">
                  <div class="abcRioButtonIcon">
                    <div style="width:18px;height:18px;" class="abcRioButtonSvgImageWithFallback abcRioButtonIconImage abcRioButtonIconImage18">
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" class="abcRioButtonSvg">
                        <g>
                          <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                          <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                          <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                          <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path>
                        </g>
                      </svg>
                    </div>
                  </div>
                  <span class="abcRioButtonContents">
                    <span id="not_signed_inqxa7b2xmul7l" style="font-family: 'Roboto', sans-serif;">{{'googleLogin' | translate}}</span>
                  </span>
                </div>
              </div>
            </div>
            <!-- <div class="col-auto center flex-column mx-4" *ngIf="false">
                <img src="/assets/images/icon/icon_blank-table.svg" height="80px" class="py-3"/>
                <button [disabled]="appConfig.app_name === null" pButton type="button" label="Blank Table" icon="pi pi-chevron-right" iconPos="right" class="p-button-secondary p-button-outlined p-button-sm mr-2 mb-2" [ngStyle]="{'background-color':'rgb(125,124,214)', 'border': 'none', 'color' : 'white'}"></button>
            </div>
            <div class="col-auto center flex-column" *ngIf="false">
                <img src="/assets/images/icon/icon_template.svg" height="80px" class="py-3"/>
                <button [disabled]="appConfig.app_name === null" pButton type="button" label="Template" icon="pi pi-chevron-right" iconPos="right" class="p-button-secondary p-button-outlined p-button-sm mr-2 mb-2" [ngStyle]="{'background-color':'rgb(89,146,211)', 'border': 'none', 'color' : 'white'}"></button>
            </div> -->
        </div>
      </div>
    <div class="grid block p-5" *ngIf="isGoogleFiles">
      <div class="col-12 mb-2">
          <p class="text-center">{{'choose_data' | translate}} Google Sheets</p>
      </div>
      <!-- <ui-fibr-grid [isGoogleFiles]="isGoogleFiles" [isMockData]="false" [gridModel]="gridModel" (dataSelect)="googleFilesFetch($event)"></ui-fibr-grid> -->
      <ui-fibr-grid [isGoogleFiles]="isGoogleFiles" [isMockData]="false" [gridModel]="gridModel" (dataSelect)="googleFilesFetch($event)"></ui-fibr-grid>
    </div>
  </div>