import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from "rxjs";
import { Modal } from "./../interfaces/modal";

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public isDisplayModal = new BehaviorSubject(false);
  public result = new BehaviorSubject(false);
	public dataContent = new Subject<Modal>();

  constructor() {}

  get displayModalValue() {
    return this.isDisplayModal.asObservable();
  } 

  get resultValue() {
    return this.result.asObservable();
  } 

  get dataContentValue(): Modal | any{
    return this.dataContent.asObservable();
  }

  set displayModalValue(value:any) {
    this.isDisplayModal.next(value);
  }

  set resultValue(value:any) {
    this.isDisplayModal.next(false);
    this.result.next(value);
  }

  set dataContentValue(data: Modal | any){
    let dataModal = {
      header: data.header != ''? data.header : 'Header',
      content: data.content != ''? data.content : 'content',
      isHeader: data.isHeader? data.isHeader : true,
      isFooter: data.isFooter? data.isFooter: true,
      isYesButton: data.isYesButton? data.isYesButton : true,
      isNoButton: data.isNoButton? data.isNoButton : true 
    }
    this.dataContent.next(dataModal);
  }

}
