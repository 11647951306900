import { Component, OnInit } from '@angular/core';
import { GlobalcrudService } from '../../../services/crud/globalcrud.service';
import { GlobalService } from '../../../services/global.service';

@Component({
  selector: 'ui-fibr-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  dataUsers:any;
  constructor(private globalService : GlobalService, private globalCrudService : GlobalcrudService) { }

  ngOnInit(): void {
    const userProfile = this.globalService.getCurrReffDataParsed('user_profile');
    const pathProfile = `users/${userProfile.uid}/profile`;
    this.globalCrudService.getcollection(pathProfile).subscribe(res =>{
      this.dataUsers = res[0];
    });
  }

}
