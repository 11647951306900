import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-fibr-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss']
})
export class ThemeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
