import { Component, OnInit } from "@angular/core";
import {
  ModalService,
  Modal,
  Confirmation,
  ToastNotificationService,
} from "@core-fibr/shared";
import { ConfirmationService } from "primeng/api";

@Component({
  selector: "ui-fibr-sample",
  templateUrl: "./sample.component.html",
  styleUrls: ["./sample.component.scss"],
})
export class SampleComponent implements OnInit {
  value: boolean | undefined;
  confirmed: boolean | undefined;
  modalTemplate: Modal | undefined;
  confirmationTemplate: Confirmation | undefined;

  constructor(
    private modalservice: ModalService,
    private cs: ConfirmationService,
    private toastNotificationService: ToastNotificationService
  ) {}

  ngOnInit(): void {
    this.modalservice.resultValue.subscribe((val: boolean) => {
      this.value = val;
    });

    console.log("this.confirmed: ", this.confirmed);
  }

  showBasicDialog(): void {
    this.modalTemplate = {
      header: "Aku adalah modal",
      content: "Klik yes jika mau true, klik no jika mau false",
    };
    this.modalservice.dataContentValue = this.modalTemplate;
    this.modalservice.displayModalValue = true;
  }

  async confirmation() {
    this.confirmationTemplate = {
      header: "Aku adalah confirmation",
      content: "Klik yes jika mau true, klik no jika mau false",
      icon: "pi pi-exclamation-triangle",
    };
    this.cs.confirm({
      message: this.confirmationTemplate.content,
      header: this.confirmationTemplate.header,
      icon: this.confirmationTemplate.icon,
      accept: () => {
        this.confirmed = true;
      },
      reject: () => {
        this.confirmed = false;
      },
    });
  }

  showToast() {
    this.toastNotificationService.showNotification("success", "Success Bro");
    this.toastNotificationService.showNotification("info", "info Bro");
    this.toastNotificationService.showNotification("warn", "warning Bro");
    this.toastNotificationService.showNotification("error", "error Bro");
  }
}
