<div class="layout-main">
    <div class="layout-content">
        <div class="layout-dashboard">
            <div class="grid">
                <div class="col-12 text-center" *ngIf="appName === 'sifa'" >
                    <p-panel header="QR Code" [toggleable]="false">
                        <ng-template pTemplate="icons">
                            <button pButton class="p-panel-header-icon p-link" (click)="print()">
                                <span class="pi pi-print"></span>
                            </button>
                        </ng-template>
                        <qrcode id="section-to-print" [qrdata]="userProfile.uid" [width]="300" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
                    </p-panel>
                </div>
                <div class="col-12">
                    <p-panel header="User Profile" [toggleable]="false">
                        <ng-template pTemplate="icons">
                            <button pButton class="p-panel-header-icon p-link" (click)="menu.toggle($event)">
                                <span class="pi pi-cog"></span>
                            </button>
                            <p-menu #menu id="config_menu" [model]="items" [popup]="true"></p-menu>
                        </ng-template>
                            <div class="grid">
                                <div class="col-3">
                                    <img width="140" [src]="dataUsers?.photo_url | getDownloadURL" onerror="this.src='/assets/iconic_logo.png'" />
                                </div>
                            <div class="col-9">
                                <div class="grid">
                                    <div class="col-3">Full Name </div>
                                    <div class="col-9">: {{dataUsers?.displayName}}</div>
                                    <div class="col-3">Email </div>
                                    <div class="col-9">: {{dataUsers?.email}}</div>
                                    <div class="col-3">Phone </div>
                                    <div class="col-9">: {{dataUsers?.phone}}</div>
                                </div>
                            </div>
                        </div>
                    </p-panel>
                </div>
                <div class="col-12">
                    <p-fileUpload name="demo[]" url="./upload.php" (onUpload)="onUpload($event)">
                        <ng-template pTemplate="content">
                            <ul *ngIf="uploadedFiles.length">
                                <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
                            </ul>
                        </ng-template>
                </p-fileUpload>
                </div>
            </div>
        </div>
    </div>
</div>