import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'libs/auth/src/lib/services/authentication/authentication.service';
import { GlobalService } from '@core-fibr/shared';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthenticationService,
    private globalService: GlobalService,) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.authService.userAccessToken;
    const accessTokenProject = this.authService.userProjectToken;
    // console.log('lewat sini',request.body, accessTokenProject)

    let token = '';
    if (!request.body?.hitBy) {
      token = accessToken
    }
    if (request.body?.hitBy == 'pwa') {
      if (accessTokenProject == '' || accessTokenProject == undefined) {
        const proj = this.globalService.getCurrReffDataParsed("user_project");
        token = proj?.accessToken
      } else {
        token = accessTokenProject
      }
    } 

    if (token != "") {
      const clonedRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!request.body?.hitBy) {
        return next.handle(clonedRequest).pipe(
          catchError((error: any) => {
            if (error instanceof HttpErrorResponse) {
              if (error.status === 401) {
                return this.authService.refreshToken().pipe(
                  switchMap((result: any) => {
                    const clonedRequest = request.clone({
                      setHeaders: {
                        Authorization: `Bearer ${result.data.accessToken}`
                      }
                    });
                    return next.handle(clonedRequest);
                  }),
                  catchError((refreshError: any) => {
                    return throwError(refreshError);
                  })
                );
              } else if (error.status === 403) {
                this.authService.handle403();
              } else if (error.status === 417) {
                this.authService.handle417();
              }
            }
            return throwError(error);
          })
        );
      }
      if (request.body?.hitBy == 'pwa') {
        return next.handle(clonedRequest).pipe(
          catchError((error: any) => {
            if (error instanceof HttpErrorResponse) {
              if (error.status === 401) {
                return this.authService.refreshTokenProject().pipe(
                  switchMap((result: any) => {
                    const clonedRequest = request.clone({
                      setHeaders: {
                        Authorization: `Bearer ${result.data.accessToken}`
                      }
                    });
                    return next.handle(clonedRequest);
                  }),
                  catchError((refreshError: any) => {
                    return throwError(refreshError);
                  })
                );
              } else if (error.status === 403) {
                this.authService.handle403Project();
              } 
            }
            return throwError(error);
          })
        );
      } 
      
    }

    return next.handle(request);
  }
}
