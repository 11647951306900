import { Component, OnInit } from '@angular/core';
import { Modal } from '../../interfaces/modal';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'ui-fibr-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  displayModal: Boolean = false;
  isDisplayModal$: any;
  dataModal: Modal| undefined;
  constructor(private modalService : ModalService ) { }
  
  ngOnInit(): void {
      this.dataModal = {
        header : 'header',
        content: "content"
      };
     this.modalService.displayModalValue.subscribe( (val:Boolean) => this.displayModal = val );
     this.modalService.dataContentValue.subscribe( (val:Modal) => this.dataModal = val );

  }

  clickYes(){
    this.modalService.resultValue = true;
  }

  clickNo(){
    this.modalService.resultValue = false;
  }

}
