import { Component, OnInit, AfterViewInit  } from '@angular/core';
import { ShepherdService } from '../../services/shepherd.service';
import { steps as defaultSteps, defaultStepOptions} from './data';

@Component({
  selector: 'ui-fibr-shepherd',
  templateUrl: './shepherd.component.html',
  styleUrls: ['./shepherd.component.scss']
})
export class ShepherdComponent implements AfterViewInit  {

  constructor(private shepherdService: ShepherdService,) { }

  // ngOnInit(): void {
  // }
  ngAfterViewInit() {
    this.shepherdService.defaultStepOptions = defaultStepOptions;
    this.shepherdService.modal = true;
    this.shepherdService.confirmCancel = false;
    this.shepherdService.addSteps(defaultSteps);
    this.shepherdService.start();
  }

}
