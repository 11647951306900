import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParserService {

  err: any =[];
  constructor() { }

  parserCalculation(fmr: any, varList: any){
    const err: any = [];
    // const operator: any = ['+','-','/','*','(',')'];
    const operator: any = ['+','-','/','*'];
    const barometer = this.injectBarometer(varList,operator)
    // console.log(barometer,varList)
    const arrFormula = fmr.split("");
    const lastArr = arrFormula.length - 1;
    /**sementara dibatasi array maksimal 5 */
    if (arrFormula.length > 5) err.push('wrong caracter!0');
    for (let i=0; i<arrFormula.length; i++) {
      let dtText = [];
      const data = arrFormula[i];
      const prevData = arrFormula[i-1];
      dtText = barometer.filter((x:any) => x == arrFormula[i]);
      if(dtText.length == 0) {
        err.push('wrong caracter!0');
      } else {
        const buka = arrFormula.filter((x:any) => x == '(')
        const tutup = arrFormula.filter((x:any) => x == ')')
        if (buka.length != tutup.length) {
          err.push('wrong caracter!1');
        }
        if (i == 0) {
          if (data == '+' || data == '-'|| data == '/'|| data == '*'|| data == ')') err.push('wrong caracter!2');
        }
        if (i != lastArr) {
          if (i==0) {
            if (data == '+' || data == '-'|| data == '/'|| data == '*'|| data == ')') err.push('wrong caracter!3');
          } else {
            if (prevData == '(') {
              if (data=='+' || data=='-' || data=='/'|| data=='*'|| data == '(' || data == ')') err.push('wrong caracter!4');
            }
            if (prevData == ')') {
              for (let x1=0; x1<varList.length; x1++) {
                if (data == varList[x1].alias) err.push('wrong caracter!5');
              }
            }
            if (prevData=='+' || prevData=='-' || prevData=='/' || prevData=='*') {
              if (data=='+' || data=='-' || data=='/'|| data=='*'|| data == ')') err.push('wrong caracter!7');
            } else {
              if (prevData == '(') {
                if (data=='+' || data=='-' || data=='/'|| data=='*'|| data == '(' || data == ')') err.push('wrong caracter!8');
              } 
              if (prevData == ')') {
                for (let x1=0; x1<varList.length; x1++) {
                  if (varList[x1].alias == data) err.push('wrong caracter!10'+x1);
                }
              }
              /**sudah oke */
              if (prevData != '(' || prevData != ')') {
                // if (data == '(') err.push('wrong caracter!10b');
                for (let x1=0; x1<varList.length; x1++) {
                  if (prevData == varList[x1].alias) {
                    for (let xz=0; xz<varList.length; xz++) { 
                      if (data == varList[xz].alias) err.push('wrong caracter!11b');
                    }
                  }
                }
              }
            }
          }
        }
        if (i == lastArr) {
          for (let x1=0; x1<varList.length; x1++) {
            if (data == varList[x1].alias && (prevData == ')' || prevData == '(')) err.push('wrong caracter!11a');
            if (data == varList[x1].alias) {
              for (let xz=0; xz<varList.length; xz++) { 
                if (prevData == varList[xz].alias) err.push('wrong caracter!11b');
              }
            }
          }
          if (data == ')') {
            if (prevData == '+' || prevData == '-'|| prevData == '/' || prevData == '*'|| prevData == '(') err.push('wrong caracter!11c');
          }
          if (data == '+' || data == '-'|| data == '/' || data == '*'|| data == '(') err.push('wrong caracter!11d');
        }
      }
    }
    return err;
  }

  injectBarometer(varList:any,operator:any) {
    const arrData = [];
    for (let x=0; x<operator.length; x++){
      arrData.push(operator[x]);
    }
    for (let i=0; i<varList.length; i++) {
      arrData.push(varList[i].alias)
    }
    return arrData;
  }

  dataAlfabet(){
    const alfabeth = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','q','r','s','t','u','v','w','x','y','z'] ;
    return alfabeth;
  }

  // getData(arrData:any, formula:any){
  //   let dataCal = 0;
  //   console.log(arrData,formula);
  //   const arrFr = formula.split("");
  //   const injectDt = [];
  //   for (let x=0; x<arrFr.length; x++) {
  //     const dt = arrData.filter((cl:any) => cl.alias == arrFr[x])
  //     if (dt.length > 0) {
  //       injectDt.push(dt[0].value)
  //     } else {
  //       injectDt.push(arrFr[x])
  //     }
  //   }
  //   if (injectDt.length == 1) dataCal = injectDt[0];
  //   if (injectDt.length == 3) dataCal = this.inject1(injectDt);
  //   if (injectDt.length == 5) dataCal = this.inject3(injectDt,injectDt[1]);
  //   // console.log(arrFr, injectDt, dataCal)
  //   return dataCal;
  // }

  getData(arrData:any, formula:any){
    let dataCal = 0;
    const arrFormula = formula.split("");
    const arrNewFormula = [];
    let newFormula = '';
    for (let x=0; x<arrFormula.length; x++) {
      const dt = arrData.find((y:any) => y.alias == arrFormula[x])
      if (dt == undefined) {
        arrNewFormula.push(arrFormula[x])
      } else {
        arrNewFormula.push(String(dt.value))
      }
    }
    newFormula = arrNewFormula.join(' ');
    dataCal = eval(newFormula);
    return dataCal;
  }

  inject1(inj: any) {
    let dataCal = 0;
    if (inj[1] == '+') dataCal = inj[0] + inj[2];
    if (inj[1] == '-') dataCal = inj[0] - inj[2];
    if (inj[1] == '*') dataCal = inj[0] * inj[2];
    if (inj[1] == '/') dataCal = inj[0] / inj[2];
    return dataCal;
  }

  inject3(inj:any, act2:any) {
    let dataCal = 0;
    if (act2 == '+') {
      if (inj[3] == '+') dataCal = inj[0] + inj[2] + inj[4]
      if (inj[3] == '-') dataCal = inj[0] + inj[2] - inj[4]
      if (inj[3] == '*') dataCal = inj[0] + inj[2] * inj[4]
      if (inj[3] == '/') dataCal = inj[0] + inj[2] / inj[4]
    }
    if (act2 == '-') {
      if (inj[3] == '+') dataCal = inj[0] - inj[2] + inj[4]
      if (inj[3] == '-') dataCal = inj[0] - inj[2] - inj[4]
      if (inj[3] == '*') dataCal = inj[0] - inj[2] * inj[4]
      if (inj[3] == '/') dataCal = inj[0] - inj[2] / inj[4]
    }
    if (act2 == '*') {
      if (inj[3] == '+') dataCal = inj[0] * inj[2] + inj[4]
      if (inj[3] == '-') dataCal = inj[0] * inj[2] - inj[4]
      if (inj[3] == '*') dataCal = inj[0] * inj[2] * inj[4]
      if (inj[3] == '/') dataCal = inj[0] * inj[2] / inj[4]
    }
    if (act2 == '/') {
      if (inj[3] == '+') dataCal = inj[0] / inj[2] + inj[4]
      if (inj[3] == '-') dataCal = inj[0] / inj[2] - inj[4]
      if (inj[3] == '*') dataCal = inj[0] / inj[2] * inj[4]
      if (inj[3] == '/') dataCal = inj[0] / inj[2] / inj[4]
    }
    return dataCal;
  }


}
