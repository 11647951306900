import { group } from "@angular/animations";
import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgForm } from "@angular/forms";
import { GlobalcrudService, GlobalService } from "@core-fibr/shared";
import { head, max } from "lodash";
import { from } from "rxjs";
import { configMobile } from "../../../interfaces/global";
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

@Component({
  selector: 'ui-fibr-mobile-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  @Input() config: configMobile = {};
  @Input() data: any[] = [];
  @Input() dataSummary: any[] = [];
  @Input() column: any;
  @Input() dataColumn: any;
  @Input() params: any;
  @Input() isTemplate: any;

  dataSum: any[] = [];
  dt2: any[] = [];
  dataSumTop: any[] = [];
  dataSumButtom: any[] = [];
  dataTotal: any[] = [];
  dataSumChart: any[] = [];
  is_showFilter = false;
  is_download = false;
  arrFilter: any = [];
  propertyFilter: any = [{column: '', columnType: '', label: '', header: ''}];
  columnPath = "";
  labelMain:any;
  labelVal1:any;
  labelVal2:any;
  typeMain:any;
  typeVal1:any;
  typeVal2:any;
  arrContent: any;

  /**data filter */
  mainFilter: any;
  filterDate1: any;
  filterDate2: any;
  filterText: any = '';
  formulaNumber = [{val:'=='}, {val:'>'}, {val:'<'}];
  formulaValue: any;
  filterNumber: any;
  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };
  colourPalette1 = ['#DAB792','#7794AD','#A7BFC8','#EACBB0','#8DADD3','#BBD2D9','#F7EAD9','#88B3D6','#E8ECEB','#D4CDAA','#A5C4E2','#D6D5CC',
    '#BEBF99','#C0D5E4','#C6C7BA','#A7B189','#CFE1EC','#88AEDA','#8ACCC3','#9AA474']

  constructor(private globalService: GlobalService,private globalServiceCrud: GlobalcrudService,) { }

  ngOnInit(): void {
    this.getInfoColumn();
    if (!this.config.layoutDesign?.summaryType || this.config.layoutDesign?.summaryType == 'data') {
      setTimeout(() => {
        this.globalService.convertData(this.data,this.arrContent);
      },500);
    }
    
    setTimeout(() => {
      this.getDataSummary(this.data);
    },1500);
    this.arrFilter = this.config.dataContent?.configSummaryTable?.filterFunction;
    this.globalService.isSummaryFilter.subscribe((res: any) => {
      this.is_showFilter = res;
    });
    this.globalService.isSummaryDownload.subscribe((res: any) => {
      this.is_download = res;
    });
  }

  async getInfoColumn() {
    const tbl: any = this.config.dataContent?.contentDataSource;
    if (this.isTemplate) {
      this.columnPath = `/templates/${this.params.appid}/tables`; 
    } else {
      if (this.config.dataContent?.isExternalDataSource) {
        this.columnPath = this.globalService.getPathColumnExternalSource(tbl,this.params.id);
      } else {
        this.columnPath = `/users/${this.params.id}/data_projects/${this.params.appid}/tables`;
      }
    }

    let col:any;
    if (this.config.dataContent?.isExternalDataSource) {
      col = await this.globalServiceCrud.getCollectionwithidPromise(this.columnPath, tbl+'_struct');
    } else {
      col = await this.globalServiceCrud.getDynamicCollectionwithidPromise(this.columnPath, tbl);
    }

    
      const setVal:any = this.config.dataContent?.configSummaryTable?.value
      this.labelMain = col.columns.find((x:any)=> x.field == setVal[0].column)
      this.labelVal1 = col.columns.find((x:any)=> x.field == setVal[1].column)
      this.labelVal2 = col.columns.find((x:any)=> x.field == setVal[2].column)

      this.arrContent = [];
      const setType:any = this.config.dataContent?.configSummaryTable?.content
      this.typeMain = col.columns.find((x:any)=> x.field == setType.group.column)
      this.arrContent.push({value:this.typeMain?.field, type: this.typeMain?.type})
      if (setType.sub_group.column != '') {
        this.typeVal1 = col.columns.find((x:any)=> x.field == setType.sub_group.column)
        const cekArr = this.arrContent.find((x:any)=> x?.value == this.typeVal1?.field)
        if (cekArr == undefined) {
          this.arrContent.push({value:this.typeVal1.field, type: this.typeVal1.type})
        }
      }
      if (setType.child_group.column != '') {
        this.typeVal2 = col.columns.find((x:any)=> x.field == setType.child_group.column)
        const cekArr = this.arrContent.find((x:any)=> x?.value == this.typeVal2?.field)
        if (cekArr == undefined) {
          this.arrContent.push({value:this.typeVal2.field, type: this.typeVal2.type})
        }
      }
  }

  getDataSummary(dt: any) {
    if (!this.config.layoutDesign?.summaryType || this.config.layoutDesign?.summaryType == 'data') {
      this.dataSum = [];
      const group: any = this.config.dataContent?.configSummaryTable?.content?.group?.column;
      const subGroup: any = this.config.dataContent?.configSummaryTable?.content?.sub_group?.column;
      const childGroup: any = this.config.dataContent?.configSummaryTable?.content?.child_group?.column;
      // const sortgroup: any = this.config.dataContent?.configSummaryTable?.content?.group?.sortType;
      // const sortsubGroup: any = this.config.dataContent?.configSummaryTable?.content?.sub_group?.sortType;
      // const sortchildGroup: any = this.config.dataContent?.configSummaryTable?.content?.child_group?.sortType;
      const val: any = this.config.dataContent?.configSummaryTable?.value;
      const mainVal: any = val[0]?.column;
      const mainFormula: any = val[0]?.formula;
      // const val1: any = val[1]?.column;
      // const val1Formula: any = val[1]?.formula;
      // const val2: any = val[2]?.column;
      // const val2Formula: any = val[2]?.formula;
      const readGroup: any[] = [];
      const readSubGroup: any[] = [];
      const readChildGroup: any[] = [];
      let arrGroup = [];
      let arrSubGroup = [];
      let arrChildGroup = [];
      this.actAllFormula(dt,mainFormula,mainVal);
      for (let i=0; i<dt.length; i++) {
        arrGroup = readGroup.filter((x:any) => x == dt[i][group]); 
        if (arrGroup.length == 0) {
          readGroup.push(dt[i][group])
          this.actGroupFormula(dt,dt[i][group],mainFormula,mainVal,group)
        }
        arrSubGroup = readSubGroup.filter((x:any) => x.group == dt[i][group] && x.sub_group == dt[i][subGroup]);  
        if (arrSubGroup.length == 0) {
          readSubGroup.push({group: dt[i][group], sub_group: dt[i][subGroup]})
          this.actSubGroupFormula(dt,dt[i][group],dt[i][subGroup],mainFormula,mainVal,group,subGroup)
        }
        arrChildGroup = readChildGroup.filter((x:any) => x.group == dt[i][group] && x.sub_group == dt[i][subGroup] && x.child_group == dt[i][childGroup]);  
        if (arrChildGroup.length == 0) {
          readChildGroup.push({group: dt[i][group], sub_group: dt[i][subGroup], child_group: dt[i][childGroup]})
          this.actChildGroupFormula(dt,dt[i][group],dt[i][subGroup],dt[i][childGroup],mainFormula,mainVal,group,subGroup,childGroup)
        }
      }

      let arr1:any = []
      let arr2:any = []
      const readArr1: any[] = [];
      const readArr2: any[] = [];
      // let arr1_2:any = []
      // let arr2_2:any = []
      // const readArr1_2: any[] = [];
      // const readArr2_2: any[] = [];
      
      this.dataSumTop = [];
      this.dataSumButtom = [];
      for (let i=0; i<this.dataSum.length; i++) {
        arr1 = readArr1.filter((x:any) => x == this.dataSum[i].group); 
        if (arr1.length == 0) {
          readArr1.push(this.dataSum[i].group);
          let dtArr = this.dataSum.filter((dd:any) => dd.group == this.dataSum[i].group)
          dtArr = this.sortData(dtArr,'sub');
          const headG = dtArr.find((yy:any) => yy.sub_group == '' && yy.child_group == '');
          // console.log(headG,dtArr)
          this.dataSumTop.push({group: headG.group, sub_group: '', child_group: '', result: headG.result, label: headG.label,
          value1:headG.value1, labelVal1:headG.labelVal1, value2:headG.value2, labelVal2:headG.labelVal2,
          typeMain: headG.typeMain, typeVal1: headG.typeVal1, typeVal2: headG.typeVal2});
          for (let y=0; y<dtArr.length; y++) {
            if (dtArr[y].group != '' && dtArr[y].sub_group != '') {
              arr2 = readArr2.filter((x:any) => x.group == dtArr[y].group && x.sub_group == dtArr[y].sub_group); 
              if (arr2.length == 0) {
                readArr2.push({group: dtArr[y].group, sub_group: dtArr[y].sub_group});
                let dtArr2 = dtArr.filter((dd:any) => dd.group == dtArr[y].group && dd.sub_group == dtArr[y].sub_group)
                dtArr2 = this.sortData(dtArr2,'child');
                const headSub = dtArr2.find((yy:any) => yy.sub_group != '' && yy.child_group == '');
                // console.log(headSub,dtArr2)
                if (headSub.sub_group != undefined) {
                  this.dataSumTop.push({group: headSub.group, sub_group: headSub.sub_group, child_group: '', result: headSub.result, label: headSub.label,
                  value1:headSub.value1, labelVal1:headSub.labelVal1, value2:headSub.value2, labelVal2:headSub.labelVal2,
                  typeMain: headSub.typeMain, typeVal1: headSub.typeVal1, typeVal2: headSub.typeVal2});
                }
                for (let yi=0; yi<dtArr2.length; yi++) {
                  if (dtArr2[yi].child_group != undefined) { 
                    if (dtArr2[yi].sub_group != '' && dtArr2[yi].child_group != '') {
                      this.dataSumTop.push({group: dtArr2[yi].group, sub_group: dtArr2[yi].sub_group, child_group: dtArr2[yi].child_group,result: dtArr2[yi].result, 
                      label:dtArr2[yi].label, value1:dtArr2[yi].value1, labelVal1:dtArr2[yi].labelVal1, value2:dtArr2[yi].value2, labelVal2:dtArr2[yi].labelVal2,
                      typeMain: dtArr2[yi].typeMain, typeVal1: dtArr2[yi].typeVal1, typeVal2: dtArr2[yi].typeVal2});
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {
      this.dataSumChart = [];
      const arrConfig: any = this.config.dataContent?.configSummaryTable?.sectionChart;
      for ( let i=0; i<arrConfig.length; i++) {
        const arrData: any[] = []; 
        let arrHead: any = [];
        const readArrHead: any[] = [];
        const arrField: any = [];
        if (arrConfig[i].chartType == 'pie') {
          arrField.push({value:arrConfig[i]?.chartConfig?.referenceColumn, type: arrConfig[i]?.chartConfig?.referenceType})
        }
        if (arrConfig[i].chartType == 'bar' || arrConfig[i].chartType == 'line') {
          arrField.push({value:arrConfig[i]?.chartConfig?.colX, type: arrConfig[i]?.chartConfig?.colXType})
        }
        this.globalService.convertData(dt,arrField);
        for (let ii=0; ii<dt.length; ii++) {
          if (arrConfig[i].chartType == 'pie') {
            if (dt[ii][arrConfig[i].chartConfig.referenceColumn] != undefined && dt[ii]?.id != 'dummy') {
              arrHead = readArrHead.filter((x:any) => x == dt[ii][arrConfig[i].chartConfig.referenceColumn]);
              if (arrHead.length == 0) {
                let arrC =0;
                readArrHead.push(dt[ii][arrConfig[i].chartConfig.referenceColumn])
                const dtArr = dt.filter((dd:any) => dd[arrConfig[i].chartConfig.referenceColumn] == dt[ii][arrConfig[i].chartConfig.referenceColumn]);
                for (let a=0; a<dtArr.length; a++) {
                  arrC = arrC + this.globalService.convertToNumber(dtArr[a][arrConfig[i].chartConfig.sourceColumn])
                }
                arrData.push({label: dt[ii][arrConfig[i].chartConfig.referenceColumn], value: arrC})
              }
            }
          } else {
            if (dt[ii][arrConfig[i].chartConfig.colX] != undefined && dt[ii]?.id != 'dummy') {
              arrHead = readArrHead.filter((x:any) => x == dt[ii][arrConfig[i].chartConfig.colX]);
              if (arrHead.length == 0) {
                let arrC =0;
                readArrHead.push(dt[ii][arrConfig[i].chartConfig.colX])
                const dtArr = dt.filter((dd:any) => dd[arrConfig[i].chartConfig.colX] == dt[ii][arrConfig[i].chartConfig.colX]);
                for (let a=0; a<dtArr.length; a++) {
                  arrC = arrC + this.globalService.convertToNumber(dtArr[a][arrConfig[i].chartConfig.colY])
                }
                arrData.push({label: dt[ii][arrConfig[i].chartConfig.colX], value: arrC}) 
              }
            }
          }
        }
        const labels = [];
        const dataSetsData = [];
        const dataSetsBC = [];
        for (let x=0; x < arrData.length; x++) {
          labels.push(arrData[x].label);
          dataSetsData.push(arrData[x].value)
          // dataSetsBC.push('#'+Math.floor(Math.random()*16777215).toString(16))
          if (x < 20) {
            dataSetsBC.push(this.colourPalette1[x])
          } else if ( x > 19 && x < 40) { 
            dataSetsBC.push(this.colourPalette1[x - 20])
          }
          else if ( x > 39 && x < 60) {
            dataSetsBC.push(this.colourPalette1[x - 40])
          }
        }
        this.dataSumChart.push({chartType:arrConfig[i].chartType, chartTitle:arrConfig[i].chartTitle, 
          chartData: {labels: labels, datasets: [{label: 'Data',data: dataSetsData, backgroundColor: dataSetsBC}]}})
      }
    }
  }

  sortDt(data:any) {
    const prGroup:any = this.config.dataContent?.configSummaryTable?.content?.group?.column;
    // const srGroup:any = this.config.dataContent?.configSummaryTable?.content?.group?.column;
    const prSubGroup:any = this.config.dataContent?.configSummaryTable?.content?.sub_group?.column;
    // const srSubGroup:any = this.config.dataContent?.configSummaryTable?.content?.sub_group?.column;
    const prChildGroup:any = this.config.dataContent?.configSummaryTable?.content?.child_group?.column;
    // const srChildGroup:any = this.config.dataContent?.configSummaryTable?.content?.child_group?.column;

    if (prSubGroup != '' && prChildGroup != '') {
      data.sort((a:any, b:any) => (a[prGroup] > b[prGroup]) ? -1 : ((b[prGroup] > a[prGroup]) ? 1 : 0) && 
      (a[prSubGroup] > b[prSubGroup]) ? -1 : ((b[prSubGroup] > a[prSubGroup]) ? 1 : 0) && 
      (a[prChildGroup] > b[prChildGroup]) ? -1 : ((b[prChildGroup] > a[prChildGroup]) ? 1 : 0));
    }
    return data;
  }

  sortData(data:any,act:any) {
    // let param:any = '';
    let srt:any = '';
    if (act == 'sub') {
      // param = this.config.dataContent?.configSummaryTable?.content?.sub_group?.column;
      srt = this.config.dataContent?.configSummaryTable?.content?.sub_group?.sortType;
      if (srt == 'asc') {
        data.sort((a:any,b:any) => (a.sub_group > b.sub_group) ? 1 : ((b.sub_group > a.sub_group) ? -1 : 0));
      } else {
        data.sort((a:any,b:any) => (a.sub_group > b.sub_group) ? -1 : ((b.sub_group > a.sub_group) ? 1 : 0));
      }
    }
    if (act == 'child') {
      // param = this.config.dataContent?.configSummaryTable?.content?.child_group?.column;
      srt = this.config.dataContent?.configSummaryTable?.content?.child_group?.sortType;
      if (srt == 'asc') {
        data.sort((a:any,b:any) => (a.child_group > b.child_group) ? 1 : ((b.child_group > a.child_group) ? -1 : 0));
      } else {
        data.sort((a:any,b:any) => (a.child_group > b.child_group) ? -1 : ((b.child_group > a.child_group) ? 1 : 0));
      }
    }
    // console.log(data,act,param,srt)
    return data;
  }

  actAllFormula(data:any, mainFormula:any, mainVal: any) {
    this.dataTotal = [];
    let result: any = 0;
    let resAvg = 0;
    const resMin = [];
    const resMax = [];
    let labelMain: any = 'Total';
    for (let z=0; z < data.length; z++) {
      if (mainVal != '') {
        // if (Number.isNaN(Number(data[z][mainVal]))) data[z][mainVal] = 0; else data[z][mainVal];
        // console.log(Number(data[z][mainVal]), Number.isNaN(Number(data[z][mainVal])))
        if (mainFormula == 'sum') result = result + this.globalService.convertToNumber(data[z][mainVal]);
        if (mainFormula == 'count') result = result + 1;
        if (mainFormula == 'countA') result = result + 1;
        if (mainFormula == 'countUnique') result = '';
        if (mainFormula == 'avg') resAvg = resAvg + this.globalService.convertToNumber(data[z][mainVal]);
        if (mainFormula == 'max') resMax.push(this.globalService.convertToNumber(data[z][mainVal]));
        if (mainFormula == 'min') resMin.push(this.globalService.convertToNumber(data[z][mainVal]));
        if (mainFormula == '') result = '';
      } else {
        result = '';
      }
    }
    if (mainFormula == 'avg') { labelMain = 'Average'; result = Math.round(resAvg / data.length);}
    // ref: https://www.geeksforgeeks.org/find-the-min-max-element-of-an-array-using-javascript/
    if (mainFormula == 'max') {result = Math.max(...resMax); labelMain = 'Maximum';}
    if (mainFormula == 'min') {result = Math.min(...resMin); labelMain = 'Minimum';}
    // this.dataTotal.push({group: 'Grand Total', sub_group: '', value: '', count:'', result: result, mainVal: mainVal, labelMain: labelMain});
    this.dataTotal.push({group: 'Grand Total', sub_group: '', value: '', count:'', result: result, mainVal: this.labelMain?.header, labelMain: labelMain});
  }

  actGroupFormula(data: any, valGroup: any, mainFormula: any, mainVal: any, group: any) {
    let arrDataGroup: any[] = [];
    let resultGroup: any = 0;
    let resultVal1: any  = 0;
    let resultVal2: any  = 0;
    let resMainAvg = 0;
    const resMainMin = [];
    const resMainMax = [];
    let resVal1Avg = 0;
    const resVal1Min = [];
    const resVal1Max = [];
    let resVal2Avg = 0;
    const resVal2Min = [];
    const resVal2Max = [];
    const val: any = this.config.dataContent?.configSummaryTable?.value;
    const val1: any = val[1]?.column;
    const val1Formula: any = val[1]?.formula;
    const val2: any = val[2]?.column;
    const val2Formula: any = val[2]?.formula;

    arrDataGroup = data.filter((dx:any) => dx[group] == valGroup);
    for (let z=0; z < arrDataGroup.length; z++) {
      if (mainVal != '') {
        if (mainFormula == 'sum') resultGroup = resultGroup + this.globalService.convertToNumber(arrDataGroup[z][mainVal]);
        if (mainFormula == 'count') resultGroup = resultGroup + 1;
        if (mainFormula == 'countA') resultGroup = resultGroup + 1;
        if (mainFormula == 'countUnique') resultGroup = '';
        if (mainFormula == 'avg') resMainAvg = resMainAvg + this.globalService.convertToNumber(arrDataGroup[z][mainVal]);
        if (mainFormula == 'max') resMainMax.push(this.globalService.convertToNumber(arrDataGroup[z][mainVal]));
        if (mainFormula == 'min') resMainMin.push(this.globalService.convertToNumber(arrDataGroup[z][mainVal]));
        if (mainFormula == '') resultGroup = '';
      } else {
        resultGroup = '';
      }
      if (mainFormula == 'avg') resultGroup = Math.round(resMainAvg / arrDataGroup.length);
      if (mainFormula == 'max') resultGroup = Math.max(...resMainMax);
      if (mainFormula == 'min') resultGroup = Math.min(...resMainMin);

      if (val1 != '') {
        if (val1Formula == 'sum') resultVal1 = resultVal1 + this.globalService.convertToNumber(arrDataGroup[z][val1]);
        if (val1Formula == 'count') resultVal1 = resultVal1 + 1;
        if (val1Formula == 'countA') resultVal1 = resultVal1 + 1;
        if (val1Formula == 'countUnique') resultVal1 = '';
        if (val1Formula == 'avg') resVal1Avg = resVal1Avg + this.globalService.convertToNumber(arrDataGroup[z][val1]);
        if (val1Formula == 'max') resVal1Max.push(this.globalService.convertToNumber(arrDataGroup[z][val1]));
        if (val1Formula == 'min') resVal1Min.push(this.globalService.convertToNumber(arrDataGroup[z][val1]));
        if (val1Formula == '') resultVal1 = '';
      } else {
        resultVal1 = '';
      }
      if (val1Formula == 'avg') resultVal1 = Math.round(resMainAvg / arrDataGroup.length);
      if (val1Formula == 'max') resultVal1 = Math.max(...resVal1Max);
      if (val1Formula == 'min') resultVal1 = Math.min(...resVal1Min);

      if (val2 != '') {
        if (val2Formula == 'sum') resultVal2 = resultVal2 + this.globalService.convertToNumber(arrDataGroup[z][val2]);
        if (val2Formula == 'count') resultVal2 = resultVal2 + 1;
        if (val2Formula == 'countA') resultVal2 = resultVal2 + 1;
        if (val2Formula == 'countUnique') resultVal2 = '';
        if (val2Formula == 'avg') resVal2Avg = resVal2Avg + this.globalService.convertToNumber(arrDataGroup[z][val2]);
        if (val2Formula == 'max') resVal2Max.push(this.globalService.convertToNumber(arrDataGroup[z][val2]));
        if (val2Formula == 'min') resVal2Min.push(this.globalService.convertToNumber(arrDataGroup[z][val2]));
        if (val2Formula == '') resultVal2 = '';
      } else {
        resultVal2 = '';
      }
      if (val2Formula == 'avg') resultVal2 = Math.round(resMainAvg / arrDataGroup.length);
      if (val2Formula == 'max') resultVal2 = Math.max(...resVal2Max);
      if (val2Formula == 'min') resultVal2 = Math.min(...resVal2Min);
    }
    // this.dataSum.push({group: valGroup, sub_group: '', child_group: '', result: resultGroup, label: mainVal, 
    //   value1: resultVal1, labelVal1: val1, value2: resultVal2, labelVal2: val2});
    this.dataSum.push({group: valGroup, sub_group: '', child_group: '', result: resultGroup, label: this.labelMain?.header, 
      value1: resultVal1, labelVal1: this.labelVal1?.header, value2: resultVal2, labelVal2: this.labelVal2?.header,
      typeMain: this.typeMain?.type, typeVal1: this.typeVal1?.type, typeVal2: this.typeVal2?.type});
  }

  actSubGroupFormula(data: any, valGroup: any, valSub_group: any, mainFormula: any, mainVal: any, group:any, sub_group:any) {
    let arrDataGroup: any[] = [];
    let resultGroup: any = 0;
    let resultVal1: any  = 0;
    let resultVal2: any  = 0;
    let resMainAvg = 0;
    const resMainMin = [];
    const resMainMax = [];
    let resVal1Avg = 0;
    const resVal1Min = [];
    const resVal1Max = [];
    let resVal2Avg = 0;
    const resVal2Min = [];
    const resVal2Max = [];
    const val: any = this.config.dataContent?.configSummaryTable?.value;
    const val1: any = val[1]?.column;
    const val1Formula: any = val[1]?.formula;
    const val2: any = val[2]?.column;
    const val2Formula: any = val[2]?.formula;

    arrDataGroup = data.filter((dx:any) => dx[group] == valGroup && dx[sub_group] == valSub_group);
    for (let z=0; z < arrDataGroup.length; z++) {
      if (mainVal != '') {
        if (mainFormula == 'sum') resultGroup = resultGroup + this.globalService.convertToNumber(arrDataGroup[z][mainVal]);
        if (mainFormula == 'count') resultGroup = resultGroup + 1;
        if (mainFormula == 'countA') resultGroup = resultGroup + 1;
        if (mainFormula == 'countUnique') resultGroup = '';
        if (mainFormula == 'avg') resMainAvg = resMainAvg + this.globalService.convertToNumber(arrDataGroup[z][mainVal]);
        if (mainFormula == 'max') resMainMax.push(this.globalService.convertToNumber(arrDataGroup[z][mainVal]));
        if (mainFormula == 'min') resMainMin.push(this.globalService.convertToNumber(arrDataGroup[z][mainVal]));
        if (mainFormula == '') resultGroup = '';
      } else {
        resultGroup = '';
      }
      if (mainFormula == 'avg') resultGroup = Math.round(resMainAvg / arrDataGroup.length);
      if (mainFormula == 'max') resultGroup = Math.max(...resMainMax);
      if (mainFormula == 'min') resultGroup = Math.min(...resMainMin);

      if (val1 != '') {
        if (val1Formula == 'sum') resultVal1 = resultVal1 + this.globalService.convertToNumber(arrDataGroup[z][val1]);
        if (val1Formula == 'count') resultVal1 = resultVal1 + 1;
        if (val1Formula == 'countA') resultVal1 = resultVal1 + 1;
        if (val1Formula == 'countUnique') resultVal1 = '';
        if (val1Formula == 'avg') resVal1Avg = resVal1Avg + this.globalService.convertToNumber(arrDataGroup[z][val1]);
        if (val1Formula == 'max') resVal1Max.push(this.globalService.convertToNumber(arrDataGroup[z][val1]));
        if (val1Formula == 'min') resVal1Min.push(this.globalService.convertToNumber(arrDataGroup[z][val1]));
        if (val1Formula == '') resultVal1 = '';
      } else {
        resultVal1 = '';
      }
      if (val1Formula == 'avg') resultVal1 = Math.round(resVal1Avg / arrDataGroup.length);
      if (val1Formula == 'max') resultVal1 = Math.max(...resVal1Max);
      if (val1Formula == 'min') resultVal1 = Math.min(...resVal1Min);

      if (val2 != '') {
        if (val2Formula == 'sum') resultVal2 = resultVal2 + this.globalService.convertToNumber(arrDataGroup[z][val2]);
        if (val2Formula == 'count') resultVal2 = resultVal2 + 1;
        if (val2Formula == 'countA') resultVal2 = resultVal2 + 1;
        if (val2Formula == 'countUnique') resultVal2 = '';
        if (val2Formula == 'avg') resVal2Avg = resVal2Avg + this.globalService.convertToNumber(arrDataGroup[z][val2]);
        if (val2Formula == 'max') resVal2Max.push(this.globalService.convertToNumber(arrDataGroup[z][val2]));
        if (val2Formula == 'min') resVal2Min.push(this.globalService.convertToNumber(arrDataGroup[z][val2]));
        if (val2Formula == '') resultVal2 = '';
      } else {
        resultVal2 = '';
      }
      if (val2Formula == 'avg') resultVal2 = Math.round(resVal2Avg / arrDataGroup.length);
      if (val2Formula == 'max') resultVal2 = Math.max(...resVal2Max);
      if (val2Formula == 'min') resultVal2 = Math.min(...resVal2Min);
    }
    // this.dataSum.push({group: valGroup, sub_group: valSub_group, child_group: '', result: resultGroup, label: mainVal, 
    //   value1: resultVal1, labelVal1: val1, value2: resultVal2, labelVal2: val2});
    this.dataSum.push({group: valGroup, sub_group: valSub_group, child_group: '', result: resultGroup, label: this.labelMain?.header, 
      value1: resultVal1, labelVal1: this.labelVal1?.header, value2: resultVal2, labelVal2: this.labelVal2?.header,
      typeMain: this.typeMain?.type, typeVal1: this.typeVal1?.type, typeVal2: this.typeVal2?.type});
  }

  actChildGroupFormula(data: any, valGroup: any, valSub_group: any, valChild_group: any, mainFormula: any, mainVal: any, 
    group:any, sub_group:any,child_group:any) {
    let arrDataGroup: any[] = [];
    let resultGroup: any = 0;
    let resultVal1: any  = 0;
    let resultVal2: any  = 0;
    let resMainAvg = 0;
    const resMainMin = [];
    const resMainMax = [];
    let resVal1Avg = 0;
    const resVal1Min = [];
    const resVal1Max = [];
    let resVal2Avg = 0;
    const resVal2Min = [];
    const resVal2Max = [];
    const val: any = this.config.dataContent?.configSummaryTable?.value;
    const val1: any = val[1]?.column;
    const val1Formula: any = val[1]?.formula;
    const val2: any = val[2]?.column;
    const val2Formula: any = val[2]?.formula;
    arrDataGroup = data.filter((dx:any) => dx[group] == valGroup && dx[sub_group] == valSub_group && dx[child_group] == valChild_group);
    for (let z=0; z < arrDataGroup.length; z++) {
      if (mainVal != '') {
        if (mainFormula == 'sum') resultGroup = resultGroup + this.globalService.convertToNumber(arrDataGroup[z][mainVal]);
        if (mainFormula == 'count') resultGroup = resultGroup + 1;
        if (mainFormula == 'countA') resultGroup = resultGroup + 1;
        if (mainFormula == 'countUnique') resultGroup = '';
        if (mainFormula == 'avg') resMainAvg = resMainAvg + this.globalService.convertToNumber(arrDataGroup[z][mainVal]);
        if (mainFormula == 'max') resMainMax.push(this.globalService.convertToNumber(arrDataGroup[z][mainVal]));
        if (mainFormula == 'min') resMainMin.push(this.globalService.convertToNumber(arrDataGroup[z][mainVal]));
        if (mainFormula == '') resultGroup = '';
      } else {
        resultGroup = '';
      }
      if (mainFormula == 'avg') resultGroup = Math.round(resMainAvg / arrDataGroup.length);
      if (mainFormula == 'max') resultGroup = Math.max(...resMainMax);
      if (mainFormula == 'min') resultGroup = Math.min(...resMainMin);

      if (val1 != '') {
        if (val1Formula == 'sum') resultVal1 = resultVal1 + this.globalService.convertToNumber(arrDataGroup[z][val1]);
        if (val1Formula == 'count') resultVal1 = resultVal1 + 1;
        if (val1Formula == 'countA') resultVal1 = resultVal1 + 1;
        if (val1Formula == 'countUnique') resultVal1 = '';
        if (val1Formula == 'avg') resVal1Avg = resVal1Avg + this.globalService.convertToNumber(arrDataGroup[z][val1]);
        if (val1Formula == 'max') resVal1Max.push(this.globalService.convertToNumber(arrDataGroup[z][val1]));
        if (val1Formula == 'min') resVal1Min.push(this.globalService.convertToNumber(arrDataGroup[z][val1]));
        if (val1Formula == '') resultVal1 = '';
      } else {
        resultVal1 = '';
      }
      if (val1Formula == 'avg') resultVal1 = Math.round(resVal1Avg / arrDataGroup.length);
      if (val1Formula == 'max') resultVal1 = Math.max(...resVal1Max);
      if (val1Formula == 'min') resultVal1 = Math.min(...resVal1Min);

      if (val2 != '') {
        if (val2Formula == 'sum') resultVal2 = resultVal2 + this.globalService.convertToNumber(arrDataGroup[z][val2]);
        if (val2Formula == 'count') resultVal2 = resultVal2 + 1;
        if (val2Formula == 'countA') resultVal2 = resultVal2 + 1;
        if (val2Formula == 'countUnique') resultVal2 = '';
        if (val2Formula == 'avg') resVal2Avg = resVal2Avg + this.globalService.convertToNumber(arrDataGroup[z][val2]);
        if (val2Formula == 'max') resVal2Max.push(this.globalService.convertToNumber(arrDataGroup[z][val2]));
        if (val2Formula == 'min') resVal2Min.push(this.globalService.convertToNumber(arrDataGroup[z][val2]));
        if (val2Formula == '') resultVal2 = '';
      } else {
        resultVal2 = '';
      }
      if (val2Formula == 'avg') resultVal2 = Math.round(resVal2Avg / arrDataGroup.length);
      if (val2Formula == 'max') resultVal2 = Math.max(...resVal2Max);
      if (val2Formula == 'min') resultVal2 = Math.min(...resVal2Min);
    }
    // this.dataSum.push({group: valGroup, sub_group: valSub_group, child_group: valChild_group, result: resultGroup, label: mainVal, 
    //   value1: resultVal1, labelVal1: val1, value2: resultVal2, labelVal2: val2});
    this.dataSum.push({group: valGroup, sub_group: valSub_group, child_group: valChild_group, result: resultGroup, label: this.labelMain?.header, 
      value1: resultVal1, labelVal1: this.labelVal1?.header, value2: resultVal2, labelVal2: this.labelVal2?.header,
      typeMain: this.typeMain?.type, typeVal1: this.typeVal1?.type, typeVal2: this.typeVal2?.type});
  }

  getFilter(filter:any) {
    this.propertyFilter =[];
    this.propertyFilter = this.arrFilter.filter((dx:any) => dx.column == filter);
  }

  cancelFilter() {
    this.is_showFilter = false;
    if (!this.config.layoutDesign?.summaryType || this.config.layoutDesign?.summaryType == 'data') {
      this.getInfoColumn();
      setTimeout(() => {
        this.globalService.convertData(this.data,this.arrContent);
      },500);
    }
    this.getDataSummary(this.data);
  }

  showFilter(f: NgForm, propertyFilter:any) {
    this.is_showFilter = false;
    const body = f.value;
    let dtFilter = [];
    if (propertyFilter?.columnType == 'text' || propertyFilter?.columnType == 'string' || propertyFilter?.columnType == 'link' || 
      propertyFilter?.columnType == 'image' || propertyFilter?.columnType == 'boolean' || propertyFilter?.columnType == 'text_editor') {
      dtFilter = this.data.filter((dt:any) => dt[propertyFilter.column].toLocaleLowerCase().includes(body.filterText.toLocaleLowerCase()))
    }
    if (propertyFilter?.columnType == 'number') {
      if (body.formulaValue == '==') {
        dtFilter = this.data.filter((dt:any) => dt[propertyFilter.column] == body.filterNumber)
      }
      if (body.formulaValue == '>') {
        dtFilter = this.data.filter((dt:any) => dt[propertyFilter.column] > body.filterNumber)
      }
      if (body.formulaValue == '<') {
        dtFilter = this.data.filter((dt:any) => dt[propertyFilter.column] < body.filterNumber)
      }
    }
    if (propertyFilter?.columnType == 'date' || propertyFilter?.columnType == 'time') {
      this.cloneArr();
      const date1: any = new Date(body?.filterDate1).getTime();
      const date2: any = new Date((body?.filterDate2).getTime() + (23.99*60*60*1000));
      dtFilter = this.data.filter((dt:any) => (dt[propertyFilter.column]['seconds']*1000) >= date1 && (dt[propertyFilter.column]['seconds']*1000) <= date2)
    }
    if (!this.config.layoutDesign?.summaryType || this.config.layoutDesign?.summaryType == 'data') {
      this.globalService.convertData(dtFilter,this.arrContent);
    }  
    this.getDataSummary(dtFilter);
  }

  cloneArr() {
    this.data = [];
    this.dataSummary.forEach(val => this.data.push(Object.assign({}, val)));
  }

  exportPdf(): void {
    const source: any = window.document.getElementById("mysummary");
    html2canvas(source, { allowTaint: false, useCORS: true }).then(canvas => {
      // Few necessary setting options
      const contentDataURL = canvas.toDataURL('image/png')
      const pdf = new jsPDF('p', 'mm'); // A4 size page of PDF
      const width = pdf.internal.pageSize.getWidth();
      const height = canvas.height * width / canvas.width;
      pdf.addImage(contentDataURL, 'PNG', 0, 0, width, height)
      pdf.save(`${this.config.pageProperty?.pageTitle}.pdf`); // Generated PDF
    });
  }

  public downloadAsPDF() {
    this.globalService.changeBlockui(true);
    const data: any = window.document.getElementById("mysummary_dialog");
    html2canvas(data).then((canvas:any) => {
      const imgWidth = 210;
      const pageHeight = 297;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jsPDF('p', 'mm');
      doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
      }
      doc.save(`${this.config.pageProperty?.pageTitle}.pdf`);
    });
    setTimeout(() => {
      this.globalService.changeBlockui(false);
      this.is_download = false;
    },2000);
  }
}
