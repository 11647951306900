import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  GlobalService,
  GlobalcrudService,
  LoaderService,
  SessionService,
  ToastNotificationService,
} from "@core-fibr/shared";
import { TranslateService } from "@ngx-translate/core";
import { environment, AuthenticationService } from "@core-fibr/auth";
import { NgForm } from "@angular/forms";

@Component({
  selector: 'ui-fibr-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  elementForm: any[]=[];
  elementForm2: any[]=[];
  is_otp = false;
  userProject = {};
  uid = '';
  appConfig:any;
  logo = '';
  @Input() params: any;
  @Output() registerClick = new EventEmitter<any>();
  @Output() loginClick = new EventEmitter<any>();
  
  constructor(private router: Router,
    public translate: TranslateService,
    public sessionService: SessionService,
    public loaderService: LoaderService,
    public authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private globalCrudService: GlobalcrudService,
    private notif: ToastNotificationService,
    private authService: AuthenticationService) { }

  ngOnInit(): void {
    this.elementForm = [{field: 'email', label: 'Email', value: '', required: true}]
    this.elementForm2 = [
      {field: 'otp', label: 'OTP', value: '', required: true},
      {field: 'password', label: 'New Password', value: '', required: true}
    ];
    this.globalService.appConfig.subscribe((val: any) => this.appConfig = val);
    if (this.appConfig.is_paid && this.appConfig.app_logo != '') this.logo = this.appConfig.app_logo; else this.logo = '/assets/logo_appfibr_vertical.svg';
  }

  onClickSendEmail(f: NgForm) {
    const errVal = this.validasi(f.value)
    if (errVal != '') {
      this.notif.showNotification("error", errVal);
    } else {
      this.globalService.changeBlockui(true);
      this.userProject = this.globalService.getCurrReffDataParsed("user_project");
      const body = {email: f.value.email, userId: this.params.id, projectId: this.params.appid}
      this.globalService.methodPost(environment.be_dev + `/api/v1/fibr-app/auth/password/reset`,body)
      .subscribe((res) => {
        setTimeout(() => {
          this.notif.showNotification(res.status, res.message);
          this.is_otp = true;
          this.uid = res.data.id;
          this.globalService.changeBlockui(false);
        }, 1000);
      },
      (err: any) => {
        this.notif.showNotification(err?.error?.status, err?.error?.message);
        this.globalService.changeBlockui(false);
      });
    }
  }

  onClickResetPassword(f2: NgForm) { 
    const errVal = this.validasi2(f2.value)
    if (errVal != '') {
      this.notif.showNotification("error", errVal);
    } else {
      this.globalService.changeBlockui(true);
      this.userProject = this.globalService.getCurrReffDataParsed("user_project");
      const body = {otp: f2.value.otp, userId: this.params.id, projectId: this.params.appid, password: f2.value.password}
      this.globalService.methodPut(environment.be_dev + `/api/v1/fibr-app/auth/password/reset/${this.uid}`,body)
      .subscribe((res) => {
        setTimeout(() => {
          this.notif.showNotification(res.status, res.message);
          this.loginClick.emit(false);
          this.globalService.changeBlockui(false);
        }, 1000);
      },
      (err: any) => {
        this.notif.showNotification(err?.error?.status, err?.error?.message);
        this.globalService.changeBlockui(false);
      });
    }
  }

  onClickResendEmail() {
    this.is_otp = false;
  }

  onClickLogin() {
    this.loginClick.emit(false);
  }

  validasi(val: any)  {
    let isEmail = false;
    let errValidasi2 = '';
    if(val.email == '') {
      errValidasi2 = errValidasi2 + 'Email is required!';
    } else {
      const regexp = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
      isEmail = regexp.test(val.email);
      if (!isEmail) {
        errValidasi2 = errValidasi2 + 'Email invalid Format!';
      }
    }
    return errValidasi2;
  }

  validasi2(val: any)  {
    let errValidasi2 = '';
    if(val.otp == '') {
      errValidasi2 = errValidasi2 + 'OTP cannot be empty!';
    } 
    if(val.password == '') {
      errValidasi2 = errValidasi2 + 'new password cannot be empty!';
    } 
    return errValidasi2;
  }

}
