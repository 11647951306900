<div class="grid">
  <div class="col-12">
    <p-table #table [columns]="columns" 
    [id]="tableId"
    [value]="gridModel?.Data!" 
    [lazy]="true" 
    selectionMode="single" 
    [(selection)]="multipleSelection" 
    dataKey="id"
    styleClass="p-datatable-gridlines" 
    responsiveLayout="scroll"
    [paginator]="gridModel.isPaginator" 
    [rows]="rowsPerPage" 
    [showCurrentPageReport]="showCurrentPageReport"
    [currentPageReportTemplate]="currentPageReportTemplate" 
    [rowsPerPageOptions]="rowsPerPageOptions"
    [loading]="isLoading" 
    [totalRecords]="totalRecords" 
    (onLazyLoad)="lazyLoadData($event)" 
    [resizableColumns]="false"
    [scrollable]="true" 
    [scrollHeight]="'flex'"
    *ngIf="isVisible">
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <ng-container *ngFor="let col of columns">
            <ng-container *ngIf="checkArray(col.field) === true; else notArrayColumnTemplate">
              <col class="button-column-width">
            </ng-container>
            <ng-template #notArrayColumnTemplate>
              <col class="resizable-column-width">
            </ng-template>
          </ng-container>
        </colgroup>
      </ng-template>
      <ng-template pTemplate="caption">
        <div class="flex p-flex-column p-flex-md-row" *ngIf="gridModel.GridName">
            <img class="align-self-center mr-3" alt="icon" src="/assets/images/icon-lg/icon_table-lg.svg" height="20">
            <h3 class="menu-title">{{gridModel.GridName}}</h3>
        </div>
        <div class="flex flex-column justify-content-between flex-wrap md:flex-row p-jc-md-between mt-3" *ngIf="!isGoogleFiles">
          <div class="p-inputgroup md:col-4 col-12 my-0 md:my-3 p-0" *ngIf="!isGoogleFiles && gridModel.isShowFilter">
              <input type="text" pInputText placeholder="{{'editor.search' | translate}}" [(ngModel)]="textSearch" (keydown.enter)="filterGlobal($any($event.target).value, 'contains')">
              <span class="p-inputgroup-addon" (click)="filterGlobal(textSearch, 'contains')"><i class="pi pi-search"></i></span>        
          </div>
          <div class="col-auto ml-auto p-0 mt-3">
            <button *ngIf="multipleSelection.length > 0" type="button" pButton pRipple (click)="submitEvent('copy_row')" icon="pi pi-copy" iconPos="left" label="Copy Row"
              class="p-button p-button-secondary mr-2 mb-2"></button>
            <button *ngIf="multipleSelection.length > 0" type="button" pButton pRipple (click)="submitEvent('remove')" icon="pi pi-trash" iconPos="left" label="Delete Row"
              class="p-button p-button-secondary text-magenta mr-2 mb-2"></button>
            <p-button *ngIf="gridModel.isAddNewData && !isGoogleFiles" label="Tambah Data" icon="pi pi-plus" iconPos="right" (onClick)="createNewRow()" styleClass="mr-2 mb-2"> </p-button>
            <button *ngIf="gridModel.isAddNewColumn && !isGoogleFiles" type="button" pButton pRipple (click)="addNewColumns()" icon="pi pi-plus" iconPos="left" label="{{'editor.grid.add.column' | translate}}"
                class="p-button-secondary mr-2 mb-2"></button>
            <button *ngIf="gridModel.isUpload" type="button" pButton pRipple (click)="uploadButton()" icon="pi pi-upload" iconPos="left" label=""
                class="p-button-warning mr-2 mb-2" pTooltip="Upload Data" tooltipPosition="bottom"></button>
            <button *ngIf="gridModel.isUpload" type="button" pButton pRipple (click)="removeButton()" icon="pi pi-trash" iconPos="left" label=""
                class="p-button-danger mr-2 mb-2" pTooltip="Remove All Data" tooltipPosition="bottom"></button>
            <button type="button" pButton pRipple (click)="actionTable.toggle($event)" class="p-button-secondary mr-2 mb-2" label="" icon="pi pi-ellipsis-v" iconPos="right"> </button>
            <p-menu #actionTable appendTo="body" [popup]="true" [model]="itemTable"></p-menu>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr *ngIf="columns.length > 0">
          <th>
            <p-tableHeaderCheckbox (click)='isMultipleSelection = !isMultipleSelection;onRowMultipleSelect($event);' *ngIf="gridModel.isEditor">
            </p-tableHeaderCheckbox>
          </th>
          <ng-container *ngFor="let col of columns">
            <th pResizableColumn *ngIf="col.sortable" [ngStyle]="{'width': (col.type === 'empty' || col.type === 'button' || col.type === 'icon' )? '8%' : (col.type === 'button2'? '15%' : 'auto'),
            'max-width': (col.type === 'empty' || col.type === 'button' || col.type === 'icon' )? '8%' : (col.type === 'button2'? '15%' : 'auto')}">
              <div class="flex justify-content-center align-items-center w-100">
                <!-- <span class="mr-2" *ngIf="gridModel.isEditor"> -->
                  <span class="mr-2">
                    <button type="button" pButton class="p-button-secondary p-button-icon-only data-type-btn" (click)="editColumns(col)">
                      <ng-container *ngIf="col.type !== 'button' && col.type !== 'image' && col.type !== 'date' && col.type !== 'time' 
                      && col.type !== 'datepicker' && col.type !== 'checkbox' && col.type !== 'link' && col.type !== 'number' 
                      && col.type !== 'boolean' && col.type !== 'text_editor' && col.type !== 'qrcode' && col.type !== 'calculation' 
                      && col.type !== 'autofill_calculation' && col.type !== 'location'">
                        <img alt="icon" src='/assets/images/icon-data-type/icon_type-string.svg'/>
                      </ng-container>
                      <ng-container *ngIf="col.type === 'date'">
                        <img alt="icon" src='/assets/images/icon-data-type/icon_type-data.svg'/>
                      </ng-container>
                      <ng-container *ngIf="col.type === 'time'">
                        <img alt="icon" src='/assets/images/icon-data-type/icon_type-time.svg'/>
                      </ng-container>
                      <ng-container *ngIf="col.type === 'number'">
                        <img alt="icon" src='/assets/images/icon-data-type/icon_type-number.svg'/>
                      </ng-container>
                      <ng-container *ngIf="col.type === 'image'">
                        <img alt="icon" src='/assets/images/icon-data-type/icon_type-img.svg'/>
                      </ng-container>
                      <ng-container *ngIf="col.type === 'link'">
                        <img alt="icon" src='/assets/images/icon-data-type/icon_type-link.svg'/>
                      </ng-container>
                      <ng-container *ngIf="col.type === 'boolean'">
                        <img alt="icon" src='/assets/images/icon-data-type/icon_type-boolean.svg'/>
                      </ng-container>
                      <ng-container *ngIf="col.type === 'text_editor'">
                        <img alt="icon" src='/assets/images/icon-data-type/icon_type-text-editor.svg'/>
                      </ng-container>
                      <ng-container *ngIf="col.type === 'qrcode'">
                        <img alt="icon" src='/assets/images/icon-data-type/icon_type-qrcode.svg'/>
                      </ng-container>
                      <ng-container *ngIf="col.type === 'calculation'">
                        <img alt="icon" src='/assets/images/icon-data-type/icon_type-calculation.svg'/>
                      </ng-container>
                      <ng-container *ngIf="col.type === 'autofill_calculation'">
                        <img alt="icon" src='/assets/images/icon-data-type/icon_type-autofill-calculation.svg'/>
                      </ng-container>
                      <ng-container *ngIf="col.type === 'location'">
                        <img alt="icon" src='/assets/images/icon-data-type/icon_type-location.svg'/>
                      </ng-container>
                    </button>
                </span>
                <span class="header-text" pEditableColumn pTooltip="{{ col.header }}" tooltipPosition="bottom">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="col.header" (mouseout)="cekUpdateColumn('edit',col.field)" (keydown.enter)="cekUpdateColumn('edit',col.field)" (keydown.tab)="cekUpdateColumn('edit',col.field)" >
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ col.header }}
                    </ng-template>
                  </p-cellEditor>
                </span> 
                <span [pSortableColumn]="col.field" class="ml-auto">
                    <p-sortIcon [field]="col.field" *ngIf="col.sortable" style="float: right;"></p-sortIcon>
                </span>
              </div>
            </th>
            <th pResizableColumn *ngIf="!col.sortable"
            [ngStyle]="{'width': (col.type === 'empty' || col.type === 'button' || col.type === 'icon' )? '8%' : (col.type === 'button2'? '15%' : 'auto'),
            'max-width': (col.type === 'empty' || col.type === 'button' || col.type === 'icon' )? '8%' : (col.type === 'button2'? '15%' : 'auto'),
            'text-align': (col.type === 'button2'? 'center' : 'unset')}">
              <p-cellEditor>
                <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="col.header" (mouseout)="cekUpdateColumn('edit',col.field)" (keydown.enter)="cekUpdateColumn('edit',col.field)" (keydown.tab)="cekUpdateColumn('edit',col.field)" >
                </ng-template>
                <ng-template pTemplate="output">
                  {{ col.header }}
                </ng-template>
              </p-cellEditor>
            </th>
          </ng-container>
        </tr>
        <!-- <tr *ngIf="gridModel.isShowFilter">
          <th></th>
          <th *ngFor="let col of columns">
            <ng-container *ngIf="col.filterable === null || col.filterable">
              <p-columnFilter type="text" matchMode="equals" [field]="col.field" [matchModeOptions]="matchModeOptions"
                *ngIf="col.type !== 'button' && col.type !== 'button2'">
              </p-columnFilter>
            </ng-container>
          </th>
        </tr> -->
      </ng-template>

      <!-- Can edit/ modify -->
      <ng-template pTemplate="body" let-datas let-data let-index let-columns="columns" *ngIf="gridModel.isEditor">
        <tr>
          <td>
            <ng-container>
              <p-tableCheckbox [value]='datas' (onRowSelect)="onRowSelects($event)" (onRowUnselect)="onRowUnSelects($event)"></p-tableCheckbox>
            </ng-container>
          </td>
          <td pEditableColumn *ngFor="let col of columns" [ngClass]="{'text-center': col.type === 'checkbox'}"
          [ngStyle]="{'width': (col.type === 'empty' || col.type === 'button' || col.type === 'icon' )? '8%' : (col.type === 'button2'? '15%' : 'auto'),
          'max-width': (col.type === 'empty' || col.type === 'button' || col.type === 'icon' )? '8%' : (col.type === 'button2'? '15%' : 'auto'),
          'text-align': (col.type === 'button2'|| col.type === 'icon')? 'center' : 'unset',
          'justify-content': (col.type === 'button2'|| col.type === 'icon')? 'center' : 'unset'}">
            <ng-container *ngIf="col.type !== 'button' && col.type !== 'image' && col.type !== 'date' && col.type !== 'time' 
            && col.type !== 'datepicker' && col.type !== 'checkbox' && col.type !== 'link' && col.type !== 'boolean' && col.type !== 'number' 
            && col.type !== 'text_editor' && col.field !== 'password' && col.field !== 'role' && col.type !== 'qrcode'
            && col.type !== 'calculation' && col.type !== 'autofill_calculation' && col.type !== 'location'">
              <p-cellEditor pTooltip="{{ data[col.field] }}" tooltipPosition="left">
                <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="data[col.field]" (mouseout)="updateGrid(data, col.type, col.field)" (keydown.enter)="updateGrid(data, col.type, col.field)" (keydown.tab)="updateGrid(data, col.type, col.field)" (keydown)="disabledNav($event)">
                </ng-template>
                <ng-template pTemplate="output">
                  <span class="output">{{ data[col.field] }}</span>
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.type === 'date' ">
              <p-cellEditor (click)="showDateTimeType=true; getDataGrid(data, col.type, col.field, $event)">
                <ng-template pTemplate="input">
                  <span class="p-column-title" *ngIf="data[col.field] !== null && data[col.field] !== 0">
                    {{(data[col.field]['seconds']*1000) | date : 'dd MMM yyyy' }}
                    <!-- {{((data[col.field] | numberString) | slice:0:11)}} -->
                  </span>
                  <!-- <p-button styleClass="p-button p-button-secondary p-button-table p-button-date" (click)="showDateTimeType=true; getDataGrid(data, col.type, col.field, $event)" pTooltip="Change Date" tooltipPosition="bottom" icon="pi pi-calendar" iconPos="right"></p-button> -->
                </ng-template>
                <ng-template pTemplate="output">
                  <span class="p-column-title" *ngIf="data[col.field] !== null && data[col.field] !== 0">
                    {{(data[col.field]['seconds']*1000) | date : 'dd MMM yyyy' }}
                    <!-- {{((data[col.field] | numberString) | slice:0:11)}} -->
                  </span>
                  <!-- <p-button styleClass="p-button p-button-secondary p-button-table p-button-date" (click)="showDateTimeType=true; getDataGrid(data, col.type, col.field, $event)" pTooltip="Change Date" tooltipPosition="bottom" icon="pi pi-calendar" iconPos="right"></p-button> -->
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.type === 'time' ">
              <p-cellEditor (click)="showDateTimeType=true; getDataGrid(data, col.type, col.field, $event)">
                <ng-template pTemplate="input">
                  <span class="p-column-title" *ngIf="data[col.field] !== null && data[col.field] !== 0">
                    {{(data[col.field]['seconds']*1000) | date : 'hh:mm' }}
                    <!-- {{((data[col.field] | numberString) | slice:12:17)}} -->
                  </span>
                  <!-- <p-button styleClass="p-button p-button-secondary p-button-table" (click)="showDateTimeType=true; getDataGrid(data, col.type, col.field, $event)" pTooltip="Change Time" tooltipPosition="bottom" icon="pi pi-clock" iconPos="right"></p-button> -->
                </ng-template>
                <ng-template pTemplate="output">
                  <span class="p-column-title" *ngIf="data[col.field] !== null && data[col.field] !== 0">
                    {{(data[col.field]['seconds']*1000) | date : 'hh:mm' }}
                    <!-- {{((data[col.field] | numberString) | slice:12:17)}} -->
                  </span>
                  <!-- <p-button styleClass="p-button p-button-secondary p-button-table" (click)="showDateTimeType=true; getDataGrid(data, col.type, col.field, $event)" pTooltip="Change Time" tooltipPosition="bottom" icon="pi pi-clock" iconPos="right"></p-button> -->
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.type === 'checkbox' ">
              <p-cellEditor>
                <ng-template pTemplate="input">
                    <p-checkbox [(ngModel)]="data[col.field]" [binary]="true" [disabled]="false" (ngModelChange)="updateGrid(data, col.type, col.field)" (keydown)="disabledNav($event)"></p-checkbox>
                </ng-template>
                <ng-template pTemplate="output">
                  <p-checkbox [(ngModel)]="data[col.field]" [binary]="true" [disabled]="true"></p-checkbox>
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.type === 'number' ">
              <p-cellEditor pTooltip="{{ data[col.field] | number}}" tooltipPosition="left">
                <ng-template pTemplate="input">
                  <p-inputNumber [(ngModel)]="data[col.field]" [useGrouping]="false" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="2" 
                    [step]="0" (keydown.enter)="updateGrid(data, col.type, col.field); getCalculation(data,col.field,columns)" 
                    (keydown.tab)="updateGrid(data, col.type, col.field); getCalculation(data,col.field,columns)" 
                    (keydown)="disabledNav($event)">
                  </p-inputNumber>
                </ng-template>
                <ng-template pTemplate="output">
                  <span class="output">{{ data[col.field] | number }}</span>
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.type === 'location'">
              <p-cellEditor pTooltip="{{ data[col.field] }}" tooltipPosition="left">
                <ng-template pTemplate="input">
                  <p-inputNumber [(ngModel)]="data[col.field]" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="6" 
                    [step]="0" (keydown.enter)="updateGrid(data, col.type, col.field)" (keydown.tab)="updateGrid(data, col.type, col.field)" 
                    (keydown)="disabledNav($event)">
                  </p-inputNumber>
                </ng-template>
                <ng-template pTemplate="output">
                  <span class="output">{{ data[col.field] }}</span>
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.type === 'boolean' ">
              <p-cellEditor pTooltip="{{ data[col.field] }}" tooltipPosition="left">
                <ng-template pTemplate="input">
                  <!-- <input pInputText type="text" [(ngModel)]="data[col.field]" (keydown.enter)="updateGrid(data)" (keydown.tab)="updateGrid(data)" > -->
                  <!-- <p-dropdown [options]="typeBoolean" [(ngModel)]="data[col.field]" optionLabel="label" optionValue="value" (onChange)="updateGrid(data)" appendTo="body"></p-dropdown> -->
                  <p-checkbox [(ngModel)]="data[col.field]" [binary]="true" inputId="binary" (onChange)="updateGrid(data, col.type, col.field)" (keydown)="disabledNav($event)"></p-checkbox>
                </ng-template>
                <ng-template pTemplate="output">
                  <p-checkbox [(ngModel)]="data[col.field]" [binary]="true" inputId="binary" (onChange)="updateGrid(data, col.type, col.field)" (keydown)="disabledNav($event)"></p-checkbox>
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.type === 'link' ">
              <p-cellEditor pTooltip="{{ data[col.field] }}" tooltipPosition="left">
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="data[col.field]" (mouseout)="updateGrid(data, col.type, col.field)" (keydown.enter)="updateGrid(data, col.type, col.field)" (keydown.tab)="updateGrid(data, col.type, col.field)" (keydown)="disabledNav($event)">
                </ng-template>
                <ng-template pTemplate="output">
                  <a href="javascript:;" (click)="openTypeDataLink(data[col.field])"><span class="output">{{ data[col.field] }}</span></a>
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.type === 'button' ">
              <span class="p-column-title">{{col.header}}</span>
              <ng-container *ngFor="let button of col.field">
                <button pButton [icon]="button.icon" class="p-button-raised p-button-secondary p-button-text mr-2 mb-2"
                  (click)="onButtonActionClick(button, data)" (keydown)="disabledNav($event)">{{button.Title}}
                </button>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="col.type === 'button2' ">
              <ng-container *ngFor="let button of col.field">
                <button pButton class="p-button-primary" [icon]="button.icon"
                  (click)="onButtonActionClick2(button, data)" (keydown)="disabledNav($event)">{{button.Title}}</button>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="col.type === 'icon' ">
              <img src="/assets/images/icon/icon_g-sheet.svg" height="20px"/>
            </ng-container>
            <ng-container *ngIf="col.type === 'image' ">
              <p-cellEditor (click)="showImageUploadOpt=true; getDataGrid(data, 'image', col.field, $event)">
                <ng-template pTemplate="input">
                  <img *ngIf="data[col.field] !== null && data[col.field] !== ''" height="20" [src]="data[col.field] | gdriveConvert" onerror="this.src = './assets/iconic_logo.png'"/>
                </ng-template>
                <ng-template pTemplate="output">
                  <img *ngIf="data[col.field] !== null && data[col.field] !== ''" height="20" [src]="data[col.field] | gdriveConvert" onerror="this.src = './assets/iconic_logo.png'" (mouseenter)="ppInfo.toggle($event)" (mouseleave)="ppInfo.toggle($event)"/>
                  <p-overlayPanel #ppInfo styleClass="overlay-info" appendTo="body">
                      <ng-template pTemplate>
                          <div class="p-card-shadow p-card p-component">
                              <div class="p-card-body d-flex p-3">
                                <img alt="Image Preview" [src]="data[col.field] | gdriveConvert" class="w-100 border-round-md"/>
                              </div>
                          </div>
                      </ng-template>
                  </p-overlayPanel>
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.type === 'text_editor' ">
              <p-cellEditor (click)="showDateTimeType=true; getDataGrid(data, col.type, col.field, $event)">
                <ng-template pTemplate="input">
                  {{ data[col.field] }}
                </ng-template>
                <ng-template pTemplate="output">
                  {{ data[col.field] }}
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.type === 'qrcode' ">
              <p-cellEditor (click)="showDateTimeType=true; getDataGrid(data, 'qrcode', col.field, $event)">
                <ng-template pTemplate="input">
                  <img *ngIf="data[col.field] !== null && data[col.field] !== ''" height="20" [src]="gbrQrcode" onerror="this.src = './assets/iconic_logo.png'"/>
                </ng-template>
                <ng-template pTemplate="output">
                  <!-- <qrcode *ngIf="data[col.field] !== null && data[col.field] !== ''" [qrdata]="data[col.field]" [errorCorrectionLevel]="'M'" [elementType]="'img'" [allowEmptyString]="true"></qrcode> -->
                  <img *ngIf="data[col.field] !== null && data[col.field] !== ''" height="20" [src]="gbrQrcode" onerror="this.src = './assets/iconic_logo.png'"/>
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.type === 'calculation' || col.type === 'autofill_calculation'">
              <p-cellEditor pTooltip="{{ data[col.field] | number}}" tooltipPosition="left">
                <ng-template pTemplate="input"><span class="output">{{ data[col.field] | number }}</span></ng-template>
                <ng-template pTemplate="output"><span class="output">{{ data[col.field] | number }}</span></ng-template>
              </p-cellEditor>
            </ng-container>
            <!-- ubah password userapp_fibr -->
            <ng-container *ngIf="col.field === 'password' && gridModel.GridName === 'userapp_fibr' ">
              <p-cellEditor (click)="showDateTimeType=true; getDataGrid(data, col.type, col.field, $event)">
                <ng-template pTemplate="input">
                  <span class="p-column-title">{{data[col.field]}}</span>
                </ng-template>
                <ng-template pTemplate="output">
                  <span class="p-column-title">{{data[col.field]}}</span>
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <!-- ubah role userapp_fibr -->
            <ng-container *ngIf="col.field === 'role' && gridModel.GridName === 'userapp_fibr' ">
              <p-cellEditor (click)="showDateTimeType=true; getDataGrid(data, col.type, col.field, $event); getDataRole();">
                <ng-template pTemplate="input">
                  <span class="p-column-title">{{data[col.field]}}</span>
                </ng-template>
                <ng-template pTemplate="output">
                  <span class="p-column-title">{{data[col.field]}}</span>
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <!-- ubah data roleapp_fibr -->
            <ng-container *ngIf="col.field === 'role' && gridModel.GridName === 'roleapp_fibr' ">
              <p-cellEditor pTooltip="{{ data[col.field] }}" tooltipPosition="left">
                <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="data[col.field]" (mouseout)="updateGrid(data, col.type, col.field)" (keydown.enter)="updateGrid(data, col.type, col.field)" (keydown.tab)="updateGrid(data, col.type, col.field)" (keydown)="disabledNav($event)">
                </ng-template>
                <ng-template pTemplate="output">
                  {{ data[col.field] }}
                </ng-template>
              </p-cellEditor>
            </ng-container>
          </td>
        </tr>
      </ng-template>
      <!-- <ng-template pTemplate="summary" *ngIf="gridModel.isEditor && gridModel.GridName === 'userapp_fibr'">
        <div _ngcontent-ybi-c204="" class="flex align-items-center justify-content-between ng-star-inserted mt-5">
          * Use your login account credential to login in app as admin
        </div>
      </ng-template> -->
      <ng-template pTemplate="summary" *ngIf="gridModel.isEditor">
        <div _ngcontent-ybi-c204="" *ngIf="gridModel.GridName === 'userapp_fibr'" class="flex align-items-center justify-content-between ng-star-inserted mt-5">
          * Use your login account credential to login in app as admin
        </div>
        <div class="flex align-items-center justify-content-between text-magenta">
          {{'editor.max_table' | translate:{ max_tables, max_rows, statusProj } }}
        </div>
      </ng-template>
      
      <!-- Can't edit/ modify -->
      <ng-template pTemplate="body" let-data let-index let-columns="columns" *ngIf="!gridModel.isEditor">
        <tr [ngStyle]="{'background-color': data === null ? null : data['IsLate'] ? 'red' : null }">
          <td ></td>
          <td *ngFor="let col of columns" [ngClass]="{'text-center': col.type === 'checkbox'}"
          [ngStyle]="{'width': (col.type === 'empty' || col.type === 'button' || col.type === 'icon' )? '8%' : (col.type === 'button2'? '15%' : 'auto'),
          'max-width': (col.type === 'empty' || col.type === 'button' || col.type === 'icon' )? '8%' : (col.type === 'button2'? '15%' : 'auto'),
          'text-align': (col.type === 'button2'|| col.type === 'icon')? 'center' : 'unset',
          'justify-content': (col.type === 'button2'|| col.type === 'icon')? 'center' : 'unset'}">
            <ng-container
            *ngIf="col.type !== 'button' && col.type !== 'image' && col.type !== 'date' && col.type !== 'time' 
            && col.type !== 'datepicker' && col.type !== 'checkbox' && col.type !== 'link' && col.type !== 'boolean' && col.type !== 'number' 
            && col.type !== 'text_editor' && col.field !== 'password' && col.field !== 'role' && col.type !== 'qrcode' && col.type !== 'calculation'">
              <p-cellEditor pTooltip="{{ data[col.field] }}" tooltipPosition="left">
                <ng-template pTemplate="output">
                  <span class="output">{{ data[col.field] }}</span>
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.type === 'date'">
              <p-cellEditor pTooltip="{{ data[col.field] }}" tooltipPosition="left">
                <ng-template pTemplate="output">
                  <span class="p-column-title" *ngIf="data[col.field] !== null && data[col.field] !== 0">
                    {{(data[col.field]['seconds']*1000) | date : 'dd MMM yyyy' }}
                  </span>
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.type === 'time'">
              <p-cellEditor pTooltip="{{ data[col.field] }}" tooltipPosition="left">
                <ng-template pTemplate="output">
                  <span class="p-column-title" *ngIf="data[col.field] !== null && data[col.field] !== 0">
                    {{(data[col.field]['seconds']*1000) | date : 'hh:mm' }}
                  </span>
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.type === 'image'">
              <p-cellEditor>
                <ng-template pTemplate="output">
                  <img *ngIf="data[col.field] !== null && data[col.field] !== ''" height="20" [src]="data[col.field] | gdriveConvert" onerror="this.src = './assets/iconic_logo.png'" (mouseenter)="ppInfo.toggle($event)" (mouseleave)="ppInfo.toggle($event)"/>
                  <p-overlayPanel #ppInfo styleClass="overlay-info" appendTo="body">
                      <ng-template pTemplate>
                          <div class="p-card-shadow p-card p-component">
                              <div class="p-card-body d-flex p-3">
                                <img alt="Image Preview" [src]="data[col.field] | gdriveConvert" class="w-100 border-round-md"/>
                              </div>
                          </div>
                      </ng-template>
                  </p-overlayPanel>
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.type === 'number' || col.type === 'calculation' || col.type === 'autofill_calculation'">
              <p-cellEditor pTooltip="{{ data[col.field] | number}}" tooltipPosition="left">
                <ng-template pTemplate="output">
                  <span class="output">{{ data[col.field] | number}}</span>
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.type === 'checkbox'">
              <p-checkbox [(ngModel)]="data[col.field]" [binary]="true" [disabled]="true"></p-checkbox>
            </ng-container>
            <ng-container *ngIf="col.type === 'qrcode'">
              <p-cellEditor pTooltip="{{ data[col.field] }}" tooltipPosition="left">
                <ng-template pTemplate="output">
                  <span class="output">{{ data[col.field] }}</span>
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.type === 'text_editor'">
              <p-cellEditor pTooltip="{{ data[col.field] }}" tooltipPosition="left">
                <ng-template pTemplate="output">
                  <span class="output">{{ data[col.field] }}</span>
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.type === 'boolean'">
              <p-cellEditor pTooltip="{{ data[col.field] }}" tooltipPosition="left">
                <ng-template pTemplate="output">
                  <p-checkbox [(ngModel)]="data[col.field]" [binary]="true" inputId="binary" [disabled]="true"></p-checkbox>
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.type === 'button'">
              <span class="p-column-title">{{col.header}}</span>
              <ng-container *ngFor="let button of col.field">
                <button pButton class="p-button-rounded p-button-text mr-2 mb-2" [label]="button.Title" [icon]="button.icon || 'pi pi-file-o'" [attr.class]="button.class"
                  (click)="onButtonActionClick(button, data)"></button>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="col.type === 'button2'">
              <ng-container *ngFor="let button of col.field">
                <button pButton class="p-button-primary"
                  (click)="onButtonActionClick2(button, data)">{{button.Title}}</button>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="col.type === 'checkbox'">
              <p-checkbox [(ngModel)]="data[col.field]" [binary]="true" [disabled]="true"></p-checkbox>
            </ng-container>
            <ng-container *ngIf="col.type === 'link'">
              <p-cellEditor pTooltip="{{ data[col.field] }}" tooltipPosition="left">
                <ng-template pTemplate="output">
                  <!-- <a [href]="data[col.field]"><span class="output">{{ data[col.field] }}</span></a> -->
                  <a href="javascript:;" (click)="openTypeDataLink(data[col.field])"><span class="output">{{ data[col.field] }}</span></a>
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.type === 'icon'">
              <img src="/assets/images/icon/icon_g-sheet.svg" height="20px">
            </ng-container>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="columns.length" class="no-data">No data found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-dialog [(visible)]="showImageUploadOpt" [modal]="true" [blockScroll]="true" [draggable]="false" [dismissableMask]="true" appendTo="body" styleClass="dialog-no-footer" [style]="{width: '500px'}">
  <ng-template pTemplate = "header">
      <span class="p-dialog-title"><i class="pi pi-upload"></i>Upload Image API</span>
  </ng-template>
  <div class="grid">
      <div class="col-12 text-center">
          <p>Paste your image URL Link</p>
          <div class="p-inputgroup mt-2">
              <input type="text" class="mr-2 rounded" pInputText placeholder="Your image URL link" [(ngModel)]="valueData"/>
              <button pButton type="button" (click)="updateColumnDialog(dataColumn,typeField,nameField)" label="Save" icon="pi pi-save" iconPos="right" class="p-button p-component rounded"></button>
          </div>
      </div>
      <div class="col">
          <div class="p-divider p-component p-divider-horizontal">
            <div class="p-divider-content">or select your local file</div>
          </div>
      </div>
      <div class="col-12 text-center">
        <!-- <p-fileUpload url="./upload.php" [auto]="true" styleClass="custom-upload"> -->
        <p-fileUpload [auto]="true" styleClass="custom-upload">
          <ng-template pTemplate="toolbar" class="d-none"></ng-template>
          <ng-template pTemplate="file" let-file>
            <p-progressSpinner></p-progressSpinner>
          </ng-template>
          <ng-template pTemplate="content" let-files>
            <div class="grid p-5" fileDragDrop (filesChangeEmiter)="onFileChange($event, dataColumn,typeField,nameField,gridModel.GridName)">
              <div class="col-12 text-center">
                <i class="pi pi-upload text-7xl mb-2"></i>
                <p class="mb-4">Drag & drop your file here or</p>
                <!-- <p-fileUpload #fubauto mode="basic" name="demo[]" url="./upload.php" accept="image/*" [auto]="true" chooseLabel="Browse File"></p-fileUpload> -->
                <p-fileUpload #fubauto  mode="basic" (onSelect)="onSelectImage($event, dataColumn,fubauto,typeField,nameField,gridModel.GridName);" accept="image/*" [auto]="true" chooseLabel="Browse File"></p-fileUpload>
                <p class="mt-2 text-sm"><i>(max. size {{max_upload/1000}}KB)</i></p>
              </div>
            </div>
          </ng-template>
        </p-fileUpload>
      </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="showDateTimeType" [modal]="true" [blockScroll]="true" [draggable]="false" [dismissableMask]="true" appendTo="body" styleClass="dialog-no-footer" [style]="{width: '550px'}">
  <ng-template pTemplate = "header">
    <!-- <span class="p-dialog-title capitalize"><i class="pi" [ngClass]="typeField === 'date' ? 'pi-calendar' : 'pi-clock'"></i>Change {{typeField}}</span> -->
    <span *ngIf="typeField === 'date'" class="p-dialog-title capitalize"><i class="pi pi-calendar"></i>Change {{typeField}}</span>
    <span *ngIf="typeField === 'time'" class="p-dialog-title capitalize"><i class="pi pi-clock"></i>Change {{typeField}}</span>
    <span *ngIf="typeField === 'text_editor'" class="p-dialog-title capitalize"><i class="pi pi-list"></i>{{'change_text_editor' | translate}}</span>
    <span *ngIf="nameField === 'password'" class="p-dialog-title capitalize"><i class="pi pi-lock"></i>Change {{nameField}}</span>
    <span *ngIf="nameField === 'role'" class="p-dialog-title capitalize"><i class="pi pi-lock"></i>Change {{nameField}}</span>
    <span *ngIf="typeField === 'qrcode'" class="p-dialog-title capitalize"><i class="pi pi-qrcode"></i>Change QR Code</span>
  </ng-template>
  <div class="grid">
      <div class="col-12 text-left">
          <!-- <p class="capitalize" *ngIf="nameField !== 'password'">{{typeField}}</p>
          <p class="capitalize" *ngIf="nameField === 'password'">{{nameField}}</p> -->
          <p class="capitalize">{{nameField}}</p>
          <div class="p-inputgroup mt-2">
              <p-calendar *ngIf="typeField === 'date'" [(ngModel)]="valueData" inputId="basic" appendTo="body" [monthNavigator]="true" [yearNavigator]="true" yearRange="1945:2040"></p-calendar>
              <p-calendar *ngIf="typeField === 'time'" [(ngModel)]="valueData" inputId="basic" appendTo="body" [timeOnly]="true" [hourFormat]="'24'" [showTime]="true"></p-calendar>  
              <p-editor *ngIf="typeField === 'text_editor'" [(ngModel)]="valueData" [style]="{ height: '320px' }"></p-editor>
              <input *ngIf="typeField === 'string' && nameField === 'password'" type="text" class="mr-2 rounded" pInputText placeholder="Change password" [(ngModel)]="valueData"/>
              <input *ngIf="typeField === 'qrcode'" type="text" class="mr-2 rounded" pInputText placeholder="Change QR Code" [(ngModel)]="valueData"/>
              <!-- <button pButton type="button" (click)="updateColumnDialog(dataColumn,typeField,nameField)" label="Update" icon="pi pi-save" iconPos="right" class="rounded ml-2"></button> -->
          </div>
          <span *ngIf="typeField === 'string' && nameField === 'password'" class="flex align-items-center justify-content-between pr-5 text-blue">* Minimum 6 characters contain uppercase, lowercase letter, and numeric</span> 
          <p-dropdown styleClass="mb-3" *ngIf="(typeField === 'text' || typeField === 'string') && nameField === 'role'" [options]="optionsRole" [(ngModel)]="valueData" 
            placeholder="Select" optionLabel="value" optionValue="value" appendTo="body">
          </p-dropdown>
          <div class="m-auto w-100" *ngIf="typeField === 'qrcode' && valueData !== ''">
            <br />
            <!-- <p class="text-sm text-center">Scan QR Code</p> -->
            <div class="card box inline-flex p-2">
              <qrcode #parentQrCode [qrdata]="valueData" [elementType]="'img'" [allowEmptyString]="true"></qrcode>
            </div>
            <div class="p-inputgroup">
              <button pButton type="button" (click)="saveAsImage(parentQrCode)" label="Download" icon="pi pi-cloud-download" iconPos="right" class="rounded"></button>
            </div>
          </div>
          <div class="p-inputgroup mt-2">
            <button pButton type="button" (click)="updateColumnDialog(dataColumn,typeField,nameField)" label="{{'update' | translate}}" icon="pi pi-save" iconPos="right" class="rounded ml-auto"></button>
          </div>
      </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="showSettingColumn" [modal]="true" [blockScroll]="true" [draggable]="false" [dismissableMask]="true" appendTo="body" styleClass="dialog-no-footer" [style]="{width: '400px'}">
  <ng-template pTemplate = "header">
    <span class="p-dialog-title capitalize"><i class="pi pi-list"></i>Show Or Hide Column</span>
  </ng-template>
  <div class="grid">
      <div class="col-12 text-left">
          <p class="capitalize">Columns</p>
          <div *ngFor="let checkbox of columnForSetting" class="field-checkbox">
            <p-checkbox [(ngModel)]="columnShow" [value]="checkbox" value="checkbox" (ngModelChange)="changeColumns(checkbox)" [inputId]="checkbox.field"></p-checkbox>
            <label for="checkbox" class="capitalize">{{checkbox.header}}</label>
          </div>
          <div class="p-inputgroup mt-2">
            <button pButton type="button" (click)="saveSettingColumns()" label="Save" icon="pi pi-save" iconPos="right" class="rounded ml-auto"></button>
          </div>
      </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="showImportTable" [modal]="true" [blockScroll]="true" [draggable]="false" [dismissableMask]="true" appendTo="body" styleClass="dialog-no-footer" [style]="{width: '550px'}">
  <ng-template pTemplate = "header">
    <span class="p-dialog-title capitalize"><i class="pi pi-table"></i>{{'editor.grid.import' | translate}}</span>
  </ng-template>
  <div class="grid">
      <div class="col-12 center">
          <span class="text-xl pt-4">{{'editor.grid.popup.import.title' | translate}}</span>
      </div>
      <div class="col-12">
          <p-messages severity="info" styleClass="message-flat">
            <ng-template pTemplate class="rounded border-0">
                <div>{{'editor.grid.popup.import.description' | translate}}</div>
            </ng-template>
          </p-messages>
      </div>
  </div>
  <ng-template pTemplate="footer">
    <button pButton type="button" (click)="showImportTable = false" class="p-button p-button-secondary" label="{{'project.popup.cancel' | translate}}"></button>
    <p-button (onClick)="importTable()" label="{{'editor.grid.import' | translate}}" icon="pi pi-chevron-right" iconPos="right"></p-button>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="showExportTable" [modal]="true" [blockScroll]="true" [draggable]="false" [dismissableMask]="true" appendTo="body" styleClass="dialog-no-footer" [style]="{width: '550px'}">
  <ng-template pTemplate = "header">
    <span class="p-dialog-title capitalize"><i class="pi pi-table"></i>{{'editor.grid.import' | translate}}</span>
  </ng-template>
  <div class="grid">
      <div class="col-12 center">
          <span class="text-xl pt-4">{{'editor.grid.popup.export.title' | translate}}</span>
      </div>
      <div class="col-12">
          <p-messages severity="info" styleClass="message-flat">
            <ng-template pTemplate class="rounded border-0">
                <div>{{'editor.grid.popup.export.description' | translate}}</div>
            </ng-template>
          </p-messages>
      </div>
  </div>
  <ng-template pTemplate="footer">
    <button pButton type="button" (click)="showExportTable = false" class="p-button p-button-secondary" label="{{'project.popup.cancel' | translate}}"></button>
    <p-button type="button" (onClick)="exportTable()" label="{{'editor.grid.export' | translate}}" icon="pi pi-chevron-right" iconPos="right"></p-button>
  </ng-template>
</p-dialog>