import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from '@core-fibr/shared';
import * as Leaflet from 'leaflet';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ui-fibr-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  options: Leaflet.MapOptions = {};
  geoReverseService = 'https://nominatim.openstreetmap.org/reverse?key=iTzWSiYpGxDvhATNtSrqx5gDcnMOkntL&format=json&addressdetails=1&lat={lat}&lon={lon}';
  reverseGeoSub: Subscription | undefined;
  addressDetail:any;
  fromAccess:any;
  constructor(private globalService: GlobalService,private httpClient: HttpClient) { 
    this.initMap()
  }

  ngOnInit(): void {
    // 
  }

  initMap () {
    this.globalService.mapLocation.subscribe((position: any) => {
      this.fromAccess = position
      this.options = {
        layers: getLayers(position),
        zoom: 14,
        center: new Leaflet.LatLng(position.latitude, position.longitude)
      };
      const service = (this.geoReverseService || '')
      .replace(new RegExp('{lon}', 'ig'), `${position.longitude}`)
      .replace(new RegExp('{lat}', 'ig'), `${position.latitude}`)
      this.reverseGeoSub = this.httpClient.get(service).subscribe(data => {
        this.addressDetail = data;
      })
    });
  }

}

export const getMarkers = (position:any): Leaflet.Marker[] => {
  return [
    // new Leaflet.Marker(new Leaflet.LatLng(43.5121264, 16.4700729), {
    new Leaflet.Marker(new Leaflet.LatLng(position.latitude, position.longitude), {
      icon: new Leaflet.Icon({
        iconSize: [50, 41],
        iconAnchor: [13, 41],
        iconUrl: '/assets/images/icon/icon_map-marker.svg',
      }),
      title: 'Location'
    } as Leaflet.MarkerOptions),
  ] as Leaflet.Marker[];
};

export const getLayers = (position:any): Leaflet.Layer[] => {
  return [
    new Leaflet.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenStreetMap'
    } as Leaflet.TileLayerOptions),

    ...getMarkers(position),
  ] as Leaflet.Layer[];
};
