/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateHelperService {

  getConvertDate(isoStringDate: any) {
    if (isoStringDate != null) {
      const d = new Date(isoStringDate);
      const year = d.getFullYear();
      const month = d.getMonth() + 1;
      const day = d.getDate();
      let dates1 = '';
      let month1 = '';

      if (day < 10) {
        dates1 = '0' + day;
      } else {
        dates1 = '' + day;
      }
      if (month < 10) {
        month1 = '0' + month;
      } else {
        month1 = '' + month;
      }

      const dateNow = year + '-' + month1 + '-' + dates1;
      return dateNow;
    }
    else {
      return null;
    }
  }

  parseDateFromTimestamp(date:any){
    let value;
    if(typeof date == 'string' ){
      value = new Date(date);
    }else{
      if(date == null) return null
      if(typeof date.seconds == 'number'){
        const dateInMillis  = date.seconds * 1000;
        value = new Date(dateInMillis);
      }else{
        value = new Date(date);
      }
    }
    return value;
  }


}
