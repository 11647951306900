<div *ngIf="actFrom === 'pwa'" class="splash-screen" [ngStyle]="{'background-image': 'url(' + appConfig.splash_screen + ')'}">
    <img class="img-app" [src]="logo" alt="AppFibr Logo"/>
    <div class="grid justify-content-center" *ngIf="!isPaid">
        <div class="col-auto splash-powered">
            <p>Powered by</p>
            <a href="https://appfibr.io/"><img src="/assets/logo_appfibr.svg" alt="AppFibr Logo"/></a>
        </div>
    </div>
</div>

<div *ngIf="!isLogin || (isLogin && login_user && is_activeEmail)">
<ui-fibr-mobile-header
    [showPageDetails]="showPageDetails" [data]="e" [actFrom]="actFrom" [isPaid]="isPaid" [params]="params" [isLogin]="isLogin"
    [config]="config" [fields]="fields" [showForm]="showForm"
    (buttonOnclick)="buttonCloseDetail()" (buttonSearchClick)="buttonSearchClick($event)"
    (buttonSortClick)="buttonSortClick($event)" (logoutOnclick)="buttonLogoutClick()"
    (buttonChangePasswordClick)="changePassword($event)"
>     
</ui-fibr-mobile-header>
<ui-fibr-mobile-list [config]="config" [data]="data"
    *ngIf="!showForm && !showPageDetails && config.layoutDesign?.layoutTemplate === 'list'" [column]="fields" [dataColumn]="dataColumn"  
    (buttonFloatOnclick)="buttonFloatOnclick($event)" (buttonDetailOnclick)="buttonDetailOnclick($event)" (scrollLazyLoadAct)="scrollLazyLoadAct($event)">
</ui-fibr-mobile-list>
<ui-fibr-mobile-grid [config]="config" [data]="data"
    *ngIf="!showForm && !showPageDetails && config.layoutDesign?.layoutTemplate === 'grid'" [column]="fields" [dataColumn]="dataColumn"
    (buttonFloatOnclick)="buttonFloatOnclick($event)" (buttonDetailOnclick)="buttonDetailOnclick($event)">
</ui-fibr-mobile-grid>
<ui-fibr-mobile-cart [config]="config" [data]="data" [params]="params" [actFrom]="actFrom" [itemSelected]="itemSelected"
  *ngIf="!showForm && !showPageDetails && config.layoutDesign?.layoutTemplate === 'cart'" [column]="fields" [dataColumn]="dataColumn"
  (buttonFloatOnclick)="buttonFloatOnclick($event)" (buttonDetailOnclick)="buttonDetailOnclick($event)" (buttonSearchClick)="buttonSearchClick($event)">
</ui-fibr-mobile-cart>
<ui-fibr-form [config]="config" [data]="data" [actEdit]="actEdit" [actFrom]="actFrom" [dataDetail]="dataDetail" [params]="params"
    *ngIf="showForm" [isTemplate]="isTemplate" [isLogin]="isLogin" [isPaid]="isPaid" (buttonFloatOnclick)="buttonFloatOnclick($event)"
    (buttonSearchClick)="buttonSearchClick($event)" [itemSelected]="itemSelected">
</ui-fibr-form>
<ui-fibr-mobile-summary *ngIf="!showForm && !showPageDetails && config.layoutDesign?.layoutTemplate === 'summary'" 
  [config]="config" [data]="data" [dataSummary]="dataSummary" [column]="fields" [dataColumn]="dataColumn" [params]="params" [isTemplate]="isTemplate">
</ui-fibr-mobile-summary>
<ui-fibr-page-details [config]="config" [data]="e" [actFrom]="actFrom" [params]="params" [isTemplate]="isTemplate" [itemPages]="itemPages"
    *ngIf="showPageDetails || config.layoutDesign?.layoutTemplate === 'details'" (buttonOnclick)="buttonCloseDetail()" 
    (buttonFloatClick)="onForFormDetail($event)" (buttonTypeButtonClick)="onFromPageDetailButton($event)" (buttonRedirect)="onClick($event)"
    (buttonRedirectWithParam)="onClickWithParams($event)" [itemSelected]="itemSelected">
</ui-fibr-page-details>
<ui-fibr-mobile-bottommenu class="grid mobile-bottommenu"
    [config]="config" [itemPages]="itemPages" [actFrom]="actFrom" [isTemplate]="isTemplate" [appConfig]="appConfig"
    [formConfiguration]="config?.formConfiguration!" (menuClick)="onClick($event)">
</ui-fibr-mobile-bottommenu>
</div>

<div *ngIf="isLogin && !login_user && !is_register && !is_resetPassword">
    <!-- <ui-fibr-login-form (loginClick)="buttonLoginClick($event)" (registerClick)="buttonToRegisterClick($event)"></ui-fibr-login-form> -->
    <ui-fibr-login-form (loginClick)="cekUserProject($event)" (registerClick)="buttonToRegisterClick($event)"
    (resetPasswordClick)="buttonToResetPasswordClick($event)" (loginClickGuest)="cekUserProject($event)"></ui-fibr-login-form>
</div>

<div *ngIf="isLogin && !login_user && is_register">
    <!-- <ui-fibr-register-form (registerClick)="buttonRegisterClick($event)" (loginClick)="buttonToLoginClick($event)"></ui-fibr-register-form> -->
    <ui-fibr-register-form (registerClick)="buttonRegisterWithApiClick($event)" (loginClick)="buttonToLoginClick($event)"
    [params]="params"></ui-fibr-register-form>
</div>

<div *ngIf="isLogin && !login_user && is_resetPassword">
  <ui-fibr-reset-password (registerClick)="buttonRegisterWithApiClick($event)" (loginClick)="buttonToLoginClick($event)"
  [params]="params"></ui-fibr-reset-password>
</div>

<div *ngIf="isLogin && login_user && !is_activeEmail">
  <ui-fibr-verifi-email (activeEmailClick)="buttonToActiveEmailClick($event)" (loginClick)="buttonLogoutClick()"
  [params]="params"></ui-fibr-verifi-email>
</div>