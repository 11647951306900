import { Component, OnInit } from '@angular/core';
import { appConfig, GlobalService, GridModel, ToastNotificationService } from '@core-fibr/shared';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'ui-fibr-createnew-option',
  templateUrl: './createnew-option.component.html',
  styleUrls: ['./createnew-option.component.scss']
})
export class CreatenewOptionComponent implements OnInit {
  // gridModel: GridModel = new GridModel();
  // fields: any = {
  //   tableName: ''
  // };

  // showInputTableName = false;
  // isGoogleFiles = false;
  // user: any;
  // appConfig: appConfig = {};

  constructor( 
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
    private globalService: GlobalService,
    ) { }

  ngOnInit(): void {
    // this.user = this.globalService.userProfile;
    // this.isGoogleFiles = this.config.data?.isGoogleFiles? this.config.data?.isGoogleFiles : false;
    // this.gridModel.APIEndpoint = this.config.data?.url ?? '';
    // this.globalService.appConfig.subscribe((res: appConfig) => {
    //   this.appConfig = res;
    // });
  }

  cancelDialog(){
    this.ref.close({action : 'cancel'});
  }
  
  blankTable(){
    this.ref.close({action : 'newTable'});
  }

  googleSheets(): void{
    this.ref.close({action: 'googleSheets'});
  }

}
