import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  GlobalService,
  GlobalcrudService,
  LoaderService,
  SessionService,
  ToastNotificationService,
} from "@core-fibr/shared";
import { TranslateService } from "@ngx-translate/core";
import { environment, AuthenticationService } from "@core-fibr/auth";
import { NgForm } from "@angular/forms";

@Component({
  selector: 'ui-fibr-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  public env = environment;
  @Output() loginClick = new EventEmitter<any>();
  @Output() registerClick = new EventEmitter<any>();
  @Output() resetPasswordClick = new EventEmitter<any>();
  @Output() loginClickGuest = new EventEmitter<any>();

  userName: string | undefined;
  password: string | undefined;
  elementForm: any[]=[];
  is_activeEmail = false; /**ini nanti diambilkan dari localstorage user_project */
  appConfig:any;
  logo = '';
  is_register = false;
  fieldTextType = false;

  constructor(
    private router: Router,
    public translate: TranslateService,
    public sessionService: SessionService,
    public loaderService: LoaderService,
    public authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private globalCrudService: GlobalcrudService,
    // private captchaService: CaptchaService,
    private notif: ToastNotificationService,
    private authService: AuthenticationService
  ) {
    
  }

  ngOnInit() {
    this.elementForm = [
      {field: 'username', label: 'Email', value: '', required: true},
      {field: 'password', label: 'Password', value: '', required: true}
    ];
    this.globalService.appConfig.subscribe((val: any) => this.appConfig = val);
    if (this.appConfig.is_paid && this.appConfig.app_logo != '') this.logo = this.appConfig.app_logo; else this.logo = '/assets/logo_appfibr_vertical.svg';
    if (this.appConfig?.is_registration == true || this.appConfig?.is_registration == undefined) this.is_register = true; else this.is_register = false; 
  }

  onClickLogin(f: NgForm) {
    // console.log(f.value)
    this.loginClick.emit(f.value);
  }

  onClickLoginGuest() {
    this.loginClickGuest.emit({isLoginGuest: true});
  }

  onClickRegister() {
    // console.log('kesini')
    this.registerClick.emit(true);
  }

  onClickResetPassword() {
    this.resetPasswordClick.emit(true);
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

}
