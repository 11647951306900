import { Injectable } from '@angular/core';
import {DialogService} from 'primeng/dynamicdialog';
import {DynamicDialogRef} from 'primeng/dynamicdialog';

@Injectable({
  providedIn: 'root'
})
export class DynamicdialogService {

  constructor(public dialogService: DialogService, ) { }
  ref: DynamicDialogRef | undefined;

  show( template : any, config : any ){
    // console.log(this.ref)
    // console.log(template)
    // console.log(config)
    return this.dialogService.open(template, config);
  }
}
