import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linkcolumn'
})
export class LinkcolumnPipe implements PipeTransform {

  transform(value: string, altString: string): string {
    return value == null ? altString : value;
  }

}
