/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService, SessionService } from '@core-fibr/shared';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'libs/auth/src/environments/environment';
import { GlobalService } from 'libs/shared/src/lib/services/global.service';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Lang } from '../../models/user-info';
import { AuthenticationService } from '../../services/authentication/authentication.service';

@Component({
  selector: 'ui-fibr-add-faskes',
  templateUrl: './add-faskes.component.html',
  styleUrls: ['./add-faskes.component.scss']
})
export class AddFaskesComponent implements OnInit {
  userName: string | undefined;
  password: string | undefined;
  fullName: string | undefined;
  confirmPassword: string | undefined;
  locale: string | undefined;
  version: string | undefined;
  returnUrl='';

  isProgressSpinner= false;
  selectedValues= false;
  msgs: any;
  country: any;

  logo = environment.logo;
  image_login = environment.image_login;
  registerNameLabel = environment.registerNameLabel_en;
  nextStage = environment.nextStage;

  selectedLanguage: Lang | undefined;
  faskes = [{name: ''}]
  private unsubscribe: Subject<any> | undefined;

  constructor(
    private router: Router,
    public translate: TranslateService,
    public sessionService:  SessionService,
    public loaderService: LoaderService,
    public authenticationService: AuthenticationService,
    private cdr : ChangeDetectorRef,
    private route: ActivatedRoute,
    private globalService: GlobalService
  ) { 
    this.unsubscribe = new Subject();
    this.route.queryParams.subscribe((params) => {
      this.userName = params.email;
      this.fullName = params.fullName;
      this.password = params.password;
      this.returnUrl = params.returnUrl || "/portal";
    });
  }

  ngOnInit(): void {
    this.country = [
      {name: 'English', code: 'en'},
      {name: 'Indonesia', code: 'id'},
    ];
    this.locale = this.sessionService.getItem("fibr-language") || 'en';
    this.registerNameLabel = this.locale == 'en'? environment['registerNameLabel_en']: environment['registerNameLabel_id'];
    this.selectedLanguage = this.country.filter((x : any) => x.code == this.locale)[0];
    this.version = environment.version;
    this.msgs = [{ severity: 'info', detail: 'UserName: admin' }, { severity: 'info', detail: 'Password: password' }];
  }

  onClickRegisterUseFireEmailandPassword() {
    if(this.nextStage.isNextPage) {
        this.router.navigate([this.nextStage.url], { queryParams : { 
        email: this.userName,
        password: this.password,
        fullName: this.fullName
      } });
      return;
    }
    this.isProgressSpinner = true;
    this.authenticationService
      .registerFireEmailandPassword({email: this.userName, password: this.password, fullName: this.fullName})
      .then((result) => {
            if(result){
              this.router.navigateByUrl('auth/login');
            }
            this.isProgressSpinner = false;
          }
        ),
        takeUntil((this.unsubscribe as Observable<any>)),
        finalize(() => {
          this.isProgressSpinner = false;
          this.cdr.markForCheck();
        })
  }

  onClickGoogle() {

  }

  onLanguageChange() {
    this.locale = this.selectedLanguage?.code;
    if (this.locale == undefined || this.locale == null || this.locale.length == 0) {
      this.locale = "en";
    }
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(this.locale);
    this.sessionService.setItem("fibr-language", this.locale);
    this.registerNameLabel = this.locale == 'en'? environment['registerNameLabel_en']: environment['registerNameLabel_id'];
  }

  removeFaskes(i:number){
    this.faskes.splice(i,1); 
  }

  addNewFaskes(){
    this.faskes.push({ name: ''});  
  }

}
