/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-fibr-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit {
  @Input() srcImg: string = '/assets/logo_appfibr.svg';
  constructor() { console.log();}

  ngOnInit(): void {
    console.log();
  }

}
