<div class="login-body layout-light">
    <div class="login-content">
        <div class="night-switch">
            <div class="language-menu">
                <p-dropdown (onChange)="onLanguageChange()" [options]="country" [(ngModel)]="selectedLanguage" placeholder="{{'selectLanguage' | translate}}" optionLabel="name" [showClear]="false"></p-dropdown>
            </div>
        </div>
        <div class="login-panel">
            <div class="login-panel-content text-left">
              <div class="col login-header">
                <div class="logo"><img [src]="logo" alt="fibr-logo" /></div>
                  <h4 class="mb-2">{{'Fibr-portalRegister' | translate}}</h4>
                  <span>{{'WelcomeMessageRegistration' | translate}}</span>
                </div>

                <div class="col" *ngIf="env.app_name === 'fibr'">
                  <div id="my-signin2">
                    <div class="abcRioButton" (click)="onClickGoogle()">
                      <div class="abcRioButtonContentWrapper">
                        <div class="abcRioButtonIcon">
                          <div style="width:18px;height:18px;" class="abcRioButtonSvgImageWithFallback abcRioButtonIconImage abcRioButtonIconImage18">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" class="abcRioButtonSvg">
                              <g>
                                <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                        <span class="abcRioButtonContents">
                          <span id="not_signed_inqxa7b2xmul7l" style="font-family: 'Roboto', sans-serif;">{{'googleRegister' | translate}}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col"  *ngIf="env.app_name === 'fibr'">
                  <div class="p-divider p-component p-divider-horizontal">
                    <div class="p-divider-content bg-softgrey">{{'or' | translate}}</div>
                  </div>
                </div>

                <div class="col">
                    <div class="forms mt-0">
                        <div class="login-input-wrapper mb-3"><label for="fullname">{{'fullname' | translate}}</label><input type="text" [(ngModel)]="fullName" id="fullname" placeholder="{{'placeholderFullName' | translate}}" class="p-inputtext p-component line-input py-3" /></div>
                        <div class="login-input-wrapper mb-3"><label for="username">Email</label><input id="username" pinputtext="" [(ngModel)]="userName" placeholder="{{'placeholderEmail' | translate}}" class="p-inputtext p-component line-input py-3" /></div>
                        <div class="login-input-wrapper mb-3"><label for="password">{{ 'password' | translate }}</label><input type="password" [(ngModel)]="password" id="password" placeholder="{{'placeholderPassword' | translate}}" class="p-inputtext p-component line-input py-3" /></div>
                        <div class="login-input-wrapper mb-3"><label for="confirmpassword">{{'confirmpassword' | translate}}</label><input type="password" [(ngModel)]="confirmPassword" id="confirmpassword" placeholder="{{'placeholderConfirmpassword' | translate}}" class="p-inputtext p-component line-input py-3" /></div>
                        <ui-fibr-captcha [config]="captchaConfig"></ui-fibr-captcha>
                        <div class="col-12">
                            <p-checkbox name="groupname" [binary]="true" [(ngModel)]="selectedValues"></p-checkbox>      
                            <label class="p-checkbox-label"> {{'checkboxRegister' | translate}} <a [ngStyle]="{color:'var(--primary-color)'}" href="https://appfibr.io/syarat-ketentuan/" target="_blank">{{'termofservice' | translate}}</a> {{'and' | translate}} <a [ngStyle]="{color:'var(--primary-color)'}" href="https://appfibr.io/privacy-policy/" target="_blank">{{'privacypolicy' | translate}}</a></label>               
                        </div>
                        <div class="buttons">
                            <button id="registermail-appfibr" pbutton="" [disabled]="isProgressSpinner" type="button" label="Login" tabindex="0" class="col-12 py-3 p-button text-lg" (click)="isAdditional? onClickRegisterUseFireEmailandPasswordAdditional() : onClickRegisterUseFireEmailandPassword()"
                            [ngStyle]="{ 'background-color': primaryButtonColor}">
                                <span class="p-button-label">{{'Register' | translate}}</span>
                                <p-progressSpinner *ngIf="isProgressSpinner" strokeWidth="3" styleClass="progress-spinner-btn" [style]="{width: '20px', height: '20px'}"></p-progressSpinner>
                            </button>
                        </div>
                        <div class="col-12 center">
                            <a href="javascript:;" [ngStyle]="{ 'color': primaryButtonColor}" [ngClass]="{'disabled': isProgressSpinner}" [routerLink]="['/auth/login']" pbutton="" type="button" label="Register" tabindex="0" class="p-button-text mt-4"><span class="p-button-label">{{'backtologin' | translate}}</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="login-panel right " 
        [ngClass]="{'dentago-banner' : env.app_name === 'dentago', 'geenoms-banner' : env.app_name === 'geenoms'}"
          [ngStyle]="{
            'background-image': 'url(' + image_register + ')',
            'background-size': 'cover',
            'background-position': 'center',
            'background-repeat': 'no-repeat'}"
            >
          <div *ngIf="env.app_name != 'fibr'" class="login-panel-content">
            <!-- <div class="logo">
              <img [src]="logoRight" alt="{{labelAlt}}" />
            </div> -->
            <h4 class="mb-2 text-shadow">{{env.login_right.h4_register | translate}}</h4>
            <p class="text-shadow">{{env.login_right.p | translate}}</p>

            <div class="mt-auto">
              <span class="text-shadow">Copyright © 2023 <b *ngIf="['dentago', 'geenoms'].includes(env.app_name)" [ngStyle]="{ 'color': primaryButtonColor, 'font-size': '1.5rem'}">{{env.app_name}}&#8482;</b>
                <img *ngIf="env.app_name === 'fibr'" src="/assets/logo_fibr_footer.svg" height="14">
                <img *ngIf="env.app_name === 'sifa'" src="/assets/logo_fibr_footer.svg" height="14">
              </span>
            </div>
          </div>

          <div class="dentago-banner-logo" *ngIf="['dentago', 'geenoms'].includes(env.app_name)">
            <img class="dentago-banner-logo-img" [src]="image_login" alt="{{labelAlt}}" />
          </div>
        </div>
    </div>
</div>

<ui-fibr-blockui-login [blocked]="isBlocked"></ui-fibr-blockui-login>
