import { Component, Input, OnInit } from '@angular/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DynamicdialogService, GlobalcrudService, NotifProComponent } from '@core-fibr/shared';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { appConfig } from '../../../interfaces/global';
import { GlobalService } from '../../../services/global.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ui-fibr-middle-action-button',
  templateUrl: './middle-action-button.component.html',
  styleUrls: ['./middle-action-button.component.scss']
})
export class MiddleActionButtonComponent implements OnInit {
  @Input() appConfig: appConfig = {};
  showUpgradeToPro = false;
  showOrderSummary = false;
  showInvoice = false;
  createNewConfig: DynamicDialogConfig = {};
  user: any = {};
  notifExpireInvoice = '';
  statusProject = '';
  
  constructor(
    private globalService: GlobalService,
    private dynamicDialogService: DynamicdialogService,
    private globalServiceCrud: GlobalcrudService,
    private translate: TranslateService,
  ) 
  { }

  ngOnInit(): void {
    this.user = this.globalService.getCurrReffDataParsed("user_profile");
    this.globalService.appConfig.subscribe((val: any) => { 
      setTimeout(() => {
        if(val.status_project == 'business') {
          this.statusProject = 'business'
        } else {
          if(val.is_paid) this.statusProject = 'Pro'; else this.statusProject = 'Basic';
        }
        const pathInvoice = `/users/${this.user.uid}/projects_invoice`;
        this.globalServiceCrud.getcollectionwithid(pathInvoice, val.app_id)
        .subscribe((res: any) => {
          const dateNow : any = new Date()

          if (res?.expire_app) {
            const diff: number = (res?.expire_app.toDate() - dateNow.getTime()) / (1000 * 3600 * 24)
            this.notifExpireInvoice = 'Pro plan '+Math.round(diff)+' days remaining'
          } else {
            this.notifExpireInvoice = '';
          }

        });
      }, 1000);
      
    })
  }

  onBlur(){
    this.globalService.setAppConfig(this.appConfig);
  }

  updatePro(act: any) {
    const e = this.appConfig
    this.createNewConfig = {
      // header: "Upgrade Project Plan",
      header: act == "upgrade" ? this.translate.instant('table.upgrade.project_plan') : this.translate.instant('table.upgrade.extend_pro_plan'),
      width: "1000px",
      contentStyle: { overflow: "auto" },
      styleClass: "dialog-no-footer create-new",
      dismissableMask: true,
      baseZIndex: 10000,
      showHeader: true,
      closable: true,
      data: {e, act}
    };
    this.dynamicDialogService.show(NotifProComponent, this.createNewConfig)
  }

}
