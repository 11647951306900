/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { FilterType } from "./types";
import { QueryParamsHandling } from '@angular/router';
import { MenuItem } from "primeng/api";

export class Global {
}

export class Filter {
    FilterProperty?: string;
    FilterDataType?: FilterType;
    FilterStringValue?: string;
    FilterIDLookupValue?: number;
    FilterNumberFromValue?: number;
    FilterNumberToValue?: number;
    FilterDateFromValue?: Date;
    FilterDateToValue?: Date;
    FilterBoolValue?: boolean;
  }

export class GridModel {
    PageSize: number;
    TotalElements: number;
    TotalPages: number;
    CurrentPageNumber: number;
    SortBy: string;
    SortDir: string;
    Data: Array<any>;
    GridHeader: string[];
    DataToExport: Array<any>;
    GridName?: string;
    IsExport: boolean;
    IsImport: boolean;
    IsPDF: boolean;
    Filters: Array<Filter>;
    APIEndpoint?: string='';
    AggregateIdentifier?: string;
    RowsPerPageOptions: Array<number> = [];
    IdColumn?: string;
    GridId?: any = {};
    methodEndpoint: string = 'post';
    firebase: any;
    isPaginator: boolean;
    isEditor: boolean;
    isAddBlankRow: boolean;
    isAddNewData: boolean;
    isAddNewColumn : boolean;
    isShowFilter: boolean;
    isUpload:boolean;
    isAddtionalFirebase:boolean;
    isShowCheckbox:boolean;
    isDelete: boolean;

    constructor(
      defaultSortBy: string = 'id',
      defaultSortDir: string = 'asc',
      // defaultSortBy: string = 'created_date',
      // defaultSortDir: string = 'desc',
      pageSize: number = 10,
      defaultFirebase: any = {
        path: '',
        docid: ''
      }
    ) {
      this.TotalElements = 0;
      this.TotalPages = 0;
      this.CurrentPageNumber = 0;
      this.Data = [];
      this.GridHeader = [];
      this.DataToExport = [];
      this.IsExport = false;
      this.IsImport = false;
      this.Filters = new Array<Filter>();
      this.IsPDF = false;
      this.SortBy = defaultSortBy;
      this.SortDir = defaultSortDir;
      this.PageSize = pageSize;
      this.firebase = defaultFirebase;
      this.isPaginator = true;
      this.isEditor = false;
      this.isAddBlankRow = true;
      this.isAddNewData = false;
      this.isAddNewColumn = true;
      this.isShowFilter = false;
      this.isUpload = false;
      this.isAddtionalFirebase = false;
      this.isShowCheckbox = false;
      this.isDelete = true;
    }
  }

  export class EventEmitModel {
    event: any;
    id?: string | number;
    data?: any;
    navigationLink?: string;
    type?: string;
  }
  
  export class LinkObjectModel {
    ActionName?: string;
    NavigationParam?: Array<string>;
  }

  export class GridColumnModel {
    field: string | any;
    header?: string;
    sortable?: boolean;
    type?: string;
    filterable?: boolean;
    additional?: boolean;
    parameter?: boolean;
    linkObject?: LinkObjectModel;
    localizationLookup?: boolean;
    refDataLookupName?: string;
  }

  export interface customMenuItem {
    isCustom : boolean;
    label?: string;
    icon?: string;
    command?: (event?: any) => void;
    url?: string;
    items?: MenuItem[];
    expanded?: boolean;
    disabled?: boolean;
    visible?: boolean;
    target?: string;
    escape?: boolean;
    routerLinkActiveOptions?: any;
    separator?: boolean;
    badge?: string;
    tooltip?: string;
    tooltipPosition?: string;
    badgeStyleClass?: string;
    style?: any;
    styleClass?: string;
    title?: string;
    id?: string;
    automationId?: any;
    tabindex?: string;
    routerLink?: any;
    queryParams?: {
        [k: string]: any;
    };
    fragment?: string;
    queryParamsHandling?: QueryParamsHandling;
    preserveFragment?: boolean;
    skipLocationChange?: boolean;
    replaceUrl?: boolean;
    state?: {
        [k: string]: any;
    };
    tooltipOptions?: {
        tooltipLabel?: string;
        tooltipPosition?: string;
        tooltipEvent?: string;
        appendTo?: any;
        positionStyle?: string;
        tooltipStyleClass?: string;
        tooltipZIndex?: string;
        escape?: boolean;
        disabled?: boolean;
        positionTop?: number;
        positionLeft?: number;
        showDelay?: number;
        hideDelay?: number;
        life?: number;
    };
}

export interface SelectionModel {
  value?: any;
  label?: string;
  name?: string;
  code?: string;
  date?: string;
  url?: string;
  type?: string;
  field?: string;
  fieldType?: string;
  validations?: string;
  data_type?: string;
  source?: string;
  values?: any;
  checkbox_max?: any;
  extension?: any;
  content?: any;
  mandatoryLabel?: boolean;
  mandatoryType?: boolean;
  mandatoryField?: boolean;
  mandatoryTypeUpload?: boolean;
  mandatoryOptionType?: boolean;
  buttonProperty?: any;
  isExternalSource?: boolean;
  sourceColumn?: any;
  configHiddenField?: any;
  qrConfig?: any;
  relation?: any;
  mediaProperty?: any;
  showInForm?: boolean;
  showInGrid?: boolean;
  header?: string;
  configCascading?: any;
  configCalculation?: any;
  index?:any;
  locationProperty?:any;
}