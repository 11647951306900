/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  LoaderService,
  SessionService,
  ToastNotificationService
} from "@core-fibr/shared";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "libs/auth/src/environments/environment";
import { Subject } from "rxjs";
import { AuthenticationService } from "../../services/authentication/authentication.service";

interface ResetPassword {
  apiKey: string;
  lang: string;
  mode: string;
  oobCode: string;
}

@Component({
  selector: "ui-fibr-input-forgot-password",
  templateUrl: "./input-forgot-password.component.html",
  styleUrls: ["./input-forgot-password.component.scss"]
})
export class InputForgotPasswordComponent implements OnInit {
  public env = environment;

  resetCode!: ResetPassword;

  password!: string;
  cpassword: string | undefined;

  returnUrl = "";

  isProgressSpinner = false;
  selectedValues = false;
  fieldTextType = false;
  fieldTextTypes = false;
  msgs: any;
  country: any;

  logo = environment.logo;
  logoRight = environment.logo_right;
  labelAlt = environment.app_name;
  image_login = environment.image_login;
  image_forgotPassword = environment.image_forgotPassword;
  isAppSifa = environment.app_name == "fibr";
  primaryButtonColor = environment.primary_button_color;

  private unsubscribe: Subject<any> | undefined;

  constructor(
    private router: Router,
    public translate: TranslateService,
    public sessionService: SessionService,
    public loaderService: LoaderService,
    public authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private notif: ToastNotificationService
  ) {
    this.unsubscribe = new Subject();
    this.route.queryParams.subscribe((params) => {
      this.resetCode = params as ResetPassword;
    });
  }

  ngOnInit() {
    this.cpassword = "";
    this.password = "";
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleFieldTextTypes() {
    this.fieldTextTypes = !this.fieldTextTypes;
  }

  onSubmit() {
    if (this.password == "") {
      this.notif.showNotification("warn", "Please input new password!");
      return;
    }
    if (this.password !== this.cpassword) {
      this.notif.showNotification(
        "warn",
        "Password and Confirm Password Miss Match!"
      );
      return;
    }
    this.isProgressSpinner = true;
    this.authenticationService
      .updatePasswordFirebaseWithCode(this.password, this.resetCode.oobCode)
      .then(() => {
        this.isProgressSpinner = false;
        this.notif.showNotification(
          "success",
          "Password has been updated successfully!"
        );
        this.router.navigateByUrl("auth/login");
      });
  }
}
