import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Template } from '../../interfaces/template';
import { TemplateService } from '../../services/crud/template.service';
import { MenuItem } from 'primeng/api';
import { GlobalcrudService, GlobalService } from "@core-fibr/shared";

@Component({
  selector: 'ui-fibr-dataview2',
  templateUrl: './dataview2.component.html',
  styleUrls: ['./dataview2.component.scss']
})
export class Dataview2Component implements OnInit {

  @Input() sortOptions: SelectItem[] = [];
  @Input() dataViewModel: any ={};
  @Input() selectedProject: any;
  
  itemProject: MenuItem[] = [];
  itemProjectPro: MenuItem[] = [];
  data: any[]= [];
  dataProject: any = {};
  sortOrder = -1;
  sortField = 'id';
  sortKey = '';
  dataTemplate: any = [];
  showProjectTemplate = false;
  @Output() status: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dataAndAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() createNewProject: EventEmitter<any> = new EventEmitter<any>();
  @Output() createProjectFromTemplate: EventEmitter<any> = new EventEmitter<any>();
  // @Output() dataAllProjects: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private templateService: TemplateService,
    private globalService: GlobalService,
    private globalCrudService: GlobalcrudService
  ) {
    this.globalCrudService.getcollection(`/templates`).subscribe((res: any) =>{
      this.dataTemplate = res;
    });
  }

  ngOnInit(): void {
    // console.log(this.dataViewModel)
    this.templateService.getCollectionsPromise(this.dataViewModel.url, 'updated_date')
    .subscribe((data : any[]) => {
      this.globalService.statusProject.subscribe((val: string) => { 
        // console.log(data, val)
        // this.data = data;
        if (val == 'all')
          this.data = data.filter((x: any) => x?.is_delete == undefined || !x?.is_delete);
        else 
          this.data = data.filter((x: any) => x.is_publish == true && (x?.is_delete == undefined || !x?.is_delete));
        this.status.emit(this.data.length > 0);
      });
    });

    this.itemProject = [
      {
        label: 'Upgrade', 
        icon: 'pi pi-fw pi-check-circle',
        styleClass: "menu-green",
        command: () => {
          this.onclick('update Pro', this.dataProject)
        }
      },
      {
          separator:true
      },
      {
        label: 'Edit', 
        icon: 'pi pi-fw pi-pencil',
        styleClass: "menu-blue",
        command: () => {
          this.onclick('edit', this.dataProject)
        }
      },
      // {
      //     separator:true
      // },
      // {
      //   label: 'Delete', 
      //   icon: 'pi pi-fw pi-trash',
      //   styleClass: "menu-magenta",
      //   command: () => {
      //     this.onclick('delete', this.dataProject);
      //   }
      // },
    ];

    this.itemProjectPro = [
      {
        label: 'Edit', 
        icon: 'pi pi-fw pi-pencil',
        styleClass: "menu-blue",
        command: () => {
          this.onclick('edit', this.dataProject)
        }
      },
      // {
      //     separator:true
      // },
      // {
      //   label: 'Delete', 
      //   icon: 'pi pi-fw pi-trash',
      //   styleClass: "menu-magenta",
      //   command: () => {
      //     this.onclick('delete', this.dataProject);
      //   }
      // },
    ];
  }

  insertMenu(project: any) {
    // 
  }

  onSortChange(event: any) {
      const value = event.value;
      if (value.indexOf('!') === 0) {
          this.sortOrder = -1;
          this.sortField = value.substring(1, value.length);
      } else {
          this.sortOrder = 1;
          this.sortField = value;
      }
  }

  applyFilterGlobal(){
    this.data.filter
  }

  getProject(dataProject: any){
    this.dataProject = dataProject;
  }

  onclick(type: string, data: any){
    this.dataAndAction.emit({action : type, data: data})
  }

  onclickNewProject(){
    this.createNewProject.emit();
    this.showProjectTemplate = false;
  }

  onClickTemplate(item: any) {
    this.createProjectFromTemplate.emit(item);
    this.showProjectTemplate = false;
  }

}
