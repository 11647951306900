<form #f="ngForm">
  <div class="splash-screen bg-app" [ngStyle]="{'background-image': 'url(' + appConfig.splash_screen + ')'}">
    <!-- <div class="grid justify-content-center" *ngIf="!appConfig.is_paid">
        <div class="col-auto splash-powered">
            <p>Powered by</p>
            <a href="https://appfibr.io/"><img src="/assets/logo_appfibr.svg" alt="AppFibr Logo"/></a>
        </div>
    </div> -->
  </div>
   
  <div  class="mobile-comp mobile-form">
    <div class="p-grid p-fluid">
      <!-- <img class="img-app" src="/assets/logo_appfibr_vertical.svg" alt="AppFibr Logo"/> -->
      <img class="img-app" [src]="logo" alt="AppFibr Logo"/>

      <div class="field" *ngFor="let item of elementForm">
        <ng-container *ngIf="item.field === 'username'">
          <span class="relative">
            <p class="form-label">{{item.label}}</p>
            <input [name]="item.field" type="text" pInputText [(ngModel)]="item.value">
          </span>
        </ng-container>
        <ng-container *ngIf="item.field === 'password'">
          <!-- <span class="relative">
            <p class="form-label">{{item.label}}</p>
            <input [name]="item.field" type="password" id="inputtext" pInputText [(ngModel)]="item.value">
          </span> -->
          <p class="relative">{{item.label}}</p>
          <span class="p-input-icon-right">
            <input [name]="item.field" [type]="fieldTextType ? 'text' : 'password'" pInputText [(ngModel)]="item.value">
            <i [class]="fieldTextType ? 'pi pi-eye': 'pi pi-eye-slash'" (click)="toggleFieldTextType()"></i>
          </span>
        </ng-container>
      </div>
      <div class="grid" >
        <div class="col-12 p-0">
          <button pButton pRipple class="p-button p-component w-100" (click)="onClickLogin(f)">Login</button>
        </div>
        <div class="col-12 mt-2 z-1">
          <a href="javascript:;" (click)="onClickResetPassword()" pbutton="" type="button" label="Forgot Password" tabindex="0" class="p-button-text mt-3 relative">
            <span class="p-button-label center">Forgot Password?</span>
          </a>
        </div>
      </div>
    </div>

    <div class="grid">
        <div class="col-12 px-0">
          <p-divider></p-divider>
        </div>
        <div class="col-12 p-0">
          <button pButton pRipple class="p-button p-button-secondary2 w-100" *ngIf="appConfig?.is_login_guest" (click)="onClickLoginGuest()">Login as Guest</button>
        </div>
        <div class="col-12 mt-2 z-1" *ngIf="is_register">
          <a href="javascript:;" (click)="onClickRegister()" pbutton="" type="button" label="Register" tabindex="0" class="p-button-text mt-3">
            <span class="p-button-label center">Register</span>
          </a>
        </div>
    </div>
  </div>
</form>