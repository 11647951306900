import { LowerCasePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { appConfig, ConnectionService, GlobalcrudService, GlobalService, GridModel } from '@core-fibr/shared';
import { isNull } from 'lodash';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { environment } from '../../../../../../auth/src/environments/environment';
// import mixpanel from 'mixpanel-browser';
import mixpanel from 'mixpanel-browser'

@Component({
  selector: 'ui-fibr-createnew-blank',
  templateUrl: './createnew-blank.component.html',
  styleUrls: ['./createnew-blank.component.scss']
})
export class CreatenewBlankComponent implements OnInit {
  gridModel: GridModel = new GridModel();
  appConfig: appConfig = {};
  isGoogleFiles: boolean | undefined;
  tableName = "";
  data: any = {};
  user: any;
  waitProcess: any = {};
  urlWait= "";
  showMsg = false;
  showMsgTable = false;
  isDataExists = false;
  statusApp = "";
  tableKey: any = [];
  pathProjectFirebase = "";
  freeVersion: any;
  env: any;
  constructor(
    private router: Router, 
    private globalService: GlobalService,
    private globalCrudService: GlobalcrudService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private tolowercase : LowerCasePipe,
    private connectionService: ConnectionService,
    private httpClient: HttpClient
    ) { 
      this.env = this.globalService.decryptEnv(environment);
    }

  ngOnInit(): void {
    this.isGoogleFiles = this.config.data?.isGoogleFiles? this.config.data?.isGoogleFiles : false;
    this.gridModel.APIEndpoint = this.config.data?.url ?? '';
    this.globalService.appConfig.subscribe((res: appConfig) => {
      this.appConfig = res;
    });
    this.user = this.globalService.userProfile;
    /** for need trial */
    this.globalCrudService
      .getcollection(`users/${this.user.uid}/projects`)
      .subscribe((res: any) => {
        this.isDataExists = res.length > 0; 
    });
    // this.statusApp = environment.firebase.projectId;
    this.statusApp = this.env.firebase.projectId;
    this.urlWait = `users/${this.user.uid}/profile`;
    this.globalCrudService.getcollection(this.urlWait).subscribe((res: any) => {this.waitProcess = res[0];});
    this.globalCrudService.getcollectionwithid('/config/facilities/data', 'free').subscribe((res: any)=>{
      this.freeVersion = res;
    });
  }

  blankTable() : void{
    
    if(this.appConfig.app_name == null || this.appConfig.app_name == ''){
      this.showMsg = true;
      return;
    }
    if(this.tableName == null || this.tableName == ''){
      this.showMsgTable = true;
      return;
    }
    this.showMsg = false;
    this.showMsgTable = false;
    this.appConfig.app_name = this.globalService.replaceString(this.appConfig.app_name);
    // this.blankTableFetch(this.appConfig.app_name, this.tableName);
    if (!this.showMsg && !this.showMsgTable) {
      this.globalService.changeBlockui(true);
      setTimeout(() => {
        this.blankTableFetch(this.appConfig.app_name, this.tableName);
        this.globalService.changeBlockui(false);
        // this.router.navigate(['/portal/project/editor'], {
        //   queryParams: { filename: this.appConfig.app_name, fileid: this.appConfig.file_id, appid: this.appConfig.app_id }
        // });
      }, 2000);
      setTimeout(() => {
        this.globalService.changeBlockui(false);
        this.router.navigate(['/portal/project/editor'], {
          queryParams: { filename: this.appConfig.app_name, fileid: this.appConfig.file_id, appid: this.appConfig.app_id }
        });
      }, 2000);
    }
  }

  blankTableFetch(appName: any, tableName: any){
    this.globalService.changeBlockui(true);
    this.appConfig.app_id = this.tolowercase.transform((appName)?.split(' ').join('') + this.globalCrudService.generateId());
    this.appConfig.file_id = this.tolowercase.transform((tableName)?.split(' ').join('') + this.globalCrudService.generateId());
    this.appConfig.file_name = appName;
    this.appConfig.version = '1.0.0';
    this.appConfig.app_icon = '';
    this.appConfig.build_number = 1;
    this.appConfig.is_suspend = false;
    this.appConfig.is_publish = false;
    this.appConfig.created_date = new Date();
    this.appConfig.updated_date = new Date();
    this.appConfig.config= '';
    this.appConfig.app_desc = '';
    this.appConfig.package_name = '';
    this.appConfig.app_type = '';
    this.appConfig.publish_option = '';
    this.appConfig.app_logo = '';
    this.appConfig.primary_color = '#97DDFF';
    this.appConfig.secondary_color = '#4394D8';
    this.appConfig.font_color = '#1A1A1A';
    this.appConfig.splash_screen = '';
    this.appConfig.login_registration = '';
    this.appConfig.broadcast_content = '';
    this.appConfig.broadcast_title = '';
    this.appConfig.is_paypwa = false;
    this.appConfig.is_payapps = false;
    this.appConfig.category = '';
    this.appConfig.created_by = '';
    this.appConfig.is_paid = false;
    // this.appConfig.max_rows = environment.addPackage.max_rows_free;
    // this.appConfig.max_pages = environment.addPackage.max_page_free;
    // this.appConfig.max_tables = environment.addPackage.max_table_free;
    this.appConfig.max_rows = this.freeVersion.max_rows;
    this.appConfig.max_pages = this.freeVersion.max_pages;
    this.appConfig.max_tables = this.freeVersion.max_tables;
    this.appConfig.is_login = false;
    this.appConfig.email = this.user.email;
    this.appConfig.uid = this.user.uid;
    this.appConfig.number_logged = 0;
    this.appConfig.number_registered = 0;
    this.appConfig.fullName = this.user.fullName;
    this.appConfig.phoneNumber = this.user.phoneNumber;
    this.appConfig.is_process_status = '';
    this.appConfig.upgrade_pro_date = null;
    this.appConfig.membership_expired_date = null;
    this.appConfig.published_date = null;
    this.appConfig.traffic = 0;
    this.appConfig.traffic_app = 0;
    this.appConfig.traffic_pwa = 0;
    this.appConfig.max_storage = 0;
    this.appConfig.is_publish_pwa = false;
    this.appConfig.publish_pwa_date = null;
    this.appConfig.is_role = false;
    this.appConfig.status_project = 'basic';
    this.appConfig.is_registration = false;
    this.globalService.setAppConfig(this.appConfig);
    this.globalService.setProjectEditStatus(true);
    this.ref.close();

    this.connectionService.swicthFirebase(this.user,this.appConfig.app_id);
    const path = `/users/${this.user.uid}/projects`;
    const urlDataPathFirebase = `/users/${this.user.uid}/data_projects`;
  
    this.globalCrudService.createcollectionwithdocumentname(this.appConfig, path, this.appConfig.app_id)
    // .then(() => {
      const dataForAdd: any = {};
      const convertArrayToObject: any = {};
      convertArrayToObject[tableName] = {};
      const pathData = `/users/${this.user.uid}/data_projects/${this.appConfig.app_id}/tables`;
      const collectionPath = pathData + "/" + tableName + "/data"; 
      const addCol = ['created_date','created_by','modify_date','modified_by'];
      const columns: any[] = [];          
      for (let ii=0; ii < addCol.length; ii++) {
        columns.push({ 
          "field" : addCol[ii], "header" : addCol[ii], "sortable" : true, "filterable": true, "col_order": ii, 
          type: this.globalService.getType(addCol[ii]), "showInGrid": false, "showInForm": true 
        });     
      }
                
      // this.globalCrudService.createDynamiccollectionwithdocumentid({columns : columns}, pathData, tableName).then(() => console.log());
      this.globalCrudService.createcollectionwithdocumentname({columns : columns}, pathData, tableName).then(() => console.log());

      convertArrayToObject[tableName]["modify_date"] = new Date();
      convertArrayToObject[tableName]["created_date"] = new Date();
      dataForAdd["modify_date"] = new Date();
      dataForAdd['created_by']= '';
      dataForAdd['modified_by']= '';
      this.tableKey.push(tableName);

      this.globalCrudService.createcollectionwithdocumentname(convertArrayToObject,urlDataPathFirebase,this.appConfig?.app_id??'');
      this.globalCrudService.createcollectionwithdocumentname({ key: this.tableKey },urlDataPathFirebase,this.appConfig.app_id??''); // ###### 
      this.globalCrudService.createcollectionWithId(dataForAdd, collectionPath);
      // this.globalCrudService.createcollectionWithIdDummy(dataForAdd, collectionPath);

      /**config mixpanel */
      /**dev */
      // mixpanel.init('9ca962da62d0f86a324432b2423d58ba', {debug: true, track_pageview: true, persistence: 'localStorage'});
      /**prod */
      mixpanel.init('6d431289594ff534ccd663c3ca8ce3c5', {debug: true, track_pageview: true, persistence: 'localStorage'});
      mixpanel.identify(this.user.uid);
      mixpanel.people.set({'$name': this.user.fullName,'$email': this.user.email,'User Detail Info' : this.user});
      mixpanel.track('Project Created', {
        'Initial Project': 'Blank Project'
      })
  }

}
