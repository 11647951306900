/* eslint-disable @typescript-eslint/no-explicit-any */
import { DatePipe, TitleCasePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output, ElementRef } from "@angular/core";
import { Confirmation, GlobalcrudService, GlobalService, SelectionModel, ToastNotificationService } from "@core-fibr/shared";
import { environment } from "@core-fibr/auth";
import { ConfirmationService, MenuItem } from "primeng/api";
import { Subscription } from "rxjs";
import { appConfig, configMobile } from "../../interfaces/global";
import { ShowMockupService } from "../../services/showmockup.service";
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: "ui-fibr-mobile",
  templateUrl: "./mobile.component.html",
  styleUrls: ["./mobile.component.scss"],
})
export class MobileComponent implements OnInit {
  @Input() config: configMobile = {};
  @Input() data: any;
  @Input() dataSummary: any;
  @Input() itemPages: MenuItem[] = [];
  @Input() params: any;
  @Input() actFrom: any;
  @Input() isTemplate: boolean | undefined;
  @Input() isPaid: any;
  @Input() isLogin: any;
  @Input() selectedItem: any;
  @Input() dataColumn: any;
  @Input() appConfig: any = {}
  

  @Output() menuClick = new EventEmitter<any>();
  @Output() menuClickWithParams = new EventEmitter<any>();
  @Output() onDeleteDetail = new EventEmitter<any>();

  showForm = false;
  showPageDetails = false;
  e: any;
  subscription: Subscription | undefined;
  actEdit = false;
  dataDetail: any;
  fields: any = [];
  tableName = "";
  pipe = new DatePipe('en-US');
  login_user = false;
  is_register = false;
  is_activeEmail = false;
  is_resetPassword = false;
  confirmationTemplate: Confirmation | undefined;
  pathUser: any;
  userProject: any;
  // appConfig:any;
  appConfig2: any
  logo = '';
  itemSelected: any;
  headers: any = "";

  pageIndex=0;
  totalRecords=0;

  constructor( 
    private showMockupService: ShowMockupService,
    private titlecase: TitleCasePipe,
    private globalServiceCrud: GlobalcrudService,
    private globalService: GlobalService,
    private confirmService: ConfirmationService,
    private toastNotificationService: ToastNotificationService,
    private el: ElementRef,
  ) {
    this.subscription = showMockupService.pageDetailShow$.subscribe(
      pageDetail => {
        this.showPageDetails = pageDetail;
    });
  }

  ngOnInit(): void {
    this.e = this.data[0];
    // console.log('property actFrom adalah variabel untuk case pwa / app preview : ',this.actFrom)
    this.pathUser = `/users/${this.params.id}/userapp_fibr/${this.params.appid}/data/`;
    const urlPathFirebase = `/users/${this.params.id}/projects`;
    this.userProject = this.globalService.getCurrReffDataParsed("user_project");
    if (this.userProject != null) {
      this.login_user = true;
      if (this.userProject?.isLoginGuest) {
        this.is_activeEmail = true;
      } else {
        this.is_activeEmail = this.userProject?.is_active;
      }
    }
      
      
    // this.globalService.appConfig.subscribe((val: any) => {
    //   this.appConfig = val;
      (this.el.nativeElement as HTMLElement).style.setProperty('--primaryColor', this.appConfig.primary_color);
      (this.el.nativeElement as HTMLElement).style.setProperty('--secondaryColor', this.appConfig.secondary_color);
      (this.el.nativeElement as HTMLElement).style.setProperty('--fontColor', this.appConfig.font_color);
    // });
    if (this.appConfig.is_paid && this.appConfig.app_logo != '') this.logo = this.appConfig.app_logo; else this.logo = '/assets/logo_appfibr_vertical.svg';  
    // (this.el.nativeElement as HTMLElement).style.setProperty('--primaryColor', this.appConfig.primary_color);
    // (this.el.nativeElement as HTMLElement).style.setProperty('--secondaryColor', this.appConfig.secondary_color);
    // (this.el.nativeElement as HTMLElement).style.setProperty('--fontColor', this.appConfig.font_color);
  }

  async onClick(e: any, act?: any) {
    this.showForm = false;
    this.e = e.state.layoutDesign.layoutTemplate == "details" ? null : e;
    this.config = e.state.layoutDesign.layoutTemplate == "details" ? e.state : this.config;
    this.showPageDetails = false;
    if (act != 'fromPageDetail')
      this.menuClick.emit({ item: e, display: false, isModifyPageMenu: false });

    this.fields = [];
    this.tableName = e.state.dataContent.contentDataSource;
    let urlColumn = '';
    if (this.isTemplate) 
      urlColumn = `/templates/${this.params.appid}/tables`
    else
      urlColumn = `/users/${this.params.id}/data_projects/${this.params.appid}/tables`;
    this.globalServiceCrud.getDynamicCollectionwithid(urlColumn, this.tableName)
    .subscribe((response: any) => { 
      response?.columns.forEach( (col: any) => {
        this.fields.push({ value: col?.field, name: this.titlecase.transform(col?.header), type: col?.type });
      });
    });
    this.itemSelected = e;
    this.pageIndex = 0;
    await this.globalServiceCrud.getCountCollection(urlColumn + `/${this.tableName}/data`).then(x => {
      this.totalRecords = x
    });
  }

  onClickWithParams(event: any) {
    // console.log(event)
    this.showPageDetails = false;
    this.menuClick.emit({item: event?.child, params: event?.parent, from: event?.from, data: event?.data, display: false, isModifyPageMenu: false});
    this.itemSelected = event?.child;
  }

  buttonFloatOnclick(e: any) {
    this.showForm = e;
    this.actEdit = false;
    this.dataDetail = {};
  }

  buttonDetailOnclick(e: any) {
    this.e = e;
    this.showPageDetails = true;
  }

  buttonCloseDetail() {
    this.showPageDetails = false;
  }

  onForFormDetail(event: any) {
    this.showForm = event.showForm;
    this.actEdit = event.actEdit;
    this.dataDetail = event.dataDetail;
  }

  onFromPageDetailButton(event: any) {
    this.data = event.dataTypeButton;
    this.config = event.config.state
    // const con = {state: event.config};
    this.onClick(event.config, 'fromPageDetail');
  }

  buttonSearchClick(event: any) {
    // console.log(event, this.fields, this.itemSelected,this.config)
    this.userProject = this.globalService.getCurrReffDataParsed("user_project");
    let body: any = {};
    body = this.bodyData(event, 'search'); 
    let url: any = '';
    if (this.isTemplate) {
      url = `/templates/${this.params.appid}/tables/${this.tableName}/data`;
    } else {
      url = `/users/${this.params.id}/data_projects/${this.params.appid}/tables/${this.tableName}/data`;
    }  
    let filterCol: any = '';
    if (!this.config.dataContent?.filter) filterCol = ''; else filterCol = this.config.dataContent?.filter[0]?.column;
    if (this.itemSelected != undefined)
      this.menuClick.emit({ item: this.itemSelected, bodyPwa: body});
  }

  buttonSortClick(event: any) {
    if (event.field == '') {
      this.toastNotificationService.showNotification("error", "Field must be selected!");
    } else {
      this.data = [];
      let body: any = {};
      body = this.bodyData(event, 'sort');
      let url: any = '';
      if (this.isTemplate) {
        url = `/templates/${this.params.appid}/tables/${this.tableName}/data`;
      } else {
        url = `/users/${this.params.id}/data_projects/${this.params.appid}/tables/${this.tableName}/data`;
      }  
      let sortCol: any = '';
      if (!this.config.dataContent?.sort) sortCol = ''; else sortCol = this.config.dataContent?.sort[0]?.column;
      if (this.itemSelected != undefined)
        this.menuClick.emit({ item: this.itemSelected, bodyPwa: body});
    }
  }

  async scrollLazyLoadAct(event: any) {
    // console.log(this.actFrom, this.config)
    if (this.actFrom == 'pwa') {
      let body: any = {};
      body = this.bodyData(event, 'lazyLoad'); 
      // console.log(body)
      if (this.data.length != this.totalRecords && body.filters.length == 0 && body.accessible == 'all_users' && body.configFilter == '') {
        let url = ''
        if (this.config.dataContent?.isExternalDataSource) {
          url = this.globalService.getPathDataExternalSource(this.config.dataContent?.contentDataSource, this.params);
        } else {
          url = `/users/${this.params.id}/data_projects/${this.params.appid}/tables/${this.tableName}/data`;;
        }
        this.getDataLazyAct(url,body)
      } else {
        this.globalService.setScrollLazyLoad(false);
      }
    } else {
      this.globalService.setScrollLazyLoad(false);
    }
  }

  getDataLazyAct(url:any, body:any) {
    this.globalService.changeBlockui(true);
    this.globalServiceCrud.getDynamicCollectionLazyAction(url,body,this.config.dataContent?.isExternalDataSource)?.then((result:any) => {
      if (result.docs != null) {
        result.docs.forEach((doc: any) => {
          const rowdata = doc.data();
          rowdata.id = doc.id;
          this.data.push(rowdata);
        });
        this.globalService.convertDataApp(this.data)
        this.globalService.changeBlockui(false);
        this.globalService.setScrollLazyLoad(true);
        console.log(this.data)
      }
    }); 
  }

  showData(url: any, body: any){
    if (this.config.pageProperty?.isShowAllData || !this.login_user || this.isTemplate) {
      this.globalServiceCrud.dynamicColectionSeacrh(url,body)?.then((result:any) => {
        if (result.docs != null) {
          this.data = [];
          result.docs.forEach((doc: any) => {
            const rowdata = doc.data();
            rowdata.id = doc.id
            this.data.push(rowdata);
          });
          // this.convertData(this.data);
          this.globalService.convertDataApp(this.data)
        }
      });
    } else {
      if (this.userProject.level == 'admin') {
        this.globalServiceCrud.dynamicColectionSeacrh(url,body)?.then((result:any) => {
          if (result.docs != null) {
            this.data = [];
            result.docs.forEach((doc: any) => {
              const rowdata = doc.data();
              rowdata.id = doc.id
              // this.data.push(doc.data());
              this.data.push(rowdata);
            });
            this.globalService.convertDataApp(this.data)
          }
        });
      } else {
        this.globalServiceCrud.dynamicColectionSeacrhWithUser(url,body,this.userProject.email)?.then((result:any) => {
          if (result.docs != null) {
            this.data = [];
            result.docs.forEach((doc: any) => {
              // this.data.push(doc.data());
              const rowdata = doc.data();
              rowdata.id = doc.id
              this.data.push(rowdata);
            });
            this.globalService.convertDataApp(this.data)
          }
        });
      }
    }
  }

  showDataSort(url: any, body: any){
    if (this.config.pageProperty?.isShowAllData || !this.login_user || this.isTemplate) {
      this.globalServiceCrud.dynamicColectionSort(url,body)?.then((result:any) => {
        if (result.docs != null) {
          this.data = [];
          result.docs.forEach((doc: any) => {
            const rowdata = doc.data();
            rowdata.id = doc.id
            this.data.push(rowdata);
          });
          // this.convertData(this.data);
          this.globalService.convertDataApp(this.data)
        }
      });
    } else {
      if (this.userProject.level == 'admin') {
        this.globalServiceCrud.dynamicColectionSort(url,body)?.then((result:any) => {
          if (result.docs != null) {
            this.data = [];
            result.docs.forEach((doc: any) => {
              const rowdata = doc.data();
              rowdata.id = doc.id
              // this.data.push(doc.data());
              this.data.push(rowdata);
            });
            this.globalService.convertDataApp(this.data)
          }
        });
      } else {
        this.globalServiceCrud.dynamicColectionSortWithUser(url,body,this.userProject.email)?.then((result:any) => {
          if (result.docs != null) {
            this.data = [];
            result.docs.forEach((doc: any) => {
              // this.data.push(doc.data());
              const rowdata = doc.data();
              rowdata.id = doc.id
              this.data.push(rowdata);
            });
            this.globalService.convertDataApp(this.data)
          }
        });
      }
    }
  }

  bodyData(event: any, action: string) {
    // console.log(event, this.fields, this.itemSelected,this.config)
    const body: any = {};
    body.first = 0;
    body.rows = 10;
    body.filters = [];
    if (action == 'search') {
      if (!this.config.dataContent?.sort || this.config.dataContent?.sort[0].column == '') {
        body.sortField = 'created_date';
        body.sortOrder ='desc';
      } else {
        body.sortField = this.config.dataContent?.sort[0].column;
        body.sortOrder = this.config.dataContent?.sort[0].sortType;
      }
      if (this.config.layoutDesign?.layoutTemplate == 'list' || this.config.layoutDesign?.layoutTemplate == 'grid') {
        for (let i = 0; i < this.fields.length; i++) {
          if (this.fields[i].type == 'text' || this.fields[i].type == 'string')
            body.filters.push({propertyName: this.fields[i].value, value: event.search, matchMode: 'equals'});
        }
      }
      if (this.config.layoutDesign?.layoutTemplate == 'cart') {
        if (this.config.dataContent?.header != '') body.filters.push({propertyName: this.config.dataContent?.header, value: event.search, matchMode: 'equals'});
        if (this.config.dataContent?.mainTitle != '') body.filters.push({propertyName: this.config.dataContent?.mainTitle, value: event.search, matchMode: 'equals'});
        if (this.config.dataContent?.label != '') body.filters.push({propertyName: this.config.dataContent?.label, value: event.search, matchMode: 'equals'});
        if (this.config.dataContent?.description != '') body.filters.push({propertyName: this.config.dataContent?.description, value: event.search, matchMode: 'equals'});
      }
    }

    if (action == 'lazyLoad') {
      if (!this.config.dataContent?.sort || this.config.dataContent?.sort[0].column == '') {
        body.sortField = 'created_date';
        body.sortOrder ='desc';
      } else {
        body.sortField = this.config.dataContent?.sort[0].column;
        body.sortOrder = this.config.dataContent?.sort[0].sortType;
      }
      this.pageIndex +=1;
      body.limit = this.pageIndex * body.rows;
      body.accessible = this.config.dataContent?.accessible?.type
      const configFilter:any = this.config.dataContent?.filter;
      body.configFilter = configFilter[0].column
    }

    if (action == 'sort') {
      body.sortField = event.field;
      if (event.short == 1) 
        body.sortOrder ='asc';
      else
        body.sortOrder ='desc';
    }
    return body;
  }

  buttonLoginClick(item: any) {
    // const path = `/users/${this.params.id}/userapp_fibr/${this.params.appid}/data/`;
    const body = {whereField: [{field: 'email', value: item.username}, {field: 'password', value: item.password}]};
    this.globalService.changeBlockui(true);
    setTimeout(() => {
      this.globalServiceCrud.getDynamicCollectionObjectWherePromise(this.pathUser, body)
      .then((result: any) => {
        this.globalService.changeBlockui(false);
        if (result.docs == 0) {
          this.toastNotificationService.showNotification("error", "user not found!");
        } else {
          result.docs.map((doc: any) => {
            const dataUser:any = doc.data();
            // this.infoUser = {userId: dataUser.id, userEmail: dataUser.email, userName: dataUser.user_name}
            this.login_user = true;
            dataUser.status_login = true;
            dataUser.password = '';
            this.globalService.setRefDataToLocalstorage("user_project", dataUser);
          });
        }
      });
    }, 1000); 
  }

  buttonLoginWithApiClick(item: any) {
    // console.log(this.params, item)
    const body = {email: item.username, password: item.password, userId: this.params.id, projectId: this.params.appid};
    this.globalService.changeBlockui(true);
    this.globalService.methodPost(environment.be_dev + `/api/v1/fibr-app/auth/login`,body)
    .subscribe((res) => {
      setTimeout(() => {
        this.globalService.changeBlockui(false);
        const dataUser:any = res.data;
        this.login_user = true;
        this.is_activeEmail = res.data?.is_active; /**ini nanti diambilkan dari status is_active email dari res */
        this.globalService.setRefDataToLocalstorage("user_project", dataUser);
      }, 1000); 
    },
    (err: any) => {
      this.globalService.changeBlockui(false);
      this.toastNotificationService.showNotification(err?.error?.status, err?.error?.message);
    });
  }

  buttonLoginGuestWithApiClick() {
    const userAgent = window.navigator.userAgent;
    const body = {deviceId: userAgent,userId: this.params.id, projectId: this.params.appid, accessToken: ''};
    this.globalService.changeBlockui(true);
    this.globalService.methodPost(environment.be_dev + `/api/v1/fibr-app/auth/login/guest`,body)
    .subscribe((res) => {
      setTimeout(() => {
        this.globalService.changeBlockui(false);
        const dataUser:any = res.data;
        dataUser.isLoginGuest = true;
        dataUser.email = 'login_as_guest';
        dataUser.role = 'fibr_guest';
        this.login_user = true;
        this.is_activeEmail = true; 
        this.globalService.setRefDataToLocalstorage("user_project", dataUser);
      }, 1000); 
    },
    (err: any) => {
      this.globalService.changeBlockui(false);
      this.toastNotificationService.showNotification(err?.error?.status, err?.error?.message);
    });
  }

  cekUserProject(item: any) {
    const infoUser = this.globalService.getCurrReffDataParsed("user_project");
    if (infoUser == null) {
      if (item?.isLoginGuest) {
        this.buttonLoginGuestWithApiClick()
      } else {
        this.buttonLoginWithApiClick(item)
      }
    } else {
      this.confirmService.confirm({
        message: 'User Project Session is still running!, Click yes if you want to remove and continue open new project',
        header: 'Check User Project Session',
        rejectButtonStyleClass: 'p-button-secondary',
        accept: () => {
          localStorage.removeItem('user_project');
          if (item?.isLoginGuest) {
            this.buttonLoginGuestWithApiClick()
          } else {
            this.buttonLoginWithApiClick(item)
          } 
        },
        reject: () => console.log()
      });
    }
  }

  buttonToRegisterClick(event: any) {
    this.is_register = event;
  }

  // buttonRegisterClick(item: any) {
  //     const body = {whereField: [{field: 'email', value: item.email}]};
  //     this.globalService.changeBlockui(true);
  //     setTimeout(() => {
  //       this.globalServiceCrud.getDynamicCollectionObjectWherePromise(this.pathUser, body)
  //       .then((result: any) => {
  //         // console.log(result.docs.length)
  //         if (result.docs.length == 0) {
  //           this.globalServiceCrud.dynamicCreatecollectionWithId(item, this.pathUser)
  //           .then((res: any) => {
  //             this.toastNotificationService.showNotification("success", "successful registration..");
  //             this.is_register = false;
  //           });
  //         } else {
  //           this.toastNotificationService.showNotification("error", "e-mail already registered!");
  //         }
  //         this.globalService.changeBlockui(false);
  //       });
  //     }, 1000);
  // }

  buttonRegisterWithApiClick(item: any) {
    this.globalService.changeBlockui(true);
    this.globalService.methodPost(environment.be_dev + `/api/v1/fibr-app/auth/register`,item)
    .subscribe((res) => {
      this.globalService.changeBlockui(false);
      this.toastNotificationService.showNotification(res.status, res.message);
      this.is_register = false;
      this.userProject = {};
    },
    (err: any) => {
      this.globalService.changeBlockui(false);
      this.toastNotificationService.showNotification(err?.error?.status, err?.error?.message);
    });
  }

  buttonToLoginClick(event: any) {
    this.is_register = event;
    this.is_resetPassword = event;
  }

  buttonLogoutClick() {
    this.confirmationTemplate = {
      header: "Confirmation!",
      content: "Click Yes, if you want to Logout?",
      acceptButtonStyleClass: "p-button",
      rejectButtonStyleClass: "p-button-secondary"
    };
    this.confirmService.confirm({
      message: this.confirmationTemplate.content,
      header: this.confirmationTemplate.header,
      acceptButtonStyleClass: this.confirmationTemplate.acceptButtonStyleClass,
      rejectButtonStyleClass: this.confirmationTemplate.rejectButtonStyleClass,
      accept: () => {
        this.login_user = false;
        setTimeout(() => {
          localStorage.removeItem('user_project');
          this.userProject = {};
          this.data = [];
        }, 1000);
      },
      reject: () => console.log()
    });
  }

  /**active email */
  buttonToActiveEmailClick(event: any) {
    // console.log(event, this.params, this.userProject);
    this.globalService.changeBlockui(true);
    this.userProject = this.globalService.getCurrReffDataParsed("user_project");
    const body = {otp: event.token, userId: this.params.id, projectId: this.params.appid}
    this.globalService.methodPut(environment.be_dev + `/api/v1/fibr-app/auth/login/${this.userProject.uid}`,body)
    .subscribe((res) => {
      this.globalService.changeBlockui(false);
      this.toastNotificationService.showNotification(res.status, res.message);
      this.userProject.is_active = res.data?.is_active;
      this.globalService.setRefDataToLocalstorage("user_project", this.userProject);
      this.is_activeEmail = res.data?.is_active;
      
    },
    (err: any) => {
      this.globalService.changeBlockui(false);
      this.toastNotificationService.showNotification(err?.error?.status, err?.error?.message);
    });
  }

  /**reset password */
  buttonToResetPasswordClick(event: any) {
    this.is_resetPassword = event;
  }

  async changePassword(event:any) {
    // console.log(event, this.userProject)
    this.globalService.changeBlockui(true);
    this.headers  = new HttpHeaders({ 'Content-Type':  'application/json', Authorization: `Bearer ${this.userProject?.accessToken}`});
    const body = {projectId: this.params.appid, password: event.password, hitBy: 'pwa'}
      await this.globalService.methodPutOptions(environment.be_dev + `/api/v1/fibr-app/auth/password/update`,body,this.headers)
      .subscribe(( res: any) => {
        this.globalService.changeBlockui(false);
        this.toastNotificationService.showNotification(res.status, res.message);
      },
      (err: any) => {
        this.globalService.changeBlockui(false);
        this.toastNotificationService.showNotification(err?.error?.status, err?.error?.message);
      });
  }
  
}
