import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { GlobalcrudService, GlobalService } from '@core-fibr/shared';

@Component({
  selector: 'ui-fibr-detail-project',
  templateUrl: './detail-project.component.html',
  styleUrls: ['./detail-project.component.scss']
})
export class DetailProjectComponent implements OnInit {

  @Input() dataProject: any ={};
  user: any = {};
  descPublish = "";
  statusPublish = false;
  notifExpireInvoice = '';
  statusProject = '';

  constructor(
    private globalService: GlobalService,
    public titlecase: TitleCasePipe,
    private globalCrudService: GlobalcrudService,
  ) { 
    // 
   }

  ngOnInit(): void {
    this.user = this.globalService.userprofile;
    const path = `users/${this.user.uid}/projects/${this.dataProject.app_id}/firebase`;
    this.globalCrudService.getcollection(path).subscribe((res: any) => {
      const checkProject = res[0];
      if (checkProject?.process) {
        this.descPublish = "waiting publish on Progress";
        this.statusPublish = true;
      } else {
        this.statusPublish = false;
      }  
    });

    this.globalService.appConfig.subscribe((val: any) => { 
      setTimeout(() => {
        const pathInvoice = `/users/${this.user.uid}/projects_invoice`;
        this.globalCrudService.getcollectionwithid(pathInvoice, this.dataProject.app_id)
        .subscribe((res: any) => {
          const dateNow : any = new Date()
          if (res?.expire_app) {
            const diff: number = (res?.expire_app.toDate() - dateNow.getTime()) / (1000 * 3600 * 24)
            this.notifExpireInvoice = 'Pro plan '+Math.round(diff)+' days remaining'
          } else {
            this.notifExpireInvoice = ''
          }
        });
      }, 1000);
    })

    if(this.dataProject.status_project == 'business') {
      this.statusProject = 'business'
    } else {
      if(this.dataProject.is_paid) this.statusProject = 'Pro'; else this.statusProject = 'Basic';
    }

  }

}
