/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  GlobalService,
  GlobalcrudService,
  LoaderService,
  SessionService,
  ToastNotificationService,
} from "@core-fibr/shared";
import { TranslateService } from "@ngx-translate/core";
import { environment, AuthenticationService } from "@core-fibr/auth";
import { Observable, Subject } from "rxjs";
import { finalize, takeUntil, tap } from "rxjs/operators";
import { Lang } from "../../models/user-info";
import { CaptchaService } from "../captcha/captcha.service";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "ui-fibr-auth-login",
  templateUrl: "login.component.html",
  styleUrls: ["login.component.scss"],
})
export class LoginComponent implements OnInit {
  public env = environment;

  userName: string | undefined;
  password: string | undefined;
  locale: string | undefined;
  version: string | undefined;
  returnUrl = "";

  isProgressSpinner = false;
  selectedValues = false;
  msgs: any;
  country: any;

  logo = environment.logo;
  logoRight = environment.logo_right;
  labelAlt = environment.app_name;
  image_login = environment.image_login;
  image_forgotPassword = environment.image_forgotPassword;
  isGoogleSignIn = environment.isGoogleSignIn;
  isAppleSignIn = environment.isAppleSignIn;
  isMicrosoftSignIn = environment.isMicrosoftSignIn;
  isAppSifa = environment.app_name == "fibr";
  primaryButtonColor = environment.primary_button_color;
  textColorCopy = environment.text_color;
  countGetToken = 0;
  ip_addrress = '';
  browserName = '';
  browserVer = '';
  isBlocked = false;

  selectedLanguage: Lang | undefined;
  private unsubscribe: Subject<any> | undefined;
  captchaStatus: any = null;
  captchaConfig: any = {
    length: 6,
    cssClass: "custom",
    back: {
      stroke: "#2F9688",
      solid: "#fff",
    },
    font: {
      color: "#000000",
      size: "35px",
    },
  };
  fieldTextType = false;
  countLoopLogin = 0;

  constructor(
    private router: Router,
    public translate: TranslateService,
    public sessionService: SessionService,
    public loaderService: LoaderService,
    public authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private globalCrudService: GlobalcrudService,
    private captchaService: CaptchaService,
    private notif: ToastNotificationService,
    private authService: AuthenticationService,
    private http:HttpClient
  ) {
    this.unsubscribe = new Subject();
    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params.returnUrl || "/portal";
    });
  }

  ngOnInit() {
    if (this.globalService.getCurrReffDataParsed("user_profile")) {
      this.router.navigate([``]);
    }
    this.country = [
      { name: "English", code: "en" },
      { name: "Indonesia", code: "id" },
    ];
    this.userName = "";
    this.password = "";
    this.locale = this.sessionService.getItem("fibr-language");
    this.selectedLanguage = this.country.filter(
      (x: any) => x.code == this.locale
    )[0];
    this.version = environment.version;
    this.msgs = [
      { severity: "info", detail: "UserName: admin" },
      { severity: "info", detail: "Password: password" },
    ];
    this.captchaService.captchStatus.subscribe((status) => {
      this.captchaStatus = status;
    });
    // this.getIp();
    this.browserName = this.detectBrowserName();
    this.browserVer = this.detectBrowserVersion();
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  onClickLogin() {
    if (!this.captchaStatus) {
      this.notif.showNotification("error", "Opps!\nCaptcha mismatch");
      return;
    }
    this.authenticationService
      .login({ email: this.userName, password: this.password })
      .pipe(
        tap(
          (result) => {
            const user = result;
            this.globalService.setRefDataToLocalstorage("user_profile", user);
            this.globalService.setRefDataToLocalstorage("token", user.access_token);
            this.router.navigateByUrl(this.returnUrl)
            .then(() => {
              window.location.reload();
            });
          }
        ),
        takeUntil(this.unsubscribe as Observable<any>),
        finalize(() => {
          this.cdr.markForCheck();
        })
      )
      .subscribe();
  }

  onClickLoginUseFireEmailandPassword() {
    this.isProgressSpinner = true;
    this.isBlocked = true;
    this.authenticationService.loginFireEmailandPassword({ email: this.userName, password: this.password })
      .then((result) => {
        // console.log(result)
        const pathProfile = `users/${result.uid}/profile`;
        this.globalCrudService.getcollectionPromise(pathProfile).then(res => {
          // console.log(res)
          if (res.length > 0) {
            const userInfo: any = res[0];
            if (userInfo?.is_email_verified == false) {
              this.authService.clearAuthToken();
              this.notif.showNotification('error', 'To be able to login, please verify your email first!');
              setTimeout(() => {
                location.reload();
              }, 3000);
            } else {
              if (environment.app_name == 'fibr') {
                if (userInfo != undefined) { //kalau user ketemu *atau ada baru hit endpoint ini kalau gak jangan
                  this.getToken(userInfo)
                }
              } else {
                this.isBlocked = false;
                this.globalService.setRefDataToLocalstorage("user_profile", userInfo);
                this.router.navigateByUrl(this.returnUrl)
                .then(() => {
                  window.location.reload();
                });
              }
            }
          } else {
            this.authService.clearAuthToken();
            this.notif.showNotification('error', 'User Not Found!');
            setTimeout(() => {
              location.reload();
            }, 3000);
          }
        })
        .catch((error) => {
          // 
        });
        setTimeout(() => {
          this.isProgressSpinner = false;
          this.isBlocked = false;
        }, 6000);
      }),
      takeUntil(this.unsubscribe as Observable<any>),
      finalize(() => {
        this.isProgressSpinner = false;
        this.isBlocked = false;
        this.cdr.markForCheck();
      });
  }

  getToken(userInfo: any) {
    const pathUser = `/users/${userInfo?.uid}/profile/`;
    this.globalService.methodPost(environment.be_dev + `/api/v1/fibr-app/token`, { uid: userInfo.uid }
    ).subscribe((response) => {
      setTimeout(() => {
        userInfo.accessToken = response?.data?.accessToken;
        userInfo.refreshToken = response?.data?.refreshToken;
        userInfo.last_login = new Date();
        this.globalCrudService.modifyRefMergeCollection(userInfo,pathUser,'data');
        this.globalService.setRefDataToLocalstorage("user_profile", userInfo);
        this.saveLog(userInfo);
        this.router.navigateByUrl(this.returnUrl)
        .then(() => {
          window.location.reload();
        });
        this.isBlocked = false;
      }, 3000);
    },
      (err: any) => {
        if (this.countGetToken < 3) {
          setTimeout(() => {
            this.getToken(userInfo);
          }, 2000);
          this.countGetToken++;
        } else {
          this.authService.clearAuthToken();
            this.notif.showNotification('error', 'failed to get tokens!');
            setTimeout(() => {
              location.reload();
            }, 2000);
        }
      });
  }

  onClickGoogle() {
    // this.isProgressSpinner = true;
    this.authenticationService.signInWithPopup().then((result: any) => {
      const pathProfile = `users/${result?.uid}/profile`;
      this.getUser(pathProfile, result.isExists);
      this.globalService.isStatusLogin.subscribe((val: boolean) => { 
        this.isProgressSpinner = val;
        this.isBlocked = val;
      });
    }),
      takeUntil(this.unsubscribe as Observable<any>),
      finalize(() => {
        this.isProgressSpinner = false;
        this.isBlocked = false;
        this.cdr.markForCheck();
      });
  }

  getUser(pathProfile: any, isExists: any) {
    this.globalCrudService.getcollectionPromise(pathProfile).then((res) => {
      if (res.length > 0) {
        const userInfo: any = res[0];
        if (!res[0]) {
          this.getUser(pathProfile, isExists);
        } else {
          if (res[0]?.isDelete) {
            this.authService.clearAuthToken();
            this.notif.showNotification('error', 'User Not Found!');
            setTimeout(() => {
              location.reload();
            }, 3000);
          } else {
            if (isExists || environment.app_name == "fibr") {
              this.getToken(userInfo)
            } else {
              setTimeout(() => {
                this.isBlocked = true;
                this.globalService.setRefDataToLocalstorage("user_profile", userInfo);
                this.router.navigateByUrl(this.returnUrl)
                .then(() => {
                  window.location.reload();
                });
                this.isProgressSpinner = false;
              }, 6000);
            }
          }
        }
      } else {
        if (this.countLoopLogin < 4) {
          setTimeout(() => {
            this.getUser(pathProfile, isExists);
          }, 2000);
          this.countLoopLogin++;
        } else {
          this.authService.clearAuthToken();
          this.notif.showNotification('error', 'Login Errors!');
            setTimeout(() => {
              location.reload();
          }, 3000);
        }
      }
    });
  }

  onClickApple() {
    this.isProgressSpinner = true;
    this.authenticationService.signInWithApple().then((result) => {
      console.log("result: ", result);
      this.globalService.setRefDataToLocalstorage("user_profile", result);
      this.router.navigateByUrl(this.returnUrl)
      .then(() => {
        window.location.reload();
      });
      this.isProgressSpinner = false;
    }),
      takeUntil(this.unsubscribe as Observable<any>),
      finalize(() => {
        this.isProgressSpinner = false;
        this.cdr.markForCheck();
      });
  }

  onClickMicrosoft() {
    this.isProgressSpinner = true;
    this.authenticationService.signInWithMicrosoft().then((result) => {
      console.log("result: ", result);
      this.globalService.setRefDataToLocalstorage("user_profile", result);
      this.router.navigateByUrl(this.returnUrl)
      .then(() => {
        window.location.reload();
      });
      this.isProgressSpinner = false;
    }),
      takeUntil(this.unsubscribe as Observable<any>),
      finalize(() => {
        this.isProgressSpinner = false;
        this.cdr.markForCheck();
      });
  }

  onLanguageChange() {
    this.locale = this.selectedLanguage?.code;
    if (
      this.locale == undefined ||
      this.locale == null ||
      this.locale.length == 0
    ) {
      this.locale = "en";
    }
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(this.locale);
    this.sessionService.setItem("fibr-language", this.locale);
  }

  saveLog(userInfo: any) {
    const path = `/users/${userInfo?.uid}/login_history`;
    // console.log(this.ip_addrress,this.browserName,this.browserVer)
    const body = {
      uid: userInfo.uid,
      created_date: new Date(),
      // ip_addrress: this.ip_addrress,
      browser_name : this.browserName,
      browser_ver: this.browserVer
    };
    this.globalCrudService.createcollection(body,path);
    // console.log(body);
  }

  getIp() {
    this.http.get("http://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.ip_addrress =  res.ip;
    });
  }

  detectBrowserName() { 
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
   
  detectBrowserVersion(){
      // eslint-disable-next-line prefer-const
      let userAgent = navigator.userAgent, tem, 
      matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      
      if(/trident/i.test(matchTest[1])){
          tem =  /\brv[ :]+(\d+)/g.exec(userAgent) || [];
          return 'IE '+(tem[1] || '');
      }
      if(matchTest[1]=== 'Chrome'){
          tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
          if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      matchTest= matchTest[2]? [matchTest[1], matchTest[2]]: [navigator.appName, navigator.appVersion, '-?'];
      if((tem= userAgent.match(/version\/(\d+)/i))!= null) matchTest.splice(1, 1, tem[1]);
      return matchTest.join(' ');
  }
}
