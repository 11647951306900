import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { AngularFireAuth } from "@angular/fire/auth";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationFireGuard implements CanActivate {
  loggedIn = new BehaviorSubject<boolean>(false);
  loggedIn$ = this.loggedIn.asObservable();
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    public afAuth: AngularFireAuth) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.afAuth.authState.pipe(map((res:any): boolean => {
        // console.log('res', res)
        if (res && res.uid && (sessionStorage.getItem('accessToken') || localStorage.getItem('token'))){
          return true;
        }else{
          this.router.navigate([`/auth/login`]);
          return false;
        }
     }));
  }
  
}
