<div class="grid">
    <div class="col-12 md:col-6">
        <label class="text-bold text-large label-info">{{page_id}}</label>
        <span class="flex align-items-center justify-content-between pr-5">{{page_from}}</span> 
    </div>
    <div class="col-12 md:col-6">
        <div class="p-3 rounded border-full-grey">
            <span class="flex align-items-center justify-content-between">{{'disbursed_amount' | translate}}</span> 
            <label class="text-large text-blue">{{disbursed_amount}}</label>
        </div>
    </div>
    <!-- <div class="col-12 md:col-4">
        <div class="p-3 rounded border-full-grey">
            <span class="flex align-items-center justify-content-between">{{'total_transaction' | translate}}</span> 
            <label class="text-large text-blue">{{transaction_amount}}</label>
        </div>
    </div> -->
    <div class="col-12 col-scroll-list">
        <div *ngIf="disbursementHistoryData.length === 0; else transactionList">
            <div class="empty-transaction py-2 px-5 rounded border-full-grey col-12">
                <label class="align-items-center text-large justify-content-between">
                    <i class="flex justify-content-center mb-2 pi" [ngClass]="'pi-info-circle'"></i> 
                    {{'empty_transaction' | translate}}
                </label> 
            </div>
        </div>
        <ng-template #transactionList>
            <div *ngFor="let item of disbursementHistoryData">
              <div class="grid p-3 rounded border-full-grey mb-3">
                    <div _ngcontent-ybi-c302="" class="col-12 md:col-5 pb-0">
                        <span class="flex align-items-center justify-content-between">{{'disbursement_date' | translate}}</span> 
                        <label class="p-0 text-large">{{(item.modify_date['seconds']*1000) | date : 'dd/MM/yyyy HH:mm' }}</label>
                    </div>
                    <div class="col-12 md:col-5">
                        <span class="flex align-items-center justify-content-between">{{'disbursement_amount' | translate}}</span> 
                        <label class="text-large text-blue">{{formatCurrency(item.disbursed_amount)}}</label>
                    </div>
                    <div class="col-12 md:col-2">
                        <span class="flex align-items-center justify-content-between">Status</span> 
                        <span *ngIf="item.disbursement_status == 'success'" class="mr-2 p-tag p-component p-tag-success">
                            <span class="p-tag-value">{{'success' | translate}}</span>
                        </span>
                        <span *ngIf="item.disbursement_status == 'waiting'" class="mr-2 p-tag p-component p-tag-secondary">
                            <span class="p-tag-value">{{'waiting' | translate}}</span>
                        </span>
                        <span *ngIf="item.disbursement_status == 'failed'" class="mr-2 p-tag p-component p-tag-danger">
                            <span class="p-tag-value">{{'failed' | translate}}</span>
                        </span>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>