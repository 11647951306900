<div class="card md:w-7 lg:w-4 mx-auto md:my-6 p-6">
    <div class="grid">
        <div class="col-12">
            <a href="https://appfibr.io"><img src="/assets/logo_appfibr.svg" height="30px" /></a>
            <h4 class="mb-2">{{'profile.complete_profile' | translate}}</h4>
            <span>{{'profile.fill_form' | translate}}</span>
        </div>
    </div>

    <div class="grid py-5">
        <div class="col-12 mb-4">
            <label>{{'profile.phone' | translate}}</label>
            <!-- <input placeholder="0811xxxxxx" [(ngModel)]="userProfile.noHp"  (blur)="onBlur()" maxlength="15" class="p-inputtext p-component line-input py-3" type="number" /> -->
            <input placeholder="0811xxxxxx" [(ngModel)]="userProfile.noHp"  (blur)="onBlur()" maxlength="15" class="p-inputtext p-component line-input py-3"/>
            <sub class="text-blue"><i>*{{'profile.phone.validation' | translate}}</i></sub>

        </div>

        <div class="col-12 mb-4">
          <label>{{'profile.kode_referral' | translate}}</label>
          <input placeholder="" [(ngModel)]="userProfile.kodeReferal"  (blur)="onBlur()" class="p-inputtext p-component line-input py-3"/>
      </div>

        <div class="col-12 mb-4">
            <label>{{'question.team.work' | translate}}?</label>
            <p-selectButton [options]="teamOptions" [(ngModel)]="userProfile.teamOptions" [multiple]="false" optionLabel="name" optionValue="name" 
            (blur)="onBlur()" styleClass="multi-select-button mt-3">
            </p-selectButton>
        </div>

        <div class="col-12 mb-4">
            <label>{{'question.app' | translate}}?</label>
            <p-selectButton [options]="appOptions" [(ngModel)]="userProfile.appOptions" [multiple]="true" optionLabel="name" optionValue="name" 
            (blur)="onBlur()" styleClass="multi-select-button mt-3"></p-selectButton>
        </div>

        <div class="col-12">
            <p-button label="{{'letgo' | translate}}" (onClick)="saveQuestion()" styleClass="p-button p-button-lg w-100 py-3"></p-button>
        </div>
    </div>

    <div class="grid">
        <div class="col-12 text-center">
            <span>Copyright © 2023 <img src="/assets/logo_fibr_footer.svg" height="14px"> </span>
        </div>
    </div>
</div>