<!-- <form #f="ngForm" *ngIf="!isOrder"> -->
<form #f="ngForm" *ngIf="!isOrder && !this.config?.cartConfig?.startOfPage?.isLogin">
  <!-- <div class="splash-screen bg-app" [ngStyle]="{'background-image': 'url(' + appConfig.splash_screen + ')'}"> -->
  <div class="splash-screen bg-app">
    <div class="grid justify-content-center" *ngIf="!appConfig.is_paid">
        <div class="col-auto splash-powered">
            <p>Powered by</p>
            <a href="https://appfibr.io/"><img src="/assets/logo_appfibr.svg" alt="AppFibr Logo"/></a>
        </div>
    </div>
  </div>
   
  <div  class="mobile-comp mobile-form">
    <div class="p-grid p-fluid">
      <img class="img-app" [src]="logo" alt="AppFibr Logo"/>
      <div class="field" *ngFor="let item of elementForm">
        <!-- <ng-container *ngIf="item.field === 'customer_identity' && !this.config?.cartConfig?.startOfPage?.isLogin"> -->
        <ng-container *ngIf="item.field === 'customer_identity'">
            <span class="relative">
                <p class="form-label">{{item.label}}</p>
                <input [name]="item.field" type="text" id="inputtext" pInputText [(ngModel)]="item.value">
            </span>
        </ng-container>
        <!-- <ng-container *ngIf="item.field === 'password'">
          <span class="p-float-label">
              <input [name]="item.field" type="password" id="inputtext" pInputText [(ngModel)]="item.value">
              <label for="inputtext">{{item.label}}</label>
          </span>
        </ng-container> -->
      </div>
      <div class="grid">
        <div class="col-12 p-0">
          <button pButton pRipple class="p-button p-component w-100" (click)="onClickOrder(f)">Order Now</button>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="mobile-comp mobile-form px-0" *ngIf="isOrder && !dataHeaderOrder.isToCart" [ngClass]="{'horz' : layoutStyle.orientation === 'horizontal'}">
  <div class="comp-col comp-col-1">
    <div class="grid btn-back-top">
      <div class="col-12">
        <a href="javascript:;" (click)="goReOrder()" class="flex align-items-center"><i class="pi pi-arrow-left mr-2"></i>Back</a>
      </div>
    </div>

    <div class="comp-col shape-xs" *ngIf="dataMenu.length === 0" [ngClass]="{
      'comp-col-1': layoutStyle.column === '1',
      'loose': layoutStyle.padding === 'loose', 
      'rounded': layoutStyle.corners === 'rounded',
      'outline' : layoutStyle.border === 'outline',
      'float' : layoutStyle.border === 'float',
      'shape-xs' : layoutStyle.shape === '3:1',
      'shape-sm': layoutStyle.shape === '3:2',
      'shape-md': layoutStyle.shape === 'sq',
      'shape-lg': layoutStyle.shape === '3:4',
      'shape-xl': layoutStyle.shape === '3:5',
      'fs-md': layoutText.fontsize === 'medium',
      'fs-lg': layoutText.fontsize === 'large',
      'align-right': layoutText.aligment === 'right',
      'align-center': layoutText.aligment === 'center',
      'text-lower': layoutText.lettercase === 'lowercase',
      'text-upper': layoutText.lettercase === 'uppercase'
  }">
      <ng-container>
        <div class="card-grid card-grid-default" >
          <!-- <div class="card-img"> -->
            <img src="/assets/images/ani_empty.gif" alt="Icon Support">
          <!-- </div> -->
        </div>
      </ng-container>
    </div>
    <div class="comp-col shape-xs" *ngIf="dataMenu.length > 0" [ngClass]="{
              'comp-col-1': layoutStyle.column === '1',
              'comp-col-2': layoutStyle.column === '2', 
              'loose': layoutStyle.padding === 'loose', 
              'rounded': layoutStyle.corners === 'rounded',
              'outline' : layoutStyle.border === 'outline',
              'float' : layoutStyle.border === 'float',
              'shape-xs' : layoutStyle.shape === '3:1',
              'shape-sm': layoutStyle.shape === '3:2',
              'shape-md': layoutStyle.shape === 'sq',
              'shape-lg': layoutStyle.shape === '3:4',
              'shape-xl': layoutStyle.shape === '3:5',
              'fs-md': layoutText.fontsize === 'medium',
              'fs-lg': layoutText.fontsize === 'large',
              'align-right': layoutText.aligment === 'right',
              'align-center': layoutText.aligment === 'center',
              'text-lower': layoutText.lettercase === 'lowercase',
              'text-upper': layoutText.lettercase === 'uppercase'
          }">
          <ng-container *ngIf="layoutText.position === 'below';else overlay">
              <!-- <a href="javascript:;" class="card-grid card-grid-default" *ngFor="let field of data" (click)="onButtonDetailClick(field)"> -->
              <!-- <a href="javascript:;" class="card-grid card-grid-default" *ngFor="let field of dataMenu"> -->
              <div class="card-grid card-grid-default" *ngFor="let field of dataMenu">
                  <div class="card-img" *ngIf="field[config.dataContent?.images!] !== null" (click)="onButtonDetailClick(field)">
                    <img *ngIf="config.dataContent?.isImageUrl" class="img" [src]="field[config.dataContent?.images!] | gdriveConvert"
                      onerror="this.src='/assets/icon_no_image.png'">
                    <div *ngIf="!config.dataContent?.isImageUrl" class="img">
                        {{field[config.dataContent?.images!] !== null? ((field[config.dataContent?.images!] | stripTags) | slice:0:1) : ''}}
                    </div>
                  </div>
                  <div class="card-content">
                      <div (click)="onButtonDetailClick(field)">
                        <div class="card-title-container" *ngIf="field[config.dataContent?.header!] !== null || field[config.dataContent?.mainTitle!] !== null">
                          <div class="card-header text-base line-height-3" *ngIf="headerType !== 'number'">{{field[config.dataContent?.header!] | stripTags}}</div> 
                          <div class="card-header text-base line-height-3" *ngIf="headerType === 'number'">{{field[config.dataContent?.header!] | number}}</div> 
                          
                          <div class="card-title" *ngIf="mainTitleType !== 'number'">{{field[config.dataContent?.mainTitle!] | stripTags}}</div>
                          <div class="card-title" *ngIf="mainTitleType === 'number'">{{field[config.dataContent?.mainTitle!] | number}}</div>
                        </div>

                        <div class="card-desc" *ngIf="descriptionType !=='number'">{{field[config.dataContent?.description!] | stripTags}}</div>
                        <div class="card-desc" *ngIf="descriptionType ==='number'">{{field[config.dataContent?.description!] | number}}</div>

                        <div *ngIf="config.dataContent?.additionalImage" class="card-detail">
                          <div class="detail-left">
                            <div class="avatar">
                              <img *ngIf="config.dataContent?.isImageUrl && field[config.dataContent?.additionalImage!] !== null" class="ava-img"
                                [src]="field[config.dataContent?.additionalImage!] | gdriveConvert" onerror="this.src='/assets/icon_no_image.png'">
                              <div *ngIf="!config.dataContent?.isImageUrl && field[config.dataContent?.additionalImage!] !== null" class="ava-img">
                                {{field[config.dataContent?.additionalImage!] !== null?
                                ((field[config.dataContent?.additionalImage!]) | slice:0:1) : ''}}</div>
                              <div class="card-ava-container">
                                <div class="ava-title" *ngIf="additionalTextType !== 'number'">{{field[config.dataContent?.additionalText!] | stripTags}}</div>
                                <div class="ava-title" *ngIf="additionalTextType === 'number'">{{field[config.dataContent?.additionalText!] | number}}</div>
                                <div class="ava-detail" *ngIf="additionalLabelType !== 'number'">{{field[config.dataContent?.additionalLabel!] | stripTags}}</div>
                                <div class="ava-detail" *ngIf="additionalLabelType === 'number'">{{field[config.dataContent?.additionalLabel!] | number}}</div>
                              </div>
                            </div>
                          </div>
                          <div class="detail-right">
                            <div class="avatar" *ngIf="detailType !== 'number'"><div>{{field[config.dataContent?.details!] | stripTags}}</div></div>
                            <div class="avatar" *ngIf="detailType === 'number'"><div>{{field[config.dataContent?.details!] | number}}</div></div>
                          </div>
                        </div>
                      </div>

                      <div class="cart-inputnumber">
                        <p-inputNumber [(ngModel)]="field.qty" [showButtons]="true" buttonLayout="horizontal" spinnerMode="horizontal" 
                        inputId="horizontal" [min]="0" decrementButtonClass="border-right-none" incrementButtonClass="border-left-none" 
                        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" (onBlur)="cekItemBucket(dataMenu)">
                        </p-inputNumber>
                      </div>
                  </div>
                  <div class="card-overlay" >
                    <div class="label-overlay">
                      <div class="label-content top-right-label">
                        <div class="label-text" *ngIf="labelType !=='number'">{{field[config.dataContent?.label!] | stripTags}}</div>
                        <div class="label-text" *ngIf="labelType ==='number'">{{field[config.dataContent?.label!] | number}}</div>
                      </div>
                    </div>
                  </div>
              <!-- </a> -->
            </div>
          </ng-container>

          <ng-template #overlay>
              <!-- <a href="javascript:;" class="card-grid card-grid-overlay" *ngFor="let field of data" (click)="onButtonDetailClick(field)"> -->
              <a href="javascript:;" class="card-grid card-grid-overlay" *ngFor="let field of dataMenu">
                  <div class="card-img">
                      <img *ngIf="config.dataContent?.isImageUrl" class="img" [src]="field[config.dataContent?.images!] | gdriveConvert"
                          onerror="this.src='/assets/icon_no_image.png'">
                      <div *ngIf="!config.dataContent?.isImageUrl" class="img">{{field[config.dataContent?.images!] !==
                          null? ((field[config.dataContent?.images!] | stripTags) | slice:0:1) : ''}}</div>
                  </div>
                  
                  <div class="card-overlay">
                      <div class="label-overlay">
                          <div class="label-content top-right-label">
                            <div class="label-text" *ngIf="labelType !=='number'">{{field[config.dataContent?.label!] | stripTags}}</div>
                            <div class="label-text" *ngIf="labelType ==='number'">{{field[config.dataContent?.label!] | number}}</div>
                          </div>
                      </div>
                      <div class="card-content">
                          <div class="card-title-container">
                            <div class="card-header" *ngIf="headerType !== 'number'">{{field[config.dataContent?.header!] | stripTags}}</div> 
                            <div class="card-header" *ngIf="headerType === 'number'">{{field[config.dataContent?.header!] | number}}</div>

                            <div class="card-title" *ngIf="mainTitleType !== 'number'">{{field[config.dataContent?.mainTitle!] | stripTags}}</div>
                            <div class="card-title" *ngIf="mainTitleType === 'number'">{{field[config.dataContent?.mainTitle!] | number}}</div>
                          </div>
                          <div class="card-desc" *ngIf="descriptionType !=='number'">{{field[config.dataContent?.description!] | stripTags}}</div>
                          <div class="card-desc" *ngIf="descriptionType ==='number'">{{field[config.dataContent?.description!] | number}}</div>

                          <div *ngIf="config.dataContent?.additionalImage" class="card-detail">
                            <div class="detail-left">
                              <div class="avatar">
                                <img *ngIf="config.dataContent?.isImageUrl && field[config.dataContent?.additionalImage!] !== null" class="ava-img"
                                  [src]="field[config.dataContent?.additionalImage!] | gdriveConvert" onerror="this.src='/assets/icon_no_image.png'">
                                <div *ngIf="!config.dataContent?.isImageUrl && field[config.dataContent?.additionalImage!] !== null" class="ava-img">
                                  {{field[config.dataContent?.additionalImage!] !== null?
                                  ((field[config.dataContent?.additionalImage!]) | slice:0:1) : ''}}</div>
                                <div class="card-ava-container">
                                  <div class="ava-title" *ngIf="additionalTextType !== 'number'">{{field[config.dataContent?.additionalText!] | stripTags}}</div>
                                  <div class="ava-title" *ngIf="additionalTextType === 'number'">{{field[config.dataContent?.additionalText!] | number}}</div>
                                  <div class="ava-detail" *ngIf="additionalLabelType !== 'number'">{{field[config.dataContent?.additionalLabel!] | stripTags}}</div>
                                  <div class="ava-detail" *ngIf="additionalLabelType === 'number'">{{field[config.dataContent?.additionalLabel!] | number}}</div>
                                </div>
                              </div>
                            </div>
                            <div class="detail-right">
                              <div class="avatar" *ngIf="detailType !== 'number'"><div>{{field[config.dataContent?.details!] | stripTags}}</div></div>
                              <div class="avatar" *ngIf="detailType === 'number'"><div>{{field[config.dataContent?.details!] | number}}</div></div>
                            </div>
                          </div>
                      </div>
                  </div>
              </a>
          </ng-template>
    </div>
  </div>
</div>

<div class="mobile-comp mobile-form px-0" *ngIf="isOrder && dataHeaderOrder.isToCart && !dataHeaderOrder.isToSummary">
  <div class="comp-col comp-col-1">
    <div class="grid btn-back-top">
      <div class="col-12">
        <a href="javascript:;" (click)="onButtonBackOrderClick()" *ngIf="isOrder && dataHeaderOrder.isToCart"
        class="flex align-items-center"><i class="pi pi-arrow-left mr-2"></i> Back</a>
      </div>
    </div>

    <div class="card-list card-list-default cart-list" *ngFor="let det of dataDetailOrder">
      <div class="card-content">
        <div class="card-detail">
          <div class="detail-left">
            <div class="avatar">
              <img class="ava-img" [src]="det[sourceItemPicture] | gdriveConvert" onerror="this.src='/assets/icon_no_image.png'">
              <div class="card-ava-container text-left">
                <div class="ava-title">{{det[sourceItemName] | stripTags}}</div>
                <div class="ava-price">Rp. {{det[sourceItemPrice] | number}}</div>
              </div>
              <span class="cart-inputnumber mt-0">
                <p-inputNumber [(ngModel)]="det.qty" [showButtons]="true" buttonLayout="horizontal" spinnerMode="horizontal" inputId="horizontal"
                   decrementButtonClass="border-right-none" incrementButtonClass="border-left-none"  [min]="0"
                   incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" (onInput)="adjustQty($event.value,det)" 
                   (onKeyDown)="adjustQty($event.value,det)" (onBlur)="adjustQty($event.value,det)">
                  </p-inputNumber>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid my-3 px-2">
      <div class="col-6 p-1">Total</div>
      <div class="col-6 p-1 text-right font-bold">{{totalItem | number}}</div>
      <div class="col-6 p-1">{{PriceCalculation[0]?.label}} ({{PriceCalculation[0]?.percentage}}%)</div>
      <div class="col-6 p-1 text-right font-bold">{{value_1 | number}}</div>
      <div class="col-6 p-1">{{PriceCalculation[1]?.label}} ({{PriceCalculation[1]?.percentage}}%)</div>
      <div class="col-6 p-1 text-right font-bold">{{value_2 | number}}</div>
      <!-- <div class="col-6 p-1">Grand Total</div>
      <div class="col-6 p-1 text-right font-bold">{{grandTotal | number}}</div> -->
    </div>
  </div>

  <div class="grid mobile-bottommenu bottom-page">
    <div class="col-5 text-left align-self-center">
      <label>Grand Total</label>
      <label class="text-xl font-bold text-active">{{grandTotal | number}}</label>
    </div>
    <div class="col-7 text-right align-self-center">
      <button pButton pRipple class="p-button p-component w-100 px-2 p-button-sm" (click)="onConfirmPurchaseClick()">Confirm Purchase</button>
    </div>
  </div>

  <!-- <button pButton pRipple type="button" icon="pi pi-arrow-left" *ngIf="isOrder && dataHeaderOrder.isToCart"
  class="p-button-rounded btn-float btn-back" (click)="onButtonBackOrderClick()"></button> -->
</div>

<div class="mobile-comp mobile-form px-0" *ngIf="isOrder && dataHeaderOrder.isToCart && dataHeaderOrder.isToSummary">
  <div class="grid btn-back-top">
    <div class="col-12">
      <a href="javascript:;" (click)="onButtonBackPurchaseClick()" *ngIf="config.cartConfig?.inAppPayment === true && paymentInfo?.is_paid === false"
      class="flex align-items-center"><i class="pi pi-arrow-left mr-2"></i> Back</a>
    </div>
  </div>
  <div class="grid my-3 px-2" *ngIf="config.cartConfig?.inAppPayment === true && paymentInfo?.status_payment === 'PAID'">
    <div class="col-12 p-1 text-center">
      <img class="ava-img" [src]="paySuccess" onerror="this.src='/assets/icon_no_image.png'">
    </div>
    <div class="col-12 p-1 text-center font-bold">Payment Success</div>
  </div>
  <div class="grid my-3 px-2" *ngIf="config.cartConfig?.inAppPayment === true && paymentInfo?.status_payment === 'EXPIRED'">
    <div class="col-12 p-1 text-center">
      <img class="ava-img" [src]="payExpired" onerror="this.src='/assets/icon_no_image.png'">
    </div>
    <div class="col-12 p-1 text-center font-bold">Payment Expired</div>
  </div>
  <div class="grid my-3 px-2" *ngIf="config.cartConfig?.inAppPayment === true && paymentInfo?.status_payment === 'PAID' && (paymentInfo?.payment_invoice !== '' || paymentInfo?.payment_invoice !== null)">
    <div class="col-12 p-1 text-right font-bold">
      <a href="javascript:;" (click)="downloadInvoice()">Download Invoice</a>
    </div>
  </div>
  <div class="comp-col comp-col-1 mb-7">
    <div class="card-list card-list-default cart-list" *ngFor="let det of dataDetailOrder">
      <div class="card-content">
        <div class="card-detail">
          <div class="detail-left">
            <div class="avatar">
              <img class="ava-img" [src]="det[sourceItemPicture] | gdriveConvert" onerror="this.src='/assets/icon_no_image.png'">
              <div class="card-ava-container text-left">
                <div class="ava-title">{{det[sourceItemName] | stripTags}}</div>
                <div>{{det.qty | number}} x Rp. {{det[sourceItemPrice] | number}}</div>
              </div>
              <div class="card-ava-container text-right">
                <div class="ava-price">Rp. {{det.total_price | number}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid my-3 px-2">
      <div class="col-6 p-1">Total</div>
      <div class="col-6 p-1 text-right font-bold">{{totalItem | number}}</div>
      <div class="col-6 p-1">{{PriceCalculation[0]?.label}} ({{PriceCalculation[0]?.percentage}}%)</div>
      <div class="col-6 p-1 text-right font-bold">{{value_1 | number}}</div>
      <div class="col-6 p-1">{{PriceCalculation[1]?.label}} ({{PriceCalculation[1]?.percentage}}%)</div>
      <div class="col-6 p-1 text-right font-bold">{{value_2 | number}}</div>
    </div>
  </div>

  <div class="grid mt-2 sticky-bottom">
    <div class="col-6 p-1">Grand Total</div>
    <div class="col-6 p-1 text-right font-bold text-xl">{{grandTotal | number}}</div>
  </div>

  <div class="grid mobile-bottommenu bottom-page" *ngIf="actFrom === 'pwa' && config.cartConfig?.inAppPayment === true 
  && paymentInfo?.status_payment === 'EXPIRED'">
    <div class="col-4 align-self-center">
      <button pButton pRipple class="p-button p-button-secondary2 w-100 px-2" (click)="goReOrder()">Home</button>
    </div>
    <div class="col-8 align-self-center">
      <button pButton pRipple class="p-button p-component w-100 px-2" (click)="proceedToPayment('xendit')">Proceed to Payment</button>
    </div>
  </div>
  <div class="grid mobile-bottommenu bottom-page" *ngIf="actFrom === 'pwa' && paymentInfo?.status_payment !== 'EXPIRED'">
    <div class="col-12 align-self-center" *ngIf="config.cartConfig?.inAppPayment === false">
      <button pButton pRipple class="p-button p-component w-100 px-2" (click)="goReOrder()">Re - Order</button>
    </div>
    <!-- <div class="col-12 align-self-center" *ngIf="config.cartConfig?.inAppPayment === true">
      <button pButton pRipple class="p-button p-component w-100 px-2" *ngIf="paymentInfo?.is_paid === false"
      (click)="proceedToPayment('xendit')">Proceed to Payment</button>
      <button pButton pRipple class="p-button p-component w-100 px-2" *ngIf="paymentInfo?.is_paid === true"
      (click)="goReOrder()">Re - Order</button>
    </div> -->
    <div class="col-12 align-self-center" *ngIf="config.cartConfig?.inAppPayment === true && paymentInfo?.is_paid === true">
      <button pButton pRipple class="p-button p-component w-100 px-2" *ngIf="paymentInfo?.is_paid === true"
      (click)="goReOrder()">Re - Order</button>
    </div>
    <div class="col-12 align-self-center" *ngIf="config.cartConfig?.inAppPayment === true && paymentInfo?.is_paid === false && !config.cartConfig?.inAppPaymentConfig?.usingCashPayment">
      <button pButton pRipple class="p-button p-component w-100 px-2"(click)="proceedToPayment('xendit')">Proceed to Payment</button>
    </div>
    <div class="col-6 align-self-center" *ngIf="config.cartConfig?.inAppPayment === true && paymentInfo?.is_paid === false && config.cartConfig?.inAppPaymentConfig?.usingCashPayment">
      <button style="font-size: small;" pButton pRipple class="p-button p-button-secondary2 w-100 px-2" (click)="proceedToPayment('cash')">Cash Payment</button>
    </div>
    <div class="col-6 align-self-center" *ngIf="config.cartConfig?.inAppPayment === true && paymentInfo?.is_paid === false && config.cartConfig?.inAppPaymentConfig?.usingCashPayment">
      <button style="font-size: small;" pButton pRipple class="p-button p-component w-100 px-2" (click)="proceedToPayment('xendit')">Online Payment</button>
    </div>
  </div>
  <div class="grid mobile-bottommenu bottom-page" *ngIf="actFrom !== 'pwa'">
    <div class="col-12 align-self-center">
      <button pButton pRipple class="p-button p-component w-100 px-2" (click)="goReOrder()">Re - Order</button>
    </div>
  </div>
</div>

<button pButton pRipple type="button" icon="pi pi-shopping-cart" *ngIf="isOrder && !dataHeaderOrder.isToCart"
  class="p-button-rounded btn-float btn-cart" (click)="onButtonCartClick()">
  <p-badge *ngIf="bucketStatus" severity="danger" class="notification-trigger__badge"></p-badge>
</button>

<div #mobileContent class="modal-mobile"></div>

<p-dialog [modal]="false" [appendTo]="mobileContent" [(visible)]="showDetailOrder" position="bottom" [draggable]="false" [resizable]="false">
  <ng-template pTemplate = "header">
      <span class="mx-auto">Details</span>
  </ng-template>
  <div class="mobile-comp py-0" *ngIf="isOrder">
    <div class="comp-col shape-md">
      <div class="card-grid card-grid-default" style="zoom:120%;">
        <div class="card-img" *ngIf="config.dataContent?.images! !== null">
          <img *ngIf="config.dataContent?.isImageUrl" class="img" [src]="imageData | gdriveConvert"
            onerror="this.src='/assets/icon_no_image.png'">
          <div *ngIf="!config.dataContent?.isImageUrl" class="img">{{imageData !== null? ((imageData) | slice:0:1) : ''}}</div>
        </div>
        <div class="card-content px-0">
            <div class="card-title-container">
              <div class="card-header white-space-normal text-base line-height-3" *ngIf="headerType !== 'number'">{{headerData | stripTags}}</div> 
              <div class="card-header white-space-normal text-base line-height-3" *ngIf="headerType === 'number'">{{headerData | number}}</div> 
              
              <div class="card-title white-space-normal" *ngIf="mainTitleType !== 'number'">{{mainTitleData | stripTags}}</div>
              <div class="card-title white-space-normal" *ngIf="mainTitleType === 'number'">{{mainTitleData | number}}</div>
            </div>

            <div class="card-desc" style="-webkit-line-clamp: unset;" *ngIf="descriptionType !=='number'">{{descriptionData | stripTags}}</div>
            <div class="card-desc" style="-webkit-line-clamp: unset;" *ngIf="descriptionType ==='number'">{{descriptionData | number}}</div>

            <div class="cart-inputnumber">
              <p-inputNumber [(ngModel)]="qtyData" [showButtons]="true" buttonLayout="horizontal" spinnerMode="horizontal" inputId="horizontal" [min]="0"
                decrementButtonClass="border-right-none" incrementButtonClass="border-left-none" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                (onBlur)="onButtonDetailCartClick(fieldDetail,qtyData); cekItemBucket(dataMenu)">
              </p-inputNumber>
            </div>
        </div>
        <div class="card-overlay" >
          <div class="label-overlay">
            <div class="label-content top-right-label">
              <div class="label-text" *ngIf="labelType !=='number'">{{labelData | stripTags}}</div>
              <div class="label-text" *ngIf="labelType ==='number'">{{labelData | number}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-dialog>