/* eslint-disable @typescript-eslint/no-explicit-any */
import { DatePipe } from "@angular/common";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Confirmation, SelectionModel, ToastNotificationService } from "@core-fibr/shared";
import { ConfirmationService, MenuItem } from "primeng/api";
import { Subject } from "rxjs";
import { configMobile } from "../../../interfaces/global";
import { GlobalcrudService } from "../../../services/crud/globalcrud.service";
import { GlobalService } from "../../../services/global.service";
import { DomSanitizer  } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import { environment } from "@core-fibr/auth";
import * as CryptoJS from 'crypto-js';
@Component({
  selector: "ui-fibr-page-details",
  templateUrl: "./page-details.component.html",
  styleUrls: ["./page-details.component.scss"],
})
export class PageDetailsComponent implements OnInit {
  @Input() config: configMobile = {};
  @Input() data: any;
  @Input() params: any;
  @Input() actFrom: any;
  @Input() isTemplate: boolean | undefined;
  @Input() itemPages: MenuItem[] = [];
  @Input() itemSelected: any;
  

  @Output() buttonOnclick = new EventEmitter<any>();
  @Output() buttonFloatClick = new EventEmitter<any>();
  @Output() buttonTypeButtonClick = new EventEmitter<any>();
  @Output() buttonRedirect = new EventEmitter<any>();
  @Output() buttonRedirectWithParam = new EventEmitter<any>();

  details: any;
  sectionDetails: any;
  linkToDetails!: string;
  islinkToDetails!: boolean;
  isUrl = false;
  user: any = {};
  userProject: any = {};
  isPreviewOneRow = false;
  date!: string;
  pipe = new DatePipe('en-US');
  confirmationTemplate: Confirmation | undefined;
  tablePath = "";
  fields: SelectionModel[] = [];
  sectionDetail: any = [];
  is_typeButton = false;
  buttonProperty: any;
  // typeButton: any;
  viewUrl: any;
  dataButton: any = [];
  column_data: any;
  column_type: any;

  dataHeaderOrder:any;
  dataDetailOrder:any;
  paymentDesc = '';
  paymentAmount = 0;
  paymentBtn = '';
  indexButton:any;
  paymentInfo:any;
  pageId:any;
  myWindow: any;
  paySuccess = '/assets/images/payment/icon_success.svg';
  payFailed = '/assets/images/payment/icon_failed.svg';
  payExpired = '/assets/images/payment/icon_expired.svg';

  constructor(
    private globalService: GlobalService,
    private globalServiceCrud: GlobalcrudService,
    private cs: ConfirmationService,
    private toastNotificationService: ToastNotificationService,
    private sanitizer: DomSanitizer ,
    private http:HttpClient,
  ) {}

  ngOnInit(): void {
    // console.log(this.data)
    this.islinkToDetails = this.config?.dataContent?.linkToDetails == "default";
    this.user = this.globalService.getCurrReffDataParsed("user_profile");
    this.userProject = this.globalService.getCurrReffDataParsed("user_project");
    /**kondisi detail event dari list data */
    if (!this.islinkToDetails && this.data) {
      // console.log('satu')
      if (this.config?.dataContent?.linkToDetails == "detail_page") {
        this.linkToDetails = this.config?.dataContent?.linkToDetailsConfig?.pageId ?? "";
        this.details = this.itemPages.find((x: any) => x.id == this.linkToDetails);
        this.sectionDetails = this.details?.state?.dataContent?.sectionDetails;
        this.getColumns(this.data);
        if (this.itemSelected != undefined) {
          this.pageId = this.itemSelected?.id;
        } else {
          this.pageId = this.details.id;
        }
      }
      if (this.config?.dataContent?.linkToDetails == "page_type") {
        const pageMenu = this.itemPages.find((x: any) => x.id == this.config.dataContent?.linkToDetailsConfig?.pageId);
        if (this.config.dataContent?.linkToDetailsConfig?.relationWithField == false) {
          this.buttonRedirect.emit(pageMenu);
        } 
        if (this.config.dataContent?.linkToDetailsConfig?.relationWithField == true) {
          this.buttonRedirectWithParam.emit({parent: this.config, child: pageMenu, from: 'link_detail', data: this.data})
        }
      }
      if (this.config?.dataContent?.linkToDetails == "link_type") {
        this.goToTypeLink(this.config.dataContent?.linkToDetailsConfig,this.data);
        this.buttonRedirect.emit(this.itemSelected)
      }
    }
    /**kondisi detail event dari itempage atau menu page */
    if (this.islinkToDetails && !this.data) {
      // console.log('kedua ini')
      this.pageId = this.itemSelected?.id;
        this.globalService.appConfig.subscribe((val: any) => {
          const tableName :any =  this.config?.dataContent?.contentDataSource;
          let urlColumn = '';
          if (this.isTemplate) {
            urlColumn = `/templates/${this.params.appid}/tables/${tableName}/data`
          } else {
            if (this.config.dataContent?.isExternalDataSource) {
              urlColumn = this.globalService.getPathDataExternalSource(tableName,this.params)
            } else {
              urlColumn = `/users/${this.params.id}/data_projects/${this.params.appid}/tables/${tableName}/data`;
            }
          }

          if (this.config.dataContent?.isExternalDataSource) {
            this.globalServiceCrud.colectionPromise(urlColumn).then((res) => {
              this.get2NdData(res)
            });
          } else {
            this.globalServiceCrud.dynamicColectionPromise(urlColumn).then((res) => {
              this.get2NdData(res)
            });
          }
        });
    }

    /**kondisi detail event dari itempage type detail and ada data di klik dari grid atau list dengan value linkToDetails = default */
    if (this.islinkToDetails && this.data) {
        if (this.config?.layoutDesign?.layoutTemplate !== 'details') {
          // console.log('tiga',this.itemSelected)
          this.linkToDetails = this.config?.dataContent?.linkToDetailsConfig?.pageId ?? "";
          this.details = this.itemPages.find((x: any) => x.id == this.linkToDetails);
          this.sectionDetails = this.details?.state?.dataContent?.sectionDetails;
          this.getColumns(this.data);
        } else {
          // console.log('empat', this.data,this.itemSelected,this.config)
          this.data = {};
          this.globalService.appConfig.subscribe((val: any) => {
            const tableName :any =  this.config?.dataContent?.contentDataSource;
            let urlColumn = '';
            if (this.isTemplate) {
              urlColumn = `/templates/${this.params.appid}/tables/${tableName}/data`
            } else {
              if (this.config.dataContent?.isExternalDataSource) {
                urlColumn = this.globalService.getPathDataExternalSource(tableName,this.params)
              } else {
                urlColumn = `/users/${this.params.id}/data_projects/${this.params.appid}/tables/${tableName}/data`;
              }
            }

            if (this.config.dataContent?.isExternalDataSource) {
              this.globalServiceCrud.colectionPromise(urlColumn).then((res) => {
                this.get2NdData(res)
              });
            } else {
              this.globalServiceCrud.dynamicColectionPromise(urlColumn).then((res) => {
                this.get2NdData(res)
              });
            }
          });
        }
      }
  }

  get2NdData(res:any) {
    if (res.docs[0].data()) {
      this.data = res.docs[0]?.data();
      this.data.id = res.docs[0].id;
      this.globalService.convertDataOne(this.data);
      this.sectionDetails = this.config.dataContent?.sectionDetails;
      this.islinkToDetails = false;
      this.getColumns(this.data);
    }
  }

  getColumns(data: any){
    // console.log(this.data,this.config, this.sectionDetails)
    let urlColumn = '';
    const tblName: any = this.config.dataContent?.contentDataSource;
    let tableName = '';
    if (this.isTemplate) {
      tableName = tblName;
      urlColumn = `/templates/${this.params.appid}/tables`;
    } else {
      if (this.config.dataContent?.isExternalDataSource) {
        tableName = tblName+'_struct';
        urlColumn = this.globalService.getPathColumnExternalSource(tblName,this.params.id)
      } else {
        tableName = tblName;
        urlColumn = `/users/${this.params.id}/data_projects/${this.params.appid}/tables`;
      }
    }
    
    let arrData: any = [];
    if (data) arrData = Object.keys(data).map(key => ({field: key, value: data[key]}));
    if (this.config.dataContent?.isExternalDataSource) {
      this.globalServiceCrud.getCollectionwithidPromise(urlColumn, tableName)
      .then((response) => { 
        this.adjustField(response,arrData);
      });
    } else {
      this.globalServiceCrud.getDynamicCollectionwithidPromise(urlColumn, tableName)
      .then((response) => { 
        this.adjustField(response,arrData);
      });
    }
  }

  adjustField(response:any, arrData:any) {
    if (this.islinkToDetails) {
      this.fields=[];
      response?.columns.forEach( (col: any) => {
        if (col?.showInForm == undefined) col.showInForm = true;
        const dataVal = arrData.filter((x:any) => x.field == col.field);
        if (dataVal.length > 0) {
          this.fields.push({ field: col?.field, name: col?.header, type: col?.type, value: dataVal[0].value, showInForm: col?.showInForm});
        } else {
          this.fields.push({ field: col?.field, name: col?.header, type: col?.type, value: null, showInForm: col?.showInForm });
        }
      });
    } else {
      // console.log(this.sectionDetails)
      if ( response?.columns ) {
        this.fields=[];
        const colm = response?.columns;
        let data_type = '';
        let valDt = ''
        this.sectionDetails.forEach( (col: any, index:any) => {
          if (col.field != 'custom')
          {
            valDt = ''
            for (let index = 0; index < colm.length; index++) {
              if (col.field == colm[index].field) {
                for (let ixx = 0; ixx < arrData.length; ixx++) {
                  if (colm[index].field == arrData[ixx].field) {
                    valDt =  arrData[ixx].value;
                  }
                }
                data_type = colm[index].type;
              }
            }
          } else {
            valDt = col.custom;
          }
          
          if (col.type == 'button') {
            this.fields.push({ field: col.field, type: data_type, value: valDt, data_type: col.type, buttonProperty: col.buttonProperty,
              mediaProperty: {parentMedia: col.parentMedia,childMedia: col.childMedia}, index: index});
          } else {
            if (col.field != 'custom') {
              this.fields.push({ field: col.field, type: data_type, value: valDt, data_type: col.type, 
              mediaProperty: {parentMedia: col.parentMedia,childMedia: col.childMedia}, locationProperty: col.configLocation });
            } else {
              this.fields.push({ field: col.field, type: 'custom', value: valDt, data_type: col.type, 
              mediaProperty: {parentMedia: col.parentMedia,childMedia: col.childMedia} });
            }
          }
        });
      }
    }
    const getFieldLocation = this.fields.filter((x:any) => x.data_type == 'location');
    if (getFieldLocation.length > 0) {
      const position = {
        latitude: this.data[getFieldLocation[0].locationProperty.latitude], 
        longitude: this.data[getFieldLocation[0].locationProperty.longitude],
        access: 'page_detail'
      }
      this.globalService.setMapLocation(position);
    }
    this.fields = this.globalService.convertDataJsonArr(this.fields)
  }

  onButtonClick() {
    this.buttonOnclick.emit(false);
  }

  checkIsUrl(e: any) {
    if (!e || typeof e == "object") return false;
    if (e.substr(0, 4) == "http") {
      return true;
    } else {
      return false;
    }
  }

  onButtonFloatClick() {
    this.buttonFloatClick.emit({ showForm: true, actEdit: true, dataDetail: this.data });
    this.buttonOnclick.emit(false);
  }

  onTypeButtonClick(params: any, index?: any) {
    this.buttonProperty = params
    if (params.type == 'custom') {
      this.goToButtonCustom(params);
    }
    if (params.type == 'page') {
      this.goToButtonPage(params);
    }
    if (params.type == 'column') {
      this.goToButtonColumn(params);
    }
    if (params.type == 'in_app_payment') {
      this.goToButtonPayment(params, index);
    }
  }

  goToButtonCustom(buttonProperty:any) {
    this.is_typeButton = false;
    let viewUrl = '';
    if (buttonProperty.relationWithField) {
      if (buttonProperty.customValueField) {
        viewUrl = buttonProperty.url +'?'+ buttonProperty.queryParam +'='+ buttonProperty.relationField;
      } else {
        viewUrl = buttonProperty.url +'?'+ buttonProperty.queryParam +'='+ this.data[buttonProperty.relationField];
      }
    } else {
      viewUrl = buttonProperty.url
    } 
    this.showLink(viewUrl);
  }

  goToTypeLink(link:any, data: any) {
    let viewUrl = '';
    if (link.relationWithField) {
      if (link.queryParam != '') {
        viewUrl = link.url +'?'+ link.queryParam +'='+ data[link.relationField];
      } else {
        viewUrl = link.url +'/'+ data[link.relationField];
      }
    } else {
      viewUrl = link.url;
    } 
    this.showLink(viewUrl);
  }

  showLink(viewUrl: any) {
    this.globalService.changeBlockui(true);
    setTimeout(() => {
      let url = '';
      if (viewUrl.substring(0,4) != 'http') url = 'https://'+viewUrl; else url = viewUrl;
      window.open(url,'_blank');
      this.globalService.changeBlockui(false);
    }, 2000);
    // this.transform(viewUrl);
  }

  goToButtonPage(buttonProperty:any) {
    this.is_typeButton = true;
    const configLinkTable = this.itemPages.find((x: any) => x.id == buttonProperty.linkToPage);
    if (buttonProperty.relationWithField == false) {
      this.buttonRedirect.emit(configLinkTable);
    } else {
      this.buttonRedirectWithParam.emit({parent: buttonProperty, child: configLinkTable, from: 'button_page', data: this.data})
    }
  }

  goToButtonColumn(buttonProperty: any) {
    // console.log(buttonProperty, this.data, this.fields, this.config)
    const actFrom : any = "pwa";
    let path = '';
    if (this.isTemplate) {
      path = `/templates/${this.params.appid}/tables`;
    } else {
      path = `/users/${this.params.id}/data_projects/${this.params.appid}/tables`;
    } 
    const tableName: any = this.config?.dataContent?.contentDataSource
    this.globalServiceCrud.getDynamicCollectionwithidPromise(path,tableName)?.then((result:any) => {
      // console.log(result)

      for (let x=0; x < result.columns.length; x++) {
        if (result.columns[x].field == buttonProperty.columnData)
          this.column_type = result.columns[x].type; 
      }
      // console.log('data', this.column_data, 'type', this.column_type, 'res', result)
      if (this.column_type == 'link') {
        this.is_typeButton = false;
        if (this.column_data != '') {
          this.globalService.changeBlockui(true);
          setTimeout(() => {
            let url = '';
            if (this.column_data.substring(0,4) != 'http') url = 'https://'+this.column_data; else url = this.column_data;
            window.open(url,'_blank');
            this.globalService.changeBlockui(false);
          }, 2000);
        }
      } else {
        this.is_typeButton = true;
      }
    })
    const dataObject = Object.keys(this.data).map(key => ({field: key, value: this.data[key]}));
    for (let i=0; i<dataObject.length; i++) {
      if (dataObject[i].field == buttonProperty.columnData)
        this.column_data = dataObject[i].value; 
    }
  }

  transform(url: any) {
    // ref : https://stackblitz.com/edit/display-external-html-into-angular?file=src%2Fapp%2Fapp.component.ts
    // url = url + "&output=embed";
    // return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.http.get(url,{responseType:'text'}).subscribe(res=>{
      // console.log(url, res)
      this.viewUrl = this.sanitizer.bypassSecurityTrustHtml(res);
    })
  }

  goToButtonPayment(buttonProperty:any, index:any) {
    this.is_typeButton = true;
    this.onClickOrder(index);
    this.paymentDesc = buttonProperty.inAppPaymentDescription;
    this.paymentAmount = this.globalService.convertToNumber(this.data[buttonProperty.inAppPaymentAmountColumn]);
    this.paymentBtn = buttonProperty.label;
    this.indexButton = index;
    this.getDataCart(index, 'init');
  }

  onClickOrder(index:any) {
    this.dataHeaderOrder = this.globalService.getCurrReffDataParsed("button_order_"+this.pageId+"_"+index);
    if (this.dataHeaderOrder == null) {
      const dtId = this.getOrderId(index);
      const dtOrder: any = {};
      dtOrder.appid = this.params.appid;
      dtOrder.pageId = this.pageId;
      dtOrder.index = index;
      dtOrder.order_id = dtId;
      dtOrder.isOrder = true;
      dtOrder.isToSummary = false;
      dtOrder.isToPayment = false;
      dtOrder.docId = '';
      dtOrder.docTransId = '';
      this.dataHeaderOrder = dtOrder;
      this.globalService.setRefDataToLocalstorage("button_order_"+this.pageId+"_"+index, dtOrder);
      setTimeout(() => {
        this.dataHeaderOrder = this.globalService.getCurrReffDataParsed("button_order_"+this.pageId+"_"+index);
      },500);
    }
  }

  getOrderId(index:any) {
    const orderId: string = window.navigator.userAgent + this.params.appid + this.params.id + this.pageId + index + (new Date().getTime()).toString();
    const sh1OrderId = CryptoJS.SHA1(orderId).toString();
    return sh1OrderId;
  }

  onConfirmPurchaseClick(index:any) {
    const tablePath2 = `/users/${this.params.id}/detail_payment_transaction_fibr/${this.params.appid}/data`;
      this.cs.confirm({
        message: "Are you sure to confirm this "+this.paymentBtn+"?",
        header: "Confirm "+this.paymentBtn,
        rejectButtonStyleClass: "p-button-secondary",
        dismissableMask: true,
        accept:  async () => {
          this.dataHeaderOrder.isToPayment = true;
          this.globalService.setRefDataToLocalstorage("button_order_"+this.pageId+"_"+index, this.dataHeaderOrder);
          if (this.actFrom == 'pwa') {
            this.insertToDb(index)
          }
        },
        reject: () => {
          // 
        }
      });
  }

  async insertToDb(index:any) {
    // if (this.dataHeaderOrder.docTransId == '') {
    //   const tblName = 'button_transactions';
    //   const tablePath = `/users/${this.params.id}/data_projects/${this.params.appid}/tables/${tblName}/data`;
    //   const temp:any = {};
    //   temp.order_id = this.dataHeaderOrder.order_id;
    //   temp.customer_identity = '';
    //   temp.item_price = this.paymentAmount;
    //   temp.item_description = this.paymentDesc;
    //   temp.qty = 1;
    //   temp.total_price = this.paymentAmount;
    //   temp.modify_date = null;
    //   temp.created_by = '';
    //   temp.modified_by = '';
      
    //   const body1:any = {}
    //   const mappedForm1 = Object.keys(temp).map(key => ({field: key, value: temp[key]}));
    //   mappedForm1.forEach((v: { field: string | number; value: any; }) => body1[v?.field] = v?.value);
    //   const inv = await this.globalServiceCrud.createcollectionWithIdForCart(body1, tablePath)
    //   this.dataHeaderOrder.docTransId = inv?.id;                    
    //   this.globalService.setRefDataToLocalstorage("button_order_"+this.pageId+"_"+index, this.dataHeaderOrder);
    // }

    /**hit api untuk pop up */
    this.globalService.changeBlockui(true);
    let customer : any = null
    if (this.userProject != null || this.userProject != undefined) {
      if (this.userProject?.email != 'login_as_guest') {
        customer = {first_name: this.userProject?.fullname,last_name: this.userProject?.fullname,email: this.userProject?.email}
      }
    }
    setTimeout(() => {
      const bodyCart = {
        user_id: this.params.id,
        project_id: this.params.appid,
        order_id: this.dataHeaderOrder.order_id,
        // doc_id: this.dataHeaderOrder.docId, // untuk v1 , v2 tidak dipakai
        page_id: this.dataHeaderOrder.pageId,
        from: "detail_button",
        amount: this.paymentAmount,
        description: this.paymentDesc,
        customer: customer,
        payment_option: "xendit"
      }
      // this.globalService.methodPost(environment.payment + `/v1/invoices`, bodyCart)
      this.globalService.methodPost(environment.payment + `/v2/invoices`, bodyCart)
      .subscribe((resp) => {
        if (resp?.code == 200 && resp?.status == 'success') {
          this.dataHeaderOrder.docId = resp.data.doc_id;                                  
          this.globalService.setRefDataToLocalstorage("button_order_"+this.pageId+"_"+index, this.dataHeaderOrder);
          this.myWindow = this.globalService.windowsOpen(resp.data.invoice_url, '_blank', 500, 500);
          this.getDataCart(index,'purchase');
        } else {
          this.toastNotificationService.showNotification('error','Create Invoice Failed!');
        }
      },
      (err: any) => {
        this.toastNotificationService.showNotification('error',err?.error?.message);
        this.getClose(index);
      });
      this.globalService.changeBlockui(false);
    },1000)
  }

  // getDataCart_v1(index:any, act?:any) {
  //   this.dataHeaderOrder = this.globalService.getCurrReffDataParsed("button_order_"+this.pageId+"_"+index);
  //   const tablePath = `/users/${this.params.id}/detail_payment_transaction_fibr/${this.params.appid}/data`;
  //   const body = [{propertyName:'order_id', value: this.dataHeaderOrder?.order_id}]
  //   this.globalServiceCrud.getCollectionsWhere1(tablePath, body).subscribe((res:any) => {
  //     if (res.length == 0) {
  //       this.paymentInfo = {is_paid: false,status_payment: 'UNPAID'};
  //     } else {
  //       if (act == 'init') {
  //         if (this.paymentAmount != res[0].amount && this.dataHeaderOrder.order_id == res[0].order_id) {
  //           this.toastNotificationService.showNotification('info','there are still transactions that have not been completed!');
  //           this.paymentAmount = res[0].amount;
  //           this.paymentDesc = res[0].description;
  //         } 
  //         this.paymentInfo = res[0];
  //       }
  //       if (act == 'purchase') {
  //         this.paymentInfo = res[0];
  //       }
  //     }
  //   })
  // }

  /**payment v2 */
  getDataCart(index:any, act?:any) {
    this.dataHeaderOrder = this.globalService.getCurrReffDataParsed("button_order_"+this.pageId+"_"+index);
    if (this.dataHeaderOrder?.docId == '') {
      this.paymentInfo = {is_paid: false,status_payment: 'UNPAID'};
    } else {
      const tablePath = `/users/${this.params.id}/detail_payment_transaction_fibr/${this.params.appid}/data`;
      this.globalServiceCrud.getcollectionwithid(tablePath, this.dataHeaderOrder?.docId).subscribe((res:any) => { 
        if (res == undefined) {
          this.paymentInfo = {is_paid: false,status_payment: 'UNPAID'};
        } else {
          if (act == 'init') {
            if (this.paymentAmount != res.amount && this.dataHeaderOrder.order_id == res.order_id) {
              this.toastNotificationService.showNotification('info','there are still transactions that have not been completed!');
              this.paymentAmount = res.amount;
              this.paymentDesc = res.description;
            } 
            this.paymentInfo = res;
          }
          if (act == 'purchase') {
            this.paymentInfo = res;
          }
        }
      })
    }
  }

  getClose(index:any) {
    this.globalService.changeBlockui(true);
    localStorage.removeItem("button_order_"+this.pageId+"_"+index);
    setTimeout(() => {
      this.buttonRedirect.emit(this.itemSelected);
      this.globalService.changeBlockui(false);
    },1000);
  }

  downloadInvoice() {
    window.open(this.paymentInfo?.payment_invoice);
  }
}
