<!-- <ng-template pTemplate = "header">
<span class="p-dialog-title"><i class="pi pi-check-circle"></i>Upgrade Project Plan</span>
</ng-template> -->

<div class="grid">
    <div class="col-12">
        <p-table responsiveLayout="scroll" styleClass="p-datatable-gridlines pricing-plan-table">
            <ng-template pTemplate="footer">
                <tr>
                    <td></td>
                    <td>
                        <h4>Basic</h4>
                        <h2><small>Rp</small> 0 <small>,-</small></h2>
                        <p>{{'table.upgrade.you_are_on_basic' | translate}}</p>
                        <p-button styleClass="p-button p-button-secondary" label="{{'table.upgrade.you_are_on_basic' | translate}}" [disabled]="true"></p-button>
                    </td>
                    <td>
                        <h4>Pro</h4>
                        <!-- <h2><small>Rp</small> {{realPay.app_price | number}} <small>,-</small></h2> -->
                        <h2><small>Rp</small> {{app_priceInFront | number}} <small>,-</small></h2>
                        <p>{{paymentDesc}}</p>
                        <!-- <p-button styleClass="p-button p-button-primary" label="Coming Soon" icon="pi pi-chevron-right" 
                        iconPos="right" [disabled]="true"></p-button> -->
                        <!-- <p-button styleClass="p-button p-button-primary" label="Pro Version Available" icon="pi pi-chevron-right" 
                        iconPos="right" [disabled]="false" (onClick)="showUpgradeToPro=false; showOrderSummary=true"></p-button> -->
                        <p-button styleClass="p-button p-button-primary" label="Pro Version Available" icon="pi pi-chevron-right" 
                        iconPos="right" [disabled]="false" (onClick)="cekInvNoPaid()"></p-button>
                    </td>
                </tr>
                <tr>
                    <td class="section-title">{{'table.upgrade.project' | translate}}</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>{{'table.upgrade.total_page' | translate}}</td>
                    <td>{{max_pages_free}}</td>
                    <td>{{max_pages_pro}}</td>
                </tr>
                <tr>
                    <td>{{'table.upgrade.pub.web_app' | translate}}</td>
                    <td>Unlimited</td>
                    <td>Unlimited</td>
                </tr>
                <tr>
                    <td>{{'table.upgrade.pub.app' | translate}}</td>
                    <!-- <td><p class="label-info justify-content-center">1 <i class="pi pi-info-circle" pTooltip="One time publish quota in user"></i></p></td> -->
                    <td><p class="label-info justify-content-center">-</p></td>
                    <td><p class="label-info justify-content-center">Unlimited <i class="pi pi-info-circle" pTooltip="One active app with multiple publish"></i></p></td>
                </tr>
                <tr>
                    <td>{{'table.upgrade.public.user' | translate}}</td>
                    <td>Unlimited</td>
                    <td>Unlimited</td>
                </tr>
                <tr>
                    <td>{{'table.upgrade.private.user' | translate}}</td>
                    <td>10</td>
                    <td>100</td>
                </tr>
                <tr>
                    <td>{{'table.upgrade.rows_table' | translate}}</td>
                    <td>{{max_rows_free}}</td>
                    <td>{{max_rows_pro}}</td>
                </tr>
                <tr>
                    <td>{{'table.upgrade.table_limit' | translate}}</td>
                    <td>{{max_tables_free}}</td>
                    <td>{{max_tables_pro}}</td>
                </tr>
                <tr>
                    <td>{{'table.upgrade.total_rows' | translate}}</td>
                    <td>1000</td>
                    <td>Unlimited</td>
                </tr>
                <tr>
                    <td>{{'table.upgrade.max_upload' | translate}}</td>
                    <td>500 KB</td>
                    <td>10 MB</td>
                </tr>
                <tr>
                    <td>{{'table.upgrade.storage_capacity' | translate}}</td>
                    <td>1 GB</td>
                    <td>5 GB</td>
                </tr>
                <tr>
                    <td class="section-title">{{'table.upgrade.feature' | translate}}</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>{{'table.upgrade.push_notification' | translate}}</td>
                    <td>1</td>
                    <td>10</td>
                </tr>
                <tr>
                    <td>{{'table.upgrade.whitelabel' | translate}}</td>
                    <td>-</td>
                    <td><i class="pi pi-check-circle"></i></td>
                </tr>
                <!-- <tr>
                    <td>Custom Domain</td>
                    <td>-</td>
                    <td><span class="p-badge"> Coming Soon </span></td>
                </tr> -->
                <tr>
                    <td>{{'table.upgrade.user_login' | translate}}</td>
                    <td><i class="pi pi-check-circle"></i></td>
                    <td><i class="pi pi-check-circle"></i></td>
                </tr>
                <tr>
                    <td>{{'table.upgrade.list_grid' | translate}}</td>
                    <td><i class="pi pi-check-circle"></i></td>
                    <td><i class="pi pi-check-circle"></i></td>
                </tr>
                <tr>
                    <td>{{'table.upgrade.calendar_view' | translate}}</td>
                    <td>-</td>
                    <td><span class="p-badge"> {{'table.upgrade.comming_soon' | translate}} </span></td>
                </tr>
                <!-- <tr>
                    <td>In-app Chat</td>
                    <td>-</td>
                    <td><span class="p-badge"> Coming Soon </span></td>
                </tr> -->
                <!-- <tr>
                    <td>Video conference</td>
                    <td>-</td>
                    <td><span class="p-badge"> Coming Soon </span></td>
                </tr> -->
                <!-- <tr>
                    <td>QR Scanner</td>
                    <td>-</td>
                    <td><span class="p-badge"> Coming Soon </span></td>
                </tr> -->
                <tr>
                    <td>{{'table.upgrade.in_app_purchase' | translate}}</td>
                    <td>-</td>
                    <td><span class="p-badge"> {{'table.upgrade.comming_soon' | translate}} </span></td>
                </tr>
                <!-- <tr>
                    <td>Analytics</td>
                    <td>-</td>
                    <td><span class="p-badge"> Coming Soon </span></td>
                </tr> -->
                <!-- <tr>
                    <td>Publish to Play Store</td>
                    <td><i class="pi pi-check-circle"></i></td>
                    <td><i class="pi pi-check-circle"></i></td>
                </tr> -->
                <tr>
                    <td class="section-title">{{'table.upgrade.support' | translate}}</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>{{'table.upgrade.aab_to_playstore' | translate}}</td>
                    <td><i class="pi pi-check-circle"></i></td>
                    <td><i class="pi pi-check-circle"></i></td>
                </tr>
                <tr>
                    <td>{{'table.upgrade.wa_group_public' | translate}}</td>
                    <td><i class="pi pi-check-circle"></i></td>
                    <td><i class="pi pi-check-circle"></i></td>
                </tr>
                <tr>
                    <td>{{'table.upgrade.wa_group_private' | translate}}</td>
                    <td>-</td>
                    <td><i class="pi pi-check-circle"></i></td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<p-dialog [(visible)]="showOrderSummary" *ngIf="showOrderSummary" [modal]="true" [blockScroll]="true" [draggable]="false" [dismissableMask]="true" position="top" appendTo="body" [style]="{width: '500px'}" styleClass="footer-default">
    <ng-template pTemplate = "header">
        <span class="p-dialog-title"><i class="pi pi-credit-card"></i>{{'editor.summary.title' | translate}}</span>
    </ng-template>
    <form #f="ngForm">
      <div class="grid mt-3">
        <div class="col-12">
            <h5>{{'editor.summary.payment_method' | translate}}</h5>
            <p-selectButton name="paymentMethod" [options]="paymentMethod" [(ngModel)]="selectedPaymentMethod" optionLabel="label" optionValue="value" styleClass="option-button">
                <ng-template let-item pTemplate>
                    <p class="title">{{item.label}}</p>
                    <span class="sub-title">{{item.desc}}</span>
                </ng-template>
            </p-selectButton>
        </div>
      </div>
        <div class="grid">
            <div class="col-12">
                <h5>{{'editor.summary.pricing_package' | translate}}</h5>
                <p-selectButton name="paymentPackage" [options]="paymentPackage" [(ngModel)]="selectedpaymentPackage" (onChange)="chosePacket($event)" 
                optionLabel="description" optionValue="id" styleClass="option-button block w-100">
                    <ng-template let-item pTemplate>
                        <div class="flex align-items-center justify-content-between w-100">
                            <div class="flex flex-column">
                                <p class="title">{{item.description}}</p>
                                <span class="price">Rp <span class="p-tag p-component text-lg p-tag-warning"><span class="p-tag-value">{{item.app_price/item.package_month | number}}</span></span><label>{{item.app_price/item.package_month | number}}</label><label class="text-sm font-normal"> / Month</label></span>
                                <span class="sub-title">Charge Rp {{item.app_price | number}} for {{item.description}}</span>
                            </div>
                            <div class="flex align-items-center">
                                <p class="price line-through text-sm mr-2 mb-0">Rp {{priceMonth[0].app_price * item.package_month | number}}</p>
                                <span class="p-tag p-component text-sm p-tag-danger"><span class="p-tag-value">{{item.benefit}}</span></span>
                                <span class="p-tag p-component text-sm p-tag-secondary2"><span class="p-tag-value">{{item.benefit}}</span></span>
                            </div>
                        </div>
                    </ng-template>
                </p-selectButton>
            </div>
        </div>

        <div class="grid mt-3">
            <div class="col-12">
                <h5>{{'editor.summary.project_plan' | translate}}</h5>
                <div class="grid px-3">
                    <div class="col-6">
                        <label class="mb-0">{{config.data.e.app_name}} ({{'editor.summary.plan' | translate:{ day: max_day } }})</label>
                    </div>
                    <div class="col-6 text-right">
                        <span>Rp {{app_price | number}}</span>
                    </div>
                </div>
            </div>
        </div>

        <p-divider></p-divider>

        <div class="grid">
            <div class="col-12">
                <div class="grid">
                    <div class="col-6 pl-5">
                        <label class="label-info">Sub Total</label>
                    </div>
                    <div class="col-6 text-right">
                        <span>Rp {{app_price | number}}</span>
                    </div>
                </div>
                <div class="grid">
                    <div class="col-6 pl-5">
                        <label class="label-info">{{'editor.summary.tax' | translate}} ({{realPay.app_tax | number}} %)</label>
                    </div>
                    <div class="col-6 text-right">
                        <span>Rp {{app_price * (realPay.app_tax/100) | number}}</span>
                    </div>
                </div>
                <div class="grid">
                    <div class="col-6 pl-5">
                        <label class="label-info">{{'editor.summary.discount' | translate}}</label>
                    </div>
                    <div class="col-6 text-right">
                        <span>Rp {{((app_price + (app_price * (realPay.app_tax/100))) * (app_discount/100)) | number}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid">
            <div class="col-12">
                <div class="grid align-items-center bg-softblue">
                    <div class="col-6 p-3 pl-5">
                        <label class="label-info">Total</label>
                    </div>
                    <div class="col-6 p-3 text-right">
                        <h3 class="mb-0 text-blue"><small class="text-lg font-light">Rp</small> 
                            <!-- total = app_price + pajak - discount  -->
                            {{app_price + ((app_price * (realPay.app_tax/100)) - 
                            ((app_price + (app_price * (realPay.app_tax/100))) * (app_discount/100)))  | number}} 
                        <small class="text-lg font-light">,-</small></h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid">
            <div class="col-12">
                <div class="grid align-items-center">
                    <div class="col-3 p-3 pl-5">
                        <label class="label-info">{{'editor.summary.voucher' | translate}}</label>
                    </div>
                    <div class="col-6 p-3 text-right">
                        <input name="voucher" type="text" id="inputtext" pInputText [(ngModel)]="vaucher" placeholder="{{'editor.summary.voucher' | translate}}"
                        (keydown.enter)="cekPromo(vaucher)" (keydown.tab)="cekPromo(vaucher)">
                    </div>
                    <div class="col-3 p-3 text-right">
                        <p-button styleClass="p-button p-button-primary" label="{{'editor.summary.apply' | translate}}" (click)="cekPromo(vaucher)"></p-button>
                    </div>
                </div>
            </div>
        </div>

        <p-divider></p-divider>

        <div class="grid mb-3">
            <div class="col-6 p-0 pr-2">
                <p-button styleClass="p-button p-button-secondary" label="{{'project.popup.cancel' | translate}}" (click)="showUpgradeToPro=true; showOrderSummary=false"></p-button>
                <!-- <p-button styleClass="p-button p-button-secondary2" label="CC Form" (click)="showCreditCardForm=true; showOrderSummary=false"></p-button> -->
            </div>
            <div class="col-6 p-0 pr-2 text-right">
                <p-button styleClass="p-button p-button-primary" label="{{'editor.summary.checkout' | translate}}" icon="pi pi-chevron-right" iconPos="right" 
                (click)="updatePro(f);"></p-button>
            </div>
        </div>
    </form>
    <!-- <ng-template pTemplate="footer">
        <p-button styleClass="p-button p-button-secondary" label="Cancel" (click)="showUpgradeToPro=true; showOrderSummary=false"></p-button>
        <p-button styleClass="p-button p-button-primary" label="Checkout" icon="pi pi-chevron-right" iconPos="right" 
        (click)="updatePro();"></p-button>
    </ng-template> -->
</p-dialog>

<p-dialog [(visible)]="showInvoice" *ngIf="showInvoice" [modal]="true" [blockScroll]="true" [draggable]="false" [dismissableMask]="true" position="top" appendTo="body" [style]="{width: '800px'}" styleClass="footer-default">
    <ng-template pTemplate = "header">
        <span class="p-dialog-title"><i class="pi pi-file"></i>{{'invoice.title' | translate}}</span>
    </ng-template>

    <div class="grid">
        <div class="col-12 text-right mb-3">
            <p-button styleClass="p-button p-button-primary bg-softblue text-blue mr-2" (onClick)="print()" label="Print" icon="pi pi-print" iconPos="right"></p-button>
            <p-button styleClass="p-button p-button-primary bg-softblue text-blue" (onClick)="exportPdf()" label="Download" icon="pi pi-download" iconPos="right"></p-button>
        </div>
    </div>

    <div class="card box invoice-section">
        <div class="grid" [id]="'myinvoice'">
            <div class="col-12">
                <div class="grid align-items-center">
                    <div class="col-6">
                        <a href="https://appfibr.io/"><img src="/assets/logo_appfibr.svg" alt="AppFibr Logo" height="30"/></a>
                    </div>
                    <div class="col-6 text-right">
                        <h6 class="mb-0">{{'invoice.title' | translate}}</h6>
                        <h5 class="mt-0"><a href="javascript:;">{{invForm.order_id}}</a></h5>
                        <p>{{'invoice.billing.date' | translate}} <b>{{(invForm?.created_date['_seconds']*1000) | date : 'dd MMM yyyy' }}</b></p>
                        <p>{{'invoice.expire.billing.date' | translate}} <b>{{(invForm?.expire_invoice['_seconds']*1000) | date : 'dd MMM yyyy HH:mm' }}</b></p>
                    </div>
                </div>
            </div>
    
            <div class="col-12">
                <div class="grid">
                    <div class="col-12">
                        <div class="grid bg-softblue">
                            <div class="col-12 md:col-8 p-4">
                                <h6><b>{{'invoice.billed.to' | translate}}</b></h6>
                                <p class="mb-0">{{user.fullName}}</p>
                                <!-- <p>(Jl. M.T. Haryono No.27, RT.8/RW.9, Tebet)</p> -->
                            </div>
                            <div class="col-12 md:col-4 p-4">
                                <h6><b>Status</b></h6>
                                <span class="p-tag p-component" [ngClass]="invForm.status === 'paid' ? 'p-tag-success' : invForm.status === 'unpaid' ? 'p-tag-danger' : 'p-tag-secondary'">
                                    <span class="p-tag-value">{{'billing.status.' + invForm.status | translate}}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="grid">
                    <div class="col-12">
                        <p-divider styleClass="my-2"></p-divider>
                        <div class="grid">
                            <div class="col-3 md:col-6 pl-5">
                                <span><b>{{'invoice.item' | translate}}</b></span>
                            </div>
                            <div class="col-3 md:col-2 text-right">
                                <span><b>{{'invoice.cost' | translate}}</b></span>
                            </div>
                            <div class="col-3 md:col-2 text-right">
                                <span><b>{{'invoice.quantity' | translate}}</b></span>
                            </div>
                            <div class="col-3 md:col-2 text-right">
                                <span><b>{{'invoice.price' | translate}}</b></span>
                            </div>
                        </div>
                        <p-divider styleClass="my-2"></p-divider>
                    </div>
                </div>
                <div class="grid">
                    <div class="col-12">
                        <h6>{{'editor.summary.project_plan' | translate}}</h6>
                        <div class="grid">
                            <div class="col-3 md:col-6 pl-5">
                                <label class="label-info">{{invForm.project_name}} ({{'editor.summary.plan' | translate:{day: max_day} }})</label>
                            </div>
                            <div class="col-3 md:col-2 text-right">
                                <span>Rp {{invForm.app_price | number}}</span>
                            </div>
                            <div class="col-3 md:col-2 text-right">
                                <span>1</span>
                            </div>
                            <div class="col-3 md:col-2 text-right">
                                <span>Rp {{invForm.app_price | number}}</span>
                            </div>
                        </div>
                        <p-divider styleClass="my-2"></p-divider>
                    </div>
                </div>
            </div>

            <div class="col-8 md:col-6 ml-auto">
                <div class="grid">
                    <div class="col-6">
                        <label class="label-info">Sub Total</label>
                    </div>
                    <div class="col-6 text-right">
                        <span>Rp {{invForm.app_price | number}}</span>
                    </div>
                </div>
                <div class="grid">
                    <div class="col-6">
                        <label class="label-info">{{'editor.summary.tax' | translate}}</label>
                    </div>
                    <div class="col-6 text-right">
                        <span>Rp {{invForm.total_tax | number}}</span>
                    </div>
                </div>
                <div class="grid">
                    <div class="col-6">
                        <label class="label-info">{{'editor.summary.discount' | translate}}</label>
                    </div>
                    <div class="col-6 text-right">
                        <span>Rp {{invForm.total_disc | number}}</span>
                    </div>
                </div>
                <div class="grid p-2 mt-3 align-items-center bg-softblue">
                    <div class="col-6">
                        <label class="label-info">Total</label>
                    </div>
                    <div class="col-6 text-right">
                        <h5 class="mb-0 text-blue"><small class="text-lg font-light">Rp</small> {{invForm.gross_amount | number}} <small class="text-lg font-light">,-</small></h5>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button (onClick)="actPay(invForm,'pay')" styleClass="p-button p-button-primary" label="{{'invoice.pay' | translate}}" icon="pi pi-chevron-right" iconPos="right"></p-button>
        <p-button (onClick)="actPay(invForm,'close')" styleClass="p-button p-button-secondary" label="{{'invoice.close' | translate}}"></p-button>
    </ng-template>
</p-dialog>
