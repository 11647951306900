/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, NgZone, PLATFORM_ID } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { SessionService } from "../session.service";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { of, throwError, Observable } from "rxjs";
import { catchError, first } from "rxjs/operators";
import { ToastNotificationService } from "../toast-notification.service";
import { environment } from "libs/auth/src/environments/environment";
import firebase from "firebase";
import { GlobalService } from "../global.service";
import { merge } from "lodash";

@Injectable({
  providedIn: "root",
})
export class GlobalcrudService {
  private readonly API_URL: string ="https://sheets.googleapis.com/v4/spreadsheets";

  // auth_dynamic = new AngularFireAuth(
  //   environment.firebase,
  //   environment.firebase.projectId,
  //   PLATFORM_ID,
  //   this.zone,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null
  // );
  // firebase_dynamic = new AngularFirestore(
  //   environment.firebase,
  //   environment.firebase.projectId,
  //   false,
  //   null,
  //   PLATFORM_ID,
  //   this.zone,
  //   null,
  //   null,
  //   null
  // );

  // auth_sifa = new AngularFireAuth(
  //   environment.firebase_additional,
  //   environment.firebase_additional.projectId,
  //   PLATFORM_ID,
  //   this.zone,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null
  // );
  // firebase_additional = new AngularFirestore(
  //   environment.firebase_additional,
  //   environment.firebase_additional.projectId,
  //   false,
  //   null,
  //   PLATFORM_ID,
  //   this.zone,
  //   null,
  //   null,
  //   null
  // );

  auth_dynamic= new AngularFireAuth({},null,PLATFORM_ID,this.zone,null,null,null,null,null,null);
  firebase_dynamic= new AngularFirestore({},null,false,null,PLATFORM_ID,this.zone,null,null,null);
  auth_sifa= new AngularFireAuth({},null,PLATFORM_ID,this.zone,null,null,null,null,null,null);
  firebase_additional= new AngularFirestore({},null,false,null,PLATFORM_ID,this.zone,null,null,null);

  lastVisible!: any;
  size!: number;

  constructor(
    private firestore: AngularFirestore,
    private sessionService: SessionService,
    private zone: NgZone,
    private httpClient: HttpClient,
    public notificationService: ToastNotificationService, private globalService: GlobalService,
  ) {
    const env = this.globalService.decryptEnv(environment);
    
    this.auth_dynamic = new AngularFireAuth(
      env.firebase,env.firebase.projectId,PLATFORM_ID,this.zone,null,null,null,null,null,null
    );
    this.firebase_dynamic = new AngularFirestore(
      env.firebase,env.firebase.projectId,false,null,PLATFORM_ID,this.zone,null,null,null
    );
  
    this.auth_sifa = new AngularFireAuth(
      env.firebase_additional,env.firebase_additional.projectId,PLATFORM_ID,this.zone,null,null,null,null,null,null
    );
    this.firebase_additional = new AngularFirestore(
      env.firebase_additional,env.firebase_additional.projectId,false,null,PLATFORM_ID,this.zone,null,null,null
    );
  }

  initalAngularFire() {
    this.getcollectionwithid(
      "/users/gMmW1xuYOnhMR9cd6VfPlWics0a2/profile/",
      "data"
    ).subscribe(() => console.log());
  }

  createcollection(data: any, collection: string): any {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection(collection)
        .add(data)
        .then(
          () => {
            console.log();
          },
          (err) => reject(err)
        );
    });
  }

  createcollectionWithIdForCart( data: any, collection: string ): any {
    if (!data?.created_date) data["created_date"] = new Date();
    const idDoc = this.firestore.createId();
    data["id"] = idDoc;
    this.firestore
    .collection(collection)
    .doc(idDoc)
    .set(data)
    .then();

    return new Promise<any>((resolve, reject) => {
      return this.firestore
        .collection(collection)
        .doc(idDoc)
        .get()
        .toPromise()
        .then((_) => resolve(_));
    });
  }

  createcollectionWithId( data: any, collection: string ): any {
    if (!data?.created_date) data["created_date"] = new Date();
    let idDoc = this.firestore.createId();
    data["id"] = idDoc;
    return new Promise<any>((resolve, reject) => {
      return this.firestore
              .collection(collection)
              .doc(idDoc)
              .set(data)
              .then(
                (res) => {
                  resolve(res);
                },
                (err) => {
                  reject(err);
                  return false;
                }
              )
    })
  }

  createcollectionWithIdDummy( data: any, collection: string ): any {
    if (!data?.created_date) data["created_date"] = new Date();
    let idDoc = 'dummy';
    data["id"] = idDoc;
    return new Promise<any>((resolve, reject) => {
      return this.firestore
              .collection(collection)
              .doc(idDoc)
              .set(data)
              .then(
                (res) => {
                  resolve(res);
                },
                (err) => {
                  reject(err);
                  return false;
                }
              )
    })
  }

  createCollectionWithId_2( data: any, collection: string ) {
    if (!data?.created_date) data["created_date"] = new Date();
    const idDoc = this.firestore.createId();
    data["id"] = idDoc;
    return this.firestore
              .collection(collection)
              .add(data);
  }

  createcollectionwithdocumentname(
    data: any,
    collection: string,
    docname: string
  ): any {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection(collection)
        .doc(docname)
        .set(data)
        .then(
          () => {
            console.log();
          },
          (err) => reject(err)
        );
    });
  }

  createcollectionwithdocumentid(
    data: any,
    collection: string,
    docname: string
  ): any {
    return new Promise<any>((resolve, reject) => {
      return this.firestore
        .collection(collection)
        .doc(docname)
        .set(data)
        .then((data: any) => resolve(data));
    });
  }

  modifyCollection(data: any, path: string, docId: string) {
    return this.firestore.collection(path).doc(docId).set(data);
  }

  modifyRefCollection(data: any, path: string, docId: string) {
    return this.firestore.collection(path).doc(docId).ref.update(data);
  }

  modifyRefMergeCollection(data: any, path: string, docId: any) {
    return this.firestore.collection(path).doc(docId).ref.set(data, { merge: true });
  }

  modifyRefCollectionWithModifyDate(data: any, path: string, docId: string) {
    if (!data.modify_date) data["modify_date"] = new Date();
    return this.firestore.collection(path).doc(docId)
      .ref.set(data, { merge: true });
  }

  addColumnCollection(path: string, data: any) {
    this.firestore.collection(path).get();
  }

  deleteCollection(path: string, docId: string) {
    return this.firestore.collection(path).doc(docId).delete();
  }

  getcollection(collection: string) {
    return this.firestore.collection(collection).valueChanges();
  }

  getCollectionWithLimit(collection: string,limit:number) {
    return this.firestore.collection(collection, (ref) => ref.limit(limit)).valueChanges();
  }

  async getcollectiongroup(collection: string): Promise<any> {
    try {
      const res = await this.firestore.collectionGroup(collection, (ref) => ref
      .where("project_name", "!=", "")
      ).valueChanges().pipe(first()).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  getColectionWithBody(collection: string, body: any) {
    return this.firestore.collection(collection, (ref) =>
    ref.orderBy(body.sortField, body.sortOrder)).valueChanges();
  }

  colectionObservable(collection: string, field_name: string): Observable<any> {
    return this.firestore.collection(collection, (ref) => ref.orderBy(field_name, "desc")).valueChanges({ idField: 'id' });
  }

  getcollectionPromise(collection: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      return this.firestore
        .collection(collection)
        .valueChanges()
        .pipe(first())
        .toPromise()
        .then(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
            return false;
          }
        );
    });
  }

  colectionPromise(collection: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      return this.firestore
        .collection(collection)
        .get()
        .toPromise()
        .then((_) => resolve(_));
    });
  }

  getCollectionwithidPromise(
    collection: string,
    id: string
  ): Promise<any> {
    return this.firestore
      .collection(collection)
      .doc(id)
      .valueChanges()
      .pipe(first())
      .toPromise();
  }

  getCollectionWithId( collection: any, id: string ): any {
    return new Promise<any>((resolve, reject) => {
      return this.firestore
        .collection(collection)
        .doc(id)
        .get()
        .toPromise()
        .then((_) => resolve(_));
    });
  }

  get_id_collection(collection: string) {
    return this.firestore.collection(collection).get();
  }

  getonecollection(collection: string) {
    return this.firestore
      .collection(collection, (ref) => ref.limit(1))
      .valueChanges();
  }

  getcollectionwithid(collection: string, id: string) {
    return this.firestore.collection(collection).doc(id).valueChanges();
  }

  getonecollectionwithid(collection: string, id: string) {
    return this.firestore.collection(collection).doc(id).get();
  }

  getcollectionorderbydesc(collection: string, field_name: string) {
    return this.firestore
      .collection(collection, (ref) => ref.orderBy(field_name, "desc"))
      .valueChanges();
  }

  getCollectionOrderByDescWithFieldId(collection: string, field_name: string) {
    return this.firestore
      .collection(collection, (ref) => ref.orderBy(field_name, "desc"))
      .valueChanges({ idField: 'id' })
  }

  getcollectionorderbyasc(collection: string, field_name: string) {
    return this.firestore
      .collection(collection, (ref) => ref.orderBy(field_name, "asc"))
      .valueChanges();
  }

  getCollectionsWhere1(collection: string, body: any) {
    return this.firestore.collection(collection, (ref) => ref
      .where(body[0].propertyName, "==", body[0].value)
    )
    .valueChanges();
  }

  getCollectionPromiseOrderByAsc(collection: string, field_name: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      return this.firestore
        .collection(collection, (ref) => ref.orderBy(field_name, "asc"))
        .get()
        .toPromise()
        .then((_) => resolve(_));
    });
  }

  getCollectionPromiseOrderByDesc(collection: string, field_name: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      return this.firestore
        .collection(collection, (ref) => ref.orderBy(field_name, "desc"))
        .get()
        .toPromise()
        .then((_) => resolve(_));
    });
  }

  getGoogleSheetsbyList(sheetid: string) {
    const authtoken: string = this.sessionService.getItem("accessToken");
    return this.httpClient
      .get(`https://sheets.googleapis.com/v4/spreadsheets/${sheetid}/edit`, {
        headers: new HttpHeaders({
          Authorization: `Bearer ${authtoken}`,
        }),
      })
      .pipe(
        catchError((error: any) =>
          of(this.handleError(error, this.notificationService))
        )
      );
  }

  getGoogleSheetsbyId(sheetid: string) {
    const authtoken: string = this.sessionService.getItem("accessToken");
    const url = `https://sheets.googleapis.com/v4/spreadsheets`;
    return this.httpClient
      .get(url, {
        headers: new HttpHeaders({
          Authorization: `Bearer ${authtoken}`,
        }),
      })
      .pipe(
        catchError((error: any) =>
          of(this.handleError(error, this.notificationService))
        )
      );
  }

  getcollection_sifa(collection: string) {
    return this.firebase_additional.collection(collection).valueChanges();
  }

  getcollectionwithid_sifa(collection: string, id: string) {
    return this.firebase_additional
      .collection(collection)
      .doc(id)
      .valueChanges();
  }

  generateId() {
    let text = "";
    const possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 20; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  get(url: string) {
    return this.httpClient.get(url);
  }

  get_form_urlencoded(url: string) {
    return this.httpClient.get(url, { responseType: "blob" });
  }

  post(url: string, body: any) {
    return this.httpClient.post(url, body);
  }

  post_form_urlencoded(url: string, body: any) {
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    return this.httpClient.post(url, body, {
      responseType: "blob",
      headers,
    });
  }

  handleError(error: HttpErrorResponse, notif: ToastNotificationService) {
    let errorMessage = "Unknown error!";
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    notif.showNotification("error", errorMessage);
    return throwError(errorMessage);
  }

  dynamicFirebase(config: any, isAuth: boolean = false, credential: any) {
    this.auth_dynamic = new AngularFireAuth(
      config,
      config.projectId,
      PLATFORM_ID,
      this.zone,
      null,
      null,
      null,
      null,
      null,
      null
    );
    this.firebase_dynamic = new AngularFirestore(
      config,
      config.projectId,
      false,
      null,
      PLATFORM_ID,
      this.zone,
      null,
      null,
      null
    );
    if (isAuth) {
      this.auth_dynamic.signInWithEmailAndPassword(
        credential.email,
        credential.password
      );
    }
  }

  dynamicColection(collection: string) {
    return this.firebase_dynamic.collection(collection).valueChanges();
  }

  dynamicColectionWithLimit(collection: string, limit:any) {
    return this.firebase_dynamic.collection(collection, (ref) => ref.limit(limit)).valueChanges();
  }

  dynamicColectionWithBody(collection: string, body: any) {
    return this.firebase_dynamic.collection(collection, (ref) =>
    ref.orderBy(body.sortField, body.sortOrder)).valueChanges();
  }

  getDynamicCollectionLazy(collection: string, body: any, externalData?: any) {
    if (externalData) {
      return new Promise<any>((resolve, reject) => {
        return this.firestore.collection(collection, (ref) => ref
          .orderBy(body.sortField, body.sortOrder).limit(body.rows)
        )
        .get().toPromise().then((_) => resolve(_));
      });
    } else {
      return new Promise<any>((resolve, reject) => {
        return this.firebase_dynamic.collection(collection, (ref) => ref
          .orderBy(body.sortField, body.sortOrder).limit(body.rows)
        )
        .get().toPromise().then((_) => resolve(_));
      });
    }
  }

  getDynamicCollectionLazyAction(collection: string, body: any, externalData?: any) {
    if (externalData) {
      return new Promise<any>((resolve, reject) => {
        return this.firestore.collection(collection, (ref) => ref.orderBy(body.sortField, body.sortOrder))
        .get().toPromise().then((_) => resolve(_));
      });
    } else {
      const next = this.firebase_dynamic.collection(collection, (ref) => ref
        .orderBy(body.sortField, body.sortOrder)
        .limit(body.limit)
      )
      .get().toPromise()

      return new Promise<any>((resolve, reject) => {
        return next.then(
          (docsnapshot) => {
            const lastVisible = docsnapshot.docs[docsnapshot.docs.length-1];
            // console.log("last", lastVisible)
  
            this.firebase_dynamic.collection(collection, (ref) => ref
              .orderBy(body.sortField, body.sortOrder).limit(body.rows)
              .startAfter(lastVisible)
            )
            .get().toPromise().then((_) => resolve(_));
          },
          (err) => reject(err)
        );
      })
    }
  }

  dynamicColectionSeacrh(collection: string, body: any, externalData?: any) {
    // console.log(body)
    if (externalData) {
        const next = this.firestore
        .collection(collection, (ref) =>
          ref.orderBy(body.sortField, body.sortOrder)
        )
        .get()
        .toPromise();
      return new Promise<any>((resolve, reject) => {
        return next.then(
          (docsnapshot) => {
            const docs: firebase.firestore.QueryDocumentSnapshot<unknown>[] = [];
            let length = 0;
            let fltr: any = [];
            // if (body.filters.length == 1 && body.filters[0].propertyName == 'created_date') {
            if (body.reqFrom == 'config') {
              docsnapshot.docs.map((doc) => {
                if (body.filters.length == 0) {
                  docs.push(doc);
                  this.lastVisible = doc;
                  length++;
                } else {
                  if (body.filters[0].columnType == undefined || body.filters[0].columnType == '') {
                    docs.push(doc);
                    this.lastVisible = doc;
                    length++;
                  } else if (body.filters[0].columnType != 'date' && body.filters[0].columnType != 'time') {
                    const data: any = doc.data();
                    const loop = Object.keys(data);
                    fltr = [];
                    body.filters.map((filter: any) => {
                      for (let index = 0; index < loop.length; index++) {
                        const key = loop[index];
                          if ( filter.propertyName != '' && key == filter.propertyName && data[key] !== null ) {
                            const val = data[key];
                            if (val.toString().toLocaleLowerCase().includes(filter.value.toLocaleLowerCase())) {
                              fltr.push({propertyName: filter.propertyName,value: filter.value,matchMode: filter.matchMode});
                            }
                          }
                      }
                    });
                    const len = body.filters.length;
                    let n = 0;
                    for (let index = 0; index < len; index++) {
                      const element = body.filters[index];
                      if (fltr.find((x: any) => x.propertyName == element.propertyName))
                        n += 1;
                    }
                    if (n === len) {
                      docs.push(doc);
                      this.lastVisible = doc;
                      length++;
                    }
                  } else {
                    const xData: any = doc.data();
                    const dateNow = new Date();
                    let existDate: any = '';
                    let dataDate: any = '';
                    if (body.filters[0]?.filterDate?.status) {
                      let startDate = 0;
                      let endDate = 0;
                      if (typeof(xData[body.filters[0].propertyName]) == 'object' && xData[body.filters[0].propertyName] != null) {
                        if (body.filters[0].filterDate.type == 'greater than') {
                          startDate = new Date(body.filters[0].filterDate.start).getTime() + (23.99*60*60*1000);
                          if ((xData[body.filters[0].propertyName]['seconds']*1000) > startDate) {
                            docs.push(doc);
                            this.lastVisible = doc;
                            length++;
                          }
                        }
                        if (body.filters[0].filterDate.type == 'greater than equal') {
                          startDate = new Date(body.filters[0].filterDate.start).getTime();
                          if ((xData[body.filters[0].propertyName]['seconds']*1000) >= startDate) {
                            docs.push(doc);
                            this.lastVisible = doc;
                            length++;
                          }
                        }
                        if (body.filters[0].filterDate.type == 'less than') {
                          startDate = new Date(body.filters[0].filterDate.start).getTime();
                          if ((xData[body.filters[0].propertyName]['seconds']*1000) < startDate) {
                            docs.push(doc);
                            this.lastVisible = doc;
                            length++;
                          }
                        }
                        if (body.filters[0].filterDate.type == 'less than equal') {
                          startDate = new Date(body.filters[0].filterDate.start).getTime() + (23.99*60*60*1000);
                          if ((xData[body.filters[0].propertyName]['seconds']*1000) <= startDate) {
                            docs.push(doc);
                            this.lastVisible = doc;
                            length++;
                          }
                        }
                        if (body.filters[0].filterDate.type == 'equal to') {
                          const startDay = new Date(body.filters[0].filterDate.start).getTime();
                          const endDay = new Date(body.filters[0].filterDate.start).getTime() + (23.99*60*60*1000);
                          if ((xData[body.filters[0].propertyName]['seconds']*1000) >= startDay && (xData[body.filters[0].propertyName]['seconds']*1000) <= endDay) {
                            docs.push(doc);
                            this.lastVisible = doc;
                            length++;
                          }
                        }
                        if (body.filters[0].filterDate.type == 'range') {
                          startDate = new Date(body.filters[0].filterDate.start).getTime();
                          endDate = new Date(body.filters[0].filterDate.end).getTime() + (23.99*60*60*1000);
                          if ((xData[body.filters[0].propertyName]['seconds']*1000) >= startDate && (xData[body.filters[0].propertyName]['seconds']*1000) <= endDate) {
                            docs.push(doc);
                            this.lastVisible = doc;
                            length++;
                          }
                        }
                      }
                    } else {
                      if (body.filters[0].propertyName && typeof(xData[body.filters[0].propertyName]) == 'object' && xData[body.filters[0].propertyName] != null) {
                        if (body.filters[0].value == 'current day') {
                          existDate = this.globalService.cekDate(dateNow, 'day');
                          dataDate = this.globalService.cekDataDate(xData[body.filters[0].propertyName], 'day');
                          if (existDate == dataDate) {
                            docs.push(doc);
                            this.lastVisible = doc;
                            length++;
                          }
                        } else if (body.filters[0].value == 'current month') {
                          existDate = this.globalService.cekDate(dateNow, 'mounth');
                          dataDate = this.globalService.cekDataDate(xData[body.filters[0].propertyName], 'mounth');
                          if (existDate == dataDate) {
                            docs.push(doc);
                            this.lastVisible = doc;
                            length++;
                          }
                        } else if (body.filters[0].value == 'current year') {
                          existDate = this.globalService.cekDate(dateNow, 'year');
                          dataDate = this.globalService.cekDataDate(xData[body.filters[0].propertyName], 'year');
                          if (existDate == dataDate) {
                            docs.push(doc);
                            this.lastVisible = doc;
                            length++;
                          }
                        }
                      }
                    }
                  }
                }
              }); 
            } else {
              docsnapshot.docs.map((doc) => {
                // console.log(doc.id)
                fltr = [];
                body.filters.map((filter: any) => {
                  if (filter.propertyName == "uid") {
                    if (doc.id
                        .toLocaleLowerCase()
                        .includes(filter.value.toLocaleLowerCase())
                    ) {
                      fltr.push({
                        propertyName: filter.propertyName,
                        value: filter.value,
                        matchMode: filter.matchMode,
                      });
                    }
                  } else {
                    const data: any = doc.data();
                    const loop = Object.keys(data);
                    for (let index = 0; index < loop.length; index++) {
                      const key = loop[index];
                      if (body.filters.length > 1 ) {
                        if ( key != "created_date" && key != "modify_date" && data[key] !== null ) {
                          const val = data[key];
                          if (val.toString().toLocaleLowerCase().includes(filter.value.toLocaleLowerCase())) {
                            fltr.push({
                              propertyName: filter.propertyName,
                              value: filter.value,
                              matchMode: filter.matchMode,
                            });
                          }
                        }
                      } else {
                        if ( filter.propertyName != '' && key == filter.propertyName && data[key] !== null ) {
                          const val = data[key];
                          if (val.toString().toLocaleLowerCase().includes(filter.value.toLocaleLowerCase())) {
                            fltr.push({
                              propertyName: filter.propertyName,
                              value: filter.value,
                              matchMode: filter.matchMode,
                            });
                          }
                        } else {
                          if ( key != "created_date" && key != "modify_date" && data[key] !== null ) {
                            const val = data[key];
                            if (val.toString().toLocaleLowerCase().includes(filter.value.toLocaleLowerCase())) {
                              fltr.push({
                                propertyName: filter.propertyName,
                                value: filter.value,
                                matchMode: filter.matchMode,
                              });
                            }
                          }
                        }
                      }
                    }
                  }
                });
                const len = body.filters.length;
                let n = 0;
                for (let index = 0; index < len; index++) {
                  const element = body.filters[index];
                  if (fltr.find((x: any) => x.propertyName == element.propertyName))
                    n += 1;
                }
                if (n === len) {
                  docs.push(doc);
                  this.lastVisible = doc;
                  length++;
                }
              });
            }
            // console.log(docs)
            resolve({ docs: docs, size: length});
          },
          (err) => reject(err)
        );
      });
    } else {
        const next = this.firebase_dynamic
        .collection(collection, (ref) =>
          ref.orderBy(body.sortField, body.sortOrder)
        )
        .get()
        .toPromise();
      return new Promise<any>((resolve, reject) => {
        return next.then(
          (docsnapshot) => {
            const docs: firebase.firestore.QueryDocumentSnapshot<unknown>[] = [];
            let length = 0;
            let fltr: any = [];
            // if (body.filters.length == 1 && body.filters[0].propertyName == 'created_date') {
            if (body.reqFrom == 'config') {
              docsnapshot.docs.map((doc) => {
                if (body.filters.length == 0) {
                  docs.push(doc);
                  this.lastVisible = doc;
                  length++;
                } else {
                  if (body.filters[0].columnType == undefined || body.filters[0].columnType == '') {
                    docs.push(doc);
                    this.lastVisible = doc;
                    length++;
                  } else if (body.filters[0].columnType != 'date' && body.filters[0].columnType != 'time') {
                    const data: any = doc.data();
                    const loop = Object.keys(data);
                    fltr = [];
                    body.filters.map((filter: any) => {
                      for (let index = 0; index < loop.length; index++) {
                        const key = loop[index];
                          if ( filter.propertyName != '' && key == filter.propertyName && data[key] !== null ) {
                            const val = data[key];
                            if (val.toString().toLocaleLowerCase().includes(filter.value.toLocaleLowerCase())) {
                              fltr.push({propertyName: filter.propertyName,value: filter.value,matchMode: filter.matchMode});
                            }
                          }
                      }
                    });
                    const len = body.filters.length;
                    let n = 0;
                    for (let index = 0; index < len; index++) {
                      const element = body.filters[index];
                      if (fltr.find((x: any) => x.propertyName == element.propertyName))
                        n += 1;
                    }
                    if (n === len) {
                      docs.push(doc);
                      this.lastVisible = doc;
                      length++;
                    }
                  } else {
                    const xData: any = doc.data();
                    const dateNow = new Date();
                    let existDate: any = '';
                    let dataDate: any = '';
                    if (body.filters[0]?.filterDate?.status) {
                      let startDate = 0;
                      let endDate = 0;
                      if (typeof(xData[body.filters[0].propertyName]) == 'object' && xData[body.filters[0].propertyName] != null) {
                        if (body.filters[0].filterDate.type == 'greater than') {
                          startDate = new Date(body.filters[0].filterDate.start).getTime() + (23.99*60*60*1000);
                          if ((xData[body.filters[0].propertyName]['seconds']*1000) > startDate) {
                            docs.push(doc);
                            this.lastVisible = doc;
                            length++;
                          }
                        }
                        if (body.filters[0].filterDate.type == 'greater than equal') {
                          startDate = new Date(body.filters[0].filterDate.start).getTime();
                          if ((xData[body.filters[0].propertyName]['seconds']*1000) >= startDate) {
                            docs.push(doc);
                            this.lastVisible = doc;
                            length++;
                          }
                        }
                        if (body.filters[0].filterDate.type == 'less than') {
                          startDate = new Date(body.filters[0].filterDate.start).getTime();
                          if ((xData[body.filters[0].propertyName]['seconds']*1000) < startDate) {
                            docs.push(doc);
                            this.lastVisible = doc;
                            length++;
                          }
                        }
                        if (body.filters[0].filterDate.type == 'less than equal') {
                          startDate = new Date(body.filters[0].filterDate.start).getTime() + (23.99*60*60*1000);
                          if ((xData[body.filters[0].propertyName]['seconds']*1000) <= startDate) {
                            docs.push(doc);
                            this.lastVisible = doc;
                            length++;
                          }
                        }
                        if (body.filters[0].filterDate.type == 'equal to') {
                          const startDay = new Date(body.filters[0].filterDate.start).getTime();
                          const endDay = new Date(body.filters[0].filterDate.start).getTime() + (23.99*60*60*1000);
                          if ((xData[body.filters[0].propertyName]['seconds']*1000) >= startDay && (xData[body.filters[0].propertyName]['seconds']*1000) <= endDay) {
                            docs.push(doc);
                            this.lastVisible = doc;
                            length++;
                          }
                        }
                        if (body.filters[0].filterDate.type == 'range') {
                          startDate = new Date(body.filters[0].filterDate.start).getTime();
                          endDate = new Date(body.filters[0].filterDate.end).getTime() + (23.99*60*60*1000);
                          if ((xData[body.filters[0].propertyName]['seconds']*1000) >= startDate && (xData[body.filters[0].propertyName]['seconds']*1000) <= endDate) {
                            docs.push(doc);
                            this.lastVisible = doc;
                            length++;
                          }
                        }
                      }
                    } else {
                      if (body.filters[0].propertyName && typeof(xData[body.filters[0].propertyName]) == 'object' && xData[body.filters[0].propertyName] != null) {
                        if (body.filters[0].value == 'current day') {
                          existDate = this.globalService.cekDate(dateNow, 'day');
                          dataDate = this.globalService.cekDataDate(xData[body.filters[0].propertyName], 'day');
                          if (existDate == dataDate) {
                            docs.push(doc);
                            this.lastVisible = doc;
                            length++;
                          }
                        } else if (body.filters[0].value == 'current month') {
                          existDate = this.globalService.cekDate(dateNow, 'mounth');
                          dataDate = this.globalService.cekDataDate(xData[body.filters[0].propertyName], 'mounth');
                          if (existDate == dataDate) {
                            docs.push(doc);
                            this.lastVisible = doc;
                            length++;
                          }
                        } else if (body.filters[0].value == 'current year') {
                          existDate = this.globalService.cekDate(dateNow, 'year');
                          dataDate = this.globalService.cekDataDate(xData[body.filters[0].propertyName], 'year');
                          if (existDate == dataDate) {
                            docs.push(doc);
                            this.lastVisible = doc;
                            length++;
                          }
                        }
                      }
                    }
                  }
                }
              }); 
            } else {
              docsnapshot.docs.map((doc) => {
                // console.log(doc.id)
                fltr = [];
                body.filters.map((filter: any) => {
                  if (filter.propertyName == "uid") {
                    if (doc.id
                        .toLocaleLowerCase()
                        .includes(filter.value.toLocaleLowerCase())
                    ) {
                      fltr.push({
                        propertyName: filter.propertyName,
                        value: filter.value,
                        matchMode: filter.matchMode,
                      });
                    }
                  } else {
                    const data: any = doc.data();
                    const loop = Object.keys(data);
                    for (let index = 0; index < loop.length; index++) {
                      const key = loop[index];
                      if (body.filters.length > 1 ) {
                        if ( key != "created_date" && key != "modify_date" && data[key] !== null ) {
                          const val = data[key];
                          if (val.toString().toLocaleLowerCase().includes(filter.value.toLocaleLowerCase())) {
                            fltr.push({
                              propertyName: filter.propertyName,
                              value: filter.value,
                              matchMode: filter.matchMode,
                            });
                          }
                        }
                      } else {
                        if ( filter.propertyName != '' && key == filter.propertyName && data[key] !== null ) {
                          const val = data[key];
                          if (val.toString().toLocaleLowerCase().includes(filter.value.toLocaleLowerCase())) {
                            fltr.push({
                              propertyName: filter.propertyName,
                              value: filter.value,
                              matchMode: filter.matchMode,
                            });
                          }
                        } else {
                          if ( key != "created_date" && key != "modify_date" && data[key] !== null ) {
                            const val = data[key];
                            if (val.toString().toLocaleLowerCase().includes(filter.value.toLocaleLowerCase())) {
                              fltr.push({
                                propertyName: filter.propertyName,
                                value: filter.value,
                                matchMode: filter.matchMode,
                              });
                            }
                          }
                        }
                      }
                    }
                  }
                });
                const len = body.filters.length;
                let n = 0;
                for (let index = 0; index < len; index++) {
                  const element = body.filters[index];
                  if (fltr.find((x: any) => x.propertyName == element.propertyName))
                    n += 1;
                }
                if (n === len) {
                  docs.push(doc);
                  this.lastVisible = doc;
                  length++;
                }
              });
            }
            // console.log(docs)
            resolve({ docs: docs, size: length});
          },
          (err) => reject(err)
        );
      });
    }
  }

  dynamicColectionSeacrhWithUser(collection: string, body: any, userProj: any) {
    const next = this.firebase_dynamic
      .collection(collection, (ref) =>
        ref.where('created_by', "==", userProj)
      )
      .get()
      .toPromise();
    return new Promise<any>((resolve, reject) => {
      return next.then(
        (docsnapshot) => {
          const docs: firebase.firestore.QueryDocumentSnapshot<unknown>[] = [];
          let length = 0;
          let fltr: any = [];
          // if (body.filters.length == 1 && body.filters.propertyName == 'created_date') {
          //   docsnapshot.docs.map((doc) => {
          //     docs.push(doc);
          //     this.lastVisible = doc;
          //     length++;
          //   }); 
          // } else {
            docsnapshot.docs.map((doc) => {
              // console.log(doc.id)
              fltr = [];
              body.filters.map((filter: any) => {
                if (filter.propertyName == "uid") {
                  if (doc.id
                      .toLocaleLowerCase()
                      .includes(filter.value.toLocaleLowerCase())
                  ) {
                    fltr.push({
                      propertyName: filter.propertyName,
                      value: filter.value,
                      matchMode: filter.matchMode,
                    });
                  }
                } else {
                  const data: any = doc.data();
                  const loop = Object.keys(data);
                  for (let index = 0; index < loop.length; index++) {
                    const key = loop[index];
                    if ( key != "created_date" && key != "modify_date" && data[key] !== null) {
                      const val = data[key];
                      if (val
                          .toString()
                          .toLocaleLowerCase()
                          .includes(filter.value.toLocaleLowerCase())
                      ) {
                        fltr.push({
                          propertyName: filter.propertyName,
                          value: filter.value,
                          matchMode: filter.matchMode,
                        });
                      }
                    }
                  }
                }
              });
              const len = body.filters.length;
              let n = 0;
              for (let index = 0; index < len; index++) {
                const element = body.filters[index];
                if (fltr.find((x: any) => x.propertyName == element.propertyName))
                  n += 1;
              }
              // if (docs.length < body.rows && n === len) {
              if (n === len) {
                docs.push(doc);
                this.lastVisible = doc;
                length++;
              }
            });
          // }
          resolve({ docs: docs, size: length});
        },
        (err) => reject(err)
      );
    });
  }

  dynamicColectionSeacrhWithFilter(collection: string, body: any, column: any, valColumn: any) {
    console.log(body)
    const next = this.firebase_dynamic
      .collection(collection, (ref) =>
        ref.where(column, "==", valColumn)
      )
      .get()
      .toPromise();
    return new Promise<any>((resolve, reject) => {
      return next.then(
        (docsnapshot) => {
          const docs: firebase.firestore.QueryDocumentSnapshot<unknown>[] = [];
          let length = 0;
          let fltr: any = [];
          docsnapshot.docs.map((doc) => {
            // console.log(doc.id)
            fltr = [];
            body.filters.map((filter: any) => {
              if (filter.propertyName == "uid") {
                if (doc.id
                    .toLocaleLowerCase()
                    .includes(filter.value.toLocaleLowerCase())
                ) {
                  fltr.push({
                    propertyName: filter.propertyName,
                    value: filter.value,
                    matchMode: filter.matchMode,
                  });
                }
              } else {
                const data: any = doc.data();
                const loop = Object.keys(data);
                for (let index = 0; index < loop.length; index++) {
                  const key = loop[index];
                  if ( key != "created_date" && key != "modify_date" && data[key] !== null) {
                    const val = data[key];
                    if (val
                        .toString()
                        .toLocaleLowerCase()
                        .includes(filter.value.toLocaleLowerCase())
                    ) {
                      fltr.push({
                        propertyName: filter.propertyName,
                        value: filter.value,
                        matchMode: filter.matchMode,
                      });
                    }
                  }
                }
              }
            });
            const len = body.filters.length;
            let n = 0;
            for (let index = 0; index < len; index++) {
              const element = body.filters[index];
              if (fltr.find((x: any) => x.propertyName == element.propertyName))
                n += 1;
            }

            // if (docs.length < body.rows && n === len) {
              if (n === len) {
                docs.push(doc);
                this.lastVisible = doc;
                length++;
              }
          });
          resolve({ docs: docs, size: length});
        },
        (err) => reject(err)
      );
    });
  }

  dynamicColectionButtonType(collection: string,body: any) {
      if (body.field == '') 
      {
        return this.firebase_dynamic
        .collection(collection).get();
        // .collection(collection, (ref) => ref.orderBy(body.sortField, body.sortOrder)).get();
      } else {
        return this.firebase_dynamic
        .collection(collection, (ref) => ref.where(body.field, "==", body.value)).get();
        // .collection(collection, (ref) => ref.where(body.field, "==", body.value).orderBy(body.sortField, body.sortOrder)).get();
      }
  }

  dynamicColectionSort(collection: string, body: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      return this.firebase_dynamic
        .collection(collection, (ref) =>
        ref.orderBy(body.sortField, body.sortOrder))
        .get()
        .toPromise()
        .then((_) => resolve(_));
    });
  }

  dynamicColectionSortWithUser(collection: string, body: any, userProj: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      return this.firebase_dynamic
        .collection(collection, (ref) =>
        ref
        .where('created_by', "==", userProj)
        .orderBy(body.sortField, body.sortOrder))
        .get()
        .toPromise()
        .then((_) => resolve(_));
    });
  }

  dynamicColectionSortWithFilter(collection: string, body: any, column: any, valColumn: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      return this.firebase_dynamic
        .collection(collection, (ref) =>
        ref
        .where(column, "==", valColumn)
        .orderBy(body.sortField, body.sortOrder))
        .get()
        .toPromise()
        .then((_) => resolve(_));
    });
  }

  dynamicColectionPromise(collection: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      return this.firebase_dynamic
        .collection(collection)
        .get()
        .toPromise()
        .then((_) => resolve(_));
    });
  }

  dynamicColectionGet(collection: string) {
    return this.firebase_dynamic.collection(collection).get();
  }

  dynamicColectionwhereclause(collection: string, where: any) {
    return this.firebase_dynamic
      .collection(collection, (ref) =>
        ref.where(where.field, "==", where.value)
      )
      .valueChanges();
  }

  dynamicColectionwhereclausePromise(
    collection: string,
    where: any
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      return this.firebase_dynamic
        .collection(collection, (ref) =>
          ref.where(where.field, "==", where.value)
        )
        .get()
        .toPromise()
        .then((_) => resolve(_));
    });
  }
  
  getDynamicCollectionWithIdPromise(
    collection: string,
    id: string
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
    return this.firebase_dynamic
      .collection(collection)
      .doc(id)
      .get()
      .toPromise()
      .then((_) => resolve(_));
    });
  }

  dynamicColectionwhereclauseGet(collection: string, where: any) {
    return this.firebase_dynamic
      .collection(collection, (ref) =>
        ref.where(where.field, "==", where.value)
      )
      .get();
  }

  dynamicColectiondaterangeGet(collection: string, where: any) {
    return this.firebase_dynamic
      .collection(collection, (ref) =>
        ref
          .where(where.field, ">=", new Date(where.start))
          .where(where.field, "<=", new Date(where.end))
      )
      .get();
  }

  dynamicColectionorderbydesc(
    collection: string,
    field_name: string,
    sort: any
  ) {
    return this.firebase_dynamic
      .collection(collection, (ref) => ref.orderBy(field_name, sort))
      .valueChanges();
  }

  get_id_dynamicColection(collection: string) {
    return this.firebase_dynamic.collection(collection).get();
  }

  get_id_dynamicColection_with_order(
    collection: string,
    field_name: string,
    sort: any
  ) {
    // console.log("sort: ", sort);
    // console.log("field_name: ", field_name);
    // console.log("collection: ", collection);
    return this.firebase_dynamic
      .collection(collection, (ref) => ref.orderBy(field_name, sort))
      .get();
  }

  get_search_dynamicColection(collection: string, value: any, field: string) {
    return this.firebase_dynamic
      .collection(collection, (ref) =>
        ref
          .orderBy(field)
          .startAt(value.toUpperCase())
          .endAt(value.toUpperCase() + "\uf8ff")
      )
      .get();
  }

  get_search_equal_dynamicColection(
    collection: string,
    value: any,
    field: string
  ) {
    return this.firebase_dynamic
      .collection(collection, (ref) =>
        ref.where(field, "in", this.findPermutations(value.toUpperCase()))
      )
      .get();
  }

  get_search_notequal_dynamicColection(
    collection: string,
    value: any,
    field: string
  ) {
    return this.firebase_dynamic
      .collection(collection, (ref) =>
        ref.where(field, "!=", value.toUpperCase())
      )
      .get();
  }

  findPermutations = (string: string) => {
    if (!string || typeof string !== "string") {
      return "Please enter a string";
    } else if (string.length < 2) {
      return string;
    }

    const permutationsArray: any = [];

    for (let i = 0; i < string.length; i++) {
      const char = string[i];
      const remainingChars =
        string.slice(0, i) + string.slice(i + 1, string.length);
      for (const permutation of this.findPermutations(remainingChars)) {
        permutationsArray.push(char + permutation);
      }
    }
    return permutationsArray;
  };

  get_search_in_dynamicColection(
    collection: string,
    value: any,
    field: string
  ) {
    return this.firebase_dynamic
      .collection(collection, (ref) =>
        ref.where(field, "in", this.findPermutations(value))
      )
      .get();
  }

  dynamicCreatecollection(data: any, collection: string): any {
    if (!data?.created_date) data["created_date"] = new Date();
    if (!data?.modify_date) data["modify_date"] = new Date();
    return new Promise<any>((resolve, reject) => {
      return this.firebase_dynamic
        .collection(collection)
        .add(data)
        .then(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
            return false;
          }
        );
    });
  }

  dynamicCreatecollectionWithId( data: any, collection: string ): any {
    if (!data?.created_date) data["created_date"] = new Date();
    let idDoc = this.firestore.createId();
    data["id"] = idDoc;
    return new Promise<any>((resolve, reject) => {
      return this.firebase_dynamic
              .collection(collection)
              .doc(idDoc)
              .set(data)
              .then(
                (res) => {
                  resolve(res);
                },
                (err) => {
                  reject(err);
                  return false;
                }
              )
    })
  }

  dynamicCreatecollectionWithIdDummy( data: any, collection: string ): any {
    if (!data?.created_date) data["created_date"] = new Date();
    let idDoc = 'dummy';
    data["id"] = idDoc;
    return new Promise<any>((resolve, reject) => {
      return this.firebase_dynamic
              .collection(collection)
              .doc(idDoc)
              .set(data)
              .then(
                (res) => {
                  resolve(res);
                },
                (err) => {
                  reject(err);
                  return false;
                }
              )
    })
  }

  createDynamicCollectionWithId( data: any, collection: string ) {
    if (!data?.created_date) data["created_date"] = new Date();
    let idDoc = this.firestore.createId();
    data["id"] = idDoc;
    return this.firebase_dynamic
              .collection(collection)
              .add(data);
  }

  createDynamicCollectionWithId2( data: any, collection: string ) {
    if (!data?.created_date) data["created_date"] = new Date();
    let idDoc = this.firestore.createId();
    data["id"] = idDoc;
    this.firebase_dynamic.collection(collection).doc(idDoc).set(data, {merge:true});
          
    return new Promise<any>((resolve, reject) => {
      return this.firestore.collection(collection).doc(idDoc).get().toPromise().then((_) => resolve(_));
    });

  }

  dynamicCreatecollectionIdCustom(data: any, collection: string, idDoc: string): any {
    if (!data?.created_date) data["created_date"] = new Date();
    if (!data?.modify_date) data["modify_date"] = new Date();
    return new Promise<any>((resolve, reject) => {
      return this.firebase_dynamic
              .collection(collection)
              .doc(idDoc)
              .set(data)
              .then(
                (res) => {
                  resolve(res);
                },
                (err) => {
                  reject(err);
                  return false;
                }
              )
    })
  }

  getoneDynamicCollection(collection: string) {
    return this.firebase_dynamic.collection(collection, (ref) => ref.limit(1)).valueChanges();
  }

  getDynamicCollectionwithid(collection: string, id: string) {
    return this.firebase_dynamic.collection(collection).doc(id).valueChanges();
  }

  getDynamicCollectionwithidResponseGet(collection: string, id: any) {
    return this.firebase_dynamic.collection(collection).doc(id).get();
  }

  getDynamicCollectionwithidPromise(
    collection: string,
    id: string
  ): Promise<any> {
    return this.firebase_dynamic
      .collection(collection)
      .doc(id)
      .valueChanges()
      .pipe(first())
      .toPromise();
  }

  getDynamicCollectionwithidnorderby(
    collection: string,
    id: string,
    field_name: string,
    sort: any
  ) {
    return this.firebase_dynamic
      .collection(collection, (ref) => ref.orderBy(field_name, sort))
      .doc(id)
      .valueChanges();
  }

  addDynamicCollection(data: any, collection: string): any {
    return this.firebase_dynamic.collection(collection).add(data);
  }

  createDynamiccollectionwithdocumentname(
    data: any,
    collection: string,
    docname: string
  ): any {
    return new Promise<any>((resolve, reject) => {
      this.firebase_dynamic
        .collection(collection)
        .doc(docname)
        .set(data)
        .then(
          () => {
            console.log();
          },
          (err) => reject(err)
        );
    });
  }

  modifyRefDynamiccollectionwithdocumentname(
    data: any,
    path: string,
    docId: string
  ) {
    return this.firebase_dynamic.collection(path).doc(docId).ref.update(data);
  }

  createDynamiccollectionwithdocumentid(
    data: any,
    collection: string,
    docname: string
  ): any {
    return new Promise<any>((resolve, reject) => {
      return this.firebase_dynamic
        .collection(collection)
        .doc(docname)
        .set(data)
        .then((data: any) => resolve(data));
    });
  }

  modifyDynamicColection(data: any, path: string, docId: string) {
    if (!data.modify_date) data["modify_date"] = new Date();
    data["id"] = docId;
    return this.firebase_dynamic.collection(path).doc(docId).set(data);
  }

  modifyRefDynamicColection(data: any, path: string, docId: string) {
    // if (!data.modify_date) data["modify_date"] = new Date(); else data["modify_date"] = new Date();
    if (!data.modify_date) data["modify_date"] = new Date();
    return this.firebase_dynamic
      .collection(path)
      .doc(docId)
      .ref.set(data, { merge: true });
      // .set(data);
  }

  modifyRefNoUpdateDateDynamicColection(data: any, path: string, docId: string) {
    return this.firestore.collection(path).doc(docId).ref.set(data, { merge: true });
  }

  modifyDataRefCollection(data: any, path: string, docId: string) {
    if (!data.modify_date) data["modify_date"] = new Date();
    return this.firestore.collection(path).doc(docId).ref.update(data);
  }

  deleteDynamicCollection(path: string, docId: string) {
    return this.firebase_dynamic.collection(path).doc(docId).delete();
  }

  deleteCollectionReference(path: string, where: any){
    return this.firebase_dynamic
    .collection(path, ref=>ref.where( where.field,"==", where.value))
    .get()
    .subscribe(data=>data.forEach(el=> {
      let datas:any[] = [
        el.data()
      ];
      this.deleteDynamicCollection(path, datas[0].id);
    }));
  }

  async deleteDynamicCollectionWithoutId(path: string) {
    return this.firebase_dynamic
      .collection(path)
      .get()
      .subscribe((data) => {
        data.docs.map((key: any) => {
          this.firebase_dynamic
            .collection(path)
            .doc(key.id)
            .delete()
            .catch((error) => {
              console.log(error);
            })
            .then(() => console.log(`Deleting data)`));
        });
      });
  }

  async deleteCollectionWithoutId(path: string) {
    return this.firestore
      .collection(path)
      .get()
      .subscribe((data) => {
        data.docs.map((key: any) => {
          this.firebase_dynamic
            .collection(path)
            .doc(key.id)
            .delete()
            .catch((error) => {
              console.log(error);
            })
            .then(() => console.log(`Deleting data)`));
        });
      });
  }

  getDynamicCollectionObjectWhere2(collection: string, body: any) {
    // console.log(body.whereField)
      return this.firebase_dynamic
          .collection(collection, (ref) => 
            ref
            .where(body.whereField[0].field, "==", body.whereField[0].value)
            .where(body.whereField[1].field, "==", body.whereField[1].value)
          )
          // .get()
          .valueChanges()
  }

  getDynamicCollectionObjectWherePromise(collection: string, body: any): any {
      // console.log(body.whereField)
      if (body.whereField.length == 1) {
        return this.firebase_dynamic
        .collection(collection, (ref) => 
          ref
          .where(body.whereField[0].field, "==", body.whereField[0].value)
        )
        .get()
        .toPromise()
        .then((doc) => {
          return doc
        },
        (err) => {
            console.log(err);
        });
      }
      if (body.whereField.length == 2) {
        return this.firebase_dynamic
        .collection(collection, (ref) => 
          ref
          .where(body.whereField[0].field, "==", body.whereField[0].value)
          .where(body.whereField[1].field, "==", body.whereField[1].value)
        )
        .get()
        .toPromise()
        .then((doc) => {
          return doc
        },
        (err) => {
            console.log(err);
            // return false;
        });
      }
      // if (body.whereField.length == 3) {}
      // if (body.whereField.length == 4) {}
  }

  getCountCollectionWhereField(path: string, body: any) {
    // console.log(body)
      return this.firebase_dynamic
        .collection(path, (ref) => ref.where(body.whereField[0].field, "==",body.whereField[0].value) )
        .get()
        .toPromise()
        .then((doc) => {
          return doc.size;
        });
  }

  getCountCollection(path: string) {
    return this.firebase_dynamic
      .collection(path)
      .get()
      .toPromise()
      .then((doc) => {
        return doc.size;
      });
  }

  async getDynamicCollectionsWithBody(collection: string, body: any) {
    // console.log(body)
    // if (typeof body.whereField == "object")
    // if (body.Filters.length > 0)
    //   return this.getDynamicCollectionsMultiWhereField(collection, body);
    if (body.First == 0) {
      body.SortOrder = body.SortField == "id" ? "desc" : body.SortOrder;
      body.SortField = body.SortField == "id" ? "created_date" : body.SortField;
      const first = this.firebase_dynamic.collection(collection, (ref) =>
          ref.orderBy(body.SortField, body.SortOrder).limit(body.Rows)
        )
        .get()
        .toPromise();

      first.then((documentSnapshots: any) => {
        this.lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
      });
      return new Promise<any>((resolve, reject) => {
        return first.then(
          (_) => {
            resolve(_);
          },
          (err) => reject(err)
        );
      });
    } else {
      body.SortOrder = body.SortField == "id" ? "desc" : body.SortOrder;
      body.SortField = body.SortField == "id" ? "created_date" : body.SortField;
      const all = await this.firebase_dynamic
        .collection(collection, (ref) =>ref.orderBy(body.SortField, body.SortOrder))
        .get()
        .toPromise()
        .then((documentSnapshots: any) => {
          this.lastVisible = documentSnapshots.docs[body.First - 1];
        });

      if (this.lastVisible) {
        const next = this.firebase_dynamic
        .collection(collection, (ref) =>
          ref
            .orderBy(body.SortField, body.SortOrder)
            .startAfter(this.lastVisible)
            .limit(body.Rows)
        )
        .get()
        .toPromise();
        return new Promise<any>((resolve, reject) => {
          return next.then(
            (_) => {
              resolve(_);
            },
            (err) => reject(err)
          );
        });
      }
    }
  }

  async getDynamicCollectionsMultiWhereField(collection: string, body: any) {
    // console.log(body)
    // if (body.First == 0) {
    //   const first = this.firebase_dynamic
    //     .collection(collection, (ref) =>
    //       ref
    //         .where(body.whereField[0].field, "==", body.whereField[0].value)
    //         // .orderBy(body.SortField, body.SortOrder)
    //         .limit(body.Rows)
    //     )
    //     .get()
    //     .toPromise();

    //   first.then((documentSnapshots: any) => {
    //     this.lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
    //   });
    //   return new Promise<any>((resolve, reject) => {
    //     return first.then(
    //       (_) => {
    //         resolve(_);
    //       },
    //       (err) => reject(err)
    //     );
    //   });
    // } else {
    //   await this.firebase_dynamic
    //     .collection(collection, (ref) =>
    //     ref
    //       .where(body.whereField[0].field, "==", body.whereField[0].value)
    //       // .orderBy(body.SortField, body.SortOrder)
    //     )
    //     .get()
    //     .toPromise()
    //     .then((documentSnapshots: any) => {
    //       this.lastVisible = documentSnapshots.docs[body.First - 1];
    //     });

    //   if (this.lastVisible) {
    //     const next = this.firebase_dynamic
    //     .collection(collection, (ref) =>
    //       ref
    //         .where(body.whereField[0].field, "==", body.whereField[0].value)
    //         // .orderBy(body.SortField, body.SortOrder)
    //         .startAfter(this.lastVisible)
    //         .limit(body.Rows)
    //     )
    //     .get()
    //     .toPromise();
    //     return new Promise<any>((resolve, reject) => {
    //       return next.then(
    //         (_) => {
    //           resolve(_);
    //         },
    //         (err) => reject(err)
    //       );
    //     });
    //   }
    // }
  }
  
}
