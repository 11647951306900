/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { GlobalService } from '../../../services/global.service';
import { ParserService } from '../../../services/parser.service';
import { ToastNotificationService } from '@core-fibr/shared';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ui-fibr-add-columns',
  templateUrl: './add-columns.component.html',
  styleUrls: ['./add-columns.component.scss']
})
export class AddColumnsComponent implements OnInit {
  dataSource : any[] = [];
  alfabeth: any[] = [];
  fields: any = {
    fieldName: '',
    fieldType: '',
    value: null,
    calculation: {}
  };
  columns: any;
  varList = [{column:'',alias:'a'}];
  formula: any = '';
  countVarCal = 0;
  // calculation = {}
  constructor( public ref: DynamicDialogRef,private globalService: GlobalService, private parser: ParserService,
    private ts: ToastNotificationService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.dataSource = [
      // {value: '', name: this.translate.instant('editor.grid.form.column.select')}, 
      {value: 'text', name: this.translate.instant('editor.grid.column.type.text')},
      {value: 'number', name: this.translate.instant('editor.grid.column.type.number')},
      {value: 'date', name: this.translate.instant('editor.grid.column.type.date')},
      {value: 'time', name: this.translate.instant('editor.grid.column.type.time')},
      {value: 'image', name: this.translate.instant('editor.grid.column.type.image')},
      {value: 'link', name: this.translate.instant('editor.grid.column.type.link')},
      {value: 'boolean', name: this.translate.instant('editor.grid.column.type.boolean')},
      {value: 'text_editor', name: this.translate.instant('editor.grid.column.type.text_editor')},
      {value: 'qrcode', name: this.translate.instant('editor.grid.column.type.qrcode')},
      {value: 'calculation', name: this.translate.instant('editor.grid.column.type.calculation')},
      {value: 'autofill_calculation', name: 'Autofill Calculation'},
      {value: 'location', name: 'Location'},
    ];
    this.globalService.columnGrid.subscribe((res: any) => {
      this.columns = res.filter((x: any) => x.type == 'number' || x.type == 'calculation' || x.type == 'autofill_calculation');
    })
    this.alfabeth = this.parser.dataAlfabet();
  }

  cancelDialog(){
    this.ref.close(false);
  }

  doItDialog(){
    const valDt = this.validasiData();
    if (valDt != '') {
      this.ts.showNotification("error", valDt);
    } else {
      if (this.fields.fieldType == 'calculation') {
        const err: any = this.matchFormula(this.formula,this.varList);
        let errList = '';
        if (err.length > 0) {
          this.ts.showNotification("error", "wrote the formula wrong!");
        } else {
          errList = this.validasiFormula(this.varList,this.formula,this.fields);
          if (errList != '') {
            this.ts.showNotification("error", errList);
          } else {
            this.fields.calculation = {varList: this.varList, formula: this.formula}
            // console.log(this.fields)
            this.ref.close(this.fields);
          }
        }
      } else {
        this.fields.calculation = {};
        this.ref.close(this.fields);
      }
    }
  }

  validasiData() {
    let err = '';
    if (this.fields.fieldName == '') err = err+' Field Name must be filled in!';
    if (this.fields.fieldType == '') err = err+' Field Type must be filled in!';
    return err;
  }

  fieldTypeChange(event: any){
    switch (event.value) {
      case 'text':
      case 'image':
          this.fields.value = '';
        break;
    case 'number':
      this.fields.value = 0;
        break;
    case 'boolean':
      this.fields.value = false;
        break;
    case 'date':
      this.fields.value = new Date();
        break;
    }
  }

  addVar() {
    const urut = this.varList.length;
    // if (urut < 3)
      this.varList.push({column:'',alias:this.alfabeth[urut]});
      this.countVarCal = this.countVarCal + 1;
    // else 
    //   this.ts.showNotification("error", "max 3 column!");
  }

  delVar(i: number) {
    this.varList.splice(i, 1);
    this.countVarCal = this.countVarCal - 1;
  }

  checkFormula(formula: any, varList: any) {
    const err: any = this.matchFormula(formula,varList);
    if (err.length > 0) {
      this.ts.showNotification("error", "wrote the formula wrong!");
    } else {
      this.ts.showNotification("success", "wrote the formula valid");
    }
  }

  matchFormula(formula: any, varList: any) {
    const err: any = this.parser.parserCalculation(formula,varList)
    // console.log(err)
    return err
  }

  validasiFormula(varList:any, formula:any, fields: any) {
    let err: any ='';
    const varCount = varList;
    for (let x=0; x<varList.length; x++) {
      if (varList[x].column == '') err = err+' column must selected!';
      const cekAlias = varCount.filter((xy:any) => xy.alias == varList[x].alias);
      if (cekAlias.length > 1) err = err+' error duplicate alias';
      const cekCol = varCount.filter((xz:any) => xz.column == varList[x].column);
      if (cekCol.length > 1) err = err+' error duplicate column';
    }
    if (formula == '') err = err+' formula must be filled in!';
    return err;
  }

}
