<!-- <div *ngIf="waitProcess.process">
  <div class="grid">
    <div class="col-12 mb-2">
        <h5 class="text-center menu-subtitle mb-1">Please wait,..</h5>
        <p class="text-center">in the process of data adjustment <br />
          add the project will be done again after the process is complete</p>
    </div>
  </div>
</div> -->

  <!-- <div *ngIf="statusApp === 'prod-fibr'">
    <div *ngIf="isDataExists">
      <div class="grid my-5">
        <div class="col-12">
            <h5 class="text-center menu-subtitle mb-3"><b>You're on free trial mode!</b></h5>
            <p class="text-center">You can only create I project on this free trial mode</p>
        </div>
      </div>
    </div>
    <div *ngIf="!isDataExists">
      <div *ngIf="!isGoogleFiles">
        <div class="grid">
            <div class="col-12 mb-2">
                <h5 class="text-center menu-subtitle mb-1">You can create a new project</h5>
                <p class="text-center">by importing data from google sheets or create data using data fibr or you can start with an existing template from our market place</p>
            </div>
        </div>
        <p-divider></p-divider>
        <div class="grid">
            <div class="col-12 px-5">
                <p class="">Your project Name<sup>*</sup></p>
                <input type="text" pInputText class="w-100" [(ngModel)]="appConfig.app_name" placeholder="Project title"> 
                <sub *ngIf="showMsg" class="text-magenta">field required</sub>
            </div>
            <div class="col-12 px-5">
              <p class="">{{'editor.add.table.name' | translate}}<sup>*</sup></p>
              <input type="text" pInputText class="w-100" [(ngModel)]="tableName" placeholder="{{'editor.add.table.name' | translate}}"> 
              <sub *ngIf="showMsgTable" class="text-magenta">table required</sub>
            </div>
        </div>
        <p-divider></p-divider>
        <div class="grid center mb-5">
            <div class="col-auto center flex-column mx-4" *ngIf="false">
                <img src="/assets/images/icon/icon_blank-table.svg" height="80px" class="py-3"/>
                <button [disabled]="appConfig.app_name === null" (click)="blankTable()" pButton type="button" label="Blank Table" icon="pi pi-chevron-right" iconPos="right" class="p-button-secondary p-button-outlined p-button-sm mr-2 mb-2 bg-dark-blue text-white"></button>
            </div>
        </div>
      </div>
    </div>
  </div> -->


  <!-- <div *ngIf="statusApp === 'dev-fibr' || statusApp === 'staging-fibr'"> -->
  <div>
      <div *ngIf="!isGoogleFiles">
        <div class="grid">
            <div class="col-12 mb-2">
                <h5 class="text-center menu-subtitle mb-1">{{'project.create.new' | translate}}</h5>
                <p class="text-center">using Blank Table</p>
            </div>
        </div>
        <p-divider></p-divider>
        <div class="grid">
            <div class="col-12 px-5">
                <p class="">{{'project.template.form.name' | translate}}<sup>*</sup></p>
                <input type="text" pInputText class="w-100" [(ngModel)]="appConfig.app_name" placeholder="{{'project.template.form.name.placeholder' | translate}}"> 
                <sub *ngIf="showMsg" class="text-magenta">field required</sub>
            </div>
            <div class="col-12 px-5">
              <p class="">{{'editor.add.table.name' | translate}}<sup>*</sup></p>
              <input type="text" pInputText class="w-100" [(ngModel)]="tableName" placeholder="{{'editor.add.table.name' | translate}}"> 
              <sub *ngIf="showMsgTable" class="text-magenta">table required</sub>
            </div>
        </div>
        <p-divider></p-divider>
        <div class="grid text-center justify-content-center p-5">
          <div class="col-auto center flex-column mx-4">
              <a href="javascript:;" (click)="blankTable()">
                  <img src="/assets/images/icon/icon_blank-table.svg" height="80px" class="py-3"/>
                  <p class="text-grey">{{'editor.add.table.blank' | translate}}</p>
              </a>
          </div>
        </div>
      </div>
  </div>