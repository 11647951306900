<!-- <button type="button" *ngIf="config.layoutDesign?.layoutTemplate !== 'details'" pButton pRipple class="p-button-secondary2 p-button-text p-button p-component"
    (click)="onButtonClick()">back</button> -->
<div *ngIf="!is_typeButton">
<div class="mobile-comp" >
    <div class="comp-col comp-col-1">
        <div class="card-list card-list-default card-list-details">
            <div class="card-content">
                <div class="card-detail">
                    <div class="detail-left">
                        <div class="avatar">
                          <!-- view default detail = islinkToDetails -->
                            <div class="card-ava-container" *ngIf="islinkToDetails">
                                <ng-container *ngFor="let item of fields">
                                    <div *ngIf="item.type === 'image' && item.value !== '' && item.showInForm">
                                      <img *ngIf="item.type === 'image'" class="detail-image" 
                                        [src]="item.value | gdriveConvert" onerror="this.src='/assets/iconic_logo.png'">
                                    </div>
                                    <!-- <div *ngIf="item.type === 'image' && item.value === ''"></div> -->
                                    <div *ngIf="item.type !== 'image' && item.showInForm" class="ava-title">{{item.name}}</div>
                                    <div *ngIf="item.type !== 'image' && item.type === 'link' && item.showInForm" class="ava-detail">
                                        <a [href]="item.value">{{item.value}}</a>
                                    </div>
                                    <div *ngIf="item.type !== 'image' && item.type !== 'link' && item.type === 'time' && item.showInForm" class="ava-detail">
                                        {{item.value | slice:12:17}}
                                    </div>
                                    <div *ngIf="item.type !== 'image' && item.type !== 'link' && item.type === 'text_editor' && item.showInForm" class="ava-detail">
                                        <p-editor styleClass="editor-content-only" [(ngModel)]="item.value" [readonly]="true">
                                            <ng-template pTemplate="header">
                                                <span class="ql-formats">
                                                    <!-- <button type="button" class="ql-bold" aria-label="Bold"></button>
                                                    <button type="button" class="ql-italic" aria-label="Italic"></button>
                                                    <button type="button" class="ql-underline" aria-label="Underline"></button> -->
                                                </span>
                                            </ng-template>
                                        </p-editor>
                                    </div>
                                    <div *ngIf="item.type !== 'image' && item.type !== 'link' && item.type === 'number' && item.showInForm" class="ava-detail">
                                      {{item.value | number}}
                                    </div>
                                    <div *ngIf="item.type !== 'image' && item.type !== 'link' && item.type === 'calculation' && item.showInForm" class="ava-detail">
                                      {{item.value | number}}
                                    </div>
                                    <div *ngIf="item.type !== 'image' && item.type !== 'link' && item.type !== 'time' && item.type !== 'number' 
                                    && item.type !== 'calculation' && item.type !== 'text_editor' && item.showInForm" class="ava-detail">
                                        {{item.value}}
                                    </div>
                                    <hr *ngIf="item.showInForm">
                                </ng-container>
                            </div>

                            <div class="card-ava-container" *ngIf="!islinkToDetails && (config?.dataContent?.linkToDetails === 'detail_page' || 
                            config?.dataContent?.linkToDetails === 'default')">
                                <ng-container *ngFor="let item of fields">
                                  <div *ngIf="item.type === 'image' && item.value !== ''">
                                    <img *ngIf="item.type === 'image'" class="detail-image" 
                                      [src]="item.value | gdriveConvert" onerror=" this.src='/assets/iconic_logo.png'">
                                  </div>
                                  <div *ngIf="item.data_type === 'image' && item.field === 'custom'" class="ava-detail">
                                    <img class="detail-image" [src]="item.value | gdriveConvert" onerror=" this.src='/assets/iconic_logo.png'">
                                  </div>

                                  <div *ngIf="item.data_type === 'image' && item.type === 'qrcode' && item.value !== ''" class="ava-detail">
                                    <qrcode [qrdata]="item.value" [elementType]="'img'" [allowEmptyString]="true"></qrcode>
                                  </div>

                                  <div *ngIf="item.data_type === 'title' && item.type !== 'link' && item.type !== 'time' && item.type !== 'date' && 
                                    item.type !== 'text_editor' && item.type !== 'number' && item.type !== 'calculation'" class="ava-title">
                                    {{item.value | stripTags}}
                                  </div>
                                  <div *ngIf="item.data_type === 'content' && item.type !== 'link' && item.type !== 'time' && item.type !== 'date' && 
                                    item.type !== 'text_editor' && item.type !== 'number' && item.type !== 'calculation'" class="ava-detail">
                                      {{item.value | stripTags}}
                                  </div>
                                    

                                    <div *ngIf="item.data_type === 'title' && item.type === 'link'" class="ava-title">
                                      <a [href]="item.value">{{item.value}}</a>
                                    </div>
                                    <div *ngIf="item.data_type === 'content' && item.type === 'link'" class="ava-detail">
                                        <a [href]="item.value">{{item.value}}</a>
                                    </div>

                                    <div *ngIf="item.data_type === 'title' && item.type === 'number'" class="ava-title">{{item.value | number}}</div>
                                    <div *ngIf="item.data_type === 'content' && item.type === 'number'" class="ava-detail">{{item.value| number}}</div>

                                    <div *ngIf="item.data_type === 'title' && item.type === 'calculation'" class="ava-title">{{item.value | number}}</div>
                                    <div *ngIf="item.data_type === 'content' && item.type === 'calculation'" class="ava-detail">{{item.value| number}}</div>

                                    <div *ngIf="item.data_type === 'title' && item.type === 'time'" class="ava-title">{{item.value | slice:12:17}}</div>
                                    <div *ngIf="item.data_type === 'content' && item.type === 'time'" class="ava-detail">{{item.value | slice:12:17}}</div>

                                    <div *ngIf="item.data_type === 'title' && item.type === 'date'" class="ava-title">{{item.value | slice:0:11}}</div>
                                    <div *ngIf="item.data_type === 'content' && item.type === 'date'" class="ava-detail">{{item.value | slice:0:11}}</div>

                                    <div *ngIf="item.data_type === 'title' && item.type === 'text_editor'" class="ava-title">
                                      <p-editor styleClass="editor-content-only" [(ngModel)]="item.value" [readonly]="true">
                                        <ng-template pTemplate="header"><span class="ql-formats"></span></ng-template>
                                      </p-editor>
                                    </div>
                                    <div *ngIf="item.data_type === 'content' && item.type === 'text_editor'" class="ava-detail">
                                      <p-editor styleClass="editor-content-only" [(ngModel)]="item.value" [readonly]="true">
                                        <ng-template pTemplate="header"><span class="ql-formats"></span></ng-template>
                                      </p-editor>
                                    </div>
                                    <!-- media youtube -->
                                    <div *ngIf="item.data_type === 'media' && item.type === 'link' && item.mediaProperty.childMedia === 'youtube' && item.value!==''" class="ava-detail">
                                      <div class="media-container">
                                        <iframe [src]="item.value" frameborder="0" allowfullscreen></iframe>
                                      </div>
                                    </div>
                                    <hr *ngIf="item.data_type === 'separator'">
                                    <p-button *ngIf="item.data_type === 'button'" [label]="item.buttonProperty.label" 
                                    (onClick)="onTypeButtonClick(item.buttonProperty, item?.index)" styleClass="btn-details"></p-button>

                                    <div *ngIf="item.data_type === 'location'" class="ava-detail">
                                      <ui-fibr-map></ui-fibr-map>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <!-- <button *ngIf="config.formConfiguration?.delete" pButton pRipple type="button" icon="pi pi-trash"
                        class="p-button-rounded p-button-danger" (click)="deleteData()"></button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<button *ngIf="config.formConfiguration?.edit" pButton pRipple type="button" icon="pi pi-pencil"
class="p-button-rounded btn-float" (click)="onButtonFloatClick()"></button>
</div>

<div *ngIf="is_typeButton">
        <div class="mobile-comp" *ngIf="buttonProperty.type === 'column'">
            <div class="comp-col comp-col-1">
                <div class="card-list card-list-default card-list-details">
                    <div class="card-content">
                        <div class="card-detail">
                            <div class="detail-left">
                                <div class="avatar">
                                    <div class="card-ava-container" *ngIf="buttonProperty.type === 'column'">
                                      <img *ngIf="column_type === 'image'" class="detail-image"
                                        [src]="column_data | gdriveConvert" onerror="this.src='/assets/iconic_logo.png'">
                                      <div *ngIf="column_type === 'time'" class="ava-detail">{{column_data | slice:12:17}}</div>
                                      <div *ngIf="column_type === 'text_editor'" class="ava-detail">
                                        <p-editor styleClass="editor-content-only" [(ngModel)]="column_data" [readonly]="true">
                                          <ng-template pTemplate="header"><span class="ql-formats"></span></ng-template>
                                        </p-editor>
                                      </div>
                                      <div *ngIf="column_type !== 'time' && column_type !== 'image' && column_type !== 'text_editor'" class="ava-detail">
                                        {{column_data}}
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="mobile-comp mobile-form px-0" *ngIf="buttonProperty.type === 'in_app_payment'">
          <!-- <div class="grid my-3 px-2">
            <div class="col-12 p-1 text-center font-bold">Payment {{paymentInfo?.status_payment}}</div>
          </div> -->
          <div class="grid my-3 px-2" *ngIf="paymentInfo?.status_payment === 'PAID'">
            <div class="col-12 p-1 text-center">
              <img class="ava-img" [src]="paySuccess" onerror="this.src='/assets/icon_no_image.png'">
            </div>
            <div class="col-12 p-1 text-center font-bold">Payment Success</div>
          </div>
          <div class="grid my-3 px-2" *ngIf="paymentInfo?.status_payment === 'EXPIRED'">
            <div class="col-12 p-1 text-center">
              <img class="ava-img" [src]="payExpired" onerror="this.src='/assets/icon_no_image.png'">
            </div>
            <div class="col-12 p-1 text-center font-bold">Payment Expired</div>
          </div>
          <div class="grid my-3 px-2" *ngIf="paymentInfo?.status_payment === 'PAID' && paymentInfo?.status_payment === 'PAID' && (paymentInfo?.payment_invoice !== '' || paymentInfo?.payment_invoice !== null)">
            <div class="col-12 p-1 text-right font-bold">
              <a href="javascript:;" (click)="downloadInvoice()">Download Invoice</a>
            </div>
          </div>

          <div class="comp-col comp-col-1 mb-7">
            <div class="card-list card-list-default cart-list">
              <div class="card-content">
                <div class="card-detail">
                  <div class="detail-left">
                    <div class="avatar">
                      <!-- <img class="ava-img" [src]="det[sourceItemPicture] | gdriveConvert" onerror="this.src='/assets/icon_no_image.png'"> -->
                      <div class="card-ava-container text-left">
                        <div class="ava-title">{{paymentDesc | stripTags}}</div>
                        <div>1 x Rp. {{paymentAmount | number}}</div>
                      </div>
                      <div class="card-ava-container text-right">
                        <div class="ava-price">Rp. {{paymentAmount | number}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
            <div class="grid my-3 px-2">
              <div class="col-6 p-1">Total</div>
              <div class="col-6 p-1 text-right font-bold">{{paymentAmount | number}}</div>
            </div>
          </div>
        
          <div class="grid mt-2 sticky-bottom">
            <div class="col-6 p-1">Grand Total</div>
            <div class="col-6 p-1 text-right font-bold text-xl">{{paymentAmount | number}}</div>
          </div>

          <div class="grid mobile-bottommenu bottom-page" *ngIf="actFrom === 'pwa' && paymentInfo?.status_payment === 'EXPIRED'">
            <div class="col-4 align-self-center">
              <button pButton pRipple class="p-button p-button-secondary2 w-100 px-2" (click)="getClose(indexButton)">Close</button>
            </div>
            <div class="col-8 align-self-center">
              <button pButton pRipple class="p-button p-component w-100 px-2"
              (click)="onConfirmPurchaseClick(indexButton)">{{paymentBtn}}</button>
            </div>
          </div>
          <div class="grid mobile-bottommenu bottom-page" *ngIf="actFrom === 'pwa' && paymentInfo?.status_payment !== 'EXPIRED'">
            <div class="col-12 align-self-center" *ngIf="paymentInfo?.is_paid === false">
              <button pButton pRipple class="p-button p-component w-100 px-2"
              (click)="onConfirmPurchaseClick(indexButton)">{{paymentBtn}}</button>
            </div>
            <div class="col-12 align-self-center" *ngIf="paymentInfo?.is_paid === true">
              <button pButton pRipple class="p-button p-component w-100 px-2" (click)="getClose(indexButton)">Close</button>
            </div>
          </div>
          <div class="grid mobile-bottommenu bottom-page" *ngIf="actFrom !== 'pwa'">
            <div class="col-12 align-self-center">
              <button pButton pRipple class="p-button p-component w-100 px-2" (click)="getClose(indexButton)">Close</button>
            </div>
          </div>
        </div>
</div>

