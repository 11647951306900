/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable } from "@angular/core";
import { FieldConfig, Toolbar } from "@core-fibr/dynamic-form";
import {
  ToastNotificationService,
} from "../toast-notification.service";
import { TitleCasePipe } from "@angular/common";
import { Validators } from "@angular/forms";
import _ from "lodash";
import { GridModel } from "../../clases/global";
import { GlobalService } from "../global.service";
import { GlobalCrudPromisesService } from "../crud/global-crud-promises.service";
import { GlobalcrudService } from "../crud/globalcrud.service";
@Injectable({
  providedIn: "root",
})
export class HelpersService {
  modelsPath = `/models`;
  rolePermissionPath = `/config/role_permissions/data`;
  fieldIsDate = [""];
  toolbar: Toolbar = {};
  config!: FieldConfig[];
  userProfile: any;
  role = "";

  constructor(
    private titleCase: TitleCasePipe,
    private globalService: GlobalService,
    private globalCrudService: GlobalCrudPromisesService,
    private globalCrudServices: GlobalcrudService
  ) {
    this.userProfile = this.globalService.getCurrReffDataParsed("user_profile");
    this.role = this.userProfile.role;
    this.config = [
      {
        type: "card",
        name: "main-card",
        label: "Main Form", //this.activatedRoute.snapshot.data['title'],
        paneltoggleable: true,
        items: [],
      },
      {
        type: "button",
        multipleButton: false,
        label: "Submit",
        name: "submit",
        disabled: false,
      },
    ];
    this.toolbar = {
      title: "",
      type: "card",
      show: true,
      leftButton: [],
      rightButton: [],
    };
  }

  generateId() {
    let text = "";
    const possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 10; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  parseDateFromTimestamp(date: any) {
    let value;
    if (typeof date == "string") {
      value = new Date(date);
    } else {
      const dateInMillis = date.seconds * 1000;
      value = new Date(dateInMillis);
    }
    return value;
  }

  getAge(birthYear: number) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const age = currentYear - birthYear;
    return age;
  }

  async makeGrid(collectionName: string, keys?: string) {
    let allowEdit = false;
    let allowDelete = false;
    const model = {
      field: null,
      header: null,
      filterable: null,
      sortable: null,
      type: null,
    };
    const s = await this.globalCrudService.a_getcollectionsbyid(
      this.rolePermissionPath,
      this.role
    ); //'wzbcwFfUZF3Li2buWD6b');
    const rest = s.data();

    let loop: any = {};
    if (rest) {
      const roleForm =
        typeof rest.form == "string" ? JSON.parse(rest.form) : rest.form;
      let ruleThisForm = keys ? roleForm[keys] : null;
      if (ruleThisForm == null)
        ruleThisForm = collectionName ? roleForm[collectionName] : null;
      if (ruleThisForm !== null) {
        allowEdit = ruleThisForm?.show_grid_button_edit;
        allowDelete = ruleThisForm?.show_grid_button_delete;
      }
      loop = JSON.parse(rest.form)[collectionName]["treeNode"];
    } else {
      const t = await this.globalCrudService.a_getcollectionsbyid(
        this.modelsPath,
        collectionName
      );
      const res = t.data();
      loop = res;
    }
    const columns: any[] = [];
    Object.keys(loop).map((key) => {
      if (loop[key].show_in_grid) columns.push(loop[key]);
    });
    const result = columns.sort((a, b) =>
      a.col_order > b.col_order ? 1 : b.col_order > a.col_order ? -1 : 0
    );
    const newModel: any = [];
    result.forEach((data) => {
      newModel.push(_.pick(data, _.keys(model)));
    });

    const factionButton = [];

    if (allowEdit) {
      if (collectionName === "appointment" || collectionName === "consultation")
        factionButton.push({
          Name: "update",
          Title: "",
          icon: "pi pi-calendar-times",
          Action: "update_status",
          class: "p-button-rounded p-button-text p-button-primary",
        });
      factionButton.push({
        Name: "edit",
        Title: "",
        icon: "pi pi-pencil",
        Action: "edit",
        class: "p-button-rounded p-button-text p-button-secondary",
      });
    }

    if (allowDelete)
      factionButton.push({
        Name: "delete",
        Title: "",
        icon: "pi pi-trash",
        Action: "delete",
        class: "p-button-rounded p-button-text p-button-danger",
      });

    const actionButton = {
      field: factionButton,
      header: "",
      sortable: false,
      filterable: false,
      type: "button",
    };

    if (factionButton?.length > 0) newModel.unshift(actionButton);
    return newModel;
  }

  createBodyRequest(res: any): string {
    let result: any = "const body:any = {\n";
    Object.keys(res).map((key, index) => {
      if (key !== "uid") {
        if (index == Object.keys(res).length - 1)
          result += `${key} : this.form?.value.${key}\n`;
        else result += `${key} : this.form?.value.${key}??'',\n`;
      }
    });
    result += "};";
    return result;
  }

  createResetValue(res: any): string {
    let result = "this.form = this.fb.group({\n";
    Object.keys(res).map((key, index) => {
      if (key !== "uid") {
        if (index == Object.keys(res).length - 1)
          result += `${key} : [null, Validators.required]\n`;
        else result += `${key} : [null, Validators.required],\n`;
      }
    });
    result += "});";
    return result;
  }

  createConfigForm(collectionName: string, routePath: string) {
    const user = this.globalService.getCurrReffDataParsed("user_profile");
    this.globalCrudService
      .a_getcollectionsbyidpromise(this.rolePermissionPath, user?.role)
      .then((result: any) => {
        const form = JSON.parse(result?.form);
        const select = form[collectionName];
        this.toolbar.title = select.name;

        if (form[collectionName]["show_button_back"])
          this.toolbar.leftButton?.push({
            label: "Kembali",
            icon: "pi pi-arrow-left",
            routerLink: routePath,
          });

        if (form[collectionName]["show_button_clear"]) {
          this.toolbar.rightButton?.push({
            label: "",
            icon: "pi pi-refresh text-blue",
            id: "clear",
            styleClass: "p-button-secondary p-mx-auto mr-1",
          });
        }

        if (form[collectionName]["show_button_delete"]) {
          this.toolbar.rightButton?.push({
            label: "",
            icon: "pi pi-trash text-magenta",
            id: "delete",
            styleClass: "p-button-secondary",
          });
        }

        Object.keys(select.treeNode).map((key) => {
          if (select.treeNode[key].show_in_form) {
            const obj: FieldConfig = {
              type: select.treeNode[key].type,
              label: this.titleCase.transform(
                select.treeNode[key].header.split("_").join(" ")
              ),
              placeholder:
                "Input " +
                this.titleCase.transform(
                  select.treeNode[key].header.split("_").join(" ")
                ),
              name: select.treeNode[key].field,
              validation: [Validators.required],
              containerClass: "col-6",
              class: "col-12",
            };
            if (["select", "multiselect"].includes(select.treeNode[key].type)) {
              obj.options = [];
              obj.placeholder = "Pilih satu";
              obj.optionValue = "value";
              obj.optionLabel = "label";
            }
            this.config[0].items?.push(obj);
          }
        });
      });
  }

  async generateProperty(collectionName: string): Promise<any> {
    const t = await this.globalCrudService.a_getcollectionsbyid(
      this.modelsPath,
      collectionName
    );
    const res = t.data();
    const bodyRequest = this.createBodyRequest(res);
    const resetValue = this.createResetValue(res);
    return { resetValue: resetValue, body: bodyRequest };
  }

  async generateSubProperty(collectionName: string): Promise<any> {
    const t = await this.globalCrudService.a_getcollectionsbyid(
      this.modelsPath,
      collectionName
    );
    const res = t.data();
    const bodyRequest = this.createBodyRequest(res);
    const resetValue = this.createResetValue(res);
    return { resetValue: resetValue, body: bodyRequest };
  }

  public flattenConfig = (config: FieldConfig[]): FieldConfig[] => {
    const flattenedConfig = _.filter(_.flatMap(config, "items"), (el: any) => {
      return el != null;
    });

    const flattenedConfig1 = _.filter(
      _.flatMap(flattenedConfig, "items"),
      (el: any) => {
        return el != null;
      }
    );

    const buttonConfig = _.filter(config, (el: any) => {
      return el.type === "button";
    });
    return [...flattenedConfig, ...flattenedConfig1, ...buttonConfig];
  };

  gridModelConfig(
    title: string,
    dataPath: string,
    isFaskes = false,
    isImport = false
  ) {
    const x = new GridModel();
    x.GridName = title;
    x.isAddBlankRow = false;
    x.isAddNewColumn = false;
    x.isAddNewData = true;
    x.isShowFilter = true;
    x.APIEndpoint = dataPath;
    x.RowsPerPageOptions = [10, 25, 50];
    x.isAddtionalFirebase = true;
    if (isImport) x.IsImport = ["partner"].includes(this.userProfile.role_id);
    if (isFaskes)
      if (["faskes"].includes(this.userProfile.role_id))
        x.GridId = { faskes: this.userProfile.additional_profile_id };

    return x;
  }

  getReferenceData(flattenedConfig: FieldConfig[]) {
    flattenedConfig.map(async (con: FieldConfig) => {
      if (["card", "select", "multiselect"].includes(con.type)) {
        const api = con.referenceDataType ?? "";
        if (api !== "") {
          if (
            [
              "province",
              "city",
              "disctricts",
              "sub_disctricts",
              "province_id",
              "city_id",
              "disctricts_id",
              "sub_disctricts_id",
            ].includes(con.name)
          ) {
            this.globalService.methodGet(api).subscribe((res: any) => {
              if (res.status == "success") {
                con.options = res.data;
              }
            });
          } else {
            await this.globalCrudServices
              .dynamicColection(con.referenceDataType ?? "")
              .subscribe((x: any) => {
                x.map((data: any) => {
                  let obj: any = {};
                  obj = data;
                  obj[con.optionValue ?? "id"] =
                    data[con.optionValue ?? ""] ?? "";
                  obj[con.optionLabel ?? "name"] =
                    data[con.optionLabel ?? ""] ?? "";
                  con.options?.push(obj);
                });
              });
          }
        }
      }
    });
  }

  getReferenceDataPeresepan(config: FieldConfig[]) {
    config
      .filter((_) => ["select", "multiselect"].includes(_.type))
      .forEach(async (value: FieldConfig, index: number) => {
        if (value.referenceDataType) {
          if (
            ["faskes"].includes(this.userProfile.role_id) &&
            value.name == "_"
          ) {
            const where = {
              field: "faskes",
              value: this.userProfile.additional_profile_id,
            };
            this.globalCrudServices
              .dynamicColectionwhereclause(value.referenceDataType ?? "", where)
              .subscribe((x: any) => {
                x.map((data: any) => {
                  if (value.kindof) {
                    if (value.kindof == data["category_name"])
                      value.options?.push(data);
                  } else value.options?.push(data);
                });
              });
          } else {
            this.globalCrudServices
              .dynamicColection(value.referenceDataType ?? "")
              .subscribe((x: any) => {
                x.map((data: any) => {
                  if (value.kindof) {
                    if (
                      value.kindof == data["category_name"] &&
                      !value.options?.find((x) => x.name == data["name"])
                    )
                      value.options?.push(data);
                    if (
                      data["category_name"] == "waktu pakai" &&
                      value.kindof == data["category_name"] &&
                      !value.options?.find((x) => x.name == data["name"])
                    )
                      console.log();
                  } else value.options?.push(data);
                });
              });
          }
        }
      });
  }

  removedata(
    e: any,
    toastNotificationService: ToastNotificationService,
    dataPath: string
  ) {
    this.globalCrudServices
      .deleteDynamicCollection(dataPath, e.data.uid)
      .then((res: any) => {
        toastNotificationService.showNotification(
          "success",
          "Data deleted sucessfully!"
        );
      });
  }

  resetValue(form: any) {
    Object.keys(form?.value).map((key: string) => {
      form?.setValue(key, null);
    });
  }

  setDisabledAll(form: any) {
    Object.keys(form?.value).map((key: string) => {
      form?.setDisabled(key, true);
    });
  }

  setEnableAll(form: any) {
    Object.keys(form?.value).map((key: string) => {
      form?.setDisabled(key, false);
    });
  }

  setValueFlatConfig(
    flattenedConfig: FieldConfig[],
    form: any,
    key: string,
    value: any,
    type?: string
  ) {
    let index = 0;
    if (type)
      index = flattenedConfig.findIndex((x) => x.name == key && x.type == type);
    else index = flattenedConfig.findIndex((x) => x.name == key);
    flattenedConfig[index].value = value;
    if (type == "upload") flattenedConfig[index].isUploaded = true;
    form?.setValue(key, value);
  }

  getValueFlatConfig(
    flattenedConfig: FieldConfig[],
    key: string,
    type?: string
  ) {
    let index = 0;
    if (type)
      index = flattenedConfig.findIndex((x) => x.name == key && x.type == type);
    else index = flattenedConfig.findIndex((x) => x.name == key);
    return flattenedConfig[index].value;
  }

  getValueFlatConfigDescription(
    flattenedConfig: FieldConfig[],
    key: string,
    value: any,
    label?: string
  ) {
    const index = flattenedConfig.findIndex((x) => x.name == key);
    const comp = flattenedConfig[index];
    const options = comp.options;
    const opt = options?.find((x) => x[comp.optionValue ?? "id"] == value);
    const val = opt ? opt[comp.optionLabel ?? label ?? "text"] : value;

    return val;
  }

  getArrayValueFlatConfigDescription(
    flattenedConfig: FieldConfig[],
    key: string,
    value: any
  ) {
    const index = flattenedConfig.findIndex((x) => x.name == key);
    const comp = flattenedConfig[index];
    const options = comp.options;
    const val: any = [];
    value.map((v: string) => {
      const opt = options?.find((x) => x[comp.optionValue ?? "id"] == v);
      val.push(opt ? opt[comp.optionLabel ?? "text"] : v);
    });
    return val;
  }

  getArrayValueFlatConfigFromDescription(
    flattenedConfig: FieldConfig[],
    key: string,
    value: any
  ) {
    const index = flattenedConfig.findIndex((x) => x.name == key);
    const comp = flattenedConfig[index];
    const options = comp.options;
    let val: any = "";
    const opt = options?.find((x) => x[comp.optionLabel ?? "text"] == value);
    val = opt ? opt[comp.value ?? "id"] : value;
    return val;
  }

  syncGrid(
    flattenedConfig: FieldConfig[],
    x: any,
    name: string,
    isInitial = true
  ) {
    const i = flattenedConfig.findIndex((x) => x.name == name);
    const comp = flattenedConfig[i];
    comp.gridModel = x;
    if (isInitial) comp.gridInitial = true;
  }

  initialGrid(flattenedConfig: FieldConfig[], name: string, value: boolean) {
    const i = flattenedConfig.findIndex((x) => x.name == name);
    const comp = flattenedConfig[i];
    comp.gridInitial = value;
  }

  createFormData(filesUploadedList: any, userProfile: any) {
    const formData = new FormData();
    formData.append("user_type", "partner");
    formData.append("user_id", userProfile.uid);
    for (let item = 0; item < filesUploadedList.length; item++) {
      formData.append(`file`, filesUploadedList[item].File);
    }
    return formData;
  }

  findFlatConfig(key: string, flattenedConfig: FieldConfig[]) {
    const index = flattenedConfig.findIndex((x) => x.name == key);
    return flattenedConfig[index];
  }

  // multiple delete document
  multipleDeleteDocument(path = '/sifa_staging'){
    this.globalCrudServices.get_id_collection(path)
    .subscribe((x:any)=>{
      console.log('x: ', x);
      x.docs.map((y:any)=> {
        //cannot delete this
        if(['role_permissions','roles'].includes(y.id)){
          console.log(y.id);
        }else{
          this.globalCrudServices.deleteCollection(path, y.id)
          .then(() => console.log())
        }
      })
    })
  }

}
