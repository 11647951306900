import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ui-fibr-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  title: string = '';
  name: string = '';
  constructor(
    private activateRoute: ActivatedRoute
  ) { 
    this.title = activateRoute.snapshot.data?.title || '';
    this.name = activateRoute.snapshot.data?.name || '';
  }

  ngOnInit(): void {
  }

}
