<div class="grid">
  <!-- <div class="col-12">
      <p class="">Field Name</p>
      <input type="text" disabled pInputText class="w-100" placeholder="Field title" [(ngModel)]="fields.fieldName"> 
  </div> -->
  <div class="col-12">
    <p class="">Column Name</p>
    <input type="text" pInputText class="w-100" placeholder="Field title" [(ngModel)]="fields.fieldHeader"> 
  </div>
  <div class="col-12">
      <p class="">Type</p>
      <p-dropdown [options]="dataSource" optionLabel="name" optionValue="value" [(ngModel)]="fields.fieldType" (onChange)="fieldTypeChange($event)" appendTo="body"></p-dropdown>   
  </div>

  <div class="col-12" *ngIf="fields.fieldType ==='calculation'">
    <div class="grid">
      <div class="col-12">
          <h5 class="label-info">Variable List</h5>
      </div>
    </div>
    <div class="grid" *ngFor="let item of varList; let i=index">
      <div class="col-5">
        <input type="text" class="w-100" pInputText [(ngModel)]="item.alias" [attr.readonly]="true"/>
      </div>
      <div class="col-6">
        <p-dropdown [options]="columns" [(ngModel)]="item.column" optionLabel="header" optionValue="field" 
        placeholder="Select Column" appendTo="body"></p-dropdown>
      </div>
      <div class="col-1 text-right py-0" *ngIf="(countVarCal - 1) === i">
        <button type="button" (click)="delVar(i)" class="p-button p-button-danger p-button-text p-button-icon-only p-2" pButton label="Remove" icon="pi pi-trash"></button>
      </div>
    </div>
    <div class="grid" *ngIf="countVarCal < 3">
      <div class="col-12">
      <button type="button" (click)="addVar()" class="p-button p-button-primary" pButton label="Add" icon="pi pi-plus"></button>
      </div>
    </div>

    <hr>

    <div class="grid">
      <div class="col-12">
          <h5 class="label-info">Formula</h5>
      </div>
    </div>

    <div class="grid">
      <div class="col-12">
        <input type="text" class="w-100" pInputText [(ngModel)]="formula"
        (keydown.enter)="checkFormula(formula,varList)" (keydown.tab)="checkFormula(formula,varList)" placeholder="Input formula"/>
      </div>
    </div>
  </div>
</div>
<div class="grid">
  <div class="col-12">
    <hr>
  </div>
  <div class="col-6">
    <button (click)="doItDeleteDialog()" pButton type="button" icon="pi pi-trash" iconPos="right" class="p-button p-button-secondary text-magenta mr-2 mb-2" pTooltip="Delete Column" tooltipPosition="bottom"></button>
  </div>
  <div class="col-6 text-right">
    <button (click)="cancelDialog()" pButton type="button" label="Cancel" icon="" iconPos="right" class="p-button p-button-secondary p-component p-ripple mr-2 mb-2"></button>
    <button (click)="doItDialog()" pButton type="button" label="Update" icon="" iconPos="right" class="p-button p-component p-ripple mr-2 mb-2"></button>
  </div>
</div>
