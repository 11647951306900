<form #f="ngForm">
  <div class="splash-screen bg-app" [ngStyle]="{'background-image': 'url(' + appConfig.splash_screen + ')'}">
  </div>
  
  <div  class="mobile-comp mobile-form">
      <div class="p-grid p-fluid">
        <!-- <img class="img-app" src="/assets/logo_appfibr_vertical.svg" alt="AppFibr Logo"/> -->
        <img class="img-app" [src]="logo" alt="AppFibr Logo"/>

        <div class="field" *ngFor="let item of elementForm">
          <ng-container *ngIf="item.field === 'fullname'">
              <span class="relative">
                  <p class="form-label">{{item.label}}</p>
                  <input [name]="item.field" type="text" pInputText [(ngModel)]="item.value" >
              </span>
          </ng-container>
          <ng-container *ngIf="item.field === 'email'">
            <span class="relative">
                <p class="form-label">{{item.label}}</p>
                <input [name]="item.field" type="text" pInputText [(ngModel)]="item.value">
            </span>
          </ng-container>
          <ng-container *ngIf="item.field === 'password'">
            <p class="relative">{{item.label}}</p>
            <span class="p-input-icon-right">
                <!-- <input [name]="item.field" type="password" id="inputtext" pInputText [(ngModel)]="item.value"> -->
                <input [name]="item.field" [type]="fieldTextType ? 'text' : 'password'" pInputText [(ngModel)]="item.value">
                <i [class]="fieldTextType ? 'pi pi-eye': 'pi pi-eye-slash'" (click)="toggleFieldTextType()"></i>
            </span>
          </ng-container>
          <ng-container *ngIf="item.field === 'confirmPassword'">
            <p class="relative">{{item.label}}</p>
            <span class="p-input-icon-right">
                <!-- <input [name]="item.field" type="password" id="inputtext" pInputText [(ngModel)]="item.value"> -->
                <input [name]="item.field" [type]="confirmPwd ? 'text' : 'password'" pInputText [(ngModel)]="item.value">
                <i [class]="confirmPwd ? 'pi pi-eye': 'pi pi-eye-slash'" (click)="toggleConfirmPwd()"></i>
            </span>
          </ng-container>
        </div>

        <div class="grid" >
            <div class="col-12 p-0">
                <button pButton pRipple class="p-button p-component w-100" (click)="onClickRegister(f)">Register</button>
            </div>
            <div class="col-12 mt-2 z-1">
              <a href="javascript:;" (click)="onClickLogin()" pbutton="" type="button" label="Register" tabindex="0" class="p-button-text mt-3">
                <span class="p-button-label center">Back to Login</span>
              </a>
            </div>
        </div>
      </div>
  </div>
</form>