import Step from 'shepherd.js/src/types/step';

export const builtInButtons = {
  cancel: {
    classes: 'p-button p-button-secondary2',
    secondary: true,
    text: 'Skip the Tour',
    type: 'cancel'
  },
  next: {
    classes: 'p-button p-button-primary',
    text: 'Next',
    type: 'next'
  },
  back: {
    classes: 'p-button p-button-secondary',
    secondary: true,
    text: 'Back',
    type: 'back'
  },
  finish: {
    classes: 'p-button p-button-primary',
    text: 'Finish',
    type: 'cancel'
  }
};

export const defaultStepOptions: Step.StepOptions = {
  classes: 'shepherd-theme-arrows custom-default-class',
  scrollTo: true,
  cancelIcon: {
    enabled: false
  }
};

export const steps: Step.StepOptions[] = [
  {
    attachTo: {
      element: '.first-element',
      on: 'right'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'data-editor',
    arrow: false,
    title: 'Edit Data Editor',
    text: 'Click the data sheet in the left panel, then you can edit data and add column in the table'
  },
  {
    attachTo: {
      element: '.second-element',
      on: 'left'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'page-app',
    arrow: false,
    title: 'Add Page App',
    text: 'Click the add page button and select your data view'
  },
  {
    attachTo: {
      element: '.third-element',
      on: 'left'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'page-property',
    arrow: false,
    title: 'View Page Property',
    text: 'Click an item in the preview at the right of your screen to view its details'
  },
  {
    attachTo: {
      element: '.fourth-element',
      on: 'left'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'form',
    arrow: false,
    title: 'Add Form',
    text: 'Click add, edit and delete to activate the form, click add more in field list section and choose the data type to add the feature in the form',
    when: {
      show: () => {
        console.log('show step');
      },
      hide: () => {
        console.log('hide step');
      }
    }
  },
  {
    attachTo: {
      element: '.fifth-element',
      on: 'left'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'details-page',
    arrow: false,
    title: 'Add Details Page',
    text: 'Click add page button and select layout design choose details, add data content'
  },
  {
    attachTo: {
      element: '.sixth-element',
      on: 'left'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'connect-details-page',
    arrow: false,
    title: 'Connect Details Page',
    text: 'Click your page as parent page, select data content, select details page and choose detail page'
  },
  {
    attachTo: {
      element: '.seventh-element',
      on: 'left'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'appearance',
    arrow: false,
    title: 'Change the Appearance',
    text: 'Open project properties, select app settings, change the accent color and other options'
  },
  {
    attachTo: {
      element: '.eighth-element',
      on: 'left'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'publish',
    arrow: false,
    title: 'Publish Your App',
    text: 'Publish your app to get web app and android app then share it with others'
  },
  {
    attachTo: {
      element: '.modal-element',
      on: 'top'
    },
    buttons: [
      builtInButtons.back,
      builtInButtons.finish
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'modal',
    arrow: false,
    title: 'Congratulations!',
    text: 'You are on the ends of the tour'
  }
];
