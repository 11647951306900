import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from "rxjs";
import { Confirmation } from "./../interfaces/confirmation";

@Injectable({
  providedIn: 'root'
})
export class ConfirmationsService {

  public result = new BehaviorSubject(false);

  constructor() {}

  get resultValue() {
    return this.result.asObservable();
  } 

  set resultValue(value:any) {
    this.result.next(value);
  }
}
