<div>
<!-- page fibr -->
<div *ngIf="env.app_name !== 'sifa' && env.app_name !== 'dentago' && env.app_name !== 'geenoms'" class="login-body layout-light">
  <div class="login-content">
      <div class="night-switch">
          <div class="language-menu">
              <p-dropdown (onChange)="onLanguageChange()" [options]="country" [(ngModel)]="selectedLanguage" placeholder="{{'selectLanguage' | translate}}" optionLabel="name" [showClear]="false"></p-dropdown>
          </div>
      </div>
      <div class="login-panel">
          <div class="login-panel-content text-left">
              <div class="col login-header">
                <div class="logo">
                  <a href="https://appfibr.io"><img [src]="logo" alt="{{labelAlt}}" /></a>
                </div>
                <h4 class="mb-2">{{'Fibr-portalLogin' | translate}}</h4>
                <span>{{'WelcomeMessage' | translate}}</span>
              </div>
              <div class="col">
                  <!-- <p> {{'anotherauth' | translate}} </p> -->
                  <!-- <div class="p-inputgroup" *ngIf="isGoogleSignIn">
                    <span class="p-inputgroup-addon border-0 py-3 px-4">
                      <img alt="logo" height="30px" src="assets/images/logo/google-logo.svg">
                    </span>
                    <button (click)="onClickGoogle()" pButton pRipple type="button" label="{{'googleLogin' | translate}}" class="p-button w-100"></button>
                  </div> -->
                  <div id="my-signin2" *ngIf="isGoogleSignIn">
                    <div class="abcRioButton" (click)="onClickGoogle()">
                      <div class="abcRioButtonContentWrapper">
                        <div class="abcRioButtonIcon">
                          <div style="width:18px;height:18px;" class="abcRioButtonSvgImageWithFallback abcRioButtonIconImage abcRioButtonIconImage18">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" class="abcRioButtonSvg">
                              <g>
                                <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                        <span class="abcRioButtonContents">
                          <span id="not_signed_inqxa7b2xmul7l" style="font-family: 'Roboto', sans-serif;">{{'googleLogin' | translate}}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- <button *ngIf="isGoogleSignIn && isAppSifa" (click)="onClickGoogle()" pButton pRipple type="button" icon="pi" label=" {{'google' | translate}}" class="p-button p-button-secondary2 p-button-text mb-3 mr-3 btn-img-sqr">
                    <img alt="logo" src="assets/images/logo/google-logo.svg">
                  </button>
                  <button *ngIf="isAppleSignIn && isAppSifa" (click)="onClickApple()" pButton pRipple type="button" icon="pi" label=" {{'apple' | translate}}" class="p-button p-button-secondary2 p-button-text mb-3 mr-3 btn-img-sqr">
                    <img alt="logo" src="assets/images/logo/apple-logo.svg">
                  </button>
                  <button *ngIf="isMicrosoftSignIn && isAppSifa" (click)="onClickMicrosoft()" pButton pRipple type="button" icon="pi" label=" {{'microsoft' | translate}}" class="p-button p-button-secondary2 p-button-text mb-3 btn-img-sqr">
                    <img alt="logo" src="assets/images/logo/microsoft-logo.svg">
                  </button> -->
                  <!-- <div class="p-inputgroup mb-2" *ngIf="isGoogleSignIn && isAppSifa">
                    <span class="p-inputgroup-addon border-0 p-3 px-4">
                      <img alt="logo" width="20px" src="assets/images/logo/google-logo.svg">
                    </span>
                    <button (click)="onClickGoogle()" pButton pRipple type="button" label="Login or register using Google" class="p-button w-100"></button>
                  </div>
                  <div class="p-inputgroup mb-2" *ngIf="isAppleSignIn && isAppSifa">
                    <span class="p-inputgroup-addon border-0 p-3 px-4">
                      <img alt="logo" width="20px" src="assets/images/logo/apple-logo.svg">
                    </span>
                    <button (click)="onClickApple()" pButton pRipple type="button" label="Login or register using Apple" class="p-button w-100"></button>
                  </div>
                  <div class="p-inputgroup mb-2" *ngIf="isMicrosoftSignIn && isAppSifa">
                    <span class="p-inputgroup-addon border-0 p-3 px-4">
                      <img alt="logo" width="20px" src="assets/images/logo/microsoft-logo.svg">
                    </span>
                    <button (click)="onClickMicrosoft()" pButton pRipple type="button" label="Login or register using Microsoft" class="p-button w-100"></button>
                  </div> -->
              </div>
              <div class="col">
                  <div class="p-divider p-component p-divider-horizontal">
                    <div class="p-divider-content bg-softgrey">{{ 'or' | translate }}</div>
                  </div>
              </div>
              <div class="col">
                <div class="forms mt-0">
                    <div class="login-input-wrapper mb-3">
                      <label for="username">Email</label>
                      <input (keydown.enter)="onClickLoginUseFireEmailandPassword()" id="username" pinputtext="" [(ngModel)]="userName" placeholder="{{'placeholderEmail' | translate}}" class="p-inputtext p-component line-input py-3" />
                      <i class="pi pi-user"></i>
                    </div>
                    <div class="login-input-wrapper mb-3">
                      <label for="password">{{ 'password' | translate }}</label>
                      <input (keydown.enter)="onClickLoginUseFireEmailandPassword()" [type]="fieldTextType ? 'text' : 'password'" [(ngModel)]="password" id="password" placeholder="{{'placeholderPassword' | translate}}" ppassword="" class="p-inputtext p-component line-input py-3" />
                      <i [class]="fieldTextType? 'pi pi-eye': 'pi pi-eye-slash'" (click)="toggleFieldTextType()"></i>
                    </div>
                    <div class="grid center">
                      <!-- <div class="col-6">
                          <p-checkbox name="groupname" value="val1" label="{{'checkboxLogin' | translate}}" [(ngModel)]="selectedValues"></p-checkbox>
                      </div> -->
                      <div class="col-6 px-0 text-right ml-auto">
                          <a href="javascript:;" [routerLink]="['/auth/send-email']" class="secondary-button" [ngStyle]="{ 'color': primaryButtonColor}">{{'forgotPassword' | translate}}</a>
                      </div>
                    </div>
                    
                    <div class="buttons">
                        <button id="login-appfibr" pbutton="" [disabled]="isProgressSpinner" type="button" label="Login" tabindex="0" class="col-12 py-3 p-button text-lg" (click)="onClickLoginUseFireEmailandPassword()"
                        [ngStyle]="{ 'background-color': primaryButtonColor}"
                        >
                          <span class="p-button-label">{{'Login' | translate}}</span>
                          <!-- <p-progressSpinner *ngIf="isProgressSpinner" strokeWidth="3" styleClass="progress-spinner-btn" [style]="{width: '20px', height: '20px'}" ></p-progressSpinner> -->
                        </button>
                    </div>
                    <div class="col-12 center">
                      <a href="javascript:;" [routerLink]="['/auth/register']" pbutton="" type="button" label="Register" tabindex="0" class="p-button-text mt-3"
                        [ngStyle]="{ 'color': primaryButtonColor}"
                      >
                        <span class="p-button-label">{{'RegisterLink' | translate}}</span>
                      </a>
                    </div>

                    <div *ngIf="env.app_name !== 'dentago' && env.app_name !== 'geenoms'" class="col-12 center">
                      <a href="https://appfibr.io" pbutton="" type="button" label="Back to AppFibr website" tabindex="1" class="p-button-text mt-3"
                        [ngStyle]="{ 'color': primaryButtonColor}"
                      >
                        <span class="p-button-label">{{'backTofibrIo' | translate}}</span>
                      </a>
                    </div>

                </div>
              </div>
          </div>
      </div>
      <div class="login-panel right " 
      [ngClass]="{'dentago-banner' : env.app_name === 'dentago', 'geenoms-banner' : env.app_name === 'geenoms'}"
        [ngStyle]="{
          'background-image': 'url(' + image_login + ')',
          'background-size': 'cover',
          'background-position': 'center',
          'background-repeat': 'no-repeat'}"
          >
        <div *ngIf="env.app_name != 'fibr'" class="login-panel-content">
          <!-- <div class="logo">
            <img [src]="logoRight" alt="{{labelAlt}}" />
          </div> -->
          <h4 class="mb-2 text-shadow">{{env.login_right.h4 | translate}}</h4>
          <p class="text-shadow">{{env.login_right.p | translate}}</p>

          <div class="mt-auto">
            <span class="text-shadow">Copyright © 2023 <b *ngIf="['dentago', 'geenoms'].includes(env.app_name)" [ngStyle]="{ 'color': primaryButtonColor, 'font-size': '1.5rem'}">{{env.app_name}}&#8482;</b>
              <img *ngIf="env.app_name === 'fibr'" src="/assets/logo_fibr_footer.svg" height="14">
              <img *ngIf="env.app_name === 'sifa'" src="/assets/logo_fibr_footer.svg" height="14">
            </span>
          </div>
        </div>

        <div class="dentago-banner-logo" *ngIf="['dentago', 'geenoms'].includes(env.app_name)">
          <img class="dentago-banner-logo-img" [src]="image_login" alt="{{labelAlt}}" />
        </div>
      </div>
  </div>
</div>

<!-- sifa & other -->
<div *ngIf="env.app_name !== 'fibr'" class="login-body layout-light">
  <div class="login-content">
      <div class="night-switch">
          <div class="language-menu">
              <p-dropdown (onChange)="onLanguageChange()" [options]="country" [(ngModel)]="selectedLanguage" placeholder="{{'selectLanguage' | translate}}" optionLabel="name" [showClear]="true"></p-dropdown>
          </div>
      </div>
      <div class="login-panel-sifa">
          <div class="login-panel-content text-left">
              <div class="col login-header">
                <div class="logo">
                  <img [src]="logo" alt="{{labelAlt}}" />
                </div>
                <h4 class="mb-2">{{'Fibr-portalLogin' | translate}}</h4>
                <span>{{'WelcomeMessage' | translate}}</span>
              </div>
              <div class="col">
                  <!-- <p> {{'anotherauth' | translate}} </p> -->
                  <div class="p-inputgroup" *ngIf="isGoogleSignIn">
                    <span class="p-inputgroup-addon border-0 py-3 px-4">
                      <img alt="logo" height="30px" src="assets/images/logo/google-logo.svg">
                    </span>
                  <button (click)="onClickGoogle()" pButton pRipple type="button" label="{{'googleLogin' | translate}}" class="p-button w-100"></button></div>
              </div>
              <div class="col">
                  <div class="p-divider p-component p-divider-horizontal">
                    <div class="p-divider-content bg-softgrey">OR</div>
                  </div>
              </div>
              <div class="col">
                <div class="forms mt-0">
                    <div class="login-input-wrapper mb-3">
                      <label for="username">Email</label>
                      <input id="username" pinputtext="" [(ngModel)]="userName" placeholder="{{'placeholderEmail' | translate}}" class="p-inputtext p-component line-input py-3"  [ngStyle]="{'display':'flex'}" />
                      <i class="pi pi-user"></i>
                    </div>
                    <div class="login-input-wrapper mb-3">
                      <label for="password">Password</label>
                      <input [type]="fieldTextType ? 'text' : 'password'" [(ngModel)]="password" id="password" placeholder="{{'placeholderPassword' | translate}}" ppassword="" class="p-inputtext p-component line-input py-3" [ngStyle]="{'display':'flex'}"/>
                      <i [class]="fieldTextType? 'pi pi-eye': 'pi pi-eye-slash'" (click)="toggleFieldTextType()"></i>
                    </div>
                    <div class="grid center">
                      <div class="col-6">
                          <p-checkbox name="groupname" value="val1" label="{{'checkboxLogin' | translate}}" [(ngModel)]="selectedValues"></p-checkbox>
                      </div>
                      <div class="col-6 px-0 text-right">
                          <a href="javascript:;" [routerLink]="['/auth/send-email']" class="secondary-button" [ngStyle]="{ 'color': primaryButtonColor}">{{'forgotPassword' | translate}}</a>
                      </div>
                    </div>
                    
                    <div class="buttons">
                        <button pbutton="" [disabled]="isProgressSpinner" type="button" label="Login" tabindex="0" class="col-12 py-3 p-button text-lg" (click)="onClickLoginUseFireEmailandPassword()"
                        [ngStyle]="{ 'background-color': primaryButtonColor}"
                        >
                          <span class="p-button-label">{{'Login' | translate}}</span>
                          <p-progressSpinner *ngIf="isProgressSpinner" strokeWidth="3" styleClass="progress-spinner-btn" [style]="{width: '20px', height: '20px'}" ></p-progressSpinner>
                        </button>
                    </div>
                    <div *ngIf="env.app_name !== 'dentago' && env.app_name !== 'geenoms'" class="col-12 center">
                      <a href="javascript:;" [routerLink]="['/auth/register']" pbutton="" type="button" label="Register" tabindex="0" class="p-button-text mt-3"
                        [ngStyle]="{ 'color': primaryButtonColor}"
                      >
                        <span class="p-button-label">{{'RegisterLink' | translate}}</span>
                      </a>
                    </div>
                </div>
              </div>
          </div>
      </div>
      <div class="login-panel-sifa right " 
      [ngClass]="{'dentago-banner' : env.app_name === 'dentago', 'geenoms-banner' : env.app_name === 'geenoms'}"
        [ngStyle]="{
          'background-image': 'url(' + image_login + ')',
          'background-size': 'cover',
          'background-position': 'center',
          'background-repeat': 'no-repeat'}"
          >
        <div class="login-panel-content">
          <!-- <div class="logo">
            <img [src]="logoRight" alt="{{labelAlt}}" />
          </div> -->
          <h4 class="mb-2">{{env.login_right.h4 | translate}}</h4>
          <p>{{env.login_right.p | translate}}</p>

          <div class="mt-auto">
            <span>Copyright © 2023 <b *ngIf="['dentago', 'geenoms'].includes(env.app_name)" [ngStyle]="{ 'color': textColorCopy, 'font-size': '1.5rem'}">{{env.app_name}}&#8482;</b>
              <img *ngIf="env.app_name === 'fibr'" src="/assets/logo_fibr_footer.svg" height="14">
              <img *ngIf="env.app_name === 'sifa'" src="/assets/logo_fibr_footer.svg" height="14">
            </span>
          </div>
        </div>

        <div class="dentago-banner-logo" *ngIf="['dentago', 'geenoms'].includes(env.app_name)">
          <img class="dentago-banner-logo-img" [src]="image_login" alt="{{labelAlt}}" />
        </div>
      </div>
  </div>
</div>
<!-- end div -->
</div>

<ui-fibr-blockui-login [blocked]="isBlocked"></ui-fibr-blockui-login>


