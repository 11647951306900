/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalcrudService, LoaderService, SessionService, ToastNotificationService } from '@core-fibr/shared';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'libs/auth/src/environments/environment';
import { GlobalService } from 'libs/shared/src/lib/services/global.service';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Lang } from '../../models/user-info';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { CaptchaService } from '../captcha/captcha.service';

@Component({
  selector: 'ui-fibr-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public env = environment;

  userName: string | undefined;
  password: string | undefined;
  fullName: string | undefined;
  confirmPassword: string | undefined;
  locale: string | undefined;
  version: string | undefined;
  returnUrl='';
  errValidasi: string | undefined ;

  isProgressSpinner= false;
  selectedValues= false;
  msgs: any;
  country: any;
  isAdditional = environment.app_name == 'fibr'? false : true;

  logo = environment.logo;
  logoRight = environment.logo_right;
  labelAlt = environment.app_name;
  image_login = environment.image_login;
  image_register = environment.image_register;
  isAppSifa = environment.app_name == 'fibr';
  primaryButtonColor = environment.primary_button_color;
  
  registerNameLabel = environment.registerNameLabel_en;
  nextStage = environment.nextStage;
  isBlocked = false;
  countGetToken = 0;

  selectedLanguage: Lang | undefined;
  private unsubscribe: Subject<any> | undefined;
  captchaStatus:any = null;
  captchaConfig:any = {
    length:6,
    cssClass:'custom',
    back: {
      stroke:"#2F9688",
      solid:"#fff"
    } ,
    font:{
      color:"#000000",
      size:"35px"
    }
  };

  constructor(
    private router: Router,
    public translate: TranslateService,
    public sessionService:  SessionService,
    public loaderService: LoaderService,
    public authenticationService: AuthenticationService,
    private cdr : ChangeDetectorRef,
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private notif: ToastNotificationService,
    private captchaService: CaptchaService,
    private globalCrudService: GlobalcrudService,
    private authService: AuthenticationService,
  ) { 
    this.unsubscribe = new Subject();
    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params.returnUrl || "/portal";
    });
    if(environment.app_name == 'fibr') this.isAdditional = true;
    else this.isAdditional = false;
  }

  ngOnInit(): void {
    this.country = [
      {name: 'English', code: 'en'},
      {name: 'Indonesia', code: 'id'},
    ];
    this.userName = "";
    this.password = "";
    this.fullName = "";
    this.confirmPassword = "";
    this.route.queryParams.subscribe((params) => {
      this.userName = params.userName;
      this.fullName = params.fullName;
      this.password = params.password;
      this.confirmPassword = params.password;
    });
    this.locale = this.sessionService.getItem("fibr-language") || 'en';
    this.registerNameLabel = this.locale == 'en'? environment['registerNameLabel_en']: environment['registerNameLabel_id'];
    this.selectedLanguage = this.country.filter((x : any) => x.code == this.locale)[0];
    this.version = environment.version;
    this.msgs = [{ severity: 'info', detail: 'UserName: admin' }, { severity: 'info', detail: 'Password: password' }];
    this.captchaService.captchStatus.subscribe((status)=>{
      this.captchaStatus= status;
      // if (status == false) {
      //   alert("Opps!\nCaptcha mismatch");
      // } else  if (status == true) {
      //   alert("Success!\nYou are right");
      // }
    });
  }

  async onClickRegisterUseFireEmailandPassword() {
    this.globalService.logPageView('registrationStart');
    this.errValidasi = this.validasi({captchaStatus: this.captchaStatus, password: this.password, fullName: this.fullName, 
      confirmPassword: this.confirmPassword, selectedValues: this.selectedValues}) 
    if (this.errValidasi !== '') {
      this.notif.showNotification('error',this.errValidasi);
      this.isProgressSpinner = false;
      return;
    }
    this.isProgressSpinner = true;
    const body = {email: this.userName, password: this.password, fullName: this.fullName}
    /**hit api BE */
    this.globalService.methodPost(environment.be_dev + `/api/v1/fibr-app/creator/auth/register`, body)
    .subscribe((res) => {
      if(res){
        setTimeout(() => {
          this.globalService.methodGet(environment.be_dev + `/api/v1/sendmail/registration_verification/` + res.data.uid
          ).subscribe((res) => {
            // 
          },
          (err: any) => {
            this.notif.showNotification(err?.error?.status, err?.error?.message);
          });
          this.isProgressSpinner = false;
          this.notif.showNotification('success', 'Registration successfully, Please check your mail for activate!');
          this.router.navigateByUrl('auth/login');
        }, 5000);
      } else {
        this.isProgressSpinner = false;
      }
    },
    (err: any) => {
      console.log(err?.error)
      this.notif.showNotification(err?.error?.status, err?.error?.message);
      this.isProgressSpinner = false;
    });
    

    /**manual from FE*/
    // await this.authenticationService.registerFireEmailandPassword({email: this.userName, password: this.password, fullName: this.fullName})
    // .then((result) => {
    //         if(result){
    //           /** get api for create field  is_email_verification */
    //           this.globalService.methodGet(environment.be_dev + `/api/v1/sendmail/registration_verification/` + result.uid);
    //           setTimeout(() => {
    //             this.isProgressSpinner = false;
    //             this.notif.showNotification('success', 'Registration successfully, Please check your mail for activate!');
    //             this.router.navigateByUrl('auth/login');
    //           }, 100000);
    //         }else{
    //           this.isProgressSpinner = false;
    //         }
    // })
    // .catch(() => this.isProgressSpinner = false),
    // takeUntil((this.unsubscribe as Observable<any>)),
    // finalize(() => {
    //   this.isProgressSpinner = false;
    //   this.cdr.markForCheck();
    // })
  }

  async onClickRegisterUseFireEmailandPasswordAdditional() {
    this.isProgressSpinner = true;
    this.globalService.logPageView('registrationStart');
    this.errValidasi = this.validasi({captchaStatus: this.captchaStatus, password: this.password, fullName: this.fullName, 
      confirmPassword: this.confirmPassword, selectedValues: this.selectedValues}) 
    if (this.errValidasi !== '') {
      this.notif.showNotification('error',this.errValidasi);
      this.isProgressSpinner = false;
      return;
    }
    
    this.isProgressSpinner = true;
    const body = {email: this.userName, password: this.password, name: this.fullName}
    /**hit api BE */
    this.globalService.methodPost(environment.be_dev + `/api/v1/fibr-app/creator/auth/register`, body)
    .subscribe((res) => {
      if(res){
        setTimeout(() => {
          this.globalService.methodGet(environment.be_dev + `/api/v1/sendmail/registration_verification/` + res.data.uid
          ).subscribe((res) => {
            // 
          },
          (err: any) => {
            this.notif.showNotification(err?.error?.status, err?.error?.message);
          });
          this.isProgressSpinner = false;
          this.notif.showNotification('success', 'Registration successfully, Please check your mail for activate!');
          this.router.navigateByUrl('auth/login');
        }, 5000);
      } else {
        this.isProgressSpinner = false;
      }
    },
    (err: any) => {
      console.log(err?.error)
      this.notif.showNotification(err?.error?.status, err?.error?.message);
      this.isProgressSpinner = false;
    });
    /** exec with FE */
    // await this.authenticationService
    //   .registerFireEmailandPasswordAdditional({email: this.userName, password: this.password, fullName: this.fullName})
    //   .then((result) => {
    //     /** get api for create field  is_email_verification */
    //     // console.log(result.uid)
    //     if(result){
    //           setTimeout(() => {
    //             // console.log(environment.be_dev + `/api/v1/sendmail/registration_verification/` + result.uid)
    //             this.globalService.methodGet(environment.be_dev + `/api/v1/sendmail/registration_verification/` + result.uid
    //             ).subscribe((res) => {
    //               // this.notif.showNotification(res.status, res.message);
    //             },
    //             (err: any) => {
    //               // console.log(err?.error?.status)
    //               this.notif.showNotification(err?.error?.status, err?.error?.message);
    //             });
    //             this.isProgressSpinner = false;
    //             this.notif.showNotification('success', 'Registration successfully, Please check your mail for activate!');
    //             this.router.navigateByUrl('auth/login');
    //           }, 10000);
    //         } else {
    //           this.isProgressSpinner = false;
    //         }
    //     }
    //   )
    //   .catch(() => this.isProgressSpinner = false),
    //   takeUntil((this.unsubscribe as Observable<any>)),
    //   finalize(() => {
    //     this.isProgressSpinner = false;
    //     this.cdr.markForCheck();
    // })
  }

  onClickGoogle() {
    this.globalService.logPageView('registrationStart');
    this.authenticationService.signInWithPopup().then((result: any) => {
      const pathProfile = `users/${result?.uid}/profile`;
      this.getUser(pathProfile, result.isExists);
    }),
      takeUntil(this.unsubscribe as Observable<any>),
      finalize(() => {
        this.isProgressSpinner = false;
        this.isBlocked = false;
        this.cdr.markForCheck();
      });
  }

  getUser(pathProfile: any, isExists: any) {
    this.globalCrudService.getcollectionPromise(pathProfile).then((res) => {
      if (res.length > 0) {
        const userInfo: any = res[0];
        if (!res[0]) {
          this.getUser(pathProfile, isExists);
        } else {
          if (isExists || environment.app_name == "fibr") {
              this.getToken(userInfo)
          } else {
              // setTimeout(() => {
              //   this.isBlocked = true;
              //   this.globalService.setRefDataToLocalstorage("user_profile", userInfo);
              //   this.router.navigateByUrl(this.returnUrl);
              //   this.isProgressSpinner = false;
              // }, 60000);
          }
        }
      } else {
        this.authService.clearAuthToken();
          this.notif.showNotification('error', 'Register With Google Failed!');
          setTimeout(() => {
            location.reload();
        }, 3000);
      }
    });
  }

  getToken(userInfo: any) {
    this.globalService.isStatusLogin.subscribe((val: boolean) => { 
      this.isProgressSpinner = val;
      this.isBlocked = val;
    });
    const pathUser = `/users/${userInfo?.uid}/profile/`;
    this.globalService.methodPost(environment.be_dev + `/api/v1/fibr-app/token`, { uid: userInfo.uid }
    ).subscribe((response) => {
      setTimeout(() => {
        userInfo.accessToken = response?.data?.accessToken;
        userInfo.refreshToken = response?.data?.refreshToken;
        userInfo.last_login = new Date();
        this.globalCrudService.modifyRefMergeCollection(userInfo,pathUser,'data');
        this.globalService.setRefDataToLocalstorage("user_profile", userInfo);
        this.saveLog(userInfo);
        this.router.navigateByUrl(this.returnUrl);
        this.isBlocked = false;
        this.isProgressSpinner = false
      }, 3000);
    },
      (err: any) => {
        if (this.countGetToken < 3) {
          setTimeout(() => {
            this.getToken(userInfo);
          }, 2000);
          this.countGetToken++;
        } else {
          this.authService.clearAuthToken();
          this.notif.showNotification('error', 'failed to get tokens!');
          setTimeout(() => {
              location.reload();
          }, 2000);
          this.isBlocked = false;
          this.isProgressSpinner = false
        }
      });
  }

  saveLog(userInfo: any) {
    const path = `/users/${userInfo?.uid}/login_history`;
    const body = {
      uid: userInfo.uid,
      created_date: new Date(),
      browser_name : '',
      browser_ver: ''
    };
    this.globalCrudService.createcollection(body,path);
  }

  onLanguageChange() {
    this.locale = this.selectedLanguage?.code;
    if (this.locale == undefined || this.locale == null || this.locale.length == 0) {
      this.locale = "en";
    }
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(this.locale);
    this.sessionService.setItem("fibr-language", this.locale);
    this.registerNameLabel = this.locale == 'en'? environment['registerNameLabel_en']: environment['registerNameLabel_id'];
  }

  validasi(val: any)  {
    let errValidasi2 = '';
    if(val.selectedValues === false) {
      errValidasi2 = 'T&C dan Privacy - Must be check in';
    }
    if(val.password === undefined) {
      errValidasi2 = 'password cannot be empty!';
    } else {
      if (val.password !== val.confirmPassword){
        errValidasi2 = 'Those passwords didn’t match. Try again.';
      }
    } 
    if (Number(val.fullName?.length) > 50) {
      errValidasi2 = 'Full Name cannot be more than 50 characters!';
    }
    if(!val.captchaStatus){
      errValidasi2 = 'Opps!\nCaptcha mismatch';
    }
    return errValidasi2;
  }

}
