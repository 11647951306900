<!-- <ng-container  *ngFor="let item of itemPages | slice:1; let i=index">
  <div *ngIf="item.icon !== 'pi pi-fw pi-plus' && item.state?.pageProperty.isMenu" (click)="onClick(item)" class="col m-auto p-0 px-2">
      <i class="material-icons material-icons-outlined text-3xl" [ngClass]="idMenu === item.id ? 'text-active' : ''">
          {{item.state?.pageProperty.pageIcon}}
      </i>
      <label [ngClass]="idMenu === item.id ? 'text-active' : ''">{{item.state?.pageProperty.pageTitle}}</label>
  </div>
</ng-container> -->

<ng-container *ngFor="let item of itemRole | slice:1; let i=index">
    <div *ngIf="item.icon !== 'pi pi-fw pi-plus' && item.state?.pageProperty.isMenu" (click)="onClick(item)" class="col m-auto p-0 px-2">
        <!-- <i class="material-icons material-icons-outlined text-3xl" [ngClass]="idMenu === item.id ? 'text-active' : ''" pBadge severity="danger">
            {{item.state?.pageProperty.pageIcon}}
        </i> -->
        <i class="material-icons material-icons-outlined text-3xl" [ngClass]="idMenu === item.id ? 'text-active' : ''">
          {{item.state?.pageProperty.pageIcon}}
        </i>
        <label [ngClass]="idMenu === item.id ? 'text-active' : ''">{{item.state?.pageProperty.pageTitle}}</label>
    </div>
</ng-container>

<!-- <ng-container *ngFor="let item of itemApp | slice:1; let i=index">
  <div *ngIf="item.icon !== 'pi pi-fw pi-plus' && item.state?.pageProperty.isMenu" (click)="onClick(item)" class="col m-auto p-0 px-2">
      <i class="material-icons material-icons-outlined text-3xl" [ngClass]="idMenu === item.id ? 'text-active' : ''" pBadge severity="danger">
          {{item.state?.pageProperty.pageIcon}}
      </i>
      <label [ngClass]="idMenu === item.id ? 'text-active' : ''">{{item.state?.pageProperty.pageTitle}}</label>
  </div>
</ng-container> -->
