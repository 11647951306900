
  <div class="splash-screen bg-app" [ngStyle]="{'background-image': 'url(' + appConfig.splash_screen + ')'}">
  </div>
  
  <div  class="mobile-comp mobile-form">
    <form #f="ngForm" *ngIf="!is_otp">
      <div class="p-grid p-fluid">
        <!-- <img class="img-app" src="/assets/logo_appfibr_vertical.svg" alt="AppFibr Logo"/> -->
        <img class="img-app" [src]="logo" alt="AppFibr Logo"/>

        <div class="field" *ngFor="let item of elementForm">
          <ng-container *ngIf="item.field === 'email'">
              <span class="relative">
                  <p class="form-label">{{item.label}}</p>
                  <input [name]="item.field" type="text" pInputText [(ngModel)]="item.value" >
              </span>
          </ng-container>
        </div>

        <div class="grid" >
            <div class="col-12 p-0">
                <button pButton pRipple class="p-button p-component w-100" (click)="onClickSendEmail(f)">Send</button>
            </div>
        </div>
      </div>
    </form>

    <form #f2="ngForm" *ngIf="is_otp">
      <div class="p-grid p-fluid">
        <!-- <img class="img-app" src="/assets/logo_appfibr_vertical.svg" alt="AppFibr Logo"/> -->
        <img class="img-app" [src]="logo" alt="AppFibr Logo"/>
        <div class="field" *ngFor="let item2 of elementForm2">
          <ng-container *ngIf="item2.field === 'otp'">
            <span class="relative">
                <p class="form-label">{{item2.label}}</p>
                <input [name]="item2.field" type="text" pInputText [(ngModel)]="item2.value">
            </span>
          </ng-container>
          <ng-container *ngIf="item2.field === 'password'">
            <span class="relative">
                <p class="form-label">{{item2.label}}</p>
                <input [name]="item2.field" type="password" pInputText [(ngModel)]="item2.value">
            </span>
          </ng-container>
        </div>

        <div class="grid" >
            <div class="col-12 p-0">
                <button pButton pRipple class="p-button p-component w-100" (click)="onClickResetPassword(f2)">Reset Password</button>
            </div>
            <div class="col-12 mt-2 z-1">
              <a href="javascript:;" (click)="onClickResendEmail()" pbutton="" type="button" label="Register" tabindex="0" class="p-button-text mt-3">
                <span class="p-button-label center">Resend Email</span>
              </a>
            </div>
        </div>
      </div>
    </form>

    <div class="grid" >
      <div class="col-12 mt-2 z-1">
        <a href="javascript:;" (click)="onClickLogin()" pbutton="" type="button" label="Register" tabindex="0" class="p-button-text mt-3">
          <span class="p-button-label center">Back to Login</span>
        </a>
      </div>
    </div>
  </div>

  
    