<p-dataView #dv [value]="data" [paginator]="true" [rows]="11" filterBy="name" [sortField]="sortField" [sortOrder]="sortOrder" layout="grid" class="project-dataview">
        <!-- <ng-template pTemplate="header"> -->
            <!-- <div class="flex flex-column md:flex-row p-jc-md-between">
                <div class="p-inputgroup md:col-4 col-12 mb-3 p-0">
                    <input type="text" pInputText placeholder="Search" (input)="dv.filter($any($event.target).value)"> 
                    <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>        
                </div>
                <div class="col-auto ml-auto p-0">
                    <span class="mr-3"><i class="pi pi-sort-amount-down mr-2"></i> Sort by</span>
                    <p-dropdown [options]="sortOptions" [(ngModel)]="sortKey" placeholder="Sort By Price" (onChange)="onSortChange($event)"></p-dropdown>
                </div>
            </div> -->
        <!-- </ng-template> -->
        <ng-template let-template pTemplate="listItem">
            <div class="col-12">
                <div class="template-list-item">
                    <div class="template-list-detail">
                        <img [src]="template.app_icon" [alt]="template.name" onerror="this.src='/assets/iconic_logo.png'">
                        <div class="template-name">{{template.app_name}}</div>
                        <i class="pi pi-tag template-category-icon"></i><span class="template-category">{{template.is_publish?'Published' :  'Draft'}}</span>
                    </div>
                    <div class="template-list-action">
                        <span class="template-price">${{template.price}}</span>
                        <span [class]="'template-badge'">{{template.inventoryStatus}}</span>
                    </div>
                </div>
            </div>
        </ng-template>
        
        <ng-template let-template pTemplate="gridItem" let-i="rowIndex">
            <div class="col-12 md:col-6 lg:col-4" [ngClass]="{'hidden': i != 0}">
                <p-accordion styleClass="acc-usage" (click)="showProjectTemplate=true;">
                    <p-accordionTab class="acc-btn-new">
                        <ng-template pTemplate="header">
                            <div class="list-item p-0">
                                <span class="p-menuitem-icon pi pi-fw pi-plus-circle text-7xl mr-3"></span>
                                <div class="list-detail">
                                    <h5 class="list-title m-0">{{'project.create.new' | translate}}</h5>
                                </div>
                                <div class="product-list-action">
                                    <span class="p-menuitem-icon pi pi-fw pi-chevron-right p-5"></span>
                                </div>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
            </div>

            <div class="col-12 md:col-6 lg:col-4">
                <!-- <div class="grid project-grid-item center">
                    <div class="col-3 p-0">
                        <img class="w-100 align-middle py-3" [src]="template.app_icon" [alt]="template.name" onerror="this.src='/assets/iconic_logo.png'">
                    </div>
                    <div class="col-7 item-content p-3">
                        <span class="p-1">{{template.created_date.seconds.toDate() | date:'dd MMM yyyy'}}</span>
                        <span class="text-xs mb-0" *ngIf="template.updated_date">{{template.updated_date.toDate() | date:'dd MMM yyyy'}}</span><br/>
                        <span class="text-lg mb-1">{{template.app_name}}</span><br/>
                        <span class="p-tag p-component" [ngClass]="template.is_publish == 'Published' ? 'p-tag-success' : 'p-tag-secondary'"><span class="p-tag-value">{{(template.is_publish ? 'Published' :  'Draft') | uppercase}}</span></span>
                    </div>
                    <div class="col-2">
                        <p-button (onClick)="onclick('delete', template)" styleClass="p-button-danger p-button-text" icon="pi pi-trash"></p-button>                
                        <p-button (onClick)="onclick('edit', template)" styleClass="p-button-primary p-button-text" lable icon="pi pi-pencil"></p-button>
                    </div>
                </div> -->
                <p-accordion styleClass="acc-usage">
                <p-accordionTab>
                        <ng-template pTemplate="header">
                            <div class="list-item p-0">
                                <img [src]="template.app_icon" [alt]="template.name" onerror="this.src='/assets/iconic_logo.png'">
                                <div class="list-detail" (click)="onclick('edit', template)">
                                    <span class="text-xs mb-0" *ngIf="template?.updated_date">{{(template.updated_date['seconds']*1000) | date:'dd MMM yyyy'}}</span>
                                    <h5 class="list-title mt-0 mb-1">{{template?.app_name}}</h5>
                                    <!-- <span class="p-tag p-component" [ngClass]="template.is_publish ? 'p-tag-success' : 'p-tag-secondary'"><span class="p-tag-value">{{(template.is_publish ? 'Published' :  'Draft') | uppercase}}</span></span> -->
                                    <ui-fibr-detail-project [dataProject]="template"></ui-fibr-detail-project>
                                </div>
                                <div class="product-list-action" *ngIf="!template.is_paid">
                                    <p-button id="dropdown-project-list" (click)="more.toggle($event);getProject(template);" styleClass="p-button p-button-text" icon="pi pi-ellipsis-v"> </p-button>
                                    <p-menu #more [popup]="true" [model]="itemProject" appendTo="body"></p-menu>
                                    <!-- <p-button (onClick)="onclick('delete', template)" styleClass="p-button-danger p-button-text" icon="pi pi-trash" pTooltip="Delete Project" tooltipPosition="bottom"></p-button>                
                                    <p-button (onClick)="onclick('edit', template)" styleClass="p-button-primary p-button-text" lable icon="pi pi-pencil" pTooltip="Edit Project" tooltipPosition="bottom"></p-button> -->
                                </div>
                                <div class="product-list-action" *ngIf="template.is_paid">
                                    <p-button id="dropdown-project-list" (click)="more.toggle($event);getProject(template);" styleClass="p-button p-button-text" icon="pi pi-ellipsis-v"> </p-button>
                                    <p-menu #more [popup]="true" [model]="itemProjectPro" appendTo="body"></p-menu>
                                </div>
                            </div>
                        </ng-template>
                        <!-- <ng-template pTemplate="content">
                            <div class="grid">
                                <div class="col-12">
                                    <p>User <b>100 / 1.000</b></p>
                                    <p-progressBar styleClass="progress-sm" value="10"></p-progressBar>
                                </div>
                                <div class="col-12">
                                    <p>Storage <b>50 MB / 1.000 MB</b></p>
                                    <p-progressBar styleClass="progress-sm" value="5"></p-progressBar>
                                </div>
                            </div>
                        </ng-template> -->
                </p-accordionTab>
                </p-accordion>
            </div>
        
            <!-- <div class="col-12 md:col-3">
                <div class="template-grid-item card">
                    <div class="template-grid-item-top">
                        <div>
                            <i class="pi pi-tag template-category-icon"></i>
                            <span class="template-category">{{template.is_publish?'Published' :  'Draft'}}</span>
                        </div>
                    </div>
                    <div class="template-grid-item-content">
                    <img [src]="template.app_icon" [alt]="template.name" onerror="this.src='/assets/logo_fibr_footer.svg'">
                    <div class="template-name">{{template.app_name}}</div>
                    </div>
                    <div class="template-grid-item-bottom">
                        <p-button (onClick)="onclick('edit', template)" styleClass="p-button-primary p-button-text" lable icon="pi pi-pencil"></p-button>
                        <p-button (onClick)="onclick('delete', template)" styleClass="p-button-danger p-button-text" icon="pi pi-trash"></p-button>
                    </div>
                </div>
            </div> -->
        </ng-template>
</p-dataView>

<p-dialog [(visible)]="showProjectTemplate" *ngIf="showProjectTemplate" [modal]="true" [blockScroll]="true" [draggable]="false" [dismissableMask]="true" appendTo="body" styleClass="dialog-no-footer" [style]="{width: '90%'}">
    <ng-template pTemplate = "header">
        <span class="p-dialog-title"><img class="align-self-center mr-3" alt="icon" src="/assets/images/icon-lg/icon_billing-lg.svg" height="20">{{'project.create.new' | translate}}</span>
    </ng-template>

    <div class="grid">
      <div class="col-12 text-center mt-5">
        <p-button label="{{'project.create.new.scratch' | translate}}" (click)="onclickNewProject()" icon="pi pi-plus" iconPos="right" styleClass="p-button-lg"> </p-button>
      </div>
    </div>

    <p-divider styleClass="my-6"></p-divider>

    <div class="grid">
        <div class="col-12">
            <div class="flex p-flex-column p-flex-md-row">
                <img class="align-self-center mr-3" alt="icon" src="/assets/images/icon-lg/icon_billing-lg.svg" height="20">
                <h4 class="menu-title">{{'project.create.new.template' | translate}}</h4>
            </div>
        </div>
    </div>

    <div class="grid">
        <div class="col-6 md:col-4 lg:col-3" *ngFor="let item of dataTemplate">
            <div class="p-card-shadow p-card p-component card-project-template" (click)="onClickTemplate(item)">
                <div class="p-card-header">
                    <img alt="Project Template Image" [src]="item.app_icon" class="img-template" onerror="this.src='/assets/iconic_logo.png'">
                </div>
                <div class="p-card-body p-3 pb-0">
                    <div class="list-item p-0">
                        <div class="list-detail d-flex flex-row align-items-center">
                            <!-- <img src="/assets/images/icon/icon_support.svg" alt="Icon Support"> -->
                            <div class="d-block">
                                <span class="text-xs mb-0"> {{(item?.updated_date['seconds']*1000) | date : 'dd MMM yyyy'}} </span>
                                <h5 class="list-title mt-0 mb-1" pTooltip="{{item?.app_name}}" tooltipPosition="bottom"> {{item?.app_name}} </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-dialog>