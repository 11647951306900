import { Injectable, NgZone, PLATFORM_ID } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { SessionService } from "../session.service";
import { of, throwError } from "rxjs";
import { catchError, first } from "rxjs/operators";
import { ToastNotificationService } from "../toast-notification.service";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from "libs/auth/src/environments/environment";
import { GlobalCrudPromisesService, GlobalcrudService, GlobalService } from "@core-fibr/shared";
import { AuthenticationService } from "@core-fibr/auth";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  env: any;
  // public userProfile: any;

  constructor(
    private globalService: GlobalService, 
    private globalCrudService : GlobalcrudService, 
    private globalCrudPromisesService : GlobalCrudPromisesService,
    private router: Router,
    private authService: AuthenticationService,
    public notificationService: ToastNotificationService
  ) {}

  // swicthFirebase(userProfile: any) {
  swicthFirebase(userProfile: any, appId: any) {
    // this.globalService.testEncrypDecryp();
    this.env = this.globalService.decryptEnv(environment);
    const pathS = `/users/${userProfile.uid}/projects/${appId}/firebase`;
    this.globalCrudService.getcollection(pathS).subscribe((res: any) => { 
      let firebaseConfig = this.env.firebase;
      const credentials = {
        email: environment.config.username,
        password: environment.config.password
      };
      if (res.length == 0) {
        firebaseConfig = this.env.firebase;
      } else {
        if (res[0]?.state != 'ACTIVE') {
          firebaseConfig = this.env.firebase; 
        } else {
          if (!res[0]?.apiKey) res[0].apiKey = this.env.firebase.apiKey;
            firebaseConfig = res[0];
        }
      }
      this.globalCrudService.dynamicFirebase(firebaseConfig, false, credentials);
      this.globalCrudPromisesService.dynamicFirebase(firebaseConfig, false, credentials);
    });

    // console.log(userProfile.firebase.state);
    /** old setting */
    // let firebaseConfig = this.this.env.firebase;
    // const credentials = {
    //   email: environment.config.username,
    //   password: environment.config.password
    // };
    // if(userProfile.firebase.state != 'ACTIVE') {
    //   firebaseConfig = this.this.env.firebase;
    // }else{
    //   firebaseConfig = userProfile.firebase;
    // }
    // this.globalCrudService.dynamicFirebase(firebaseConfig, false, credentials);
    // this.globalCrudPromisesService.dynamicFirebase(firebaseConfig, false, credentials);
  }

  swicthFirebaseGrid(userProfile: any, appId: any, setting_fibr?:string) {
    this.env = this.globalService.decryptEnv(environment);
    let firebaseConfig = this.env.firebase;
    const pathS = `/users/${userProfile}/projects/${appId}/firebase`;
    const credentials = {
      email: environment.config.username,
      password: environment.config.password
    };
    if (setting_fibr == 'userapp_fibr' || setting_fibr == 'roleapp_fibr' || setting_fibr == 'detail_payment_transaction_fibr') {
      firebaseConfig = this.env.firebase;
      this.globalCrudService.dynamicFirebase(firebaseConfig, false, credentials);
      this.globalCrudPromisesService.dynamicFirebase(firebaseConfig, false, credentials);
    } else {
      this.globalCrudService.getcollection(pathS).subscribe((res: any) => { 
        if (res.length == 0) {
          firebaseConfig = this.env.firebase;
        } else {
          // if (setting_fibr == 'userapp_fibr' || setting_fibr == 'roleapp_fibr' || setting_fibr == 'detail_payment_transaction_fibr') {
          //   firebaseConfig = this.env.firebase;
          // } else {
            if (res[0]?.state != 'ACTIVE') {
              firebaseConfig = this.env.firebase; 
            } else {
              if (!res[0]?.apiKey) res[0].apiKey = this.env.firebase.apiKey;
              firebaseConfig = res[0];
            }
          // }
        }
        this.globalCrudService.dynamicFirebase(firebaseConfig, false, credentials);
        this.globalCrudPromisesService.dynamicFirebase(firebaseConfig, false, credentials);
      });
    }
  }
  
}
