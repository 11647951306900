<div>
  <div class="grid">
      <div class="col-12 mb-2">
        <h5 class="menu-subtitle mb-1 mb-3">{{'purpose' | translate}}<span class="text-magenta pr-5 mb-2">*</span></h5>
        <textarea type="text" class="col-12" [rows]="5" autoResize="true" pInputText [(ngModel)]="purpose_create"></textarea>
        <sub *ngIf="submitClicked && !purpose_create" class="text-magenta alert"><i>*{{'editor.pages.form.validation.required' | translate}}</i></sub>
      </div>
      <div class="col-12 mb-2">
        <h5 class="menu-subtitle mb-1">{{'term' | translate}}</h5>
        <span class="flex align-items-center justify-content-between text-magenta pr-5 mb-2">
          *{{'read_scroll' | translate}}
        </span> 
        <div class="mt-4">
          <p-scrollPanel [style]="{width: '100%', height: '100px', border: '1px #ccc solid'}">
            <h5 class="text-center">{{'payment_approval' | translate}}</h5>
            {{'payment_approval_term' | translate}}
            <br><br>
            <h6>1. {{'payment_approval_1' | translate}}</h6>
            {{'payment_approval_1_content' | translate}}
            <br><br>
            <h6>2. {{'payment_approval_2' | translate}}</h6>
            {{'payment_approval_2_content' | translate}}
            <br><br>
            <h6>3. {{'payment_approval_3' | translate}}</h6>
            <h6>3.1 {{'payment_approval_3.1' | translate}}</h6>
            {{'payment_approval_3.1_content' | translate}}
            <br>
            {{'payment_approval_3.1_content_2' | translate}}
            <br><br>
            <h6>3.2 {{'payment_approval_3.2' | translate}}</h6>
            {{'payment_approval_3.2_content' | translate}}
            <h6>3.3 {{'payment_approval_3.3' | translate}}</h6>
            {{'payment_approval_3.3_content' | translate}}
            <br><br>
            <h6>4. {{'payment_approval_4' | translate}}</h6>
            {{'payment_approval_4_content' | translate}}
            <br>
            {{'payment_approval_4_content_2' | translate}}
            <br><br>
            <h6>5. {{'payment_approval_5' | translate}}</h6>
            {{'payment_approval_5_content' | translate}}
            <br><br>
            <h6>6. {{'payment_approval_6' | translate}}</h6>
            {{'payment_approval_6_content' | translate}}
            <br><br>
            <h6>7. {{'payment_approval_7' | translate}}</h6>
            {{'payment_approval_7_content' | translate}}
            <br><br>
            <h6>8. {{'payment_approval_8' | translate}}</h6>
            {{'payment_approval_8_content' | translate}}
            <br><br>
            {{'payment_approval_integrating' | translate}}
          </p-scrollPanel>
        </div>
        <div class="field-checkbox mt-3">
            <p-checkbox [(ngModel)]="is_agree" name='add' [binary]="true" inputId="id-agree"></p-checkbox>
            <label for="id-agree">{{'read_agree' | translate}}</label>
        </div>
      </div>
  </div>
  <div class="grid text-center justify-content-center p-5">
    <div class="col-auto center flex-column mx-4">
        <p-button icon="pi pi-save" label="{{'submit' | translate}}" styleClass="p-button p-button-primary" (click)="onSubmit()" [disabled]="!is_agree"></p-button>
    </div>
  </div>
</div>
<ui-fibr-blockui [blocked]="blocked"></ui-fibr-blockui>